// 生成时间：2024/9/27 17:34:43
/* eslint-disable */
import axios from 'axios'
import qs from 'qs'
let domain = ''
export const getDomain = () => {
  return domain
}
export const setDomain = ($domain) => {
  domain = $domain
}
export const request = (method, url, body, queryParameters, form, config = {}) => {
  method = method.toLowerCase()
  let keys = Object.keys(queryParameters)
  let queryUrl = url
  if (keys.length > 0) {
    queryUrl = url + '?' + qs.stringify(queryParameters)
  }
  // let queryUrl = url+(keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
  if (body) {
    return axios[method](queryUrl, body, config)
  } else if (method === 'get') {
    return axios[method](queryUrl, {
      params: form,
      ...config
    })
  } else {
    return axios[method](queryUrl, qs.stringify(form), config)
  }
}
/*==========================================================
 *                    健康管理平台接口文档
 ==========================================================*/
/**
 * 获取国家卫健委新冠肺炎对学生视力影响评估调查表
 * request: bizCovid19SvisqFindByStudent
 * formData: bizCovid19SvisqFindByStudentFormData
 * url: bizCovid19SvisqFindByStudent_URL
 * method: bizCovid19SvisqFindByStudent_TYPE
 * raw_url: bizCovid19SvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizCovid19SvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/covid19-svisq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizCovid19SvisqFindByStudentFormData = function(formData) {
  const url = bizCovid19SvisqFindByStudent_RAW_URL()
  const method = bizCovid19SvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizCovid19SvisqFindByStudent_RAW_URL = function() {
  return '/business/covid19-svisq/by-student/{studentId}'
}
export const bizCovid19SvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizCovid19SvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/covid19-svisq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存2021年疫情防控期间学生视力影响因素专项调查表
 * request: bizCovid19SvisqSave
 * formData: bizCovid19SvisqSaveFormData
 * url: bizCovid19SvisqSave_URL
 * method: bizCovid19SvisqSave_TYPE
 * raw_url: bizCovid19SvisqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a03 - 
 * @param a04LeftVision - 
 * @param a04RightVision - 
 * @param a05 - 
 * @param a06 - 
 * @param a06Day - 当a06填未隔离，则不填
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b06 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b17OtherDescription - 
 * @param b18 - 
 * @param c01 - 
 * @param c011 - c01选是则必填，反之不填
 * @param c011OtherDescription - 
 * @param c012 - c01选是则必填，反之不填
 * @param c013 - c01选是则必填，反之不填
 * @param c013Date - 
 * @param c014 - c01选是则必填，反之不填
 * @param c014OtherDescription - c01选是则必填，反之不填
 * @param c015 - c01选是则必填，反之不填; 值','隔开
 * @param c016 - c01选是则必填，反之不填; 值','隔开
 * @param c0171 - 单位分钟
 * @param c0172 - 单位分钟
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c081 - 
 * @param c09 - 
 * @param c091 - 
 * @param c11 - 
 * @param c12 - 
 * @param c121 - 
 * @param c121Minute - 
 * @param c123 - 
 * @param c123Minute - 
 * @param c14 - 
 * @param c14Minute - 
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 */
export const bizCovid19SvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/covid19-svisq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04LeftVision'] !== undefined) {
    form['a04LeftVision'] = parameters['a04LeftVision']
  }
  if (parameters['a04LeftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04LeftVision'))
  }
  if (parameters['a04RightVision'] !== undefined) {
    form['a04RightVision'] = parameters['a04RightVision']
  }
  if (parameters['a04RightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04RightVision'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a06Day'] !== undefined) {
    form['a06Day'] = parameters['a06Day']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17OtherDescription'] !== undefined) {
    form['b17OtherDescription'] = parameters['b17OtherDescription']
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c011'] !== undefined) {
    form['c011'] = parameters['c011']
  }
  if (parameters['c011OtherDescription'] !== undefined) {
    form['c011OtherDescription'] = parameters['c011OtherDescription']
  }
  if (parameters['c012'] !== undefined) {
    form['c012'] = parameters['c012']
  }
  if (parameters['c013'] !== undefined) {
    form['c013'] = parameters['c013']
  }
  if (parameters['c013Date'] !== undefined) {
    form['c013Date'] = parameters['c013Date']
  }
  if (parameters['c014'] !== undefined) {
    form['c014'] = parameters['c014']
  }
  if (parameters['c014OtherDescription'] !== undefined) {
    form['c014OtherDescription'] = parameters['c014OtherDescription']
  }
  if (parameters['c015'] !== undefined) {
    form['c015'] = parameters['c015']
  }
  if (parameters['c016'] !== undefined) {
    form['c016'] = parameters['c016']
  }
  if (parameters['c0171'] !== undefined) {
    form['c0171'] = parameters['c0171']
  }
  if (parameters['c0172'] !== undefined) {
    form['c0172'] = parameters['c0172']
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c081'] !== undefined) {
    form['c081'] = parameters['c081']
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c091'] !== undefined) {
    form['c091'] = parameters['c091']
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c12'] !== undefined) {
    form['c12'] = parameters['c12']
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121Minute'] !== undefined) {
    form['c121Minute'] = parameters['c121Minute']
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123Minute'] !== undefined) {
    form['c123Minute'] = parameters['c123Minute']
  }
  if (parameters['c14'] !== undefined) {
    form['c14'] = parameters['c14']
  }
  if (parameters['c14Minute'] !== undefined) {
    form['c14Minute'] = parameters['c14Minute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizCovid19SvisqSaveFormData = function(formData) {
  const url = bizCovid19SvisqSave_RAW_URL()
  const method = bizCovid19SvisqSave_TYPE()
  return axios[method](url, formData)
}
export const bizCovid19SvisqSave_RAW_URL = function() {
  return '/business/covid19-svisq/save/{studentId}'
}
export const bizCovid19SvisqSave_TYPE = function() {
  return 'post'
}
export const bizCovid19SvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/covid19-svisq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 系统信息
 * request: bizMainSystemInfo
 * formData: bizMainSystemInfoFormData
 * url: bizMainSystemInfo_URL
 * method: bizMainSystemInfo_TYPE
 * raw_url: bizMainSystemInfo_RAW_URL
 */
export const bizMainSystemInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/main/system-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMainSystemInfoFormData = function(formData) {
  const url = bizMainSystemInfo_RAW_URL()
  const method = bizMainSystemInfo_TYPE()
  return axios[method](url, formData)
}
export const bizMainSystemInfo_RAW_URL = function() {
  return '/business/main/system-info'
}
export const bizMainSystemInfo_TYPE = function() {
  return 'get'
}
export const bizMainSystemInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/main/system-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看中学学生健康状况及影响因素调查表
 * request: bizMshiqFindByStudent
 * formData: bizMshiqFindByStudentFormData
 * url: bizMshiqFindByStudent_URL
 * method: bizMshiqFindByStudent_TYPE
 * raw_url: bizMshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizMshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/mshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizMshiqFindByStudentFormData = function(formData) {
  const url = bizMshiqFindByStudent_RAW_URL()
  const method = bizMshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizMshiqFindByStudent_RAW_URL = function() {
  return '/business/mshiq/by-student/{studentId}'
}
export const bizMshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizMshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/mshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查表
 * request: bizMshiqSave
 * formData: bizMshiqSaveFormData
 * url: bizMshiqSave_URL
 * method: bizMshiqSave_TYPE
 * raw_url: bizMshiqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 
 * @param d06Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d07 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d08 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d081 - 每次服用止咳药水的量是多少？
 * @param d09 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填
 * @param f02 - 当F01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param f03 - 当F01的值为2时必填，反之不填
 * @param f04 - 当F01的值为2时必填，反之不填
 * @param f05 - 当F01的值为2时必填，反之不填
 * @param g01 - 
 * @param g02 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 
 * @param h05 - 
 * @param h06 - 
 * @param h07 - 
 * @param h08 - 
 * @param h09 - 
 * @param h10 - 
 * @param h11 - 
 * @param h12 - 
 * @param h13 - 
 * @param h14 - 
 * @param h15 - 
 * @param h16 - 
 * @param h17 - 
 * @param h18 - 
 * @param h19 - 
 * @param h20 - 
 * @param h21 - 感觉紧张，焦虑或急切
 * @param h22 - 不能够停止或控制担忧
 * @param h23 - 对各种恶样的事情担忧过多
 * @param h24 - 很难放松下来
 * @param h25 - 由于不安而无法静坐
 * @param h26 - 变得容易烦恼或急躁
 * @param h27 - 感到似乎将有可怕的事情发生而害怕
 * @param h28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param h281 - 你寻求了什么形式的帮助
 * @param h282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param i01 - 
 * @param i011 - 
 * @param i02 - 
 * @param i021 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const bizMshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/mshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['d06Description'] !== undefined) {
    form['d06Description'] = parameters['d06Description']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d07'))
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d08'))
  }
  if (parameters['d081'] !== undefined) {
    form['d081'] = parameters['d081']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['h21'] !== undefined) {
    form['h21'] = parameters['h21']
  }
  if (parameters['h21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h21'))
  }
  if (parameters['h22'] !== undefined) {
    form['h22'] = parameters['h22']
  }
  if (parameters['h22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h22'))
  }
  if (parameters['h23'] !== undefined) {
    form['h23'] = parameters['h23']
  }
  if (parameters['h23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h23'))
  }
  if (parameters['h24'] !== undefined) {
    form['h24'] = parameters['h24']
  }
  if (parameters['h24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h24'))
  }
  if (parameters['h25'] !== undefined) {
    form['h25'] = parameters['h25']
  }
  if (parameters['h25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h25'))
  }
  if (parameters['h26'] !== undefined) {
    form['h26'] = parameters['h26']
  }
  if (parameters['h26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h26'))
  }
  if (parameters['h27'] !== undefined) {
    form['h27'] = parameters['h27']
  }
  if (parameters['h27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h27'))
  }
  if (parameters['h28'] !== undefined) {
    form['h28'] = parameters['h28']
  }
  if (parameters['h28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h28'))
  }
  if (parameters['h281'] !== undefined) {
    form['h281'] = parameters['h281']
  }
  if (parameters['h282'] !== undefined) {
    form['h282'] = parameters['h282']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizMshiqSaveFormData = function(formData) {
  const url = bizMshiqSave_RAW_URL()
  const method = bizMshiqSave_TYPE()
  return axios[method](url, formData)
}
export const bizMshiqSave_RAW_URL = function() {
  return '/business/mshiq/save/{studentId}'
}
export const bizMshiqSave_TYPE = function() {
  return 'post'
}
export const bizMshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/mshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看小学学生健康状况及影响因素调查表
 * request: bizPshiqFindByStudent
 * formData: bizPshiqFindByStudentFormData
 * url: bizPshiqFindByStudent_URL
 * method: bizPshiqFindByStudent_TYPE
 * raw_url: bizPshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizPshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/pshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizPshiqFindByStudentFormData = function(formData) {
  const url = bizPshiqFindByStudent_RAW_URL()
  const method = bizPshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizPshiqFindByStudent_RAW_URL = function() {
  return '/business/pshiq/by-student/{studentId}'
}
export const bizPshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizPshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/pshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查表
 * request: bizPshiqSave
 * formData: bizPshiqSaveFormData
 * url: bizPshiqSave_URL
 * method: bizPshiqSave_TYPE
 * raw_url: bizPshiqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 过去一年内，你是否因生病休学几个月
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填.第一次尝试吸烟的年龄？
 * @param d03 - 
 * @param d04 - 
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填
 * @param e01 - 
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填
 * @param e02 - 当E01的值为2时必填，反之不填.过去7天，你平均每天使用耳机时间数选项
 * @param e03 - 当E01的值为2时必填，反之不填
 * @param e04 - 当E01的值为2时必填，反之不填
 * @param e05 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f011 - 
 * @param f02 - 
 * @param f021 - 
 * @param f05 - 
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const bizPshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/pshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizPshiqSaveFormData = function(formData) {
  const url = bizPshiqSave_RAW_URL()
  const method = bizPshiqSave_TYPE()
  return axios[method](url, formData)
}
export const bizPshiqSave_RAW_URL = function() {
  return '/business/pshiq/save/{studentId}'
}
export const bizPshiqSave_TYPE = function() {
  return 'post'
}
export const bizPshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/pshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生列表
 * request: bizScreeningStudentList
 * formData: bizScreeningStudentListFormData
 * url: bizScreeningStudentList_URL
 * method: bizScreeningStudentList_TYPE
 * raw_url: bizScreeningStudentList_RAW_URL
 */
export const bizScreeningStudentList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentListFormData = function(formData) {
  const url = bizScreeningStudentList_RAW_URL()
  const method = bizScreeningStudentList_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentList_RAW_URL = function() {
  return '/business/screening-student'
}
export const bizScreeningStudentList_TYPE = function() {
  return 'get'
}
export const bizScreeningStudentList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据ID绑定筛查学生
 * request: bizScreeningStudentBindingById
 * formData: bizScreeningStudentBindingByIdFormData
 * url: bizScreeningStudentBindingById_URL
 * method: bizScreeningStudentBindingById_TYPE
 * raw_url: bizScreeningStudentBindingById_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param id - id
 */
export const bizScreeningStudentBindingById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/binding/by-id'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentBindingByIdFormData = function(formData) {
  const url = bizScreeningStudentBindingById_RAW_URL()
  const method = bizScreeningStudentBindingById_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentBindingById_RAW_URL = function() {
  return '/business/screening-student/binding/by-id'
}
export const bizScreeningStudentBindingById_TYPE = function() {
  return 'post'
}
export const bizScreeningStudentBindingById_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/binding/by-id'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据姓名身份证绑定筛查学生
 * request: bizScreeningStudentBindingByNameIdCard
 * formData: bizScreeningStudentBindingByNameIdCardFormData
 * url: bizScreeningStudentBindingByNameIdCard_URL
 * method: bizScreeningStudentBindingByNameIdCard_TYPE
 * raw_url: bizScreeningStudentBindingByNameIdCard_RAW_URL
 * @param name - 
 * @param idCard - 
 */
export const bizScreeningStudentBindingByNameIdCard = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/binding/by-name-id-card'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentBindingByNameIdCardFormData = function(formData) {
  const url = bizScreeningStudentBindingByNameIdCard_RAW_URL()
  const method = bizScreeningStudentBindingByNameIdCard_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentBindingByNameIdCard_RAW_URL = function() {
  return '/business/screening-student/binding/by-name-id-card'
}
export const bizScreeningStudentBindingByNameIdCard_TYPE = function() {
  return 'post'
}
export const bizScreeningStudentBindingByNameIdCard_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/binding/by-name-id-card'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据姓名回乡证/学生居留许可证绑定筛查学生
 * request: bizScreeningStudentBindingByNameReentryStudentResidencePermit
 * formData: bizScreeningStudentBindingByNameReentryStudentResidencePermitFormData
 * url: bizScreeningStudentBindingByNameReentryStudentResidencePermit_URL
 * method: bizScreeningStudentBindingByNameReentryStudentResidencePermit_TYPE
 * raw_url: bizScreeningStudentBindingByNameReentryStudentResidencePermit_RAW_URL
 * @param name - 
 * @param reentryStudentResidencePermit - 
 */
export const bizScreeningStudentBindingByNameReentryStudentResidencePermit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/binding/by-name-reentry-student-residence-permit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['reentryStudentResidencePermit'] !== undefined) {
    form['reentryStudentResidencePermit'] = parameters['reentryStudentResidencePermit']
  }
  if (parameters['reentryStudentResidencePermit'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reentryStudentResidencePermit'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentBindingByNameReentryStudentResidencePermitFormData = function(formData) {
  const url = bizScreeningStudentBindingByNameReentryStudentResidencePermit_RAW_URL()
  const method = bizScreeningStudentBindingByNameReentryStudentResidencePermit_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentBindingByNameReentryStudentResidencePermit_RAW_URL = function() {
  return '/business/screening-student/binding/by-name-reentry-student-residence-permit'
}
export const bizScreeningStudentBindingByNameReentryStudentResidencePermit_TYPE = function() {
  return 'post'
}
export const bizScreeningStudentBindingByNameReentryStudentResidencePermit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/binding/by-name-reentry-student-residence-permit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生历史记录
 * request: bizScreeningStudentHistory
 * formData: bizScreeningStudentHistoryFormData
 * url: bizScreeningStudentHistory_URL
 * method: bizScreeningStudentHistory_TYPE
 * raw_url: bizScreeningStudentHistory_RAW_URL
 * @param personId - personId
 */
export const bizScreeningStudentHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentHistoryFormData = function(formData) {
  const url = bizScreeningStudentHistory_RAW_URL()
  const method = bizScreeningStudentHistory_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentHistory_RAW_URL = function() {
  return '/business/screening-student/history'
}
export const bizScreeningStudentHistory_TYPE = function() {
  return 'get'
}
export const bizScreeningStudentHistory_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/history'
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生历史记录（带视力、屈光报告）
 * request: bizScreeningStudentHistoryWithReport
 * formData: bizScreeningStudentHistoryWithReportFormData
 * url: bizScreeningStudentHistoryWithReport_URL
 * method: bizScreeningStudentHistoryWithReport_TYPE
 * raw_url: bizScreeningStudentHistoryWithReport_RAW_URL
 * @param personId - personId
 */
export const bizScreeningStudentHistoryWithReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/history-with-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentHistoryWithReportFormData = function(formData) {
  const url = bizScreeningStudentHistoryWithReport_RAW_URL()
  const method = bizScreeningStudentHistoryWithReport_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentHistoryWithReport_RAW_URL = function() {
  return '/business/screening-student/history-with-report'
}
export const bizScreeningStudentHistoryWithReport_TYPE = function() {
  return 'get'
}
export const bizScreeningStudentHistoryWithReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/history-with-report'
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生详情
 * request: bizScreeningStudentFind
 * formData: bizScreeningStudentFindFormData
 * url: bizScreeningStudentFind_URL
 * method: bizScreeningStudentFind_TYPE
 * raw_url: bizScreeningStudentFind_RAW_URL
 * @param id - id
 * @param screeningPlanId - screeningPlanId
 */
export const bizScreeningStudentFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentFindFormData = function(formData) {
  const url = bizScreeningStudentFind_RAW_URL()
  const method = bizScreeningStudentFind_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentFind_RAW_URL = function() {
  return '/business/screening-student/{id}'
}
export const bizScreeningStudentFind_TYPE = function() {
  return 'get'
}
export const bizScreeningStudentFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传学生图片
 * request: bizScreeningStudentUploadPhoto
 * formData: bizScreeningStudentUploadPhotoFormData
 * url: bizScreeningStudentUploadPhoto_URL
 * method: bizScreeningStudentUploadPhoto_TYPE
 * raw_url: bizScreeningStudentUploadPhoto_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param file - 
 */
export const bizScreeningStudentUploadPhoto = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/screening-student/{screeningStudentId}/photo'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizScreeningStudentUploadPhotoFormData = function(formData) {
  const url = bizScreeningStudentUploadPhoto_RAW_URL()
  const method = bizScreeningStudentUploadPhoto_TYPE()
  return axios[method](url, formData)
}
export const bizScreeningStudentUploadPhoto_RAW_URL = function() {
  return '/business/screening-student/{screeningStudentId}/photo'
}
export const bizScreeningStudentUploadPhoto_TYPE = function() {
  return 'post'
}
export const bizScreeningStudentUploadPhoto_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/screening-student/{screeningStudentId}/photo'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生视力不良及影响因素专项调查表
 * request: bizSpvisqFindByStudent
 * formData: bizSpvisqFindByStudentFormData
 * url: bizSpvisqFindByStudent_URL
 * method: bizSpvisqFindByStudent_TYPE
 * raw_url: bizSpvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizSpvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/spvisq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizSpvisqFindByStudentFormData = function(formData) {
  const url = bizSpvisqFindByStudent_RAW_URL()
  const method = bizSpvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizSpvisqFindByStudent_RAW_URL = function() {
  return '/business/spvisq/by-student/{studentId}'
}
export const bizSpvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizSpvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/spvisq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查表
 * request: bizSpvisqSave
 * formData: bizSpvisqSaveFormData
 * url: bizSpvisqSave_URL
 * method: bizSpvisqSave_TYPE
 * raw_url: bizSpvisqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 当C03不是选中选项5和6时必填，反之不填
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param e04 - 在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间？
 * @param e04Time - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的平均分钟数
 * @param e04NonLearningTime - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间非学习目的使用分钟数
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const bizSpvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/spvisq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e04Time'] !== undefined) {
    form['e04Time'] = parameters['e04Time']
  }
  if (parameters['e04NonLearningTime'] !== undefined) {
    form['e04NonLearningTime'] = parameters['e04NonLearningTime']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizSpvisqSaveFormData = function(formData) {
  const url = bizSpvisqSave_RAW_URL()
  const method = bizSpvisqSave_TYPE()
  return axios[method](url, formData)
}
export const bizSpvisqSave_RAW_URL = function() {
  return '/business/spvisq/save/{studentId}'
}
export const bizSpvisqSave_TYPE = function() {
  return 'post'
}
export const bizSpvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/spvisq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生新冠肺炎专项调查表
 * request: bizScsqFindByStudent
 * formData: bizScsqFindByStudentFormData
 * url: bizScsqFindByStudent_URL
 * method: bizScsqFindByStudent_TYPE
 * raw_url: bizScsqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizScsqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/student-covid19-special-questionary/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizScsqFindByStudentFormData = function(formData) {
  const url = bizScsqFindByStudent_RAW_URL()
  const method = bizScsqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizScsqFindByStudent_RAW_URL = function() {
  return '/business/student-covid19-special-questionary/by-student/{studentId}'
}
export const bizScsqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizScsqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/student-covid19-special-questionary/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生新冠肺炎专项调查表
 * request: bizScsqSave
 * formData: bizScsqSaveFormData
 * url: bizScsqSave_URL
 * method: bizScsqSave_TYPE
 * raw_url: bizScsqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param a01 - 
 * @param a02 - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a06 - 
 * @param a07 - 
 * @param a071Day - 
 * @param a072Day - 
 * @param a08 - 
 * @param a08Day - 
 * @param a09 - 
 * @param b01 - 
 * @param b01StartDate - 
 * @param b01EndDate - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b05OtherDescription - 
 * @param b06 - 
 * @param b0601 - 
 * @param b0602 - 
 * @param b0603 - 
 * @param b0604 - 
 * @param b0605 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b18 - 
 * @param b19 - 
 * @param b20 - 
 * @param b201 - 
 * @param b21 - 
 * @param b21OtherDescription - 
 * @param b22 - 
 * @param b23 - 
 * @param b24 - 
 * @param b25 - 
 * @param b26 - 
 * @param b27 - 
 * @param b28 - 
 * @param b28OtherDescription - 
 * @param b29 - 
 * @param b30 - 
 * @param b31 - 
 * @param b32 - 
 * @param b32OtherDescription - 
 * @param b33 - 
 */
export const bizScsqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/student-covid19-special-questionary/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['a01'] !== undefined) {
    form['a01'] = parameters['a01']
  }
  if (parameters['a01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a01'))
  }
  if (parameters['a02'] !== undefined) {
    form['a02'] = parameters['a02']
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a071Day'] !== undefined) {
    form['a071Day'] = parameters['a071Day']
  }
  if (parameters['a072Day'] !== undefined) {
    form['a072Day'] = parameters['a072Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Day'] !== undefined) {
    form['a08Day'] = parameters['a08Day']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b01StartDate'] !== undefined) {
    form['b01StartDate'] = parameters['b01StartDate']
  }
  if (parameters['b01EndDate'] !== undefined) {
    form['b01EndDate'] = parameters['b01EndDate']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b05OtherDescription'] !== undefined) {
    form['b05OtherDescription'] = parameters['b05OtherDescription']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b0601'] !== undefined) {
    form['b0601'] = parameters['b0601']
  }
  if (parameters['b0602'] !== undefined) {
    form['b0602'] = parameters['b0602']
  }
  if (parameters['b0603'] !== undefined) {
    form['b0603'] = parameters['b0603']
  }
  if (parameters['b0604'] !== undefined) {
    form['b0604'] = parameters['b0604']
  }
  if (parameters['b0605'] !== undefined) {
    form['b0605'] = parameters['b0605']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b11'))
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b12'))
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b13'))
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b14'))
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b15'))
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b16'))
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b17'))
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['b18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b18'))
  }
  if (parameters['b19'] !== undefined) {
    form['b19'] = parameters['b19']
  }
  if (parameters['b19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b19'))
  }
  if (parameters['b20'] !== undefined) {
    form['b20'] = parameters['b20']
  }
  if (parameters['b20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b20'))
  }
  if (parameters['b201'] !== undefined) {
    form['b201'] = parameters['b201']
  }
  if (parameters['b21'] !== undefined) {
    form['b21'] = parameters['b21']
  }
  if (parameters['b21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b21'))
  }
  if (parameters['b21OtherDescription'] !== undefined) {
    form['b21OtherDescription'] = parameters['b21OtherDescription']
  }
  if (parameters['b22'] !== undefined) {
    form['b22'] = parameters['b22']
  }
  if (parameters['b22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b22'))
  }
  if (parameters['b23'] !== undefined) {
    form['b23'] = parameters['b23']
  }
  if (parameters['b23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b23'))
  }
  if (parameters['b24'] !== undefined) {
    form['b24'] = parameters['b24']
  }
  if (parameters['b24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b24'))
  }
  if (parameters['b25'] !== undefined) {
    form['b25'] = parameters['b25']
  }
  if (parameters['b25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b25'))
  }
  if (parameters['b26'] !== undefined) {
    form['b26'] = parameters['b26']
  }
  if (parameters['b26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b26'))
  }
  if (parameters['b27'] !== undefined) {
    form['b27'] = parameters['b27']
  }
  if (parameters['b27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b27'))
  }
  if (parameters['b28'] !== undefined) {
    form['b28'] = parameters['b28']
  }
  if (parameters['b28OtherDescription'] !== undefined) {
    form['b28OtherDescription'] = parameters['b28OtherDescription']
  }
  if (parameters['b29'] !== undefined) {
    form['b29'] = parameters['b29']
  }
  if (parameters['b29'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b29'))
  }
  if (parameters['b30'] !== undefined) {
    form['b30'] = parameters['b30']
  }
  if (parameters['b30'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b30'))
  }
  if (parameters['b31'] !== undefined) {
    form['b31'] = parameters['b31']
  }
  if (parameters['b31'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b31'))
  }
  if (parameters['b32'] !== undefined) {
    form['b32'] = parameters['b32']
  }
  if (parameters['b32'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b32'))
  }
  if (parameters['b32OtherDescription'] !== undefined) {
    form['b32OtherDescription'] = parameters['b32OtherDescription']
  }
  if (parameters['b33'] !== undefined) {
    form['b33'] = parameters['b33']
  }
  if (parameters['b33'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b33'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizScsqSaveFormData = function(formData) {
  const url = bizScsqSave_RAW_URL()
  const method = bizScsqSave_TYPE()
  return axios[method](url, formData)
}
export const bizScsqSave_RAW_URL = function() {
  return '/business/student-covid19-special-questionary/save/{studentId}'
}
export const bizScsqSave_TYPE = function() {
  return 'post'
}
export const bizScsqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/student-covid19-special-questionary/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中小学生视力相关行为调查问卷
 * request: bizStudentVisionBehaviorSave
 * formData: bizStudentVisionBehaviorSaveFormData
 * url: bizStudentVisionBehaviorSave_URL
 * method: bizStudentVisionBehaviorSave_TYPE
 * raw_url: bizStudentVisionBehaviorSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a05 - 
 * @param a06 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041Left - b04选是必填
 * @param b041Right - b04选是必填
 * @param b05 - 
 * @param b06 - 
 * @param b061Left - b06选是必填
 * @param b061Right - b06选是必填
 * @param c01 - 
 * @param c02 - 
 * @param c02Other - c02选其他必填
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c09 - 
 * @param c10 - 
 * @param c11 - 
 * @param c12 - 
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d03Other - d03选其他必填
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e01True - e01选是必填
 * @param e02 - e01选是必填
 * @param e02True - e02选是必填
 * @param e03 - 
 * @param e03True - e03选是必填
 * @param e04 - e03选是必填
 * @param e04True - e04选是必填
 * @param e05 - 
 * @param e05True - e05选是必填
 * @param e06 - e05选是必填
 * @param e06True - e06选是必填
 * @param e07 - 
 * @param e07True - e07选是必填
 * @param e08 - 
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f04True - f04选是必填
 * @param f05 - 
 * @param f06 - 
 */
export const bizStudentVisionBehaviorSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/student-vision-behavior/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041Left'] !== undefined) {
    form['b041Left'] = parameters['b041Left']
  }
  if (parameters['b041Right'] !== undefined) {
    form['b041Right'] = parameters['b041Right']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061Left'] !== undefined) {
    form['b061Left'] = parameters['b061Left']
  }
  if (parameters['b061Right'] !== undefined) {
    form['b061Right'] = parameters['b061Right']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c02Other'] !== undefined) {
    form['c02Other'] = parameters['c02Other']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c09'))
  }
  if (parameters['c10'] !== undefined) {
    form['c10'] = parameters['c10']
  }
  if (parameters['c10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c10'))
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c11'))
  }
  if (parameters['c12'] !== undefined) {
    form['c12'] = parameters['c12']
  }
  if (parameters['c12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c12'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d03Other'] !== undefined) {
    form['d03Other'] = parameters['d03Other']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01True'] !== undefined) {
    form['e01True'] = parameters['e01True']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02True'] !== undefined) {
    form['e02True'] = parameters['e02True']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03True'] !== undefined) {
    form['e03True'] = parameters['e03True']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04True'] !== undefined) {
    form['e04True'] = parameters['e04True']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['e05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e05'))
  }
  if (parameters['e05True'] !== undefined) {
    form['e05True'] = parameters['e05True']
  }
  if (parameters['e06'] !== undefined) {
    form['e06'] = parameters['e06']
  }
  if (parameters['e06True'] !== undefined) {
    form['e06True'] = parameters['e06True']
  }
  if (parameters['e07'] !== undefined) {
    form['e07'] = parameters['e07']
  }
  if (parameters['e07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e07'))
  }
  if (parameters['e07True'] !== undefined) {
    form['e07True'] = parameters['e07True']
  }
  if (parameters['e08'] !== undefined) {
    form['e08'] = parameters['e08']
  }
  if (parameters['e08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e08'))
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f04True'] !== undefined) {
    form['f04True'] = parameters['f04True']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizStudentVisionBehaviorSaveFormData = function(formData) {
  const url = bizStudentVisionBehaviorSave_RAW_URL()
  const method = bizStudentVisionBehaviorSave_TYPE()
  return axios[method](url, formData)
}
export const bizStudentVisionBehaviorSave_RAW_URL = function() {
  return '/business/student-vision-behavior/save/{studentId}'
}
export const bizStudentVisionBehaviorSave_TYPE = function() {
  return 'post'
}
export const bizStudentVisionBehaviorSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/student-vision-behavior/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学生健康状况及影响因素调查表(中学版)
 * request: bizUserInvestigationTaskFindItMshiq
 * formData: bizUserInvestigationTaskFindItMshiqFormData
 * url: bizUserInvestigationTaskFindItMshiq_URL
 * method: bizUserInvestigationTaskFindItMshiq_TYPE
 * raw_url: bizUserInvestigationTaskFindItMshiq_RAW_URL
 * @param itMShiqId - itMShiqId
 * @param cityCode - 
 */
export const bizUserInvestigationTaskFindItMshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/it-mshiq/{itMShiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itMShiqId}', `${parameters['itMShiqId']}`)
  if (parameters['itMShiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itMShiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskFindItMshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskFindItMshiq_RAW_URL()
  const method = bizUserInvestigationTaskFindItMshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskFindItMshiq_RAW_URL = function() {
  return '/business/user-investigation-task/it-mshiq/{itMShiqId}'
}
export const bizUserInvestigationTaskFindItMshiq_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskFindItMshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/it-mshiq/{itMShiqId}'
  path = path.replace('{itMShiqId}', `${parameters['itMShiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看学生健康状况及影响因素调查表(小学版)
 * request: bizUserInvestigationTaskFindItPshiq
 * formData: bizUserInvestigationTaskFindItPshiqFormData
 * url: bizUserInvestigationTaskFindItPshiq_URL
 * method: bizUserInvestigationTaskFindItPshiq_TYPE
 * raw_url: bizUserInvestigationTaskFindItPshiq_RAW_URL
 * @param itPshiqId - itPshiqId
 * @param cityCode - 
 */
export const bizUserInvestigationTaskFindItPshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/it-pshiq/{itPshiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itPshiqId}', `${parameters['itPshiqId']}`)
  if (parameters['itPshiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itPshiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskFindItPshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskFindItPshiq_RAW_URL()
  const method = bizUserInvestigationTaskFindItPshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskFindItPshiq_RAW_URL = function() {
  return '/business/user-investigation-task/it-pshiq/{itPshiqId}'
}
export const bizUserInvestigationTaskFindItPshiq_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskFindItPshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/it-pshiq/{itPshiqId}'
  path = path.replace('{itPshiqId}', `${parameters['itPshiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学生视力不良及影响因素专项调查表
 * request: bizUserInvestigationTaskFindItSpvisq
 * formData: bizUserInvestigationTaskFindItSpvisqFormData
 * url: bizUserInvestigationTaskFindItSpvisq_URL
 * method: bizUserInvestigationTaskFindItSpvisq_TYPE
 * raw_url: bizUserInvestigationTaskFindItSpvisq_RAW_URL
 * @param itSpvispId - itSpvispId
 * @param cityCode - 
 */
export const bizUserInvestigationTaskFindItSpvisq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/it-spvisq/{itSpvispId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itSpvispId}', `${parameters['itSpvispId']}`)
  if (parameters['itSpvispId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itSpvispId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskFindItSpvisqFormData = function(formData) {
  const url = bizUserInvestigationTaskFindItSpvisq_RAW_URL()
  const method = bizUserInvestigationTaskFindItSpvisq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskFindItSpvisq_RAW_URL = function() {
  return '/business/user-investigation-task/it-spvisq/{itSpvispId}'
}
export const bizUserInvestigationTaskFindItSpvisq_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskFindItSpvisq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/it-spvisq/{itSpvispId}'
  path = path.replace('{itSpvispId}', `${parameters['itSpvispId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学生健康状况及影响因素调查表(大学版)
 * request: bizUserInvestigationTaskFindItUshiq
 * formData: bizUserInvestigationTaskFindItUshiqFormData
 * url: bizUserInvestigationTaskFindItUshiq_URL
 * method: bizUserInvestigationTaskFindItUshiq_TYPE
 * raw_url: bizUserInvestigationTaskFindItUshiq_RAW_URL
 * @param itUshiqId - itUshiqId
 * @param cityCode - 
 */
export const bizUserInvestigationTaskFindItUshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/it-ushiq/{itUshiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itUshiqId}', `${parameters['itUshiqId']}`)
  if (parameters['itUshiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itUshiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskFindItUshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskFindItUshiq_RAW_URL()
  const method = bizUserInvestigationTaskFindItUshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskFindItUshiq_RAW_URL = function() {
  return '/business/user-investigation-task/it-ushiq/{itUshiqId}'
}
export const bizUserInvestigationTaskFindItUshiq_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskFindItUshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/it-ushiq/{itUshiqId}'
  path = path.replace('{itUshiqId}', `${parameters['itUshiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查任务表
 * request: bizUserInvestigationTaskSaveMshiq
 * formData: bizUserInvestigationTaskSaveMshiqFormData
 * url: bizUserInvestigationTaskSaveMshiq_URL
 * method: bizUserInvestigationTaskSaveMshiq_TYPE
 * raw_url: bizUserInvestigationTaskSaveMshiq_RAW_URL
 * @param investigationTaskId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param regionPersonId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填
 * @param f02 - 当F01的值为2时必填，反之不填
 * @param f03 - 当F01的值为2时必填，反之不填
 * @param f04 - 当F01的值为2时必填，反之不填
 * @param f05 - 当F01的值为2时必填，反之不填
 * @param g01 - 
 * @param g02 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 
 * @param h05 - 
 * @param h06 - 
 * @param h07 - 
 * @param h08 - 
 * @param h09 - 
 * @param h10 - 
 * @param h11 - 
 * @param h12 - 
 * @param h13 - 
 * @param h14 - 
 * @param h15 - 
 * @param h16 - 
 * @param h17 - 
 * @param h18 - 
 * @param h19 - 
 * @param h20 - 
 * @param i01 - 
 * @param i011 - 
 * @param i02 - 
 * @param i021 - 
 * @param i03 - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const bizUserInvestigationTaskSaveMshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/save-mshiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['investigationTaskId'] !== undefined) {
    form['investigationTaskId'] = parameters['investigationTaskId']
  }
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskSaveMshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskSaveMshiq_RAW_URL()
  const method = bizUserInvestigationTaskSaveMshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskSaveMshiq_RAW_URL = function() {
  return '/business/user-investigation-task/save-mshiq'
}
export const bizUserInvestigationTaskSaveMshiq_TYPE = function() {
  return 'post'
}
export const bizUserInvestigationTaskSaveMshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/save-mshiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查任务表
 * request: bizUserInvestigationTaskSavePshiq
 * formData: bizUserInvestigationTaskSavePshiqFormData
 * url: bizUserInvestigationTaskSavePshiq_URL
 * method: bizUserInvestigationTaskSavePshiq_TYPE
 * raw_url: bizUserInvestigationTaskSavePshiq_RAW_URL
 * @param investigationTaskId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param regionPersonId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 
 * @param d04 - 
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填
 * @param e01 - 
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填
 * @param e02 - 当E01的值为2时必填，反之不填
 * @param e03 - 当E01的值为2时必填，反之不填
 * @param e04 - 当E01的值为2时必填，反之不填
 * @param e05 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f011 - 
 * @param f02 - 
 * @param f021 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const bizUserInvestigationTaskSavePshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/save-pshiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['investigationTaskId'] !== undefined) {
    form['investigationTaskId'] = parameters['investigationTaskId']
  }
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskSavePshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskSavePshiq_RAW_URL()
  const method = bizUserInvestigationTaskSavePshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskSavePshiq_RAW_URL = function() {
  return '/business/user-investigation-task/save-pshiq'
}
export const bizUserInvestigationTaskSavePshiq_TYPE = function() {
  return 'post'
}
export const bizUserInvestigationTaskSavePshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/save-pshiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查任务表
 * request: bizUserInvestigationTaskSaveSpvisq
 * formData: bizUserInvestigationTaskSaveSpvisqFormData
 * url: bizUserInvestigationTaskSaveSpvisq_URL
 * method: bizUserInvestigationTaskSaveSpvisq_TYPE
 * raw_url: bizUserInvestigationTaskSaveSpvisq_RAW_URL
 * @param investigationTaskId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param regionPersonId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 当C03不是选中选项5和6时必填，反之不填
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const bizUserInvestigationTaskSaveSpvisq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/save-spvisq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['investigationTaskId'] !== undefined) {
    form['investigationTaskId'] = parameters['investigationTaskId']
  }
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskSaveSpvisqFormData = function(formData) {
  const url = bizUserInvestigationTaskSaveSpvisq_RAW_URL()
  const method = bizUserInvestigationTaskSaveSpvisq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskSaveSpvisq_RAW_URL = function() {
  return '/business/user-investigation-task/save-spvisq'
}
export const bizUserInvestigationTaskSaveSpvisq_TYPE = function() {
  return 'post'
}
export const bizUserInvestigationTaskSaveSpvisq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/save-spvisq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查任务表
 * request: bizUserInvestigationTaskSaveUshiq
 * formData: bizUserInvestigationTaskSaveUshiqFormData
 * url: bizUserInvestigationTaskSaveUshiq_URL
 * method: bizUserInvestigationTaskSaveUshiq_TYPE
 * raw_url: bizUserInvestigationTaskSaveUshiq_RAW_URL
 * @param investigationTaskId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param regionPersonId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c027 - 
 * @param c027Times - 当C027的值为2时必填，反之不填
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c09 - 
 * @param c10 - 
 * @param c101 - 当C10的值不为1时必填，反之不填
 * @param c101OtherDescription - 当C101的包含选项6时必填，反之不填
 * @param c11 - 
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 当D05的值为1时必填，反之不填
 * @param d07 - 当D05的值为1时必填，反之不填
 * @param d08 - 当D05的值为1时必填，反之不填
 * @param d09 - 
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f03Time - 当F03的值为2时必填，反之不填
 * @param f04 - 
 * @param f05 - 
 * @param g01 - 
 * @param g02 - 当G01的值为1时必填，反之不填
 * @param g02OtherDescription - 当G02包括选项5时必填，反之不填
 * @param g03 - 当G01的值为1时必填，反之不填
 * @param g03OtherDescription - 当G03包括选项6时必填，反之不填
 * @param g04 - 当G03不包括选项7时必填，反之不填
 * @param g04AgeOfFirst - 当G04的值不为3时必填，反之不填
 * @param g05 - 当G03不包括选项7时必填，反之不填
 * @param g050 - 你在配制(第一幅)的眼镜前，曾接受过以下哪些检查？
 * @param h01 - 
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填
 * @param h02 - 当H01的值为2时必填，反之不填
 * @param h03 - 当H01的值为2时必填，反之不填
 * @param h04 - 当H01的值为2时必填，反之不填
 * @param h05 - 当H01的值为2时必填，反之不填
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i04 - 当I03的值不为1时必填，反之不填
 * @param i05 - 当I03的值不为1时必填，反之不填
 * @param i06 - 当I03的值不为1时必填，反之不填
 * @param i07 - 当I03的值不为1时必填，反之不填
 * @param i08 - 当I03的值不为1时必填，反之不填
 * @param i09 - 当I03的值不为1时必填，反之不填
 * @param j01 - 
 * @param j02 - 
 * @param j03 - 
 * @param j04 - 
 * @param j05 - 
 * @param j06 - 
 * @param j07 - 
 * @param j08 - 
 * @param j09 - 
 * @param j10 - 
 * @param j11 - 
 * @param j12 - 
 * @param j13 - 
 * @param j14 - 
 * @param j15 - 
 * @param j16 - 
 * @param j17 - 
 * @param j18 - 
 * @param j19 - 
 * @param j20 - 
 * @param k01 - 
 * @param k011 - 
 * @param k02 - 
 * @param k021 - 
 * @param k03 - 
 * @param k04 - 
 * @param k05 - 
 * @param k06 - 
 * @param k07 - 
 * @param k08 - 
 * @param k09 - 
 * @param l01 - 
 * @param l02 - 
 * @param l03 - 
 * @param l03Week - 
 * @param l04 - 
 * @param l05 - 
 * @param l06 - 
 * @param l07 - 
 * @param l08 - 
 */
export const bizUserInvestigationTaskSaveUshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/save-ushiq'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['investigationTaskId'] !== undefined) {
    form['investigationTaskId'] = parameters['investigationTaskId']
  }
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c027'] !== undefined) {
    form['c027'] = parameters['c027']
  }
  if (parameters['c027'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c027'))
  }
  if (parameters['c027Times'] !== undefined) {
    form['c027Times'] = parameters['c027Times']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c09'))
  }
  if (parameters['c10'] !== undefined) {
    form['c10'] = parameters['c10']
  }
  if (parameters['c10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c10'))
  }
  if (parameters['c101'] !== undefined) {
    form['c101'] = parameters['c101']
  }
  if (parameters['c101OtherDescription'] !== undefined) {
    form['c101OtherDescription'] = parameters['c101OtherDescription']
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c11'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02OtherDescription'] !== undefined) {
    form['g02OtherDescription'] = parameters['g02OtherDescription']
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03OtherDescription'] !== undefined) {
    form['g03OtherDescription'] = parameters['g03OtherDescription']
  }
  if (parameters['g04'] !== undefined) {
    form['g04'] = parameters['g04']
  }
  if (parameters['g04AgeOfFirst'] !== undefined) {
    form['g04AgeOfFirst'] = parameters['g04AgeOfFirst']
  }
  if (parameters['g05'] !== undefined) {
    form['g05'] = parameters['g05']
  }
  if (parameters['g050'] !== undefined) {
    form['g050'] = parameters['g050']
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['k01'] !== undefined) {
    form['k01'] = parameters['k01']
  }
  if (parameters['k01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k01'))
  }
  if (parameters['k011'] !== undefined) {
    form['k011'] = parameters['k011']
  }
  if (parameters['k02'] !== undefined) {
    form['k02'] = parameters['k02']
  }
  if (parameters['k02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k02'))
  }
  if (parameters['k021'] !== undefined) {
    form['k021'] = parameters['k021']
  }
  if (parameters['k03'] !== undefined) {
    form['k03'] = parameters['k03']
  }
  if (parameters['k03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k03'))
  }
  if (parameters['k04'] !== undefined) {
    form['k04'] = parameters['k04']
  }
  if (parameters['k04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k04'))
  }
  if (parameters['k05'] !== undefined) {
    form['k05'] = parameters['k05']
  }
  if (parameters['k05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k05'))
  }
  if (parameters['k06'] !== undefined) {
    form['k06'] = parameters['k06']
  }
  if (parameters['k06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k06'))
  }
  if (parameters['k07'] !== undefined) {
    form['k07'] = parameters['k07']
  }
  if (parameters['k07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k07'))
  }
  if (parameters['k08'] !== undefined) {
    form['k08'] = parameters['k08']
  }
  if (parameters['k08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k08'))
  }
  if (parameters['k09'] !== undefined) {
    form['k09'] = parameters['k09']
  }
  if (parameters['k09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k09'))
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskSaveUshiqFormData = function(formData) {
  const url = bizUserInvestigationTaskSaveUshiq_RAW_URL()
  const method = bizUserInvestigationTaskSaveUshiq_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskSaveUshiq_RAW_URL = function() {
  return '/business/user-investigation-task/save-ushiq'
}
export const bizUserInvestigationTaskSaveUshiq_TYPE = function() {
  return 'post'
}
export const bizUserInvestigationTaskSaveUshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/save-ushiq'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户完成调查任务列表
 * request: bizUserInvestigationTaskListFinish
 * formData: bizUserInvestigationTaskListFinishFormData
 * url: bizUserInvestigationTaskListFinish_URL
 * method: bizUserInvestigationTaskListFinish_TYPE
 * raw_url: bizUserInvestigationTaskListFinish_RAW_URL
 * @param dateMillis - 
 */
export const bizUserInvestigationTaskListFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/user/finish'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dateMillis'] !== undefined) {
    queryParameters['dateMillis'] = parameters['dateMillis']
  }
  if (parameters['dateMillis'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateMillis'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskListFinishFormData = function(formData) {
  const url = bizUserInvestigationTaskListFinish_RAW_URL()
  const method = bizUserInvestigationTaskListFinish_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskListFinish_RAW_URL = function() {
  return '/business/user-investigation-task/user/finish'
}
export const bizUserInvestigationTaskListFinish_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskListFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/user/finish'
  if (parameters['dateMillis'] !== undefined) {
    queryParameters['dateMillis'] = parameters['dateMillis']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户待完成调查任务列表
 * request: bizUserInvestigationTaskListWaitFinish
 * formData: bizUserInvestigationTaskListWaitFinishFormData
 * url: bizUserInvestigationTaskListWaitFinish_URL
 * method: bizUserInvestigationTaskListWaitFinish_TYPE
 * raw_url: bizUserInvestigationTaskListWaitFinish_RAW_URL
 */
export const bizUserInvestigationTaskListWaitFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/user/wait-finish'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskListWaitFinishFormData = function(formData) {
  const url = bizUserInvestigationTaskListWaitFinish_RAW_URL()
  const method = bizUserInvestigationTaskListWaitFinish_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskListWaitFinish_RAW_URL = function() {
  return '/business/user-investigation-task/user/wait-finish'
}
export const bizUserInvestigationTaskListWaitFinish_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskListWaitFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/user/wait-finish'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户待完成调查任务数
 * request: bizUserInvestigationTaskWaitFinishNum
 * formData: bizUserInvestigationTaskWaitFinishNumFormData
 * url: bizUserInvestigationTaskWaitFinishNum_URL
 * method: bizUserInvestigationTaskWaitFinishNum_TYPE
 * raw_url: bizUserInvestigationTaskWaitFinishNum_RAW_URL
 */
export const bizUserInvestigationTaskWaitFinishNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-investigation-task/user/wait-finish-num'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserInvestigationTaskWaitFinishNumFormData = function(formData) {
  const url = bizUserInvestigationTaskWaitFinishNum_RAW_URL()
  const method = bizUserInvestigationTaskWaitFinishNum_TYPE()
  return axios[method](url, formData)
}
export const bizUserInvestigationTaskWaitFinishNum_RAW_URL = function() {
  return '/business/user-investigation-task/user/wait-finish-num'
}
export const bizUserInvestigationTaskWaitFinishNum_TYPE = function() {
  return 'get'
}
export const bizUserInvestigationTaskWaitFinishNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-investigation-task/user/wait-finish-num'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户受检人随访列表
 * request: bizUserPersonVisitList
 * formData: bizUserPersonVisitListFormData
 * url: bizUserPersonVisitList_URL
 * method: bizUserPersonVisitList_TYPE
 * raw_url: bizUserPersonVisitList_RAW_URL
 */
export const bizUserPersonVisitList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person-visit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonVisitListFormData = function(formData) {
  const url = bizUserPersonVisitList_RAW_URL()
  const method = bizUserPersonVisitList_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonVisitList_RAW_URL = function() {
  return '/business/user-person-visit'
}
export const bizUserPersonVisitList_TYPE = function() {
  return 'get'
}
export const bizUserPersonVisitList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person-visit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户获取受检人访问
 * request: bizUserPersonVisitFind
 * formData: bizUserPersonVisitFindFormData
 * url: bizUserPersonVisitFind_URL
 * method: bizUserPersonVisitFind_TYPE
 * raw_url: bizUserPersonVisitFind_RAW_URL
 * @param personVisitId - personVisitId
 * @param personId - 受检人ID
 */
export const bizUserPersonVisitFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person-visit/{personVisitId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personVisitId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personVisitId'))
  }
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonVisitFindFormData = function(formData) {
  const url = bizUserPersonVisitFind_RAW_URL()
  const method = bizUserPersonVisitFind_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonVisitFind_RAW_URL = function() {
  return '/business/user-person-visit/{personVisitId}'
}
export const bizUserPersonVisitFind_TYPE = function() {
  return 'get'
}
export const bizUserPersonVisitFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person-visit/{personVisitId}'
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户受检人随访有阅读
 * request: bizUserPersonVisitHasRead
 * formData: bizUserPersonVisitHasReadFormData
 * url: bizUserPersonVisitHasRead_URL
 * method: bizUserPersonVisitHasRead_TYPE
 * raw_url: bizUserPersonVisitHasRead_RAW_URL
 * @param personVisitId - personVisitId
 * @param personId - 受检人ID
 */
export const bizUserPersonVisitHasRead = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person-visit/{personVisitId}/has-read'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personVisitId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personVisitId'))
  }
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonVisitHasReadFormData = function(formData) {
  const url = bizUserPersonVisitHasRead_RAW_URL()
  const method = bizUserPersonVisitHasRead_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonVisitHasRead_RAW_URL = function() {
  return '/business/user-person-visit/{personVisitId}/has-read'
}
export const bizUserPersonVisitHasRead_TYPE = function() {
  return 'put'
}
export const bizUserPersonVisitHasRead_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person-visit/{personVisitId}/has-read'
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 绑定受检人
 * request: bizUserPersonBindPerson
 * formData: bizUserPersonBindPersonFormData
 * url: bizUserPersonBindPerson_URL
 * method: bizUserPersonBindPerson_TYPE
 * raw_url: bizUserPersonBindPerson_RAW_URL
 * @param personId - 
 * @param randomCode - 
 */
export const bizUserPersonBindPerson = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person/bind-person'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters['randomCode'] !== undefined) {
    queryParameters['randomCode'] = parameters['randomCode']
  }
  if (parameters['randomCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: randomCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonBindPersonFormData = function(formData) {
  const url = bizUserPersonBindPerson_RAW_URL()
  const method = bizUserPersonBindPerson_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonBindPerson_RAW_URL = function() {
  return '/business/user-person/bind-person'
}
export const bizUserPersonBindPerson_TYPE = function() {
  return 'post'
}
export const bizUserPersonBindPerson_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person/bind-person'
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['randomCode'] !== undefined) {
    queryParameters['randomCode'] = parameters['randomCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户受检人列表
 * request: bizUserPersonList
 * formData: bizUserPersonListFormData
 * url: bizUserPersonList_URL
 * method: bizUserPersonList_TYPE
 * raw_url: bizUserPersonList_RAW_URL
 */
export const bizUserPersonList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person/by-user'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonListFormData = function(formData) {
  const url = bizUserPersonList_RAW_URL()
  const method = bizUserPersonList_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonList_RAW_URL = function() {
  return '/business/user-person/by-user'
}
export const bizUserPersonList_TYPE = function() {
  return 'get'
}
export const bizUserPersonList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person/by-user'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 受检人档案
 * request: bizUserPersonListArchive
 * formData: bizUserPersonListArchiveFormData
 * url: bizUserPersonListArchive_URL
 * method: bizUserPersonListArchive_TYPE
 * raw_url: bizUserPersonListArchive_RAW_URL
 * @param personId - personId
 */
export const bizUserPersonListArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user-person/person/{personId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserPersonListArchiveFormData = function(formData) {
  const url = bizUserPersonListArchive_RAW_URL()
  const method = bizUserPersonListArchive_TYPE()
  return axios[method](url, formData)
}
export const bizUserPersonListArchive_RAW_URL = function() {
  return '/business/user-person/person/{personId}'
}
export const bizUserPersonListArchive_TYPE = function() {
  return 'get'
}
export const bizUserPersonListArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user-person/person/{personId}'
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信登录
 * request: bizUserJoinByWx
 * formData: bizUserJoinByWxFormData
 * url: bizUserJoinByWx_URL
 * method: bizUserJoinByWx_TYPE
 * raw_url: bizUserJoinByWx_RAW_URL
 * @param returnUrl - returnUrl
 * @param code - code
 */
export const bizUserJoinByWx = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user/join-by-wx'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['returnUrl'] !== undefined) {
    queryParameters['returnUrl'] = parameters['returnUrl']
  }
  if (parameters['returnUrl'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: returnUrl'))
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserJoinByWxFormData = function(formData) {
  const url = bizUserJoinByWx_RAW_URL()
  const method = bizUserJoinByWx_TYPE()
  return axios[method](url, formData)
}
export const bizUserJoinByWx_RAW_URL = function() {
  return '/business/user/join-by-wx'
}
export const bizUserJoinByWx_TYPE = function() {
  return 'get'
}
export const bizUserJoinByWx_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user/join-by-wx'
  if (parameters['returnUrl'] !== undefined) {
    queryParameters['returnUrl'] = parameters['returnUrl']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过Ticket获取Token
 * request: bizUserLoginByTicket
 * formData: bizUserLoginByTicketFormData
 * url: bizUserLoginByTicket_URL
 * method: bizUserLoginByTicket_TYPE
 * raw_url: bizUserLoginByTicket_RAW_URL
 * @param ticket - ticket
 */
export const bizUserLoginByTicket = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user/login-by-ticket'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['ticket'] !== undefined) {
    queryParameters['ticket'] = parameters['ticket']
  }
  if (parameters['ticket'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ticket'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUserLoginByTicketFormData = function(formData) {
  const url = bizUserLoginByTicket_RAW_URL()
  const method = bizUserLoginByTicket_TYPE()
  return axios[method](url, formData)
}
export const bizUserLoginByTicket_RAW_URL = function() {
  return '/business/user/login-by-ticket'
}
export const bizUserLoginByTicket_TYPE = function() {
  return 'get'
}
export const bizUserLoginByTicket_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user/login-by-ticket'
  if (parameters['ticket'] !== undefined) {
    queryParameters['ticket'] = parameters['ticket']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信小程序登录
 * request: BizAppletsLoginWx
 * formData: BizAppletsLoginWxFormData
 * url: BizAppletsLoginWx_URL
 * method: BizAppletsLoginWx_TYPE
 * raw_url: BizAppletsLoginWx_RAW_URL
 * @param encryptedData - 手机号码信息加密数据
 * @param iv - 解密偏移量
 * @param code - 微信code
 */
export const BizAppletsLoginWx = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/user/wx-applets-login'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['encryptedData'] !== undefined) {
    queryParameters['encryptedData'] = parameters['encryptedData']
  }
  if (parameters['encryptedData'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: encryptedData'))
  }
  if (parameters['iv'] !== undefined) {
    queryParameters['iv'] = parameters['iv']
  }
  if (parameters['iv'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: iv'))
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizAppletsLoginWxFormData = function(formData) {
  const url = BizAppletsLoginWx_RAW_URL()
  const method = BizAppletsLoginWx_TYPE()
  return axios[method](url, formData)
}
export const BizAppletsLoginWx_RAW_URL = function() {
  return '/business/user/wx-applets-login'
}
export const BizAppletsLoginWx_TYPE = function() {
  return 'get'
}
export const BizAppletsLoginWx_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/user/wx-applets-login'
  if (parameters['encryptedData'] !== undefined) {
    queryParameters['encryptedData'] = parameters['encryptedData']
  }
  if (parameters['iv'] !== undefined) {
    queryParameters['iv'] = parameters['iv']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看大学学生健康状况及影响因素调查表
 * request: bizUshiqFindByStudent
 * formData: bizUshiqFindByStudentFormData
 * url: bizUshiqFindByStudent_URL
 * method: bizUshiqFindByStudent_TYPE
 * raw_url: bizUshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 */
export const bizUshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/ushiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const bizUshiqFindByStudentFormData = function(formData) {
  const url = bizUshiqFindByStudent_RAW_URL()
  const method = bizUshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const bizUshiqFindByStudent_RAW_URL = function() {
  return '/business/ushiq/by-student/{studentId}'
}
export const bizUshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const bizUshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/ushiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查表
 * request: bizUshiqSave
 * formData: bizUshiqSaveFormData
 * url: bizUshiqSave_URL
 * method: bizUshiqSave_TYPE
 * raw_url: bizUshiqSave_RAW_URL
 * @param studentId - studentId
 * @param screeningPlanId - 
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c061 - 
 * @param c062 - 
 * @param c063 - 
 * @param c064 - 
 * @param c07 - 
 * @param c08 - 
 * @param c121 - 在公共网页上受到他人的辱骂？
 * @param c122 - 曾收到侮辱性的信息？
 * @param c123 - 有人将令我尴尬的照片或视频上传到网络上？
 * @param c124 - 某人用假的身份给我发送令我难堪的信息？
 * @param c125 - 有人在网络上散布我的谣言(如社交网站等)？
 * @param c126 - 曾有人未经我的允许转发或公开我的私人信息？
 * @param c127 - 曾经受到威胁性或恐吓性的信息？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 当D05的值为1时必填，反之不填
 * @param d07 - 当D05的值为1时必填，反之不填
 * @param d08 - 当D05的值为1时必填，反之不填
 * @param d09 - 
 * @param d09Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d10 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d11 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d111 - 每次服用止咳药水的量是多少？
 * @param d12 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f03Time - 当F03的值为2时必填，反之不填
 * @param f04 - 
 * @param f05 - 
 * @param f06 - 
 * @param f06Time - 当F06的值为2时必填，反之不填
 * @param g01 - 
 * @param h01 - 
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填
 * @param h02 - 当H01的值为2时必填，反之不填，你平均每天使用耳机分钟数 --> 时间选项
 * @param h03 - 当H01的值为2时必填，反之不填
 * @param h04 - 当H01的值为2时必填，反之不填
 * @param h05 - 当H01的值为2时必填，反之不填
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i04 - 当I03的值不为1时必填，反之不填
 * @param i05 - 当I03的值不为1时必填，反之不填
 * @param i06 - 当I03的值不为1时必填，反之不填
 * @param i07 - 当I03的值不为1时必填，反之不填
 * @param i08 - 当I03的值不为1时必填，反之不填
 * @param i09 - 当I03的值不为1时必填，反之不填
 * @param j01 - 
 * @param j02 - 
 * @param j03 - 
 * @param j04 - 
 * @param j05 - 
 * @param j06 - 
 * @param j07 - 
 * @param j08 - 
 * @param j09 - 
 * @param j10 - 
 * @param j11 - 
 * @param j12 - 
 * @param j13 - 
 * @param j14 - 
 * @param j15 - 
 * @param j16 - 
 * @param j17 - 
 * @param j18 - 
 * @param j19 - 
 * @param j20 - 
 * @param j21 - 感觉紧张，焦虑或急切
 * @param j22 - 不能够停止或控制担忧
 * @param j23 - 对各种恶样的事情担忧过多
 * @param j24 - 很难放松下来
 * @param j25 - 由于不安而无法静坐
 * @param j26 - 变得容易烦恼或急躁
 * @param j27 - 感到似乎将有可怕的事情发生而害怕
 * @param j28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param j281 - 你寻求了什么形式的帮助
 * @param j282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param l01 - 
 * @param l02 - 
 * @param l03 - 
 * @param l03Week - 
 * @param l04 - 
 * @param l05 - 
 * @param l06 - 
 * @param l07 - 
 * @param l08 - 
 */
export const bizUshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/ushiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c061'] !== undefined) {
    form['c061'] = parameters['c061']
  }
  if (parameters['c061'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c061'))
  }
  if (parameters['c062'] !== undefined) {
    form['c062'] = parameters['c062']
  }
  if (parameters['c062'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c062'))
  }
  if (parameters['c063'] !== undefined) {
    form['c063'] = parameters['c063']
  }
  if (parameters['c063'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c063'))
  }
  if (parameters['c064'] !== undefined) {
    form['c064'] = parameters['c064']
  }
  if (parameters['c064'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c064'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c121'))
  }
  if (parameters['c122'] !== undefined) {
    form['c122'] = parameters['c122']
  }
  if (parameters['c122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c122'))
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c123'))
  }
  if (parameters['c124'] !== undefined) {
    form['c124'] = parameters['c124']
  }
  if (parameters['c124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c124'))
  }
  if (parameters['c125'] !== undefined) {
    form['c125'] = parameters['c125']
  }
  if (parameters['c125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c125'))
  }
  if (parameters['c126'] !== undefined) {
    form['c126'] = parameters['c126']
  }
  if (parameters['c126'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c126'))
  }
  if (parameters['c127'] !== undefined) {
    form['c127'] = parameters['c127']
  }
  if (parameters['c127'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c127'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['d09Description'] !== undefined) {
    form['d09Description'] = parameters['d09Description']
  }
  if (parameters['d10'] !== undefined) {
    form['d10'] = parameters['d10']
  }
  if (parameters['d10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d10'))
  }
  if (parameters['d11'] !== undefined) {
    form['d11'] = parameters['d11']
  }
  if (parameters['d11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d11'))
  }
  if (parameters['d111'] !== undefined) {
    form['d111'] = parameters['d111']
  }
  if (parameters['d12'] !== undefined) {
    form['d12'] = parameters['d12']
  }
  if (parameters['d12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d12'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f06Time'] !== undefined) {
    form['f06Time'] = parameters['f06Time']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['j21'] !== undefined) {
    form['j21'] = parameters['j21']
  }
  if (parameters['j21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j21'))
  }
  if (parameters['j22'] !== undefined) {
    form['j22'] = parameters['j22']
  }
  if (parameters['j22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j22'))
  }
  if (parameters['j23'] !== undefined) {
    form['j23'] = parameters['j23']
  }
  if (parameters['j23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j23'))
  }
  if (parameters['j24'] !== undefined) {
    form['j24'] = parameters['j24']
  }
  if (parameters['j24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j24'))
  }
  if (parameters['j25'] !== undefined) {
    form['j25'] = parameters['j25']
  }
  if (parameters['j25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j25'))
  }
  if (parameters['j26'] !== undefined) {
    form['j26'] = parameters['j26']
  }
  if (parameters['j26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j26'))
  }
  if (parameters['j27'] !== undefined) {
    form['j27'] = parameters['j27']
  }
  if (parameters['j27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j27'))
  }
  if (parameters['j28'] !== undefined) {
    form['j28'] = parameters['j28']
  }
  if (parameters['j28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j28'))
  }
  if (parameters['j281'] !== undefined) {
    form['j281'] = parameters['j281']
  }
  if (parameters['j282'] !== undefined) {
    form['j282'] = parameters['j282']
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const bizUshiqSaveFormData = function(formData) {
  const url = bizUshiqSave_RAW_URL()
  const method = bizUshiqSave_TYPE()
  return axios[method](url, formData)
}
export const bizUshiqSave_RAW_URL = function() {
  return '/business/ushiq/save/{studentId}'
}
export const bizUshiqSave_TYPE = function() {
  return 'post'
}
export const bizUshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/ushiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户列表
 * request: BizWxAppletsTesterList
 * formData: BizWxAppletsTesterListFormData
 * url: BizWxAppletsTesterList_URL
 * method: BizWxAppletsTesterList_TYPE
 * raw_url: BizWxAppletsTesterList_RAW_URL
 */
export const BizWxAppletsTesterList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsTesterListFormData = function(formData) {
  const url = BizWxAppletsTesterList_RAW_URL()
  const method = BizWxAppletsTesterList_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsTesterList_RAW_URL = function() {
  return '/business/wx_applets_tester'
}
export const BizWxAppletsTesterList_TYPE = function() {
  return 'get'
}
export const BizWxAppletsTesterList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增测试用户
 * request: BizWxAppletsTesterAdd
 * formData: BizWxAppletsTesterAddFormData
 * url: BizWxAppletsTesterAdd_URL
 * method: BizWxAppletsTesterAdd_TYPE
 * raw_url: BizWxAppletsTesterAdd_RAW_URL
 * @param id - id(修改必传)
 * @param name - 姓名
 * @param sex - 性别
 * @param birthday - 生日
 * @param schoolName - 学校名称
 * @param className - 班级名称
 * @param grade - 年级
 */
export const BizWxAppletsTesterAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birthday'] !== undefined) {
    form['birthday'] = parameters['birthday']
  }
  if (parameters['birthday'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birthday'))
  }
  if (parameters['schoolName'] !== undefined) {
    form['schoolName'] = parameters['schoolName']
  }
  if (parameters['schoolName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolName'))
  }
  if (parameters['className'] !== undefined) {
    form['className'] = parameters['className']
  }
  if (parameters['className'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: className'))
  }
  if (parameters['grade'] !== undefined) {
    form['grade'] = parameters['grade']
  }
  if (parameters['grade'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: grade'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsTesterAddFormData = function(formData) {
  const url = BizWxAppletsTesterAdd_RAW_URL()
  const method = BizWxAppletsTesterAdd_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsTesterAdd_RAW_URL = function() {
  return '/business/wx_applets_tester'
}
export const BizWxAppletsTesterAdd_TYPE = function() {
  return 'post'
}
export const BizWxAppletsTesterAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户详情
 * request: BizWxAppletsTesterDetails
 * formData: BizWxAppletsTesterDetailsFormData
 * url: BizWxAppletsTesterDetails_URL
 * method: BizWxAppletsTesterDetails_TYPE
 * raw_url: BizWxAppletsTesterDetails_RAW_URL
 * @param id - id
 */
export const BizWxAppletsTesterDetails = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_tester/{id}/details'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsTesterDetailsFormData = function(formData) {
  const url = BizWxAppletsTesterDetails_RAW_URL()
  const method = BizWxAppletsTesterDetails_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsTesterDetails_RAW_URL = function() {
  return '/business/wx_applets_tester/{id}/details'
}
export const BizWxAppletsTesterDetails_TYPE = function() {
  return 'get'
}
export const BizWxAppletsTesterDetails_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_tester/{id}/details'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增自测数据
 * request: BizWxAppletsVisionReportAdd
 * formData: BizWxAppletsVisionReportAddFormData
 * url: BizWxAppletsVisionReportAdd_URL
 * method: BizWxAppletsVisionReportAdd_TYPE
 * raw_url: BizWxAppletsVisionReportAdd_RAW_URL
 * @param testerId - 检测用户
 * @param distance - 距离
 * @param brightness - 检测亮度
 * @param type - 检测类型
 * @param leftVision - 左眼
 * @param rightVision - 右眼
 */
export const BizWxAppletsVisionReportAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['testerId'] !== undefined) {
    form['testerId'] = parameters['testerId']
  }
  if (parameters['testerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: testerId'))
  }
  if (parameters['distance'] !== undefined) {
    form['distance'] = parameters['distance']
  }
  if (parameters['distance'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: distance'))
  }
  if (parameters['brightness'] !== undefined) {
    form['brightness'] = parameters['brightness']
  }
  if (parameters['brightness'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: brightness'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['leftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: leftVision'))
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['rightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rightVision'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsVisionReportAddFormData = function(formData) {
  const url = BizWxAppletsVisionReportAdd_RAW_URL()
  const method = BizWxAppletsVisionReportAdd_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsVisionReportAdd_RAW_URL = function() {
  return '/business/wx_applets_vision_report'
}
export const BizWxAppletsVisionReportAdd_TYPE = function() {
  return 'post'
}
export const BizWxAppletsVisionReportAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 自测数据详情
 * request: BizWxAppletsVisionReportDetails
 * formData: BizWxAppletsVisionReportDetailsFormData
 * url: BizWxAppletsVisionReportDetails_URL
 * method: BizWxAppletsVisionReportDetails_TYPE
 * raw_url: BizWxAppletsVisionReportDetails_RAW_URL
 * @param id - id
 */
export const BizWxAppletsVisionReportDetails = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report/{id}/details'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsVisionReportDetailsFormData = function(formData) {
  const url = BizWxAppletsVisionReportDetails_RAW_URL()
  const method = BizWxAppletsVisionReportDetails_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsVisionReportDetails_RAW_URL = function() {
  return '/business/wx_applets_vision_report/{id}/details'
}
export const BizWxAppletsVisionReportDetails_TYPE = function() {
  return 'get'
}
export const BizWxAppletsVisionReportDetails_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report/{id}/details'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户数据列表
 * request: BizWxAppletsVisionReportList
 * formData: BizWxAppletsVisionReportListFormData
 * url: BizWxAppletsVisionReportList_URL
 * method: BizWxAppletsVisionReportList_TYPE
 * raw_url: BizWxAppletsVisionReportList_RAW_URL
 * @param testerId - testerId
 */
export const BizWxAppletsVisionReportList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/business/wx_applets_vision_report/{testerId}/list'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters['testerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: testerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const BizWxAppletsVisionReportListFormData = function(formData) {
  const url = BizWxAppletsVisionReportList_RAW_URL()
  const method = BizWxAppletsVisionReportList_TYPE()
  return axios[method](url, formData)
}
export const BizWxAppletsVisionReportList_RAW_URL = function() {
  return '/business/wx_applets_vision_report/{testerId}/list'
}
export const BizWxAppletsVisionReportList_TYPE = function() {
  return 'get'
}
export const BizWxAppletsVisionReportList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/business/wx_applets_vision_report/{testerId}/list'
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: cmAdministratorAdd
 * formData: cmAdministratorAddFormData
 * url: cmAdministratorAdd_URL
 * method: cmAdministratorAdd_TYPE
 * raw_url: cmAdministratorAdd_RAW_URL
 * @param name - 
 * @param mobile - 
 * @param email - 
 */
export const cmAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorAddFormData = function(formData) {
  const url = cmAdministratorAdd_RAW_URL()
  const method = cmAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorAdd_RAW_URL = function() {
  return '/common/administrator'
}
export const cmAdministratorAdd_TYPE = function() {
  return 'post'
}
export const cmAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查询管理员
 * request: cmAdministratorFindByMobile
 * formData: cmAdministratorFindByMobileFormData
 * url: cmAdministratorFindByMobile_URL
 * method: cmAdministratorFindByMobile_TYPE
 * raw_url: cmAdministratorFindByMobile_RAW_URL
 * @param mobile - 手机号
 */
export const cmAdministratorFindByMobile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/by-mobile'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorFindByMobileFormData = function(formData) {
  const url = cmAdministratorFindByMobile_RAW_URL()
  const method = cmAdministratorFindByMobile_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorFindByMobile_RAW_URL = function() {
  return '/common/administrator/by-mobile'
}
export const cmAdministratorFindByMobile_TYPE = function() {
  return 'get'
}
export const cmAdministratorFindByMobile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/by-mobile'
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 政府部门全部管理员选择列表
 * request: cmAdministratorGovernmentSectorAllChoiceList
 * formData: cmAdministratorGovernmentSectorAllChoiceListFormData
 * url: cmAdministratorGovernmentSectorAllChoiceList_URL
 * method: cmAdministratorGovernmentSectorAllChoiceList_TYPE
 * raw_url: cmAdministratorGovernmentSectorAllChoiceList_RAW_URL
 * @param businessObjectId - 部门ID
 * @param regionCode - 部门地区编码
 * @param governmentSectorType - 政府类型
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const cmAdministratorGovernmentSectorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/government-sector-all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['governmentSectorType'] !== undefined) {
    queryParameters['governmentSectorType'] = parameters['governmentSectorType']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorGovernmentSectorAllChoiceListFormData = function(formData) {
  const url = cmAdministratorGovernmentSectorAllChoiceList_RAW_URL()
  const method = cmAdministratorGovernmentSectorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorGovernmentSectorAllChoiceList_RAW_URL = function() {
  return '/common/administrator/government-sector-all-choice'
}
export const cmAdministratorGovernmentSectorAllChoiceList_TYPE = function() {
  return 'get'
}
export const cmAdministratorGovernmentSectorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/government-sector-all-choice'
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['governmentSectorType'] !== undefined) {
    queryParameters['governmentSectorType'] = parameters['governmentSectorType']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更改当前账号的手机号邮箱
 * request: cmAdministratorUpdateInfo
 * formData: cmAdministratorUpdateInfoFormData
 * url: cmAdministratorUpdateInfo_URL
 * method: cmAdministratorUpdateInfo_TYPE
 * raw_url: cmAdministratorUpdateInfo_RAW_URL
 * @param name - 
 * @param email - 
 * @param mobile - 
 * @param pwd - 
 */
export const cmAdministratorUpdateInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['pwd'] !== undefined) {
    form['pwd'] = parameters['pwd']
  }
  if (parameters['pwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: pwd'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorUpdateInfoFormData = function(formData) {
  const url = cmAdministratorUpdateInfo_RAW_URL()
  const method = cmAdministratorUpdateInfo_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorUpdateInfo_RAW_URL = function() {
  return '/common/administrator/info'
}
export const cmAdministratorUpdateInfo_TYPE = function() {
  return 'put'
}
export const cmAdministratorUpdateInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构全部管理员选择列表
 * request: cmAdministratorMedicalInstitutionAllChoiceList
 * formData: cmAdministratorMedicalInstitutionAllChoiceListFormData
 * url: cmAdministratorMedicalInstitutionAllChoiceList_URL
 * method: cmAdministratorMedicalInstitutionAllChoiceList_TYPE
 * raw_url: cmAdministratorMedicalInstitutionAllChoiceList_RAW_URL
 * @param businessObjectId - 医疗机构ID
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const cmAdministratorMedicalInstitutionAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/medical-institution-all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['businessObjectId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: businessObjectId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorMedicalInstitutionAllChoiceListFormData = function(formData) {
  const url = cmAdministratorMedicalInstitutionAllChoiceList_RAW_URL()
  const method = cmAdministratorMedicalInstitutionAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorMedicalInstitutionAllChoiceList_RAW_URL = function() {
  return '/common/administrator/medical-institution-all-choice'
}
export const cmAdministratorMedicalInstitutionAllChoiceList_TYPE = function() {
  return 'get'
}
export const cmAdministratorMedicalInstitutionAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/medical-institution-all-choice'
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校全部管理员列表
 * request: cmAdministratorSchoolAllChoiceList
 * formData: cmAdministratorSchoolAllChoiceListFormData
 * url: cmAdministratorSchoolAllChoiceList_URL
 * method: cmAdministratorSchoolAllChoiceList_TYPE
 * raw_url: cmAdministratorSchoolAllChoiceList_RAW_URL
 * @param businessObjectId - 学校ID
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const cmAdministratorSchoolAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/school-all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['businessObjectId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: businessObjectId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorSchoolAllChoiceListFormData = function(formData) {
  const url = cmAdministratorSchoolAllChoiceList_RAW_URL()
  const method = cmAdministratorSchoolAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorSchoolAllChoiceList_RAW_URL = function() {
  return '/common/administrator/school-all-choice'
}
export const cmAdministratorSchoolAllChoiceList_TYPE = function() {
  return 'get'
}
export const cmAdministratorSchoolAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/school-all-choice'
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队全部管理员选择列表
 * request: cmAdministratorScreeningTeamAllChoiceList
 * formData: cmAdministratorScreeningTeamAllChoiceListFormData
 * url: cmAdministratorScreeningTeamAllChoiceList_URL
 * method: cmAdministratorScreeningTeamAllChoiceList_TYPE
 * raw_url: cmAdministratorScreeningTeamAllChoiceList_RAW_URL
 * @param businessObjectId - 筛查团队ID
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const cmAdministratorScreeningTeamAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/administrator/screening-team-all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['businessObjectId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: businessObjectId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAdministratorScreeningTeamAllChoiceListFormData = function(formData) {
  const url = cmAdministratorScreeningTeamAllChoiceList_RAW_URL()
  const method = cmAdministratorScreeningTeamAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmAdministratorScreeningTeamAllChoiceList_RAW_URL = function() {
  return '/common/administrator/screening-team-all-choice'
}
export const cmAdministratorScreeningTeamAllChoiceList_TYPE = function() {
  return 'get'
}
export const cmAdministratorScreeningTeamAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/administrator/screening-team-all-choice'
  if (parameters['businessObjectId'] !== undefined) {
    queryParameters['businessObjectId'] = parameters['businessObjectId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 版本列表
 * request: cmAppVersionList
 * formData: cmAppVersionListFormData
 * url: cmAppVersionList_URL
 * method: cmAppVersionList_TYPE
 * raw_url: cmAppVersionList_RAW_URL
 * @param appType - 
 */
export const cmAppVersionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app-version'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters['appType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppVersionListFormData = function(formData) {
  const url = cmAppVersionList_RAW_URL()
  const method = cmAppVersionList_TYPE()
  return axios[method](url, formData)
}
export const cmAppVersionList_RAW_URL = function() {
  return '/common/app-version'
}
export const cmAppVersionList_TYPE = function() {
  return 'get'
}
export const cmAppVersionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app-version'
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 唯一码获取应用版本
 * request: cmAppVersionFindByUniqueCode
 * formData: cmAppVersionFindByUniqueCodeFormData
 * url: cmAppVersionFindByUniqueCode_URL
 * method: cmAppVersionFindByUniqueCode_TYPE
 * raw_url: cmAppVersionFindByUniqueCode_RAW_URL
 * @param uniqueCode - 
 */
export const cmAppVersionFindByUniqueCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app-version/by-uniqueCode'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['uniqueCode'] !== undefined) {
    queryParameters['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters['uniqueCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: uniqueCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppVersionFindByUniqueCodeFormData = function(formData) {
  const url = cmAppVersionFindByUniqueCode_RAW_URL()
  const method = cmAppVersionFindByUniqueCode_TYPE()
  return axios[method](url, formData)
}
export const cmAppVersionFindByUniqueCode_RAW_URL = function() {
  return '/common/app-version/by-uniqueCode'
}
export const cmAppVersionFindByUniqueCode_TYPE = function() {
  return 'get'
}
export const cmAppVersionFindByUniqueCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app-version/by-uniqueCode'
  if (parameters['uniqueCode'] !== undefined) {
    queryParameters['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取最新应用版本
 * request: cmAppVersionFindLast
 * formData: cmAppVersionFindLastFormData
 * url: cmAppVersionFindLast_URL
 * method: cmAppVersionFindLast_TYPE
 * raw_url: cmAppVersionFindLast_RAW_URL
 * @param appType - 
 */
export const cmAppVersionFindLast = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app-version/last'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters['appType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppVersionFindLastFormData = function(formData) {
  const url = cmAppVersionFindLast_RAW_URL()
  const method = cmAppVersionFindLast_TYPE()
  return axios[method](url, formData)
}
export const cmAppVersionFindLast_RAW_URL = function() {
  return '/common/app-version/last'
}
export const cmAppVersionFindLast_TYPE = function() {
  return 'get'
}
export const cmAppVersionFindLast_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app-version/last'
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取应用版本
 * request: cmAppVersionFind
 * formData: cmAppVersionFindFormData
 * url: cmAppVersionFind_URL
 * method: cmAppVersionFind_TYPE
 * raw_url: cmAppVersionFind_RAW_URL
 * @param appVersionId - appVersionId
 */
export const cmAppVersionFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app-version/{appVersionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters['appVersionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appVersionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppVersionFindFormData = function(formData) {
  const url = cmAppVersionFind_RAW_URL()
  const method = cmAppVersionFind_TYPE()
  return axios[method](url, formData)
}
export const cmAppVersionFind_RAW_URL = function() {
  return '/common/app-version/{appVersionId}'
}
export const cmAppVersionFind_TYPE = function() {
  return 'get'
}
export const cmAppVersionFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app-version/{appVersionId}'
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入报告数据
 * request: cmAppImportReportData
 * formData: cmAppImportReportDataFormData
 * url: cmAppImportReportData_URL
 * method: cmAppImportReportData_TYPE
 * raw_url: cmAppImportReportData_RAW_URL
 * @param vo - vo
 */
export const cmAppImportReportData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/import-report-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportReportDataFormData = function(formData) {
  const url = cmAppImportReportData_RAW_URL()
  const method = cmAppImportReportData_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportReportData_RAW_URL = function() {
  return '/common/app/import-report-data'
}
export const cmAppImportReportData_TYPE = function() {
  return 'post'
}
export const cmAppImportReportData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/import-report-data'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省疾控导出学生及报告数据
 * request: cmProvinceAppExportStudentWithReport
 * formData: cmProvinceAppExportStudentWithReportFormData
 * url: cmProvinceAppExportStudentWithReport_URL
 * method: cmProvinceAppExportStudentWithReport_TYPE
 * raw_url: cmProvinceAppExportStudentWithReport_RAW_URL
 * @param form - form
 * @param cityCodes - 市编码.多个市用,隔开
 * @param districtCodes - 区编码.多个区用,隔开
 * @param screeningSchoolIds - 筛查学校id.多个学校用,隔开
 * @param retest - 
 */
export const cmProvinceAppExportStudentWithReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/province/student-with-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['form'] !== undefined) {
    body = parameters['form']
  }
  if (parameters['form'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: form'))
  }
  if (parameters['cityCodes'] !== undefined) {
    form['cityCodes'] = parameters['cityCodes']
  }
  if (parameters['districtCodes'] !== undefined) {
    form['districtCodes'] = parameters['districtCodes']
  }
  if (parameters['screeningSchoolIds'] !== undefined) {
    form['screeningSchoolIds'] = parameters['screeningSchoolIds']
  }
  if (parameters['retest'] !== undefined) {
    form['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmProvinceAppExportStudentWithReportFormData = function(formData) {
  const url = cmProvinceAppExportStudentWithReport_RAW_URL()
  const method = cmProvinceAppExportStudentWithReport_TYPE()
  return axios[method](url, formData)
}
export const cmProvinceAppExportStudentWithReport_RAW_URL = function() {
  return '/common/app/province/student-with-report'
}
export const cmProvinceAppExportStudentWithReport_TYPE = function() {
  return 'post'
}
export const cmProvinceAppExportStudentWithReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/province/student-with-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入学生数据
 * request: cmAppImportStudent
 * formData: cmAppImportStudentFormData
 * url: cmAppImportStudent_URL
 * method: cmAppImportStudent_TYPE
 * raw_url: cmAppImportStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param studentVos - studentVOS
 */
export const cmAppImportStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/screening-plan/{planId}/school/{screeningSchoolId}/student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['studentVos'] !== undefined) {
    body = parameters['studentVos']
  }
  if (parameters['studentVos'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentVos'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportStudentFormData = function(formData) {
  const url = cmAppImportStudent_RAW_URL()
  const method = cmAppImportStudent_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportStudent_RAW_URL = function() {
  return '/common/app/screening-plan/{planId}/school/{screeningSchoolId}/student'
}
export const cmAppImportStudent_TYPE = function() {
  return 'post'
}
export const cmAppImportStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/screening-plan/{planId}/school/{screeningSchoolId}/student'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校筛查学生列表
 * request: cmAppListScreeningStudent
 * formData: cmAppListScreeningStudentFormData
 * url: cmAppListScreeningStudent_URL
 * method: cmAppListScreeningStudent_TYPE
 * raw_url: cmAppListScreeningStudent_RAW_URL
 * @param screeningClassIds - id','隔开
 */
export const cmAppListScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/screening-student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningClassIds'] !== undefined) {
    queryParameters['screeningClassIds'] = parameters['screeningClassIds']
  }
  if (parameters['screeningClassIds'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassIds'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppListScreeningStudentFormData = function(formData) {
  const url = cmAppListScreeningStudent_RAW_URL()
  const method = cmAppListScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const cmAppListScreeningStudent_RAW_URL = function() {
  return '/common/app/screening-student'
}
export const cmAppListScreeningStudent_TYPE = function() {
  return 'get'
}
export const cmAppListScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/screening-student'
  if (parameters['screeningClassIds'] !== undefined) {
    queryParameters['screeningClassIds'] = parameters['screeningClassIds']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 下载学生填写模板
 * request: cmAppExportScreeningStudentTpl
 * formData: cmAppExportScreeningStudentTplFormData
 * url: cmAppExportScreeningStudentTpl_URL
 * method: cmAppExportScreeningStudentTpl_TYPE
 * raw_url: cmAppExportScreeningStudentTpl_RAW_URL
 * @param accessToken - 
 */
export const cmAppExportScreeningStudentTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/student/export-tpl'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportScreeningStudentTplFormData = function(formData) {
  const url = cmAppExportScreeningStudentTpl_RAW_URL()
  const method = cmAppExportScreeningStudentTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportScreeningStudentTpl_RAW_URL = function() {
  return '/common/app/student/export-tpl'
}
export const cmAppExportScreeningStudentTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportScreeningStudentTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/student/export-tpl'
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出学生及报告（REC）
 * request: cmAppExportStudentWithReportRec
 * formData: cmAppExportStudentWithReportRecFormData
 * url: cmAppExportStudentWithReportRec_URL
 * method: cmAppExportStudentWithReportRec_TYPE
 * raw_url: cmAppExportStudentWithReportRec_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 
 */
export const cmAppExportStudentWithReportRec = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningPlanId}/export-student-with-report-rec'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportStudentWithReportRecFormData = function(formData) {
  const url = cmAppExportStudentWithReportRec_RAW_URL()
  const method = cmAppExportStudentWithReportRec_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportStudentWithReportRec_RAW_URL = function() {
  return '/common/app/{screeningPlanId}/export-student-with-report-rec'
}
export const cmAppExportStudentWithReportRec_TYPE = function() {
  return 'get'
}
export const cmAppExportStudentWithReportRec_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningPlanId}/export-student-with-report-rec'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入旧报告数据
 * request: cmAppImportReportOldData
 * formData: cmAppImportReportOldDataFormData
 * url: cmAppImportReportOldData_URL
 * method: cmAppImportReportOldData_TYPE
 * raw_url: cmAppImportReportOldData_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param voList - voList
 */
export const cmAppImportReportOldData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningPlanId}/import-report-old-data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['voList'] !== undefined) {
    body = parameters['voList']
  }
  if (parameters['voList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportReportOldDataFormData = function(formData) {
  const url = cmAppImportReportOldData_RAW_URL()
  const method = cmAppImportReportOldData_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportReportOldData_RAW_URL = function() {
  return '/common/app/{screeningPlanId}/import-report-old-data'
}
export const cmAppImportReportOldData_TYPE = function() {
  return 'post'
}
export const cmAppImportReportOldData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningPlanId}/import-report-old-data'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入学校
 * request: cmAppImportSchoolData
 * formData: cmAppImportSchoolDataFormData
 * url: cmAppImportSchoolData_URL
 * method: cmAppImportSchoolData_TYPE
 * raw_url: cmAppImportSchoolData_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param voList - voList
 */
export const cmAppImportSchoolData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningPlanId}/import-school-data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['voList'] !== undefined) {
    body = parameters['voList']
  }
  if (parameters['voList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportSchoolDataFormData = function(formData) {
  const url = cmAppImportSchoolData_RAW_URL()
  const method = cmAppImportSchoolData_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportSchoolData_RAW_URL = function() {
  return '/common/app/{screeningPlanId}/import-school-data'
}
export const cmAppImportSchoolData_TYPE = function() {
  return 'post'
}
export const cmAppImportSchoolData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningPlanId}/import-school-data'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入学生及报告（常见病REC）
 * request: cmAppImportStudentWithReportCommonDiseaseRec
 * formData: cmAppImportStudentWithReportCommonDiseaseRecFormData
 * url: cmAppImportStudentWithReportCommonDiseaseRec_URL
 * method: cmAppImportStudentWithReportCommonDiseaseRec_TYPE
 * raw_url: cmAppImportStudentWithReportCommonDiseaseRec_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param commonDiseasesPrimarySecondarySchoolRecList - commonDiseasesPrimarySecondarySchoolRecList
 * @param screeningSchoolId - 
 */
export const cmAppImportStudentWithReportCommonDiseaseRec = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningPlanId}/import-student-with-report-common-disease-rec'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['commonDiseasesPrimarySecondarySchoolRecList'] !== undefined) {
    body = parameters['commonDiseasesPrimarySecondarySchoolRecList']
  }
  if (parameters['commonDiseasesPrimarySecondarySchoolRecList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commonDiseasesPrimarySecondarySchoolRecList'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportStudentWithReportCommonDiseaseRecFormData = function(formData) {
  const url = cmAppImportStudentWithReportCommonDiseaseRec_RAW_URL()
  const method = cmAppImportStudentWithReportCommonDiseaseRec_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportStudentWithReportCommonDiseaseRec_RAW_URL = function() {
  return '/common/app/{screeningPlanId}/import-student-with-report-common-disease-rec'
}
export const cmAppImportStudentWithReportCommonDiseaseRec_TYPE = function() {
  return 'post'
}
export const cmAppImportStudentWithReportCommonDiseaseRec_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningPlanId}/import-student-with-report-common-disease-rec'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入学生及报告（视力REC）
 * request: cmAppImportStudentWithReportVisionRec
 * formData: cmAppImportStudentWithReportVisionRecFormData
 * url: cmAppImportStudentWithReportVisionRec_URL
 * method: cmAppImportStudentWithReportVisionRec_TYPE
 * raw_url: cmAppImportStudentWithReportVisionRec_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param myopiaQuestionnaireRecList - myopiaQuestionnaireRecList
 * @param screeningSchoolId - 
 */
export const cmAppImportStudentWithReportVisionRec = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningPlanId}/import-student-with-report-vision-rec'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['myopiaQuestionnaireRecList'] !== undefined) {
    body = parameters['myopiaQuestionnaireRecList']
  }
  if (parameters['myopiaQuestionnaireRecList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: myopiaQuestionnaireRecList'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportStudentWithReportVisionRecFormData = function(formData) {
  const url = cmAppImportStudentWithReportVisionRec_RAW_URL()
  const method = cmAppImportStudentWithReportVisionRec_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportStudentWithReportVisionRec_RAW_URL = function() {
  return '/common/app/{screeningPlanId}/import-student-with-report-vision-rec'
}
export const cmAppImportStudentWithReportVisionRec_TYPE = function() {
  return 'post'
}
export const cmAppImportStudentWithReportVisionRec_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningPlanId}/import-student-with-report-vision-rec'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出常见病报告填写模板
 * request: cmAppExportCommonDiseaseReportTpl
 * formData: cmAppExportCommonDiseaseReportTplFormData
 * url: cmAppExportCommonDiseaseReportTpl_URL
 * method: cmAppExportCommonDiseaseReportTpl_TYPE
 * raw_url: cmAppExportCommonDiseaseReportTpl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param retest - 
 * @param screeningPlanId - 
 */
export const cmAppExportCommonDiseaseReportTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/common-disease-report/export-tpl'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters['retest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retest'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportCommonDiseaseReportTplFormData = function(formData) {
  const url = cmAppExportCommonDiseaseReportTpl_RAW_URL()
  const method = cmAppExportCommonDiseaseReportTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportCommonDiseaseReportTpl_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/common-disease-report/export-tpl'
}
export const cmAppExportCommonDiseaseReportTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportCommonDiseaseReportTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/common-disease-report/export-tpl'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入常见病报告(初测)
 * request: cmAppImportCommonDiseaseReport
 * formData: cmAppImportCommonDiseaseReportFormData
 * url: cmAppImportCommonDiseaseReport_URL
 * method: cmAppImportCommonDiseaseReport_TYPE
 * raw_url: cmAppImportCommonDiseaseReport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param commonDiseaseReportVos - commonDiseaseReportVOS
 */
export const cmAppImportCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/common-disease-report/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['commonDiseaseReportVos'] !== undefined) {
    body = parameters['commonDiseaseReportVos']
  }
  if (parameters['commonDiseaseReportVos'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: commonDiseaseReportVos'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportCommonDiseaseReportFormData = function(formData) {
  const url = cmAppImportCommonDiseaseReport_RAW_URL()
  const method = cmAppImportCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportCommonDiseaseReport_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/common-disease-report/import'
}
export const cmAppImportCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const cmAppImportCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/common-disease-report/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出屈光报告填写模板
 * request: cmAppExportDiopterReportTpl
 * formData: cmAppExportDiopterReportTplFormData
 * url: cmAppExportDiopterReportTpl_URL
 * method: cmAppExportDiopterReportTpl_TYPE
 * raw_url: cmAppExportDiopterReportTpl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmAppExportDiopterReportTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/diopter-report/export-tpl'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportDiopterReportTplFormData = function(formData) {
  const url = cmAppExportDiopterReportTpl_RAW_URL()
  const method = cmAppExportDiopterReportTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportDiopterReportTpl_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/diopter-report/export-tpl'
}
export const cmAppExportDiopterReportTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportDiopterReportTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/diopter-report/export-tpl'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入屈光报告
 * request: cmAppImportDiopterReport
 * formData: cmAppImportDiopterReportFormData
 * url: cmAppImportDiopterReport_URL
 * method: cmAppImportDiopterReport_TYPE
 * raw_url: cmAppImportDiopterReport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param retest - retest
 * @param diopterReportVos - diopterReportVOS
 */
export const cmAppImportDiopterReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/diopter-report/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters['retest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retest'))
  }
  if (parameters['diopterReportVos'] !== undefined) {
    body = parameters['diopterReportVos']
  }
  if (parameters['diopterReportVos'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: diopterReportVos'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportDiopterReportFormData = function(formData) {
  const url = cmAppImportDiopterReport_RAW_URL()
  const method = cmAppImportDiopterReport_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportDiopterReport_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/diopter-report/import'
}
export const cmAppImportDiopterReport_TYPE = function() {
  return 'post'
}
export const cmAppImportDiopterReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/diopter-report/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出漏检未检学生
 * request: cmAppExportMissingUncheckedStudent
 * formData: cmAppExportMissingUncheckedStudentFormData
 * url: cmAppExportMissingUncheckedStudent_URL
 * method: cmAppExportMissingUncheckedStudent_TYPE
 * raw_url: cmAppExportMissingUncheckedStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmAppExportMissingUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/export-missing-unchecked-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportMissingUncheckedStudentFormData = function(formData) {
  const url = cmAppExportMissingUncheckedStudent_RAW_URL()
  const method = cmAppExportMissingUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportMissingUncheckedStudent_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/export-missing-unchecked-student'
}
export const cmAppExportMissingUncheckedStudent_TYPE = function() {
  return 'get'
}
export const cmAppExportMissingUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/export-missing-unchecked-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出初检报告填写模板
 * request: cmAppExportReportTpl
 * formData: cmAppExportReportTplFormData
 * url: cmAppExportReportTpl_URL
 * method: cmAppExportReportTpl_TYPE
 * raw_url: cmAppExportReportTpl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmAppExportReportTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/export-report-tpl'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportReportTplFormData = function(formData) {
  const url = cmAppExportReportTpl_RAW_URL()
  const method = cmAppExportReportTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportReportTpl_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/export-report-tpl'
}
export const cmAppExportReportTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportReportTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/export-report-tpl'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出复检报告填写模板
 * request: cmAppExportRetestReportTpl
 * formData: cmAppExportRetestReportTplFormData
 * url: cmAppExportRetestReportTpl_URL
 * method: cmAppExportRetestReportTpl_TYPE
 * raw_url: cmAppExportRetestReportTpl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmAppExportRetestReportTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/export-retest-report-tpl'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportRetestReportTplFormData = function(formData) {
  const url = cmAppExportRetestReportTpl_RAW_URL()
  const method = cmAppExportRetestReportTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportRetestReportTpl_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/export-retest-report-tpl'
}
export const cmAppExportRetestReportTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportRetestReportTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/export-retest-report-tpl'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入常见病报告(复测)
 * request: cmAppImportRetestCommonDiseaseReport
 * formData: cmAppImportRetestCommonDiseaseReportFormData
 * url: cmAppImportRetestCommonDiseaseReport_URL
 * method: cmAppImportRetestCommonDiseaseReport_TYPE
 * raw_url: cmAppImportRetestCommonDiseaseReport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param retestCommonDiseaseReportVos - retestCommonDiseaseReportVOS
 */
export const cmAppImportRetestCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/retest-common-disease-report/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['retestCommonDiseaseReportVos'] !== undefined) {
    body = parameters['retestCommonDiseaseReportVos']
  }
  if (parameters['retestCommonDiseaseReportVos'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retestCommonDiseaseReportVos'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportRetestCommonDiseaseReportFormData = function(formData) {
  const url = cmAppImportRetestCommonDiseaseReport_RAW_URL()
  const method = cmAppImportRetestCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportRetestCommonDiseaseReport_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/retest-common-disease-report/import'
}
export const cmAppImportRetestCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const cmAppImportRetestCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/retest-common-disease-report/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出学生及报告数据
 * request: cmAppExportStudentWithReport
 * formData: cmAppExportStudentWithReportFormData
 * url: cmAppExportStudentWithReport_URL
 * method: cmAppExportStudentWithReport_TYPE
 * raw_url: cmAppExportStudentWithReport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param retest - 
 */
export const cmAppExportStudentWithReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/student-with-report'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportStudentWithReportFormData = function(formData) {
  const url = cmAppExportStudentWithReport_RAW_URL()
  const method = cmAppExportStudentWithReport_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportStudentWithReport_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/student-with-report'
}
export const cmAppExportStudentWithReport_TYPE = function() {
  return 'get'
}
export const cmAppExportStudentWithReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/student-with-report'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出视力报告填写模板
 * request: cmAppExportVisionReportTpl
 * formData: cmAppExportVisionReportTplFormData
 * url: cmAppExportVisionReportTpl_URL
 * method: cmAppExportVisionReportTpl_TYPE
 * raw_url: cmAppExportVisionReportTpl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmAppExportVisionReportTpl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/vision-report/export-tpl'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmAppExportVisionReportTplFormData = function(formData) {
  const url = cmAppExportVisionReportTpl_RAW_URL()
  const method = cmAppExportVisionReportTpl_TYPE()
  return axios[method](url, formData)
}
export const cmAppExportVisionReportTpl_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/vision-report/export-tpl'
}
export const cmAppExportVisionReportTpl_TYPE = function() {
  return 'get'
}
export const cmAppExportVisionReportTpl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/vision-report/export-tpl'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入视力报告
 * request: cmAppImportVisionReport
 * formData: cmAppImportVisionReportFormData
 * url: cmAppImportVisionReport_URL
 * method: cmAppImportVisionReport_TYPE
 * raw_url: cmAppImportVisionReport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param retest - retest
 * @param visionReportVos - visionReportVOS
 */
export const cmAppImportVisionReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningSchoolId}/vision-report/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters['retest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retest'))
  }
  if (parameters['visionReportVos'] !== undefined) {
    body = parameters['visionReportVos']
  }
  if (parameters['visionReportVos'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: visionReportVos'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppImportVisionReportFormData = function(formData) {
  const url = cmAppImportVisionReport_RAW_URL()
  const method = cmAppImportVisionReport_TYPE()
  return axios[method](url, formData)
}
export const cmAppImportVisionReport_RAW_URL = function() {
  return '/common/app/{screeningSchoolId}/vision-report/import'
}
export const cmAppImportVisionReport_TYPE = function() {
  return 'post'
}
export const cmAppImportVisionReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningSchoolId}/vision-report/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传屈光图片
 * request: cmAppUploadDiopterPic
 * formData: cmAppUploadDiopterPicFormData
 * url: cmAppUploadDiopterPic_URL
 * method: cmAppUploadDiopterPic_TYPE
 * raw_url: cmAppUploadDiopterPic_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param retest - 
 * @param file - 
 */
export const cmAppUploadDiopterPic = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/app/{screeningStudentId}/diopter-report/pic'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters['retest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retest'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmAppUploadDiopterPicFormData = function(formData) {
  const url = cmAppUploadDiopterPic_RAW_URL()
  const method = cmAppUploadDiopterPic_TYPE()
  return axios[method](url, formData)
}
export const cmAppUploadDiopterPic_RAW_URL = function() {
  return '/common/app/{screeningStudentId}/diopter-report/pic'
}
export const cmAppUploadDiopterPic_TYPE = function() {
  return 'post'
}
export const cmAppUploadDiopterPic_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/app/{screeningStudentId}/diopter-report/pic'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['retest'] !== undefined) {
    queryParameters['retest'] = parameters['retest']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 档案列表
 * request: cmArchiveList
 * formData: cmArchiveListFormData
 * url: cmArchiveList_URL
 * method: cmArchiveList_TYPE
 * raw_url: cmArchiveList_RAW_URL
 * @param regionPersonId - 地区受检人ID
 * @param type - 类型
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param medicalInstitutionId - 医疗机构ID
 * @param startDateMills - 建档起始时间
 * @param endDateMills - 建档结束时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmArchiveList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/archive'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['medicalInstitutionId'] !== undefined) {
    queryParameters['medicalInstitutionId'] = parameters['medicalInstitutionId']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArchiveListFormData = function(formData) {
  const url = cmArchiveList_RAW_URL()
  const method = cmArchiveList_TYPE()
  return axios[method](url, formData)
}
export const cmArchiveList_RAW_URL = function() {
  return '/common/archive'
}
export const cmArchiveList_TYPE = function() {
  return 'get'
}
export const cmArchiveList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/archive'
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['medicalInstitutionId'] !== undefined) {
    queryParameters['medicalInstitutionId'] = parameters['medicalInstitutionId']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户档案趋势图
 * request: cmArchiveTendencyChart
 * formData: cmArchiveTendencyChartFormData
 * url: cmArchiveTendencyChart_URL
 * method: cmArchiveTendencyChart_TYPE
 * raw_url: cmArchiveTendencyChart_RAW_URL
 * @param regionPersonId - regionPersonId
 * @param cityCode - cityCode
 */
export const cmArchiveTendencyChart = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/archive/tendency-chart/{regionPersonId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArchiveTendencyChartFormData = function(formData) {
  const url = cmArchiveTendencyChart_RAW_URL()
  const method = cmArchiveTendencyChart_TYPE()
  return axios[method](url, formData)
}
export const cmArchiveTendencyChart_RAW_URL = function() {
  return '/common/archive/tendency-chart/{regionPersonId}'
}
export const cmArchiveTendencyChart_TYPE = function() {
  return 'get'
}
export const cmArchiveTendencyChart_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/archive/tendency-chart/{regionPersonId}'
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看儿童青少年屈光发育档案
 * request: cmArchiveFindAdolescentDioptricArchive
 * formData: cmArchiveFindAdolescentDioptricArchiveFormData
 * url: cmArchiveFindAdolescentDioptricArchive_URL
 * method: cmArchiveFindAdolescentDioptricArchive_TYPE
 * raw_url: cmArchiveFindAdolescentDioptricArchive_RAW_URL
 * @param id - id
 * @param cityCode - cityCode
 */
export const cmArchiveFindAdolescentDioptricArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/archive/{id}/adolescent-dioptric'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArchiveFindAdolescentDioptricArchiveFormData = function(formData) {
  const url = cmArchiveFindAdolescentDioptricArchive_RAW_URL()
  const method = cmArchiveFindAdolescentDioptricArchive_TYPE()
  return axios[method](url, formData)
}
export const cmArchiveFindAdolescentDioptricArchive_RAW_URL = function() {
  return '/common/archive/{id}/adolescent-dioptric'
}
export const cmArchiveFindAdolescentDioptricArchive_TYPE = function() {
  return 'get'
}
export const cmArchiveFindAdolescentDioptricArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/archive/{id}/adolescent-dioptric'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 公共查看档案
 * request: cmDataFileCheck
 * formData: cmDataFileCheckFormData
 * url: cmDataFileCheck_URL
 * method: cmDataFileCheck_TYPE
 * raw_url: cmDataFileCheck_RAW_URL
 * @param id - id
 * @param cityCode - 市编码
 */
export const cmDataFileCheck = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/archive/{id}/check'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataFileCheckFormData = function(formData) {
  const url = cmDataFileCheck_RAW_URL()
  const method = cmDataFileCheck_TYPE()
  return axios[method](url, formData)
}
export const cmDataFileCheck_RAW_URL = function() {
  return '/common/archive/{id}/check'
}
export const cmDataFileCheck_TYPE = function() {
  return 'get'
}
export const cmDataFileCheck_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/archive/{id}/check'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看0-6岁眼健康管理档案
 * request: cmArchiveFindInfantEyeHealthArchive
 * formData: cmArchiveFindInfantEyeHealthArchiveFormData
 * url: cmArchiveFindInfantEyeHealthArchive_URL
 * method: cmArchiveFindInfantEyeHealthArchive_TYPE
 * raw_url: cmArchiveFindInfantEyeHealthArchive_RAW_URL
 * @param id - id
 * @param cityCode - cityCode
 */
export const cmArchiveFindInfantEyeHealthArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/archive/{id}/infant-eye-health'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArchiveFindInfantEyeHealthArchiveFormData = function(formData) {
  const url = cmArchiveFindInfantEyeHealthArchive_RAW_URL()
  const method = cmArchiveFindInfantEyeHealthArchive_TYPE()
  return axios[method](url, formData)
}
export const cmArchiveFindInfantEyeHealthArchive_RAW_URL = function() {
  return '/common/archive/{id}/infant-eye-health'
}
export const cmArchiveFindInfantEyeHealthArchive_TYPE = function() {
  return 'get'
}
export const cmArchiveFindInfantEyeHealthArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/archive/{id}/infant-eye-health'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类选择列表
 * request: cmArticleCategoryChoiceList
 * formData: cmArticleCategoryChoiceListFormData
 * url: cmArticleCategoryChoiceList_URL
 * method: cmArticleCategoryChoiceList_TYPE
 * raw_url: cmArticleCategoryChoiceList_RAW_URL
 * @param parentId - 
 * @param isAll - 
 */
export const cmArticleCategoryChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article-category/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleCategoryChoiceListFormData = function(formData) {
  const url = cmArticleCategoryChoiceList_RAW_URL()
  const method = cmArticleCategoryChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmArticleCategoryChoiceList_RAW_URL = function() {
  return '/common/article-category/choice'
}
export const cmArticleCategoryChoiceList_TYPE = function() {
  return 'get'
}
export const cmArticleCategoryChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article-category/choice'
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类管理标签
 * request: cmArticleCategoryListArticleLabel
 * formData: cmArticleCategoryListArticleLabelFormData
 * url: cmArticleCategoryListArticleLabel_URL
 * method: cmArticleCategoryListArticleLabel_TYPE
 * raw_url: cmArticleCategoryListArticleLabel_RAW_URL
 * @param articleCategoryId - articleCategoryId
 */
export const cmArticleCategoryListArticleLabel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article-category/{articleCategoryId}/article-label'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleCategoryListArticleLabelFormData = function(formData) {
  const url = cmArticleCategoryListArticleLabel_RAW_URL()
  const method = cmArticleCategoryListArticleLabel_TYPE()
  return axios[method](url, formData)
}
export const cmArticleCategoryListArticleLabel_RAW_URL = function() {
  return '/common/article-category/{articleCategoryId}/article-label'
}
export const cmArticleCategoryListArticleLabel_TYPE = function() {
  return 'get'
}
export const cmArticleCategoryListArticleLabel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article-category/{articleCategoryId}/article-label'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航选择列表
 * request: cmArticleNavigationChoiceList
 * formData: cmArticleNavigationChoiceListFormData
 * url: cmArticleNavigationChoiceList_URL
 * method: cmArticleNavigationChoiceList_TYPE
 * raw_url: cmArticleNavigationChoiceList_RAW_URL
 * @param parentId - 
 */
export const cmArticleNavigationChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article-navigation/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleNavigationChoiceListFormData = function(formData) {
  const url = cmArticleNavigationChoiceList_RAW_URL()
  const method = cmArticleNavigationChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmArticleNavigationChoiceList_RAW_URL = function() {
  return '/common/article-navigation/choice'
}
export const cmArticleNavigationChoiceList_TYPE = function() {
  return 'get'
}
export const cmArticleNavigationChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article-navigation/choice'
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取科普文章导航
 * request: cmArticleNavigationFind
 * formData: cmArticleNavigationFindFormData
 * url: cmArticleNavigationFind_URL
 * method: cmArticleNavigationFind_TYPE
 * raw_url: cmArticleNavigationFind_RAW_URL
 * @param articleNavigationId - articleNavigationId
 */
export const cmArticleNavigationFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article-navigation/{articleNavigationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters['articleNavigationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleNavigationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleNavigationFindFormData = function(formData) {
  const url = cmArticleNavigationFind_RAW_URL()
  const method = cmArticleNavigationFind_TYPE()
  return axios[method](url, formData)
}
export const cmArticleNavigationFind_RAW_URL = function() {
  return '/common/article-navigation/{articleNavigationId}'
}
export const cmArticleNavigationFind_TYPE = function() {
  return 'get'
}
export const cmArticleNavigationFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article-navigation/{articleNavigationId}'
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取文章下载地址
 * request: cmArticleFindFilePathByArticleId
 * formData: cmArticleFindFilePathByArticleIdFormData
 * url: cmArticleFindFilePathByArticleId_URL
 * method: cmArticleFindFilePathByArticleId_TYPE
 * raw_url: cmArticleFindFilePathByArticleId_RAW_URL
 * @param articleId - articleId
 */
export const cmArticleFindFilePathByArticleId = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article/file-path/{articleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleFindFilePathByArticleIdFormData = function(formData) {
  const url = cmArticleFindFilePathByArticleId_RAW_URL()
  const method = cmArticleFindFilePathByArticleId_TYPE()
  return axios[method](url, formData)
}
export const cmArticleFindFilePathByArticleId_RAW_URL = function() {
  return '/common/article/file-path/{articleId}'
}
export const cmArticleFindFilePathByArticleId_TYPE = function() {
  return 'get'
}
export const cmArticleFindFilePathByArticleId_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article/file-path/{articleId}'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取当前机构积分数
 * request: cmArticleFindIntegral
 * formData: cmArticleFindIntegralFormData
 * url: cmArticleFindIntegral_URL
 * method: cmArticleFindIntegral_TYPE
 * raw_url: cmArticleFindIntegral_RAW_URL
 */
export const cmArticleFindIntegral = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article/integral'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleFindIntegralFormData = function(formData) {
  const url = cmArticleFindIntegral_RAW_URL()
  const method = cmArticleFindIntegral_TYPE()
  return axios[method](url, formData)
}
export const cmArticleFindIntegral_RAW_URL = function() {
  return '/common/article/integral'
}
export const cmArticleFindIntegral_TYPE = function() {
  return 'get'
}
export const cmArticleFindIntegral_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article/integral'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新可读新科普文章列表
 * request: cmArticleListNewRead
 * formData: cmArticleListNewReadFormData
 * url: cmArticleListNewRead_URL
 * method: cmArticleListNewRead_TYPE
 * raw_url: cmArticleListNewRead_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmArticleListNewRead = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article/new-read'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleListNewReadFormData = function(formData) {
  const url = cmArticleListNewRead_RAW_URL()
  const method = cmArticleListNewRead_TYPE()
  return axios[method](url, formData)
}
export const cmArticleListNewRead_RAW_URL = function() {
  return '/common/article/new-read'
}
export const cmArticleListNewRead_TYPE = function() {
  return 'get'
}
export const cmArticleListNewRead_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article/new-read'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章可读列表
 * request: cmArticleListRead
 * formData: cmArticleListReadFormData
 * url: cmArticleListRead_URL
 * method: cmArticleListRead_TYPE
 * raw_url: cmArticleListRead_RAW_URL
 * @param categoryId - 
 * @param navigationId - 导航ID
 * @param title - 
 * @param label - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmArticleListRead = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article/read'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['navigationId'] !== undefined) {
    queryParameters['navigationId'] = parameters['navigationId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleListReadFormData = function(formData) {
  const url = cmArticleListRead_RAW_URL()
  const method = cmArticleListRead_TYPE()
  return axios[method](url, formData)
}
export const cmArticleListRead_RAW_URL = function() {
  return '/common/article/read'
}
export const cmArticleListRead_TYPE = function() {
  return 'get'
}
export const cmArticleListRead_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article/read'
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['navigationId'] !== undefined) {
    queryParameters['navigationId'] = parameters['navigationId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取科普文章详情
 * request: cmArticleFind
 * formData: cmArticleFindFormData
 * url: cmArticleFind_URL
 * method: cmArticleFind_TYPE
 * raw_url: cmArticleFind_RAW_URL
 * @param articleId - articleId
 */
export const cmArticleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/article/{articleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmArticleFindFormData = function(formData) {
  const url = cmArticleFind_RAW_URL()
  const method = cmArticleFind_TYPE()
  return axios[method](url, formData)
}
export const cmArticleFind_RAW_URL = function() {
  return '/common/article/{articleId}'
}
export const cmArticleFind_TYPE = function() {
  return 'get'
}
export const cmArticleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/article/{articleId}'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板查看
 * request: cmDataFileTplCheck
 * formData: cmDataFileTplCheckFormData
 * url: cmDataFileTplCheck_URL
 * method: cmDataFileTplCheck_TYPE
 * raw_url: cmDataFileTplCheck_RAW_URL
 * @param id - id
 * @param versionId - versionId
 */
export const cmDataFileTplCheck = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data-file-tpl/{id}/check'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['versionId'] !== undefined) {
    queryParameters['versionId'] = parameters['versionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataFileTplCheckFormData = function(formData) {
  const url = cmDataFileTplCheck_RAW_URL()
  const method = cmDataFileTplCheck_TYPE()
  return axios[method](url, formData)
}
export const cmDataFileTplCheck_RAW_URL = function() {
  return '/common/data-file-tpl/{id}/check'
}
export const cmDataFileTplCheck_TYPE = function() {
  return 'get'
}
export const cmDataFileTplCheck_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data-file-tpl/{id}/check'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['versionId'] !== undefined) {
    queryParameters['versionId'] = parameters['versionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章列表
 * request: cmDataArticleByTag
 * formData: cmDataArticleByTagFormData
 * url: cmDataArticleByTag_URL
 * method: cmDataArticleByTag_TYPE
 * raw_url: cmDataArticleByTag_RAW_URL
 * @param tagType - int
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmDataArticleByTag = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/article-by-tag'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['tagType'] !== undefined) {
    queryParameters['tagType'] = parameters['tagType']
  }
  if (parameters['tagType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: tagType'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataArticleByTagFormData = function(formData) {
  const url = cmDataArticleByTag_RAW_URL()
  const method = cmDataArticleByTag_TYPE()
  return axios[method](url, formData)
}
export const cmDataArticleByTag_RAW_URL = function() {
  return '/common/data/article-by-tag'
}
export const cmDataArticleByTag_TYPE = function() {
  return 'get'
}
export const cmDataArticleByTag_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/article-by-tag'
  if (parameters['tagType'] !== undefined) {
    queryParameters['tagType'] = parameters['tagType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章标签列表
 * request: cmDataListArticleLabel
 * formData: cmDataListArticleLabelFormData
 * url: cmDataListArticleLabel_URL
 * method: cmDataListArticleLabel_TYPE
 * raw_url: cmDataListArticleLabel_RAW_URL
 */
export const cmDataListArticleLabel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/article-label'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListArticleLabelFormData = function(formData) {
  const url = cmDataListArticleLabel_RAW_URL()
  const method = cmDataListArticleLabel_TYPE()
  return axios[method](url, formData)
}
export const cmDataListArticleLabel_RAW_URL = function() {
  return '/common/data/article-label'
}
export const cmDataListArticleLabel_TYPE = function() {
  return 'get'
}
export const cmDataListArticleLabel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/article-label'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章详情
 * request: cmDataArticleDetail
 * formData: cmDataArticleDetailFormData
 * url: cmDataArticleDetail_URL
 * method: cmDataArticleDetail_TYPE
 * raw_url: cmDataArticleDetail_RAW_URL
 * @param id - id
 */
export const cmDataArticleDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataArticleDetailFormData = function(formData) {
  const url = cmDataArticleDetail_RAW_URL()
  const method = cmDataArticleDetail_TYPE()
  return axios[method](url, formData)
}
export const cmDataArticleDetail_RAW_URL = function() {
  return '/common/data/article/{id}'
}
export const cmDataArticleDetail_TYPE = function() {
  return 'get'
}
export const cmDataArticleDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章
 * request: cmDataArticles
 * formData: cmDataArticlesFormData
 * url: cmDataArticles_URL
 * method: cmDataArticles_TYPE
 * raw_url: cmDataArticles_RAW_URL
 */
export const cmDataArticles = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/articles'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataArticlesFormData = function(formData) {
  const url = cmDataArticles_RAW_URL()
  const method = cmDataArticles_TYPE()
  return axios[method](url, formData)
}
export const cmDataArticles_RAW_URL = function() {
  return '/common/data/articles'
}
export const cmDataArticles_TYPE = function() {
  return 'get'
}
export const cmDataArticles_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/articles'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑数据类型列表
 * request: cmDataDubiousTypeConfig
 * formData: cmDataDubiousTypeConfigFormData
 * url: cmDataDubiousTypeConfig_URL
 * method: cmDataDubiousTypeConfig_TYPE
 * raw_url: cmDataDubiousTypeConfig_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmDataDubiousTypeConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/dubious-type/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataDubiousTypeConfigFormData = function(formData) {
  const url = cmDataDubiousTypeConfig_RAW_URL()
  const method = cmDataDubiousTypeConfig_TYPE()
  return axios[method](url, formData)
}
export const cmDataDubiousTypeConfig_RAW_URL = function() {
  return '/common/data/dubious-type/{screeningSchoolId}'
}
export const cmDataDubiousTypeConfig_TYPE = function() {
  return 'get'
}
export const cmDataDubiousTypeConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/dubious-type/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 民族列表
 * request: cmDataListNation
 * formData: cmDataListNationFormData
 * url: cmDataListNation_URL
 * method: cmDataListNation_TYPE
 * raw_url: cmDataListNation_RAW_URL
 */
export const cmDataListNation = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/nation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListNationFormData = function(formData) {
  const url = cmDataListNation_RAW_URL()
  const method = cmDataListNation_TYPE()
  return axios[method](url, formData)
}
export const cmDataListNation_RAW_URL = function() {
  return '/common/data/nation'
}
export const cmDataListNation_TYPE = function() {
  return 'get'
}
export const cmDataListNation_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/nation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷配置列表
 * request: cmDataListQuestionaryCondition
 * formData: cmDataListQuestionaryConditionFormData
 * url: cmDataListQuestionaryCondition_URL
 * method: cmDataListQuestionaryCondition_TYPE
 * raw_url: cmDataListQuestionaryCondition_RAW_URL
 */
export const cmDataListQuestionaryCondition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/questionary-condition'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListQuestionaryConditionFormData = function(formData) {
  const url = cmDataListQuestionaryCondition_RAW_URL()
  const method = cmDataListQuestionaryCondition_TYPE()
  return axios[method](url, formData)
}
export const cmDataListQuestionaryCondition_RAW_URL = function() {
  return '/common/data/questionary-condition'
}
export const cmDataListQuestionaryCondition_TYPE = function() {
  return 'get'
}
export const cmDataListQuestionaryCondition_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/questionary-condition'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷选项列表
 * request: cmDataListQuestionaryOption
 * formData: cmDataListQuestionaryOptionFormData
 * url: cmDataListQuestionaryOption_URL
 * method: cmDataListQuestionaryOption_TYPE
 * raw_url: cmDataListQuestionaryOption_RAW_URL
 */
export const cmDataListQuestionaryOption = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/questionary-option'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListQuestionaryOptionFormData = function(formData) {
  const url = cmDataListQuestionaryOption_RAW_URL()
  const method = cmDataListQuestionaryOption_TYPE()
  return axios[method](url, formData)
}
export const cmDataListQuestionaryOption_RAW_URL = function() {
  return '/common/data/questionary-option'
}
export const cmDataListQuestionaryOption_TYPE = function() {
  return 'get'
}
export const cmDataListQuestionaryOption_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/questionary-option'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区配置列表
 * request: cmDataListRegionConfig
 * formData: cmDataListRegionConfigFormData
 * url: cmDataListRegionConfig_URL
 * method: cmDataListRegionConfig_TYPE
 * raw_url: cmDataListRegionConfig_RAW_URL
 * @param parentRegionCode - 父级地区编码
 * @param regionCode - 地区编码
 */
export const cmDataListRegionConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/region-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentRegionCode'] !== undefined) {
    queryParameters['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListRegionConfigFormData = function(formData) {
  const url = cmDataListRegionConfig_RAW_URL()
  const method = cmDataListRegionConfig_TYPE()
  return axios[method](url, formData)
}
export const cmDataListRegionConfig_RAW_URL = function() {
  return '/common/data/region-config'
}
export const cmDataListRegionConfig_TYPE = function() {
  return 'get'
}
export const cmDataListRegionConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/region-config'
  if (parameters['parentRegionCode'] !== undefined) {
    queryParameters['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查找地区特征
 * request: cmDataFindRegionFeature
 * formData: cmDataFindRegionFeatureFormData
 * url: cmDataFindRegionFeature_URL
 * method: cmDataFindRegionFeature_TYPE
 * raw_url: cmDataFindRegionFeature_RAW_URL
 * @param regionCode - 
 */
export const cmDataFindRegionFeature = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/region-feature'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataFindRegionFeatureFormData = function(formData) {
  const url = cmDataFindRegionFeature_RAW_URL()
  const method = cmDataFindRegionFeature_TYPE()
  return axios[method](url, formData)
}
export const cmDataFindRegionFeature_RAW_URL = function() {
  return '/common/data/region-feature'
}
export const cmDataFindRegionFeature_TYPE = function() {
  return 'get'
}
export const cmDataFindRegionFeature_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/region-feature'
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查条件列表
 * request: cmDataListScreeningCondition
 * formData: cmDataListScreeningConditionFormData
 * url: cmDataListScreeningCondition_URL
 * method: cmDataListScreeningCondition_TYPE
 * raw_url: cmDataListScreeningCondition_RAW_URL
 */
export const cmDataListScreeningCondition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/screening-condition'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListScreeningConditionFormData = function(formData) {
  const url = cmDataListScreeningCondition_RAW_URL()
  const method = cmDataListScreeningCondition_TYPE()
  return axios[method](url, formData)
}
export const cmDataListScreeningCondition_RAW_URL = function() {
  return '/common/data/screening-condition'
}
export const cmDataListScreeningCondition_TYPE = function() {
  return 'get'
}
export const cmDataListScreeningCondition_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/screening-condition'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查项目列表
 * request: cmDataListScreeningProject
 * formData: cmDataListScreeningProjectFormData
 * url: cmDataListScreeningProject_URL
 * method: cmDataListScreeningProject_TYPE
 * raw_url: cmDataListScreeningProject_RAW_URL
 */
export const cmDataListScreeningProject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/screening-project'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataListScreeningProjectFormData = function(formData) {
  const url = cmDataListScreeningProject_RAW_URL()
  const method = cmDataListScreeningProject_TYPE()
  return axios[method](url, formData)
}
export const cmDataListScreeningProject_RAW_URL = function() {
  return '/common/data/screening-project'
}
export const cmDataListScreeningProject_TYPE = function() {
  return 'get'
}
export const cmDataListScreeningProject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/screening-project'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 统计信息
 * request: cmDataStatistics
 * formData: cmDataStatisticsFormData
 * url: cmDataStatistics_URL
 * method: cmDataStatistics_TYPE
 * raw_url: cmDataStatistics_RAW_URL
 * @param cityCode - cityCode
 * @param districtCode - districtCode
 * @param schoolId - schoolId
 */
export const cmDataStatistics = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/data/statistics'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDataStatisticsFormData = function(formData) {
  const url = cmDataStatistics_RAW_URL()
  const method = cmDataStatistics_TYPE()
  return axios[method](url, formData)
}
export const cmDataStatistics_RAW_URL = function() {
  return '/common/data/statistics'
}
export const cmDataStatistics_TYPE = function() {
  return 'get'
}
export const cmDataStatistics_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/data/statistics'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 年级选择列表
 * request: cmGradeConfigChoiceList
 * formData: cmGradeConfigChoiceListFormData
 * url: cmGradeConfigChoiceList_URL
 * method: cmGradeConfigChoiceList_TYPE
 * raw_url: cmGradeConfigChoiceList_RAW_URL
 * @param schoolType - 学校类型
 * @param code - 编码
 * @param name - 名称
 */
export const cmGradeConfigChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/grade/choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmGradeConfigChoiceListFormData = function(formData) {
  const url = cmGradeConfigChoiceList_RAW_URL()
  const method = cmGradeConfigChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmGradeConfigChoiceList_RAW_URL = function() {
  return '/common/grade/choice-list'
}
export const cmGradeConfigChoiceList_TYPE = function() {
  return 'get'
}
export const cmGradeConfigChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/grade/choice-list'
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校年级列表
 * request: cmGradeScreeningGradeList
 * formData: cmGradeScreeningGradeListFormData
 * url: cmGradeScreeningGradeList_URL
 * method: cmGradeScreeningGradeList_TYPE
 * raw_url: cmGradeScreeningGradeList_RAW_URL
 * @param screeningSchoolId - 筛查学校id
 */
export const cmGradeScreeningGradeList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/grade/screening-grade'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmGradeScreeningGradeListFormData = function(formData) {
  const url = cmGradeScreeningGradeList_RAW_URL()
  const method = cmGradeScreeningGradeList_TYPE()
  return axios[method](url, formData)
}
export const cmGradeScreeningGradeList_RAW_URL = function() {
  return '/common/grade/screening-grade'
}
export const cmGradeScreeningGradeList_TYPE = function() {
  return 'get'
}
export const cmGradeScreeningGradeList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/grade/screening-grade'
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 重置全部管理员密码
 * request: cmMainResetAdminPassword
 * formData: cmMainResetAdminPasswordFormData
 * url: cmMainResetAdminPassword_URL
 * method: cmMainResetAdminPassword_TYPE
 * raw_url: cmMainResetAdminPassword_RAW_URL
 */
export const cmMainResetAdminPassword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/admin/reset-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMainResetAdminPasswordFormData = function(formData) {
  const url = cmMainResetAdminPassword_RAW_URL()
  const method = cmMainResetAdminPassword_TYPE()
  return axios[method](url, formData)
}
export const cmMainResetAdminPassword_RAW_URL = function() {
  return '/common/main/admin/reset-password'
}
export const cmMainResetAdminPassword_TYPE = function() {
  return 'post'
}
export const cmMainResetAdminPassword_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/admin/reset-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取captchaid
 * request: cmMainCaptchaid
 * formData: cmMainCaptchaidFormData
 * url: cmMainCaptchaid_URL
 * method: cmMainCaptchaid_TYPE
 * raw_url: cmMainCaptchaid_RAW_URL
 */
export const cmMainCaptchaid = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/captchaid'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainCaptchaidFormData = function(formData) {
  const url = cmMainCaptchaid_RAW_URL()
  const method = cmMainCaptchaid_TYPE()
  return axios[method](url, formData)
}
export const cmMainCaptchaid_RAW_URL = function() {
  return '/common/main/captchaid'
}
export const cmMainCaptchaid_TYPE = function() {
  return 'get'
}
export const cmMainCaptchaid_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/captchaid'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 清除地区缓存
 * request: cmMainCleanRegionCache
 * formData: cmMainCleanRegionCacheFormData
 * url: cmMainCleanRegionCache_URL
 * method: cmMainCleanRegionCache_TYPE
 * raw_url: cmMainCleanRegionCache_RAW_URL
 */
export const cmMainCleanRegionCache = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/clean-region-cache'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainCleanRegionCacheFormData = function(formData) {
  const url = cmMainCleanRegionCache_RAW_URL()
  const method = cmMainCleanRegionCache_TYPE()
  return axios[method](url, formData)
}
export const cmMainCleanRegionCache_RAW_URL = function() {
  return '/common/main/clean-region-cache'
}
export const cmMainCleanRegionCache_TYPE = function() {
  return 'get'
}
export const cmMainCleanRegionCache_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/clean-region-cache'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 清除微信缓存
 * request: cmMainCleanWxCache
 * formData: cmMainCleanWxCacheFormData
 * url: cmMainCleanWxCache_URL
 * method: cmMainCleanWxCache_TYPE
 * raw_url: cmMainCleanWxCache_RAW_URL
 */
export const cmMainCleanWxCache = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/clean-wx-cache'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainCleanWxCacheFormData = function(formData) {
  const url = cmMainCleanWxCache_RAW_URL()
  const method = cmMainCleanWxCache_TYPE()
  return axios[method](url, formData)
}
export const cmMainCleanWxCache_RAW_URL = function() {
  return '/common/main/clean-wx-cache'
}
export const cmMainCleanWxCache_TYPE = function() {
  return 'get'
}
export const cmMainCleanWxCache_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/clean-wx-cache'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 忘记密码发送邮箱
 * request: cmMainForgetPasswordSendEmail
 * formData: cmMainForgetPasswordSendEmailFormData
 * url: cmMainForgetPasswordSendEmail_URL
 * method: cmMainForgetPasswordSendEmail_TYPE
 * raw_url: cmMainForgetPasswordSendEmail_RAW_URL
 * @param account - 
 */
export const cmMainForgetPasswordSendEmail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/forget-password-send-email'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['account'] !== undefined) {
    form['account'] = parameters['account']
  }
  if (parameters['account'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: account'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMainForgetPasswordSendEmailFormData = function(formData) {
  const url = cmMainForgetPasswordSendEmail_RAW_URL()
  const method = cmMainForgetPasswordSendEmail_TYPE()
  return axios[method](url, formData)
}
export const cmMainForgetPasswordSendEmail_RAW_URL = function() {
  return '/common/main/forget-password-send-email'
}
export const cmMainForgetPasswordSendEmail_TYPE = function() {
  return 'post'
}
export const cmMainForgetPasswordSendEmail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/forget-password-send-email'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 初始化系统配置
 * request: cmMainInit
 * formData: cmMainInitFormData
 * url: cmMainInit_URL
 * method: cmMainInit_TYPE
 * raw_url: cmMainInit_RAW_URL
 */
export const cmMainInit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/init'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMainInitFormData = function(formData) {
  const url = cmMainInit_RAW_URL()
  const method = cmMainInit_TYPE()
  return axios[method](url, formData)
}
export const cmMainInit_RAW_URL = function() {
  return '/common/main/init'
}
export const cmMainInit_TYPE = function() {
  return 'post'
}
export const cmMainInit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/init'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 重新加载功能配置
 * request: cmMainInitFunction
 * formData: cmMainInitFunctionFormData
 * url: cmMainInitFunction_URL
 * method: cmMainInitFunction_TYPE
 * raw_url: cmMainInitFunction_RAW_URL
 */
export const cmMainInitFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/init-function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMainInitFunctionFormData = function(formData) {
  const url = cmMainInitFunction_RAW_URL()
  const method = cmMainInitFunction_TYPE()
  return axios[method](url, formData)
}
export const cmMainInitFunction_RAW_URL = function() {
  return '/common/main/init-function'
}
export const cmMainInitFunction_TYPE = function() {
  return 'post'
}
export const cmMainInitFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/init-function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 加载地区缓存
 * request: cmMainLoadRegionCache
 * formData: cmMainLoadRegionCacheFormData
 * url: cmMainLoadRegionCache_URL
 * method: cmMainLoadRegionCache_TYPE
 * raw_url: cmMainLoadRegionCache_RAW_URL
 */
export const cmMainLoadRegionCache = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/load-region-cache'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainLoadRegionCacheFormData = function(formData) {
  const url = cmMainLoadRegionCache_RAW_URL()
  const method = cmMainLoadRegionCache_TYPE()
  return axios[method](url, formData)
}
export const cmMainLoadRegionCache_RAW_URL = function() {
  return '/common/main/load-region-cache'
}
export const cmMainLoadRegionCache_TYPE = function() {
  return 'get'
}
export const cmMainLoadRegionCache_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/load-region-cache'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改密码
 * request: cmMainUpdatePwd
 * formData: cmMainUpdatePwdFormData
 * url: cmMainUpdatePwd_URL
 * method: cmMainUpdatePwd_TYPE
 * raw_url: cmMainUpdatePwd_RAW_URL
 * @param oldPwd - 
 * @param newPwd - 
 */
export const cmMainUpdatePwd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['oldPwd'] !== undefined) {
    form['oldPwd'] = parameters['oldPwd']
  }
  if (parameters['oldPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: oldPwd'))
  }
  if (parameters['newPwd'] !== undefined) {
    form['newPwd'] = parameters['newPwd']
  }
  if (parameters['newPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newPwd'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMainUpdatePwdFormData = function(formData) {
  const url = cmMainUpdatePwd_RAW_URL()
  const method = cmMainUpdatePwd_TYPE()
  return axios[method](url, formData)
}
export const cmMainUpdatePwd_RAW_URL = function() {
  return '/common/main/password'
}
export const cmMainUpdatePwd_TYPE = function() {
  return 'put'
}
export const cmMainUpdatePwd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * repairDentalCariesCount
 * request: repairDentalCariesCountUsingPOST
 * formData: repairDentalCariesCountUsingPOSTFormData
 * url: repairDentalCariesCountUsingPOST_URL
 * method: repairDentalCariesCountUsingPOST_TYPE
 * raw_url: repairDentalCariesCountUsingPOST_RAW_URL
 */
export const repairDentalCariesCountUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/repair-dental-caries-count'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const repairDentalCariesCountUsingPOSTFormData = function(formData) {
  const url = repairDentalCariesCountUsingPOST_RAW_URL()
  const method = repairDentalCariesCountUsingPOST_TYPE()
  return axios[method](url, formData)
}
export const repairDentalCariesCountUsingPOST_RAW_URL = function() {
  return '/common/main/repair-dental-caries-count'
}
export const repairDentalCariesCountUsingPOST_TYPE = function() {
  return 'post'
}
export const repairDentalCariesCountUsingPOST_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/repair-dental-caries-count'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 忘记密码重置密码
 * request: cmMainResetPassword
 * formData: cmMainResetPasswordFormData
 * url: cmMainResetPassword_URL
 * method: cmMainResetPassword_TYPE
 * raw_url: cmMainResetPassword_RAW_URL
 * @param account - 手机或邮箱
 * @param newPwd - 新密码
 * @param mailCode - 邮箱验证码
 */
export const cmMainResetPassword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/reset-password'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['account'] !== undefined) {
    form['account'] = parameters['account']
  }
  if (parameters['account'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: account'))
  }
  if (parameters['newPwd'] !== undefined) {
    form['newPwd'] = parameters['newPwd']
  }
  if (parameters['newPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newPwd'))
  }
  if (parameters['mailCode'] !== undefined) {
    form['mailCode'] = parameters['mailCode']
  }
  if (parameters['mailCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mailCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMainResetPasswordFormData = function(formData) {
  const url = cmMainResetPassword_RAW_URL()
  const method = cmMainResetPassword_TYPE()
  return axios[method](url, formData)
}
export const cmMainResetPassword_RAW_URL = function() {
  return '/common/main/reset-password'
}
export const cmMainResetPassword_TYPE = function() {
  return 'put'
}
export const cmMainResetPassword_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/reset-password'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 重置下级密码
 * request: cmMainResetSubordinatePassword
 * formData: cmMainResetSubordinatePasswordFormData
 * url: cmMainResetSubordinatePassword_URL
 * method: cmMainResetSubordinatePassword_TYPE
 * raw_url: cmMainResetSubordinatePassword_RAW_URL
 * @param adminId - adminId
 * @param newPwd - 新密码
 */
export const cmMainResetSubordinatePassword = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/reset-subordinate-password/{adminId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{adminId}', `${parameters['adminId']}`)
  if (parameters['adminId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: adminId'))
  }
  if (parameters['newPwd'] !== undefined) {
    form['newPwd'] = parameters['newPwd']
  }
  if (parameters['newPwd'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newPwd'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMainResetSubordinatePasswordFormData = function(formData) {
  const url = cmMainResetSubordinatePassword_RAW_URL()
  const method = cmMainResetSubordinatePassword_TYPE()
  return axios[method](url, formData)
}
export const cmMainResetSubordinatePassword_RAW_URL = function() {
  return '/common/main/reset-subordinate-password/{adminId}'
}
export const cmMainResetSubordinatePassword_TYPE = function() {
  return 'put'
}
export const cmMainResetSubordinatePassword_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/reset-subordinate-password/{adminId}'
  path = path.replace('{adminId}', `${parameters['adminId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更新筛查数据
 * request: cmMainUpdateScreeningStatistics
 * formData: cmMainUpdateScreeningStatisticsFormData
 * url: cmMainUpdateScreeningStatistics_URL
 * method: cmMainUpdateScreeningStatistics_TYPE
 * raw_url: cmMainUpdateScreeningStatistics_RAW_URL
 */
export const cmMainUpdateScreeningStatistics = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/screening-statistics'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMainUpdateScreeningStatisticsFormData = function(formData) {
  const url = cmMainUpdateScreeningStatistics_RAW_URL()
  const method = cmMainUpdateScreeningStatistics_TYPE()
  return axios[method](url, formData)
}
export const cmMainUpdateScreeningStatistics_RAW_URL = function() {
  return '/common/main/screening-statistics'
}
export const cmMainUpdateScreeningStatistics_TYPE = function() {
  return 'post'
}
export const cmMainUpdateScreeningStatistics_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/screening-statistics'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 系统信息
 * request: cmMainSystemInfo
 * formData: cmMainSystemInfoFormData
 * url: cmMainSystemInfo_URL
 * method: cmMainSystemInfo_TYPE
 * raw_url: cmMainSystemInfo_RAW_URL
 */
export const cmMainSystemInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/system-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainSystemInfoFormData = function(formData) {
  const url = cmMainSystemInfo_RAW_URL()
  const method = cmMainSystemInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMainSystemInfo_RAW_URL = function() {
  return '/common/main/system-info'
}
export const cmMainSystemInfo_TYPE = function() {
  return 'get'
}
export const cmMainSystemInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/system-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信服务器连接测试
 * request: cmMainTestConnectWxServer
 * formData: cmMainTestConnectWxServerFormData
 * url: cmMainTestConnectWxServer_URL
 * method: cmMainTestConnectWxServer_TYPE
 * raw_url: cmMainTestConnectWxServer_RAW_URL
 */
export const cmMainTestConnectWxServer = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/test-connect-wx-server'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainTestConnectWxServerFormData = function(formData) {
  const url = cmMainTestConnectWxServer_RAW_URL()
  const method = cmMainTestConnectWxServer_TYPE()
  return axios[method](url, formData)
}
export const cmMainTestConnectWxServer_RAW_URL = function() {
  return '/common/main/test-connect-wx-server'
}
export const cmMainTestConnectWxServer_TYPE = function() {
  return 'get'
}
export const cmMainTestConnectWxServer_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/test-connect-wx-server'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户信息
 * request: cmMainUserInfo
 * formData: cmMainUserInfoFormData
 * url: cmMainUserInfo_URL
 * method: cmMainUserInfo_TYPE
 * raw_url: cmMainUserInfo_RAW_URL
 */
export const cmMainUserInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/main/user-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMainUserInfoFormData = function(formData) {
  const url = cmMainUserInfo_RAW_URL()
  const method = cmMainUserInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMainUserInfo_RAW_URL = function() {
  return '/common/main/user-info'
}
export const cmMainUserInfo_TYPE = function() {
  return 'get'
}
export const cmMainUserInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/main/user-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取账号医疗机构信息
 * request: cmMedicalInstitutionFind
 * formData: cmMedicalInstitutionFindFormData
 * url: cmMedicalInstitutionFind_URL
 * method: cmMedicalInstitutionFind_TYPE
 * raw_url: cmMedicalInstitutionFind_RAW_URL
 */
export const cmMedicalInstitutionFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/medical-institution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMedicalInstitutionFindFormData = function(formData) {
  const url = cmMedicalInstitutionFind_RAW_URL()
  const method = cmMedicalInstitutionFind_TYPE()
  return axios[method](url, formData)
}
export const cmMedicalInstitutionFind_RAW_URL = function() {
  return '/common/medical-institution'
}
export const cmMedicalInstitutionFind_TYPE = function() {
  return 'get'
}
export const cmMedicalInstitutionFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/medical-institution'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构修改预约信息
 * request: cmMedicalInstitutionUpdateAppointmentInfo
 * formData: cmMedicalInstitutionUpdateAppointmentInfoFormData
 * url: cmMedicalInstitutionUpdateAppointmentInfo_URL
 * method: cmMedicalInstitutionUpdateAppointmentInfo_TYPE
 * raw_url: cmMedicalInstitutionUpdateAppointmentInfo_RAW_URL
 * @param name - 医疗机构名称
 * @param intro - 简介
 * @param address - 地址
 * @param phone - 电话号码
 * @param appointmentGuide - 
 * @param appointmentUrl - 
 * @param picture - 
 */
export const cmMedicalInstitutionUpdateAppointmentInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/medical-institution/appointment-info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['address'] !== undefined) {
    form['address'] = parameters['address']
  }
  if (parameters['address'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: address'))
  }
  if (parameters['phone'] !== undefined) {
    form['phone'] = parameters['phone']
  }
  if (parameters['phone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: phone'))
  }
  if (parameters['appointmentGuide'] !== undefined) {
    form['appointmentGuide'] = parameters['appointmentGuide']
  }
  if (parameters['appointmentUrl'] !== undefined) {
    form['appointmentUrl'] = parameters['appointmentUrl']
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMedicalInstitutionUpdateAppointmentInfoFormData = function(formData) {
  const url = cmMedicalInstitutionUpdateAppointmentInfo_RAW_URL()
  const method = cmMedicalInstitutionUpdateAppointmentInfo_TYPE()
  return axios[method](url, formData)
}
export const cmMedicalInstitutionUpdateAppointmentInfo_RAW_URL = function() {
  return '/common/medical-institution/appointment-info'
}
export const cmMedicalInstitutionUpdateAppointmentInfo_TYPE = function() {
  return 'put'
}
export const cmMedicalInstitutionUpdateAppointmentInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/medical-institution/appointment-info'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构选择列表
 * request: cmMedicalInstitutionChoiceList
 * formData: cmMedicalInstitutionChoiceListFormData
 * url: cmMedicalInstitutionChoiceList_URL
 * method: cmMedicalInstitutionChoiceList_TYPE
 * raw_url: cmMedicalInstitutionChoiceList_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param name - 机构名称
 */
export const cmMedicalInstitutionChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/medical-institution/choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmMedicalInstitutionChoiceListFormData = function(formData) {
  const url = cmMedicalInstitutionChoiceList_RAW_URL()
  const method = cmMedicalInstitutionChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmMedicalInstitutionChoiceList_RAW_URL = function() {
  return '/common/medical-institution/choice-list'
}
export const cmMedicalInstitutionChoiceList_TYPE = function() {
  return 'get'
}
export const cmMedicalInstitutionChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/medical-institution/choice-list'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传图片
 * request: cmMedicalInstitutionUploadFile
 * formData: cmMedicalInstitutionUploadFileFormData
 * url: cmMedicalInstitutionUploadFile_URL
 * method: cmMedicalInstitutionUploadFile_TYPE
 * raw_url: cmMedicalInstitutionUploadFile_RAW_URL
 * @param file - 
 */
export const cmMedicalInstitutionUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/medical-institution/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmMedicalInstitutionUploadFileFormData = function(formData) {
  const url = cmMedicalInstitutionUploadFile_RAW_URL()
  const method = cmMedicalInstitutionUploadFile_TYPE()
  return axios[method](url, formData)
}
export const cmMedicalInstitutionUploadFile_RAW_URL = function() {
  return '/common/medical-institution/file'
}
export const cmMedicalInstitutionUploadFile_TYPE = function() {
  return 'post'
}
export const cmMedicalInstitutionUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/medical-institution/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构转让负责人
 * request: cmMedicalInstitutionUpdateManager
 * formData: cmMedicalInstitutionUpdateManagerFormData
 * url: cmMedicalInstitutionUpdateManager_URL
 * method: cmMedicalInstitutionUpdateManager_TYPE
 * raw_url: cmMedicalInstitutionUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const cmMedicalInstitutionUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/medical-institution/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmMedicalInstitutionUpdateManagerFormData = function(formData) {
  const url = cmMedicalInstitutionUpdateManager_RAW_URL()
  const method = cmMedicalInstitutionUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const cmMedicalInstitutionUpdateManager_RAW_URL = function() {
  return '/common/medical-institution/manager'
}
export const cmMedicalInstitutionUpdateManager_TYPE = function() {
  return 'put'
}
export const cmMedicalInstitutionUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/medical-institution/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 日志列表
 * request: cmOperationLogList
 * formData: cmOperationLogListFormData
 * url: cmOperationLogList_URL
 * method: cmOperationLogList_TYPE
 * raw_url: cmOperationLogList_RAW_URL
 * @param year - 年份
 * @param createTime - 区间时间起始
 * @param upperCreateTime - 区间时间结束
 * @param operatorName - 操作员
 * @param operateType - 操作类型
 * @param screeningPlanId - 筛查计划id
 * @param type - 类型
 * @param organizationName - 机构名称(单位/组织)
 * @param mobile - 手机号
 * @param remark - 备注/操作描述
 * @param operatorIp - IP地址
 * @param loginType - 登录类型/端口
 * @param status - 请求结果
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmOperationLogList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/operation-log'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmOperationLogListFormData = function(formData) {
  const url = cmOperationLogList_RAW_URL()
  const method = cmOperationLogList_TYPE()
  return axios[method](url, formData)
}
export const cmOperationLogList_RAW_URL = function() {
  return '/common/operation-log'
}
export const cmOperationLogList_TYPE = function() {
  return 'get'
}
export const cmOperationLogList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/operation-log'
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出登录日志
 * request: cmOperationLogSignInRecordExport
 * formData: cmOperationLogSignInRecordExportFormData
 * url: cmOperationLogSignInRecordExport_URL
 * method: cmOperationLogSignInRecordExport_TYPE
 * raw_url: cmOperationLogSignInRecordExport_RAW_URL
 * @param year - 年份
 * @param createTime - 区间时间起始
 * @param upperCreateTime - 区间时间结束
 * @param operatorName - 操作员
 * @param operateType - 操作类型
 * @param screeningPlanId - 筛查计划id
 * @param organizationName - 机构名称(单位/组织)
 * @param mobile - 手机号
 * @param remark - 备注/操作描述
 * @param operatorIp - IP地址
 * @param loginType - 登录类型/端口
 * @param status - 请求结果
 */
export const cmOperationLogSignInRecordExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/operation-log/export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmOperationLogSignInRecordExportFormData = function(formData) {
  const url = cmOperationLogSignInRecordExport_RAW_URL()
  const method = cmOperationLogSignInRecordExport_TYPE()
  return axios[method](url, formData)
}
export const cmOperationLogSignInRecordExport_RAW_URL = function() {
  return '/common/operation-log/export'
}
export const cmOperationLogSignInRecordExport_TYPE = function() {
  return 'get'
}
export const cmOperationLogSignInRecordExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/operation-log/export'
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出操作日志
 * request: cmOperationLogOperationRecordExport
 * formData: cmOperationLogOperationRecordExportFormData
 * url: cmOperationLogOperationRecordExport_URL
 * method: cmOperationLogOperationRecordExport_TYPE
 * raw_url: cmOperationLogOperationRecordExport_RAW_URL
 * @param year - 年份
 * @param createTime - 区间时间起始
 * @param upperCreateTime - 区间时间结束
 * @param operatorName - 操作员
 * @param operateType - 操作类型
 * @param screeningPlanId - 筛查计划id
 * @param organizationName - 机构名称(单位/组织)
 * @param mobile - 手机号
 * @param remark - 备注/操作描述
 * @param operatorIp - IP地址
 * @param loginType - 登录类型/端口
 * @param status - 请求结果
 */
export const cmOperationLogOperationRecordExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/operation-log/operation-record-export'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmOperationLogOperationRecordExportFormData = function(formData) {
  const url = cmOperationLogOperationRecordExport_RAW_URL()
  const method = cmOperationLogOperationRecordExport_TYPE()
  return axios[method](url, formData)
}
export const cmOperationLogOperationRecordExport_RAW_URL = function() {
  return '/common/operation-log/operation-record-export'
}
export const cmOperationLogOperationRecordExport_TYPE = function() {
  return 'get'
}
export const cmOperationLogOperationRecordExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/operation-log/operation-record-export'
  if (parameters['year'] !== undefined) {
    queryParameters['year'] = parameters['year']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['operatorName'] !== undefined) {
    queryParameters['operatorName'] = parameters['operatorName']
  }
  if (parameters['operateType'] !== undefined) {
    queryParameters['operateType'] = parameters['operateType']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['organizationName'] !== undefined) {
    queryParameters['organizationName'] = parameters['organizationName']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['remark'] !== undefined) {
    queryParameters['remark'] = parameters['remark']
  }
  if (parameters['operatorIp'] !== undefined) {
    queryParameters['operatorIp'] = parameters['operatorIp']
  }
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType;'] = parameters['loginType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status;'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控记录列表
 * request: cmQualityControlRecordList
 * formData: cmQualityControlRecordListFormData
 * url: cmQualityControlRecordList_URL
 * method: cmQualityControlRecordList_TYPE
 * raw_url: cmQualityControlRecordList_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmQualityControlRecordList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordListFormData = function(formData) {
  const url = cmQualityControlRecordList_RAW_URL()
  const method = cmQualityControlRecordList_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordList_RAW_URL = function() {
  return '/common/quality-control-record'
}
export const cmQualityControlRecordList_TYPE = function() {
  return 'get'
}
export const cmQualityControlRecordList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record'
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省市区质控记录详情
 * request: cmQualityControlRecordFindAll
 * formData: cmQualityControlRecordFindAllFormData
 * url: cmQualityControlRecordFindAll_URL
 * method: cmQualityControlRecordFindAll_TYPE
 * raw_url: cmQualityControlRecordFindAll_RAW_URL
 * @param screeningPlanId - 
 * @param provinceCode - 
 * @param cityCode - 
 * @param districtCode - 
 * @param screeningSchoolId - 
 */
export const cmQualityControlRecordFindAll = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordFindAllFormData = function(formData) {
  const url = cmQualityControlRecordFindAll_RAW_URL()
  const method = cmQualityControlRecordFindAll_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordFindAll_RAW_URL = function() {
  return '/common/quality-control-record/all'
}
export const cmQualityControlRecordFindAll_TYPE = function() {
  return 'get'
}
export const cmQualityControlRecordFindAll_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/all'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 有质检报告的时间列表
 * request: cmQualityControlListDateHasReport
 * formData: cmQualityControlListDateHasReportFormData
 * url: cmQualityControlListDateHasReport_URL
 * method: cmQualityControlListDateHasReport_TYPE
 * raw_url: cmQualityControlListDateHasReport_RAW_URL
 * @param planId - planId
 * @param schoolId - schoolId
 */
export const cmQualityControlListDateHasReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/date-has-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlListDateHasReportFormData = function(formData) {
  const url = cmQualityControlListDateHasReport_RAW_URL()
  const method = cmQualityControlListDateHasReport_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlListDateHasReport_RAW_URL = function() {
  return '/common/quality-control-record/date-has-report'
}
export const cmQualityControlListDateHasReport_TYPE = function() {
  return 'get'
}
export const cmQualityControlListDateHasReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/date-has-report'
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队质控记录详情
 * request: cmQualityControlRecordDetail
 * formData: cmQualityControlRecordDetailFormData
 * url: cmQualityControlRecordDetail_URL
 * method: cmQualityControlRecordDetail_TYPE
 * raw_url: cmQualityControlRecordDetail_RAW_URL
 * @param staTime - 
 * @param endTime - 
 * @param teamId - 
 */
export const cmQualityControlRecordDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/detail'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['staTime'] !== undefined) {
    queryParameters['staTime'] = parameters['staTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['teamId'] !== undefined) {
    queryParameters['teamId'] = parameters['teamId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordDetailFormData = function(formData) {
  const url = cmQualityControlRecordDetail_RAW_URL()
  const method = cmQualityControlRecordDetail_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordDetail_RAW_URL = function() {
  return '/common/quality-control-record/detail'
}
export const cmQualityControlRecordDetail_TYPE = function() {
  return 'post'
}
export const cmQualityControlRecordDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/detail'
  if (parameters['staTime'] !== undefined) {
    queryParameters['staTime'] = parameters['staTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['teamId'] !== undefined) {
    queryParameters['teamId'] = parameters['teamId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 生成质控记录
 * request: cmQualityControlRecordSave
 * formData: cmQualityControlRecordSaveFormData
 * url: cmQualityControlRecordSave_URL
 * method: cmQualityControlRecordSave_TYPE
 * raw_url: cmQualityControlRecordSave_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param qcDate - 质控日期
 */
export const cmQualityControlRecordSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/quality-control-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['qcDate'] !== undefined) {
    form['qcDate'] = parameters['qcDate']
  }
  if (parameters['qcDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: qcDate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordSaveFormData = function(formData) {
  const url = cmQualityControlRecordSave_RAW_URL()
  const method = cmQualityControlRecordSave_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordSave_RAW_URL = function() {
  return '/common/quality-control-record/quality-control-record'
}
export const cmQualityControlRecordSave_TYPE = function() {
  return 'post'
}
export const cmQualityControlRecordSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/quality-control-record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查队的复测率、误差率统计
 * request: cmRetestErrorRateStatistics
 * formData: cmRetestErrorRateStatisticsFormData
 * url: cmRetestErrorRateStatistics_URL
 * method: cmRetestErrorRateStatistics_TYPE
 * raw_url: cmRetestErrorRateStatistics_RAW_URL
 */
export const cmRetestErrorRateStatistics = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/retest-error-rate-statistics'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRetestErrorRateStatisticsFormData = function(formData) {
  const url = cmRetestErrorRateStatistics_RAW_URL()
  const method = cmRetestErrorRateStatistics_TYPE()
  return axios[method](url, formData)
}
export const cmRetestErrorRateStatistics_RAW_URL = function() {
  return '/common/quality-control-record/retest-error-rate-statistics'
}
export const cmRetestErrorRateStatistics_TYPE = function() {
  return 'get'
}
export const cmRetestErrorRateStatistics_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/retest-error-rate-statistics'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出质检报告
 * request: cmQualityControlRecordExport
 * formData: cmQualityControlRecordExportFormData
 * url: cmQualityControlRecordExport_URL
 * method: cmQualityControlRecordExport_TYPE
 * raw_url: cmQualityControlRecordExport_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dateMillis - dateMillis
 * @param accessToken - 
 * @param screeningPlanId - 
 */
export const cmQualityControlRecordExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/screeningSchool/{screeningSchoolId}/export/{dateMillis}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  path = path.replace('{dateMillis}', `${parameters['dateMillis']}`)
  if (parameters['dateMillis'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateMillis'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordExportFormData = function(formData) {
  const url = cmQualityControlRecordExport_RAW_URL()
  const method = cmQualityControlRecordExport_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordExport_RAW_URL = function() {
  return '/common/quality-control-record/screeningSchool/{screeningSchoolId}/export/{dateMillis}'
}
export const cmQualityControlRecordExport_TYPE = function() {
  return 'get'
}
export const cmQualityControlRecordExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/screeningSchool/{screeningSchoolId}/export/{dateMillis}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  path = path.replace('{dateMillis}', `${parameters['dateMillis']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控记录详情
 * request: cmQualityControlRecordFind
 * formData: cmQualityControlRecordFindFormData
 * url: cmQualityControlRecordFind_URL
 * method: cmQualityControlRecordFind_TYPE
 * raw_url: cmQualityControlRecordFind_RAW_URL
 * @param qualityControlRecordId - qualityControlRecordId
 */
export const cmQualityControlRecordFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/quality-control-record/{qualityControlRecordId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{qualityControlRecordId}', `${parameters['qualityControlRecordId']}`)
  if (parameters['qualityControlRecordId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: qualityControlRecordId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQualityControlRecordFindFormData = function(formData) {
  const url = cmQualityControlRecordFind_RAW_URL()
  const method = cmQualityControlRecordFind_TYPE()
  return axios[method](url, formData)
}
export const cmQualityControlRecordFind_RAW_URL = function() {
  return '/common/quality-control-record/{qualityControlRecordId}'
}
export const cmQualityControlRecordFind_TYPE = function() {
  return 'get'
}
export const cmQualityControlRecordFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/quality-control-record/{qualityControlRecordId}'
  path = path.replace('{qualityControlRecordId}', `${parameters['qualityControlRecordId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取国家卫健委新冠肺炎对学生视力影响评估调查表
 * request: cmCovid19SvisqFindByStudent
 * formData: cmCovid19SvisqFindByStudentFormData
 * url: cmCovid19SvisqFindByStudent_URL
 * method: cmCovid19SvisqFindByStudent_TYPE
 * raw_url: cmCovid19SvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmCovid19SvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmCovid19SvisqFindByStudentFormData = function(formData) {
  const url = cmCovid19SvisqFindByStudent_RAW_URL()
  const method = cmCovid19SvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const cmCovid19SvisqFindByStudent_RAW_URL = function() {
  return '/common/questionary/by-student/{studentId}'
}
export const cmCovid19SvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const cmCovid19SvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看中学学生健康状况及影响因素调查表
 * request: cmQuestionaryFindMiddleStudent
 * formData: cmQuestionaryFindMiddleStudentFormData
 * url: cmQuestionaryFindMiddleStudent_URL
 * method: cmQuestionaryFindMiddleStudent_TYPE
 * raw_url: cmQuestionaryFindMiddleStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmQuestionaryFindMiddleStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/middle-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryFindMiddleStudentFormData = function(formData) {
  const url = cmQuestionaryFindMiddleStudent_RAW_URL()
  const method = cmQuestionaryFindMiddleStudent_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryFindMiddleStudent_RAW_URL = function() {
  return '/common/questionary/middle-student/{studentId}'
}
export const cmQuestionaryFindMiddleStudent_TYPE = function() {
  return 'get'
}
export const cmQuestionaryFindMiddleStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/middle-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看小学学生健康状况及影响因素调查表
 * request: cmQuestionaryFindPrimaryStudent
 * formData: cmQuestionaryFindPrimaryStudentFormData
 * url: cmQuestionaryFindPrimaryStudent_URL
 * method: cmQuestionaryFindPrimaryStudent_TYPE
 * raw_url: cmQuestionaryFindPrimaryStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmQuestionaryFindPrimaryStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/primary-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryFindPrimaryStudentFormData = function(formData) {
  const url = cmQuestionaryFindPrimaryStudent_RAW_URL()
  const method = cmQuestionaryFindPrimaryStudent_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryFindPrimaryStudent_RAW_URL = function() {
  return '/common/questionary/primary-student/{studentId}'
}
export const cmQuestionaryFindPrimaryStudent_TYPE = function() {
  return 'get'
}
export const cmQuestionaryFindPrimaryStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/primary-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: cmQuestionnaireAll
 * formData: cmQuestionnaireAllFormData
 * url: cmQuestionnaireAll_URL
 * method: cmQuestionnaireAll_TYPE
 * raw_url: cmQuestionnaireAll_RAW_URL
 * @param provinceCode - 省
 * @param cityCode - 市
 * @param districtCode - 区
 * @param screeningPlanId - 筛查计划id
 */
export const cmQuestionnaireAll = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/questionnaire-all'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionnaireAllFormData = function(formData) {
  const url = cmQuestionnaireAll_RAW_URL()
  const method = cmQuestionnaireAll_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionnaireAll_RAW_URL = function() {
  return '/common/questionary/questionnaire-all'
}
export const cmQuestionnaireAll_TYPE = function() {
  return 'get'
}
export const cmQuestionnaireAll_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/questionnaire-all'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存2021年疫情防控期间学生视力影响因素专项调查表
 * request: cmCovid19SvisqSave
 * formData: cmCovid19SvisqSaveFormData
 * url: cmCovid19SvisqSave_URL
 * method: cmCovid19SvisqSave_TYPE
 * raw_url: cmCovid19SvisqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04LeftVision - 
 * @param a04RightVision - 
 * @param a05 - 
 * @param a06 - 
 * @param a06Day - 当a06填未隔离，则不填
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b06 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b17OtherDescription - 
 * @param b18 - 
 * @param c01 - 
 * @param c011 - c01选是则必填，反之不填
 * @param c011OtherDescription - 
 * @param c012 - c01选是则必填，反之不填
 * @param c013 - c01选是则必填，反之不填
 * @param c013Date - 
 * @param c014 - c01选是则必填，反之不填
 * @param c014OtherDescription - c01选是则必填，反之不填
 * @param c015 - c01选是则必填，反之不填; 值','隔开
 * @param c016 - c01选是则必填，反之不填; 值','隔开
 * @param c0171 - 单位分钟
 * @param c0172 - 单位分钟
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c081 - 
 * @param c09 - 
 * @param c091 - 
 * @param c11 - 
 * @param c12 - 
 * @param c121 - 
 * @param c121Minute - 
 * @param c123 - 
 * @param c123Minute - 
 * @param c14 - 
 * @param c14Minute - 
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 */
export const cmCovid19SvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-csvsq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04LeftVision'] !== undefined) {
    form['a04LeftVision'] = parameters['a04LeftVision']
  }
  if (parameters['a04LeftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04LeftVision'))
  }
  if (parameters['a04RightVision'] !== undefined) {
    form['a04RightVision'] = parameters['a04RightVision']
  }
  if (parameters['a04RightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04RightVision'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a06Day'] !== undefined) {
    form['a06Day'] = parameters['a06Day']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17OtherDescription'] !== undefined) {
    form['b17OtherDescription'] = parameters['b17OtherDescription']
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c011'] !== undefined) {
    form['c011'] = parameters['c011']
  }
  if (parameters['c011OtherDescription'] !== undefined) {
    form['c011OtherDescription'] = parameters['c011OtherDescription']
  }
  if (parameters['c012'] !== undefined) {
    form['c012'] = parameters['c012']
  }
  if (parameters['c013'] !== undefined) {
    form['c013'] = parameters['c013']
  }
  if (parameters['c013Date'] !== undefined) {
    form['c013Date'] = parameters['c013Date']
  }
  if (parameters['c014'] !== undefined) {
    form['c014'] = parameters['c014']
  }
  if (parameters['c014OtherDescription'] !== undefined) {
    form['c014OtherDescription'] = parameters['c014OtherDescription']
  }
  if (parameters['c015'] !== undefined) {
    form['c015'] = parameters['c015']
  }
  if (parameters['c016'] !== undefined) {
    form['c016'] = parameters['c016']
  }
  if (parameters['c0171'] !== undefined) {
    form['c0171'] = parameters['c0171']
  }
  if (parameters['c0172'] !== undefined) {
    form['c0172'] = parameters['c0172']
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c081'] !== undefined) {
    form['c081'] = parameters['c081']
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c091'] !== undefined) {
    form['c091'] = parameters['c091']
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c12'] !== undefined) {
    form['c12'] = parameters['c12']
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121Minute'] !== undefined) {
    form['c121Minute'] = parameters['c121Minute']
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123Minute'] !== undefined) {
    form['c123Minute'] = parameters['c123Minute']
  }
  if (parameters['c14'] !== undefined) {
    form['c14'] = parameters['c14']
  }
  if (parameters['c14Minute'] !== undefined) {
    form['c14Minute'] = parameters['c14Minute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmCovid19SvisqSaveFormData = function(formData) {
  const url = cmCovid19SvisqSave_RAW_URL()
  const method = cmCovid19SvisqSave_TYPE()
  return axios[method](url, formData)
}
export const cmCovid19SvisqSave_RAW_URL = function() {
  return '/common/questionary/save-csvsq/{studentId}'
}
export const cmCovid19SvisqSave_TYPE = function() {
  return 'post'
}
export const cmCovid19SvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-csvsq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查表
 * request: cmQuestionarySaveMshiq
 * formData: cmQuestionarySaveMshiqFormData
 * url: cmQuestionarySaveMshiq_URL
 * method: cmQuestionarySaveMshiq_TYPE
 * raw_url: cmQuestionarySaveMshiq_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 
 * @param d06Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d07 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d08 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d081 - 每次服用止咳药水的量是多少？
 * @param d09 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填
 * @param f02 - 当F01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param f03 - 当F01的值为2时必填，反之不填
 * @param f04 - 当F01的值为2时必填，反之不填
 * @param f05 - 当F01的值为2时必填，反之不填
 * @param g01 - 
 * @param g02 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 
 * @param h05 - 
 * @param h06 - 
 * @param h07 - 
 * @param h08 - 
 * @param h09 - 
 * @param h10 - 
 * @param h11 - 
 * @param h12 - 
 * @param h13 - 
 * @param h14 - 
 * @param h15 - 
 * @param h16 - 
 * @param h17 - 
 * @param h18 - 
 * @param h19 - 
 * @param h20 - 
 * @param h21 - 感觉紧张，焦虑或急切
 * @param h22 - 不能够停止或控制担忧
 * @param h23 - 对各种恶样的事情担忧过多
 * @param h24 - 很难放松下来
 * @param h25 - 由于不安而无法静坐
 * @param h26 - 变得容易烦恼或急躁
 * @param h27 - 感到似乎将有可怕的事情发生而害怕
 * @param h28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param h281 - 你寻求了什么形式的帮助
 * @param h282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param i01 - 
 * @param i011 - 
 * @param i02 - 
 * @param i021 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const cmQuestionarySaveMshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-mshiq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['d06Description'] !== undefined) {
    form['d06Description'] = parameters['d06Description']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d07'))
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d08'))
  }
  if (parameters['d081'] !== undefined) {
    form['d081'] = parameters['d081']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['h21'] !== undefined) {
    form['h21'] = parameters['h21']
  }
  if (parameters['h21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h21'))
  }
  if (parameters['h22'] !== undefined) {
    form['h22'] = parameters['h22']
  }
  if (parameters['h22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h22'))
  }
  if (parameters['h23'] !== undefined) {
    form['h23'] = parameters['h23']
  }
  if (parameters['h23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h23'))
  }
  if (parameters['h24'] !== undefined) {
    form['h24'] = parameters['h24']
  }
  if (parameters['h24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h24'))
  }
  if (parameters['h25'] !== undefined) {
    form['h25'] = parameters['h25']
  }
  if (parameters['h25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h25'))
  }
  if (parameters['h26'] !== undefined) {
    form['h26'] = parameters['h26']
  }
  if (parameters['h26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h26'))
  }
  if (parameters['h27'] !== undefined) {
    form['h27'] = parameters['h27']
  }
  if (parameters['h27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h27'))
  }
  if (parameters['h28'] !== undefined) {
    form['h28'] = parameters['h28']
  }
  if (parameters['h28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h28'))
  }
  if (parameters['h281'] !== undefined) {
    form['h281'] = parameters['h281']
  }
  if (parameters['h282'] !== undefined) {
    form['h282'] = parameters['h282']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySaveMshiqFormData = function(formData) {
  const url = cmQuestionarySaveMshiq_RAW_URL()
  const method = cmQuestionarySaveMshiq_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySaveMshiq_RAW_URL = function() {
  return '/common/questionary/save-mshiq/{studentId}'
}
export const cmQuestionarySaveMshiq_TYPE = function() {
  return 'post'
}
export const cmQuestionarySaveMshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-mshiq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查表
 * request: cmQuestionarySavePshiq
 * formData: cmQuestionarySavePshiqFormData
 * url: cmQuestionarySavePshiq_URL
 * method: cmQuestionarySavePshiq_TYPE
 * raw_url: cmQuestionarySavePshiq_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d03 - 
 * @param d04 - 
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填
 * @param e01 - 
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填
 * @param e02 - 当E01的值为2时必填，反之不填.过去7天，你平均每天使用耳机时间数选项
 * @param e03 - 当E01的值为2时必填，反之不填
 * @param e04 - 当E01的值为2时必填，反之不填
 * @param e05 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f011 - 
 * @param f02 - 
 * @param f021 - 
 * @param f05 - 
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const cmQuestionarySavePshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-pshiq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySavePshiqFormData = function(formData) {
  const url = cmQuestionarySavePshiq_RAW_URL()
  const method = cmQuestionarySavePshiq_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySavePshiq_RAW_URL = function() {
  return '/common/questionary/save-pshiq/{studentId}'
}
export const cmQuestionarySavePshiq_TYPE = function() {
  return 'post'
}
export const cmQuestionarySavePshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-pshiq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生新冠肺炎专项调查表
 * request: cmQuestionarySaveScsq
 * formData: cmQuestionarySaveScsqFormData
 * url: cmQuestionarySaveScsq_URL
 * method: cmQuestionarySaveScsq_TYPE
 * raw_url: cmQuestionarySaveScsq_RAW_URL
 * @param studentId - studentId
 * @param a01 - 
 * @param a02 - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a06 - 
 * @param a07 - 
 * @param a071Day - 
 * @param a072Day - 
 * @param a08 - 
 * @param a08Day - 
 * @param a09 - 
 * @param b01 - 
 * @param b01StartDate - 
 * @param b01EndDate - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b05OtherDescription - 
 * @param b06 - 
 * @param b0601 - 
 * @param b0602 - 
 * @param b0603 - 
 * @param b0604 - 
 * @param b0605 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b18 - 
 * @param b19 - 
 * @param b20 - 
 * @param b201 - 
 * @param b21 - 
 * @param b21OtherDescription - 
 * @param b22 - 
 * @param b23 - 
 * @param b24 - 
 * @param b25 - 
 * @param b26 - 
 * @param b27 - 
 * @param b28 - 
 * @param b28OtherDescription - 
 * @param b29 - 
 * @param b30 - 
 * @param b31 - 
 * @param b32 - 
 * @param b32OtherDescription - 
 * @param b33 - 
 */
export const cmQuestionarySaveScsq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-scsq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['a01'] !== undefined) {
    form['a01'] = parameters['a01']
  }
  if (parameters['a01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a01'))
  }
  if (parameters['a02'] !== undefined) {
    form['a02'] = parameters['a02']
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a071Day'] !== undefined) {
    form['a071Day'] = parameters['a071Day']
  }
  if (parameters['a072Day'] !== undefined) {
    form['a072Day'] = parameters['a072Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Day'] !== undefined) {
    form['a08Day'] = parameters['a08Day']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b01StartDate'] !== undefined) {
    form['b01StartDate'] = parameters['b01StartDate']
  }
  if (parameters['b01EndDate'] !== undefined) {
    form['b01EndDate'] = parameters['b01EndDate']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b05OtherDescription'] !== undefined) {
    form['b05OtherDescription'] = parameters['b05OtherDescription']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b0601'] !== undefined) {
    form['b0601'] = parameters['b0601']
  }
  if (parameters['b0601'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0601'))
  }
  if (parameters['b0602'] !== undefined) {
    form['b0602'] = parameters['b0602']
  }
  if (parameters['b0602'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0602'))
  }
  if (parameters['b0603'] !== undefined) {
    form['b0603'] = parameters['b0603']
  }
  if (parameters['b0603'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0603'))
  }
  if (parameters['b0604'] !== undefined) {
    form['b0604'] = parameters['b0604']
  }
  if (parameters['b0604'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0604'))
  }
  if (parameters['b0605'] !== undefined) {
    form['b0605'] = parameters['b0605']
  }
  if (parameters['b0605'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0605'))
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b11'))
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b12'))
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b13'))
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b14'))
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b15'))
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b16'))
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b17'))
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['b18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b18'))
  }
  if (parameters['b19'] !== undefined) {
    form['b19'] = parameters['b19']
  }
  if (parameters['b19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b19'))
  }
  if (parameters['b20'] !== undefined) {
    form['b20'] = parameters['b20']
  }
  if (parameters['b20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b20'))
  }
  if (parameters['b201'] !== undefined) {
    form['b201'] = parameters['b201']
  }
  if (parameters['b21'] !== undefined) {
    form['b21'] = parameters['b21']
  }
  if (parameters['b21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b21'))
  }
  if (parameters['b21OtherDescription'] !== undefined) {
    form['b21OtherDescription'] = parameters['b21OtherDescription']
  }
  if (parameters['b22'] !== undefined) {
    form['b22'] = parameters['b22']
  }
  if (parameters['b22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b22'))
  }
  if (parameters['b23'] !== undefined) {
    form['b23'] = parameters['b23']
  }
  if (parameters['b23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b23'))
  }
  if (parameters['b24'] !== undefined) {
    form['b24'] = parameters['b24']
  }
  if (parameters['b24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b24'))
  }
  if (parameters['b25'] !== undefined) {
    form['b25'] = parameters['b25']
  }
  if (parameters['b25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b25'))
  }
  if (parameters['b26'] !== undefined) {
    form['b26'] = parameters['b26']
  }
  if (parameters['b26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b26'))
  }
  if (parameters['b27'] !== undefined) {
    form['b27'] = parameters['b27']
  }
  if (parameters['b27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b27'))
  }
  if (parameters['b28'] !== undefined) {
    form['b28'] = parameters['b28']
  }
  if (parameters['b28OtherDescription'] !== undefined) {
    form['b28OtherDescription'] = parameters['b28OtherDescription']
  }
  if (parameters['b29'] !== undefined) {
    form['b29'] = parameters['b29']
  }
  if (parameters['b29'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b29'))
  }
  if (parameters['b30'] !== undefined) {
    form['b30'] = parameters['b30']
  }
  if (parameters['b30'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b30'))
  }
  if (parameters['b31'] !== undefined) {
    form['b31'] = parameters['b31']
  }
  if (parameters['b31'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b31'))
  }
  if (parameters['b32'] !== undefined) {
    form['b32'] = parameters['b32']
  }
  if (parameters['b32'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b32'))
  }
  if (parameters['b32OtherDescription'] !== undefined) {
    form['b32OtherDescription'] = parameters['b32OtherDescription']
  }
  if (parameters['b33'] !== undefined) {
    form['b33'] = parameters['b33']
  }
  if (parameters['b33'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b33'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySaveScsqFormData = function(formData) {
  const url = cmQuestionarySaveScsq_RAW_URL()
  const method = cmQuestionarySaveScsq_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySaveScsq_RAW_URL = function() {
  return '/common/questionary/save-scsq/{studentId}'
}
export const cmQuestionarySaveScsq_TYPE = function() {
  return 'post'
}
export const cmQuestionarySaveScsq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-scsq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查表
 * request: cmQuestionarySaveSpvisq
 * formData: cmQuestionarySaveSpvisqFormData
 * url: cmQuestionarySaveSpvisq_URL
 * method: cmQuestionarySaveSpvisq_TYPE
 * raw_url: cmQuestionarySaveSpvisq_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param e04 - 
 * @param e04Time - 当E04选中选项2时必填，反之不填
 * @param e04NonLearningTime - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间非学习目的使用分钟数
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const cmQuestionarySaveSpvisq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-spvisq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e04Time'] !== undefined) {
    form['e04Time'] = parameters['e04Time']
  }
  if (parameters['e04NonLearningTime'] !== undefined) {
    form['e04NonLearningTime'] = parameters['e04NonLearningTime']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySaveSpvisqFormData = function(formData) {
  const url = cmQuestionarySaveSpvisq_RAW_URL()
  const method = cmQuestionarySaveSpvisq_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySaveSpvisq_RAW_URL = function() {
  return '/common/questionary/save-spvisq/{studentId}'
}
export const cmQuestionarySaveSpvisq_TYPE = function() {
  return 'post'
}
export const cmQuestionarySaveSpvisq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-spvisq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查表
 * request: cmQuestionarySaveUshiq
 * formData: cmQuestionarySaveUshiqFormData
 * url: cmQuestionarySaveUshiq_URL
 * method: cmQuestionarySaveUshiq_TYPE
 * raw_url: cmQuestionarySaveUshiq_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c061 - 
 * @param c062 - 
 * @param c063 - 
 * @param c064 - 
 * @param c07 - 
 * @param c08 - 
 * @param c121 - 在公共网页上受到他人的辱骂？
 * @param c122 - 曾收到侮辱性的信息？
 * @param c123 - 有人将令我尴尬的照片或视频上传到网络上？
 * @param c124 - 某人用假的身份给我发送令我难堪的信息？
 * @param c125 - 有人在网络上散布我的谣言(如社交网站等)？
 * @param c126 - 曾有人未经我的允许转发或公开我的私人信息？
 * @param c127 - 曾经受到威胁性或恐吓性的信息？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 当D05的值为1时必填，反之不填
 * @param d07 - 当D05的值为1时必填，反之不填
 * @param d08 - 当D05的值为1时必填，反之不填
 * @param d09 - 
 * @param d09Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d10 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d11 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d111 - 每次服用止咳药水的量是多少？
 * @param d12 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f03Time - 当F03的值为2时必填，反之不填
 * @param f04 - 
 * @param f05 - 
 * @param f06 - 
 * @param f06Time - 当F06的值为2时必填，反之不填
 * @param g01 - 
 * @param h01 - 
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填
 * @param h02 - 当H01的值为2时必填，反之不填，你平均每天使用耳机分钟数 --> 时间选项
 * @param h03 - 当H01的值为2时必填，反之不填
 * @param h04 - 当H01的值为2时必填，反之不填
 * @param h05 - 当H01的值为2时必填，反之不填
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i04 - 当I03的值不为1时必填，反之不填
 * @param i05 - 当I03的值不为1时必填，反之不填
 * @param i06 - 当I03的值不为1时必填，反之不填
 * @param i07 - 当I03的值不为1时必填，反之不填
 * @param i08 - 当I03的值不为1时必填，反之不填
 * @param i09 - 当I03的值不为1时必填，反之不填
 * @param j01 - 
 * @param j02 - 
 * @param j03 - 
 * @param j04 - 
 * @param j05 - 
 * @param j06 - 
 * @param j07 - 
 * @param j08 - 
 * @param j09 - 
 * @param j10 - 
 * @param j11 - 
 * @param j12 - 
 * @param j13 - 
 * @param j14 - 
 * @param j15 - 
 * @param j16 - 
 * @param j17 - 
 * @param j18 - 
 * @param j19 - 
 * @param j20 - 
 * @param j21 - 感觉紧张，焦虑或急切
 * @param j22 - 不能够停止或控制担忧
 * @param j23 - 对各种恶样的事情担忧过多
 * @param j24 - 很难放松下来
 * @param j25 - 由于不安而无法静坐
 * @param j26 - 变得容易烦恼或急躁
 * @param j27 - 感到似乎将有可怕的事情发生而害怕
 * @param j28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param j281 - 你寻求了什么形式的帮助
 * @param j282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param l01 - 
 * @param l02 - 
 * @param l03 - 
 * @param l03Week - 
 * @param l04 - 
 * @param l05 - 
 * @param l06 - 
 * @param l07 - 
 * @param l08 - 
 */
export const cmQuestionarySaveUshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/save-ushiq/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c061'] !== undefined) {
    form['c061'] = parameters['c061']
  }
  if (parameters['c061'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c061'))
  }
  if (parameters['c062'] !== undefined) {
    form['c062'] = parameters['c062']
  }
  if (parameters['c062'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c062'))
  }
  if (parameters['c063'] !== undefined) {
    form['c063'] = parameters['c063']
  }
  if (parameters['c063'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c063'))
  }
  if (parameters['c064'] !== undefined) {
    form['c064'] = parameters['c064']
  }
  if (parameters['c064'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c064'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c121'))
  }
  if (parameters['c122'] !== undefined) {
    form['c122'] = parameters['c122']
  }
  if (parameters['c122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c122'))
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c123'))
  }
  if (parameters['c124'] !== undefined) {
    form['c124'] = parameters['c124']
  }
  if (parameters['c124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c124'))
  }
  if (parameters['c125'] !== undefined) {
    form['c125'] = parameters['c125']
  }
  if (parameters['c125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c125'))
  }
  if (parameters['c126'] !== undefined) {
    form['c126'] = parameters['c126']
  }
  if (parameters['c126'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c126'))
  }
  if (parameters['c127'] !== undefined) {
    form['c127'] = parameters['c127']
  }
  if (parameters['c127'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c127'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['d09Description'] !== undefined) {
    form['d09Description'] = parameters['d09Description']
  }
  if (parameters['d10'] !== undefined) {
    form['d10'] = parameters['d10']
  }
  if (parameters['d10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d10'))
  }
  if (parameters['d11'] !== undefined) {
    form['d11'] = parameters['d11']
  }
  if (parameters['d11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d11'))
  }
  if (parameters['d111'] !== undefined) {
    form['d111'] = parameters['d111']
  }
  if (parameters['d12'] !== undefined) {
    form['d12'] = parameters['d12']
  }
  if (parameters['d12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d12'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f06Time'] !== undefined) {
    form['f06Time'] = parameters['f06Time']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['j21'] !== undefined) {
    form['j21'] = parameters['j21']
  }
  if (parameters['j21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j21'))
  }
  if (parameters['j22'] !== undefined) {
    form['j22'] = parameters['j22']
  }
  if (parameters['j22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j22'))
  }
  if (parameters['j23'] !== undefined) {
    form['j23'] = parameters['j23']
  }
  if (parameters['j23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j23'))
  }
  if (parameters['j24'] !== undefined) {
    form['j24'] = parameters['j24']
  }
  if (parameters['j24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j24'))
  }
  if (parameters['j25'] !== undefined) {
    form['j25'] = parameters['j25']
  }
  if (parameters['j25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j25'))
  }
  if (parameters['j26'] !== undefined) {
    form['j26'] = parameters['j26']
  }
  if (parameters['j26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j26'))
  }
  if (parameters['j27'] !== undefined) {
    form['j27'] = parameters['j27']
  }
  if (parameters['j27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j27'))
  }
  if (parameters['j28'] !== undefined) {
    form['j28'] = parameters['j28']
  }
  if (parameters['j28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j28'))
  }
  if (parameters['j281'] !== undefined) {
    form['j281'] = parameters['j281']
  }
  if (parameters['j282'] !== undefined) {
    form['j282'] = parameters['j282']
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySaveUshiqFormData = function(formData) {
  const url = cmQuestionarySaveUshiq_RAW_URL()
  const method = cmQuestionarySaveUshiq_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySaveUshiq_RAW_URL = function() {
  return '/common/questionary/save-ushiq/{studentId}'
}
export const cmQuestionarySaveUshiq_TYPE = function() {
  return 'post'
}
export const cmQuestionarySaveUshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/save-ushiq/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 开展学校卫生工作情况调查表详情
 * request: cmQuestionaryFindSchoolSanitationWork
 * formData: cmQuestionaryFindSchoolSanitationWorkFormData
 * url: cmQuestionaryFindSchoolSanitationWork_URL
 * method: cmQuestionaryFindSchoolSanitationWork_TYPE
 * raw_url: cmQuestionaryFindSchoolSanitationWork_RAW_URL
 * @param id - id
 */
export const cmQuestionaryFindSchoolSanitationWork = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/school-sanitation-work/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryFindSchoolSanitationWorkFormData = function(formData) {
  const url = cmQuestionaryFindSchoolSanitationWork_RAW_URL()
  const method = cmQuestionaryFindSchoolSanitationWork_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryFindSchoolSanitationWork_RAW_URL = function() {
  return '/common/questionary/school-sanitation-work/{id}'
}
export const cmQuestionaryFindSchoolSanitationWork_TYPE = function() {
  return 'get'
}
export const cmQuestionaryFindSchoolSanitationWork_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/school-sanitation-work/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学生新冠肺炎专项调查表
 * request: cmCovid19SvisqFindScsqByStudent
 * formData: cmCovid19SvisqFindScsqByStudentFormData
 * url: cmCovid19SvisqFindScsqByStudent_URL
 * method: cmCovid19SvisqFindScsqByStudent_TYPE
 * raw_url: cmCovid19SvisqFindScsqByStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmCovid19SvisqFindScsqByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/scsq-by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmCovid19SvisqFindScsqByStudentFormData = function(formData) {
  const url = cmCovid19SvisqFindScsqByStudent_RAW_URL()
  const method = cmCovid19SvisqFindScsqByStudent_TYPE()
  return axios[method](url, formData)
}
export const cmCovid19SvisqFindScsqByStudent_RAW_URL = function() {
  return '/common/questionary/scsq-by-student/{studentId}'
}
export const cmCovid19SvisqFindScsqByStudent_TYPE = function() {
  return 'get'
}
export const cmCovid19SvisqFindScsqByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/scsq-by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生视力不良及影响因素专项调查表
 * request: cmQuestionaryFindStudent
 * formData: cmQuestionaryFindStudentFormData
 * url: cmQuestionaryFindStudent_URL
 * method: cmQuestionaryFindStudent_TYPE
 * raw_url: cmQuestionaryFindStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmQuestionaryFindStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryFindStudentFormData = function(formData) {
  const url = cmQuestionaryFindStudent_RAW_URL()
  const method = cmQuestionaryFindStudent_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryFindStudent_RAW_URL = function() {
  return '/common/questionary/student/{studentId}'
}
export const cmQuestionaryFindStudent_TYPE = function() {
  return 'get'
}
export const cmQuestionaryFindStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看大学学生健康状况及影响因素调查表
 * request: cmQuestionaryFindUniversityStudent
 * formData: cmQuestionaryFindUniversityStudentFormData
 * url: cmQuestionaryFindUniversityStudent_URL
 * method: cmQuestionaryFindUniversityStudent_TYPE
 * raw_url: cmQuestionaryFindUniversityStudent_RAW_URL
 * @param studentId - studentId
 */
export const cmQuestionaryFindUniversityStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/university-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryFindUniversityStudentFormData = function(formData) {
  const url = cmQuestionaryFindUniversityStudent_RAW_URL()
  const method = cmQuestionaryFindUniversityStudent_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryFindUniversityStudent_RAW_URL = function() {
  return '/common/questionary/university-student/{studentId}'
}
export const cmQuestionaryFindUniversityStudent_TYPE = function() {
  return 'get'
}
export const cmQuestionaryFindUniversityStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/university-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 开展学校卫生工作情况调查表详情选项统计
 * request: cmQuestionaryCosswqOptionStats
 * formData: cmQuestionaryCosswqOptionStatsFormData
 * url: cmQuestionaryCosswqOptionStats_URL
 * method: cmQuestionaryCosswqOptionStats_TYPE
 * raw_url: cmQuestionaryCosswqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmQuestionaryCosswqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/cosswq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryCosswqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryCosswqOptionStats_RAW_URL()
  const method = cmQuestionaryCosswqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryCosswqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/cosswq-option-stats'
}
export const cmQuestionaryCosswqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryCosswqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/cosswq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取国家卫健委新冠肺炎对学生视力影响评估调查表选项统计
 * request: cmCovid19SvisqOptionStats
 * formData: cmCovid19SvisqOptionStatsFormData
 * url: cmCovid19SvisqOptionStats_URL
 * method: cmCovid19SvisqOptionStats_TYPE
 * raw_url: cmCovid19SvisqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmCovid19SvisqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/csvsq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmCovid19SvisqOptionStatsFormData = function(formData) {
  const url = cmCovid19SvisqOptionStats_RAW_URL()
  const method = cmCovid19SvisqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmCovid19SvisqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/csvsq-option-stats'
}
export const cmCovid19SvisqOptionStats_TYPE = function() {
  return 'get'
}
export const cmCovid19SvisqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/csvsq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生健康状况及影响因素调查表（中学版）选项统计
 * request: cmQuestionaryMshiqOptionStats
 * formData: cmQuestionaryMshiqOptionStatsFormData
 * url: cmQuestionaryMshiqOptionStats_URL
 * method: cmQuestionaryMshiqOptionStats_TYPE
 * raw_url: cmQuestionaryMshiqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmQuestionaryMshiqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/mshiq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryMshiqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryMshiqOptionStats_RAW_URL()
  const method = cmQuestionaryMshiqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryMshiqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/mshiq-option-stats'
}
export const cmQuestionaryMshiqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryMshiqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/mshiq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理部门学校卫生工作调查表选项统计
 * request: cmQuestionaryMsswqOptionStats
 * formData: cmQuestionaryMsswqOptionStatsFormData
 * url: cmQuestionaryMsswqOptionStats_URL
 * method: cmQuestionaryMsswqOptionStats_TYPE
 * raw_url: cmQuestionaryMsswqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const cmQuestionaryMsswqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/msswq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryMsswqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryMsswqOptionStats_RAW_URL()
  const method = cmQuestionaryMsswqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryMsswqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/msswq-option-stats'
}
export const cmQuestionaryMsswqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryMsswqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/msswq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生健康状况及影响因素调查表（小学版）选项统计
 * request: cmQuestionaryPshiqOptionStats
 * formData: cmQuestionaryPshiqOptionStatsFormData
 * url: cmQuestionaryPshiqOptionStats_URL
 * method: cmQuestionaryPshiqOptionStats_TYPE
 * raw_url: cmQuestionaryPshiqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const cmQuestionaryPshiqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/pshiq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryPshiqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryPshiqOptionStats_RAW_URL()
  const method = cmQuestionaryPshiqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryPshiqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/pshiq-option-stats'
}
export const cmQuestionaryPshiqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryPshiqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/pshiq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生新冠肺炎防控知识行为专题调查表选项统计
 * request: cmQuestionaryScsqOptionStats
 * formData: cmQuestionaryScsqOptionStatsFormData
 * url: cmQuestionaryScsqOptionStats_URL
 * method: cmQuestionaryScsqOptionStats_TYPE
 * raw_url: cmQuestionaryScsqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmQuestionaryScsqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/scsq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryScsqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryScsqOptionStats_RAW_URL()
  const method = cmQuestionaryScsqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryScsqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/scsq-option-stats'
}
export const cmQuestionaryScsqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryScsqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/scsq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校环境健康影响因素调查表详情选项统计
 * request: cmQuestionarySehiqOptionStats
 * formData: cmQuestionarySehiqOptionStatsFormData
 * url: cmQuestionarySehiqOptionStats_URL
 * method: cmQuestionarySehiqOptionStats_TYPE
 * raw_url: cmQuestionarySehiqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmQuestionarySehiqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/sehiq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySehiqOptionStatsFormData = function(formData) {
  const url = cmQuestionarySehiqOptionStats_RAW_URL()
  const method = cmQuestionarySehiqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySehiqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/sehiq-option-stats'
}
export const cmQuestionarySehiqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionarySehiqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/sehiq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生视力不良及影响因素专项调查表选项统计
 * request: cmQuestionarySpvisqOptionStats
 * formData: cmQuestionarySpvisqOptionStatsFormData
 * url: cmQuestionarySpvisqOptionStats_URL
 * method: cmQuestionarySpvisqOptionStats_TYPE
 * raw_url: cmQuestionarySpvisqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 * @param schoolType - 学校类型
 */
export const cmQuestionarySpvisqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/spvisq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionarySpvisqOptionStatsFormData = function(formData) {
  const url = cmQuestionarySpvisqOptionStats_RAW_URL()
  const method = cmQuestionarySpvisqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionarySpvisqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/spvisq-option-stats'
}
export const cmQuestionarySpvisqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionarySpvisqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/spvisq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生健康状况及影响因素调查表（大学版）选项统计
 * request: cmQuestionaryUshiqOptionStats
 * formData: cmQuestionaryUshiqOptionStatsFormData
 * url: cmQuestionaryUshiqOptionStats_URL
 * method: cmQuestionaryUshiqOptionStats_TYPE
 * raw_url: cmQuestionaryUshiqOptionStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const cmQuestionaryUshiqOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/questionary/{screeningPlanId}/ushiq-option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmQuestionaryUshiqOptionStatsFormData = function(formData) {
  const url = cmQuestionaryUshiqOptionStats_RAW_URL()
  const method = cmQuestionaryUshiqOptionStats_TYPE()
  return axios[method](url, formData)
}
export const cmQuestionaryUshiqOptionStats_RAW_URL = function() {
  return '/common/questionary/{screeningPlanId}/ushiq-option-stats'
}
export const cmQuestionaryUshiqOptionStats_TYPE = function() {
  return 'get'
}
export const cmQuestionaryUshiqOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/questionary/{screeningPlanId}/ushiq-option-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区受检人列表
 * request: cmRegionPersonList
 * formData: cmRegionPersonListFormData
 * url: cmRegionPersonList_URL
 * method: cmRegionPersonList_TYPE
 * raw_url: cmRegionPersonList_RAW_URL
 * @param cityCode - 省级单位必传
 * @param districtCode - 区编码
 * @param schoolId - 学校
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param current - 是否当前地区
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmRegionPersonList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-person'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['current'] !== undefined) {
    queryParameters['current'] = parameters['current']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionPersonListFormData = function(formData) {
  const url = cmRegionPersonList_RAW_URL()
  const method = cmRegionPersonList_TYPE()
  return axios[method](url, formData)
}
export const cmRegionPersonList_RAW_URL = function() {
  return '/common/region-person'
}
export const cmRegionPersonList_TYPE = function() {
  return 'get'
}
export const cmRegionPersonList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-person'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['current'] !== undefined) {
    queryParameters['current'] = parameters['current']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区受检人选择列表
 * request: cmRegionPersonChoiceList
 * formData: cmRegionPersonChoiceListFormData
 * url: cmRegionPersonChoiceList_URL
 * method: cmRegionPersonChoiceList_TYPE
 * raw_url: cmRegionPersonChoiceList_RAW_URL
 * @param cityCode - 省级单位必传
 * @param districtCode - 
 * @param schoolId - 
 * @param name - 
 * @param idCard - 
 * @param current - 是否当前地区
 */
export const cmRegionPersonChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-person/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['current'] !== undefined) {
    queryParameters['current'] = parameters['current']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionPersonChoiceListFormData = function(formData) {
  const url = cmRegionPersonChoiceList_RAW_URL()
  const method = cmRegionPersonChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmRegionPersonChoiceList_RAW_URL = function() {
  return '/common/region-person/choice'
}
export const cmRegionPersonChoiceList_TYPE = function() {
  return 'get'
}
export const cmRegionPersonChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-person/choice'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['current'] !== undefined) {
    queryParameters['current'] = parameters['current']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区受检人筛查列表
 * request: cmRegionPersonListScreeningStudent
 * formData: cmRegionPersonListScreeningStudentFormData
 * url: cmRegionPersonListScreeningStudent_URL
 * method: cmRegionPersonListScreeningStudent_TYPE
 * raw_url: cmRegionPersonListScreeningStudent_RAW_URL
 * @param personId - personId
 * @param checkStartDate - 
 * @param checkEndDate - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmRegionPersonListScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-person/person/{personId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters['checkStartDate'] !== undefined) {
    queryParameters['checkStartDate'] = parameters['checkStartDate']
  }
  if (parameters['checkEndDate'] !== undefined) {
    queryParameters['checkEndDate'] = parameters['checkEndDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionPersonListScreeningStudentFormData = function(formData) {
  const url = cmRegionPersonListScreeningStudent_RAW_URL()
  const method = cmRegionPersonListScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const cmRegionPersonListScreeningStudent_RAW_URL = function() {
  return '/common/region-person/person/{personId}'
}
export const cmRegionPersonListScreeningStudent_TYPE = function() {
  return 'get'
}
export const cmRegionPersonListScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-person/person/{personId}'
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters['checkStartDate'] !== undefined) {
    queryParameters['checkStartDate'] = parameters['checkStartDate']
  }
  if (parameters['checkEndDate'] !== undefined) {
    queryParameters['checkEndDate'] = parameters['checkEndDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区受检人详情
 * request: cmRegionPersonFind
 * formData: cmRegionPersonFindFormData
 * url: cmRegionPersonFind_URL
 * method: cmRegionPersonFind_TYPE
 * raw_url: cmRegionPersonFind_RAW_URL
 * @param regionPersonId - regionPersonId
 * @param cityCode - 
 */
export const cmRegionPersonFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-person/{regionPersonId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionPersonFindFormData = function(formData) {
  const url = cmRegionPersonFind_RAW_URL()
  const method = cmRegionPersonFind_TYPE()
  return axios[method](url, formData)
}
export const cmRegionPersonFind_RAW_URL = function() {
  return '/common/region-person/{regionPersonId}'
}
export const cmRegionPersonFind_TYPE = function() {
  return 'get'
}
export const cmRegionPersonFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-person/{regionPersonId}'
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人绑定随机码
 * request: cmRegionPersonBindRandomCode
 * formData: cmRegionPersonBindRandomCodeFormData
 * url: cmRegionPersonBindRandomCode_URL
 * method: cmRegionPersonBindRandomCode_TYPE
 * raw_url: cmRegionPersonBindRandomCode_RAW_URL
 * @param regionPersonId - regionPersonId
 * @param cityCode - 
 */
export const cmRegionPersonBindRandomCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-person/{regionPersonId}/person-random-code'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionPersonBindRandomCodeFormData = function(formData) {
  const url = cmRegionPersonBindRandomCode_RAW_URL()
  const method = cmRegionPersonBindRandomCode_TYPE()
  return axios[method](url, formData)
}
export const cmRegionPersonBindRandomCode_RAW_URL = function() {
  return '/common/region-person/{regionPersonId}/person-random-code'
}
export const cmRegionPersonBindRandomCode_TYPE = function() {
  return 'get'
}
export const cmRegionPersonBindRandomCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-person/{regionPersonId}/person-random-code'
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷完整度列表
 * request: cmRegionQuestionaryIntactList
 * formData: cmRegionQuestionaryIntactListFormData
 * url: cmRegionQuestionaryIntactList_URL
 * method: cmRegionQuestionaryIntactList_TYPE
 * raw_url: cmRegionQuestionaryIntactList_RAW_URL
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmRegionQuestionaryIntactList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-questionary-intact'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionQuestionaryIntactListFormData = function(formData) {
  const url = cmRegionQuestionaryIntactList_RAW_URL()
  const method = cmRegionQuestionaryIntactList_TYPE()
  return axios[method](url, formData)
}
export const cmRegionQuestionaryIntactList_RAW_URL = function() {
  return '/common/region-questionary-intact'
}
export const cmRegionQuestionaryIntactList_TYPE = function() {
  return 'get'
}
export const cmRegionQuestionaryIntactList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-questionary-intact'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生获取地区问卷完整度
 * request: cmRegionQuestionaryIntactFindByStudent
 * formData: cmRegionQuestionaryIntactFindByStudentFormData
 * url: cmRegionQuestionaryIntactFindByStudent_URL
 * method: cmRegionQuestionaryIntactFindByStudent_TYPE
 * raw_url: cmRegionQuestionaryIntactFindByStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param screeningPlanId - 
 */
export const cmRegionQuestionaryIntactFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-questionary-intact/by-screening-student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionQuestionaryIntactFindByStudentFormData = function(formData) {
  const url = cmRegionQuestionaryIntactFindByStudent_RAW_URL()
  const method = cmRegionQuestionaryIntactFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const cmRegionQuestionaryIntactFindByStudent_RAW_URL = function() {
  return '/common/region-questionary-intact/by-screening-student/{screeningStudentId}'
}
export const cmRegionQuestionaryIntactFindByStudent_TYPE = function() {
  return 'get'
}
export const cmRegionQuestionaryIntactFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-questionary-intact/by-screening-student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷完整度选择列表
 * request: cmRegionQuestionaryIntactChoiceList
 * formData: cmRegionQuestionaryIntactChoiceListFormData
 * url: cmRegionQuestionaryIntactChoiceList_URL
 * method: cmRegionQuestionaryIntactChoiceList_TYPE
 * raw_url: cmRegionQuestionaryIntactChoiceList_RAW_URL
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param schoolType - 
 * @param questionaryObject - 
 */
export const cmRegionQuestionaryIntactChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-questionary-intact/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionQuestionaryIntactChoiceListFormData = function(formData) {
  const url = cmRegionQuestionaryIntactChoiceList_RAW_URL()
  const method = cmRegionQuestionaryIntactChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmRegionQuestionaryIntactChoiceList_RAW_URL = function() {
  return '/common/region-questionary-intact/choice'
}
export const cmRegionQuestionaryIntactChoiceList_TYPE = function() {
  return 'get'
}
export const cmRegionQuestionaryIntactChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-questionary-intact/choice'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取地区问卷完整度
 * request: cmRegionQuestionaryIntactFind
 * formData: cmRegionQuestionaryIntactFindFormData
 * url: cmRegionQuestionaryIntactFind_URL
 * method: cmRegionQuestionaryIntactFind_TYPE
 * raw_url: cmRegionQuestionaryIntactFind_RAW_URL
 * @param id - id
 */
export const cmRegionQuestionaryIntactFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/region-questionary-intact/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmRegionQuestionaryIntactFindFormData = function(formData) {
  const url = cmRegionQuestionaryIntactFind_RAW_URL()
  const method = cmRegionQuestionaryIntactFind_TYPE()
  return axios[method](url, formData)
}
export const cmRegionQuestionaryIntactFind_RAW_URL = function() {
  return '/common/region-questionary-intact/{id}'
}
export const cmRegionQuestionaryIntactFind_TYPE = function() {
  return 'get'
}
export const cmRegionQuestionaryIntactFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/region-questionary-intact/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校详情(学校部门获取)
 * request: cmSchoolFindBySchool
 * formData: cmSchoolFindBySchoolFormData
 * url: cmSchoolFindBySchool_URL
 * method: cmSchoolFindBySchool_TYPE
 * raw_url: cmSchoolFindBySchool_RAW_URL
 */
export const cmSchoolFindBySchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/by-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolFindBySchoolFormData = function(formData) {
  const url = cmSchoolFindBySchool_RAW_URL()
  const method = cmSchoolFindBySchool_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolFindBySchool_RAW_URL = function() {
  return '/common/school/by-school'
}
export const cmSchoolFindBySchool_TYPE = function() {
  return 'get'
}
export const cmSchoolFindBySchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/by-school'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校选择列表
 * request: cmSchoolChoiceList
 * formData: cmSchoolChoiceListFormData
 * url: cmSchoolChoiceList_URL
 * method: cmSchoolChoiceList_TYPE
 * raw_url: cmSchoolChoiceList_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param type - 学校类型
 * @param name - 学校名称
 * @param isNotScreeningPlanId - 学校不属于该筛查计划
 */
export const cmSchoolChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['isNotScreeningPlanId'] !== undefined) {
    queryParameters['isNotScreeningPlanId'] = parameters['isNotScreeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolChoiceListFormData = function(formData) {
  const url = cmSchoolChoiceList_RAW_URL()
  const method = cmSchoolChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolChoiceList_RAW_URL = function() {
  return '/common/school/choice'
}
export const cmSchoolChoiceList_TYPE = function() {
  return 'get'
}
export const cmSchoolChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/choice'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['isNotScreeningPlanId'] !== undefined) {
    queryParameters['isNotScreeningPlanId'] = parameters['isNotScreeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校转让负责人
 * request: cmSchoolUpdateManager
 * formData: cmSchoolUpdateManagerFormData
 * url: cmSchoolUpdateManager_URL
 * method: cmSchoolUpdateManager_TYPE
 * raw_url: cmSchoolUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const cmSchoolUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmSchoolUpdateManagerFormData = function(formData) {
  const url = cmSchoolUpdateManager_RAW_URL()
  const method = cmSchoolUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolUpdateManager_RAW_URL = function() {
  return '/common/school/manager'
}
export const cmSchoolUpdateManager_TYPE = function() {
  return 'put'
}
export const cmSchoolUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校类型统计
 * request: cmSchoolScreeningSchoolTypeStats
 * formData: cmSchoolScreeningSchoolTypeStatsFormData
 * url: cmSchoolScreeningSchoolTypeStats_URL
 * method: cmSchoolScreeningSchoolTypeStats_TYPE
 * raw_url: cmSchoolScreeningSchoolTypeStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 * @param districtCode - 
 * @param status - 
 */
export const cmSchoolScreeningSchoolTypeStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-plan/{screeningPlanId}/screening-school-type-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningSchoolTypeStatsFormData = function(formData) {
  const url = cmSchoolScreeningSchoolTypeStats_RAW_URL()
  const method = cmSchoolScreeningSchoolTypeStats_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningSchoolTypeStats_RAW_URL = function() {
  return '/common/school/screening-plan/{screeningPlanId}/screening-school-type-stats'
}
export const cmSchoolScreeningSchoolTypeStats_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningSchoolTypeStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-plan/{screeningPlanId}/screening-school-type-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校选择列表(筛查团队获取)
 * request: cmSchoolScreeningSchoolList
 * formData: cmSchoolScreeningSchoolListFormData
 * url: cmSchoolScreeningSchoolList_URL
 * method: cmSchoolScreeningSchoolList_TYPE
 * raw_url: cmSchoolScreeningSchoolList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param status - 状态
 */
export const cmSchoolScreeningSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningSchoolListFormData = function(formData) {
  const url = cmSchoolScreeningSchoolList_RAW_URL()
  const method = cmSchoolScreeningSchoolList_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningSchoolList_RAW_URL = function() {
  return '/common/school/screening-school'
}
export const cmSchoolScreeningSchoolList_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-school'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校详情
 * request: cmSchoolFindScreeningSchool
 * formData: cmSchoolFindScreeningSchoolFormData
 * url: cmSchoolFindScreeningSchool_URL
 * method: cmSchoolFindScreeningSchool_TYPE
 * raw_url: cmSchoolFindScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmSchoolFindScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-school/{screeningSchoolId}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolFindScreeningSchoolFormData = function(formData) {
  const url = cmSchoolFindScreeningSchool_RAW_URL()
  const method = cmSchoolFindScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolFindScreeningSchool_RAW_URL = function() {
  return '/common/school/screening-school/{screeningSchoolId}/'
}
export const cmSchoolFindScreeningSchool_TYPE = function() {
  return 'get'
}
export const cmSchoolFindScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-school/{screeningSchoolId}/'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校问卷
 * request: cmSchoolScreeningSchoolQuestionary
 * formData: cmSchoolScreeningSchoolQuestionaryFormData
 * url: cmSchoolScreeningSchoolQuestionary_URL
 * method: cmSchoolScreeningSchoolQuestionary_TYPE
 * raw_url: cmSchoolScreeningSchoolQuestionary_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmSchoolScreeningSchoolQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-school/{screeningSchoolId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningSchoolQuestionaryFormData = function(formData) {
  const url = cmSchoolScreeningSchoolQuestionary_RAW_URL()
  const method = cmSchoolScreeningSchoolQuestionary_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningSchoolQuestionary_RAW_URL = function() {
  return '/common/school/screening-school/{screeningSchoolId}/questionary'
}
export const cmSchoolScreeningSchoolQuestionary_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningSchoolQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-school/{screeningSchoolId}/questionary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校学生双眼对比统计
 * request: cmVisionComparison
 * formData: cmVisionComparisonFormData
 * url: cmVisionComparison_URL
 * method: cmVisionComparison_TYPE
 * raw_url: cmVisionComparison_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningPlanId - screeningPlanId
 */
export const cmVisionComparison = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-school/{screeningSchoolId}/vision_comparison'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmVisionComparisonFormData = function(formData) {
  const url = cmVisionComparison_RAW_URL()
  const method = cmVisionComparison_TYPE()
  return axios[method](url, formData)
}
export const cmVisionComparison_RAW_URL = function() {
  return '/common/school/screening-school/{screeningSchoolId}/vision_comparison'
}
export const cmVisionComparison_TYPE = function() {
  return 'get'
}
export const cmVisionComparison_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-school/{screeningSchoolId}/vision_comparison'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生选择列表
 * request: cmSchoolScreeningStudentList
 * formData: cmSchoolScreeningStudentListFormData
 * url: cmSchoolScreeningStudentList_URL
 * method: cmSchoolScreeningStudentList_TYPE
 * raw_url: cmSchoolScreeningStudentList_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 */
export const cmSchoolScreeningStudentList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screening-student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningStudentListFormData = function(formData) {
  const url = cmSchoolScreeningStudentList_RAW_URL()
  const method = cmSchoolScreeningStudentList_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningStudentList_RAW_URL = function() {
  return '/common/school/screening-student'
}
export const cmSchoolScreeningStudentList_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningStudentList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screening-student'
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区获取筛查学校
 * request: cmSchoolScreeningSchoolByRegionCode
 * formData: cmSchoolScreeningSchoolByRegionCodeFormData
 * url: cmSchoolScreeningSchoolByRegionCode_URL
 * method: cmSchoolScreeningSchoolByRegionCode_TYPE
 * raw_url: cmSchoolScreeningSchoolByRegionCode_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 
 */
export const cmSchoolScreeningSchoolByRegionCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/screeningPlan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningSchoolByRegionCodeFormData = function(formData) {
  const url = cmSchoolScreeningSchoolByRegionCode_RAW_URL()
  const method = cmSchoolScreeningSchoolByRegionCode_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningSchoolByRegionCode_RAW_URL = function() {
  return '/common/school/screeningPlan/{screeningPlanId}'
}
export const cmSchoolScreeningSchoolByRegionCode_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningSchoolByRegionCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/screeningPlan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校详情
 * request: cmSchoolFind
 * formData: cmSchoolFindFormData
 * url: cmSchoolFind_URL
 * method: cmSchoolFind_TYPE
 * raw_url: cmSchoolFind_RAW_URL
 * @param schoolId - schoolId
 */
export const cmSchoolFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/{schoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolFindFormData = function(formData) {
  const url = cmSchoolFind_RAW_URL()
  const method = cmSchoolFind_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolFind_RAW_URL = function() {
  return '/common/school/{schoolId}'
}
export const cmSchoolFind_TYPE = function() {
  return 'get'
}
export const cmSchoolFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/{schoolId}'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校详情
 * request: cmScreeningSchoolList
 * formData: cmScreeningSchoolListFormData
 * url: cmScreeningSchoolList_URL
 * method: cmScreeningSchoolList_TYPE
 * raw_url: cmScreeningSchoolList_RAW_URL
 * @param formList - formList
 * @param screeningPlanId - screeningPlanId
 */
export const cmScreeningSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/{screeningPlanId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['formList'] !== undefined) {
    body = parameters['formList']
  }
  if (parameters['formList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: formList'))
  }
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmScreeningSchoolListFormData = function(formData) {
  const url = cmScreeningSchoolList_RAW_URL()
  const method = cmScreeningSchoolList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningSchoolList_RAW_URL = function() {
  return '/common/school/{screeningPlanId}/screening-school'
}
export const cmScreeningSchoolList_TYPE = function() {
  return 'post'
}
export const cmScreeningSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/{screeningPlanId}/screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看问卷
 * request: cmSchoolScreeningStudentQuestionnaire
 * formData: cmSchoolScreeningStudentQuestionnaireFormData
 * url: cmSchoolScreeningStudentQuestionnaire_URL
 * method: cmSchoolScreeningStudentQuestionnaire_TYPE
 * raw_url: cmSchoolScreeningStudentQuestionnaire_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const cmSchoolScreeningStudentQuestionnaire = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/school/{screeningStudentId}/questionnaire'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSchoolScreeningStudentQuestionnaireFormData = function(formData) {
  const url = cmSchoolScreeningStudentQuestionnaire_RAW_URL()
  const method = cmSchoolScreeningStudentQuestionnaire_TYPE()
  return axios[method](url, formData)
}
export const cmSchoolScreeningStudentQuestionnaire_RAW_URL = function() {
  return '/common/school/{screeningStudentId}/questionnaire'
}
export const cmSchoolScreeningStudentQuestionnaire_TYPE = function() {
  return 'get'
}
export const cmSchoolScreeningStudentQuestionnaire_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/school/{screeningStudentId}/questionnaire'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区学生整体完成度
 * request: cmScreeningMonitoringRegionStudentEntiretyMonitorList
 * formData: cmScreeningMonitoringRegionStudentEntiretyMonitorListFormData
 * url: cmScreeningMonitoringRegionStudentEntiretyMonitorList_URL
 * method: cmScreeningMonitoringRegionStudentEntiretyMonitorList_TYPE
 * raw_url: cmScreeningMonitoringRegionStudentEntiretyMonitorList_RAW_URL
 * @param cityCode - 
 * @param districtCode - 
 * @param screeningSchoolId - 
 */
export const cmScreeningMonitoringRegionStudentEntiretyMonitorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-monitoring/region-student-entirety-monitor'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningMonitoringRegionStudentEntiretyMonitorListFormData = function(formData) {
  const url = cmScreeningMonitoringRegionStudentEntiretyMonitorList_RAW_URL()
  const method = cmScreeningMonitoringRegionStudentEntiretyMonitorList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningMonitoringRegionStudentEntiretyMonitorList_RAW_URL = function() {
  return '/common/screening-monitoring/region-student-entirety-monitor'
}
export const cmScreeningMonitoringRegionStudentEntiretyMonitorList_TYPE = function() {
  return 'get'
}
export const cmScreeningMonitoringRegionStudentEntiretyMonitorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-monitoring/region-student-entirety-monitor'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区查看筛查监控（按班级分组，带调查问卷）
 * request: cmDistrictScreeningMonitoringFindWithQuestionary
 * formData: cmDistrictScreeningMonitoringFindWithQuestionaryFormData
 * url: cmDistrictScreeningMonitoringFindWithQuestionary_URL
 * method: cmDistrictScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: cmDistrictScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param districtCode - districtCode
 */
export const cmDistrictScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{districtCode}', `${parameters['districtCode']}`)
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmDistrictScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = cmDistrictScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = cmDistrictScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const cmDistrictScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/common/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
}
export const cmDistrictScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const cmDistrictScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{districtCode}', `${parameters['districtCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 部门筛查计划
 * request: cmScreeningPlanBusinessObjectPlan
 * formData: cmScreeningPlanBusinessObjectPlanFormData
 * url: cmScreeningPlanBusinessObjectPlan_URL
 * method: cmScreeningPlanBusinessObjectPlan_TYPE
 * raw_url: cmScreeningPlanBusinessObjectPlan_RAW_URL
 * @param screeningPlanId - 计划ID
 * @param status - 计划状态
 * @param schoolStatus - 学校状态
 * @param regionStatus - 地区状态
 * @param planName - 计划名称
 * @param planType - 计划类型
 * @param inputType - 录入类型
 * @param startTime - 开始时间
 * @param endTime - 结束时间
 * @param planCreateTime - 计划创建时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningPlanBusinessObjectPlan = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/business-object'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['schoolStatus'] !== undefined) {
    queryParameters['schoolStatus'] = parameters['schoolStatus']
  }
  if (parameters['regionStatus'] !== undefined) {
    queryParameters['regionStatus'] = parameters['regionStatus']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['startTime'] !== undefined) {
    queryParameters['startTime'] = parameters['startTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['planCreateTime'] !== undefined) {
    queryParameters['planCreateTime'] = parameters['planCreateTime']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanBusinessObjectPlanFormData = function(formData) {
  const url = cmScreeningPlanBusinessObjectPlan_RAW_URL()
  const method = cmScreeningPlanBusinessObjectPlan_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanBusinessObjectPlan_RAW_URL = function() {
  return '/common/screening-plan/business-object'
}
export const cmScreeningPlanBusinessObjectPlan_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanBusinessObjectPlan_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/business-object'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['schoolStatus'] !== undefined) {
    queryParameters['schoolStatus'] = parameters['schoolStatus']
  }
  if (parameters['regionStatus'] !== undefined) {
    queryParameters['regionStatus'] = parameters['regionStatus']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['startTime'] !== undefined) {
    queryParameters['startTime'] = parameters['startTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['planCreateTime'] !== undefined) {
    queryParameters['planCreateTime'] = parameters['planCreateTime']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 部门筛查计划选择列表
 * request: cmScreeningPlanBusinessObjectPlanChoiceList
 * formData: cmScreeningPlanBusinessObjectPlanChoiceListFormData
 * url: cmScreeningPlanBusinessObjectPlanChoiceList_URL
 * method: cmScreeningPlanBusinessObjectPlanChoiceList_TYPE
 * raw_url: cmScreeningPlanBusinessObjectPlanChoiceList_RAW_URL
 * @param inputType - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningPlanBusinessObjectPlanChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/business-object-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanBusinessObjectPlanChoiceListFormData = function(formData) {
  const url = cmScreeningPlanBusinessObjectPlanChoiceList_RAW_URL()
  const method = cmScreeningPlanBusinessObjectPlanChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanBusinessObjectPlanChoiceList_RAW_URL = function() {
  return '/common/screening-plan/business-object-choice'
}
export const cmScreeningPlanBusinessObjectPlanChoiceList_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanBusinessObjectPlanChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/business-object-choice'
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区筛查计划选择列表
 * request: cmScreeningPlanRegionPlanChoice
 * formData: cmScreeningPlanRegionPlanChoiceFormData
 * url: cmScreeningPlanRegionPlanChoice_URL
 * method: cmScreeningPlanRegionPlanChoice_TYPE
 * raw_url: cmScreeningPlanRegionPlanChoice_RAW_URL
 * @param regionType - 
 * @param regionCode - 
 * @param startYear - 
 * @param endYear - 
 */
export const cmScreeningPlanRegionPlanChoice = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/region-plan-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['startYear'] !== undefined) {
    queryParameters['startYear'] = parameters['startYear']
  }
  if (parameters['endYear'] !== undefined) {
    queryParameters['endYear'] = parameters['endYear']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanRegionPlanChoiceFormData = function(formData) {
  const url = cmScreeningPlanRegionPlanChoice_RAW_URL()
  const method = cmScreeningPlanRegionPlanChoice_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanRegionPlanChoice_RAW_URL = function() {
  return '/common/screening-plan/region-plan-choice'
}
export const cmScreeningPlanRegionPlanChoice_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanRegionPlanChoice_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/region-plan-choice'
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['startYear'] !== undefined) {
    queryParameters['startYear'] = parameters['startYear']
  }
  if (parameters['endYear'] !== undefined) {
    queryParameters['endYear'] = parameters['endYear']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查地区
 * request: cmScreeningPlanFindScreeningRegionByCode
 * formData: cmScreeningPlanFindScreeningRegionByCodeFormData
 * url: cmScreeningPlanFindScreeningRegionByCode_URL
 * method: cmScreeningPlanFindScreeningRegionByCode_TYPE
 * raw_url: cmScreeningPlanFindScreeningRegionByCode_RAW_URL
 * @param code - 
 */
export const cmScreeningPlanFindScreeningRegionByCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/screening-region/by-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanFindScreeningRegionByCodeFormData = function(formData) {
  const url = cmScreeningPlanFindScreeningRegionByCode_RAW_URL()
  const method = cmScreeningPlanFindScreeningRegionByCode_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanFindScreeningRegionByCode_RAW_URL = function() {
  return '/common/screening-plan/screening-region/by-code'
}
export const cmScreeningPlanFindScreeningRegionByCode_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanFindScreeningRegionByCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/screening-region/by-code'
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查地区
 * request: cmScreeningPlanFindScreeningRegion
 * formData: cmScreeningPlanFindScreeningRegionFormData
 * url: cmScreeningPlanFindScreeningRegion_URL
 * method: cmScreeningPlanFindScreeningRegion_TYPE
 * raw_url: cmScreeningPlanFindScreeningRegion_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const cmScreeningPlanFindScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/screening-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanFindScreeningRegionFormData = function(formData) {
  const url = cmScreeningPlanFindScreeningRegion_RAW_URL()
  const method = cmScreeningPlanFindScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanFindScreeningRegion_RAW_URL = function() {
  return '/common/screening-plan/screening-region/{screeningRegionId}'
}
export const cmScreeningPlanFindScreeningRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanFindScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/screening-region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生详情
 * request: cmScreeningPlanStudentDetail
 * formData: cmScreeningPlanStudentDetailFormData
 * url: cmScreeningPlanStudentDetail_URL
 * method: cmScreeningPlanStudentDetail_TYPE
 * raw_url: cmScreeningPlanStudentDetail_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const cmScreeningPlanStudentDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/screening-student/{screeningStudentId}/detail'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanStudentDetailFormData = function(formData) {
  const url = cmScreeningPlanStudentDetail_RAW_URL()
  const method = cmScreeningPlanStudentDetail_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanStudentDetail_RAW_URL = function() {
  return '/common/screening-plan/screening-student/{screeningStudentId}/detail'
}
export const cmScreeningPlanStudentDetail_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanStudentDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/screening-student/{screeningStudentId}/detail'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查学校
 * request: cmScreeningSchoolDelete
 * formData: cmScreeningSchoolDeleteFormData
 * url: cmScreeningSchoolDelete_URL
 * method: cmScreeningSchoolDelete_TYPE
 * raw_url: cmScreeningSchoolDelete_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 筛查学校id
 */
export const cmScreeningSchoolDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/delete_screeningSchool'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningSchoolDeleteFormData = function(formData) {
  const url = cmScreeningSchoolDelete_RAW_URL()
  const method = cmScreeningSchoolDelete_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningSchoolDelete_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/delete_screeningSchool'
}
export const cmScreeningSchoolDelete_TYPE = function() {
  return 'get'
}
export const cmScreeningSchoolDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/delete_screeningSchool'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划详情
 * request: cmScreeningPlanDetail
 * formData: cmScreeningPlanDetailFormData
 * url: cmScreeningPlanDetail_URL
 * method: cmScreeningPlanDetail_TYPE
 * raw_url: cmScreeningPlanDetail_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 * @param screeningSchoolId - screeningSchoolId
 * @param regionCode - regionCode
 */
export const cmScreeningPlanDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/detail'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanDetailFormData = function(formData) {
  const url = cmScreeningPlanDetail_RAW_URL()
  const method = cmScreeningPlanDetail_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanDetail_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/detail'
}
export const cmScreeningPlanDetail_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/detail'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校判断是否有下级数据
 * request: cmScreeningPlanIsSchoolDataScreeningRegion
 * formData: cmScreeningPlanIsSchoolDataScreeningRegionFormData
 * url: cmScreeningPlanIsSchoolDataScreeningRegion_URL
 * method: cmScreeningPlanIsSchoolDataScreeningRegion_TYPE
 * raw_url: cmScreeningPlanIsSchoolDataScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - ScreeningSchoolId
 * @param screeningSchoolId - 筛查学校id
 */
export const cmScreeningPlanIsSchoolDataScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/is_school_data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['ScreeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanIsSchoolDataScreeningRegionFormData = function(formData) {
  const url = cmScreeningPlanIsSchoolDataScreeningRegion_RAW_URL()
  const method = cmScreeningPlanIsSchoolDataScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanIsSchoolDataScreeningRegion_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/is_school_data'
}
export const cmScreeningPlanIsSchoolDataScreeningRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanIsSchoolDataScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/is_school_data'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['ScreeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区筛查地区列表
 * request: cmScreeningPlanRegionScreeningRegionList
 * formData: cmScreeningPlanRegionScreeningRegionListFormData
 * url: cmScreeningPlanRegionScreeningRegionList_URL
 * method: cmScreeningPlanRegionScreeningRegionList_TYPE
 * raw_url: cmScreeningPlanRegionScreeningRegionList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 
 * @param regionType - 
 * @param checked - 
 */
export const cmScreeningPlanRegionScreeningRegionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/region-screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanRegionScreeningRegionListFormData = function(formData) {
  const url = cmScreeningPlanRegionScreeningRegionList_RAW_URL()
  const method = cmScreeningPlanRegionScreeningRegionList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanRegionScreeningRegionList_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/region-screening-region'
}
export const cmScreeningPlanRegionScreeningRegionList_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanRegionScreeningRegionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/region-screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区域重新录入
 * request: cmScreeningRegionAgain
 * formData: cmScreeningRegionAgainFormData
 * url: cmScreeningRegionAgain_URL
 * method: cmScreeningRegionAgain_TYPE
 * raw_url: cmScreeningRegionAgain_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - 筛查地区id
 */
export const cmScreeningRegionAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/region_again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningRegionAgainFormData = function(formData) {
  const url = cmScreeningRegionAgain_RAW_URL()
  const method = cmScreeningRegionAgain_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningRegionAgain_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/region_again'
}
export const cmScreeningRegionAgain_TYPE = function() {
  return 'get'
}
export const cmScreeningRegionAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/region_again'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区完成旧数据录入
 * request: cmScreeningRegionFinish
 * formData: cmScreeningRegionFinishFormData
 * url: cmScreeningRegionFinish_URL
 * method: cmScreeningRegionFinish_TYPE
 * raw_url: cmScreeningRegionFinish_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - 筛查地区id
 */
export const cmScreeningRegionFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/region_finish'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningRegionFinishFormData = function(formData) {
  const url = cmScreeningRegionFinish_RAW_URL()
  const method = cmScreeningRegionFinish_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningRegionFinish_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/region_finish'
}
export const cmScreeningRegionFinish_TYPE = function() {
  return 'get'
}
export const cmScreeningRegionFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/region_finish'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校重新录入
 * request: cmScreeningSchoolAgain
 * formData: cmScreeningSchoolAgainFormData
 * url: cmScreeningSchoolAgain_URL
 * method: cmScreeningSchoolAgain_TYPE
 * raw_url: cmScreeningSchoolAgain_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 筛查学校id
 */
export const cmScreeningSchoolAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/school_again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningSchoolAgainFormData = function(formData) {
  const url = cmScreeningSchoolAgain_RAW_URL()
  const method = cmScreeningSchoolAgain_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningSchoolAgain_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/school_again'
}
export const cmScreeningSchoolAgain_TYPE = function() {
  return 'get'
}
export const cmScreeningSchoolAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/school_again'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校完成旧数据录入
 * request: cmScreeningSchoolFinish
 * formData: cmScreeningSchoolFinishFormData
 * url: cmScreeningSchoolFinish_URL
 * method: cmScreeningSchoolFinish_TYPE
 * raw_url: cmScreeningSchoolFinish_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 筛查学校id
 */
export const cmScreeningSchoolFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/school_finish'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningSchoolFinishFormData = function(formData) {
  const url = cmScreeningSchoolFinish_RAW_URL()
  const method = cmScreeningSchoolFinish_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningSchoolFinish_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/school_finish'
}
export const cmScreeningSchoolFinish_TYPE = function() {
  return 'get'
}
export const cmScreeningSchoolFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/school_finish'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查班级列表
 * request: cmScreeningPlanListScreeningClass
 * formData: cmScreeningPlanListScreeningClassFormData
 * url: cmScreeningPlanListScreeningClass_URL
 * method: cmScreeningPlanListScreeningClass_TYPE
 * raw_url: cmScreeningPlanListScreeningClass_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 * @param checkIsCanDelete - 
 */
export const cmScreeningPlanListScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/screening-class'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters['checkIsCanDelete'] !== undefined) {
    queryParameters['checkIsCanDelete'] = parameters['checkIsCanDelete']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanListScreeningClassFormData = function(formData) {
  const url = cmScreeningPlanListScreeningClass_RAW_URL()
  const method = cmScreeningPlanListScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanListScreeningClass_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/screening-class'
}
export const cmScreeningPlanListScreeningClass_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanListScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/screening-class'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters['checkIsCanDelete'] !== undefined) {
    queryParameters['checkIsCanDelete'] = parameters['checkIsCanDelete']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区列表
 * request: cmScreeningPlanListScreeningRegion
 * formData: cmScreeningPlanListScreeningRegionFormData
 * url: cmScreeningPlanListScreeningRegion_URL
 * method: cmScreeningPlanListScreeningRegion_TYPE
 * raw_url: cmScreeningPlanListScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param pid - 
 * @param isAll - 
 */
export const cmScreeningPlanListScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['pid'] !== undefined) {
    queryParameters['pid'] = parameters['pid']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanListScreeningRegionFormData = function(formData) {
  const url = cmScreeningPlanListScreeningRegion_RAW_URL()
  const method = cmScreeningPlanListScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanListScreeningRegion_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/screening-region'
}
export const cmScreeningPlanListScreeningRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanListScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['pid'] !== undefined) {
    queryParameters['pid'] = parameters['pid']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校列表
 * request: cmScreeningPlanListScreeningSchool
 * formData: cmScreeningPlanListScreeningSchoolFormData
 * url: cmScreeningPlanListScreeningSchool_URL
 * method: cmScreeningPlanListScreeningSchool_TYPE
 * raw_url: cmScreeningPlanListScreeningSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param schoolName - 
 * @param screeningRegionId - 
 * @param screeningTeamId - 
 * @param schoolType - 
 * @param status - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningPlanListScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['schoolName'] !== undefined) {
    queryParameters['schoolName'] = parameters['schoolName']
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanListScreeningSchoolFormData = function(formData) {
  const url = cmScreeningPlanListScreeningSchool_RAW_URL()
  const method = cmScreeningPlanListScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanListScreeningSchool_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/screening-school'
}
export const cmScreeningPlanListScreeningSchool_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanListScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['schoolName'] !== undefined) {
    queryParameters['schoolName'] = parameters['schoolName']
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学生列表
 * request: cmScreeningPlanListScreeningStudent
 * formData: cmScreeningPlanListScreeningStudentFormData
 * url: cmScreeningPlanListScreeningStudent_URL
 * method: cmScreeningPlanListScreeningStudent_TYPE
 * raw_url: cmScreeningPlanListScreeningStudent_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 
 * @param id - 
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 姓名
 * @param createTime - 区间时间起始
 * @param upperCreateTime - 区间时间结束
 * @param checkDate - 检查时间
 * @param birth - 出生日期
 * @param sex - 性别
 * @param idCard - 身份证
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param healthSituation - 健康状况
 * @param userLook - 是否查阅
 * @param deleteFlag - 恢复列表传true
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningPlanListScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-plan/{screeningPlanId}/screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['nation'] !== undefined) {
    queryParameters['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['healthSituation'] !== undefined) {
    queryParameters['healthSituation'] = parameters['healthSituation']
  }
  if (parameters['userLook'] !== undefined) {
    queryParameters['userLook'] = parameters['userLook']
  }
  if (parameters['deleteFlag'] !== undefined) {
    queryParameters['deleteFlag'] = parameters['deleteFlag']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningPlanListScreeningStudentFormData = function(formData) {
  const url = cmScreeningPlanListScreeningStudent_RAW_URL()
  const method = cmScreeningPlanListScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningPlanListScreeningStudent_RAW_URL = function() {
  return '/common/screening-plan/{screeningPlanId}/screening-student'
}
export const cmScreeningPlanListScreeningStudent_TYPE = function() {
  return 'get'
}
export const cmScreeningPlanListScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-plan/{screeningPlanId}/screening-student'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['id'] !== undefined) {
    queryParameters['id'] = parameters['id']
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['upperCreateTime'] !== undefined) {
    queryParameters['upperCreateTime'] = parameters['upperCreateTime']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['birth'] !== undefined) {
    queryParameters['birth'] = parameters['birth']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['nation'] !== undefined) {
    queryParameters['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['healthSituation'] !== undefined) {
    queryParameters['healthSituation'] = parameters['healthSituation']
  }
  if (parameters['userLook'] !== undefined) {
    queryParameters['userLook'] = parameters['userLook']
  }
  if (parameters['deleteFlag'] !== undefined) {
    queryParameters['deleteFlag'] = parameters['deleteFlag']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据姓名身份证获取筛查学生
 * request: cmScreeningStudentFindByNameAndIdCard
 * formData: cmScreeningStudentFindByNameAndIdCardFormData
 * url: cmScreeningStudentFindByNameAndIdCard_URL
 * method: cmScreeningStudentFindByNameAndIdCard_TYPE
 * raw_url: cmScreeningStudentFindByNameAndIdCard_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param name - 
 * @param idCard - 
 */
export const cmScreeningStudentFindByNameAndIdCard = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-student/screening-school/{screeningSchoolId}/student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStudentFindByNameAndIdCardFormData = function(formData) {
  const url = cmScreeningStudentFindByNameAndIdCard_RAW_URL()
  const method = cmScreeningStudentFindByNameAndIdCard_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStudentFindByNameAndIdCard_RAW_URL = function() {
  return '/common/screening-student/screening-school/{screeningSchoolId}/student'
}
export const cmScreeningStudentFindByNameAndIdCard_TYPE = function() {
  return 'get'
}
export const cmScreeningStudentFindByNameAndIdCard_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-student/screening-school/{screeningSchoolId}/student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['idCard'] !== undefined) {
    queryParameters['idCard'] = parameters['idCard']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 任务列表
 * request: cmScreeningTaskScreeningPlanTaskList
 * formData: cmScreeningTaskScreeningPlanTaskListFormData
 * url: cmScreeningTaskScreeningPlanTaskList_URL
 * method: cmScreeningTaskScreeningPlanTaskList_TYPE
 * raw_url: cmScreeningTaskScreeningPlanTaskList_RAW_URL
 * @param screeningPlanId - 
 * @param status - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningTaskScreeningPlanTaskList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-task'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTaskScreeningPlanTaskListFormData = function(formData) {
  const url = cmScreeningTaskScreeningPlanTaskList_RAW_URL()
  const method = cmScreeningTaskScreeningPlanTaskList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTaskScreeningPlanTaskList_RAW_URL = function() {
  return '/common/screening-task'
}
export const cmScreeningTaskScreeningPlanTaskList_TYPE = function() {
  return 'get'
}
export const cmScreeningTaskScreeningPlanTaskList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-task'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 任务管理列表
 * request: cmScreeningTaskScreeningPlanTaskManageList
 * formData: cmScreeningTaskScreeningPlanTaskManageListFormData
 * url: cmScreeningTaskScreeningPlanTaskManageList_URL
 * method: cmScreeningTaskScreeningPlanTaskManageList_TYPE
 * raw_url: cmScreeningTaskScreeningPlanTaskManageList_RAW_URL
 * @param screeningPlanId - 
 * @param type - 任务类型
 * @param handlerId - 处理人id
 * @param handlerName - 任务处理机构
 * @param fromObjectName - 任务来源
 * @param accepterId - 
 * @param handleStartTime - 
 * @param handleEndTime - 
 * @param acceptStartTime - 
 * @param acceptEndTime - 
 * @param status - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 * @param toObjectId - 查看学校, 医疗机构的任务列表
 */
export const cmScreeningTaskScreeningPlanTaskManageList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-task/manage'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['handlerId'] !== undefined) {
    queryParameters['handlerId'] = parameters['handlerId']
  }
  if (parameters['handlerName'] !== undefined) {
    queryParameters['handlerName'] = parameters['handlerName']
  }
  if (parameters['fromObjectName'] !== undefined) {
    queryParameters['fromObjectName'] = parameters['fromObjectName']
  }
  if (parameters['accepterId'] !== undefined) {
    queryParameters['accepterId'] = parameters['accepterId']
  }
  if (parameters['handleStartTime'] !== undefined) {
    queryParameters['handleStartTime'] = parameters['handleStartTime']
  }
  if (parameters['handleEndTime'] !== undefined) {
    queryParameters['handleEndTime'] = parameters['handleEndTime']
  }
  if (parameters['acceptStartTime'] !== undefined) {
    queryParameters['acceptStartTime'] = parameters['acceptStartTime']
  }
  if (parameters['acceptEndTime'] !== undefined) {
    queryParameters['acceptEndTime'] = parameters['acceptEndTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters['toObjectId'] !== undefined) {
    queryParameters['toObjectId'] = parameters['toObjectId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTaskScreeningPlanTaskManageListFormData = function(formData) {
  const url = cmScreeningTaskScreeningPlanTaskManageList_RAW_URL()
  const method = cmScreeningTaskScreeningPlanTaskManageList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTaskScreeningPlanTaskManageList_RAW_URL = function() {
  return '/common/screening-task/manage'
}
export const cmScreeningTaskScreeningPlanTaskManageList_TYPE = function() {
  return 'get'
}
export const cmScreeningTaskScreeningPlanTaskManageList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-task/manage'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['handlerId'] !== undefined) {
    queryParameters['handlerId'] = parameters['handlerId']
  }
  if (parameters['handlerName'] !== undefined) {
    queryParameters['handlerName'] = parameters['handlerName']
  }
  if (parameters['fromObjectName'] !== undefined) {
    queryParameters['fromObjectName'] = parameters['fromObjectName']
  }
  if (parameters['accepterId'] !== undefined) {
    queryParameters['accepterId'] = parameters['accepterId']
  }
  if (parameters['handleStartTime'] !== undefined) {
    queryParameters['handleStartTime'] = parameters['handleStartTime']
  }
  if (parameters['handleEndTime'] !== undefined) {
    queryParameters['handleEndTime'] = parameters['handleEndTime']
  }
  if (parameters['acceptStartTime'] !== undefined) {
    queryParameters['acceptStartTime'] = parameters['acceptStartTime']
  }
  if (parameters['acceptEndTime'] !== undefined) {
    queryParameters['acceptEndTime'] = parameters['acceptEndTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters['toObjectId'] !== undefined) {
    queryParameters['toObjectId'] = parameters['toObjectId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 我的任务列表
 * request: cmScreeningTaskMyList
 * formData: cmScreeningTaskMyListFormData
 * url: cmScreeningTaskMyList_URL
 * method: cmScreeningTaskMyList_TYPE
 * raw_url: cmScreeningTaskMyList_RAW_URL
 * @param screeningPlanId - 
 * @param type - 
 * @param handleStartTime - 
 * @param handleEndTime - 
 * @param status - 
 * @param finished - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningTaskMyList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-task/my'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['handleStartTime'] !== undefined) {
    queryParameters['handleStartTime'] = parameters['handleStartTime']
  }
  if (parameters['handleEndTime'] !== undefined) {
    queryParameters['handleEndTime'] = parameters['handleEndTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['finished'] !== undefined) {
    queryParameters['finished'] = parameters['finished']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTaskMyListFormData = function(formData) {
  const url = cmScreeningTaskMyList_RAW_URL()
  const method = cmScreeningTaskMyList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTaskMyList_RAW_URL = function() {
  return '/common/screening-task/my'
}
export const cmScreeningTaskMyList_TYPE = function() {
  return 'get'
}
export const cmScreeningTaskMyList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-task/my'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['handleStartTime'] !== undefined) {
    queryParameters['handleStartTime'] = parameters['handleStartTime']
  }
  if (parameters['handleEndTime'] !== undefined) {
    queryParameters['handleEndTime'] = parameters['handleEndTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['finished'] !== undefined) {
    queryParameters['finished'] = parameters['finished']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 待接受任务列表
 * request: cmScreeningTaskWaitList
 * formData: cmScreeningTaskWaitListFormData
 * url: cmScreeningTaskWaitList_URL
 * method: cmScreeningTaskWaitList_TYPE
 * raw_url: cmScreeningTaskWaitList_RAW_URL
 * @param screeningPlanId - 
 * @param type - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmScreeningTaskWaitList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-task/wait'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTaskWaitListFormData = function(formData) {
  const url = cmScreeningTaskWaitList_RAW_URL()
  const method = cmScreeningTaskWaitList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTaskWaitList_RAW_URL = function() {
  return '/common/screening-task/wait'
}
export const cmScreeningTaskWaitList_TYPE = function() {
  return 'get'
}
export const cmScreeningTaskWaitList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-task/wait'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 任务详情
 * request: cmScreeningTaskFind
 * formData: cmScreeningTaskFindFormData
 * url: cmScreeningTaskFind_URL
 * method: cmScreeningTaskFind_TYPE
 * raw_url: cmScreeningTaskFind_RAW_URL
 * @param id - id
 */
export const cmScreeningTaskFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/screening-task/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTaskFindFormData = function(formData) {
  const url = cmScreeningTaskFind_RAW_URL()
  const method = cmScreeningTaskFind_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTaskFind_RAW_URL = function() {
  return '/common/screening-task/{id}'
}
export const cmScreeningTaskFind_TYPE = function() {
  return 'get'
}
export const cmScreeningTaskFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/screening-task/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 关闭筛查学校自动抽检
 * request: cmSpotCheckClose
 * formData: cmSpotCheckCloseFormData
 * url: cmSpotCheckClose_URL
 * method: cmSpotCheckClose_TYPE
 * raw_url: cmSpotCheckClose_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmSpotCheckClose = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/close'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckCloseFormData = function(formData) {
  const url = cmSpotCheckClose_RAW_URL()
  const method = cmSpotCheckClose_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckClose_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/close'
}
export const cmSpotCheckClose_TYPE = function() {
  return 'put'
}
export const cmSpotCheckClose_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/close'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 开启筛查学校自动抽检
 * request: cmSpotCheckOpen
 * formData: cmSpotCheckOpenFormData
 * url: cmSpotCheckOpen_URL
 * method: cmSpotCheckOpen_TYPE
 * raw_url: cmSpotCheckOpen_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmSpotCheckOpen = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/open'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckOpenFormData = function(formData) {
  const url = cmSpotCheckOpen_RAW_URL()
  const method = cmSpotCheckOpen_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckOpen_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/open'
}
export const cmSpotCheckOpen_TYPE = function() {
  return 'put'
}
export const cmSpotCheckOpen_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/open'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 手动随机抽检学生
 * request: cmSpotCheckRandomCheckStudent
 * formData: cmSpotCheckRandomCheckStudentFormData
 * url: cmSpotCheckRandomCheckStudent_URL
 * method: cmSpotCheckRandomCheckStudent_TYPE
 * raw_url: cmSpotCheckRandomCheckStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmSpotCheckRandomCheckStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/random-check'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckRandomCheckStudentFormData = function(formData) {
  const url = cmSpotCheckRandomCheckStudent_RAW_URL()
  const method = cmSpotCheckRandomCheckStudent_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckRandomCheckStudent_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/random-check'
}
export const cmSpotCheckRandomCheckStudent_TYPE = function() {
  return 'get'
}
export const cmSpotCheckRandomCheckStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/random-check'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 设置筛查学校复测率
 * request: cmSpotCheckSetRetestPercentage
 * formData: cmSpotCheckSetRetestPercentageFormData
 * url: cmSpotCheckSetRetestPercentage_URL
 * method: cmSpotCheckSetRetestPercentage_TYPE
 * raw_url: cmSpotCheckSetRetestPercentage_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param retestPercentage - 
 */
export const cmSpotCheckSetRetestPercentage = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/set-retest-percentage'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['retestPercentage'] !== undefined) {
    queryParameters['retestPercentage'] = parameters['retestPercentage']
  }
  if (parameters['retestPercentage'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: retestPercentage'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckSetRetestPercentageFormData = function(formData) {
  const url = cmSpotCheckSetRetestPercentage_RAW_URL()
  const method = cmSpotCheckSetRetestPercentage_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckSetRetestPercentage_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/set-retest-percentage'
}
export const cmSpotCheckSetRetestPercentage_TYPE = function() {
  return 'put'
}
export const cmSpotCheckSetRetestPercentage_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/set-retest-percentage'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['retestPercentage'] !== undefined) {
    queryParameters['retestPercentage'] = parameters['retestPercentage']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 系统抽检学生列表
 * request: cmSpotCheckListStudent
 * formData: cmSpotCheckListStudentFormData
 * url: cmSpotCheckListStudent_URL
 * method: cmSpotCheckListStudent_TYPE
 * raw_url: cmSpotCheckListStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const cmSpotCheckListStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckListStudentFormData = function(formData) {
  const url = cmSpotCheckListStudent_RAW_URL()
  const method = cmSpotCheckListStudent_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckListStudent_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/student'
}
export const cmSpotCheckListStudent_TYPE = function() {
  return 'get'
}
export const cmSpotCheckListStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已抽检学生数
 * request: cmSpotCheckStudentNum
 * formData: cmSpotCheckStudentNumFormData
 * url: cmSpotCheckStudentNum_URL
 * method: cmSpotCheckStudentNum_TYPE
 * raw_url: cmSpotCheckStudentNum_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const cmSpotCheckStudentNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/student-num'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmSpotCheckStudentNumFormData = function(formData) {
  const url = cmSpotCheckStudentNum_RAW_URL()
  const method = cmSpotCheckStudentNum_TYPE()
  return axios[method](url, formData)
}
export const cmSpotCheckStudentNum_RAW_URL = function() {
  return '/common/spot-check/screening-school/{screeningSchoolId}/student-num'
}
export const cmSpotCheckStudentNum_TYPE = function() {
  return 'get'
}
export const cmSpotCheckStudentNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/spot-check/screening-school/{screeningSchoolId}/student-num'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 市区查看筛查统计
 * request: cmScreeningStatisticsFindByCity
 * formData: cmScreeningStatisticsFindByCityFormData
 * url: cmScreeningStatisticsFindByCity_URL
 * method: cmScreeningStatisticsFindByCity_TYPE
 * raw_url: cmScreeningStatisticsFindByCity_RAW_URL
 * @param cityCode - cityCode
 * @param checkedEmpty - 
 */
export const cmScreeningStatisticsFindByCity = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-city'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByCityFormData = function(formData) {
  const url = cmScreeningStatisticsFindByCity_RAW_URL()
  const method = cmScreeningStatisticsFindByCity_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByCity_RAW_URL = function() {
  return '/common/statistics/by-city'
}
export const cmScreeningStatisticsFindByCity_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByCity_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-city'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区县查看筛查统计
 * request: cmScreeningStatisticsFindByDistrict
 * formData: cmScreeningStatisticsFindByDistrictFormData
 * url: cmScreeningStatisticsFindByDistrict_URL
 * method: cmScreeningStatisticsFindByDistrict_TYPE
 * raw_url: cmScreeningStatisticsFindByDistrict_RAW_URL
 * @param cityCode - cityCode
 * @param districtCode - districtCode
 * @param checkedEmpty - 
 */
export const cmScreeningStatisticsFindByDistrict = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-district'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByDistrictFormData = function(formData) {
  const url = cmScreeningStatisticsFindByDistrict_RAW_URL()
  const method = cmScreeningStatisticsFindByDistrict_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByDistrict_RAW_URL = function() {
  return '/common/statistics/by-district'
}
export const cmScreeningStatisticsFindByDistrict_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByDistrict_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-district'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区县查看总筛查统计
 * request: cmScreeningStatisticsFindByDistrictForTotal
 * formData: cmScreeningStatisticsFindByDistrictForTotalFormData
 * url: cmScreeningStatisticsFindByDistrictForTotal_URL
 * method: cmScreeningStatisticsFindByDistrictForTotal_TYPE
 * raw_url: cmScreeningStatisticsFindByDistrictForTotal_RAW_URL
 * @param cityCode - cityCode
 * @param districtCode - districtCode
 * @param checkedEmpty - 
 */
export const cmScreeningStatisticsFindByDistrictForTotal = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-district/total'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByDistrictForTotalFormData = function(formData) {
  const url = cmScreeningStatisticsFindByDistrictForTotal_RAW_URL()
  const method = cmScreeningStatisticsFindByDistrictForTotal_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByDistrictForTotal_RAW_URL = function() {
  return '/common/statistics/by-district/total'
}
export const cmScreeningStatisticsFindByDistrictForTotal_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByDistrictForTotal_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-district/total'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省市查看筛查统计
 * request: cmScreeningStatisticsFindByProvince
 * formData: cmScreeningStatisticsFindByProvinceFormData
 * url: cmScreeningStatisticsFindByProvince_URL
 * method: cmScreeningStatisticsFindByProvince_TYPE
 * raw_url: cmScreeningStatisticsFindByProvince_RAW_URL
 * @param checkedEmpty - 
 */
export const cmScreeningStatisticsFindByProvince = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-province'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByProvinceFormData = function(formData) {
  const url = cmScreeningStatisticsFindByProvince_RAW_URL()
  const method = cmScreeningStatisticsFindByProvince_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByProvince_RAW_URL = function() {
  return '/common/statistics/by-province'
}
export const cmScreeningStatisticsFindByProvince_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByProvince_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-province'
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校查看筛查统计
 * request: cmScreeningStatisticsFindBySchool
 * formData: cmScreeningStatisticsFindBySchoolFormData
 * url: cmScreeningStatisticsFindBySchool_URL
 * method: cmScreeningStatisticsFindBySchool_TYPE
 * raw_url: cmScreeningStatisticsFindBySchool_RAW_URL
 * @param schoolId - 
 * @param checkedEmpty - 
 * @param schoolType - 
 */
export const cmScreeningStatisticsFindBySchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindBySchoolFormData = function(formData) {
  const url = cmScreeningStatisticsFindBySchool_RAW_URL()
  const method = cmScreeningStatisticsFindBySchool_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindBySchool_RAW_URL = function() {
  return '/common/statistics/by-school'
}
export const cmScreeningStatisticsFindBySchool_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindBySchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-school'
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['checkedEmpty'] !== undefined) {
    queryParameters['checkedEmpty'] = parameters['checkedEmpty']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 市的筛查统计
 * request: cmScreeningStatisticsFindByPlanCityRegion
 * formData: cmScreeningStatisticsFindByPlanCityRegionFormData
 * url: cmScreeningStatisticsFindByPlanCityRegion_URL
 * method: cmScreeningStatisticsFindByPlanCityRegion_TYPE
 * raw_url: cmScreeningStatisticsFindByPlanCityRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const cmScreeningStatisticsFindByPlanCityRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/city-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByPlanCityRegionFormData = function(formData) {
  const url = cmScreeningStatisticsFindByPlanCityRegion_RAW_URL()
  const method = cmScreeningStatisticsFindByPlanCityRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByPlanCityRegion_RAW_URL = function() {
  return '/common/statistics/by-screening-plan/{screeningPlanId}/city-region'
}
export const cmScreeningStatisticsFindByPlanCityRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByPlanCityRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/city-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区的筛查统计
 * request: cmScreeningStatisticsFindByPlanDistrictRegion
 * formData: cmScreeningStatisticsFindByPlanDistrictRegionFormData
 * url: cmScreeningStatisticsFindByPlanDistrictRegion_URL
 * method: cmScreeningStatisticsFindByPlanDistrictRegion_TYPE
 * raw_url: cmScreeningStatisticsFindByPlanDistrictRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 * @param districtCode - 
 */
export const cmScreeningStatisticsFindByPlanDistrictRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/district-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByPlanDistrictRegionFormData = function(formData) {
  const url = cmScreeningStatisticsFindByPlanDistrictRegion_RAW_URL()
  const method = cmScreeningStatisticsFindByPlanDistrictRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByPlanDistrictRegion_RAW_URL = function() {
  return '/common/statistics/by-screening-plan/{screeningPlanId}/district-region'
}
export const cmScreeningStatisticsFindByPlanDistrictRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByPlanDistrictRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/district-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省的筛查统计
 * request: cmScreeningStatisticsFindByPlanProvinceRegion
 * formData: cmScreeningStatisticsFindByPlanProvinceRegionFormData
 * url: cmScreeningStatisticsFindByPlanProvinceRegion_URL
 * method: cmScreeningStatisticsFindByPlanProvinceRegion_TYPE
 * raw_url: cmScreeningStatisticsFindByPlanProvinceRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const cmScreeningStatisticsFindByPlanProvinceRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/province-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByPlanProvinceRegionFormData = function(formData) {
  const url = cmScreeningStatisticsFindByPlanProvinceRegion_RAW_URL()
  const method = cmScreeningStatisticsFindByPlanProvinceRegion_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByPlanProvinceRegion_RAW_URL = function() {
  return '/common/statistics/by-screening-plan/{screeningPlanId}/province-region'
}
export const cmScreeningStatisticsFindByPlanProvinceRegion_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByPlanProvinceRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/province-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校的筛查统计
 * request: cmScreeningStatisticsFindByPlanSchool
 * formData: cmScreeningStatisticsFindByPlanSchoolFormData
 * url: cmScreeningStatisticsFindByPlanSchool_URL
 * method: cmScreeningStatisticsFindByPlanSchool_TYPE
 * raw_url: cmScreeningStatisticsFindByPlanSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 * @param districtCode - 
 * @param schoolId - 
 */
export const cmScreeningStatisticsFindByPlanSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindByPlanSchoolFormData = function(formData) {
  const url = cmScreeningStatisticsFindByPlanSchool_RAW_URL()
  const method = cmScreeningStatisticsFindByPlanSchool_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindByPlanSchool_RAW_URL = function() {
  return '/common/statistics/by-screening-plan/{screeningPlanId}/school'
}
export const cmScreeningStatisticsFindByPlanSchool_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindByPlanSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/by-screening-plan/{screeningPlanId}/school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 时间范围内各计划的视力屈光筛查统计
 * request: cmScreeningStatisticsFindVisionDiopterByTime
 * formData: cmScreeningStatisticsFindVisionDiopterByTimeFormData
 * url: cmScreeningStatisticsFindVisionDiopterByTime_URL
 * method: cmScreeningStatisticsFindVisionDiopterByTime_TYPE
 * raw_url: cmScreeningStatisticsFindVisionDiopterByTime_RAW_URL
 * @param startDate - 
 * @param endDate - 
 * @param cityCode - 
 * @param districtCode - 
 * @param schoolId - 
 */
export const cmScreeningStatisticsFindVisionDiopterByTime = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/statistics/vision-diopter-statistics/by-time'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['startDate'] !== undefined) {
    queryParameters['startDate'] = parameters['startDate']
  }
  if (parameters['startDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startDate'))
  }
  if (parameters['endDate'] !== undefined) {
    queryParameters['endDate'] = parameters['endDate']
  }
  if (parameters['endDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: endDate'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningStatisticsFindVisionDiopterByTimeFormData = function(formData) {
  const url = cmScreeningStatisticsFindVisionDiopterByTime_RAW_URL()
  const method = cmScreeningStatisticsFindVisionDiopterByTime_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningStatisticsFindVisionDiopterByTime_RAW_URL = function() {
  return '/common/statistics/vision-diopter-statistics/by-time'
}
export const cmScreeningStatisticsFindVisionDiopterByTime_TYPE = function() {
  return 'get'
}
export const cmScreeningStatisticsFindVisionDiopterByTime_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/statistics/vision-diopter-statistics/by-time'
  if (parameters['startDate'] !== undefined) {
    queryParameters['startDate'] = parameters['startDate']
  }
  if (parameters['endDate'] !== undefined) {
    queryParameters['endDate'] = parameters['endDate']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划统计信息
 * request: cmStatsByPlan
 * formData: cmStatsByPlanFormData
 * url: cmStatsByPlan_URL
 * method: cmStatsByPlan_TYPE
 * raw_url: cmStatsByPlan_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningType - screeningType
 */
export const cmStatsByPlan = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/stats/by-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters['screeningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStatsByPlanFormData = function(formData) {
  const url = cmStatsByPlan_RAW_URL()
  const method = cmStatsByPlan_TYPE()
  return axios[method](url, formData)
}
export const cmStatsByPlan_RAW_URL = function() {
  return '/common/stats/by-plan'
}
export const cmStatsByPlan_TYPE = function() {
  return 'get'
}
export const cmStatsByPlan_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/stats/by-plan'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区统计信息
 * request: cmStatsByRegion
 * formData: cmStatsByRegionFormData
 * url: cmStatsByRegion_URL
 * method: cmStatsByRegion_TYPE
 * raw_url: cmStatsByRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 * @param screeningType - screeningType
 */
export const cmStatsByRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/stats/by-region'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters['screeningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStatsByRegionFormData = function(formData) {
  const url = cmStatsByRegion_RAW_URL()
  const method = cmStatsByRegion_TYPE()
  return axios[method](url, formData)
}
export const cmStatsByRegion_RAW_URL = function() {
  return '/common/stats/by-region'
}
export const cmStatsByRegion_TYPE = function() {
  return 'get'
}
export const cmStatsByRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/stats/by-region'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校统计信息
 * request: cmStatsBySchool
 * formData: cmStatsBySchoolFormData
 * url: cmStatsBySchool_URL
 * method: cmStatsBySchool_TYPE
 * raw_url: cmStatsBySchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningType - screeningType
 */
export const cmStatsBySchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/stats/by-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters['screeningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStatsBySchoolFormData = function(formData) {
  const url = cmStatsBySchool_RAW_URL()
  const method = cmStatsBySchool_TYPE()
  return axios[method](url, formData)
}
export const cmStatsBySchool_RAW_URL = function() {
  return '/common/stats/by-school'
}
export const cmStatsBySchool_TYPE = function() {
  return 'get'
}
export const cmStatsBySchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/stats/by-school'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningType'] !== undefined) {
    queryParameters['screeningType'] = parameters['screeningType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出家长查阅率excel文件
 * request: consultRatioExport
 * formData: consultRatioExportFormData
 * url: consultRatioExport_URL
 * method: consultRatioExport_TYPE
 * raw_url: consultRatioExport_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param scope - 不传(省)、市编码、区编码、学校id
 */
export const consultRatioExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/Export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['scope'] !== undefined) {
    queryParameters['scope'] = parameters['scope']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const consultRatioExportFormData = function(formData) {
  const url = consultRatioExport_RAW_URL()
  const method = consultRatioExport_TYPE()
  return axios[method](url, formData)
}
export const consultRatioExport_RAW_URL = function() {
  return '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/Export'
}
export const consultRatioExport_TYPE = function() {
  return 'get'
}
export const consultRatioExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/Export'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['scope'] !== undefined) {
    queryParameters['scope'] = parameters['scope']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 市的筛查查看统计
 * request: cmStudentScreeningLookStatisticsFindByCity
 * formData: cmStudentScreeningLookStatisticsFindByCityFormData
 * url: cmStudentScreeningLookStatisticsFindByCity_URL
 * method: cmStudentScreeningLookStatisticsFindByCity_TYPE
 * raw_url: cmStudentScreeningLookStatisticsFindByCity_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const cmStudentScreeningLookStatisticsFindByCity = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/city'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentScreeningLookStatisticsFindByCityFormData = function(formData) {
  const url = cmStudentScreeningLookStatisticsFindByCity_RAW_URL()
  const method = cmStudentScreeningLookStatisticsFindByCity_TYPE()
  return axios[method](url, formData)
}
export const cmStudentScreeningLookStatisticsFindByCity_RAW_URL = function() {
  return '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/city'
}
export const cmStudentScreeningLookStatisticsFindByCity_TYPE = function() {
  return 'get'
}
export const cmStudentScreeningLookStatisticsFindByCity_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/city'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区的筛查查看统计
 * request: cmStudentScreeningLookStatisticsFindByDistrict
 * formData: cmStudentScreeningLookStatisticsFindByDistrictFormData
 * url: cmStudentScreeningLookStatisticsFindByDistrict_URL
 * method: cmStudentScreeningLookStatisticsFindByDistrict_TYPE
 * raw_url: cmStudentScreeningLookStatisticsFindByDistrict_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param districtCode - 
 */
export const cmStudentScreeningLookStatisticsFindByDistrict = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/district'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentScreeningLookStatisticsFindByDistrictFormData = function(formData) {
  const url = cmStudentScreeningLookStatisticsFindByDistrict_RAW_URL()
  const method = cmStudentScreeningLookStatisticsFindByDistrict_TYPE()
  return axios[method](url, formData)
}
export const cmStudentScreeningLookStatisticsFindByDistrict_RAW_URL = function() {
  return '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/district'
}
export const cmStudentScreeningLookStatisticsFindByDistrict_TYPE = function() {
  return 'get'
}
export const cmStudentScreeningLookStatisticsFindByDistrict_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/district'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省的筛查查看统计
 * request: cmStudentScreeningLookStatisticsFindByProvince
 * formData: cmStudentScreeningLookStatisticsFindByProvinceFormData
 * url: cmStudentScreeningLookStatisticsFindByProvince_URL
 * method: cmStudentScreeningLookStatisticsFindByProvince_TYPE
 * raw_url: cmStudentScreeningLookStatisticsFindByProvince_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const cmStudentScreeningLookStatisticsFindByProvince = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/province'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentScreeningLookStatisticsFindByProvinceFormData = function(formData) {
  const url = cmStudentScreeningLookStatisticsFindByProvince_RAW_URL()
  const method = cmStudentScreeningLookStatisticsFindByProvince_TYPE()
  return axios[method](url, formData)
}
export const cmStudentScreeningLookStatisticsFindByProvince_RAW_URL = function() {
  return '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/province'
}
export const cmStudentScreeningLookStatisticsFindByProvince_TYPE = function() {
  return 'get'
}
export const cmStudentScreeningLookStatisticsFindByProvince_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/province'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校的筛查查看统计
 * request: cmStudentScreeningLookStatisticsFindByScreeningSchool
 * formData: cmStudentScreeningLookStatisticsFindByScreeningSchoolFormData
 * url: cmStudentScreeningLookStatisticsFindByScreeningSchool_URL
 * method: cmStudentScreeningLookStatisticsFindByScreeningSchool_TYPE
 * raw_url: cmStudentScreeningLookStatisticsFindByScreeningSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningSchoolId - 
 */
export const cmStudentScreeningLookStatisticsFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentScreeningLookStatisticsFindByScreeningSchoolFormData = function(formData) {
  const url = cmStudentScreeningLookStatisticsFindByScreeningSchool_RAW_URL()
  const method = cmStudentScreeningLookStatisticsFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const cmStudentScreeningLookStatisticsFindByScreeningSchool_RAW_URL = function() {
  return '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/screening-school'
}
export const cmStudentScreeningLookStatisticsFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const cmStudentScreeningLookStatisticsFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-screening-look-statistics/screening-plan/{screeningPlanId}/screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校id获取中小学生视力相关行为调查问卷
 * request: cmStudentVisionBehaviorByScreeningSchool
 * formData: cmStudentVisionBehaviorByScreeningSchoolFormData
 * url: cmStudentVisionBehaviorByScreeningSchool_URL
 * method: cmStudentVisionBehaviorByScreeningSchool_TYPE
 * raw_url: cmStudentVisionBehaviorByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const cmStudentVisionBehaviorByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentVisionBehaviorByScreeningSchoolFormData = function(formData) {
  const url = cmStudentVisionBehaviorByScreeningSchool_RAW_URL()
  const method = cmStudentVisionBehaviorByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const cmStudentVisionBehaviorByScreeningSchool_RAW_URL = function() {
  return '/common/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
}
export const cmStudentVisionBehaviorByScreeningSchool_TYPE = function() {
  return 'get'
}
export const cmStudentVisionBehaviorByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生id获取中小学生视力相关行为调查问卷
 * request: cmStudentVisionBehavior
 * formData: cmStudentVisionBehaviorFormData
 * url: cmStudentVisionBehavior_URL
 * method: cmStudentVisionBehavior_TYPE
 * raw_url: cmStudentVisionBehavior_RAW_URL
 * @param studentId - studentId
 */
export const cmStudentVisionBehavior = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/student-vision-behavior/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmStudentVisionBehaviorFormData = function(formData) {
  const url = cmStudentVisionBehavior_RAW_URL()
  const method = cmStudentVisionBehavior_TYPE()
  return axios[method](url, formData)
}
export const cmStudentVisionBehavior_RAW_URL = function() {
  return '/common/student-vision-behavior/by-student/{studentId}'
}
export const cmStudentVisionBehavior_TYPE = function() {
  return 'get'
}
export const cmStudentVisionBehavior_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/student-vision-behavior/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * initAdmin
 * request: initAdminUsingPOST
 * formData: initAdminUsingPOSTFormData
 * url: initAdminUsingPOST_URL
 * method: initAdminUsingPOST_TYPE
 * raw_url: initAdminUsingPOST_RAW_URL
 */
export const initAdminUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/system/init-admin'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const initAdminUsingPOSTFormData = function(formData) {
  const url = initAdminUsingPOST_RAW_URL()
  const method = initAdminUsingPOST_TYPE()
  return axios[method](url, formData)
}
export const initAdminUsingPOST_RAW_URL = function() {
  return '/common/system/init-admin'
}
export const initAdminUsingPOST_TYPE = function() {
  return 'post'
}
export const initAdminUsingPOST_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/system/init-admin'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划是否添加指标
 * request: cmTaskIndicatorCheckPlanIndicator
 * formData: cmTaskIndicatorCheckPlanIndicatorFormData
 * url: cmTaskIndicatorCheckPlanIndicator_URL
 * method: cmTaskIndicatorCheckPlanIndicator_TYPE
 * raw_url: cmTaskIndicatorCheckPlanIndicator_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 
 */
export const cmTaskIndicatorCheckPlanIndicator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/task-indicator/screening-plan/{screeningPlanId}/check-plan-indicator'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmTaskIndicatorCheckPlanIndicatorFormData = function(formData) {
  const url = cmTaskIndicatorCheckPlanIndicator_RAW_URL()
  const method = cmTaskIndicatorCheckPlanIndicator_TYPE()
  return axios[method](url, formData)
}
export const cmTaskIndicatorCheckPlanIndicator_RAW_URL = function() {
  return '/common/task-indicator/screening-plan/{screeningPlanId}/check-plan-indicator'
}
export const cmTaskIndicatorCheckPlanIndicator_TYPE = function() {
  return 'get'
}
export const cmTaskIndicatorCheckPlanIndicator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/task-indicator/screening-plan/{screeningPlanId}/check-plan-indicator'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队选择列表
 * request: cmScreeningTeamChoiceList
 * formData: cmScreeningTeamChoiceListFormData
 * url: cmScreeningTeamChoiceList_URL
 * method: cmScreeningTeamChoiceList_TYPE
 * raw_url: cmScreeningTeamChoiceList_RAW_URL
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param name - 机构名称
 */
export const cmScreeningTeamChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/team/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTeamChoiceListFormData = function(formData) {
  const url = cmScreeningTeamChoiceList_RAW_URL()
  const method = cmScreeningTeamChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTeamChoiceList_RAW_URL = function() {
  return '/common/team/choice'
}
export const cmScreeningTeamChoiceList_TYPE = function() {
  return 'get'
}
export const cmScreeningTeamChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/team/choice'
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队设备选择列表
 * request: cmScreeningTeamDeviceChoiceList
 * formData: cmScreeningTeamDeviceChoiceListFormData
 * url: cmScreeningTeamDeviceChoiceList_URL
 * method: cmScreeningTeamDeviceChoiceList_TYPE
 * raw_url: cmScreeningTeamDeviceChoiceList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param screeningTeamId - 筛查团队ID
 * @param type - 类型
 * @param status - 状态
 */
export const cmScreeningTeamDeviceChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/team/device-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['screeningTeamId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTeamId'))
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTeamDeviceChoiceListFormData = function(formData) {
  const url = cmScreeningTeamDeviceChoiceList_RAW_URL()
  const method = cmScreeningTeamDeviceChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTeamDeviceChoiceList_RAW_URL = function() {
  return '/common/team/device-choice'
}
export const cmScreeningTeamDeviceChoiceList_TYPE = function() {
  return 'get'
}
export const cmScreeningTeamDeviceChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/team/device-choice'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队转让负责人
 * request: cmScreeningTeamUpdateManager
 * formData: cmScreeningTeamUpdateManagerFormData
 * url: cmScreeningTeamUpdateManager_URL
 * method: cmScreeningTeamUpdateManager_TYPE
 * raw_url: cmScreeningTeamUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const cmScreeningTeamUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/team/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTeamUpdateManagerFormData = function(formData) {
  const url = cmScreeningTeamUpdateManager_RAW_URL()
  const method = cmScreeningTeamUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTeamUpdateManager_RAW_URL = function() {
  return '/common/team/manager'
}
export const cmScreeningTeamUpdateManager_TYPE = function() {
  return 'put'
}
export const cmScreeningTeamUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/team/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队的筛查计划列表
 * request: cmScreeningTeamScreeningPlanList
 * formData: cmScreeningTeamScreeningPlanListFormData
 * url: cmScreeningTeamScreeningPlanList_URL
 * method: cmScreeningTeamScreeningPlanList_TYPE
 * raw_url: cmScreeningTeamScreeningPlanList_RAW_URL
 * @param screeningTeamId - screeningTeamId
 */
export const cmScreeningTeamScreeningPlanList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/team/screening-team/{screeningTeamId}/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTeamId}', `${parameters['screeningTeamId']}`)
  if (parameters['screeningTeamId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTeamId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTeamScreeningPlanListFormData = function(formData) {
  const url = cmScreeningTeamScreeningPlanList_RAW_URL()
  const method = cmScreeningTeamScreeningPlanList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTeamScreeningPlanList_RAW_URL = function() {
  return '/common/team/screening-team/{screeningTeamId}/screening-plan'
}
export const cmScreeningTeamScreeningPlanList_TYPE = function() {
  return 'get'
}
export const cmScreeningTeamScreeningPlanList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/team/screening-team/{screeningTeamId}/screening-plan'
  path = path.replace('{screeningTeamId}', `${parameters['screeningTeamId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队工作人员选择列表
 * request: cmScreeningTeamWorkerChoiceList
 * formData: cmScreeningTeamWorkerChoiceListFormData
 * url: cmScreeningTeamWorkerChoiceList_URL
 * method: cmScreeningTeamWorkerChoiceList_TYPE
 * raw_url: cmScreeningTeamWorkerChoiceList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param screeningTeamId - 筛查团队ID
 * @param status - 状态
 */
export const cmScreeningTeamWorkerChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/team/worker-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['screeningTeamId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTeamId'))
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningTeamWorkerChoiceListFormData = function(formData) {
  const url = cmScreeningTeamWorkerChoiceList_RAW_URL()
  const method = cmScreeningTeamWorkerChoiceList_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningTeamWorkerChoiceList_RAW_URL = function() {
  return '/common/team/worker-choice'
}
export const cmScreeningTeamWorkerChoiceList_TYPE = function() {
  return 'get'
}
export const cmScreeningTeamWorkerChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/team/worker-choice'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取微信绑定二维码
 * request: cmWeixinBindCodeUrl
 * formData: cmWeixinBindCodeUrlFormData
 * url: cmWeixinBindCodeUrl_URL
 * method: cmWeixinBindCodeUrl_TYPE
 * raw_url: cmWeixinBindCodeUrl_RAW_URL
 */
export const cmWeixinBindCodeUrl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/bind-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWeixinBindCodeUrlFormData = function(formData) {
  const url = cmWeixinBindCodeUrl_RAW_URL()
  const method = cmWeixinBindCodeUrl_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinBindCodeUrl_RAW_URL = function() {
  return '/common/weixin/bind-code'
}
export const cmWeixinBindCodeUrl_TYPE = function() {
  return 'get'
}
export const cmWeixinBindCodeUrl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/bind-code'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disposeMsg
 * request: disposeMsgUsingGET
 * formData: disposeMsgUsingGETFormData
 * url: disposeMsgUsingGET_URL
 * method: disposeMsgUsingGET_TYPE
 * raw_url: disposeMsgUsingGET_RAW_URL
 * @param timestamp - timestamp
 * @param nonce - nonce
 * @param signature - signature
 * @param echostr - echostr
 */
export const disposeMsgUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/dispose-msg'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['timestamp'] !== undefined) {
    queryParameters['timestamp'] = parameters['timestamp']
  }
  if (parameters['timestamp'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: timestamp'))
  }
  if (parameters['nonce'] !== undefined) {
    queryParameters['nonce'] = parameters['nonce']
  }
  if (parameters['nonce'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: nonce'))
  }
  if (parameters['signature'] !== undefined) {
    queryParameters['signature'] = parameters['signature']
  }
  if (parameters['signature'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: signature'))
  }
  if (parameters['echostr'] !== undefined) {
    queryParameters['echostr'] = parameters['echostr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const disposeMsgUsingGETFormData = function(formData) {
  const url = disposeMsgUsingGET_RAW_URL()
  const method = disposeMsgUsingGET_TYPE()
  return axios[method](url, formData)
}
export const disposeMsgUsingGET_RAW_URL = function() {
  return '/common/weixin/dispose-msg'
}
export const disposeMsgUsingGET_TYPE = function() {
  return 'get'
}
export const disposeMsgUsingGET_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/dispose-msg'
  if (parameters['timestamp'] !== undefined) {
    queryParameters['timestamp'] = parameters['timestamp']
  }
  if (parameters['nonce'] !== undefined) {
    queryParameters['nonce'] = parameters['nonce']
  }
  if (parameters['signature'] !== undefined) {
    queryParameters['signature'] = parameters['signature']
  }
  if (parameters['echostr'] !== undefined) {
    queryParameters['echostr'] = parameters['echostr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * disposeMsg
 * request: disposeMsgUsingPOST
 * formData: disposeMsgUsingPOSTFormData
 * url: disposeMsgUsingPOST_URL
 * method: disposeMsgUsingPOST_TYPE
 * raw_url: disposeMsgUsingPOST_RAW_URL
 * @param timestamp - timestamp
 * @param nonce - nonce
 * @param signature - signature
 * @param echostr - echostr
 */
export const disposeMsgUsingPOST = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/dispose-msg'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['timestamp'] !== undefined) {
    queryParameters['timestamp'] = parameters['timestamp']
  }
  if (parameters['timestamp'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: timestamp'))
  }
  if (parameters['nonce'] !== undefined) {
    queryParameters['nonce'] = parameters['nonce']
  }
  if (parameters['nonce'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: nonce'))
  }
  if (parameters['signature'] !== undefined) {
    queryParameters['signature'] = parameters['signature']
  }
  if (parameters['signature'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: signature'))
  }
  if (parameters['echostr'] !== undefined) {
    queryParameters['echostr'] = parameters['echostr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const disposeMsgUsingPOSTFormData = function(formData) {
  const url = disposeMsgUsingPOST_RAW_URL()
  const method = disposeMsgUsingPOST_TYPE()
  return axios[method](url, formData)
}
export const disposeMsgUsingPOST_RAW_URL = function() {
  return '/common/weixin/dispose-msg'
}
export const disposeMsgUsingPOST_TYPE = function() {
  return 'post'
}
export const disposeMsgUsingPOST_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/dispose-msg'
  if (parameters['timestamp'] !== undefined) {
    queryParameters['timestamp'] = parameters['timestamp']
  }
  if (parameters['nonce'] !== undefined) {
    queryParameters['nonce'] = parameters['nonce']
  }
  if (parameters['signature'] !== undefined) {
    queryParameters['signature'] = parameters['signature']
  }
  if (parameters['echostr'] !== undefined) {
    queryParameters['echostr'] = parameters['echostr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 事件测试
 * request: cmWeixinEventTest
 * formData: cmWeixinEventTestFormData
 * url: cmWeixinEventTest_URL
 * method: cmWeixinEventTest_TYPE
 * raw_url: cmWeixinEventTest_RAW_URL
 * @param fromUserName - 
 * @param sceneStr - 
 */
export const cmWeixinEventTest = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/event-test'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['fromUserName'] !== undefined) {
    queryParameters['fromUserName'] = parameters['fromUserName']
  }
  if (parameters['fromUserName'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: fromUserName'))
  }
  if (parameters['sceneStr'] !== undefined) {
    queryParameters['sceneStr'] = parameters['sceneStr']
  }
  if (parameters['sceneStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sceneStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const cmWeixinEventTestFormData = function(formData) {
  const url = cmWeixinEventTest_RAW_URL()
  const method = cmWeixinEventTest_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinEventTest_RAW_URL = function() {
  return '/common/weixin/event-test'
}
export const cmWeixinEventTest_TYPE = function() {
  return 'put'
}
export const cmWeixinEventTest_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/event-test'
  if (parameters['fromUserName'] !== undefined) {
    queryParameters['fromUserName'] = parameters['fromUserName']
  }
  if (parameters['sceneStr'] !== undefined) {
    queryParameters['sceneStr'] = parameters['sceneStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取微信登录二维码
 * request: cmWeixinLoginCodeUrl
 * formData: cmWeixinLoginCodeUrlFormData
 * url: cmWeixinLoginCodeUrl_URL
 * method: cmWeixinLoginCodeUrl_TYPE
 * raw_url: cmWeixinLoginCodeUrl_RAW_URL
 * @param loginType - 
 */
export const cmWeixinLoginCodeUrl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/login-code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType'] = parameters['loginType']
  }
  if (parameters['loginType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: loginType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWeixinLoginCodeUrlFormData = function(formData) {
  const url = cmWeixinLoginCodeUrl_RAW_URL()
  const method = cmWeixinLoginCodeUrl_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinLoginCodeUrl_RAW_URL = function() {
  return '/common/weixin/login-code'
}
export const cmWeixinLoginCodeUrl_TYPE = function() {
  return 'get'
}
export const cmWeixinLoginCodeUrl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/login-code'
  if (parameters['loginType'] !== undefined) {
    queryParameters['loginType'] = parameters['loginType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 解除微信绑定
 * request: cmWeixinRemoveBind
 * formData: cmWeixinRemoveBindFormData
 * url: cmWeixinRemoveBind_URL
 * method: cmWeixinRemoveBind_TYPE
 * raw_url: cmWeixinRemoveBind_RAW_URL
 */
export const cmWeixinRemoveBind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/remove-bind'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWeixinRemoveBindFormData = function(formData) {
  const url = cmWeixinRemoveBind_RAW_URL()
  const method = cmWeixinRemoveBind_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinRemoveBind_RAW_URL = function() {
  return '/common/weixin/remove-bind'
}
export const cmWeixinRemoveBind_TYPE = function() {
  return 'get'
}
export const cmWeixinRemoveBind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/remove-bind'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取场景信息
 * request: cmWeixinFindScene
 * formData: cmWeixinFindSceneFormData
 * url: cmWeixinFindScene_URL
 * method: cmWeixinFindScene_TYPE
 * raw_url: cmWeixinFindScene_RAW_URL
 * @param sceneId - sceneId
 */
export const cmWeixinFindScene = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/scene/{sceneId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{sceneId}', `${parameters['sceneId']}`)
  if (parameters['sceneId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sceneId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWeixinFindSceneFormData = function(formData) {
  const url = cmWeixinFindScene_RAW_URL()
  const method = cmWeixinFindScene_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinFindScene_RAW_URL = function() {
  return '/common/weixin/scene/{sceneId}'
}
export const cmWeixinFindScene_TYPE = function() {
  return 'get'
}
export const cmWeixinFindScene_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/scene/{sceneId}'
  path = path.replace('{sceneId}', `${parameters['sceneId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 微信网页配置
 * request: cmWeixinWebConfig
 * formData: cmWeixinWebConfigFormData
 * url: cmWeixinWebConfig_URL
 * method: cmWeixinWebConfig_TYPE
 * raw_url: cmWeixinWebConfig_RAW_URL
 * @param url - url
 */
export const cmWeixinWebConfig = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/weixin/web-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters['url'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: url'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWeixinWebConfigFormData = function(formData) {
  const url = cmWeixinWebConfig_RAW_URL()
  const method = cmWeixinWebConfig_TYPE()
  return axios[method](url, formData)
}
export const cmWeixinWebConfig_RAW_URL = function() {
  return '/common/weixin/web-config'
}
export const cmWeixinWebConfig_TYPE = function() {
  return 'get'
}
export const cmWeixinWebConfig_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/weixin/web-config'
  if (parameters['url'] !== undefined) {
    queryParameters['url'] = parameters['url']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 注册筛查工作人员
 * request: cmScreeningWorkerAdd
 * formData: cmScreeningWorkerAddFormData
 * url: cmScreeningWorkerAdd_URL
 * method: cmScreeningWorkerAdd_TYPE
 * raw_url: cmScreeningWorkerAdd_RAW_URL
 * @param code - code
 * @param name - 姓名
 * @param organization - 所属单位
 * @param mobile - 手机号码
 * @param base64Str - 
 * @param certificateFiles - 资格图片
 */
export const cmScreeningWorkerAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/worker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['organization'] !== undefined) {
    form['organization'] = parameters['organization']
  }
  if (parameters['organization'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organization'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['base64Str'] !== undefined) {
    form['base64Str'] = parameters['base64Str']
  }
  if (parameters['base64Str'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: base64Str'))
  }
  if (parameters['certificateFiles'] !== undefined) {
    body = parameters['certificateFiles']
  }
  if (parameters['certificateFiles'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: certificateFiles'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const cmScreeningWorkerAddFormData = function(formData) {
  const url = cmScreeningWorkerAdd_RAW_URL()
  const method = cmScreeningWorkerAdd_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningWorkerAdd_RAW_URL = function() {
  return '/common/worker'
}
export const cmScreeningWorkerAdd_TYPE = function() {
  return 'post'
}
export const cmScreeningWorkerAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/worker'
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取注册信息
 * request: cmScreeningWorkerInfo
 * formData: cmScreeningWorkerInfoFormData
 * url: cmScreeningWorkerInfo_URL
 * method: cmScreeningWorkerInfo_TYPE
 * raw_url: cmScreeningWorkerInfo_RAW_URL
 * @param code - code
 */
export const cmScreeningWorkerInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/worker/info'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmScreeningWorkerInfoFormData = function(formData) {
  const url = cmScreeningWorkerInfo_RAW_URL()
  const method = cmScreeningWorkerInfo_TYPE()
  return axios[method](url, formData)
}
export const cmScreeningWorkerInfo_RAW_URL = function() {
  return '/common/worker/info'
}
export const cmScreeningWorkerInfo_TYPE = function() {
  return 'get'
}
export const cmScreeningWorkerInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/worker/info'
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户列表
 * request: cmWxAppletsTesterList
 * formData: cmWxAppletsTesterListFormData
 * url: cmWxAppletsTesterList_URL
 * method: cmWxAppletsTesterList_TYPE
 * raw_url: cmWxAppletsTesterList_RAW_URL
 * @param mobile - 手机
 * @param name - 姓名
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmWxAppletsTesterList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/wx_applets_tester'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWxAppletsTesterListFormData = function(formData) {
  const url = cmWxAppletsTesterList_RAW_URL()
  const method = cmWxAppletsTesterList_TYPE()
  return axios[method](url, formData)
}
export const cmWxAppletsTesterList_RAW_URL = function() {
  return '/common/wx_applets_tester'
}
export const cmWxAppletsTesterList_TYPE = function() {
  return 'get'
}
export const cmWxAppletsTesterList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/wx_applets_tester'
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 测试用户自测数据详情
 * request: cmWxAppletsVisionReportVOList
 * formData: cmWxAppletsVisionReportVOListFormData
 * url: cmWxAppletsVisionReportVOList_URL
 * method: cmWxAppletsVisionReportVOList_TYPE
 * raw_url: cmWxAppletsVisionReportVOList_RAW_URL
 * @param userId - userId
 * @param testerId - testerId
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const cmWxAppletsVisionReportVOList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/common/wx_applets_tester/{userId}/{testerId}/details'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{userId}', `${parameters['userId']}`)
  if (parameters['userId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: userId'))
  }
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters['testerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: testerId'))
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const cmWxAppletsVisionReportVOListFormData = function(formData) {
  const url = cmWxAppletsVisionReportVOList_RAW_URL()
  const method = cmWxAppletsVisionReportVOList_TYPE()
  return axios[method](url, formData)
}
export const cmWxAppletsVisionReportVOList_RAW_URL = function() {
  return '/common/wx_applets_tester/{userId}/{testerId}/details'
}
export const cmWxAppletsVisionReportVOList_TYPE = function() {
  return 'get'
}
export const cmWxAppletsVisionReportVOList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/common/wx_applets_tester/{userId}/{testerId}/details'
  path = path.replace('{userId}', `${parameters['userId']}`)
  path = path.replace('{testerId}', `${parameters['testerId']}`)
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改学生数据
 * request: jkJustDataStudentAdd
 * formData: jkJustDataStudentAddFormData
 * url: jkJustDataStudentAdd_URL
 * method: jkJustDataStudentAdd_TYPE
 * raw_url: jkJustDataStudentAdd_RAW_URL
 * @param voList - voList
 */
export const jkJustDataStudentAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/adjust-data-student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['voList'] !== undefined) {
    body = parameters['voList']
  }
  if (parameters['voList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkJustDataStudentAddFormData = function(formData) {
  const url = jkJustDataStudentAdd_RAW_URL()
  const method = jkJustDataStudentAdd_TYPE()
  return axios[method](url, formData)
}
export const jkJustDataStudentAdd_RAW_URL = function() {
  return '/jkzx/adjust-data-student'
}
export const jkJustDataStudentAdd_TYPE = function() {
  return 'post'
}
export const jkJustDataStudentAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/adjust-data-student'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通知列表
 * request: stNoticeList
 * formData: stNoticeListFormData
 * url: stNoticeList_URL
 * method: stNoticeList_TYPE
 * raw_url: stNoticeList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const stNoticeList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/adjust-data-student-notice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const stNoticeListFormData = function(formData) {
  const url = stNoticeList_RAW_URL()
  const method = stNoticeList_TYPE()
  return axios[method](url, formData)
}
export const stNoticeList_RAW_URL = function() {
  return '/jkzx/adjust-data-student-notice'
}
export const stNoticeList_TYPE = function() {
  return 'get'
}
export const stNoticeList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/adjust-data-student-notice'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通知详情
 * request: stNoticeDetail
 * formData: stNoticeDetailFormData
 * url: stNoticeDetail_URL
 * method: stNoticeDetail_TYPE
 * raw_url: stNoticeDetail_RAW_URL
 * @param id - id
 */
export const stNoticeDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/adjust-data-student-notice/{id}/detail'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const stNoticeDetailFormData = function(formData) {
  const url = stNoticeDetail_RAW_URL()
  const method = stNoticeDetail_TYPE()
  return axios[method](url, formData)
}
export const stNoticeDetail_RAW_URL = function() {
  return '/jkzx/adjust-data-student-notice/{id}/detail'
}
export const stNoticeDetail_TYPE = function() {
  return 'get'
}
export const stNoticeDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/adjust-data-student-notice/{id}/detail'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: jkAdministratorList
 * formData: jkAdministratorListFormData
 * url: jkAdministratorList_URL
 * method: jkAdministratorList_TYPE
 * raw_url: jkAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorListFormData = function(formData) {
  const url = jkAdministratorList_RAW_URL()
  const method = jkAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorList_RAW_URL = function() {
  return '/jkzx/administrator'
}
export const jkAdministratorList_TYPE = function() {
  return 'get'
}
export const jkAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: jkAdministratorAdd
 * formData: jkAdministratorAddFormData
 * url: jkAdministratorAdd_URL
 * method: jkAdministratorAdd_TYPE
 * raw_url: jkAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const jkAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorAddFormData = function(formData) {
  const url = jkAdministratorAdd_RAW_URL()
  const method = jkAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorAdd_RAW_URL = function() {
  return '/jkzx/administrator'
}
export const jkAdministratorAdd_TYPE = function() {
  return 'post'
}
export const jkAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 全部管理员选择列表
 * request: jkAdministratorAllChoiceList
 * formData: jkAdministratorAllChoiceListFormData
 * url: jkAdministratorAllChoiceList_URL
 * method: jkAdministratorAllChoiceList_TYPE
 * raw_url: jkAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const jkAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorAllChoiceListFormData = function(formData) {
  const url = jkAdministratorAllChoiceList_RAW_URL()
  const method = jkAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorAllChoiceList_RAW_URL = function() {
  return '/jkzx/administrator/all-choice'
}
export const jkAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const jkAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: jkImportAdministrator
 * formData: jkImportAdministratorFormData
 * url: jkImportAdministrator_URL
 * method: jkImportAdministrator_TYPE
 * raw_url: jkImportAdministrator_RAW_URL
 * @param file - 
 */
export const jkImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkImportAdministratorFormData = function(formData) {
  const url = jkImportAdministrator_RAW_URL()
  const method = jkImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const jkImportAdministrator_RAW_URL = function() {
  return '/jkzx/administrator/import'
}
export const jkImportAdministrator_TYPE = function() {
  return 'post'
}
export const jkImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: jkAdministratorDelete
 * formData: jkAdministratorDeleteFormData
 * url: jkAdministratorDelete_URL
 * method: jkAdministratorDelete_TYPE
 * raw_url: jkAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const jkAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorDeleteFormData = function(formData) {
  const url = jkAdministratorDelete_RAW_URL()
  const method = jkAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorDelete_RAW_URL = function() {
  return '/jkzx/administrator/{administratorId}'
}
export const jkAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const jkAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: jkAdministratorListRole
 * formData: jkAdministratorListRoleFormData
 * url: jkAdministratorListRole_URL
 * method: jkAdministratorListRole_TYPE
 * raw_url: jkAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const jkAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorListRoleFormData = function(formData) {
  const url = jkAdministratorListRole_RAW_URL()
  const method = jkAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorListRole_RAW_URL = function() {
  return '/jkzx/administrator/{administratorId}/role'
}
export const jkAdministratorListRole_TYPE = function() {
  return 'get'
}
export const jkAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: jkAdministratorAddRole
 * formData: jkAdministratorAddRoleFormData
 * url: jkAdministratorAddRole_URL
 * method: jkAdministratorAddRole_TYPE
 * raw_url: jkAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const jkAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorAddRoleFormData = function(formData) {
  const url = jkAdministratorAddRole_RAW_URL()
  const method = jkAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorAddRole_RAW_URL = function() {
  return '/jkzx/administrator/{administratorId}/role'
}
export const jkAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const jkAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: jkAdministratorDeleteRole
 * formData: jkAdministratorDeleteRoleFormData
 * url: jkAdministratorDeleteRole_URL
 * method: jkAdministratorDeleteRole_TYPE
 * raw_url: jkAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const jkAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleId'] !== undefined) {
    queryParameters['roleId'] = parameters['roleId']
  }
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkAdministratorDeleteRoleFormData = function(formData) {
  const url = jkAdministratorDeleteRole_RAW_URL()
  const method = jkAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const jkAdministratorDeleteRole_RAW_URL = function() {
  return '/jkzx/administrator/{administratorId}/role'
}
export const jkAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const jkAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['roleId'] !== undefined) {
    queryParameters['roleId'] = parameters['roleId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章列表
 * request: jkArticleList
 * formData: jkArticleListFormData
 * url: jkArticleList_URL
 * method: jkArticleList_TYPE
 * raw_url: jkArticleList_RAW_URL
 * @param categoryId - 类别id
 * @param title - 标题
 * @param readerType - 阅读者类型
 * @param intro - 简介
 * @param label - 宣教标签
 * @param creatorName - 创建人姓名
 * @param createTime - 创建时间
 * @param auditorName - 审核人
 * @param auditTime - 审核时间
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkArticleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleListFormData = function(formData) {
  const url = jkArticleList_RAW_URL()
  const method = jkArticleList_TYPE()
  return axios[method](url, formData)
}
export const jkArticleList_RAW_URL = function() {
  return '/jkzx/article'
}
export const jkArticleList_TYPE = function() {
  return 'get'
}
export const jkArticleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article'
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章
 * request: jkArticleAdd
 * formData: jkArticleAddFormData
 * url: jkArticleAdd_URL
 * method: jkArticleAdd_TYPE
 * raw_url: jkArticleAdd_RAW_URL
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const jkArticleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkArticleAddFormData = function(formData) {
  const url = jkArticleAdd_RAW_URL()
  const method = jkArticleAdd_TYPE()
  return axios[method](url, formData)
}
export const jkArticleAdd_RAW_URL = function() {
  return '/jkzx/article'
}
export const jkArticleAdd_TYPE = function() {
  return 'post'
}
export const jkArticleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类添加
 * request: jkArticleCategoryAdd
 * formData: jkArticleCategoryAddFormData
 * url: jkArticleCategoryAdd_URL
 * method: jkArticleCategoryAdd_TYPE
 * raw_url: jkArticleCategoryAdd_RAW_URL
 * @param parentId - 
 * @param name - 
 * @param earnIntegral - 
 */
export const jkArticleCategoryAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    form['parentId'] = parameters['parentId']
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['earnIntegral'] !== undefined) {
    form['earnIntegral'] = parameters['earnIntegral']
  }
  if (parameters['earnIntegral'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earnIntegral'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryAddFormData = function(formData) {
  const url = jkArticleCategoryAdd_RAW_URL()
  const method = jkArticleCategoryAdd_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryAdd_RAW_URL = function() {
  return '/jkzx/article-category'
}
export const jkArticleCategoryAdd_TYPE = function() {
  return 'post'
}
export const jkArticleCategoryAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类选择列表
 * request: jkArticleCategoryChoiceList
 * formData: jkArticleCategoryChoiceListFormData
 * url: jkArticleCategoryChoiceList_URL
 * method: jkArticleCategoryChoiceList_TYPE
 * raw_url: jkArticleCategoryChoiceList_RAW_URL
 * @param parentId - 
 * @param isAll - 
 */
export const jkArticleCategoryChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryChoiceListFormData = function(formData) {
  const url = jkArticleCategoryChoiceList_RAW_URL()
  const method = jkArticleCategoryChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryChoiceList_RAW_URL = function() {
  return '/jkzx/article-category/choice'
}
export const jkArticleCategoryChoiceList_TYPE = function() {
  return 'get'
}
export const jkArticleCategoryChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/choice'
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters['isAll'] !== undefined) {
    queryParameters['isAll'] = parameters['isAll']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取科普文章分类
 * request: jkArticleCategoryFind
 * formData: jkArticleCategoryFindFormData
 * url: jkArticleCategoryFind_URL
 * method: jkArticleCategoryFind_TYPE
 * raw_url: jkArticleCategoryFind_RAW_URL
 * @param articleCategoryId - articleCategoryId
 */
export const jkArticleCategoryFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/{articleCategoryId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryFindFormData = function(formData) {
  const url = jkArticleCategoryFind_RAW_URL()
  const method = jkArticleCategoryFind_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryFind_RAW_URL = function() {
  return '/jkzx/article-category/{articleCategoryId}'
}
export const jkArticleCategoryFind_TYPE = function() {
  return 'get'
}
export const jkArticleCategoryFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/{articleCategoryId}'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类修改
 * request: jkArticleCategoryUpdate
 * formData: jkArticleCategoryUpdateFormData
 * url: jkArticleCategoryUpdate_URL
 * method: jkArticleCategoryUpdate_TYPE
 * raw_url: jkArticleCategoryUpdate_RAW_URL
 * @param articleCategoryId - articleCategoryId
 * @param name - 
 * @param earnIntegral - 
 */
export const jkArticleCategoryUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/{articleCategoryId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['earnIntegral'] !== undefined) {
    form['earnIntegral'] = parameters['earnIntegral']
  }
  if (parameters['earnIntegral'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earnIntegral'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryUpdateFormData = function(formData) {
  const url = jkArticleCategoryUpdate_RAW_URL()
  const method = jkArticleCategoryUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryUpdate_RAW_URL = function() {
  return '/jkzx/article-category/{articleCategoryId}'
}
export const jkArticleCategoryUpdate_TYPE = function() {
  return 'put'
}
export const jkArticleCategoryUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/{articleCategoryId}'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类删除
 * request: jkArticleCategoryDelete
 * formData: jkArticleCategoryDeleteFormData
 * url: jkArticleCategoryDelete_URL
 * method: jkArticleCategoryDelete_TYPE
 * raw_url: jkArticleCategoryDelete_RAW_URL
 * @param articleCategoryId - articleCategoryId
 */
export const jkArticleCategoryDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/{articleCategoryId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryDeleteFormData = function(formData) {
  const url = jkArticleCategoryDelete_RAW_URL()
  const method = jkArticleCategoryDelete_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryDelete_RAW_URL = function() {
  return '/jkzx/article-category/{articleCategoryId}'
}
export const jkArticleCategoryDelete_TYPE = function() {
  return 'delete'
}
export const jkArticleCategoryDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/{articleCategoryId}'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类管理标签
 * request: jkArticleCategoryListArticleLabel
 * formData: jkArticleCategoryListArticleLabelFormData
 * url: jkArticleCategoryListArticleLabel_URL
 * method: jkArticleCategoryListArticleLabel_TYPE
 * raw_url: jkArticleCategoryListArticleLabel_RAW_URL
 * @param articleCategoryId - articleCategoryId
 */
export const jkArticleCategoryListArticleLabel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/{articleCategoryId}/article-label'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryListArticleLabelFormData = function(formData) {
  const url = jkArticleCategoryListArticleLabel_RAW_URL()
  const method = jkArticleCategoryListArticleLabel_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryListArticleLabel_RAW_URL = function() {
  return '/jkzx/article-category/{articleCategoryId}/article-label'
}
export const jkArticleCategoryListArticleLabel_TYPE = function() {
  return 'get'
}
export const jkArticleCategoryListArticleLabel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/{articleCategoryId}/article-label'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章分类添加标签
 * request: jkArticleCategoryAddLabel
 * formData: jkArticleCategoryAddLabelFormData
 * url: jkArticleCategoryAddLabel_URL
 * method: jkArticleCategoryAddLabel_TYPE
 * raw_url: jkArticleCategoryAddLabel_RAW_URL
 * @param articleCategoryId - articleCategoryId
 * @param label - 
 */
export const jkArticleCategoryAddLabel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-category/{articleCategoryId}/label'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['articleCategoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleCategoryId'))
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleCategoryAddLabelFormData = function(formData) {
  const url = jkArticleCategoryAddLabel_RAW_URL()
  const method = jkArticleCategoryAddLabel_TYPE()
  return axios[method](url, formData)
}
export const jkArticleCategoryAddLabel_RAW_URL = function() {
  return '/jkzx/article-category/{articleCategoryId}/label'
}
export const jkArticleCategoryAddLabel_TYPE = function() {
  return 'put'
}
export const jkArticleCategoryAddLabel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-category/{articleCategoryId}/label'
  path = path.replace('{articleCategoryId}', `${parameters['articleCategoryId']}`)
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航列表
 * request: jkArticleNavigationList
 * formData: jkArticleNavigationListFormData
 * url: jkArticleNavigationList_URL
 * method: jkArticleNavigationList_TYPE
 * raw_url: jkArticleNavigationList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkArticleNavigationList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationListFormData = function(formData) {
  const url = jkArticleNavigationList_RAW_URL()
  const method = jkArticleNavigationList_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationList_RAW_URL = function() {
  return '/jkzx/article-navigation'
}
export const jkArticleNavigationList_TYPE = function() {
  return 'get'
}
export const jkArticleNavigationList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航添加
 * request: jkArticleNavigationAdd
 * formData: jkArticleNavigationAddFormData
 * url: jkArticleNavigationAdd_URL
 * method: jkArticleNavigationAdd_TYPE
 * raw_url: jkArticleNavigationAdd_RAW_URL
 * @param parentId - 
 * @param name - 
 * @param status - 
 * @param sortNum - 
 * @param categoryIdStr - 二级导航必填
 */
export const jkArticleNavigationAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    form['parentId'] = parameters['parentId']
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters['categoryIdStr'] !== undefined) {
    form['categoryIdStr'] = parameters['categoryIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationAddFormData = function(formData) {
  const url = jkArticleNavigationAdd_RAW_URL()
  const method = jkArticleNavigationAdd_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationAdd_RAW_URL = function() {
  return '/jkzx/article-navigation'
}
export const jkArticleNavigationAdd_TYPE = function() {
  return 'post'
}
export const jkArticleNavigationAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航选择列表
 * request: jkArticleNavigationChoiceList
 * formData: jkArticleNavigationChoiceListFormData
 * url: jkArticleNavigationChoiceList_URL
 * method: jkArticleNavigationChoiceList_TYPE
 * raw_url: jkArticleNavigationChoiceList_RAW_URL
 * @param parentId - 
 */
export const jkArticleNavigationChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationChoiceListFormData = function(formData) {
  const url = jkArticleNavigationChoiceList_RAW_URL()
  const method = jkArticleNavigationChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationChoiceList_RAW_URL = function() {
  return '/jkzx/article-navigation/choice'
}
export const jkArticleNavigationChoiceList_TYPE = function() {
  return 'get'
}
export const jkArticleNavigationChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation/choice'
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取科普文章导航排序号
 * request: jkArticleNavigationGetSortNum
 * formData: jkArticleNavigationGetSortNumFormData
 * url: jkArticleNavigationGetSortNum_URL
 * method: jkArticleNavigationGetSortNum_TYPE
 * raw_url: jkArticleNavigationGetSortNum_RAW_URL
 * @param parentId - 
 */
export const jkArticleNavigationGetSortNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation/sort-num'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationGetSortNumFormData = function(formData) {
  const url = jkArticleNavigationGetSortNum_RAW_URL()
  const method = jkArticleNavigationGetSortNum_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationGetSortNum_RAW_URL = function() {
  return '/jkzx/article-navigation/sort-num'
}
export const jkArticleNavigationGetSortNum_TYPE = function() {
  return 'get'
}
export const jkArticleNavigationGetSortNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation/sort-num'
  if (parameters['parentId'] !== undefined) {
    queryParameters['parentId'] = parameters['parentId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航修改
 * request: jkArticleNavigationUpdate
 * formData: jkArticleNavigationUpdateFormData
 * url: jkArticleNavigationUpdate_URL
 * method: jkArticleNavigationUpdate_TYPE
 * raw_url: jkArticleNavigationUpdate_RAW_URL
 * @param articleNavigationId - articleNavigationId
 * @param name - 
 * @param status - 
 * @param sortNum - 
 * @param categoryIdStr - 二级导航必填
 */
export const jkArticleNavigationUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation/{articleNavigationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters['articleNavigationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleNavigationId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters['categoryIdStr'] !== undefined) {
    form['categoryIdStr'] = parameters['categoryIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationUpdateFormData = function(formData) {
  const url = jkArticleNavigationUpdate_RAW_URL()
  const method = jkArticleNavigationUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationUpdate_RAW_URL = function() {
  return '/jkzx/article-navigation/{articleNavigationId}'
}
export const jkArticleNavigationUpdate_TYPE = function() {
  return 'put'
}
export const jkArticleNavigationUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation/{articleNavigationId}'
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章导航删除
 * request: jkArticleNavigationDelete
 * formData: jkArticleNavigationDeleteFormData
 * url: jkArticleNavigationDelete_URL
 * method: jkArticleNavigationDelete_TYPE
 * raw_url: jkArticleNavigationDelete_RAW_URL
 * @param articleNavigationId - articleNavigationId
 */
export const jkArticleNavigationDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article-navigation/{articleNavigationId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters['articleNavigationId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleNavigationId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkArticleNavigationDeleteFormData = function(formData) {
  const url = jkArticleNavigationDelete_RAW_URL()
  const method = jkArticleNavigationDelete_TYPE()
  return axios[method](url, formData)
}
export const jkArticleNavigationDelete_RAW_URL = function() {
  return '/jkzx/article-navigation/{articleNavigationId}'
}
export const jkArticleNavigationDelete_TYPE = function() {
  return 'delete'
}
export const jkArticleNavigationDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article-navigation/{articleNavigationId}'
  path = path.replace('{articleNavigationId}', `${parameters['articleNavigationId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传文件
 * request: jkArticleUploadFile
 * formData: jkArticleUploadFileFormData
 * url: jkArticleUploadFile_URL
 * method: jkArticleUploadFile_TYPE
 * raw_url: jkArticleUploadFile_RAW_URL
 * @param file - 
 * @param image - 
 */
export const jkArticleUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkArticleUploadFileFormData = function(formData) {
  const url = jkArticleUploadFile_RAW_URL()
  const method = jkArticleUploadFile_TYPE()
  return axios[method](url, formData)
}
export const jkArticleUploadFile_RAW_URL = function() {
  return '/jkzx/article/file'
}
export const jkArticleUploadFile_TYPE = function() {
  return 'post'
}
export const jkArticleUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/file'
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章待审核列表
 * request: jkArticleWaitAudit
 * formData: jkArticleWaitAuditFormData
 * url: jkArticleWaitAudit_URL
 * method: jkArticleWaitAudit_TYPE
 * raw_url: jkArticleWaitAudit_RAW_URL
 */
export const jkArticleWaitAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/wait-audit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleWaitAuditFormData = function(formData) {
  const url = jkArticleWaitAudit_RAW_URL()
  const method = jkArticleWaitAudit_TYPE()
  return axios[method](url, formData)
}
export const jkArticleWaitAudit_RAW_URL = function() {
  return '/jkzx/article/wait-audit'
}
export const jkArticleWaitAudit_TYPE = function() {
  return 'get'
}
export const jkArticleWaitAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/wait-audit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章预警关联列表
 * request: jkArticleEarlyWarningList
 * formData: jkArticleEarlyWarningListFormData
 * url: jkArticleEarlyWarningList_URL
 * method: jkArticleEarlyWarningList_TYPE
 * raw_url: jkArticleEarlyWarningList_RAW_URL
 * @param articleId - articleId
 */
export const jkArticleEarlyWarningList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleEarlyWarningListFormData = function(formData) {
  const url = jkArticleEarlyWarningList_RAW_URL()
  const method = jkArticleEarlyWarningList_TYPE()
  return axios[method](url, formData)
}
export const jkArticleEarlyWarningList_RAW_URL = function() {
  return '/jkzx/article/{articleId}/early-warning'
}
export const jkArticleEarlyWarningList_TYPE = function() {
  return 'get'
}
export const jkArticleEarlyWarningList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章预警关联
 * request: jkArticleEarlyWarningAdd
 * formData: jkArticleEarlyWarningAddFormData
 * url: jkArticleEarlyWarningAdd_URL
 * method: jkArticleEarlyWarningAdd_TYPE
 * raw_url: jkArticleEarlyWarningAdd_RAW_URL
 * @param articleId - articleId
 * @param earlyWarningType - 
 * @param earlyWarningLevel - 
 */
export const jkArticleEarlyWarningAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters['earlyWarningType'] !== undefined) {
    form['earlyWarningType'] = parameters['earlyWarningType']
  }
  if (parameters['earlyWarningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningType'))
  }
  if (parameters['earlyWarningLevel'] !== undefined) {
    form['earlyWarningLevel'] = parameters['earlyWarningLevel']
  }
  if (parameters['earlyWarningLevel'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningLevel'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkArticleEarlyWarningAddFormData = function(formData) {
  const url = jkArticleEarlyWarningAdd_RAW_URL()
  const method = jkArticleEarlyWarningAdd_TYPE()
  return axios[method](url, formData)
}
export const jkArticleEarlyWarningAdd_RAW_URL = function() {
  return '/jkzx/article/{articleId}/early-warning'
}
export const jkArticleEarlyWarningAdd_TYPE = function() {
  return 'post'
}
export const jkArticleEarlyWarningAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章预警关联
 * request: jkArticleEarlyWarningDelete
 * formData: jkArticleEarlyWarningDeleteFormData
 * url: jkArticleEarlyWarningDelete_URL
 * method: jkArticleEarlyWarningDelete_TYPE
 * raw_url: jkArticleEarlyWarningDelete_RAW_URL
 * @param articleId - articleId
 * @param id - id
 */
export const jkArticleEarlyWarningDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{articleId}/early-warning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkArticleEarlyWarningDeleteFormData = function(formData) {
  const url = jkArticleEarlyWarningDelete_RAW_URL()
  const method = jkArticleEarlyWarningDelete_TYPE()
  return axios[method](url, formData)
}
export const jkArticleEarlyWarningDelete_RAW_URL = function() {
  return '/jkzx/article/{articleId}/early-warning/{id}'
}
export const jkArticleEarlyWarningDelete_TYPE = function() {
  return 'delete'
}
export const jkArticleEarlyWarningDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{articleId}/early-warning/{id}'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章详情
 * request: jkArticleFind
 * formData: jkArticleFindFormData
 * url: jkArticleFind_URL
 * method: jkArticleFind_TYPE
 * raw_url: jkArticleFind_RAW_URL
 * @param id - id
 */
export const jkArticleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleFindFormData = function(formData) {
  const url = jkArticleFind_RAW_URL()
  const method = jkArticleFind_TYPE()
  return axios[method](url, formData)
}
export const jkArticleFind_RAW_URL = function() {
  return '/jkzx/article/{id}'
}
export const jkArticleFind_TYPE = function() {
  return 'get'
}
export const jkArticleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改科普文章
 * request: jkArticleUpdate
 * formData: jkArticleUpdateFormData
 * url: jkArticleUpdate_URL
 * method: jkArticleUpdate_TYPE
 * raw_url: jkArticleUpdate_RAW_URL
 * @param id - id
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const jkArticleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleUpdateFormData = function(formData) {
  const url = jkArticleUpdate_RAW_URL()
  const method = jkArticleUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkArticleUpdate_RAW_URL = function() {
  return '/jkzx/article/{id}'
}
export const jkArticleUpdate_TYPE = function() {
  return 'put'
}
export const jkArticleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章
 * request: jkArticleDelete
 * formData: jkArticleDeleteFormData
 * url: jkArticleDelete_URL
 * method: jkArticleDelete_TYPE
 * raw_url: jkArticleDelete_RAW_URL
 * @param id - id
 */
export const jkArticleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkArticleDeleteFormData = function(formData) {
  const url = jkArticleDelete_RAW_URL()
  const method = jkArticleDelete_TYPE()
  return axios[method](url, formData)
}
export const jkArticleDelete_RAW_URL = function() {
  return '/jkzx/article/{id}'
}
export const jkArticleDelete_TYPE = function() {
  return 'delete'
}
export const jkArticleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 草稿科普文章
 * request: jkArticleDraft
 * formData: jkArticleDraftFormData
 * url: jkArticleDraft_URL
 * method: jkArticleDraft_TYPE
 * raw_url: jkArticleDraft_RAW_URL
 * @param id - id
 */
export const jkArticleDraft = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/draft'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleDraftFormData = function(formData) {
  const url = jkArticleDraft_RAW_URL()
  const method = jkArticleDraft_TYPE()
  return axios[method](url, formData)
}
export const jkArticleDraft_RAW_URL = function() {
  return '/jkzx/article/{id}/draft'
}
export const jkArticleDraft_TYPE = function() {
  return 'put'
}
export const jkArticleDraft_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/draft'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 下架科普文章
 * request: jkArticleLowerShelf
 * formData: jkArticleLowerShelfFormData
 * url: jkArticleLowerShelf_URL
 * method: jkArticleLowerShelf_TYPE
 * raw_url: jkArticleLowerShelf_RAW_URL
 * @param id - id
 */
export const jkArticleLowerShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/lower-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleLowerShelfFormData = function(formData) {
  const url = jkArticleLowerShelf_RAW_URL()
  const method = jkArticleLowerShelf_TYPE()
  return axios[method](url, formData)
}
export const jkArticleLowerShelf_RAW_URL = function() {
  return '/jkzx/article/{id}/lower-shelf'
}
export const jkArticleLowerShelf_TYPE = function() {
  return 'put'
}
export const jkArticleLowerShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/lower-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章通过审核
 * request: jkArticlePass
 * formData: jkArticlePassFormData
 * url: jkArticlePass_URL
 * method: jkArticlePass_TYPE
 * raw_url: jkArticlePass_RAW_URL
 * @param id - id
 */
export const jkArticlePass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/pass'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticlePassFormData = function(formData) {
  const url = jkArticlePass_RAW_URL()
  const method = jkArticlePass_TYPE()
  return axios[method](url, formData)
}
export const jkArticlePass_RAW_URL = function() {
  return '/jkzx/article/{id}/pass'
}
export const jkArticlePass_TYPE = function() {
  return 'put'
}
export const jkArticlePass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/pass'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发布科普文章
 * request: jkArticlePublish
 * formData: jkArticlePublishFormData
 * url: jkArticlePublish_URL
 * method: jkArticlePublish_TYPE
 * raw_url: jkArticlePublish_RAW_URL
 * @param id - id
 */
export const jkArticlePublish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/publish'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticlePublishFormData = function(formData) {
  const url = jkArticlePublish_RAW_URL()
  const method = jkArticlePublish_TYPE()
  return axios[method](url, formData)
}
export const jkArticlePublish_RAW_URL = function() {
  return '/jkzx/article/{id}/publish'
}
export const jkArticlePublish_TYPE = function() {
  return 'put'
}
export const jkArticlePublish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/publish'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章阅读学校统计
 * request: jkArticleReadSchoolStats
 * formData: jkArticleReadSchoolStatsFormData
 * url: jkArticleReadSchoolStats_URL
 * method: jkArticleReadSchoolStats_TYPE
 * raw_url: jkArticleReadSchoolStats_RAW_URL
 * @param id - id
 */
export const jkArticleReadSchoolStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/read-school-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleReadSchoolStatsFormData = function(formData) {
  const url = jkArticleReadSchoolStats_RAW_URL()
  const method = jkArticleReadSchoolStats_TYPE()
  return axios[method](url, formData)
}
export const jkArticleReadSchoolStats_RAW_URL = function() {
  return '/jkzx/article/{id}/read-school-stats'
}
export const jkArticleReadSchoolStats_TYPE = function() {
  return 'get'
}
export const jkArticleReadSchoolStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/read-school-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章驳回
 * request: jkArticleReject
 * formData: jkArticleRejectFormData
 * url: jkArticleReject_URL
 * method: jkArticleReject_TYPE
 * raw_url: jkArticleReject_RAW_URL
 * @param id - id
 * @param rejectReason - 
 */
export const jkArticleReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters['rejectReason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rejectReason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleRejectFormData = function(formData) {
  const url = jkArticleReject_RAW_URL()
  const method = jkArticleReject_TYPE()
  return axios[method](url, formData)
}
export const jkArticleReject_RAW_URL = function() {
  return '/jkzx/article/{id}/reject'
}
export const jkArticleReject_TYPE = function() {
  return 'put'
}
export const jkArticleReject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章未阅读学校
 * request: jkArticleUnreadSchool
 * formData: jkArticleUnreadSchoolFormData
 * url: jkArticleUnreadSchool_URL
 * method: jkArticleUnreadSchool_TYPE
 * raw_url: jkArticleUnreadSchool_RAW_URL
 * @param id - id
 */
export const jkArticleUnreadSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/unread-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleUnreadSchoolFormData = function(formData) {
  const url = jkArticleUnreadSchool_RAW_URL()
  const method = jkArticleUnreadSchool_TYPE()
  return axios[method](url, formData)
}
export const jkArticleUnreadSchool_RAW_URL = function() {
  return '/jkzx/article/{id}/unread-school'
}
export const jkArticleUnreadSchool_TYPE = function() {
  return 'get'
}
export const jkArticleUnreadSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/unread-school'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上架科普文章
 * request: jkArticleUpperShelf
 * formData: jkArticleUpperShelfFormData
 * url: jkArticleUpperShelf_URL
 * method: jkArticleUpperShelf_TYPE
 * raw_url: jkArticleUpperShelf_RAW_URL
 * @param id - id
 */
export const jkArticleUpperShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/upper-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkArticleUpperShelfFormData = function(formData) {
  const url = jkArticleUpperShelf_RAW_URL()
  const method = jkArticleUpperShelf_TYPE()
  return axios[method](url, formData)
}
export const jkArticleUpperShelf_RAW_URL = function() {
  return '/jkzx/article/{id}/upper-shelf'
}
export const jkArticleUpperShelf_TYPE = function() {
  return 'put'
}
export const jkArticleUpperShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/upper-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章1周内用户阅读统计
 * request: jkArticleReadWeekUserStats
 * formData: jkArticleReadWeekUserStatsFormData
 * url: jkArticleReadWeekUserStats_URL
 * method: jkArticleReadWeekUserStats_TYPE
 * raw_url: jkArticleReadWeekUserStats_RAW_URL
 * @param id - id
 */
export const jkArticleReadWeekUserStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/article/{id}/week-user-read-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkArticleReadWeekUserStatsFormData = function(formData) {
  const url = jkArticleReadWeekUserStats_RAW_URL()
  const method = jkArticleReadWeekUserStats_TYPE()
  return axios[method](url, formData)
}
export const jkArticleReadWeekUserStats_RAW_URL = function() {
  return '/jkzx/article/{id}/week-user-read-stats'
}
export const jkArticleReadWeekUserStats_TYPE = function() {
  return 'get'
}
export const jkArticleReadWeekUserStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/article/{id}/week-user-read-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 当天完成筛查数量
 * request: JkScreeningCompleteStatisticsTodayComplete
 * formData: JkScreeningCompleteStatisticsTodayCompleteFormData
 * url: JkScreeningCompleteStatisticsTodayComplete_URL
 * method: JkScreeningCompleteStatisticsTodayComplete_TYPE
 * raw_url: JkScreeningCompleteStatisticsTodayComplete_RAW_URL
 */
export const JkScreeningCompleteStatisticsTodayComplete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/complete-statistics/today-complete'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const JkScreeningCompleteStatisticsTodayCompleteFormData = function(formData) {
  const url = JkScreeningCompleteStatisticsTodayComplete_RAW_URL()
  const method = JkScreeningCompleteStatisticsTodayComplete_TYPE()
  return axios[method](url, formData)
}
export const JkScreeningCompleteStatisticsTodayComplete_RAW_URL = function() {
  return '/jkzx/complete-statistics/today-complete'
}
export const JkScreeningCompleteStatisticsTodayComplete_TYPE = function() {
  return 'get'
}
export const JkScreeningCompleteStatisticsTodayComplete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/complete-statistics/today-complete'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 当天完成调查问卷数量
 * request: JkScreeningCompleteStatisticsTodayWithQuestionary
 * formData: JkScreeningCompleteStatisticsTodayWithQuestionaryFormData
 * url: JkScreeningCompleteStatisticsTodayWithQuestionary_URL
 * method: JkScreeningCompleteStatisticsTodayWithQuestionary_TYPE
 * raw_url: JkScreeningCompleteStatisticsTodayWithQuestionary_RAW_URL
 */
export const JkScreeningCompleteStatisticsTodayWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/complete-statistics/today-with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const JkScreeningCompleteStatisticsTodayWithQuestionaryFormData = function(formData) {
  const url = JkScreeningCompleteStatisticsTodayWithQuestionary_RAW_URL()
  const method = JkScreeningCompleteStatisticsTodayWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const JkScreeningCompleteStatisticsTodayWithQuestionary_RAW_URL = function() {
  return '/jkzx/complete-statistics/today-with-questionary'
}
export const JkScreeningCompleteStatisticsTodayWithQuestionary_TYPE = function() {
  return 'get'
}
export const JkScreeningCompleteStatisticsTodayWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/complete-statistics/today-with-questionary'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查设备列表
 * request: jkScreeningDeviceList
 * formData: jkScreeningDeviceListFormData
 * url: jkScreeningDeviceList_URL
 * method: jkScreeningDeviceList_TYPE
 * raw_url: jkScreeningDeviceList_RAW_URL
 * @param screeningTeamId - 筛查团队ID
 * @param status - 状态
 * @param screeningPlanId - 筛查计划ID
 * @param screeningTeamIdStr - 
 * @param type - 类型
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningDeviceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/device'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamIdStr'] !== undefined) {
    queryParameters['screeningTeamIdStr'] = parameters['screeningTeamIdStr']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningDeviceListFormData = function(formData) {
  const url = jkScreeningDeviceList_RAW_URL()
  const method = jkScreeningDeviceList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningDeviceList_RAW_URL = function() {
  return '/jkzx/device'
}
export const jkScreeningDeviceList_TYPE = function() {
  return 'get'
}
export const jkScreeningDeviceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/device'
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamIdStr'] !== undefined) {
    queryParameters['screeningTeamIdStr'] = parameters['screeningTeamIdStr']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查设备通过审核
 * request: jkScreeningDevicePass
 * formData: jkScreeningDevicePassFormData
 * url: jkScreeningDevicePass_URL
 * method: jkScreeningDevicePass_TYPE
 * raw_url: jkScreeningDevicePass_RAW_URL
 * @param id - id
 */
export const jkScreeningDevicePass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/device/{id}/pass'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningDevicePassFormData = function(formData) {
  const url = jkScreeningDevicePass_RAW_URL()
  const method = jkScreeningDevicePass_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningDevicePass_RAW_URL = function() {
  return '/jkzx/device/{id}/pass'
}
export const jkScreeningDevicePass_TYPE = function() {
  return 'put'
}
export const jkScreeningDevicePass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/device/{id}/pass'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查设备驳回
 * request: jkScreeningDeviceReject
 * formData: jkScreeningDeviceRejectFormData
 * url: jkScreeningDeviceReject_URL
 * method: jkScreeningDeviceReject_TYPE
 * raw_url: jkScreeningDeviceReject_RAW_URL
 * @param id - id
 * @param reason - 原因
 */
export const jkScreeningDeviceReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/device/{id}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['reason'] !== undefined) {
    form['reason'] = parameters['reason']
  }
  if (parameters['reason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningDeviceRejectFormData = function(formData) {
  const url = jkScreeningDeviceReject_RAW_URL()
  const method = jkScreeningDeviceReject_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningDeviceReject_RAW_URL = function() {
  return '/jkzx/device/{id}/reject'
}
export const jkScreeningDeviceReject_TYPE = function() {
  return 'put'
}
export const jkScreeningDeviceReject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/device/{id}/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 下载全省excel文件
 * request: jkExcelFileDownloadFile
 * formData: jkExcelFileDownloadFileFormData
 * url: jkExcelFileDownloadFile_URL
 * method: jkExcelFileDownloadFile_TYPE
 * raw_url: jkExcelFileDownloadFile_RAW_URL
 * @param planId - planId
 * @param accessToken - 
 * @param condition - excel导出条件
 */
export const jkExcelFileDownloadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/excel-file/{planId}/download-file'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkExcelFileDownloadFileFormData = function(formData) {
  const url = jkExcelFileDownloadFile_RAW_URL()
  const method = jkExcelFileDownloadFile_TYPE()
  return axios[method](url, formData)
}
export const jkExcelFileDownloadFile_RAW_URL = function() {
  return '/jkzx/excel-file/{planId}/download-file'
}
export const jkExcelFileDownloadFile_TYPE = function() {
  return 'get'
}
export const jkExcelFileDownloadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/excel-file/{planId}/download-file'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出全省excel文件
 * request: jkExcelFileExport
 * formData: jkExcelFileExportFormData
 * url: jkExcelFileExport_URL
 * method: jkExcelFileExport_TYPE
 * raw_url: jkExcelFileExport_RAW_URL
 * @param planId - planId
 * @param condition - excel导出条件
 */
export const jkExcelFileExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/excel-file/{planId}/export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkExcelFileExportFormData = function(formData) {
  const url = jkExcelFileExport_RAW_URL()
  const method = jkExcelFileExport_TYPE()
  return axios[method](url, formData)
}
export const jkExcelFileExport_RAW_URL = function() {
  return '/jkzx/excel-file/{planId}/export'
}
export const jkExcelFileExport_TYPE = function() {
  return 'get'
}
export const jkExcelFileExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/excel-file/{planId}/export'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更新全省excel文件
 * request: jkExcelFileUpdateExport
 * formData: jkExcelFileUpdateExportFormData
 * url: jkExcelFileUpdateExport_URL
 * method: jkExcelFileUpdateExport_TYPE
 * raw_url: jkExcelFileUpdateExport_RAW_URL
 * @param planId - planId
 * @param condition - excel导出条件
 */
export const jkExcelFileUpdateExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/excel-file/{planId}/updateExport'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkExcelFileUpdateExportFormData = function(formData) {
  const url = jkExcelFileUpdateExport_RAW_URL()
  const method = jkExcelFileUpdateExport_TYPE()
  return axios[method](url, formData)
}
export const jkExcelFileUpdateExport_RAW_URL = function() {
  return '/jkzx/excel-file/{planId}/updateExport'
}
export const jkExcelFileUpdateExport_TYPE = function() {
  return 'get'
}
export const jkExcelFileUpdateExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/excel-file/{planId}/updateExport'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加疾控部门
 * request: jkGovernmentSectorAdd
 * formData: jkGovernmentSectorAddFormData
 * url: jkGovernmentSectorAdd_URL
 * method: jkGovernmentSectorAdd_TYPE
 * raw_url: jkGovernmentSectorAdd_RAW_URL
 * @param regionType - 地区类型
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param managerId - 管理员ID
 */
export const jkGovernmentSectorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/government-sector'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionType'] !== undefined) {
    form['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkGovernmentSectorAddFormData = function(formData) {
  const url = jkGovernmentSectorAdd_RAW_URL()
  const method = jkGovernmentSectorAdd_TYPE()
  return axios[method](url, formData)
}
export const jkGovernmentSectorAdd_RAW_URL = function() {
  return '/jkzx/government-sector'
}
export const jkGovernmentSectorAdd_TYPE = function() {
  return 'post'
}
export const jkGovernmentSectorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/government-sector'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 疾控部门
 * request: jkGovernmentSectorFindAndChildren
 * formData: jkGovernmentSectorFindAndChildrenFormData
 * url: jkGovernmentSectorFindAndChildren_URL
 * method: jkGovernmentSectorFindAndChildren_TYPE
 * raw_url: jkGovernmentSectorFindAndChildren_RAW_URL
 */
export const jkGovernmentSectorFindAndChildren = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/government-sector/children'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkGovernmentSectorFindAndChildrenFormData = function(formData) {
  const url = jkGovernmentSectorFindAndChildren_RAW_URL()
  const method = jkGovernmentSectorFindAndChildren_TYPE()
  return axios[method](url, formData)
}
export const jkGovernmentSectorFindAndChildren_RAW_URL = function() {
  return '/jkzx/government-sector/children'
}
export const jkGovernmentSectorFindAndChildren_TYPE = function() {
  return 'get'
}
export const jkGovernmentSectorFindAndChildren_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/government-sector/children'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 疾控部门转让负责人
 * request: jkGovernmentSectorUpdateManager
 * formData: jkGovernmentSectorUpdateManagerFormData
 * url: jkGovernmentSectorUpdateManager_URL
 * method: jkGovernmentSectorUpdateManager_TYPE
 * raw_url: jkGovernmentSectorUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const jkGovernmentSectorUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/government-sector/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkGovernmentSectorUpdateManagerFormData = function(formData) {
  const url = jkGovernmentSectorUpdateManager_RAW_URL()
  const method = jkGovernmentSectorUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const jkGovernmentSectorUpdateManager_RAW_URL = function() {
  return '/jkzx/government-sector/manager'
}
export const jkGovernmentSectorUpdateManager_TYPE = function() {
  return 'put'
}
export const jkGovernmentSectorUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/government-sector/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 疾控部门详情
 * request: jkGovernmentSectorFind
 * formData: jkGovernmentSectorFindFormData
 * url: jkGovernmentSectorFind_URL
 * method: jkGovernmentSectorFind_TYPE
 * raw_url: jkGovernmentSectorFind_RAW_URL
 * @param governmentSectorId - governmentSectorId
 */
export const jkGovernmentSectorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/government-sector/{governmentSectorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkGovernmentSectorFindFormData = function(formData) {
  const url = jkGovernmentSectorFind_RAW_URL()
  const method = jkGovernmentSectorFind_TYPE()
  return axios[method](url, formData)
}
export const jkGovernmentSectorFind_RAW_URL = function() {
  return '/jkzx/government-sector/{governmentSectorId}'
}
export const jkGovernmentSectorFind_TYPE = function() {
  return 'get'
}
export const jkGovernmentSectorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/government-sector/{governmentSectorId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改疾控部门管理员
 * request: jkGovernmentSectorUpdate
 * formData: jkGovernmentSectorUpdateFormData
 * url: jkGovernmentSectorUpdate_URL
 * method: jkGovernmentSectorUpdate_TYPE
 * raw_url: jkGovernmentSectorUpdate_RAW_URL
 * @param governmentSectorId - governmentSectorId
 * @param managerId - managerId
 */
export const jkGovernmentSectorUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/government-sector/{governmentSectorId}/administrator/{managerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkGovernmentSectorUpdateFormData = function(formData) {
  const url = jkGovernmentSectorUpdate_RAW_URL()
  const method = jkGovernmentSectorUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkGovernmentSectorUpdate_RAW_URL = function() {
  return '/jkzx/government-sector/{governmentSectorId}/administrator/{managerId}'
}
export const jkGovernmentSectorUpdate_TYPE = function() {
  return 'put'
}
export const jkGovernmentSectorUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/government-sector/{governmentSectorId}/administrator/{managerId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 年级列表
 * request: jkGradeConfigList
 * formData: jkGradeConfigListFormData
 * url: jkGradeConfigList_URL
 * method: jkGradeConfigList_TYPE
 * raw_url: jkGradeConfigList_RAW_URL
 * @param schoolType - 学校类型
 * @param code - 编码
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkGradeConfigList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/grade-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkGradeConfigListFormData = function(formData) {
  const url = jkGradeConfigList_RAW_URL()
  const method = jkGradeConfigList_TYPE()
  return axios[method](url, formData)
}
export const jkGradeConfigList_RAW_URL = function() {
  return '/jkzx/grade-config'
}
export const jkGradeConfigList_TYPE = function() {
  return 'get'
}
export const jkGradeConfigList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/grade-config'
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加年级
 * request: jkGradeConfigAdd
 * formData: jkGradeConfigAddFormData
 * url: jkGradeConfigAdd_URL
 * method: jkGradeConfigAdd_TYPE
 * raw_url: jkGradeConfigAdd_RAW_URL
 * @param name - 
 * @param code - 
 * @param schoolType - 
 * @param sortNum - 排序号
 */
export const jkGradeConfigAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/grade-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkGradeConfigAddFormData = function(formData) {
  const url = jkGradeConfigAdd_RAW_URL()
  const method = jkGradeConfigAdd_TYPE()
  return axios[method](url, formData)
}
export const jkGradeConfigAdd_RAW_URL = function() {
  return '/jkzx/grade-config'
}
export const jkGradeConfigAdd_TYPE = function() {
  return 'post'
}
export const jkGradeConfigAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/grade-config'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取年级排序号
 * request: jkGradeConfigSortNum
 * formData: jkGradeConfigSortNumFormData
 * url: jkGradeConfigSortNum_URL
 * method: jkGradeConfigSortNum_TYPE
 * raw_url: jkGradeConfigSortNum_RAW_URL
 */
export const jkGradeConfigSortNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/grade-config/sort-num'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkGradeConfigSortNumFormData = function(formData) {
  const url = jkGradeConfigSortNum_RAW_URL()
  const method = jkGradeConfigSortNum_TYPE()
  return axios[method](url, formData)
}
export const jkGradeConfigSortNum_RAW_URL = function() {
  return '/jkzx/grade-config/sort-num'
}
export const jkGradeConfigSortNum_TYPE = function() {
  return 'put'
}
export const jkGradeConfigSortNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/grade-config/sort-num'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 编辑年级
 * request: jkGradeConfigUpdate
 * formData: jkGradeConfigUpdateFormData
 * url: jkGradeConfigUpdate_URL
 * method: jkGradeConfigUpdate_TYPE
 * raw_url: jkGradeConfigUpdate_RAW_URL
 * @param gradeConfigId - gradeConfigId
 * @param name - 
 * @param code - 
 * @param schoolType - 
 * @param sortNum - 排序号
 */
export const jkGradeConfigUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/grade-config/{gradeConfigId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{gradeConfigId}', `${parameters['gradeConfigId']}`)
  if (parameters['gradeConfigId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeConfigId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkGradeConfigUpdateFormData = function(formData) {
  const url = jkGradeConfigUpdate_RAW_URL()
  const method = jkGradeConfigUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkGradeConfigUpdate_RAW_URL = function() {
  return '/jkzx/grade-config/{gradeConfigId}'
}
export const jkGradeConfigUpdate_TYPE = function() {
  return 'put'
}
export const jkGradeConfigUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/grade-config/{gradeConfigId}'
  path = path.replace('{gradeConfigId}', `${parameters['gradeConfigId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 调查任务列表
 * request: jkInvestigationTaskList
 * formData: jkInvestigationTaskListFormData
 * url: jkInvestigationTaskList_URL
 * method: jkInvestigationTaskList_TYPE
 * raw_url: jkInvestigationTaskList_RAW_URL
 * @param creatorSectorId - 创建部门ID
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param regionType - 地区类型
 * @param type - 调查类型
 * @param creatorStartDate - 创建开始时间
 * @param creatorEndDate - 创建结束时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkInvestigationTaskList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['creatorSectorId'] !== undefined) {
    queryParameters['creatorSectorId'] = parameters['creatorSectorId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['creatorStartDate'] !== undefined) {
    queryParameters['creatorStartDate'] = parameters['creatorStartDate']
  }
  if (parameters['creatorEndDate'] !== undefined) {
    queryParameters['creatorEndDate'] = parameters['creatorEndDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskListFormData = function(formData) {
  const url = jkInvestigationTaskList_RAW_URL()
  const method = jkInvestigationTaskList_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskList_RAW_URL = function() {
  return '/jkzx/investigation-task'
}
export const jkInvestigationTaskList_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task'
  if (parameters['creatorSectorId'] !== undefined) {
    queryParameters['creatorSectorId'] = parameters['creatorSectorId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['creatorStartDate'] !== undefined) {
    queryParameters['creatorStartDate'] = parameters['creatorStartDate']
  }
  if (parameters['creatorEndDate'] !== undefined) {
    queryParameters['creatorEndDate'] = parameters['creatorEndDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增调查任务
 * request: jkInvestigationTaskAdd
 * formData: jkInvestigationTaskAddFormData
 * url: jkInvestigationTaskAdd_URL
 * method: jkInvestigationTaskAdd_TYPE
 * raw_url: jkInvestigationTaskAdd_RAW_URL
 * @param name - 名称
 * @param cityCode - 筛查市编码
 * @param districtCode - 筛查区编码
 * @param type - 调查类型
 * @param depiction - 描述
 */
export const jkInvestigationTaskAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['depiction'] !== undefined) {
    form['depiction'] = parameters['depiction']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskAddFormData = function(formData) {
  const url = jkInvestigationTaskAdd_RAW_URL()
  const method = jkInvestigationTaskAdd_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskAdd_RAW_URL = function() {
  return '/jkzx/investigation-task'
}
export const jkInvestigationTaskAdd_TYPE = function() {
  return 'post'
}
export const jkInvestigationTaskAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人的学生健康状况及影响因素调查表(中学版)
 * request: jkInvestigationTaskFindItMshiq
 * formData: jkInvestigationTaskFindItMshiqFormData
 * url: jkInvestigationTaskFindItMshiq_URL
 * method: jkInvestigationTaskFindItMshiq_TYPE
 * raw_url: jkInvestigationTaskFindItMshiq_RAW_URL
 * @param itShiqId - itShiqId
 * @param cityCode - 
 */
export const jkInvestigationTaskFindItMshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/it-mshiq/{itShiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['itShiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itShiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskFindItMshiqFormData = function(formData) {
  const url = jkInvestigationTaskFindItMshiq_RAW_URL()
  const method = jkInvestigationTaskFindItMshiq_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskFindItMshiq_RAW_URL = function() {
  return '/jkzx/investigation-task/it-mshiq/{itShiqId}'
}
export const jkInvestigationTaskFindItMshiq_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskFindItMshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/it-mshiq/{itShiqId}'
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人的学生健康状况及影响因素调查表(小学版)
 * request: jkInvestigationTaskFindItPshiq
 * formData: jkInvestigationTaskFindItPshiqFormData
 * url: jkInvestigationTaskFindItPshiq_URL
 * method: jkInvestigationTaskFindItPshiq_TYPE
 * raw_url: jkInvestigationTaskFindItPshiq_RAW_URL
 * @param itShiqId - itShiqId
 * @param cityCode - 
 */
export const jkInvestigationTaskFindItPshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/it-pshiq/{itShiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['itShiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itShiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskFindItPshiqFormData = function(formData) {
  const url = jkInvestigationTaskFindItPshiq_RAW_URL()
  const method = jkInvestigationTaskFindItPshiq_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskFindItPshiq_RAW_URL = function() {
  return '/jkzx/investigation-task/it-pshiq/{itShiqId}'
}
export const jkInvestigationTaskFindItPshiq_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskFindItPshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/it-pshiq/{itShiqId}'
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人的学生视力不良及影响因素专项调查表
 * request: jkInvestigationTaskFindItSpvisq
 * formData: jkInvestigationTaskFindItSpvisqFormData
 * url: jkInvestigationTaskFindItSpvisq_URL
 * method: jkInvestigationTaskFindItSpvisq_TYPE
 * raw_url: jkInvestigationTaskFindItSpvisq_RAW_URL
 * @param itSpvispId - itSpvispId
 * @param cityCode - 
 */
export const jkInvestigationTaskFindItSpvisq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/it-spvisq/{itSpvispId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itSpvispId}', `${parameters['itSpvispId']}`)
  if (parameters['itSpvispId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itSpvispId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskFindItSpvisqFormData = function(formData) {
  const url = jkInvestigationTaskFindItSpvisq_RAW_URL()
  const method = jkInvestigationTaskFindItSpvisq_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskFindItSpvisq_RAW_URL = function() {
  return '/jkzx/investigation-task/it-spvisq/{itSpvispId}'
}
export const jkInvestigationTaskFindItSpvisq_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskFindItSpvisq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/it-spvisq/{itSpvispId}'
  path = path.replace('{itSpvispId}', `${parameters['itSpvispId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人的学生健康状况及影响因素调查表(大学版)
 * request: jkInvestigationTaskFindItUshiq
 * formData: jkInvestigationTaskFindItUshiqFormData
 * url: jkInvestigationTaskFindItUshiq_URL
 * method: jkInvestigationTaskFindItUshiq_TYPE
 * raw_url: jkInvestigationTaskFindItUshiq_RAW_URL
 * @param itShiqId - itShiqId
 * @param cityCode - 
 */
export const jkInvestigationTaskFindItUshiq = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/it-ushiq/{itShiqId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['itShiqId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: itShiqId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskFindItUshiqFormData = function(formData) {
  const url = jkInvestigationTaskFindItUshiq_RAW_URL()
  const method = jkInvestigationTaskFindItUshiq_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskFindItUshiq_RAW_URL = function() {
  return '/jkzx/investigation-task/it-ushiq/{itShiqId}'
}
export const jkInvestigationTaskFindItUshiq_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskFindItUshiq_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/it-ushiq/{itShiqId}'
  path = path.replace('{itShiqId}', `${parameters['itShiqId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 结束调查任务
 * request: jkInvestigationTaskEnd
 * formData: jkInvestigationTaskEndFormData
 * url: jkInvestigationTaskEnd_URL
 * method: jkInvestigationTaskEnd_TYPE
 * raw_url: jkInvestigationTaskEnd_RAW_URL
 * @param investigationTaskId - investigationTaskId
 */
export const jkInvestigationTaskEnd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/{investigationTaskId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskEndFormData = function(formData) {
  const url = jkInvestigationTaskEnd_RAW_URL()
  const method = jkInvestigationTaskEnd_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskEnd_RAW_URL = function() {
  return '/jkzx/investigation-task/{investigationTaskId}'
}
export const jkInvestigationTaskEnd_TYPE = function() {
  return 'put'
}
export const jkInvestigationTaskEnd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/{investigationTaskId}'
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 调查任务调查地区列表
 * request: jkInvestigationTaskListInvestigationTaskRegion
 * formData: jkInvestigationTaskListInvestigationTaskRegionFormData
 * url: jkInvestigationTaskListInvestigationTaskRegion_URL
 * method: jkInvestigationTaskListInvestigationTaskRegion_TYPE
 * raw_url: jkInvestigationTaskListInvestigationTaskRegion_RAW_URL
 * @param investigationTaskId - investigationTaskId
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 */
export const jkInvestigationTaskListInvestigationTaskRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/{investigationTaskId}/investigation-task-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskListInvestigationTaskRegionFormData = function(formData) {
  const url = jkInvestigationTaskListInvestigationTaskRegion_RAW_URL()
  const method = jkInvestigationTaskListInvestigationTaskRegion_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskListInvestigationTaskRegion_RAW_URL = function() {
  return '/jkzx/investigation-task/{investigationTaskId}/investigation-task-region'
}
export const jkInvestigationTaskListInvestigationTaskRegion_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskListInvestigationTaskRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/{investigationTaskId}/investigation-task-region'
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 调查任务选项统计
 * request: jkInvestigationTaskOptionStats
 * formData: jkInvestigationTaskOptionStatsFormData
 * url: jkInvestigationTaskOptionStats_URL
 * method: jkInvestigationTaskOptionStats_TYPE
 * raw_url: jkInvestigationTaskOptionStats_RAW_URL
 * @param investigationTaskId - investigationTaskId
 * @param cityCode - 筛查市编码
 * @param districtCode - 名称
 * @param type - 类型
 * @param schoolId - 学校ID
 * @param shiqSchoolType - 学校类型
 */
export const jkInvestigationTaskOptionStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/{investigationTaskId}/option-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['shiqSchoolType'] !== undefined) {
    queryParameters['shiqSchoolType'] = parameters['shiqSchoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskOptionStatsFormData = function(formData) {
  const url = jkInvestigationTaskOptionStats_RAW_URL()
  const method = jkInvestigationTaskOptionStats_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskOptionStats_RAW_URL = function() {
  return '/jkzx/investigation-task/{investigationTaskId}/option-stats'
}
export const jkInvestigationTaskOptionStats_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskOptionStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/{investigationTaskId}/option-stats'
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['shiqSchoolType'] !== undefined) {
    queryParameters['shiqSchoolType'] = parameters['shiqSchoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 调查任务受检人列表
 * request: jkInvestigationTaskListRegionPerson
 * formData: jkInvestigationTaskListRegionPersonFormData
 * url: jkInvestigationTaskListRegionPerson_URL
 * method: jkInvestigationTaskListRegionPerson_TYPE
 * raw_url: jkInvestigationTaskListRegionPerson_RAW_URL
 * @param investigationTaskId - investigationTaskId
 * @param cityCode - 筛查市编码
 * @param districtCode - 区编码
 * @param schoolId - 学校ID
 * @param shiqSchoolType - 学生健康状况及影响因素调查表学校类型
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkInvestigationTaskListRegionPerson = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/investigation-task/{investigationTaskId}/region-person'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['investigationTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: investigationTaskId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['shiqSchoolType'] !== undefined) {
    queryParameters['shiqSchoolType'] = parameters['shiqSchoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkInvestigationTaskListRegionPersonFormData = function(formData) {
  const url = jkInvestigationTaskListRegionPerson_RAW_URL()
  const method = jkInvestigationTaskListRegionPerson_TYPE()
  return axios[method](url, formData)
}
export const jkInvestigationTaskListRegionPerson_RAW_URL = function() {
  return '/jkzx/investigation-task/{investigationTaskId}/region-person'
}
export const jkInvestigationTaskListRegionPerson_TYPE = function() {
  return 'get'
}
export const jkInvestigationTaskListRegionPerson_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/investigation-task/{investigationTaskId}/region-person'
  path = path.replace('{investigationTaskId}', `${parameters['investigationTaskId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['shiqSchoolType'] !== undefined) {
    queryParameters['shiqSchoolType'] = parameters['shiqSchoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构列表
 * request: jkMedicalInstitutionList
 * formData: jkMedicalInstitutionListFormData
 * url: jkMedicalInstitutionList_URL
 * method: jkMedicalInstitutionList_TYPE
 * raw_url: jkMedicalInstitutionList_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param name - 机构名称
 * @param phone - 电话
 * @param address - 地址
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkMedicalInstitutionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['phone'] !== undefined) {
    queryParameters['phone'] = parameters['phone']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionListFormData = function(formData) {
  const url = jkMedicalInstitutionList_RAW_URL()
  const method = jkMedicalInstitutionList_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionList_RAW_URL = function() {
  return '/jkzx/medical-institution'
}
export const jkMedicalInstitutionList_TYPE = function() {
  return 'get'
}
export const jkMedicalInstitutionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['phone'] !== undefined) {
    queryParameters['phone'] = parameters['phone']
  }
  if (parameters['address'] !== undefined) {
    queryParameters['address'] = parameters['address']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加医疗机构
 * request: jkMedicalInstitutionAdd
 * formData: jkMedicalInstitutionAddFormData
 * url: jkMedicalInstitutionAdd_URL
 * method: jkMedicalInstitutionAdd_TYPE
 * raw_url: jkMedicalInstitutionAdd_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param name - 医疗机构名称
 * @param managerId - 管理员ID
 * @param intro - 简介
 * @param address - 地址
 * @param phone - 电话号码
 * @param appointmentGuide - 
 * @param appointmentUrl - 
 * @param picture - 
 */
export const jkMedicalInstitutionAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['address'] !== undefined) {
    form['address'] = parameters['address']
  }
  if (parameters['address'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: address'))
  }
  if (parameters['phone'] !== undefined) {
    form['phone'] = parameters['phone']
  }
  if (parameters['phone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: phone'))
  }
  if (parameters['appointmentGuide'] !== undefined) {
    form['appointmentGuide'] = parameters['appointmentGuide']
  }
  if (parameters['appointmentUrl'] !== undefined) {
    form['appointmentUrl'] = parameters['appointmentUrl']
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionAddFormData = function(formData) {
  const url = jkMedicalInstitutionAdd_RAW_URL()
  const method = jkMedicalInstitutionAdd_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionAdd_RAW_URL = function() {
  return '/jkzx/medical-institution'
}
export const jkMedicalInstitutionAdd_TYPE = function() {
  return 'post'
}
export const jkMedicalInstitutionAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传图片
 * request: jkMedicalInstitutionUploadFile
 * formData: jkMedicalInstitutionUploadFileFormData
 * url: jkMedicalInstitutionUploadFile_URL
 * method: jkMedicalInstitutionUploadFile_TYPE
 * raw_url: jkMedicalInstitutionUploadFile_RAW_URL
 * @param file - 
 */
export const jkMedicalInstitutionUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionUploadFileFormData = function(formData) {
  const url = jkMedicalInstitutionUploadFile_RAW_URL()
  const method = jkMedicalInstitutionUploadFile_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionUploadFile_RAW_URL = function() {
  return '/jkzx/medical-institution/file'
}
export const jkMedicalInstitutionUploadFile_TYPE = function() {
  return 'post'
}
export const jkMedicalInstitutionUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构详情
 * request: jkMedicalInstitutionFind
 * formData: jkMedicalInstitutionFindFormData
 * url: jkMedicalInstitutionFind_URL
 * method: jkMedicalInstitutionFind_TYPE
 * raw_url: jkMedicalInstitutionFind_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 */
export const jkMedicalInstitutionFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionFindFormData = function(formData) {
  const url = jkMedicalInstitutionFind_RAW_URL()
  const method = jkMedicalInstitutionFind_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionFind_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}'
}
export const jkMedicalInstitutionFind_TYPE = function() {
  return 'get'
}
export const jkMedicalInstitutionFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改医疗机构
 * request: jkMedicalInstitutionUpdate
 * formData: jkMedicalInstitutionUpdateFormData
 * url: jkMedicalInstitutionUpdate_URL
 * method: jkMedicalInstitutionUpdate_TYPE
 * raw_url: jkMedicalInstitutionUpdate_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 * @param name - 医疗机构名称
 * @param managerId - 管理员ID
 * @param intro - 简介
 * @param address - 地址
 * @param phone - 电话号码
 * @param appointmentGuide - 
 * @param appointmentUrl - 
 * @param picture - 
 */
export const jkMedicalInstitutionUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['address'] !== undefined) {
    form['address'] = parameters['address']
  }
  if (parameters['address'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: address'))
  }
  if (parameters['phone'] !== undefined) {
    form['phone'] = parameters['phone']
  }
  if (parameters['phone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: phone'))
  }
  if (parameters['appointmentGuide'] !== undefined) {
    form['appointmentGuide'] = parameters['appointmentGuide']
  }
  if (parameters['appointmentUrl'] !== undefined) {
    form['appointmentUrl'] = parameters['appointmentUrl']
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionUpdateFormData = function(formData) {
  const url = jkMedicalInstitutionUpdate_RAW_URL()
  const method = jkMedicalInstitutionUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionUpdate_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}'
}
export const jkMedicalInstitutionUpdate_TYPE = function() {
  return 'put'
}
export const jkMedicalInstitutionUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用医疗机构
 * request: jkMedicalInstitutionDisable
 * formData: jkMedicalInstitutionDisableFormData
 * url: jkMedicalInstitutionDisable_URL
 * method: jkMedicalInstitutionDisable_TYPE
 * raw_url: jkMedicalInstitutionDisable_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 */
export const jkMedicalInstitutionDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionDisableFormData = function(formData) {
  const url = jkMedicalInstitutionDisable_RAW_URL()
  const method = jkMedicalInstitutionDisable_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionDisable_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}/disable'
}
export const jkMedicalInstitutionDisable_TYPE = function() {
  return 'put'
}
export const jkMedicalInstitutionDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/disable'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用医疗机构档案管理
 * request: jkMedicalInstitutionDisableArchiveManage
 * formData: jkMedicalInstitutionDisableArchiveManageFormData
 * url: jkMedicalInstitutionDisableArchiveManage_URL
 * method: jkMedicalInstitutionDisableArchiveManage_TYPE
 * raw_url: jkMedicalInstitutionDisableArchiveManage_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 */
export const jkMedicalInstitutionDisableArchiveManage = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/disable-archive-manage'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionDisableArchiveManageFormData = function(formData) {
  const url = jkMedicalInstitutionDisableArchiveManage_RAW_URL()
  const method = jkMedicalInstitutionDisableArchiveManage_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionDisableArchiveManage_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}/disable-archive-manage'
}
export const jkMedicalInstitutionDisableArchiveManage_TYPE = function() {
  return 'put'
}
export const jkMedicalInstitutionDisableArchiveManage_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/disable-archive-manage'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用医疗机构
 * request: jkMedicalInstitutionEnable
 * formData: jkMedicalInstitutionEnableFormData
 * url: jkMedicalInstitutionEnable_URL
 * method: jkMedicalInstitutionEnable_TYPE
 * raw_url: jkMedicalInstitutionEnable_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 */
export const jkMedicalInstitutionEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionEnableFormData = function(formData) {
  const url = jkMedicalInstitutionEnable_RAW_URL()
  const method = jkMedicalInstitutionEnable_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionEnable_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}/enable'
}
export const jkMedicalInstitutionEnable_TYPE = function() {
  return 'put'
}
export const jkMedicalInstitutionEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/enable'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用医疗机构档案管理
 * request: jkMedicalInstitutionEnableArchiveManage
 * formData: jkMedicalInstitutionEnableArchiveManageFormData
 * url: jkMedicalInstitutionEnableArchiveManage_URL
 * method: jkMedicalInstitutionEnableArchiveManage_TYPE
 * raw_url: jkMedicalInstitutionEnableArchiveManage_RAW_URL
 * @param medicalInstitutionId - medicalInstitutionId
 */
export const jkMedicalInstitutionEnableArchiveManage = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/enable-archive-manage'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters['medicalInstitutionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: medicalInstitutionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMedicalInstitutionEnableArchiveManageFormData = function(formData) {
  const url = jkMedicalInstitutionEnableArchiveManage_RAW_URL()
  const method = jkMedicalInstitutionEnableArchiveManage_TYPE()
  return axios[method](url, formData)
}
export const jkMedicalInstitutionEnableArchiveManage_RAW_URL = function() {
  return '/jkzx/medical-institution/{medicalInstitutionId}/enable-archive-manage'
}
export const jkMedicalInstitutionEnableArchiveManage_TYPE = function() {
  return 'put'
}
export const jkMedicalInstitutionEnableArchiveManage_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/medical-institution/{medicalInstitutionId}/enable-archive-manage'
  path = path.replace('{medicalInstitutionId}', `${parameters['medicalInstitutionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 防控指标列表
 * request: jkMpciList
 * formData: jkMpciListFormData
 * url: jkMpciList_URL
 * method: jkMpciList_TYPE
 * raw_url: jkMpciList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - regionCode
 */
export const jkMpciList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMpciListFormData = function(formData) {
  const url = jkMpciList_RAW_URL()
  const method = jkMpciList_TYPE()
  return axios[method](url, formData)
}
export const jkMpciList_RAW_URL = function() {
  return '/jkzx/mpci'
}
export const jkMpciList_TYPE = function() {
  return 'get'
}
export const jkMpciList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增防控指标
 * request: jkMpciSave
 * formData: jkMpciSaveFormData
 * url: jkMpciSave_URL
 * method: jkMpciSave_TYPE
 * raw_url: jkMpciSave_RAW_URL
 * @param dataVo - dataVO
 */
export const jkMpciSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['dataVo'] !== undefined) {
    body = parameters['dataVo']
  }
  if (parameters['dataVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkMpciSaveFormData = function(formData) {
  const url = jkMpciSave_RAW_URL()
  const method = jkMpciSave_TYPE()
  return axios[method](url, formData)
}
export const jkMpciSave_RAW_URL = function() {
  return '/jkzx/mpci'
}
export const jkMpciSave_TYPE = function() {
  return 'post'
}
export const jkMpciSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改防控指标
 * request: jkMpciUpdate
 * formData: jkMpciUpdateFormData
 * url: jkMpciUpdate_URL
 * method: jkMpciUpdate_TYPE
 * raw_url: jkMpciUpdate_RAW_URL
 * @param id - id
 * @param myopiaRate - 近视患病率指标
 * @param newMyopiaRate - 近视发病率指标
 */
export const jkMpciUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['myopiaRate'] !== undefined) {
    form['myopiaRate'] = parameters['myopiaRate']
  }
  if (parameters['myopiaRate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: myopiaRate'))
  }
  if (parameters['newMyopiaRate'] !== undefined) {
    form['newMyopiaRate'] = parameters['newMyopiaRate']
  }
  if (parameters['newMyopiaRate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: newMyopiaRate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkMpciUpdateFormData = function(formData) {
  const url = jkMpciUpdate_RAW_URL()
  const method = jkMpciUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkMpciUpdate_RAW_URL = function() {
  return '/jkzx/mpci/{id}'
}
export const jkMpciUpdate_TYPE = function() {
  return 'put'
}
export const jkMpciUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除防控指标
 * request: jkMpciDelete
 * formData: jkMpciDeleteFormData
 * url: jkMpciDelete_URL
 * method: jkMpciDelete_TYPE
 * raw_url: jkMpciDelete_RAW_URL
 * @param id - id
 */
export const jkMpciDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkMpciDeleteFormData = function(formData) {
  const url = jkMpciDelete_RAW_URL()
  const method = jkMpciDelete_TYPE()
  return axios[method](url, formData)
}
export const jkMpciDelete_RAW_URL = function() {
  return '/jkzx/mpci/{id}'
}
export const jkMpciDelete_TYPE = function() {
  return 'delete'
}
export const jkMpciDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 本市区防控指标数据
 * request: jkMpciOwn
 * formData: jkMpciOwnFormData
 * url: jkMpciOwn_URL
 * method: jkMpciOwn_TYPE
 * raw_url: jkMpciOwn_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 编码
 */
export const jkMpciOwn = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci/{screeningPlanId}/own'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    form['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMpciOwnFormData = function(formData) {
  const url = jkMpciOwn_RAW_URL()
  const method = jkMpciOwn_TYPE()
  return axios[method](url, formData)
}
export const jkMpciOwn_RAW_URL = function() {
  return '/jkzx/mpci/{screeningPlanId}/own'
}
export const jkMpciOwn_TYPE = function() {
  return 'get'
}
export const jkMpciOwn_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci/{screeningPlanId}/own'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增防控指标地区列表
 * request: jkMpciRegionList
 * formData: jkMpciRegionListFormData
 * url: jkMpciRegionList_URL
 * method: jkMpciRegionList_TYPE
 * raw_url: jkMpciRegionList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param pid - pid
 */
export const jkMpciRegionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/mpci/{screeningPlanId}/regionList'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['pid'] !== undefined) {
    queryParameters['pid'] = parameters['pid']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMpciRegionListFormData = function(formData) {
  const url = jkMpciRegionList_RAW_URL()
  const method = jkMpciRegionList_TYPE()
  return axios[method](url, formData)
}
export const jkMpciRegionList_RAW_URL = function() {
  return '/jkzx/mpci/{screeningPlanId}/regionList'
}
export const jkMpciRegionList_TYPE = function() {
  return 'get'
}
export const jkMpciRegionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/mpci/{screeningPlanId}/regionList'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['pid'] !== undefined) {
    queryParameters['pid'] = parameters['pid']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存管理部门学校卫生工作调查表
 * request: jkMsswqSave
 * formData: jkMsswqSaveFormData
 * url: jkMsswqSave_URL
 * method: jkMsswqSave_TYPE
 * raw_url: jkMsswqSave_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param preparer - 
 * @param preparerPhone - 
 * @param date - 
 * @param aa01 - 
 * @param aa01Description - 当aa01选中选项1时必填，反之不填
 * @param aa021 - 
 * @param aa022 - 
 * @param aa023 - 
 * @param aa031 - 
 * @param aa032 - 
 * @param aa033 - 
 * @param ab01 - 
 * @param ab01Description - (疾控中心)学校卫生科室的设置描述
 * @param ab021 - 
 * @param ab022 - 
 * @param ab0221 - (疾控中心)专职人员中专及以下学历人数
 * @param ab0222 - (疾控中心)专职人员大专人数
 * @param ab0223 - (疾控中心)专职人员本科人数
 * @param ab0224 - (疾控中心)专职人员研究生人数
 * @param ab0225 - (疾控中心)专职人员初级职称人数
 * @param ab0226 - (疾控中心)专职人员中级职称人数
 * @param ab0227 - (疾控中心)专职人员副高级职称人数
 * @param ab0228 - (疾控中心)专职人员高级职称人数
 * @param ab0229 - (疾控中心)专职人员无职称人数
 * @param ab023 - 
 * @param ab0231 - (疾控中心)兼职人员中专及以下学历人数
 * @param ab0232 - (疾控中心)兼职人员大专人数
 * @param ab0233 - (疾控中心)兼职人员本科人数
 * @param ab0234 - (疾控中心)兼职人员研究生人数
 * @param ab0235 - (疾控中心)兼职人员初级职称人数
 * @param ab0236 - (疾控中心)兼职人员中级职称人数
 * @param ab0237 - (疾控中心)兼职人员副高级职称人数
 * @param ab0238 - (疾控中心)兼职人员高级职称人数
 * @param ab0239 - (疾控中心)兼职人员无职称人数
 * @param ab024 - (疾控中心)上一季度总业务专项经费
 * @param ab0241 - (疾控中心)上一季度学校卫生业务专项经费
 * @param ab0242 - (疾控中心)上一季度近视防控专项经费
 * @param ac01 - 
 * @param ac01Description - 当ac01选中选项1时必填，反之不填
 * @param ac021 - 
 * @param ac022 - 
 * @param ac023 - 
 * @param ac031 - 
 * @param ac032 - 
 * @param ac033 - 
 * @param ad011 - 
 * @param ad012 - 
 * @param ad013 - 
 * @param ad014 - 
 * @param ad015 - 
 * @param ad016 - (区(县)学校)辖区内配备足量专职卫生技术人员的中小学数
 * @param ad021 - 
 * @param ad022 - 
 * @param ad023 - 
 * @param ad024 - 
 * @param ad025 - 
 * @param ad031 - 
 * @param ad032 - 
 * @param ad033 - 
 * @param b01 - 
 * @param b010 - 
 * @param b010OtherDescription - 
 * @param b02 - 
 * @param b020 - 
 * @param b0201A - 
 * @param b0202A - 
 * @param b0202A1 - 
 * @param b0202A2 - 
 * @param b0202A3 - 
 * @param b0202A4 - 
 * @param b0203A - 
 * @param b0204A - 
 * @param b0205A - 
 * @param b020OtherDescription - 
 * @param b0206A - 
 * @param b03 - 
 * @param b030 - 
 * @param b0301A - 
 * @param b0302A - 
 * @param b0302A1 - 
 * @param b0302A2 - 
 * @param b0302A3 - 
 * @param b0302A4 - 
 * @param b0303A - 
 * @param b0304A - 
 * @param b0305A - 
 * @param b030OtherDescription - 
 * @param b0306A - 
 * @param c01 - 
 * @param c02 - 开展检测的机构是?
 * @param c03 - 上一年度学生常见病监测工作中，参加监测工作人员接受学生常见病监测技术培训情况
 * @param c031 - 部分人员接受培训的人数
 * @param c032 - 部分人员接受培训的人数占监测工作人员的比例
 * @param c033 - 全部__人接受培训
 * @param c04 - 上一年度组织学生常见病监测人员培训的单位及次数(可多选)
 * @param c0421 - 国家级疾控部门人次
 * @param c0431 - 省级疾控部门人次
 * @param c0441 - 地市级疾控部门人次
 * @param c0451 - 区县级疾控部门人次
 * @param c05 - 上一年度组织学生常见病监测人员培训的形式(可多选)
 * @param d011 - 
 * @param d012 - 
 * @param d013 - 
 * @param d014 - 
 * @param d015 - 
 * @param d016 - 
 * @param d017 - 
 * @param d018 - 
 * @param d02111 - 
 * @param d02112 - 
 * @param d02113 - 
 * @param d02114 - 
 * @param d02115 - 
 * @param d02121 - 
 * @param d02122 - 
 * @param d02123 - 
 * @param d02124 - 
 * @param d02125 - 
 * @param d02211 - 
 * @param d02212 - 
 * @param d02213 - 
 * @param d02214 - 
 * @param d02215 - 
 * @param d02221 - 
 * @param d02222 - 
 * @param d02223 - 
 * @param d02224 - 
 * @param d02225 - 
 * @param d031 - 
 * @param d032 - 
 * @param d033 - 
 * @param e01 - 是否建立了学校卫生电子信息化平台
 * @param e011 - 卫生电子信息化平台是否是独立运行的平台
 * @param e012 - 卫生电子信息化平台是隶属于哪一级的平台
 * @param e02 - 卫生电子信息化平台建立于哪一年
 * @param e021 - 卫生电子信息化平台开始运行于哪一年
 * @param e03 - 卫生电子信息化平台建设投入资金(万)
 * @param e04 - 卫生电子信息化平台业务内容
 * @param e04Description - 卫生电子信息化平台业务内容其它说明
 * @param e05 - 卫生电子信息化平台是否具有以下功能
 * @param e05Description - 卫生电子信息化平台其它功能说明
 * @param e051 - 数据采集方式
 * @param e051Description - 数据采集方式其它说明
 * @param e052 - 数据清洗方式
 * @param e052Description - 数据清洗方式其它说明
 * @param e053 - 信息查询执行者
 * @param e053Description - 信息查询执行者其它说明
 * @param e054 - 信息平台当前的功能是否能够满足学生常见病监测的需求
 * @param e06 - 卫生电子信息化平台信息存储单位
 * @param e06Description - 卫生电子信息化平台信息存储单位其它说明
 * @param e06Description2 - 卫生电子信息化平台信息存储单位其它说明2
 * @param e07 - 卫生电子信息化平台安全等级保护备案级别
 * @param e07Description - 卫生电子信息化平台安全等级保护备案级别其它说明
 * @param g01 - 市及区县级疾控部门与教育部门是否联合下发干预实施方案?
 * @param g02 - 市及区县级疾控部门是否对辖区内干预人员进行培训并考核?
 * @param g03 - 疾控部门组织指导辖区内学校开展了哪些干预活动?(可多选)
 * @param g041 - 市及区县级疾控部门在过去一年组织指导辖区内多少所学校
 * @param g042 - 辖区内学校开展了多少次干预活动
 * @param g05 - 市及区县级疾控部门是否对辖区内学校开展的干预活动进行了评估?
 */
export const jkMsswqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/msswq/save/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['preparer'] !== undefined) {
    form['preparer'] = parameters['preparer']
  }
  if (parameters['preparer'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: preparer'))
  }
  if (parameters['preparerPhone'] !== undefined) {
    form['preparerPhone'] = parameters['preparerPhone']
  }
  if (parameters['preparerPhone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: preparerPhone'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['aa01'] !== undefined) {
    form['aa01'] = parameters['aa01']
  }
  if (parameters['aa01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa01'))
  }
  if (parameters['aa01Description'] !== undefined) {
    form['aa01Description'] = parameters['aa01Description']
  }
  if (parameters['aa021'] !== undefined) {
    form['aa021'] = parameters['aa021']
  }
  if (parameters['aa021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa021'))
  }
  if (parameters['aa022'] !== undefined) {
    form['aa022'] = parameters['aa022']
  }
  if (parameters['aa022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa022'))
  }
  if (parameters['aa023'] !== undefined) {
    form['aa023'] = parameters['aa023']
  }
  if (parameters['aa023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa023'))
  }
  if (parameters['aa031'] !== undefined) {
    form['aa031'] = parameters['aa031']
  }
  if (parameters['aa031'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa031'))
  }
  if (parameters['aa032'] !== undefined) {
    form['aa032'] = parameters['aa032']
  }
  if (parameters['aa032'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa032'))
  }
  if (parameters['aa033'] !== undefined) {
    form['aa033'] = parameters['aa033']
  }
  if (parameters['aa033'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: aa033'))
  }
  if (parameters['ab01'] !== undefined) {
    form['ab01'] = parameters['ab01']
  }
  if (parameters['ab01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab01'))
  }
  if (parameters['ab01Description'] !== undefined) {
    form['ab01Description'] = parameters['ab01Description']
  }
  if (parameters['ab021'] !== undefined) {
    form['ab021'] = parameters['ab021']
  }
  if (parameters['ab021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab021'))
  }
  if (parameters['ab022'] !== undefined) {
    form['ab022'] = parameters['ab022']
  }
  if (parameters['ab022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab022'))
  }
  if (parameters['ab0221'] !== undefined) {
    form['ab0221'] = parameters['ab0221']
  }
  if (parameters['ab0221'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0221'))
  }
  if (parameters['ab0222'] !== undefined) {
    form['ab0222'] = parameters['ab0222']
  }
  if (parameters['ab0222'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0222'))
  }
  if (parameters['ab0223'] !== undefined) {
    form['ab0223'] = parameters['ab0223']
  }
  if (parameters['ab0223'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0223'))
  }
  if (parameters['ab0224'] !== undefined) {
    form['ab0224'] = parameters['ab0224']
  }
  if (parameters['ab0224'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0224'))
  }
  if (parameters['ab0225'] !== undefined) {
    form['ab0225'] = parameters['ab0225']
  }
  if (parameters['ab0225'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0225'))
  }
  if (parameters['ab0226'] !== undefined) {
    form['ab0226'] = parameters['ab0226']
  }
  if (parameters['ab0226'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0226'))
  }
  if (parameters['ab0227'] !== undefined) {
    form['ab0227'] = parameters['ab0227']
  }
  if (parameters['ab0227'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0227'))
  }
  if (parameters['ab0228'] !== undefined) {
    form['ab0228'] = parameters['ab0228']
  }
  if (parameters['ab0228'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0228'))
  }
  if (parameters['ab0229'] !== undefined) {
    form['ab0229'] = parameters['ab0229']
  }
  if (parameters['ab0229'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0229'))
  }
  if (parameters['ab023'] !== undefined) {
    form['ab023'] = parameters['ab023']
  }
  if (parameters['ab023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab023'))
  }
  if (parameters['ab0231'] !== undefined) {
    form['ab0231'] = parameters['ab0231']
  }
  if (parameters['ab0231'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0231'))
  }
  if (parameters['ab0232'] !== undefined) {
    form['ab0232'] = parameters['ab0232']
  }
  if (parameters['ab0232'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0232'))
  }
  if (parameters['ab0233'] !== undefined) {
    form['ab0233'] = parameters['ab0233']
  }
  if (parameters['ab0233'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0233'))
  }
  if (parameters['ab0234'] !== undefined) {
    form['ab0234'] = parameters['ab0234']
  }
  if (parameters['ab0234'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0234'))
  }
  if (parameters['ab0235'] !== undefined) {
    form['ab0235'] = parameters['ab0235']
  }
  if (parameters['ab0235'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0235'))
  }
  if (parameters['ab0236'] !== undefined) {
    form['ab0236'] = parameters['ab0236']
  }
  if (parameters['ab0236'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0236'))
  }
  if (parameters['ab0237'] !== undefined) {
    form['ab0237'] = parameters['ab0237']
  }
  if (parameters['ab0237'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0237'))
  }
  if (parameters['ab0238'] !== undefined) {
    form['ab0238'] = parameters['ab0238']
  }
  if (parameters['ab0238'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0238'))
  }
  if (parameters['ab0239'] !== undefined) {
    form['ab0239'] = parameters['ab0239']
  }
  if (parameters['ab0239'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0239'))
  }
  if (parameters['ab024'] !== undefined) {
    form['ab024'] = parameters['ab024']
  }
  if (parameters['ab024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab024'))
  }
  if (parameters['ab0241'] !== undefined) {
    form['ab0241'] = parameters['ab0241']
  }
  if (parameters['ab0241'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0241'))
  }
  if (parameters['ab0242'] !== undefined) {
    form['ab0242'] = parameters['ab0242']
  }
  if (parameters['ab0242'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ab0242'))
  }
  if (parameters['ac01'] !== undefined) {
    form['ac01'] = parameters['ac01']
  }
  if (parameters['ac01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac01'))
  }
  if (parameters['ac01Description'] !== undefined) {
    form['ac01Description'] = parameters['ac01Description']
  }
  if (parameters['ac021'] !== undefined) {
    form['ac021'] = parameters['ac021']
  }
  if (parameters['ac021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac021'))
  }
  if (parameters['ac022'] !== undefined) {
    form['ac022'] = parameters['ac022']
  }
  if (parameters['ac022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac022'))
  }
  if (parameters['ac023'] !== undefined) {
    form['ac023'] = parameters['ac023']
  }
  if (parameters['ac023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac023'))
  }
  if (parameters['ac031'] !== undefined) {
    form['ac031'] = parameters['ac031']
  }
  if (parameters['ac031'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac031'))
  }
  if (parameters['ac032'] !== undefined) {
    form['ac032'] = parameters['ac032']
  }
  if (parameters['ac032'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac032'))
  }
  if (parameters['ac033'] !== undefined) {
    form['ac033'] = parameters['ac033']
  }
  if (parameters['ac033'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: ac033'))
  }
  if (parameters['ad011'] !== undefined) {
    form['ad011'] = parameters['ad011']
  }
  if (parameters['ad012'] !== undefined) {
    form['ad012'] = parameters['ad012']
  }
  if (parameters['ad013'] !== undefined) {
    form['ad013'] = parameters['ad013']
  }
  if (parameters['ad014'] !== undefined) {
    form['ad014'] = parameters['ad014']
  }
  if (parameters['ad015'] !== undefined) {
    form['ad015'] = parameters['ad015']
  }
  if (parameters['ad016'] !== undefined) {
    form['ad016'] = parameters['ad016']
  }
  if (parameters['ad021'] !== undefined) {
    form['ad021'] = parameters['ad021']
  }
  if (parameters['ad022'] !== undefined) {
    form['ad022'] = parameters['ad022']
  }
  if (parameters['ad023'] !== undefined) {
    form['ad023'] = parameters['ad023']
  }
  if (parameters['ad024'] !== undefined) {
    form['ad024'] = parameters['ad024']
  }
  if (parameters['ad025'] !== undefined) {
    form['ad025'] = parameters['ad025']
  }
  if (parameters['ad031'] !== undefined) {
    form['ad031'] = parameters['ad031']
  }
  if (parameters['ad032'] !== undefined) {
    form['ad032'] = parameters['ad032']
  }
  if (parameters['ad033'] !== undefined) {
    form['ad033'] = parameters['ad033']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b010'] !== undefined) {
    form['b010'] = parameters['b010']
  }
  if (parameters['b010OtherDescription'] !== undefined) {
    form['b010OtherDescription'] = parameters['b010OtherDescription']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b020'] !== undefined) {
    form['b020'] = parameters['b020']
  }
  if (parameters['b0201A'] !== undefined) {
    form['b0201a'] = parameters['b0201A']
  }
  if (parameters['b0202A'] !== undefined) {
    form['b0202a'] = parameters['b0202A']
  }
  if (parameters['b0202A1'] !== undefined) {
    form['b0202a1'] = parameters['b0202A1']
  }
  if (parameters['b0202A2'] !== undefined) {
    form['b0202a2'] = parameters['b0202A2']
  }
  if (parameters['b0202A3'] !== undefined) {
    form['b0202a3'] = parameters['b0202A3']
  }
  if (parameters['b0202A4'] !== undefined) {
    form['b0202a4'] = parameters['b0202A4']
  }
  if (parameters['b0203A'] !== undefined) {
    form['b0203a'] = parameters['b0203A']
  }
  if (parameters['b0204A'] !== undefined) {
    form['b0204a'] = parameters['b0204A']
  }
  if (parameters['b0205A'] !== undefined) {
    form['b0205a'] = parameters['b0205A']
  }
  if (parameters['b020OtherDescription'] !== undefined) {
    form['b020OtherDescription'] = parameters['b020OtherDescription']
  }
  if (parameters['b0206A'] !== undefined) {
    form['b0206a'] = parameters['b0206A']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b030'] !== undefined) {
    form['b030'] = parameters['b030']
  }
  if (parameters['b0301A'] !== undefined) {
    form['b0301a'] = parameters['b0301A']
  }
  if (parameters['b0302A'] !== undefined) {
    form['b0302a'] = parameters['b0302A']
  }
  if (parameters['b0302A1'] !== undefined) {
    form['b0302a1'] = parameters['b0302A1']
  }
  if (parameters['b0302A2'] !== undefined) {
    form['b0302a2'] = parameters['b0302A2']
  }
  if (parameters['b0302A3'] !== undefined) {
    form['b0302a3'] = parameters['b0302A3']
  }
  if (parameters['b0302A4'] !== undefined) {
    form['b0302a4'] = parameters['b0302A4']
  }
  if (parameters['b0303A'] !== undefined) {
    form['b0303a'] = parameters['b0303A']
  }
  if (parameters['b0304A'] !== undefined) {
    form['b0304a'] = parameters['b0304A']
  }
  if (parameters['b0305A'] !== undefined) {
    form['b0305a'] = parameters['b0305A']
  }
  if (parameters['b030OtherDescription'] !== undefined) {
    form['b030OtherDescription'] = parameters['b030OtherDescription']
  }
  if (parameters['b0306A'] !== undefined) {
    form['b0306a'] = parameters['b0306A']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c031'] !== undefined) {
    form['c031'] = parameters['c031']
  }
  if (parameters['c032'] !== undefined) {
    form['c032'] = parameters['c032']
  }
  if (parameters['c033'] !== undefined) {
    form['c033'] = parameters['c033']
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c0421'] !== undefined) {
    form['c0421'] = parameters['c0421']
  }
  if (parameters['c0431'] !== undefined) {
    form['c0431'] = parameters['c0431']
  }
  if (parameters['c0441'] !== undefined) {
    form['c0441'] = parameters['c0441']
  }
  if (parameters['c0451'] !== undefined) {
    form['c0451'] = parameters['c0451']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['d011'] !== undefined) {
    form['d011'] = parameters['d011']
  }
  if (parameters['d011'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d011'))
  }
  if (parameters['d012'] !== undefined) {
    form['d012'] = parameters['d012']
  }
  if (parameters['d012'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d012'))
  }
  if (parameters['d013'] !== undefined) {
    form['d013'] = parameters['d013']
  }
  if (parameters['d013'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d013'))
  }
  if (parameters['d014'] !== undefined) {
    form['d014'] = parameters['d014']
  }
  if (parameters['d014'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d014'))
  }
  if (parameters['d015'] !== undefined) {
    form['d015'] = parameters['d015']
  }
  if (parameters['d015'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d015'))
  }
  if (parameters['d016'] !== undefined) {
    form['d016'] = parameters['d016']
  }
  if (parameters['d016'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d016'))
  }
  if (parameters['d017'] !== undefined) {
    form['d017'] = parameters['d017']
  }
  if (parameters['d017'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d017'))
  }
  if (parameters['d018'] !== undefined) {
    form['d018'] = parameters['d018']
  }
  if (parameters['d018'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d018'))
  }
  if (parameters['d02111'] !== undefined) {
    form['d02111'] = parameters['d02111']
  }
  if (parameters['d02111'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02111'))
  }
  if (parameters['d02112'] !== undefined) {
    form['d02112'] = parameters['d02112']
  }
  if (parameters['d02112'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02112'))
  }
  if (parameters['d02113'] !== undefined) {
    form['d02113'] = parameters['d02113']
  }
  if (parameters['d02113'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02113'))
  }
  if (parameters['d02114'] !== undefined) {
    form['d02114'] = parameters['d02114']
  }
  if (parameters['d02114'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02114'))
  }
  if (parameters['d02115'] !== undefined) {
    form['d02115'] = parameters['d02115']
  }
  if (parameters['d02115'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02115'))
  }
  if (parameters['d02121'] !== undefined) {
    form['d02121'] = parameters['d02121']
  }
  if (parameters['d02121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02121'))
  }
  if (parameters['d02122'] !== undefined) {
    form['d02122'] = parameters['d02122']
  }
  if (parameters['d02122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02122'))
  }
  if (parameters['d02123'] !== undefined) {
    form['d02123'] = parameters['d02123']
  }
  if (parameters['d02123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02123'))
  }
  if (parameters['d02124'] !== undefined) {
    form['d02124'] = parameters['d02124']
  }
  if (parameters['d02124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02124'))
  }
  if (parameters['d02125'] !== undefined) {
    form['d02125'] = parameters['d02125']
  }
  if (parameters['d02125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02125'))
  }
  if (parameters['d02211'] !== undefined) {
    form['d02211'] = parameters['d02211']
  }
  if (parameters['d02211'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02211'))
  }
  if (parameters['d02212'] !== undefined) {
    form['d02212'] = parameters['d02212']
  }
  if (parameters['d02212'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02212'))
  }
  if (parameters['d02213'] !== undefined) {
    form['d02213'] = parameters['d02213']
  }
  if (parameters['d02213'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02213'))
  }
  if (parameters['d02214'] !== undefined) {
    form['d02214'] = parameters['d02214']
  }
  if (parameters['d02214'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02214'))
  }
  if (parameters['d02215'] !== undefined) {
    form['d02215'] = parameters['d02215']
  }
  if (parameters['d02215'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02215'))
  }
  if (parameters['d02221'] !== undefined) {
    form['d02221'] = parameters['d02221']
  }
  if (parameters['d02221'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02221'))
  }
  if (parameters['d02222'] !== undefined) {
    form['d02222'] = parameters['d02222']
  }
  if (parameters['d02222'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02222'))
  }
  if (parameters['d02223'] !== undefined) {
    form['d02223'] = parameters['d02223']
  }
  if (parameters['d02223'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02223'))
  }
  if (parameters['d02224'] !== undefined) {
    form['d02224'] = parameters['d02224']
  }
  if (parameters['d02224'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02224'))
  }
  if (parameters['d02225'] !== undefined) {
    form['d02225'] = parameters['d02225']
  }
  if (parameters['d02225'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02225'))
  }
  if (parameters['d031'] !== undefined) {
    form['d031'] = parameters['d031']
  }
  if (parameters['d031'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d031'))
  }
  if (parameters['d032'] !== undefined) {
    form['d032'] = parameters['d032']
  }
  if (parameters['d032'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d032'))
  }
  if (parameters['d033'] !== undefined) {
    form['d033'] = parameters['d033']
  }
  if (parameters['d033'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d033'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e012'] !== undefined) {
    form['e012'] = parameters['e012']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04Description'] !== undefined) {
    form['e04Description'] = parameters['e04Description']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['e05Description'] !== undefined) {
    form['e05Description'] = parameters['e05Description']
  }
  if (parameters['e051'] !== undefined) {
    form['e051'] = parameters['e051']
  }
  if (parameters['e051Description'] !== undefined) {
    form['e051Description'] = parameters['e051Description']
  }
  if (parameters['e052'] !== undefined) {
    form['e052'] = parameters['e052']
  }
  if (parameters['e052Description'] !== undefined) {
    form['e052Description'] = parameters['e052Description']
  }
  if (parameters['e053'] !== undefined) {
    form['e053'] = parameters['e053']
  }
  if (parameters['e053Description'] !== undefined) {
    form['e053Description'] = parameters['e053Description']
  }
  if (parameters['e054'] !== undefined) {
    form['e054'] = parameters['e054']
  }
  if (parameters['e06'] !== undefined) {
    form['e06'] = parameters['e06']
  }
  if (parameters['e06Description'] !== undefined) {
    form['e06Description'] = parameters['e06Description']
  }
  if (parameters['e06Description2'] !== undefined) {
    form['e06Description2'] = parameters['e06Description2']
  }
  if (parameters['e07'] !== undefined) {
    form['e07'] = parameters['e07']
  }
  if (parameters['e07Description'] !== undefined) {
    form['e07Description'] = parameters['e07Description']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['g041'] !== undefined) {
    form['g041'] = parameters['g041']
  }
  if (parameters['g041'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g041'))
  }
  if (parameters['g042'] !== undefined) {
    form['g042'] = parameters['g042']
  }
  if (parameters['g042'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g042'))
  }
  if (parameters['g05'] !== undefined) {
    form['g05'] = parameters['g05']
  }
  if (parameters['g05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g05'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkMsswqSaveFormData = function(formData) {
  const url = jkMsswqSave_RAW_URL()
  const method = jkMsswqSave_TYPE()
  return axios[method](url, formData)
}
export const jkMsswqSave_RAW_URL = function() {
  return '/jkzx/msswq/save/{screeningRegionId}'
}
export const jkMsswqSave_TYPE = function() {
  return 'post'
}
export const jkMsswqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/msswq/save/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理部门学校卫生工作调查表详情
 * request: jkMsswqFind
 * formData: jkMsswqFindFormData
 * url: jkMsswqFind_URL
 * method: jkMsswqFind_TYPE
 * raw_url: jkMsswqFind_RAW_URL
 * @param id - id
 */
export const jkMsswqFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/msswq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMsswqFindFormData = function(formData) {
  const url = jkMsswqFind_RAW_URL()
  const method = jkMsswqFind_TYPE()
  return axios[method](url, formData)
}
export const jkMsswqFind_RAW_URL = function() {
  return '/jkzx/msswq/{id}'
}
export const jkMsswqFind_TYPE = function() {
  return 'get'
}
export const jkMsswqFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/msswq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区管理部门学校卫生工作调查表详情
 * request: jkMsswqFindByScreeningRegion
 * formData: jkMsswqFindByScreeningRegionFormData
 * url: jkMsswqFindByScreeningRegion_URL
 * method: jkMsswqFindByScreeningRegion_TYPE
 * raw_url: jkMsswqFindByScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkMsswqFindByScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/msswq/{screeningPlanId}/by-screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkMsswqFindByScreeningRegionFormData = function(formData) {
  const url = jkMsswqFindByScreeningRegion_RAW_URL()
  const method = jkMsswqFindByScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkMsswqFindByScreeningRegion_RAW_URL = function() {
  return '/jkzx/msswq/{screeningPlanId}/by-screening-region'
}
export const jkMsswqFindByScreeningRegion_TYPE = function() {
  return 'get'
}
export const jkMsswqFindByScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/msswq/{screeningPlanId}/by-screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通知人员列表
 * request: jkNoticePersonnelList
 * formData: jkNoticePersonnelListFormData
 * url: jkNoticePersonnelList_URL
 * method: jkNoticePersonnelList_TYPE
 * raw_url: jkNoticePersonnelList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkNoticePersonnelList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/notice_personnel'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkNoticePersonnelListFormData = function(formData) {
  const url = jkNoticePersonnelList_RAW_URL()
  const method = jkNoticePersonnelList_TYPE()
  return axios[method](url, formData)
}
export const jkNoticePersonnelList_RAW_URL = function() {
  return '/jkzx/notice_personnel'
}
export const jkNoticePersonnelList_TYPE = function() {
  return 'get'
}
export const jkNoticePersonnelList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/notice_personnel'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加人员
 * request: jkNoticePersonnelAdd
 * formData: jkNoticePersonnelAddFormData
 * url: jkNoticePersonnelAdd_URL
 * method: jkNoticePersonnelAdd_TYPE
 * raw_url: jkNoticePersonnelAdd_RAW_URL
 * @param administratorIds - 管理员id集合
 * @param type - 通知类型
 */
export const jkNoticePersonnelAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/notice_personnel'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorIds'] !== undefined) {
    form['administratorIds'] = parameters['administratorIds']
  }
  if (parameters['administratorIds'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorIds'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkNoticePersonnelAddFormData = function(formData) {
  const url = jkNoticePersonnelAdd_RAW_URL()
  const method = jkNoticePersonnelAdd_TYPE()
  return axios[method](url, formData)
}
export const jkNoticePersonnelAdd_RAW_URL = function() {
  return '/jkzx/notice_personnel'
}
export const jkNoticePersonnelAdd_TYPE = function() {
  return 'post'
}
export const jkNoticePersonnelAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/notice_personnel'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改发送方式
 * request: jkSendUpdate
 * formData: jkSendUpdateFormData
 * url: jkSendUpdate_URL
 * method: jkSendUpdate_TYPE
 * raw_url: jkSendUpdate_RAW_URL
 * @param type - 通知类型
 * @param sendMode - 发送方式
 */
export const jkSendUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/notice_personnel'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['sendMode'] !== undefined) {
    form['sendMode'] = parameters['sendMode']
  }
  if (parameters['sendMode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sendMode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSendUpdateFormData = function(formData) {
  const url = jkSendUpdate_RAW_URL()
  const method = jkSendUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkSendUpdate_RAW_URL = function() {
  return '/jkzx/notice_personnel'
}
export const jkSendUpdate_TYPE = function() {
  return 'put'
}
export const jkSendUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/notice_personnel'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除
 * request: jkNoticePersonnelDelete
 * formData: jkNoticePersonnelDeleteFormData
 * url: jkNoticePersonnelDelete_URL
 * method: jkNoticePersonnelDelete_TYPE
 * raw_url: jkNoticePersonnelDelete_RAW_URL
 * @param id - id
 */
export const jkNoticePersonnelDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/notice_personnel/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkNoticePersonnelDeleteFormData = function(formData) {
  const url = jkNoticePersonnelDelete_RAW_URL()
  const method = jkNoticePersonnelDelete_TYPE()
  return axios[method](url, formData)
}
export const jkNoticePersonnelDelete_RAW_URL = function() {
  return '/jkzx/notice_personnel/{id}'
}
export const jkNoticePersonnelDelete_TYPE = function() {
  return 'delete'
}
export const jkNoticePersonnelDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/notice_personnel/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 市实时筛查监控(个区统计)
 * request: jkRealTimeScreeningMonitoringCityRegionMonitor
 * formData: jkRealTimeScreeningMonitoringCityRegionMonitorFormData
 * url: jkRealTimeScreeningMonitoringCityRegionMonitor_URL
 * method: jkRealTimeScreeningMonitoringCityRegionMonitor_TYPE
 * raw_url: jkRealTimeScreeningMonitoringCityRegionMonitor_RAW_URL
 * @param planId - planId
 * @param cityCode - 
 */
export const jkRealTimeScreeningMonitoringCityRegionMonitor = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/city-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringCityRegionMonitorFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringCityRegionMonitor_RAW_URL()
  const method = jkRealTimeScreeningMonitoringCityRegionMonitor_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringCityRegionMonitor_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/city-region'
}
export const jkRealTimeScreeningMonitoringCityRegionMonitor_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringCityRegionMonitor_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/city-region'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区实时筛查监控
 * request: jkRealTimeScreeningMonitoringDistrictRegionMonitor
 * formData: jkRealTimeScreeningMonitoringDistrictRegionMonitorFormData
 * url: jkRealTimeScreeningMonitoringDistrictRegionMonitor_URL
 * method: jkRealTimeScreeningMonitoringDistrictRegionMonitor_TYPE
 * raw_url: jkRealTimeScreeningMonitoringDistrictRegionMonitor_RAW_URL
 * @param planId - planId
 * @param districtCode - 
 */
export const jkRealTimeScreeningMonitoringDistrictRegionMonitor = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/district-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringDistrictRegionMonitorFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringDistrictRegionMonitor_RAW_URL()
  const method = jkRealTimeScreeningMonitoringDistrictRegionMonitor_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringDistrictRegionMonitor_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/district-region'
}
export const jkRealTimeScreeningMonitoringDistrictRegionMonitor_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringDistrictRegionMonitor_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/district-region'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划各项总统计
 * request: jkRealTimeScreeningMonitoringPlanStats
 * formData: jkRealTimeScreeningMonitoringPlanStatsFormData
 * url: jkRealTimeScreeningMonitoringPlanStats_URL
 * method: jkRealTimeScreeningMonitoringPlanStats_TYPE
 * raw_url: jkRealTimeScreeningMonitoringPlanStats_RAW_URL
 * @param planId - planId
 * @param screeningRegionId - 
 */
export const jkRealTimeScreeningMonitoringPlanStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/plan-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringPlanStatsFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringPlanStats_RAW_URL()
  const method = jkRealTimeScreeningMonitoringPlanStats_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringPlanStats_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/plan-stats'
}
export const jkRealTimeScreeningMonitoringPlanStats_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringPlanStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/plan-stats'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省实时筛查监控(个市统计)
 * request: jkRealTimeScreeningMonitoringProvinceRegionMonitor
 * formData: jkRealTimeScreeningMonitoringProvinceRegionMonitorFormData
 * url: jkRealTimeScreeningMonitoringProvinceRegionMonitor_URL
 * method: jkRealTimeScreeningMonitoringProvinceRegionMonitor_TYPE
 * raw_url: jkRealTimeScreeningMonitoringProvinceRegionMonitor_RAW_URL
 * @param planId - planId
 */
export const jkRealTimeScreeningMonitoringProvinceRegionMonitor = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/province-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringProvinceRegionMonitorFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringProvinceRegionMonitor_RAW_URL()
  const method = jkRealTimeScreeningMonitoringProvinceRegionMonitor_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringProvinceRegionMonitor_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/province-region'
}
export const jkRealTimeScreeningMonitoringProvinceRegionMonitor_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringProvinceRegionMonitor_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/province-region'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 实时筛查监控筛查学校列表
 * request: jkRealTimeScreeningMonitoringPageSchool
 * formData: jkRealTimeScreeningMonitoringPageSchoolFormData
 * url: jkRealTimeScreeningMonitoringPageSchool_URL
 * method: jkRealTimeScreeningMonitoringPageSchool_TYPE
 * raw_url: jkRealTimeScreeningMonitoringPageSchool_RAW_URL
 * @param planId - planId
 * @param screeningRegionId - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkRealTimeScreeningMonitoringPageSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringPageSchoolFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringPageSchool_RAW_URL()
  const method = jkRealTimeScreeningMonitoringPageSchool_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringPageSchool_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/school'
}
export const jkRealTimeScreeningMonitoringPageSchool_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringPageSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/school'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 实时筛查监控今日完成筛查学校列表
 * request: jkRealTimeScreeningMonitoringPageToDayFinishSchool
 * formData: jkRealTimeScreeningMonitoringPageToDayFinishSchoolFormData
 * url: jkRealTimeScreeningMonitoringPageToDayFinishSchool_URL
 * method: jkRealTimeScreeningMonitoringPageToDayFinishSchool_TYPE
 * raw_url: jkRealTimeScreeningMonitoringPageToDayFinishSchool_RAW_URL
 * @param planId - planId
 * @param screeningRegionId - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkRealTimeScreeningMonitoringPageToDayFinishSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/real-time-screening-monitoring/{planId}/to-day-finish-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRealTimeScreeningMonitoringPageToDayFinishSchoolFormData = function(formData) {
  const url = jkRealTimeScreeningMonitoringPageToDayFinishSchool_RAW_URL()
  const method = jkRealTimeScreeningMonitoringPageToDayFinishSchool_TYPE()
  return axios[method](url, formData)
}
export const jkRealTimeScreeningMonitoringPageToDayFinishSchool_RAW_URL = function() {
  return '/jkzx/real-time-screening-monitoring/{planId}/to-day-finish-school'
}
export const jkRealTimeScreeningMonitoringPageToDayFinishSchool_TYPE = function() {
  return 'get'
}
export const jkRealTimeScreeningMonitoringPageToDayFinishSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/real-time-screening-monitoring/{planId}/to-day-finish-school'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取REC文件模板类型
 * request: jkRecFileListType
 * formData: jkRecFileListTypeFormData
 * url: jkRecFileListType_URL
 * method: jkRecFileListType_TYPE
 * raw_url: jkRecFileListType_RAW_URL
 */
export const jkRecFileListType = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/rec-file/type'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRecFileListTypeFormData = function(formData) {
  const url = jkRecFileListType_RAW_URL()
  const method = jkRecFileListType_TYPE()
  return axios[method](url, formData)
}
export const jkRecFileListType_RAW_URL = function() {
  return '/jkzx/rec-file/type'
}
export const jkRecFileListType_TYPE = function() {
  return 'get'
}
export const jkRecFileListType_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/rec-file/type'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出计划/地区REC文件
 * request: jkRecFileExport
 * formData: jkRecFileExportFormData
 * url: jkRecFileExport_URL
 * method: jkRecFileExport_TYPE
 * raw_url: jkRecFileExport_RAW_URL
 * @param planId - planId
 * @param accessToken - 
 * @param regionId - 筛查地区ID
 * @param type - REC文件模板类型
 * @param condition - REC导出条件
 */
export const jkRecFileExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/rec-file/{planId}/export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['regionId'] !== undefined) {
    queryParameters['regionId'] = parameters['regionId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRecFileExportFormData = function(formData) {
  const url = jkRecFileExport_RAW_URL()
  const method = jkRecFileExport_TYPE()
  return axios[method](url, formData)
}
export const jkRecFileExport_RAW_URL = function() {
  return '/jkzx/rec-file/{planId}/export'
}
export const jkRecFileExport_TYPE = function() {
  return 'get'
}
export const jkRecFileExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/rec-file/{planId}/export'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['regionId'] !== undefined) {
    queryParameters['regionId'] = parameters['regionId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出计划/地区REC的Excel文件
 * request: jkRecFileExportToExcel
 * formData: jkRecFileExportToExcelFormData
 * url: jkRecFileExportToExcel_URL
 * method: jkRecFileExportToExcel_TYPE
 * raw_url: jkRecFileExportToExcel_RAW_URL
 * @param planId - planId
 * @param accessToken - 
 * @param regionId - 筛查地区ID
 * @param type - REC文件模板类型
 */
export const jkRecFileExportToExcel = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/rec-file/{planId}/export-excel'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['regionId'] !== undefined) {
    queryParameters['regionId'] = parameters['regionId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRecFileExportToExcelFormData = function(formData) {
  const url = jkRecFileExportToExcel_RAW_URL()
  const method = jkRecFileExportToExcel_TYPE()
  return axios[method](url, formData)
}
export const jkRecFileExportToExcel_RAW_URL = function() {
  return '/jkzx/rec-file/{planId}/export-excel'
}
export const jkRecFileExportToExcel_TYPE = function() {
  return 'get'
}
export const jkRecFileExportToExcel_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/rec-file/{planId}/export-excel'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['regionId'] !== undefined) {
    queryParameters['regionId'] = parameters['regionId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷完整度列表
 * request: jkRegionQuestionaryIntactList
 * formData: jkRegionQuestionaryIntactListFormData
 * url: jkRegionQuestionaryIntactList_URL
 * method: jkRegionQuestionaryIntactList_TYPE
 * raw_url: jkRegionQuestionaryIntactList_RAW_URL
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param questionaryObject - 
 * @param schoolType - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkRegionQuestionaryIntactList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactListFormData = function(formData) {
  const url = jkRegionQuestionaryIntactList_RAW_URL()
  const method = jkRegionQuestionaryIntactList_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactList_RAW_URL = function() {
  return '/jkzx/region-questionary-intact'
}
export const jkRegionQuestionaryIntactList_TYPE = function() {
  return 'get'
}
export const jkRegionQuestionaryIntactList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加地区问卷完整度
 * request: jkRegionQuestionaryIntactAdd
 * formData: jkRegionQuestionaryIntactAddFormData
 * url: jkRegionQuestionaryIntactAdd_URL
 * method: jkRegionQuestionaryIntactAdd_TYPE
 * raw_url: jkRegionQuestionaryIntactAdd_RAW_URL
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param schoolType - 
 * @param questionaryObject - 
 */
export const jkRegionQuestionaryIntactAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    form['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['questionaryType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionaryType'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    form['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters['questionaryObject'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionaryObject'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactAddFormData = function(formData) {
  const url = jkRegionQuestionaryIntactAdd_RAW_URL()
  const method = jkRegionQuestionaryIntactAdd_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactAdd_RAW_URL = function() {
  return '/jkzx/region-questionary-intact'
}
export const jkRegionQuestionaryIntactAdd_TYPE = function() {
  return 'post'
}
export const jkRegionQuestionaryIntactAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷完整度选择列表
 * request: jkRegionQuestionaryIntactChoiceList
 * formData: jkRegionQuestionaryIntactChoiceListFormData
 * url: jkRegionQuestionaryIntactChoiceList_URL
 * method: jkRegionQuestionaryIntactChoiceList_TYPE
 * raw_url: jkRegionQuestionaryIntactChoiceList_RAW_URL
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param schoolType - 
 * @param questionaryObject - 
 */
export const jkRegionQuestionaryIntactChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactChoiceListFormData = function(formData) {
  const url = jkRegionQuestionaryIntactChoiceList_RAW_URL()
  const method = jkRegionQuestionaryIntactChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactChoiceList_RAW_URL = function() {
  return '/jkzx/region-questionary-intact/choice'
}
export const jkRegionQuestionaryIntactChoiceList_TYPE = function() {
  return 'get'
}
export const jkRegionQuestionaryIntactChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact/choice'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    queryParameters['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    queryParameters['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量添加地区问卷完整度
 * request: jkRegionQuestionaryIntactAddList
 * formData: jkRegionQuestionaryIntactAddListFormData
 * url: jkRegionQuestionaryIntactAddList_URL
 * method: jkRegionQuestionaryIntactAddList_TYPE
 * raw_url: jkRegionQuestionaryIntactAddList_RAW_URL
 * @param list - list
 */
export const jkRegionQuestionaryIntactAddList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact/list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['list'] !== undefined) {
    body = parameters['list']
  }
  if (parameters['list'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: list'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactAddListFormData = function(formData) {
  const url = jkRegionQuestionaryIntactAddList_RAW_URL()
  const method = jkRegionQuestionaryIntactAddList_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactAddList_RAW_URL = function() {
  return '/jkzx/region-questionary-intact/list'
}
export const jkRegionQuestionaryIntactAddList_TYPE = function() {
  return 'post'
}
export const jkRegionQuestionaryIntactAddList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact/list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取地区问卷完整度
 * request: jkRegionQuestionaryIntactFind
 * formData: jkRegionQuestionaryIntactFindFormData
 * url: jkRegionQuestionaryIntactFind_URL
 * method: jkRegionQuestionaryIntactFind_TYPE
 * raw_url: jkRegionQuestionaryIntactFind_RAW_URL
 * @param id - id
 */
export const jkRegionQuestionaryIntactFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactFindFormData = function(formData) {
  const url = jkRegionQuestionaryIntactFind_RAW_URL()
  const method = jkRegionQuestionaryIntactFind_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactFind_RAW_URL = function() {
  return '/jkzx/region-questionary-intact/{id}'
}
export const jkRegionQuestionaryIntactFind_TYPE = function() {
  return 'get'
}
export const jkRegionQuestionaryIntactFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改地区问卷完整度
 * request: jkRegionQuestionaryIntactUpdate
 * formData: jkRegionQuestionaryIntactUpdateFormData
 * url: jkRegionQuestionaryIntactUpdate_URL
 * method: jkRegionQuestionaryIntactUpdate_TYPE
 * raw_url: jkRegionQuestionaryIntactUpdate_RAW_URL
 * @param id - id
 * @param screeningPlanId - 
 * @param cityCode - 
 * @param districtCode - 
 * @param questionaryType - 
 * @param schoolType - 
 * @param questionaryObject - 
 */
export const jkRegionQuestionaryIntactUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['questionaryType'] !== undefined) {
    form['questionaryType'] = parameters['questionaryType']
  }
  if (parameters['questionaryType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionaryType'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['questionaryObject'] !== undefined) {
    form['questionaryObject'] = parameters['questionaryObject']
  }
  if (parameters['questionaryObject'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: questionaryObject'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactUpdateFormData = function(formData) {
  const url = jkRegionQuestionaryIntactUpdate_RAW_URL()
  const method = jkRegionQuestionaryIntactUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactUpdate_RAW_URL = function() {
  return '/jkzx/region-questionary-intact/{id}'
}
export const jkRegionQuestionaryIntactUpdate_TYPE = function() {
  return 'put'
}
export const jkRegionQuestionaryIntactUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除地区问卷完整度
 * request: jkRegionQuestionaryIntactDeleteById
 * formData: jkRegionQuestionaryIntactDeleteByIdFormData
 * url: jkRegionQuestionaryIntactDeleteById_URL
 * method: jkRegionQuestionaryIntactDeleteById_TYPE
 * raw_url: jkRegionQuestionaryIntactDeleteById_RAW_URL
 * @param id - id
 */
export const jkRegionQuestionaryIntactDeleteById = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/region-questionary-intact/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkRegionQuestionaryIntactDeleteByIdFormData = function(formData) {
  const url = jkRegionQuestionaryIntactDeleteById_RAW_URL()
  const method = jkRegionQuestionaryIntactDeleteById_TYPE()
  return axios[method](url, formData)
}
export const jkRegionQuestionaryIntactDeleteById_RAW_URL = function() {
  return '/jkzx/region-questionary-intact/{id}'
}
export const jkRegionQuestionaryIntactDeleteById_TYPE = function() {
  return 'delete'
}
export const jkRegionQuestionaryIntactDeleteById_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/region-questionary-intact/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: jkRoleList
 * formData: jkRoleListFormData
 * url: jkRoleList_URL
 * method: jkRoleList_TYPE
 * raw_url: jkRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRoleListFormData = function(formData) {
  const url = jkRoleList_RAW_URL()
  const method = jkRoleList_TYPE()
  return axios[method](url, formData)
}
export const jkRoleList_RAW_URL = function() {
  return '/jkzx/role'
}
export const jkRoleList_TYPE = function() {
  return 'get'
}
export const jkRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: jkRoleAdd
 * formData: jkRoleAddFormData
 * url: jkRoleAdd_URL
 * method: jkRoleAdd_TYPE
 * raw_url: jkRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const jkRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkRoleAddFormData = function(formData) {
  const url = jkRoleAdd_RAW_URL()
  const method = jkRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const jkRoleAdd_RAW_URL = function() {
  return '/jkzx/role'
}
export const jkRoleAdd_TYPE = function() {
  return 'post'
}
export const jkRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: jkRoleListFunction
 * formData: jkRoleListFunctionFormData
 * url: jkRoleListFunction_URL
 * method: jkRoleListFunction_TYPE
 * raw_url: jkRoleListFunction_RAW_URL
 */
export const jkRoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRoleListFunctionFormData = function(formData) {
  const url = jkRoleListFunction_RAW_URL()
  const method = jkRoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const jkRoleListFunction_RAW_URL = function() {
  return '/jkzx/role/function'
}
export const jkRoleListFunction_TYPE = function() {
  return 'get'
}
export const jkRoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: jkRoleFind
 * formData: jkRoleFindFormData
 * url: jkRoleFind_URL
 * method: jkRoleFind_TYPE
 * raw_url: jkRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const jkRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkRoleFindFormData = function(formData) {
  const url = jkRoleFind_RAW_URL()
  const method = jkRoleFind_TYPE()
  return axios[method](url, formData)
}
export const jkRoleFind_RAW_URL = function() {
  return '/jkzx/role/{roleId}'
}
export const jkRoleFind_TYPE = function() {
  return 'get'
}
export const jkRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: jkRoleUpdate
 * formData: jkRoleUpdateFormData
 * url: jkRoleUpdate_URL
 * method: jkRoleUpdate_TYPE
 * raw_url: jkRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const jkRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkRoleUpdateFormData = function(formData) {
  const url = jkRoleUpdate_RAW_URL()
  const method = jkRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkRoleUpdate_RAW_URL = function() {
  return '/jkzx/role/{roleId}'
}
export const jkRoleUpdate_TYPE = function() {
  return 'put'
}
export const jkRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: jkRoleDelete
 * formData: jkRoleDeleteFormData
 * url: jkRoleDelete_URL
 * method: jkRoleDelete_TYPE
 * raw_url: jkRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const jkRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkRoleDeleteFormData = function(formData) {
  const url = jkRoleDelete_RAW_URL()
  const method = jkRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const jkRoleDelete_RAW_URL = function() {
  return '/jkzx/role/{roleId}'
}
export const jkRoleDelete_TYPE = function() {
  return 'delete'
}
export const jkRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校列表
 * request: jkSchoolList
 * formData: jkSchoolListFormData
 * url: jkSchoolList_URL
 * method: jkSchoolList_TYPE
 * raw_url: jkSchoolList_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param type - 学校类型
 * @param name - 学校名称
 * @param code - 学校编码
 * @param isNotScreeningPlanId - 学校不属于该筛查计划
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['isNotScreeningPlanId'] !== undefined) {
    queryParameters['isNotScreeningPlanId'] = parameters['isNotScreeningPlanId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkSchoolListFormData = function(formData) {
  const url = jkSchoolList_RAW_URL()
  const method = jkSchoolList_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolList_RAW_URL = function() {
  return '/jkzx/school'
}
export const jkSchoolList_TYPE = function() {
  return 'get'
}
export const jkSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['isNotScreeningPlanId'] !== undefined) {
    queryParameters['isNotScreeningPlanId'] = parameters['isNotScreeningPlanId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加学校
 * request: jkSchoolAdd
 * formData: jkSchoolAddFormData
 * url: jkSchoolAdd_URL
 * method: jkSchoolAdd_TYPE
 * raw_url: jkSchoolAdd_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param type - 学校类型
 * @param name - 学校名称
 * @param managerId - 管理员ID
 * @param code - 编码
 */
export const jkSchoolAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkSchoolAddFormData = function(formData) {
  const url = jkSchoolAdd_RAW_URL()
  const method = jkSchoolAdd_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolAdd_RAW_URL = function() {
  return '/jkzx/school'
}
export const jkSchoolAdd_TYPE = function() {
  return 'post'
}
export const jkSchoolAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校通过审核
 * request: jkSchoolApplicationPass
 * formData: jkSchoolApplicationPassFormData
 * url: jkSchoolApplicationPass_URL
 * method: jkSchoolApplicationPass_TYPE
 * raw_url: jkSchoolApplicationPass_RAW_URL
 * @param id - id
 */
export const jkSchoolApplicationPass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school-application/{id}/pass'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSchoolApplicationPassFormData = function(formData) {
  const url = jkSchoolApplicationPass_RAW_URL()
  const method = jkSchoolApplicationPass_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolApplicationPass_RAW_URL = function() {
  return '/jkzx/school-application/{id}/pass'
}
export const jkSchoolApplicationPass_TYPE = function() {
  return 'put'
}
export const jkSchoolApplicationPass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school-application/{id}/pass'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校驳回
 * request: jkSchoolApplicationReject
 * formData: jkSchoolApplicationRejectFormData
 * url: jkSchoolApplicationReject_URL
 * method: jkSchoolApplicationReject_TYPE
 * raw_url: jkSchoolApplicationReject_RAW_URL
 * @param id - id
 * @param reason - 原因
 */
export const jkSchoolApplicationReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school-application/{id}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['reason'] !== undefined) {
    form['reason'] = parameters['reason']
  }
  if (parameters['reason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSchoolApplicationRejectFormData = function(formData) {
  const url = jkSchoolApplicationReject_RAW_URL()
  const method = jkSchoolApplicationReject_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolApplicationReject_RAW_URL = function() {
  return '/jkzx/school-application/{id}/reject'
}
export const jkSchoolApplicationReject_TYPE = function() {
  return 'put'
}
export const jkSchoolApplicationReject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school-application/{id}/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校详情
 * request: jkSchoolFind
 * formData: jkSchoolFindFormData
 * url: jkSchoolFind_URL
 * method: jkSchoolFind_TYPE
 * raw_url: jkSchoolFind_RAW_URL
 * @param schoolId - schoolId
 */
export const jkSchoolFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school/{schoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkSchoolFindFormData = function(formData) {
  const url = jkSchoolFind_RAW_URL()
  const method = jkSchoolFind_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolFind_RAW_URL = function() {
  return '/jkzx/school/{schoolId}'
}
export const jkSchoolFind_TYPE = function() {
  return 'get'
}
export const jkSchoolFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school/{schoolId}'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改学校
 * request: jkSchoolUpdate
 * formData: jkSchoolUpdateFormData
 * url: jkSchoolUpdate_URL
 * method: jkSchoolUpdate_TYPE
 * raw_url: jkSchoolUpdate_RAW_URL
 * @param schoolId - schoolId
 * @param type - 学校类型
 * @param name - 学校名称
 * @param managerId - 管理员ID
 */
export const jkSchoolUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school/{schoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSchoolUpdateFormData = function(formData) {
  const url = jkSchoolUpdate_RAW_URL()
  const method = jkSchoolUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolUpdate_RAW_URL = function() {
  return '/jkzx/school/{schoolId}'
}
export const jkSchoolUpdate_TYPE = function() {
  return 'put'
}
export const jkSchoolUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school/{schoolId}'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用学校
 * request: jkSchoolDisable
 * formData: jkSchoolDisableFormData
 * url: jkSchoolDisable_URL
 * method: jkSchoolDisable_TYPE
 * raw_url: jkSchoolDisable_RAW_URL
 * @param schoolId - schoolId
 */
export const jkSchoolDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school/{schoolId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSchoolDisableFormData = function(formData) {
  const url = jkSchoolDisable_RAW_URL()
  const method = jkSchoolDisable_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolDisable_RAW_URL = function() {
  return '/jkzx/school/{schoolId}/disable'
}
export const jkSchoolDisable_TYPE = function() {
  return 'put'
}
export const jkSchoolDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school/{schoolId}/disable'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用学校
 * request: jkSchoolEnable
 * formData: jkSchoolEnableFormData
 * url: jkSchoolEnable_URL
 * method: jkSchoolEnable_TYPE
 * raw_url: jkSchoolEnable_RAW_URL
 * @param schoolId - schoolId
 */
export const jkSchoolEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/school/{schoolId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkSchoolEnableFormData = function(formData) {
  const url = jkSchoolEnable_RAW_URL()
  const method = jkSchoolEnable_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolEnable_RAW_URL = function() {
  return '/jkzx/school/{schoolId}/enable'
}
export const jkSchoolEnable_TYPE = function() {
  return 'put'
}
export const jkSchoolEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/school/{schoolId}/enable'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 区查看筛查监控（按班级分组，带调查问卷）
 * request: jkDistrictScreeningMonitoringFindWithQuestionary
 * formData: jkDistrictScreeningMonitoringFindWithQuestionaryFormData
 * url: jkDistrictScreeningMonitoringFindWithQuestionary_URL
 * method: jkDistrictScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: jkDistrictScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param districtCode - districtCode
 */
export const jkDistrictScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{districtCode}', `${parameters['districtCode']}`)
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkDistrictScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = jkDistrictScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = jkDistrictScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jkDistrictScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
}
export const jkDistrictScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const jkDistrictScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/district/{districtCode}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{districtCode}', `${parameters['districtCode']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: jkScreeningMonitoringFind
 * formData: jkScreeningMonitoringFindFormData
 * url: jkScreeningMonitoringFind_URL
 * method: jkScreeningMonitoringFind_TYPE
 * raw_url: jkScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringFindFormData = function(formData) {
  const url = jkScreeningMonitoringFind_RAW_URL()
  const method = jkScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringFind_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const jkScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: jkScreeningMonitoringAbnormalStudent
 * formData: jkScreeningMonitoringAbnormalStudentFormData
 * url: jkScreeningMonitoringAbnormalStudent_URL
 * method: jkScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: jkScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = jkScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = jkScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const jkScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: jkScreeningMonitoringFinishedStudent
 * formData: jkScreeningMonitoringFinishedStudentFormData
 * url: jkScreeningMonitoringFinishedStudent_URL
 * method: jkScreeningMonitoringFinishedStudent_TYPE
 * raw_url: jkScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = jkScreeningMonitoringFinishedStudent_RAW_URL()
  const method = jkScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const jkScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: jkScreeningMonitoringFinishedStudentWithQuestionary
 * formData: jkScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: jkScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: jkScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: jkScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = jkScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = jkScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const jkScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: jkScreeningMonitoringMissingStudent
 * formData: jkScreeningMonitoringMissingStudentFormData
 * url: jkScreeningMonitoringMissingStudent_URL
 * method: jkScreeningMonitoringMissingStudent_TYPE
 * raw_url: jkScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = jkScreeningMonitoringMissingStudent_RAW_URL()
  const method = jkScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const jkScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: jkScreeningMonitoringMissingStudentWithQuestionary
 * formData: jkScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: jkScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: jkScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: jkScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = jkScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = jkScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const jkScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: jkScreeningMonitoringUncheckedStudent
 * formData: jkScreeningMonitoringUncheckedStudentFormData
 * url: jkScreeningMonitoringUncheckedStudent_URL
 * method: jkScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: jkScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = jkScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = jkScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const jkScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: jkScreeningMonitoringFindWithQuestionary
 * formData: jkScreeningMonitoringFindWithQuestionaryFormData
 * url: jkScreeningMonitoringFindWithQuestionary_URL
 * method: jkScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: jkScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = jkScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = jkScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const jkScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷已完成学生名单导出
 * request: jkScreeningMonitoringListQuestionnaryCompleteStudentExport
 * formData: jkScreeningMonitoringListQuestionnaryCompleteStudentExportFormData
 * url: jkScreeningMonitoringListQuestionnaryCompleteStudentExport_URL
 * method: jkScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE
 * raw_url: jkScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param accessToken - 
 * @param regionCode - 
 * @param screeningSchoolId - 
 */
export const jkScreeningMonitoringListQuestionnaryCompleteStudentExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringListQuestionnaryCompleteStudentExportFormData = function(formData) {
  const url = jkScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL()
  const method = jkScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
}
export const jkScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringListQuestionnaryCompleteStudentExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生名单导出
 * request: jkScreeningMonitoringListQuestionnaryIncompleteStudentExport
 * formData: jkScreeningMonitoringListQuestionnaryIncompleteStudentExportFormData
 * url: jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_URL
 * method: jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE
 * raw_url: jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param accessToken - 
 * @param regionCode - 
 * @param screeningSchoolId - 
 */
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentExportFormData = function(formData) {
  const url = jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL()
  const method = jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: jkScreeningMonitoringQualityControl
 * formData: jkScreeningMonitoringQualityControlFormData
 * url: jkScreeningMonitoringQualityControl_URL
 * method: jkScreeningMonitoringQualityControl_TYPE
 * raw_url: jkScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const jkScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringQualityControlFormData = function(formData) {
  const url = jkScreeningMonitoringQualityControl_RAW_URL()
  const method = jkScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const jkScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: jkScreeningMonitoringQuestionnaryScreeningSchool
 * formData: jkScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: jkScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: jkScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: jkScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = jkScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = jkScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const jkScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: jkScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: jkScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: jkScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: jkScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: jkScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const jkScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = jkScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = jkScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划列表
 * request: jkScreeningPlanList
 * formData: jkScreeningPlanListFormData
 * url: jkScreeningPlanList_URL
 * method: jkScreeningPlanList_TYPE
 * raw_url: jkScreeningPlanList_RAW_URL
 * @param planDate - 
 * @param planName - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningPlanList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planDate'] !== undefined) {
    queryParameters['planDate'] = parameters['planDate']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanListFormData = function(formData) {
  const url = jkScreeningPlanList_RAW_URL()
  const method = jkScreeningPlanList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanList_RAW_URL = function() {
  return '/jkzx/screening-plan'
}
export const jkScreeningPlanList_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan'
  if (parameters['planDate'] !== undefined) {
    queryParameters['planDate'] = parameters['planDate']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增筛查计划
 * request: jkScreeningPlanAdd
 * formData: jkScreeningPlanAddFormData
 * url: jkScreeningPlanAdd_URL
 * method: jkScreeningPlanAdd_TYPE
 * raw_url: jkScreeningPlanAdd_RAW_URL
 * @param type - 类型
 * @param name - 筛查计划名称
 * @param startDate - 开始时间
 * @param planEndDate - 计划完成时间
 * @param inputType - 录入类型
 */
export const jkScreeningPlanAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['startDate'] !== undefined) {
    form['startDate'] = parameters['startDate']
  }
  if (parameters['startDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startDate'))
  }
  if (parameters['planEndDate'] !== undefined) {
    form['planEndDate'] = parameters['planEndDate']
  }
  if (parameters['planEndDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planEndDate'))
  }
  if (parameters['inputType'] !== undefined) {
    form['inputType'] = parameters['inputType']
  }
  if (parameters['inputType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: inputType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanAddFormData = function(formData) {
  const url = jkScreeningPlanAdd_RAW_URL()
  const method = jkScreeningPlanAdd_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanAdd_RAW_URL = function() {
  return '/jkzx/screening-plan'
}
export const jkScreeningPlanAdd_TYPE = function() {
  return 'post'
}
export const jkScreeningPlanAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区计划选择列表
 * request: jkScreeningPlanChoiceList
 * formData: jkScreeningPlanChoiceListFormData
 * url: jkScreeningPlanChoiceList_URL
 * method: jkScreeningPlanChoiceList_TYPE
 * raw_url: jkScreeningPlanChoiceList_RAW_URL
 */
export const jkScreeningPlanChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanChoiceListFormData = function(formData) {
  const url = jkScreeningPlanChoiceList_RAW_URL()
  const method = jkScreeningPlanChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanChoiceList_RAW_URL = function() {
  return '/jkzx/screening-plan/choice'
}
export const jkScreeningPlanChoiceList_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/choice'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取下级筛查地区或学校(可以多选)
 * request: jkScreeningRegionAndScreeningSchoolList
 * formData: jkScreeningRegionAndScreeningSchoolListFormData
 * url: jkScreeningRegionAndScreeningSchoolList_URL
 * method: jkScreeningRegionAndScreeningSchoolList_TYPE
 * raw_url: jkScreeningRegionAndScreeningSchoolList_RAW_URL
 * @param form - form
 * @param screeningPlanId - 筛查计划
 * @param cityCodes - 市编码.多个市用,隔开
 * @param districtCodes - 区编 码.多个区用,隔开
 */
export const jkScreeningRegionAndScreeningSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/screening-region-and-screening-school-List'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['form'] !== undefined) {
    body = parameters['form']
  }
  if (parameters['form'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: form'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['cityCodes'] !== undefined) {
    form['cityCodes'] = parameters['cityCodes']
  }
  if (parameters['districtCodes'] !== undefined) {
    form['districtCodes'] = parameters['districtCodes']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionAndScreeningSchoolListFormData = function(formData) {
  const url = jkScreeningRegionAndScreeningSchoolList_RAW_URL()
  const method = jkScreeningRegionAndScreeningSchoolList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionAndScreeningSchoolList_RAW_URL = function() {
  return '/jkzx/screening-plan/screening-region-and-screening-school-List'
}
export const jkScreeningRegionAndScreeningSchoolList_TYPE = function() {
  return 'post'
}
export const jkScreeningRegionAndScreeningSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/screening-region-and-screening-school-List'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出筛查学生信息
 * request: jkScreeningPlanExportScreeningStudent
 * formData: jkScreeningPlanExportScreeningStudentFormData
 * url: jkScreeningPlanExportScreeningStudent_URL
 * method: jkScreeningPlanExportScreeningStudent_TYPE
 * raw_url: jkScreeningPlanExportScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param planId - 
 * @param condition - 导出条件
 */
export const jkScreeningPlanExportScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/screening-school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters['condition'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: condition'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanExportScreeningStudentFormData = function(formData) {
  const url = jkScreeningPlanExportScreeningStudent_RAW_URL()
  const method = jkScreeningPlanExportScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanExportScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-plan/screening-school/{screeningSchoolId}'
}
export const jkScreeningPlanExportScreeningStudent_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanExportScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/screening-school/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['condition'] !== undefined) {
    queryParameters['condition'] = parameters['condition']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查计划条件
 * request: jkScreeningPlanFindCondition
 * formData: jkScreeningPlanFindConditionFormData
 * url: jkScreeningPlanFindCondition_URL
 * method: jkScreeningPlanFindCondition_TYPE
 * raw_url: jkScreeningPlanFindCondition_RAW_URL
 * @param planId - planId
 * @param schoolType - schoolType
 */
export const jkScreeningPlanFindCondition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{planId}/condition'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanFindConditionFormData = function(formData) {
  const url = jkScreeningPlanFindCondition_RAW_URL()
  const method = jkScreeningPlanFindCondition_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanFindCondition_RAW_URL = function() {
  return '/jkzx/screening-plan/{planId}/condition'
}
export const jkScreeningPlanFindCondition_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanFindCondition_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{planId}/condition'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存筛查计划条件
 * request: jkScreeningPlanSaveCondition
 * formData: jkScreeningPlanSaveConditionFormData
 * url: jkScreeningPlanSaveCondition_URL
 * method: jkScreeningPlanSaveCondition_TYPE
 * raw_url: jkScreeningPlanSaveCondition_RAW_URL
 * @param planId - planId
 * @param schoolType - 
 * @param screeningProjects - 
 * @param dataIntactCondition - 
 * @param dataValidCondition - 
 * @param statisticsSampleCondition - 
 */
export const jkScreeningPlanSaveCondition = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{planId}/condition/save'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['screeningProjects'] !== undefined) {
    form['screeningProjects'] = parameters['screeningProjects']
  }
  if (parameters['screeningProjects'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningProjects'))
  }
  if (parameters['dataIntactCondition'] !== undefined) {
    form['dataIntactCondition'] = parameters['dataIntactCondition']
  }
  if (parameters['dataIntactCondition'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataIntactCondition'))
  }
  if (parameters['dataValidCondition'] !== undefined) {
    form['dataValidCondition'] = parameters['dataValidCondition']
  }
  if (parameters['dataValidCondition'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataValidCondition'))
  }
  if (parameters['statisticsSampleCondition'] !== undefined) {
    form['statisticsSampleCondition'] = parameters['statisticsSampleCondition']
  }
  if (parameters['statisticsSampleCondition'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: statisticsSampleCondition'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanSaveConditionFormData = function(formData) {
  const url = jkScreeningPlanSaveCondition_RAW_URL()
  const method = jkScreeningPlanSaveCondition_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanSaveCondition_RAW_URL = function() {
  return '/jkzx/screening-plan/{planId}/condition/save'
}
export const jkScreeningPlanSaveCondition_TYPE = function() {
  return 'post'
}
export const jkScreeningPlanSaveCondition_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{planId}/condition/save'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划详情
 * request: jkScreeningPlanFind
 * formData: jkScreeningPlanFindFormData
 * url: jkScreeningPlanFind_URL
 * method: jkScreeningPlanFind_TYPE
 * raw_url: jkScreeningPlanFind_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanFindFormData = function(formData) {
  const url = jkScreeningPlanFind_RAW_URL()
  const method = jkScreeningPlanFind_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanFind_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}'
}
export const jkScreeningPlanFind_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查计划
 * request: jkScreeningPlanUpdate
 * formData: jkScreeningPlanUpdateFormData
 * url: jkScreeningPlanUpdate_URL
 * method: jkScreeningPlanUpdate_TYPE
 * raw_url: jkScreeningPlanUpdate_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param type - 类型
 * @param name - 筛查计划名称
 * @param startDate - 开始时间
 * @param planEndDate - 计划完成时间
 */
export const jkScreeningPlanUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['startDate'] !== undefined) {
    form['startDate'] = parameters['startDate']
  }
  if (parameters['startDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startDate'))
  }
  if (parameters['planEndDate'] !== undefined) {
    form['planEndDate'] = parameters['planEndDate']
  }
  if (parameters['planEndDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planEndDate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateFormData = function(formData) {
  const url = jkScreeningPlanUpdate_RAW_URL()
  const method = jkScreeningPlanUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdate_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}'
}
export const jkScreeningPlanUpdate_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查计划
 * request: jkScreeningPlanDelete
 * formData: jkScreeningPlanDeleteFormData
 * url: jkScreeningPlanDelete_URL
 * method: jkScreeningPlanDelete_TYPE
 * raw_url: jkScreeningPlanDelete_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanDeleteFormData = function(formData) {
  const url = jkScreeningPlanDelete_RAW_URL()
  const method = jkScreeningPlanDelete_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanDelete_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}'
}
export const jkScreeningPlanDelete_TYPE = function() {
  return 'delete'
}
export const jkScreeningPlanDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更新检查筛查数量
 * request: jkScreeningPlanUpdateCheckedScreeningNumber
 * formData: jkScreeningPlanUpdateCheckedScreeningNumberFormData
 * url: jkScreeningPlanUpdateCheckedScreeningNumber_URL
 * method: jkScreeningPlanUpdateCheckedScreeningNumber_TYPE
 * raw_url: jkScreeningPlanUpdateCheckedScreeningNumber_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanUpdateCheckedScreeningNumber = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/checked-screening-number'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateCheckedScreeningNumberFormData = function(formData) {
  const url = jkScreeningPlanUpdateCheckedScreeningNumber_RAW_URL()
  const method = jkScreeningPlanUpdateCheckedScreeningNumber_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdateCheckedScreeningNumber_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/checked-screening-number'
}
export const jkScreeningPlanUpdateCheckedScreeningNumber_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdateCheckedScreeningNumber_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/checked-screening-number'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查计划为已完成状态
 * request: jkScreeningPlanUpdateFinishedStatus
 * formData: jkScreeningPlanUpdateFinishedStatusFormData
 * url: jkScreeningPlanUpdateFinishedStatus_URL
 * method: jkScreeningPlanUpdateFinishedStatus_TYPE
 * raw_url: jkScreeningPlanUpdateFinishedStatus_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanUpdateFinishedStatus = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/finished-status'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateFinishedStatusFormData = function(formData) {
  const url = jkScreeningPlanUpdateFinishedStatus_RAW_URL()
  const method = jkScreeningPlanUpdateFinishedStatus_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdateFinishedStatus_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/finished-status'
}
export const jkScreeningPlanUpdateFinishedStatus_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdateFinishedStatus_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/finished-status'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 补发筛查地区
 * request: jkScreeningPlanPublishAgain
 * formData: jkScreeningPlanPublishAgainFormData
 * url: jkScreeningPlanPublishAgain_URL
 * method: jkScreeningPlanPublishAgain_TYPE
 * raw_url: jkScreeningPlanPublishAgain_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionVo - screeningRegionVO
 */
export const jkScreeningPlanPublishAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/publish-again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionVo'] !== undefined) {
    body = parameters['screeningRegionVo']
  }
  if (parameters['screeningRegionVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanPublishAgainFormData = function(formData) {
  const url = jkScreeningPlanPublishAgain_RAW_URL()
  const method = jkScreeningPlanPublishAgain_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanPublishAgain_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/publish-again'
}
export const jkScreeningPlanPublishAgain_TYPE = function() {
  return 'post'
}
export const jkScreeningPlanPublishAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/publish-again'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校列表
 * request: jkScreeningPlanSchoolList
 * formData: jkScreeningPlanSchoolListFormData
 * url: jkScreeningPlanSchoolList_URL
 * method: jkScreeningPlanSchoolList_TYPE
 * raw_url: jkScreeningPlanSchoolList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - 
 * @param schoolName - 
 * @param schoolType - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningPlanSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['schoolName'] !== undefined) {
    queryParameters['schoolName'] = parameters['schoolName']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanSchoolListFormData = function(formData) {
  const url = jkScreeningPlanSchoolList_RAW_URL()
  const method = jkScreeningPlanSchoolList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanSchoolList_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/school'
}
export const jkScreeningPlanSchoolList_TYPE = function() {
  return 'get'
}
export const jkScreeningPlanSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['schoolName'] !== undefined) {
    queryParameters['schoolName'] = parameters['schoolName']
  }
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查地区
 * request: jkScreeningPlanAddScreeningRegion
 * formData: jkScreeningPlanAddScreeningRegionFormData
 * url: jkScreeningPlanAddScreeningRegion_URL
 * method: jkScreeningPlanAddScreeningRegion_TYPE
 * raw_url: jkScreeningPlanAddScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionVo - screeningRegionVO
 */
export const jkScreeningPlanAddScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionVo'] !== undefined) {
    body = parameters['screeningRegionVo']
  }
  if (parameters['screeningRegionVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanAddScreeningRegionFormData = function(formData) {
  const url = jkScreeningPlanAddScreeningRegion_RAW_URL()
  const method = jkScreeningPlanAddScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanAddScreeningRegion_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/screening-region'
}
export const jkScreeningPlanAddScreeningRegion_TYPE = function() {
  return 'post'
}
export const jkScreeningPlanAddScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查地区
 * request: jkScreeningPlanUpdateScreeningRegion
 * formData: jkScreeningPlanUpdateScreeningRegionFormData
 * url: jkScreeningPlanUpdateScreeningRegion_URL
 * method: jkScreeningPlanUpdateScreeningRegion_TYPE
 * raw_url: jkScreeningPlanUpdateScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 * @param screeningRegionVo - screeningRegionVO
 */
export const jkScreeningPlanUpdateScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['screeningRegionVo'] !== undefined) {
    body = parameters['screeningRegionVo']
  }
  if (parameters['screeningRegionVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateScreeningRegionFormData = function(formData) {
  const url = jkScreeningPlanUpdateScreeningRegion_RAW_URL()
  const method = jkScreeningPlanUpdateScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdateScreeningRegion_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
}
export const jkScreeningPlanUpdateScreeningRegion_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdateScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查地区
 * request: jkScreeningPlanDeleteScreeningRegion
 * formData: jkScreeningPlanDeleteScreeningRegionFormData
 * url: jkScreeningPlanDeleteScreeningRegion_URL
 * method: jkScreeningPlanDeleteScreeningRegion_TYPE
 * raw_url: jkScreeningPlanDeleteScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 */
export const jkScreeningPlanDeleteScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanDeleteScreeningRegionFormData = function(formData) {
  const url = jkScreeningPlanDeleteScreeningRegion_RAW_URL()
  const method = jkScreeningPlanDeleteScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanDeleteScreeningRegion_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
}
export const jkScreeningPlanDeleteScreeningRegion_TYPE = function() {
  return 'delete'
}
export const jkScreeningPlanDeleteScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/screening-region/{screeningRegionId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发布筛查计划
 * request: jkScreeningPlanUpdateStatusEnable
 * formData: jkScreeningPlanUpdateStatusEnableFormData
 * url: jkScreeningPlanUpdateStatusEnable_URL
 * method: jkScreeningPlanUpdateStatusEnable_TYPE
 * raw_url: jkScreeningPlanUpdateStatusEnable_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanUpdateStatusEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/status-enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateStatusEnableFormData = function(formData) {
  const url = jkScreeningPlanUpdateStatusEnable_RAW_URL()
  const method = jkScreeningPlanUpdateStatusEnable_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdateStatusEnable_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/status-enable'
}
export const jkScreeningPlanUpdateStatusEnable_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdateStatusEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/status-enable'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查计划为待筛查筛查状态
 * request: jkScreeningPlanUpdateWaitScreeningStatus
 * formData: jkScreeningPlanUpdateWaitScreeningStatusFormData
 * url: jkScreeningPlanUpdateWaitScreeningStatus_URL
 * method: jkScreeningPlanUpdateWaitScreeningStatus_TYPE
 * raw_url: jkScreeningPlanUpdateWaitScreeningStatus_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkScreeningPlanUpdateWaitScreeningStatus = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-plan/{screeningPlanId}/wait-screening-status'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningPlanUpdateWaitScreeningStatusFormData = function(formData) {
  const url = jkScreeningPlanUpdateWaitScreeningStatus_RAW_URL()
  const method = jkScreeningPlanUpdateWaitScreeningStatus_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningPlanUpdateWaitScreeningStatus_RAW_URL = function() {
  return '/jkzx/screening-plan/{screeningPlanId}/wait-screening-status'
}
export const jkScreeningPlanUpdateWaitScreeningStatus_TYPE = function() {
  return 'put'
}
export const jkScreeningPlanUpdateWaitScreeningStatus_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-plan/{screeningPlanId}/wait-screening-status'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据地区编码删除筛查地区
 * request: jkScreeningRegionDeleteByRegionCode
 * formData: jkScreeningRegionDeleteByRegionCodeFormData
 * url: jkScreeningRegionDeleteByRegionCode_URL
 * method: jkScreeningRegionDeleteByRegionCode_TYPE
 * raw_url: jkScreeningRegionDeleteByRegionCode_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 */
export const jkScreeningRegionDeleteByRegionCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/screening-plan/{screeningPlanId}/by-region-code'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDeleteByRegionCodeFormData = function(formData) {
  const url = jkScreeningRegionDeleteByRegionCode_RAW_URL()
  const method = jkScreeningRegionDeleteByRegionCode_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDeleteByRegionCode_RAW_URL = function() {
  return '/jkzx/screening-region/screening-plan/{screeningPlanId}/by-region-code'
}
export const jkScreeningRegionDeleteByRegionCode_TYPE = function() {
  return 'delete'
}
export const jkScreeningRegionDeleteByRegionCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/screening-plan/{screeningPlanId}/by-region-code'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区监测列表
 * request: jkScreeningRegionRegionMonitoringList
 * formData: jkScreeningRegionRegionMonitoringListFormData
 * url: jkScreeningRegionRegionMonitoringList_URL
 * method: jkScreeningRegionRegionMonitoringList_TYPE
 * raw_url: jkScreeningRegionRegionMonitoringList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const jkScreeningRegionRegionMonitoringList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionRegionMonitoringListFormData = function(formData) {
  const url = jkScreeningRegionRegionMonitoringList_RAW_URL()
  const method = jkScreeningRegionRegionMonitoringList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionRegionMonitoringList_RAW_URL = function() {
  return '/jkzx/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
}
export const jkScreeningRegionRegionMonitoringList_TYPE = function() {
  return 'get'
}
export const jkScreeningRegionRegionMonitoringList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除已下发任务的筛查学校
 * request: jkScreeningRegionDeleteTaskScreeningSchool
 * formData: jkScreeningRegionDeleteTaskScreeningSchoolFormData
 * url: jkScreeningRegionDeleteTaskScreeningSchool_URL
 * method: jkScreeningRegionDeleteTaskScreeningSchool_TYPE
 * raw_url: jkScreeningRegionDeleteTaskScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningRegionDeleteTaskScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/task-screening-school/{screeningSchoolId}/'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDeleteTaskScreeningSchoolFormData = function(formData) {
  const url = jkScreeningRegionDeleteTaskScreeningSchool_RAW_URL()
  const method = jkScreeningRegionDeleteTaskScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDeleteTaskScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-region/task-screening-school/{screeningSchoolId}/'
}
export const jkScreeningRegionDeleteTaskScreeningSchool_TYPE = function() {
  return 'delete'
}
export const jkScreeningRegionDeleteTaskScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/task-screening-school/{screeningSchoolId}/'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 补发下级地区
 * request: jkScreeningRegionDistributeLowerAgain
 * formData: jkScreeningRegionDistributeLowerAgainFormData
 * url: jkScreeningRegionDistributeLowerAgain_URL
 * method: jkScreeningRegionDistributeLowerAgain_TYPE
 * raw_url: jkScreeningRegionDistributeLowerAgain_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param vo - vo
 */
export const jkScreeningRegionDistributeLowerAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{parentRegionId}/distribute-lower-again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDistributeLowerAgainFormData = function(formData) {
  const url = jkScreeningRegionDistributeLowerAgain_RAW_URL()
  const method = jkScreeningRegionDistributeLowerAgain_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDistributeLowerAgain_RAW_URL = function() {
  return '/jkzx/screening-region/{parentRegionId}/distribute-lower-again'
}
export const jkScreeningRegionDistributeLowerAgain_TYPE = function() {
  return 'post'
}
export const jkScreeningRegionDistributeLowerAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{parentRegionId}/distribute-lower-again'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加下级地区
 * request: jkScreeningRegionAddLowerRegion
 * formData: jkScreeningRegionAddLowerRegionFormData
 * url: jkScreeningRegionAddLowerRegion_URL
 * method: jkScreeningRegionAddLowerRegion_TYPE
 * raw_url: jkScreeningRegionAddLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param vo - vo
 */
export const jkScreeningRegionAddLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionAddLowerRegionFormData = function(formData) {
  const url = jkScreeningRegionAddLowerRegion_RAW_URL()
  const method = jkScreeningRegionAddLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionAddLowerRegion_RAW_URL = function() {
  return '/jkzx/screening-region/{parentRegionId}/lower-region'
}
export const jkScreeningRegionAddLowerRegion_TYPE = function() {
  return 'post'
}
export const jkScreeningRegionAddLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改下级地区
 * request: jkScreeningRegionUpdateLowerRegion
 * formData: jkScreeningRegionUpdateLowerRegionFormData
 * url: jkScreeningRegionUpdateLowerRegion_URL
 * method: jkScreeningRegionUpdateLowerRegion_TYPE
 * raw_url: jkScreeningRegionUpdateLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param screeningRegionId - screeningRegionId
 * @param vo - vo
 */
export const jkScreeningRegionUpdateLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionUpdateLowerRegionFormData = function(formData) {
  const url = jkScreeningRegionUpdateLowerRegion_RAW_URL()
  const method = jkScreeningRegionUpdateLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionUpdateLowerRegion_RAW_URL = function() {
  return '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
}
export const jkScreeningRegionUpdateLowerRegion_TYPE = function() {
  return 'put'
}
export const jkScreeningRegionUpdateLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除下级地区
 * request: jkScreeningRegionDeleteLowerRegion
 * formData: jkScreeningRegionDeleteLowerRegionFormData
 * url: jkScreeningRegionDeleteLowerRegion_URL
 * method: jkScreeningRegionDeleteLowerRegion_TYPE
 * raw_url: jkScreeningRegionDeleteLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param screeningRegionId - screeningRegionId
 */
export const jkScreeningRegionDeleteLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDeleteLowerRegionFormData = function(formData) {
  const url = jkScreeningRegionDeleteLowerRegion_RAW_URL()
  const method = jkScreeningRegionDeleteLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDeleteLowerRegion_RAW_URL = function() {
  return '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
}
export const jkScreeningRegionDeleteLowerRegion_TYPE = function() {
  return 'delete'
}
export const jkScreeningRegionDeleteLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷
 * request: jkScreeningRegionQuestionary
 * formData: jkScreeningRegionQuestionaryFormData
 * url: jkScreeningRegionQuestionary_URL
 * method: jkScreeningRegionQuestionary_TYPE
 * raw_url: jkScreeningRegionQuestionary_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 */
export const jkScreeningRegionQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{screeningPlanId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionQuestionaryFormData = function(formData) {
  const url = jkScreeningRegionQuestionary_RAW_URL()
  const method = jkScreeningRegionQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionQuestionary_RAW_URL = function() {
  return '/jkzx/screening-region/{screeningPlanId}/questionary'
}
export const jkScreeningRegionQuestionary_TYPE = function() {
  return 'get'
}
export const jkScreeningRegionQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{screeningPlanId}/questionary'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷统计
 * request: jkScreeningRegionQuestionaryStats
 * formData: jkScreeningRegionQuestionaryStatsFormData
 * url: jkScreeningRegionQuestionaryStats_URL
 * method: jkScreeningRegionQuestionaryStats_TYPE
 * raw_url: jkScreeningRegionQuestionaryStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const jkScreeningRegionQuestionaryStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{screeningPlanId}/questionary-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionQuestionaryStatsFormData = function(formData) {
  const url = jkScreeningRegionQuestionaryStats_RAW_URL()
  const method = jkScreeningRegionQuestionaryStats_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionQuestionaryStats_RAW_URL = function() {
  return '/jkzx/screening-region/{screeningPlanId}/questionary-stats'
}
export const jkScreeningRegionQuestionaryStats_TYPE = function() {
  return 'get'
}
export const jkScreeningRegionQuestionaryStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{screeningPlanId}/questionary-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 补发筛查学校
 * request: jkScreeningRegionDistributeSchoolAgain
 * formData: jkScreeningRegionDistributeSchoolAgainFormData
 * url: jkScreeningRegionDistributeSchoolAgain_URL
 * method: jkScreeningRegionDistributeSchoolAgain_TYPE
 * raw_url: jkScreeningRegionDistributeSchoolAgain_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param schoolId - 学校ID
 * @param schoolType - 学校类型
 * @param screeningTeamId - 筛查团队ID
 * @param code - 编码
 */
export const jkScreeningRegionDistributeSchoolAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{screeningRegionId}/distribute-school-again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['schoolId'] !== undefined) {
    form['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['screeningTeamId'] !== undefined) {
    form['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['screeningTeamId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTeamId'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDistributeSchoolAgainFormData = function(formData) {
  const url = jkScreeningRegionDistributeSchoolAgain_RAW_URL()
  const method = jkScreeningRegionDistributeSchoolAgain_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDistributeSchoolAgain_RAW_URL = function() {
  return '/jkzx/screening-region/{screeningRegionId}/distribute-school-again'
}
export const jkScreeningRegionDistributeSchoolAgain_TYPE = function() {
  return 'post'
}
export const jkScreeningRegionDistributeSchoolAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{screeningRegionId}/distribute-school-again'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查学校
 * request: jkScreeningRegionAddScreeningSchool
 * formData: jkScreeningRegionAddScreeningSchoolFormData
 * url: jkScreeningRegionAddScreeningSchool_URL
 * method: jkScreeningRegionAddScreeningSchool_TYPE
 * raw_url: jkScreeningRegionAddScreeningSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param schoolId - 学校ID
 * @param schoolType - 学校类型
 * @param screeningTeamId - 筛查团队ID
 * @param code - 编码
 */
export const jkScreeningRegionAddScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{screeningRegionId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['schoolId'] !== undefined) {
    form['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['screeningTeamId'] !== undefined) {
    form['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionAddScreeningSchoolFormData = function(formData) {
  const url = jkScreeningRegionAddScreeningSchool_RAW_URL()
  const method = jkScreeningRegionAddScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionAddScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-region/{screeningRegionId}/screening-school'
}
export const jkScreeningRegionAddScreeningSchool_TYPE = function() {
  return 'post'
}
export const jkScreeningRegionAddScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{screeningRegionId}/screening-school'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查学校
 * request: jkScreeningRegionDeleteScreeningSchool
 * formData: jkScreeningRegionDeleteScreeningSchoolFormData
 * url: jkScreeningRegionDeleteScreeningSchool_URL
 * method: jkScreeningRegionDeleteScreeningSchool_TYPE
 * raw_url: jkScreeningRegionDeleteScreeningSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningRegionDeleteScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningRegionDeleteScreeningSchoolFormData = function(formData) {
  const url = jkScreeningRegionDeleteScreeningSchool_RAW_URL()
  const method = jkScreeningRegionDeleteScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningRegionDeleteScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
}
export const jkScreeningRegionDeleteScreeningSchool_TYPE = function() {
  return 'delete'
}
export const jkScreeningRegionDeleteScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查班级
 * request: jkScreeningSchoolAddScreeningClass
 * formData: jkScreeningSchoolAddScreeningClassFormData
 * url: jkScreeningSchoolAddScreeningClass_URL
 * method: jkScreeningSchoolAddScreeningClass_TYPE
 * raw_url: jkScreeningSchoolAddScreeningClass_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param gradeId - 年级ID
 * @param name - 班级名称
 * @param sortNum - 排序号
 */
export const jkScreeningSchoolAddScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/class'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    form['gradeId'] = parameters['gradeId']
  }
  if (parameters['gradeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolAddScreeningClassFormData = function(formData) {
  const url = jkScreeningSchoolAddScreeningClass_RAW_URL()
  const method = jkScreeningSchoolAddScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolAddScreeningClass_RAW_URL = function() {
  return '/jkzx/screening-school/class'
}
export const jkScreeningSchoolAddScreeningClass_TYPE = function() {
  return 'post'
}
export const jkScreeningSchoolAddScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/class'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更新筛查班级
 * request: jkScreeningSchoolUpdateScreeningClass
 * formData: jkScreeningSchoolUpdateScreeningClassFormData
 * url: jkScreeningSchoolUpdateScreeningClass_URL
 * method: jkScreeningSchoolUpdateScreeningClass_TYPE
 * raw_url: jkScreeningSchoolUpdateScreeningClass_RAW_URL
 * @param screeningClassId - screeningClassId
 * @param screeningSchoolId - 筛查学校ID
 * @param gradeId - 年级ID
 * @param name - 班级名称
 * @param sortNum - 排序号
 */
export const jkScreeningSchoolUpdateScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/class/{screeningClassId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    form['gradeId'] = parameters['gradeId']
  }
  if (parameters['gradeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolUpdateScreeningClassFormData = function(formData) {
  const url = jkScreeningSchoolUpdateScreeningClass_RAW_URL()
  const method = jkScreeningSchoolUpdateScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolUpdateScreeningClass_RAW_URL = function() {
  return '/jkzx/screening-school/class/{screeningClassId}'
}
export const jkScreeningSchoolUpdateScreeningClass_TYPE = function() {
  return 'put'
}
export const jkScreeningSchoolUpdateScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/class/{screeningClassId}'
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出视力监测数据
 * request: jkScreeningSchoolExportVisionMonitoringData
 * formData: jkScreeningSchoolExportVisionMonitoringDataFormData
 * url: jkScreeningSchoolExportVisionMonitoringData_URL
 * method: jkScreeningSchoolExportVisionMonitoringData_TYPE
 * raw_url: jkScreeningSchoolExportVisionMonitoringData_RAW_URL
 * @param accessToken - 
 * @param planId - 
 * @param schoolId - 
 */
export const jkScreeningSchoolExportVisionMonitoringData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/export-vision-monitoring-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolExportVisionMonitoringDataFormData = function(formData) {
  const url = jkScreeningSchoolExportVisionMonitoringData_RAW_URL()
  const method = jkScreeningSchoolExportVisionMonitoringData_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolExportVisionMonitoringData_RAW_URL = function() {
  return '/jkzx/screening-school/export-vision-monitoring-data'
}
export const jkScreeningSchoolExportVisionMonitoringData_TYPE = function() {
  return 'get'
}
export const jkScreeningSchoolExportVisionMonitoringData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/export-vision-monitoring-data'
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校参与筛查团队筛查计划列表
 * request: jkScreeningSchoolTeamScreeningSchool
 * formData: jkScreeningSchoolTeamScreeningSchoolFormData
 * url: jkScreeningSchoolTeamScreeningSchool_URL
 * method: jkScreeningSchoolTeamScreeningSchool_TYPE
 * raw_url: jkScreeningSchoolTeamScreeningSchool_RAW_URL
 * @param planType - 计划类型
 * @param planName - 计划名字
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningSchoolTeamScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolTeamScreeningSchoolFormData = function(formData) {
  const url = jkScreeningSchoolTeamScreeningSchool_RAW_URL()
  const method = jkScreeningSchoolTeamScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolTeamScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-school/screening-plan'
}
export const jkScreeningSchoolTeamScreeningSchool_TYPE = function() {
  return 'get'
}
export const jkScreeningSchoolTeamScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/screening-plan'
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查学生
 * request: jkScreeningSchoolAddScreeningStudent
 * formData: jkScreeningSchoolAddScreeningStudentFormData
 * url: jkScreeningSchoolAddScreeningStudent_URL
 * method: jkScreeningSchoolAddScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolAddScreeningStudent_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param geneticsHistory - 
 */
export const jkScreeningSchoolAddScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolAddScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolAddScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolAddScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolAddScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/student'
}
export const jkScreeningSchoolAddScreeningStudent_TYPE = function() {
  return 'post'
}
export const jkScreeningSchoolAddScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/student'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查学生
 * request: jkScreeningSchoolUpdateScreeningStudent
 * formData: jkScreeningSchoolUpdateScreeningStudentFormData
 * url: jkScreeningSchoolUpdateScreeningStudent_URL
 * method: jkScreeningSchoolUpdateScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolUpdateScreeningStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param diseaseHistoryCoronavirus - 
 * @param geneticsHistory - 
 */
export const jkScreeningSchoolUpdateScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['diseaseHistoryCoronavirus'] !== undefined) {
    form['diseaseHistoryCoronavirus'] = parameters['diseaseHistoryCoronavirus']
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolUpdateScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolUpdateScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolUpdateScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolUpdateScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/student/{screeningStudentId}'
}
export const jkScreeningSchoolUpdateScreeningStudent_TYPE = function() {
  return 'put'
}
export const jkScreeningSchoolUpdateScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查学生
 * request: jkScreeningSchoolDeleteScreeningStudent
 * formData: jkScreeningSchoolDeleteScreeningStudentFormData
 * url: jkScreeningSchoolDeleteScreeningStudent_URL
 * method: jkScreeningSchoolDeleteScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolDeleteScreeningStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const jkScreeningSchoolDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolDeleteScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolDeleteScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolDeleteScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/student/{screeningStudentId}'
}
export const jkScreeningSchoolDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const jkScreeningSchoolDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学校对应筛查计划详情
 * request: jkSchoolScreeningSchool
 * formData: jkSchoolScreeningSchoolFormData
 * url: jkSchoolScreeningSchool_URL
 * method: jkSchoolScreeningSchool_TYPE
 * raw_url: jkSchoolScreeningSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkSchoolScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningPlanId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkSchoolScreeningSchoolFormData = function(formData) {
  const url = jkSchoolScreeningSchool_RAW_URL()
  const method = jkSchoolScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jkSchoolScreeningSchool_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningPlanId}/screening-school'
}
export const jkSchoolScreeningSchool_TYPE = function() {
  return 'get'
}
export const jkSchoolScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningPlanId}/screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量恢复学生
 * request: jkScreeningSchoolBatchRecoveryScreeningStudent
 * formData: jkScreeningSchoolBatchRecoveryScreeningStudentFormData
 * url: jkScreeningSchoolBatchRecoveryScreeningStudent_URL
 * method: jkScreeningSchoolBatchRecoveryScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const jkScreeningSchoolBatchRecoveryScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolBatchRecoveryScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolBatchRecoveryScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
}
export const jkScreeningSchoolBatchRecoveryScreeningStudent_TYPE = function() {
  return 'delete'
}
export const jkScreeningSchoolBatchRecoveryScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量删除学生
 * request: jkScreeningSchoolBatchDeleteScreeningStudent
 * formData: jkScreeningSchoolBatchDeleteScreeningStudentFormData
 * url: jkScreeningSchoolBatchDeleteScreeningStudent_URL
 * method: jkScreeningSchoolBatchDeleteScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolBatchDeleteScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const jkScreeningSchoolBatchDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/batch-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolBatchDeleteScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolBatchDeleteScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolBatchDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolBatchDeleteScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/batch-screening-student'
}
export const jkScreeningSchoolBatchDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const jkScreeningSchoolBatchDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/batch-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查班级选择列表
 * request: jkScreeningSchoolScreeningClassChoiceList
 * formData: jkScreeningSchoolScreeningClassChoiceListFormData
 * url: jkScreeningSchoolScreeningClassChoiceList_URL
 * method: jkScreeningSchoolScreeningClassChoiceList_TYPE
 * raw_url: jkScreeningSchoolScreeningClassChoiceList_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningSchoolScreeningClassChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/class/choice-list'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolScreeningClassChoiceListFormData = function(formData) {
  const url = jkScreeningSchoolScreeningClassChoiceList_RAW_URL()
  const method = jkScreeningSchoolScreeningClassChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolScreeningClassChoiceList_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/class/choice-list'
}
export const jkScreeningSchoolScreeningClassChoiceList_TYPE = function() {
  return 'get'
}
export const jkScreeningSchoolScreeningClassChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/class/choice-list'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查班级
 * request: jkScreeningSchoolDeleteScreeningClass
 * formData: jkScreeningSchoolDeleteScreeningClassFormData
 * url: jkScreeningSchoolDeleteScreeningClass_URL
 * method: jkScreeningSchoolDeleteScreeningClass_TYPE
 * raw_url: jkScreeningSchoolDeleteScreeningClass_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - screeningClassId
 */
export const jkScreeningSchoolDeleteScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/class/{screeningClassId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolDeleteScreeningClassFormData = function(formData) {
  const url = jkScreeningSchoolDeleteScreeningClass_RAW_URL()
  const method = jkScreeningSchoolDeleteScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolDeleteScreeningClass_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/class/{screeningClassId}'
}
export const jkScreeningSchoolDeleteScreeningClass_TYPE = function() {
  return 'delete'
}
export const jkScreeningSchoolDeleteScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/class/{screeningClassId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量清除删除学生
 * request: jkScreeningSchoolCleanDeleteScreeningStudent
 * formData: jkScreeningSchoolCleanDeleteScreeningStudentFormData
 * url: jkScreeningSchoolCleanDeleteScreeningStudent_URL
 * method: jkScreeningSchoolCleanDeleteScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolCleanDeleteScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const jkScreeningSchoolCleanDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/clean-delete-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolCleanDeleteScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolCleanDeleteScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolCleanDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolCleanDeleteScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/clean-delete-screening-student'
}
export const jkScreeningSchoolCleanDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const jkScreeningSchoolCleanDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/clean-delete-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校完成学生录入
 * request: jkScreeningSchoolFinishInputStudent
 * formData: jkScreeningSchoolFinishInputStudentFormData
 * url: jkScreeningSchoolFinishInputStudent_URL
 * method: jkScreeningSchoolFinishInputStudent_TYPE
 * raw_url: jkScreeningSchoolFinishInputStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkScreeningSchoolFinishInputStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/finish-input-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolFinishInputStudentFormData = function(formData) {
  const url = jkScreeningSchoolFinishInputStudent_RAW_URL()
  const method = jkScreeningSchoolFinishInputStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolFinishInputStudent_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/finish-input-student'
}
export const jkScreeningSchoolFinishInputStudent_TYPE = function() {
  return 'put'
}
export const jkScreeningSchoolFinishInputStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/finish-input-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量修改学生
 * request: jkScreeningSchoolUpdateScreeningStudentCheckTime
 * formData: jkScreeningSchoolUpdateScreeningStudentCheckTimeFormData
 * url: jkScreeningSchoolUpdateScreeningStudentCheckTime_URL
 * method: jkScreeningSchoolUpdateScreeningStudentCheckTime_TYPE
 * raw_url: jkScreeningSchoolUpdateScreeningStudentCheckTime_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param gradeId - 年级ID
 * @param screeningClassId - 筛查班级ID
 * @param checkTime - 检查时间
 */
export const jkScreeningSchoolUpdateScreeningStudentCheckTime = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/screening-student-check-time'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['gradeId'] !== undefined) {
    form['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolUpdateScreeningStudentCheckTimeFormData = function(formData) {
  const url = jkScreeningSchoolUpdateScreeningStudentCheckTime_RAW_URL()
  const method = jkScreeningSchoolUpdateScreeningStudentCheckTime_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolUpdateScreeningStudentCheckTime_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/screening-student-check-time'
}
export const jkScreeningSchoolUpdateScreeningStudentCheckTime_TYPE = function() {
  return 'put'
}
export const jkScreeningSchoolUpdateScreeningStudentCheckTime_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/screening-student-check-time'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入筛查学生资料
 * request: jkScreeningSchoolImportScreeningStudent
 * formData: jkScreeningSchoolImportScreeningStudentFormData
 * url: jkScreeningSchoolImportScreeningStudent_URL
 * method: jkScreeningSchoolImportScreeningStudent_TYPE
 * raw_url: jkScreeningSchoolImportScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param file - 
 */
export const jkScreeningSchoolImportScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-school/{screeningSchoolId}/student/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningSchoolImportScreeningStudentFormData = function(formData) {
  const url = jkScreeningSchoolImportScreeningStudent_RAW_URL()
  const method = jkScreeningSchoolImportScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningSchoolImportScreeningStudent_RAW_URL = function() {
  return '/jkzx/screening-school/{screeningSchoolId}/student/import'
}
export const jkScreeningSchoolImportScreeningStudent_TYPE = function() {
  return 'post'
}
export const jkScreeningSchoolImportScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-school/{screeningSchoolId}/student/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加调整筛查地区数据任务(发布计划的地区)
 * request: jkAddScreeningTaskAddHiggerUpdateScreeningRegionData
 * formData: jkAddScreeningTaskAddHiggerUpdateScreeningRegionDataFormData
 * url: jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_URL
 * method: jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_TYPE
 * raw_url: jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionRejectList - regionRejectList
 */
export const jkAddScreeningTaskAddHiggerUpdateScreeningRegionData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/screening-plan/{screeningPlanId}/update-region-data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionRejectList'] !== undefined) {
    body = parameters['regionRejectList']
  }
  if (parameters['regionRejectList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionRejectList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkAddScreeningTaskAddHiggerUpdateScreeningRegionDataFormData = function(formData) {
  const url = jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_RAW_URL()
  const method = jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_TYPE()
  return axios[method](url, formData)
}
export const jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_RAW_URL = function() {
  return '/jkzx/screening-task/screening-plan/{screeningPlanId}/update-region-data'
}
export const jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_TYPE = function() {
  return 'post'
}
export const jkAddScreeningTaskAddHiggerUpdateScreeningRegionData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/screening-plan/{screeningPlanId}/update-region-data'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加调整筛查地区数据任务(添加筛查地区的筛查地区)
 * request: jkAddAreaUpdateScreeningRegionData
 * formData: jkAddAreaUpdateScreeningRegionDataFormData
 * url: jkAddAreaUpdateScreeningRegionData_URL
 * method: jkAddAreaUpdateScreeningRegionData_TYPE
 * raw_url: jkAddAreaUpdateScreeningRegionData_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param regionRejectList - regionRejectList
 * @param screeningTaskId - 
 */
export const jkAddAreaUpdateScreeningRegionData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/screening-region/{screeningRegionId}/area-update-region-data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['regionRejectList'] !== undefined) {
    body = parameters['regionRejectList']
  }
  if (parameters['regionRejectList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionRejectList'))
  }
  if (parameters['screeningTaskId'] !== undefined) {
    queryParameters['screeningTaskId'] = parameters['screeningTaskId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkAddAreaUpdateScreeningRegionDataFormData = function(formData) {
  const url = jkAddAreaUpdateScreeningRegionData_RAW_URL()
  const method = jkAddAreaUpdateScreeningRegionData_TYPE()
  return axios[method](url, formData)
}
export const jkAddAreaUpdateScreeningRegionData_RAW_URL = function() {
  return '/jkzx/screening-task/screening-region/{screeningRegionId}/area-update-region-data'
}
export const jkAddAreaUpdateScreeningRegionData_TYPE = function() {
  return 'post'
}
export const jkAddAreaUpdateScreeningRegionData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/screening-region/{screeningRegionId}/area-update-region-data'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningTaskId'] !== undefined) {
    queryParameters['screeningTaskId'] = parameters['screeningTaskId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加调整筛查学校数据任务(区)
 * request: jkAddAreaUpdateScreeningSchoolData
 * formData: jkAddAreaUpdateScreeningSchoolDataFormData
 * url: jkAddAreaUpdateScreeningSchoolData_URL
 * method: jkAddAreaUpdateScreeningSchoolData_TYPE
 * raw_url: jkAddAreaUpdateScreeningSchoolData_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param schoolRejectList - schoolRejectList
 * @param screeningTaskId - 
 */
export const jkAddAreaUpdateScreeningSchoolData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/screening-region/{screeningRegionId}/update-school-data'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['schoolRejectList'] !== undefined) {
    body = parameters['schoolRejectList']
  }
  if (parameters['schoolRejectList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolRejectList'))
  }
  if (parameters['screeningTaskId'] !== undefined) {
    queryParameters['screeningTaskId'] = parameters['screeningTaskId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkAddAreaUpdateScreeningSchoolDataFormData = function(formData) {
  const url = jkAddAreaUpdateScreeningSchoolData_RAW_URL()
  const method = jkAddAreaUpdateScreeningSchoolData_TYPE()
  return axios[method](url, formData)
}
export const jkAddAreaUpdateScreeningSchoolData_RAW_URL = function() {
  return '/jkzx/screening-task/screening-region/{screeningRegionId}/update-school-data'
}
export const jkAddAreaUpdateScreeningSchoolData_TYPE = function() {
  return 'post'
}
export const jkAddAreaUpdateScreeningSchoolData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/screening-region/{screeningRegionId}/update-school-data'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningTaskId'] !== undefined) {
    queryParameters['screeningTaskId'] = parameters['screeningTaskId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加重新审核筛查数据(存在下级地区的地区)
 * request: jkScreeningTaskAddAreaDispose
 * formData: jkScreeningTaskAddAreaDisposeFormData
 * url: jkScreeningTaskAddAreaDispose_URL
 * method: jkScreeningTaskAddAreaDispose_TYPE
 * raw_url: jkScreeningTaskAddAreaDispose_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param regionRejectList - regionRejectList
 */
export const jkScreeningTaskAddAreaDispose = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/area-dispose'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['regionRejectList'] !== undefined) {
    body = parameters['regionRejectList']
  }
  if (parameters['regionRejectList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionRejectList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskAddAreaDisposeFormData = function(formData) {
  const url = jkScreeningTaskAddAreaDispose_RAW_URL()
  const method = jkScreeningTaskAddAreaDispose_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskAddAreaDispose_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/area-dispose'
}
export const jkScreeningTaskAddAreaDispose_TYPE = function() {
  return 'post'
}
export const jkScreeningTaskAddAreaDispose_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/area-dispose'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成地区审核
 * request: jkScreeningTaskFinishAreaAudit
 * formData: jkScreeningTaskFinishAreaAuditFormData
 * url: jkScreeningTaskFinishAreaAudit_URL
 * method: jkScreeningTaskFinishAreaAudit_TYPE
 * raw_url: jkScreeningTaskFinishAreaAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishAreaAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-area-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishAreaAuditFormData = function(formData) {
  const url = jkScreeningTaskFinishAreaAudit_RAW_URL()
  const method = jkScreeningTaskFinishAreaAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishAreaAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/finish-area-audit'
}
export const jkScreeningTaskFinishAreaAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishAreaAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-area-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成汇总审核
 * request: jkScreeningTaskFinishCollectAudit
 * formData: jkScreeningTaskFinishCollectAuditFormData
 * url: jkScreeningTaskFinishCollectAudit_URL
 * method: jkScreeningTaskFinishCollectAudit_TYPE
 * raw_url: jkScreeningTaskFinishCollectAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishCollectAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-collect-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishCollectAuditFormData = function(formData) {
  const url = jkScreeningTaskFinishCollectAudit_RAW_URL()
  const method = jkScreeningTaskFinishCollectAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishCollectAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/finish-collect-audit'
}
export const jkScreeningTaskFinishCollectAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishCollectAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-collect-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成地区审核(上级地区)
 * request: jkScreeningTaskFinishHiggerAreaAudit
 * formData: jkScreeningTaskFinishHiggerAreaAuditFormData
 * url: jkScreeningTaskFinishHiggerAreaAudit_URL
 * method: jkScreeningTaskFinishHiggerAreaAudit_TYPE
 * raw_url: jkScreeningTaskFinishHiggerAreaAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishHiggerAreaAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-higger-area-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishHiggerAreaAuditFormData = function(formData) {
  const url = jkScreeningTaskFinishHiggerAreaAudit_RAW_URL()
  const method = jkScreeningTaskFinishHiggerAreaAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishHiggerAreaAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/finish-higger-area-audit'
}
export const jkScreeningTaskFinishHiggerAreaAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishHiggerAreaAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-higger-area-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成指标审核
 * request: jkScreeningTaskFinishIndicatorAudit
 * formData: jkScreeningTaskFinishIndicatorAuditFormData
 * url: jkScreeningTaskFinishIndicatorAudit_URL
 * method: jkScreeningTaskFinishIndicatorAudit_TYPE
 * raw_url: jkScreeningTaskFinishIndicatorAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishIndicatorAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-indicator-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishIndicatorAuditFormData = function(formData) {
  const url = jkScreeningTaskFinishIndicatorAudit_RAW_URL()
  const method = jkScreeningTaskFinishIndicatorAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishIndicatorAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/finish-indicator-audit'
}
export const jkScreeningTaskFinishIndicatorAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishIndicatorAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/finish-indicator-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成地区分发
 * request: jkScreeningTaskFinishedDistributeArea
 * formData: jkScreeningTaskFinishedDistributeAreaFormData
 * url: jkScreeningTaskFinishedDistributeArea_URL
 * method: jkScreeningTaskFinishedDistributeArea_TYPE
 * raw_url: jkScreeningTaskFinishedDistributeArea_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishedDistributeArea = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/finished-distribute-area'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishedDistributeAreaFormData = function(formData) {
  const url = jkScreeningTaskFinishedDistributeArea_RAW_URL()
  const method = jkScreeningTaskFinishedDistributeArea_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishedDistributeArea_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/finished-distribute-area'
}
export const jkScreeningTaskFinishedDistributeArea_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishedDistributeArea_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/finished-distribute-area'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 驳回审核筛查数据
 * request: jkScreeningTaskRejectAreaAudit
 * formData: jkScreeningTaskRejectAreaAuditFormData
 * url: jkScreeningTaskRejectAreaAudit_URL
 * method: jkScreeningTaskRejectAreaAudit_TYPE
 * raw_url: jkScreeningTaskRejectAreaAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param rejectReason - 
 */
export const jkScreeningTaskRejectAreaAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-area-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters['rejectReason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rejectReason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskRejectAreaAuditFormData = function(formData) {
  const url = jkScreeningTaskRejectAreaAudit_RAW_URL()
  const method = jkScreeningTaskRejectAreaAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskRejectAreaAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/reject-area-audit'
}
export const jkScreeningTaskRejectAreaAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskRejectAreaAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-area-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 驳回汇总审核地区数据
 * request: jkScreeningTaskRejectCollectAreaAudit
 * formData: jkScreeningTaskRejectCollectAreaAuditFormData
 * url: jkScreeningTaskRejectCollectAreaAudit_URL
 * method: jkScreeningTaskRejectCollectAreaAudit_TYPE
 * raw_url: jkScreeningTaskRejectCollectAreaAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param rejectReason - 
 */
export const jkScreeningTaskRejectCollectAreaAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-collect-area-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters['rejectReason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rejectReason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskRejectCollectAreaAuditFormData = function(formData) {
  const url = jkScreeningTaskRejectCollectAreaAudit_RAW_URL()
  const method = jkScreeningTaskRejectCollectAreaAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskRejectCollectAreaAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/reject-collect-area-audit'
}
export const jkScreeningTaskRejectCollectAreaAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskRejectCollectAreaAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-collect-area-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 驳回审核地区数据
 * request: jkScreeningTaskRejectHiggerAreaAudit
 * formData: jkScreeningTaskRejectHiggerAreaAuditFormData
 * url: jkScreeningTaskRejectHiggerAreaAudit_URL
 * method: jkScreeningTaskRejectHiggerAreaAudit_TYPE
 * raw_url: jkScreeningTaskRejectHiggerAreaAudit_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param rejectReason - 
 */
export const jkScreeningTaskRejectHiggerAreaAudit = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-higger-area-audit'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters['rejectReason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: rejectReason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskRejectHiggerAreaAuditFormData = function(formData) {
  const url = jkScreeningTaskRejectHiggerAreaAudit_RAW_URL()
  const method = jkScreeningTaskRejectHiggerAreaAudit_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskRejectHiggerAreaAudit_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/reject-higger-area-audit'
}
export const jkScreeningTaskRejectHiggerAreaAudit_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskRejectHiggerAreaAudit_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/reject-higger-area-audit'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加重新筛查(机构重新筛查)
 * request: jkScreeningTaskAddScreeningAgain
 * formData: jkScreeningTaskAddScreeningAgainFormData
 * url: jkScreeningTaskAddScreeningAgain_URL
 * method: jkScreeningTaskAddScreeningAgain_TYPE
 * raw_url: jkScreeningTaskAddScreeningAgain_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param screeningSchoolRejectList - screeningSchoolRejectList
 */
export const jkScreeningTaskAddScreeningAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/screening-again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['screeningSchoolRejectList'] !== undefined) {
    body = parameters['screeningSchoolRejectList']
  }
  if (parameters['screeningSchoolRejectList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolRejectList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskAddScreeningAgainFormData = function(formData) {
  const url = jkScreeningTaskAddScreeningAgain_RAW_URL()
  const method = jkScreeningTaskAddScreeningAgain_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskAddScreeningAgain_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/screening-again'
}
export const jkScreeningTaskAddScreeningAgain_TYPE = function() {
  return 'post'
}
export const jkScreeningTaskAddScreeningAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/screening-again'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成学校分配
 * request: jkScreeningTaskFinishDistribute
 * formData: jkScreeningTaskFinishDistributeFormData
 * url: jkScreeningTaskFinishDistribute_URL
 * method: jkScreeningTaskFinishDistribute_TYPE
 * raw_url: jkScreeningTaskFinishDistribute_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const jkScreeningTaskFinishDistribute = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/screening-task/{screeningTaskId}/screening-school/finish-distribute'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTaskFinishDistributeFormData = function(formData) {
  const url = jkScreeningTaskFinishDistribute_RAW_URL()
  const method = jkScreeningTaskFinishDistribute_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTaskFinishDistribute_RAW_URL = function() {
  return '/jkzx/screening-task/{screeningTaskId}/screening-school/finish-distribute'
}
export const jkScreeningTaskFinishDistribute_TYPE = function() {
  return 'put'
}
export const jkScreeningTaskFinishDistribute_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/screening-task/{screeningTaskId}/screening-school/finish-distribute'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学校环境健康影响因素调查表
 * request: jkSehiqSave
 * formData: jkSehiqSaveFormData
 * url: jkSehiqSave_URL
 * method: jkSehiqSave_TYPE
 * raw_url: jkSehiqSave_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param vo - vo
 */
export const jkSehiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/sehiq/save/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkSehiqSaveFormData = function(formData) {
  const url = jkSehiqSave_RAW_URL()
  const method = jkSehiqSave_TYPE()
  return axios[method](url, formData)
}
export const jkSehiqSave_RAW_URL = function() {
  return '/jkzx/sehiq/save/{screeningSchoolId}'
}
export const jkSehiqSave_TYPE = function() {
  return 'post'
}
export const jkSehiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/sehiq/save/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校环境健康影响因素调查表详情
 * request: jkSehiqFind
 * formData: jkSehiqFindFormData
 * url: jkSehiqFind_URL
 * method: jkSehiqFind_TYPE
 * raw_url: jkSehiqFind_RAW_URL
 * @param id - id
 */
export const jkSehiqFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/sehiq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkSehiqFindFormData = function(formData) {
  const url = jkSehiqFind_RAW_URL()
  const method = jkSehiqFind_TYPE()
  return axios[method](url, formData)
}
export const jkSehiqFind_RAW_URL = function() {
  return '/jkzx/sehiq/{id}'
}
export const jkSehiqFind_TYPE = function() {
  return 'get'
}
export const jkSehiqFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/sehiq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区获取学校环境健康影响因素调查表详情列表
 * request: jkSehiqListByScreeningRegion
 * formData: jkSehiqListByScreeningRegionFormData
 * url: jkSehiqListByScreeningRegion_URL
 * method: jkSehiqListByScreeningRegion_TYPE
 * raw_url: jkSehiqListByScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkSehiqListByScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/sehiq/{screeningPlanId}/by-screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkSehiqListByScreeningRegionFormData = function(formData) {
  const url = jkSehiqListByScreeningRegion_RAW_URL()
  const method = jkSehiqListByScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkSehiqListByScreeningRegion_RAW_URL = function() {
  return '/jkzx/sehiq/{screeningPlanId}/by-screening-region'
}
export const jkSehiqListByScreeningRegion_TYPE = function() {
  return 'get'
}
export const jkSehiqListByScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/sehiq/{screeningPlanId}/by-screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看任务指标
 * request: jkTaskIndicatorFind
 * formData: jkTaskIndicatorFindFormData
 * url: jkTaskIndicatorFind_URL
 * method: jkTaskIndicatorFind_TYPE
 * raw_url: jkTaskIndicatorFind_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorFindFormData = function(formData) {
  const url = jkTaskIndicatorFind_RAW_URL()
  const method = jkTaskIndicatorFind_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorFind_RAW_URL = function() {
  return '/jkzx/task-indicator'
}
export const jkTaskIndicatorFind_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存任务指标
 * request: jkTaskIndicatorSave
 * formData: jkTaskIndicatorSaveFormData
 * url: jkTaskIndicatorSave_URL
 * method: jkTaskIndicatorSave_TYPE
 * raw_url: jkTaskIndicatorSave_RAW_URL
 * @param vo - vo
 */
export const jkTaskIndicatorSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorSaveFormData = function(formData) {
  const url = jkTaskIndicatorSave_RAW_URL()
  const method = jkTaskIndicatorSave_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorSave_RAW_URL = function() {
  return '/jkzx/task-indicator'
}
export const jkTaskIndicatorSave_TYPE = function() {
  return 'post'
}
export const jkTaskIndicatorSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: jkTaskIndicatorDubiousStudent
 * formData: jkTaskIndicatorDubiousStudentFormData
 * url: jkTaskIndicatorDubiousStudent_URL
 * method: jkTaskIndicatorDubiousStudent_TYPE
 * raw_url: jkTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const jkTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/dubious-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = jkTaskIndicatorDubiousStudent_RAW_URL()
  const method = jkTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/jkzx/task-indicator/dubious-student/{screeningSchoolId}'
}
export const jkTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/dubious-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看年级指标和班级
 * request: jkTaskIndicatorFindForGradeStudent
 * formData: jkTaskIndicatorFindForGradeStudentFormData
 * url: jkTaskIndicatorFindForGradeStudent_URL
 * method: jkTaskIndicatorFindForGradeStudent_TYPE
 * raw_url: jkTaskIndicatorFindForGradeStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jkTaskIndicatorFindForGradeStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/grade-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorFindForGradeStudentFormData = function(formData) {
  const url = jkTaskIndicatorFindForGradeStudent_RAW_URL()
  const method = jkTaskIndicatorFindForGradeStudent_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorFindForGradeStudent_RAW_URL = function() {
  return '/jkzx/task-indicator/grade-student/{screeningSchoolId}'
}
export const jkTaskIndicatorFindForGradeStudent_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorFindForGradeStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/grade-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看计划区域指标
 * request: jkTaskIndicatorFindPlanRegion
 * formData: jkTaskIndicatorFindPlanRegionFormData
 * url: jkTaskIndicatorFindPlanRegion_URL
 * method: jkTaskIndicatorFindPlanRegion_TYPE
 * raw_url: jkTaskIndicatorFindPlanRegion_RAW_URL
 * @param planId - planId
 */
export const jkTaskIndicatorFindPlanRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/plan/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorFindPlanRegionFormData = function(formData) {
  const url = jkTaskIndicatorFindPlanRegion_RAW_URL()
  const method = jkTaskIndicatorFindPlanRegion_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorFindPlanRegion_RAW_URL = function() {
  return '/jkzx/task-indicator/plan/{planId}'
}
export const jkTaskIndicatorFindPlanRegion_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorFindPlanRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/plan/{planId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学校类型指标进度
 * request: jkTaskIndicatorSchoolDegree
 * formData: jkTaskIndicatorSchoolDegreeFormData
 * url: jkTaskIndicatorSchoolDegree_URL
 * method: jkTaskIndicatorSchoolDegree_TYPE
 * raw_url: jkTaskIndicatorSchoolDegree_RAW_URL
 * @param planId - planId
 */
export const jkTaskIndicatorSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/plan/{planId}/school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorSchoolDegreeFormData = function(formData) {
  const url = jkTaskIndicatorSchoolDegree_RAW_URL()
  const method = jkTaskIndicatorSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorSchoolDegree_RAW_URL = function() {
  return '/jkzx/task-indicator/plan/{planId}/school-degree'
}
export const jkTaskIndicatorSchoolDegree_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/plan/{planId}/school-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学生指标进度
 * request: jkTaskIndicatorStudentDegree
 * formData: jkTaskIndicatorStudentDegreeFormData
 * url: jkTaskIndicatorStudentDegree_URL
 * method: jkTaskIndicatorStudentDegree_TYPE
 * raw_url: jkTaskIndicatorStudentDegree_RAW_URL
 * @param planId - planId
 */
export const jkTaskIndicatorStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/plan/{planId}/student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorStudentDegreeFormData = function(formData) {
  const url = jkTaskIndicatorStudentDegree_RAW_URL()
  const method = jkTaskIndicatorStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorStudentDegree_RAW_URL = function() {
  return '/jkzx/task-indicator/plan/{planId}/student-degree'
}
export const jkTaskIndicatorStudentDegree_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/plan/{planId}/student-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看区域指标
 * request: jkTaskIndicatorFindForRegion
 * formData: jkTaskIndicatorFindForRegionFormData
 * url: jkTaskIndicatorFindForRegion_URL
 * method: jkTaskIndicatorFindForRegion_TYPE
 * raw_url: jkTaskIndicatorFindForRegion_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorFindForRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorFindForRegionFormData = function(formData) {
  const url = jkTaskIndicatorFindForRegion_RAW_URL()
  const method = jkTaskIndicatorFindForRegion_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorFindForRegion_RAW_URL = function() {
  return '/jkzx/task-indicator/region/{screeningRegionId}'
}
export const jkTaskIndicatorFindForRegion_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorFindForRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看学校指标
 * request: jkTaskIndicatorFindForSchool
 * formData: jkTaskIndicatorFindForSchoolFormData
 * url: jkTaskIndicatorFindForSchool_URL
 * method: jkTaskIndicatorFindForSchool_TYPE
 * raw_url: jkTaskIndicatorFindForSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorFindForSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/school/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorFindForSchoolFormData = function(formData) {
  const url = jkTaskIndicatorFindForSchool_RAW_URL()
  const method = jkTaskIndicatorFindForSchool_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorFindForSchool_RAW_URL = function() {
  return '/jkzx/task-indicator/school/{screeningRegionId}'
}
export const jkTaskIndicatorFindForSchool_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorFindForSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/school/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学校类型指标进度
 * request: jkTaskIndicatorCityRegionSchoolDegree
 * formData: jkTaskIndicatorCityRegionSchoolDegreeFormData
 * url: jkTaskIndicatorCityRegionSchoolDegree_URL
 * method: jkTaskIndicatorCityRegionSchoolDegree_TYPE
 * raw_url: jkTaskIndicatorCityRegionSchoolDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorCityRegionSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorCityRegionSchoolDegreeFormData = function(formData) {
  const url = jkTaskIndicatorCityRegionSchoolDegree_RAW_URL()
  const method = jkTaskIndicatorCityRegionSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorCityRegionSchoolDegree_RAW_URL = function() {
  return '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
}
export const jkTaskIndicatorCityRegionSchoolDegree_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorCityRegionSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学生类型指标进度
 * request: jkTaskIndicatorCityRegionStudentDegree
 * formData: jkTaskIndicatorCityRegionStudentDegreeFormData
 * url: jkTaskIndicatorCityRegionStudentDegree_URL
 * method: jkTaskIndicatorCityRegionStudentDegree_TYPE
 * raw_url: jkTaskIndicatorCityRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorCityRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorCityRegionStudentDegreeFormData = function(formData) {
  const url = jkTaskIndicatorCityRegionStudentDegree_RAW_URL()
  const method = jkTaskIndicatorCityRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorCityRegionStudentDegree_RAW_URL = function() {
  return '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
}
export const jkTaskIndicatorCityRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorCityRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查区级地区学生类型指标进度
 * request: jkTaskIndicatorDistrictRegionStudentDegree
 * formData: jkTaskIndicatorDistrictRegionStudentDegreeFormData
 * url: jkTaskIndicatorDistrictRegionStudentDegree_URL
 * method: jkTaskIndicatorDistrictRegionStudentDegree_TYPE
 * raw_url: jkTaskIndicatorDistrictRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jkTaskIndicatorDistrictRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTaskIndicatorDistrictRegionStudentDegreeFormData = function(formData) {
  const url = jkTaskIndicatorDistrictRegionStudentDegree_RAW_URL()
  const method = jkTaskIndicatorDistrictRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jkTaskIndicatorDistrictRegionStudentDegree_RAW_URL = function() {
  return '/jkzx/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
}
export const jkTaskIndicatorDistrictRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const jkTaskIndicatorDistrictRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队列表
 * request: jkScreeningTeamList
 * formData: jkScreeningTeamListFormData
 * url: jkScreeningTeamList_URL
 * method: jkScreeningTeamList_TYPE
 * raw_url: jkScreeningTeamList_RAW_URL
 * @param name - 机构名称
 * @param onlyWorkerWaitAudited - 待审核工作人员
 * @param onlyDeviceWaitAudited - 待审核设备
 * @param cityCode - 
 * @param districtCode - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningTeamList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['onlyWorkerWaitAudited'] !== undefined) {
    queryParameters['onlyWorkerWaitAudited'] = parameters['onlyWorkerWaitAudited']
  }
  if (parameters['onlyDeviceWaitAudited'] !== undefined) {
    queryParameters['onlyDeviceWaitAudited'] = parameters['onlyDeviceWaitAudited']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamListFormData = function(formData) {
  const url = jkScreeningTeamList_RAW_URL()
  const method = jkScreeningTeamList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamList_RAW_URL = function() {
  return '/jkzx/team'
}
export const jkScreeningTeamList_TYPE = function() {
  return 'get'
}
export const jkScreeningTeamList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['onlyWorkerWaitAudited'] !== undefined) {
    queryParameters['onlyWorkerWaitAudited'] = parameters['onlyWorkerWaitAudited']
  }
  if (parameters['onlyDeviceWaitAudited'] !== undefined) {
    queryParameters['onlyDeviceWaitAudited'] = parameters['onlyDeviceWaitAudited']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查团队
 * request: jkScreeningTeamAdd
 * formData: jkScreeningTeamAddFormData
 * url: jkScreeningTeamAdd_URL
 * method: jkScreeningTeamAdd_TYPE
 * raw_url: jkScreeningTeamAdd_RAW_URL
 * @param name - 筛查团队名称
 * @param managerId - 管理员ID
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 */
export const jkScreeningTeamAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamAddFormData = function(formData) {
  const url = jkScreeningTeamAdd_RAW_URL()
  const method = jkScreeningTeamAdd_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamAdd_RAW_URL = function() {
  return '/jkzx/team'
}
export const jkScreeningTeamAdd_TYPE = function() {
  return 'post'
}
export const jkScreeningTeamAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队详情
 * request: jkScreeningTeamFind
 * formData: jkScreeningTeamFindFormData
 * url: jkScreeningTeamFind_URL
 * method: jkScreeningTeamFind_TYPE
 * raw_url: jkScreeningTeamFind_RAW_URL
 * @param id - id
 */
export const jkScreeningTeamFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamFindFormData = function(formData) {
  const url = jkScreeningTeamFind_RAW_URL()
  const method = jkScreeningTeamFind_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamFind_RAW_URL = function() {
  return '/jkzx/team/{id}'
}
export const jkScreeningTeamFind_TYPE = function() {
  return 'get'
}
export const jkScreeningTeamFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查团队
 * request: jkScreeningTeamUpdate
 * formData: jkScreeningTeamUpdateFormData
 * url: jkScreeningTeamUpdate_URL
 * method: jkScreeningTeamUpdate_TYPE
 * raw_url: jkScreeningTeamUpdate_RAW_URL
 * @param id - id
 * @param name - 筛查团队名称
 * @param managerId - 管理员ID
 */
export const jkScreeningTeamUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamUpdateFormData = function(formData) {
  const url = jkScreeningTeamUpdate_RAW_URL()
  const method = jkScreeningTeamUpdate_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamUpdate_RAW_URL = function() {
  return '/jkzx/team/{id}'
}
export const jkScreeningTeamUpdate_TYPE = function() {
  return 'put'
}
export const jkScreeningTeamUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用筛查团队
 * request: jkScreeningTeamDisable
 * formData: jkScreeningTeamDisableFormData
 * url: jkScreeningTeamDisable_URL
 * method: jkScreeningTeamDisable_TYPE
 * raw_url: jkScreeningTeamDisable_RAW_URL
 * @param id - id
 */
export const jkScreeningTeamDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamDisableFormData = function(formData) {
  const url = jkScreeningTeamDisable_RAW_URL()
  const method = jkScreeningTeamDisable_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamDisable_RAW_URL = function() {
  return '/jkzx/team/{id}/disable'
}
export const jkScreeningTeamDisable_TYPE = function() {
  return 'put'
}
export const jkScreeningTeamDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用筛查团队
 * request: jkScreeningTeamEnable
 * formData: jkScreeningTeamEnableFormData
 * url: jkScreeningTeamEnable_URL
 * method: jkScreeningTeamEnable_TYPE
 * raw_url: jkScreeningTeamEnable_RAW_URL
 * @param id - id
 */
export const jkScreeningTeamEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/team/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningTeamEnableFormData = function(formData) {
  const url = jkScreeningTeamEnable_RAW_URL()
  const method = jkScreeningTeamEnable_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningTeamEnable_RAW_URL = function() {
  return '/jkzx/team/{id}/enable'
}
export const jkScreeningTeamEnable_TYPE = function() {
  return 'put'
}
export const jkScreeningTeamEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/team/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存教学环境卫生监测调查表
 * request: jkTehmqSave
 * formData: jkTehmqSaveFormData
 * url: jkTehmqSave_URL
 * method: jkTehmqSave_TYPE
 * raw_url: jkTehmqSave_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param vo - vo
 */
export const jkTehmqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/tehmq/save/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jkTehmqSaveFormData = function(formData) {
  const url = jkTehmqSave_RAW_URL()
  const method = jkTehmqSave_TYPE()
  return axios[method](url, formData)
}
export const jkTehmqSave_RAW_URL = function() {
  return '/jkzx/tehmq/save/{screeningSchoolId}'
}
export const jkTehmqSave_TYPE = function() {
  return 'post'
}
export const jkTehmqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/tehmq/save/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 教学环境卫生监测调查表详情
 * request: jkTehmqFind
 * formData: jkTehmqFindFormData
 * url: jkTehmqFind_URL
 * method: jkTehmqFind_TYPE
 * raw_url: jkTehmqFind_RAW_URL
 * @param id - id
 */
export const jkTehmqFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/tehmq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTehmqFindFormData = function(formData) {
  const url = jkTehmqFind_RAW_URL()
  const method = jkTehmqFind_TYPE()
  return axios[method](url, formData)
}
export const jkTehmqFind_RAW_URL = function() {
  return '/jkzx/tehmq/{id}'
}
export const jkTehmqFind_TYPE = function() {
  return 'get'
}
export const jkTehmqFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/tehmq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区获取学校环境健康影响因素调查表详情列表
 * request: jkTehmqListByScreeningRegion
 * formData: jkTehmqListByScreeningRegionFormData
 * url: jkTehmqListByScreeningRegion_URL
 * method: jkTehmqListByScreeningRegion_TYPE
 * raw_url: jkTehmqListByScreeningRegion_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const jkTehmqListByScreeningRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/tehmq/{screeningPlanId}/by-screening-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkTehmqListByScreeningRegionFormData = function(formData) {
  const url = jkTehmqListByScreeningRegion_RAW_URL()
  const method = jkTehmqListByScreeningRegion_TYPE()
  return axios[method](url, formData)
}
export const jkTehmqListByScreeningRegion_RAW_URL = function() {
  return '/jkzx/tehmq/{screeningPlanId}/by-screening-region'
}
export const jkTehmqListByScreeningRegion_TYPE = function() {
  return 'get'
}
export const jkTehmqListByScreeningRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/tehmq/{screeningPlanId}/by-screening-region'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员列表
 * request: jkScreeningWorkerList
 * formData: jkScreeningWorkerListFormData
 * url: jkScreeningWorkerList_URL
 * method: jkScreeningWorkerList_TYPE
 * raw_url: jkScreeningWorkerList_RAW_URL
 * @param screeningTeamId - 筛查团队ID
 * @param status - 状态
 * @param screeningPlanId - 筛查计划ID
 * @param screeningTeamIdStr - 
 * @param name - 姓名
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jkScreeningWorkerList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/worker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamIdStr'] !== undefined) {
    queryParameters['screeningTeamIdStr'] = parameters['screeningTeamIdStr']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jkScreeningWorkerListFormData = function(formData) {
  const url = jkScreeningWorkerList_RAW_URL()
  const method = jkScreeningWorkerList_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningWorkerList_RAW_URL = function() {
  return '/jkzx/worker'
}
export const jkScreeningWorkerList_TYPE = function() {
  return 'get'
}
export const jkScreeningWorkerList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/worker'
  if (parameters['screeningTeamId'] !== undefined) {
    queryParameters['screeningTeamId'] = parameters['screeningTeamId']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningTeamIdStr'] !== undefined) {
    queryParameters['screeningTeamIdStr'] = parameters['screeningTeamIdStr']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员通过审核
 * request: jkScreeningWorkerPass
 * formData: jkScreeningWorkerPassFormData
 * url: jkScreeningWorkerPass_URL
 * method: jkScreeningWorkerPass_TYPE
 * raw_url: jkScreeningWorkerPass_RAW_URL
 * @param id - id
 */
export const jkScreeningWorkerPass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/worker/{id}/pass'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningWorkerPassFormData = function(formData) {
  const url = jkScreeningWorkerPass_RAW_URL()
  const method = jkScreeningWorkerPass_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningWorkerPass_RAW_URL = function() {
  return '/jkzx/worker/{id}/pass'
}
export const jkScreeningWorkerPass_TYPE = function() {
  return 'put'
}
export const jkScreeningWorkerPass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/worker/{id}/pass'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员驳回
 * request: jkScreeningWorkerReject
 * formData: jkScreeningWorkerRejectFormData
 * url: jkScreeningWorkerReject_URL
 * method: jkScreeningWorkerReject_TYPE
 * raw_url: jkScreeningWorkerReject_RAW_URL
 * @param id - id
 * @param reason - 原因
 */
export const jkScreeningWorkerReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jkzx/worker/{id}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['reason'] !== undefined) {
    form['reason'] = parameters['reason']
  }
  if (parameters['reason'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reason'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jkScreeningWorkerRejectFormData = function(formData) {
  const url = jkScreeningWorkerReject_RAW_URL()
  const method = jkScreeningWorkerReject_TYPE()
  return axios[method](url, formData)
}
export const jkScreeningWorkerReject_RAW_URL = function() {
  return '/jkzx/worker/{id}/reject'
}
export const jkScreeningWorkerReject_TYPE = function() {
  return 'put'
}
export const jkScreeningWorkerReject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jkzx/worker/{id}/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: jyjAdministratorList
 * formData: jyjAdministratorListFormData
 * url: jyjAdministratorList_URL
 * method: jyjAdministratorList_TYPE
 * raw_url: jyjAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorListFormData = function(formData) {
  const url = jyjAdministratorList_RAW_URL()
  const method = jyjAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorList_RAW_URL = function() {
  return '/jyj/administrator'
}
export const jyjAdministratorList_TYPE = function() {
  return 'get'
}
export const jyjAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: jyjAdministratorAdd
 * formData: jyjAdministratorAddFormData
 * url: jyjAdministratorAdd_URL
 * method: jyjAdministratorAdd_TYPE
 * raw_url: jyjAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const jyjAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorAddFormData = function(formData) {
  const url = jyjAdministratorAdd_RAW_URL()
  const method = jyjAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorAdd_RAW_URL = function() {
  return '/jyj/administrator'
}
export const jyjAdministratorAdd_TYPE = function() {
  return 'post'
}
export const jyjAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 全部管理员选择列表
 * request: jyjAdministratorAllChoiceList
 * formData: jyjAdministratorAllChoiceListFormData
 * url: jyjAdministratorAllChoiceList_URL
 * method: jyjAdministratorAllChoiceList_TYPE
 * raw_url: jyjAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const jyjAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorAllChoiceListFormData = function(formData) {
  const url = jyjAdministratorAllChoiceList_RAW_URL()
  const method = jyjAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorAllChoiceList_RAW_URL = function() {
  return '/jyj/administrator/all-choice'
}
export const jyjAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const jyjAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员选择列表
 * request: jyjAdministratorChoiceList
 * formData: jyjAdministratorChoiceListFormData
 * url: jyjAdministratorChoiceList_URL
 * method: jyjAdministratorChoiceList_TYPE
 * raw_url: jyjAdministratorChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const jyjAdministratorChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorChoiceListFormData = function(formData) {
  const url = jyjAdministratorChoiceList_RAW_URL()
  const method = jyjAdministratorChoiceList_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorChoiceList_RAW_URL = function() {
  return '/jyj/administrator/choice'
}
export const jyjAdministratorChoiceList_TYPE = function() {
  return 'get'
}
export const jyjAdministratorChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: jyjImportAdministrator
 * formData: jyjImportAdministratorFormData
 * url: jyjImportAdministrator_URL
 * method: jyjImportAdministrator_TYPE
 * raw_url: jyjImportAdministrator_RAW_URL
 * @param file - 
 */
export const jyjImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjImportAdministratorFormData = function(formData) {
  const url = jyjImportAdministrator_RAW_URL()
  const method = jyjImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const jyjImportAdministrator_RAW_URL = function() {
  return '/jyj/administrator/import'
}
export const jyjImportAdministrator_TYPE = function() {
  return 'post'
}
export const jyjImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: jyjAdministratorDelete
 * formData: jyjAdministratorDeleteFormData
 * url: jyjAdministratorDelete_URL
 * method: jyjAdministratorDelete_TYPE
 * raw_url: jyjAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const jyjAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorDeleteFormData = function(formData) {
  const url = jyjAdministratorDelete_RAW_URL()
  const method = jyjAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorDelete_RAW_URL = function() {
  return '/jyj/administrator/{administratorId}'
}
export const jyjAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const jyjAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: jyjAdministratorListRole
 * formData: jyjAdministratorListRoleFormData
 * url: jyjAdministratorListRole_URL
 * method: jyjAdministratorListRole_TYPE
 * raw_url: jyjAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const jyjAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorListRoleFormData = function(formData) {
  const url = jyjAdministratorListRole_RAW_URL()
  const method = jyjAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorListRole_RAW_URL = function() {
  return '/jyj/administrator/{administratorId}/role'
}
export const jyjAdministratorListRole_TYPE = function() {
  return 'get'
}
export const jyjAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: jyjAdministratorAddRole
 * formData: jyjAdministratorAddRoleFormData
 * url: jyjAdministratorAddRole_URL
 * method: jyjAdministratorAddRole_TYPE
 * raw_url: jyjAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const jyjAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorAddRoleFormData = function(formData) {
  const url = jyjAdministratorAddRole_RAW_URL()
  const method = jyjAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorAddRole_RAW_URL = function() {
  return '/jyj/administrator/{administratorId}/role'
}
export const jyjAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const jyjAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: jyjAdministratorDeleteRole
 * formData: jyjAdministratorDeleteRoleFormData
 * url: jyjAdministratorDeleteRole_URL
 * method: jyjAdministratorDeleteRole_TYPE
 * raw_url: jyjAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const jyjAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/administrator/{administratorId}/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jyjAdministratorDeleteRoleFormData = function(formData) {
  const url = jyjAdministratorDeleteRole_RAW_URL()
  const method = jyjAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const jyjAdministratorDeleteRole_RAW_URL = function() {
  return '/jyj/administrator/{administratorId}/role/{roleId}'
}
export const jyjAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const jyjAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/administrator/{administratorId}/role/{roleId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 教育部门添加
 * request: jyjGovernmentSectorAdd
 * formData: jyjGovernmentSectorAddFormData
 * url: jyjGovernmentSectorAdd_URL
 * method: jyjGovernmentSectorAdd_TYPE
 * raw_url: jyjGovernmentSectorAdd_RAW_URL
 * @param regionType - 地区类型
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param managerId - 管理员ID
 */
export const jyjGovernmentSectorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/government-sector'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionType'] !== undefined) {
    form['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjGovernmentSectorAddFormData = function(formData) {
  const url = jyjGovernmentSectorAdd_RAW_URL()
  const method = jyjGovernmentSectorAdd_TYPE()
  return axios[method](url, formData)
}
export const jyjGovernmentSectorAdd_RAW_URL = function() {
  return '/jyj/government-sector'
}
export const jyjGovernmentSectorAdd_TYPE = function() {
  return 'post'
}
export const jyjGovernmentSectorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/government-sector'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 教育部门
 * request: jyjGovernmentSectorFindAndChildren
 * formData: jyjGovernmentSectorFindAndChildrenFormData
 * url: jyjGovernmentSectorFindAndChildren_URL
 * method: jyjGovernmentSectorFindAndChildren_TYPE
 * raw_url: jyjGovernmentSectorFindAndChildren_RAW_URL
 */
export const jyjGovernmentSectorFindAndChildren = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/government-sector/children'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjGovernmentSectorFindAndChildrenFormData = function(formData) {
  const url = jyjGovernmentSectorFindAndChildren_RAW_URL()
  const method = jyjGovernmentSectorFindAndChildren_TYPE()
  return axios[method](url, formData)
}
export const jyjGovernmentSectorFindAndChildren_RAW_URL = function() {
  return '/jyj/government-sector/children'
}
export const jyjGovernmentSectorFindAndChildren_TYPE = function() {
  return 'get'
}
export const jyjGovernmentSectorFindAndChildren_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/government-sector/children'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 卫健部门转让负责人
 * request: jyjGovernmentSectorUpdateManager
 * formData: jyjGovernmentSectorUpdateManagerFormData
 * url: jyjGovernmentSectorUpdateManager_URL
 * method: jyjGovernmentSectorUpdateManager_TYPE
 * raw_url: jyjGovernmentSectorUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const jyjGovernmentSectorUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/government-sector/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjGovernmentSectorUpdateManagerFormData = function(formData) {
  const url = jyjGovernmentSectorUpdateManager_RAW_URL()
  const method = jyjGovernmentSectorUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const jyjGovernmentSectorUpdateManager_RAW_URL = function() {
  return '/jyj/government-sector/manager'
}
export const jyjGovernmentSectorUpdateManager_TYPE = function() {
  return 'put'
}
export const jyjGovernmentSectorUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/government-sector/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 教育部门详情
 * request: jyjGovernmentSectorFind
 * formData: jyjGovernmentSectorFindFormData
 * url: jyjGovernmentSectorFind_URL
 * method: jyjGovernmentSectorFind_TYPE
 * raw_url: jyjGovernmentSectorFind_RAW_URL
 * @param governmentSectorId - governmentSectorId
 */
export const jyjGovernmentSectorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/government-sector/{governmentSectorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjGovernmentSectorFindFormData = function(formData) {
  const url = jyjGovernmentSectorFind_RAW_URL()
  const method = jyjGovernmentSectorFind_TYPE()
  return axios[method](url, formData)
}
export const jyjGovernmentSectorFind_RAW_URL = function() {
  return '/jyj/government-sector/{governmentSectorId}'
}
export const jyjGovernmentSectorFind_TYPE = function() {
  return 'get'
}
export const jyjGovernmentSectorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/government-sector/{governmentSectorId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改教育部门管理员
 * request: jyjGovernmentSectorUpdate
 * formData: jyjGovernmentSectorUpdateFormData
 * url: jyjGovernmentSectorUpdate_URL
 * method: jyjGovernmentSectorUpdate_TYPE
 * raw_url: jyjGovernmentSectorUpdate_RAW_URL
 * @param governmentSectorId - governmentSectorId
 * @param managerId - managerId
 */
export const jyjGovernmentSectorUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/government-sector/{governmentSectorId}/administrator/{managerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjGovernmentSectorUpdateFormData = function(formData) {
  const url = jyjGovernmentSectorUpdate_RAW_URL()
  const method = jyjGovernmentSectorUpdate_TYPE()
  return axios[method](url, formData)
}
export const jyjGovernmentSectorUpdate_RAW_URL = function() {
  return '/jyj/government-sector/{governmentSectorId}/administrator/{managerId}'
}
export const jyjGovernmentSectorUpdate_TYPE = function() {
  return 'put'
}
export const jyjGovernmentSectorUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/government-sector/{governmentSectorId}/administrator/{managerId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 年级列表
 * request: jyjGradeConfigList
 * formData: jyjGradeConfigListFormData
 * url: jyjGradeConfigList_URL
 * method: jyjGradeConfigList_TYPE
 * raw_url: jyjGradeConfigList_RAW_URL
 * @param schoolType - 学校类型
 * @param code - 编码
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjGradeConfigList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/grade-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjGradeConfigListFormData = function(formData) {
  const url = jyjGradeConfigList_RAW_URL()
  const method = jyjGradeConfigList_TYPE()
  return axios[method](url, formData)
}
export const jyjGradeConfigList_RAW_URL = function() {
  return '/jyj/grade-config'
}
export const jyjGradeConfigList_TYPE = function() {
  return 'get'
}
export const jyjGradeConfigList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/grade-config'
  if (parameters['schoolType'] !== undefined) {
    queryParameters['schoolType'] = parameters['schoolType']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加年级
 * request: jyjGradeConfigAdd
 * formData: jyjGradeConfigAddFormData
 * url: jyjGradeConfigAdd_URL
 * method: jyjGradeConfigAdd_TYPE
 * raw_url: jyjGradeConfigAdd_RAW_URL
 * @param name - 
 * @param code - 
 * @param schoolType - 
 * @param sortNum - 排序号
 */
export const jyjGradeConfigAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/grade-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjGradeConfigAddFormData = function(formData) {
  const url = jyjGradeConfigAdd_RAW_URL()
  const method = jyjGradeConfigAdd_TYPE()
  return axios[method](url, formData)
}
export const jyjGradeConfigAdd_RAW_URL = function() {
  return '/jyj/grade-config'
}
export const jyjGradeConfigAdd_TYPE = function() {
  return 'post'
}
export const jyjGradeConfigAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/grade-config'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取年级排序号
 * request: jyjGradeConfigSortNum
 * formData: jyjGradeConfigSortNumFormData
 * url: jyjGradeConfigSortNum_URL
 * method: jyjGradeConfigSortNum_TYPE
 * raw_url: jyjGradeConfigSortNum_RAW_URL
 */
export const jyjGradeConfigSortNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/grade-config/sort-num'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjGradeConfigSortNumFormData = function(formData) {
  const url = jyjGradeConfigSortNum_RAW_URL()
  const method = jyjGradeConfigSortNum_TYPE()
  return axios[method](url, formData)
}
export const jyjGradeConfigSortNum_RAW_URL = function() {
  return '/jyj/grade-config/sort-num'
}
export const jyjGradeConfigSortNum_TYPE = function() {
  return 'put'
}
export const jyjGradeConfigSortNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/grade-config/sort-num'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 编辑年级
 * request: jyjGradeConfigUpdate
 * formData: jyjGradeConfigUpdateFormData
 * url: jyjGradeConfigUpdate_URL
 * method: jyjGradeConfigUpdate_TYPE
 * raw_url: jyjGradeConfigUpdate_RAW_URL
 * @param gradeConfigId - gradeConfigId
 * @param name - 
 * @param code - 
 * @param schoolType - 
 * @param sortNum - 排序号
 */
export const jyjGradeConfigUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/grade-config/{gradeConfigId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{gradeConfigId}', `${parameters['gradeConfigId']}`)
  if (parameters['gradeConfigId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeConfigId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjGradeConfigUpdateFormData = function(formData) {
  const url = jyjGradeConfigUpdate_RAW_URL()
  const method = jyjGradeConfigUpdate_TYPE()
  return axios[method](url, formData)
}
export const jyjGradeConfigUpdate_RAW_URL = function() {
  return '/jyj/grade-config/{gradeConfigId}'
}
export const jyjGradeConfigUpdate_TYPE = function() {
  return 'put'
}
export const jyjGradeConfigUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/grade-config/{gradeConfigId}'
  path = path.replace('{gradeConfigId}', `${parameters['gradeConfigId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: jyjRoleList
 * formData: jyjRoleListFormData
 * url: jyjRoleList_URL
 * method: jyjRoleList_TYPE
 * raw_url: jyjRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjRoleListFormData = function(formData) {
  const url = jyjRoleList_RAW_URL()
  const method = jyjRoleList_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleList_RAW_URL = function() {
  return '/jyj/role'
}
export const jyjRoleList_TYPE = function() {
  return 'get'
}
export const jyjRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: jyjRoleAdd
 * formData: jyjRoleAddFormData
 * url: jyjRoleAdd_URL
 * method: jyjRoleAdd_TYPE
 * raw_url: jyjRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const jyjRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjRoleAddFormData = function(formData) {
  const url = jyjRoleAdd_RAW_URL()
  const method = jyjRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleAdd_RAW_URL = function() {
  return '/jyj/role'
}
export const jyjRoleAdd_TYPE = function() {
  return 'post'
}
export const jyjRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: jyjRoleListFunction
 * formData: jyjRoleListFunctionFormData
 * url: jyjRoleListFunction_URL
 * method: jyjRoleListFunction_TYPE
 * raw_url: jyjRoleListFunction_RAW_URL
 */
export const jyjRoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjRoleListFunctionFormData = function(formData) {
  const url = jyjRoleListFunction_RAW_URL()
  const method = jyjRoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleListFunction_RAW_URL = function() {
  return '/jyj/role/function'
}
export const jyjRoleListFunction_TYPE = function() {
  return 'get'
}
export const jyjRoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: jyjRoleFind
 * formData: jyjRoleFindFormData
 * url: jyjRoleFind_URL
 * method: jyjRoleFind_TYPE
 * raw_url: jyjRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const jyjRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjRoleFindFormData = function(formData) {
  const url = jyjRoleFind_RAW_URL()
  const method = jyjRoleFind_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleFind_RAW_URL = function() {
  return '/jyj/role/{roleId}'
}
export const jyjRoleFind_TYPE = function() {
  return 'get'
}
export const jyjRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: jyjRoleUpdate
 * formData: jyjRoleUpdateFormData
 * url: jyjRoleUpdate_URL
 * method: jyjRoleUpdate_TYPE
 * raw_url: jyjRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const jyjRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjRoleUpdateFormData = function(formData) {
  const url = jyjRoleUpdate_RAW_URL()
  const method = jyjRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleUpdate_RAW_URL = function() {
  return '/jyj/role/{roleId}'
}
export const jyjRoleUpdate_TYPE = function() {
  return 'put'
}
export const jyjRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: jyjRoleDelete
 * formData: jyjRoleDeleteFormData
 * url: jyjRoleDelete_URL
 * method: jyjRoleDelete_TYPE
 * raw_url: jyjRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const jyjRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const jyjRoleDeleteFormData = function(formData) {
  const url = jyjRoleDelete_RAW_URL()
  const method = jyjRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const jyjRoleDelete_RAW_URL = function() {
  return '/jyj/role/{roleId}'
}
export const jyjRoleDelete_TYPE = function() {
  return 'delete'
}
export const jyjRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校列表
 * request: jyjSchoolList
 * formData: jyjSchoolListFormData
 * url: jyjSchoolList_URL
 * method: jyjSchoolList_TYPE
 * raw_url: jyjSchoolList_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param type - 学校类型
 * @param name - 学校名称
 * @param code - 学校编码
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjSchoolList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolListFormData = function(formData) {
  const url = jyjSchoolList_RAW_URL()
  const method = jyjSchoolList_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolList_RAW_URL = function() {
  return '/jyj/school'
}
export const jyjSchoolList_TYPE = function() {
  return 'get'
}
export const jyjSchoolList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school'
  if (parameters['provinceCode'] !== undefined) {
    queryParameters['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加学校
 * request: jyjSchoolAdd
 * formData: jyjSchoolAddFormData
 * url: jyjSchoolAdd_URL
 * method: jyjSchoolAdd_TYPE
 * raw_url: jyjSchoolAdd_RAW_URL
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param type - 学校类型
 * @param name - 学校名称
 * @param managerId - 管理员ID
 * @param code - 编码
 */
export const jyjSchoolAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['districtCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: districtCode'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolAddFormData = function(formData) {
  const url = jyjSchoolAdd_RAW_URL()
  const method = jyjSchoolAdd_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolAdd_RAW_URL = function() {
  return '/jyj/school'
}
export const jyjSchoolAdd_TYPE = function() {
  return 'post'
}
export const jyjSchoolAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校详情
 * request: jyjSchoolFind
 * formData: jyjSchoolFindFormData
 * url: jyjSchoolFind_URL
 * method: jyjSchoolFind_TYPE
 * raw_url: jyjSchoolFind_RAW_URL
 * @param schoolId - schoolId
 */
export const jyjSchoolFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school/{schoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolFindFormData = function(formData) {
  const url = jyjSchoolFind_RAW_URL()
  const method = jyjSchoolFind_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolFind_RAW_URL = function() {
  return '/jyj/school/{schoolId}'
}
export const jyjSchoolFind_TYPE = function() {
  return 'get'
}
export const jyjSchoolFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school/{schoolId}'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改学校
 * request: jyjSchoolUpdate
 * formData: jyjSchoolUpdateFormData
 * url: jyjSchoolUpdate_URL
 * method: jyjSchoolUpdate_TYPE
 * raw_url: jyjSchoolUpdate_RAW_URL
 * @param schoolId - schoolId
 * @param type - 学校类型
 * @param name - 学校名称
 * @param managerId - 管理员ID
 */
export const jyjSchoolUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school/{schoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolUpdateFormData = function(formData) {
  const url = jyjSchoolUpdate_RAW_URL()
  const method = jyjSchoolUpdate_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolUpdate_RAW_URL = function() {
  return '/jyj/school/{schoolId}'
}
export const jyjSchoolUpdate_TYPE = function() {
  return 'put'
}
export const jyjSchoolUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school/{schoolId}'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用学校
 * request: jyjSchoolDisable
 * formData: jyjSchoolDisableFormData
 * url: jyjSchoolDisable_URL
 * method: jyjSchoolDisable_TYPE
 * raw_url: jyjSchoolDisable_RAW_URL
 * @param schoolId - schoolId
 */
export const jyjSchoolDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school/{schoolId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolDisableFormData = function(formData) {
  const url = jyjSchoolDisable_RAW_URL()
  const method = jyjSchoolDisable_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolDisable_RAW_URL = function() {
  return '/jyj/school/{schoolId}/disable'
}
export const jyjSchoolDisable_TYPE = function() {
  return 'put'
}
export const jyjSchoolDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school/{schoolId}/disable'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用学校
 * request: jyjSchoolEnable
 * formData: jyjSchoolEnableFormData
 * url: jyjSchoolEnable_URL
 * method: jyjSchoolEnable_TYPE
 * raw_url: jyjSchoolEnable_RAW_URL
 * @param schoolId - schoolId
 */
export const jyjSchoolEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/school/{schoolId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const jyjSchoolEnableFormData = function(formData) {
  const url = jyjSchoolEnable_RAW_URL()
  const method = jyjSchoolEnable_TYPE()
  return axios[method](url, formData)
}
export const jyjSchoolEnable_RAW_URL = function() {
  return '/jyj/school/{schoolId}/enable'
}
export const jyjSchoolEnable_TYPE = function() {
  return 'put'
}
export const jyjSchoolEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/school/{schoolId}/enable'
  path = path.replace('{schoolId}', `${parameters['schoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: jyjScreeningMonitoringFind
 * formData: jyjScreeningMonitoringFindFormData
 * url: jyjScreeningMonitoringFind_URL
 * method: jyjScreeningMonitoringFind_TYPE
 * raw_url: jyjScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const jyjScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringFindFormData = function(formData) {
  const url = jyjScreeningMonitoringFind_RAW_URL()
  const method = jyjScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringFind_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const jyjScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: jyjScreeningMonitoringAbnormalStudent
 * formData: jyjScreeningMonitoringAbnormalStudentFormData
 * url: jyjScreeningMonitoringAbnormalStudent_URL
 * method: jyjScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: jyjScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = jyjScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = jyjScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const jyjScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: jyjScreeningMonitoringFinishedStudent
 * formData: jyjScreeningMonitoringFinishedStudentFormData
 * url: jyjScreeningMonitoringFinishedStudent_URL
 * method: jyjScreeningMonitoringFinishedStudent_TYPE
 * raw_url: jyjScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = jyjScreeningMonitoringFinishedStudent_RAW_URL()
  const method = jyjScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const jyjScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: jyjScreeningMonitoringFinishedStudentWithQuestionary
 * formData: jyjScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: jyjScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: jyjScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: jyjScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = jyjScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = jyjScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const jyjScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: jyjScreeningMonitoringMissingStudent
 * formData: jyjScreeningMonitoringMissingStudentFormData
 * url: jyjScreeningMonitoringMissingStudent_URL
 * method: jyjScreeningMonitoringMissingStudent_TYPE
 * raw_url: jyjScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = jyjScreeningMonitoringMissingStudent_RAW_URL()
  const method = jyjScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const jyjScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: jyjScreeningMonitoringMissingStudentWithQuestionary
 * formData: jyjScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: jyjScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: jyjScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: jyjScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = jyjScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = jyjScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const jyjScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: jyjScreeningMonitoringUncheckedStudent
 * formData: jyjScreeningMonitoringUncheckedStudentFormData
 * url: jyjScreeningMonitoringUncheckedStudent_URL
 * method: jyjScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: jyjScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const jyjScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = jyjScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = jyjScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const jyjScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: jyjScreeningMonitoringFindWithQuestionary
 * formData: jyjScreeningMonitoringFindWithQuestionaryFormData
 * url: jyjScreeningMonitoringFindWithQuestionary_URL
 * method: jyjScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: jyjScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const jyjScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = jyjScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = jyjScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const jyjScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: jyjScreeningMonitoringQualityControl
 * formData: jyjScreeningMonitoringQualityControlFormData
 * url: jyjScreeningMonitoringQualityControl_URL
 * method: jyjScreeningMonitoringQualityControl_TYPE
 * raw_url: jyjScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const jyjScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringQualityControlFormData = function(formData) {
  const url = jyjScreeningMonitoringQualityControl_RAW_URL()
  const method = jyjScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/jyj/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const jyjScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: jyjScreeningMonitoringQuestionnaryScreeningSchool
 * formData: jyjScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: jyjScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: jyjScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: jyjScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jyjScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = jyjScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = jyjScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/jyj/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const jyjScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: jyjScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: jyjScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: jyjScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: jyjScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: jyjScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const jyjScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = jyjScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = jyjScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/jyj/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const jyjScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const jyjScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区监测列表
 * request: jyjScreeningRegionRegionMonitoringList
 * formData: jyjScreeningRegionRegionMonitoringListFormData
 * url: jyjScreeningRegionRegionMonitoringList_URL
 * method: jyjScreeningRegionRegionMonitoringList_TYPE
 * raw_url: jyjScreeningRegionRegionMonitoringList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const jyjScreeningRegionRegionMonitoringList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningRegionRegionMonitoringListFormData = function(formData) {
  const url = jyjScreeningRegionRegionMonitoringList_RAW_URL()
  const method = jyjScreeningRegionRegionMonitoringList_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningRegionRegionMonitoringList_RAW_URL = function() {
  return '/jyj/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
}
export const jyjScreeningRegionRegionMonitoringList_TYPE = function() {
  return 'get'
}
export const jyjScreeningRegionRegionMonitoringList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷
 * request: jyjScreeningRegionQuestionary
 * formData: jyjScreeningRegionQuestionaryFormData
 * url: jyjScreeningRegionQuestionary_URL
 * method: jyjScreeningRegionQuestionary_TYPE
 * raw_url: jyjScreeningRegionQuestionary_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 */
export const jyjScreeningRegionQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-region/{screeningPlanId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningRegionQuestionaryFormData = function(formData) {
  const url = jyjScreeningRegionQuestionary_RAW_URL()
  const method = jyjScreeningRegionQuestionary_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningRegionQuestionary_RAW_URL = function() {
  return '/jyj/screening-region/{screeningPlanId}/questionary'
}
export const jyjScreeningRegionQuestionary_TYPE = function() {
  return 'get'
}
export const jyjScreeningRegionQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-region/{screeningPlanId}/questionary'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷统计
 * request: jyjScreeningRegionQuestionaryStats
 * formData: jyjScreeningRegionQuestionaryStatsFormData
 * url: jyjScreeningRegionQuestionaryStats_URL
 * method: jyjScreeningRegionQuestionaryStats_TYPE
 * raw_url: jyjScreeningRegionQuestionaryStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const jyjScreeningRegionQuestionaryStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/screening-region/{screeningPlanId}/questionary-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjScreeningRegionQuestionaryStatsFormData = function(formData) {
  const url = jyjScreeningRegionQuestionaryStats_RAW_URL()
  const method = jyjScreeningRegionQuestionaryStats_TYPE()
  return axios[method](url, formData)
}
export const jyjScreeningRegionQuestionaryStats_RAW_URL = function() {
  return '/jyj/screening-region/{screeningPlanId}/questionary-stats'
}
export const jyjScreeningRegionQuestionaryStats_TYPE = function() {
  return 'get'
}
export const jyjScreeningRegionQuestionaryStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/screening-region/{screeningPlanId}/questionary-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: jyjTaskIndicatorDubiousStudent
 * formData: jyjTaskIndicatorDubiousStudentFormData
 * url: jyjTaskIndicatorDubiousStudent_URL
 * method: jyjTaskIndicatorDubiousStudent_TYPE
 * raw_url: jyjTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const jyjTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/dubious-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = jyjTaskIndicatorDubiousStudent_RAW_URL()
  const method = jyjTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/jyj/task-indicator/dubious-student/{screeningSchoolId}'
}
export const jyjTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/dubious-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看年级指标和班级
 * request: jyjTaskIndicatorFindForGradeStudent
 * formData: jyjTaskIndicatorFindForGradeStudentFormData
 * url: jyjTaskIndicatorFindForGradeStudent_URL
 * method: jyjTaskIndicatorFindForGradeStudent_TYPE
 * raw_url: jyjTaskIndicatorFindForGradeStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const jyjTaskIndicatorFindForGradeStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/grade-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorFindForGradeStudentFormData = function(formData) {
  const url = jyjTaskIndicatorFindForGradeStudent_RAW_URL()
  const method = jyjTaskIndicatorFindForGradeStudent_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorFindForGradeStudent_RAW_URL = function() {
  return '/jyj/task-indicator/grade-student/{screeningSchoolId}'
}
export const jyjTaskIndicatorFindForGradeStudent_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorFindForGradeStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/grade-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看计划区域指标
 * request: jyjTaskIndicatorFindPlanRegion
 * formData: jyjTaskIndicatorFindPlanRegionFormData
 * url: jyjTaskIndicatorFindPlanRegion_URL
 * method: jyjTaskIndicatorFindPlanRegion_TYPE
 * raw_url: jyjTaskIndicatorFindPlanRegion_RAW_URL
 * @param planId - planId
 */
export const jyjTaskIndicatorFindPlanRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/plan/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorFindPlanRegionFormData = function(formData) {
  const url = jyjTaskIndicatorFindPlanRegion_RAW_URL()
  const method = jyjTaskIndicatorFindPlanRegion_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorFindPlanRegion_RAW_URL = function() {
  return '/jyj/task-indicator/plan/{planId}'
}
export const jyjTaskIndicatorFindPlanRegion_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorFindPlanRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/plan/{planId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学校类型指标进度
 * request: jyjTaskIndicatorSchoolDegree
 * formData: jyjTaskIndicatorSchoolDegreeFormData
 * url: jyjTaskIndicatorSchoolDegree_URL
 * method: jyjTaskIndicatorSchoolDegree_TYPE
 * raw_url: jyjTaskIndicatorSchoolDegree_RAW_URL
 * @param planId - planId
 */
export const jyjTaskIndicatorSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/plan/{planId}/school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorSchoolDegreeFormData = function(formData) {
  const url = jyjTaskIndicatorSchoolDegree_RAW_URL()
  const method = jyjTaskIndicatorSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorSchoolDegree_RAW_URL = function() {
  return '/jyj/task-indicator/plan/{planId}/school-degree'
}
export const jyjTaskIndicatorSchoolDegree_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/plan/{planId}/school-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学生指标进度
 * request: jyjTaskIndicatorStudentDegree
 * formData: jyjTaskIndicatorStudentDegreeFormData
 * url: jyjTaskIndicatorStudentDegree_URL
 * method: jyjTaskIndicatorStudentDegree_TYPE
 * raw_url: jyjTaskIndicatorStudentDegree_RAW_URL
 * @param planId - planId
 */
export const jyjTaskIndicatorStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/plan/{planId}/student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorStudentDegreeFormData = function(formData) {
  const url = jyjTaskIndicatorStudentDegree_RAW_URL()
  const method = jyjTaskIndicatorStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorStudentDegree_RAW_URL = function() {
  return '/jyj/task-indicator/plan/{planId}/student-degree'
}
export const jyjTaskIndicatorStudentDegree_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/plan/{planId}/student-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看区域指标
 * request: jyjTaskIndicatorFindForRegion
 * formData: jyjTaskIndicatorFindForRegionFormData
 * url: jyjTaskIndicatorFindForRegion_URL
 * method: jyjTaskIndicatorFindForRegion_TYPE
 * raw_url: jyjTaskIndicatorFindForRegion_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jyjTaskIndicatorFindForRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorFindForRegionFormData = function(formData) {
  const url = jyjTaskIndicatorFindForRegion_RAW_URL()
  const method = jyjTaskIndicatorFindForRegion_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorFindForRegion_RAW_URL = function() {
  return '/jyj/task-indicator/region/{screeningRegionId}'
}
export const jyjTaskIndicatorFindForRegion_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorFindForRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看学校指标
 * request: jyjTaskIndicatorFindForSchool
 * formData: jyjTaskIndicatorFindForSchoolFormData
 * url: jyjTaskIndicatorFindForSchool_URL
 * method: jyjTaskIndicatorFindForSchool_TYPE
 * raw_url: jyjTaskIndicatorFindForSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jyjTaskIndicatorFindForSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/school/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorFindForSchoolFormData = function(formData) {
  const url = jyjTaskIndicatorFindForSchool_RAW_URL()
  const method = jyjTaskIndicatorFindForSchool_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorFindForSchool_RAW_URL = function() {
  return '/jyj/task-indicator/school/{screeningRegionId}'
}
export const jyjTaskIndicatorFindForSchool_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorFindForSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/school/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学校类型指标进度
 * request: jyjTaskIndicatorCityRegionSchoolDegree
 * formData: jyjTaskIndicatorCityRegionSchoolDegreeFormData
 * url: jyjTaskIndicatorCityRegionSchoolDegree_URL
 * method: jyjTaskIndicatorCityRegionSchoolDegree_TYPE
 * raw_url: jyjTaskIndicatorCityRegionSchoolDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jyjTaskIndicatorCityRegionSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorCityRegionSchoolDegreeFormData = function(formData) {
  const url = jyjTaskIndicatorCityRegionSchoolDegree_RAW_URL()
  const method = jyjTaskIndicatorCityRegionSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorCityRegionSchoolDegree_RAW_URL = function() {
  return '/jyj/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
}
export const jyjTaskIndicatorCityRegionSchoolDegree_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorCityRegionSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学生类型指标进度
 * request: jyjTaskIndicatorCityRegionStudentDegree
 * formData: jyjTaskIndicatorCityRegionStudentDegreeFormData
 * url: jyjTaskIndicatorCityRegionStudentDegree_URL
 * method: jyjTaskIndicatorCityRegionStudentDegree_TYPE
 * raw_url: jyjTaskIndicatorCityRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jyjTaskIndicatorCityRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorCityRegionStudentDegreeFormData = function(formData) {
  const url = jyjTaskIndicatorCityRegionStudentDegree_RAW_URL()
  const method = jyjTaskIndicatorCityRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorCityRegionStudentDegree_RAW_URL = function() {
  return '/jyj/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
}
export const jyjTaskIndicatorCityRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorCityRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查区级地区学生类型指标进度
 * request: jyjTaskIndicatorDistrictRegionStudentDegree
 * formData: jyjTaskIndicatorDistrictRegionStudentDegreeFormData
 * url: jyjTaskIndicatorDistrictRegionStudentDegree_URL
 * method: jyjTaskIndicatorDistrictRegionStudentDegree_TYPE
 * raw_url: jyjTaskIndicatorDistrictRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const jyjTaskIndicatorDistrictRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const jyjTaskIndicatorDistrictRegionStudentDegreeFormData = function(formData) {
  const url = jyjTaskIndicatorDistrictRegionStudentDegree_RAW_URL()
  const method = jyjTaskIndicatorDistrictRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const jyjTaskIndicatorDistrictRegionStudentDegree_RAW_URL = function() {
  return '/jyj/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
}
export const jyjTaskIndicatorDistrictRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const jyjTaskIndicatorDistrictRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/jyj/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: mgAdministratorList
 * formData: mgAdministratorListFormData
 * url: mgAdministratorList_URL
 * method: mgAdministratorList_TYPE
 * raw_url: mgAdministratorList_RAW_URL
 * @param type - 类型
 * @param name - 姓名
 * @param mobile - 手机号
 * @param email - 邮箱
 * @param regionCode - 地区编码
 * @param governmentSectorType - 部门类型
 * @param lastLoginTimeLowerLimit - 最后登录时间下限
 * @param lastLoginTimeUpperLimit - 最后登录时间上限
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['governmentSectorType'] !== undefined) {
    queryParameters['governmentSectorType'] = parameters['governmentSectorType']
  }
  if (parameters['lastLoginTimeLowerLimit'] !== undefined) {
    queryParameters['lastLoginTimeLowerLimit'] = parameters['lastLoginTimeLowerLimit']
  }
  if (parameters['lastLoginTimeUpperLimit'] !== undefined) {
    queryParameters['lastLoginTimeUpperLimit'] = parameters['lastLoginTimeUpperLimit']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorListFormData = function(formData) {
  const url = mgAdministratorList_RAW_URL()
  const method = mgAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorList_RAW_URL = function() {
  return '/management/administrator'
}
export const mgAdministratorList_TYPE = function() {
  return 'get'
}
export const mgAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    queryParameters['email'] = parameters['email']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['governmentSectorType'] !== undefined) {
    queryParameters['governmentSectorType'] = parameters['governmentSectorType']
  }
  if (parameters['lastLoginTimeLowerLimit'] !== undefined) {
    queryParameters['lastLoginTimeLowerLimit'] = parameters['lastLoginTimeLowerLimit']
  }
  if (parameters['lastLoginTimeUpperLimit'] !== undefined) {
    queryParameters['lastLoginTimeUpperLimit'] = parameters['lastLoginTimeUpperLimit']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: mgAdministratorAdd
 * formData: mgAdministratorAddFormData
 * url: mgAdministratorAdd_URL
 * method: mgAdministratorAdd_TYPE
 * raw_url: mgAdministratorAdd_RAW_URL
 * @param name - 
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const mgAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorAddFormData = function(formData) {
  const url = mgAdministratorAdd_RAW_URL()
  const method = mgAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorAdd_RAW_URL = function() {
  return '/management/administrator'
}
export const mgAdministratorAdd_TYPE = function() {
  return 'post'
}
export const mgAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: mgAdministratorImportAdministrator
 * formData: mgAdministratorImportAdministratorFormData
 * url: mgAdministratorImportAdministrator_URL
 * method: mgAdministratorImportAdministrator_TYPE
 * raw_url: mgAdministratorImportAdministrator_RAW_URL
 * @param file - 
 */
export const mgAdministratorImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorImportAdministratorFormData = function(formData) {
  const url = mgAdministratorImportAdministrator_RAW_URL()
  const method = mgAdministratorImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorImportAdministrator_RAW_URL = function() {
  return '/management/administrator/import'
}
export const mgAdministratorImportAdministrator_TYPE = function() {
  return 'post'
}
export const mgAdministratorImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改管理员
 * request: mgAdministratorUpdate
 * formData: mgAdministratorUpdateFormData
 * url: mgAdministratorUpdate_URL
 * method: mgAdministratorUpdate_TYPE
 * raw_url: mgAdministratorUpdate_RAW_URL
 * @param administratorId - administratorId
 * @param name - 
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const mgAdministratorUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['email'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: email'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorUpdateFormData = function(formData) {
  const url = mgAdministratorUpdate_RAW_URL()
  const method = mgAdministratorUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorUpdate_RAW_URL = function() {
  return '/management/administrator/{administratorId}'
}
export const mgAdministratorUpdate_TYPE = function() {
  return 'put'
}
export const mgAdministratorUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用管理员
 * request: mgAdministratorDisable
 * formData: mgAdministratorDisableFormData
 * url: mgAdministratorDisable_URL
 * method: mgAdministratorDisable_TYPE
 * raw_url: mgAdministratorDisable_RAW_URL
 * @param administratorId - administratorId
 */
export const mgAdministratorDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator/{administratorId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorDisableFormData = function(formData) {
  const url = mgAdministratorDisable_RAW_URL()
  const method = mgAdministratorDisable_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorDisable_RAW_URL = function() {
  return '/management/administrator/{administratorId}/disable'
}
export const mgAdministratorDisable_TYPE = function() {
  return 'put'
}
export const mgAdministratorDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator/{administratorId}/disable'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用管理员
 * request: mgAdministratorEnable
 * formData: mgAdministratorEnableFormData
 * url: mgAdministratorEnable_URL
 * method: mgAdministratorEnable_TYPE
 * raw_url: mgAdministratorEnable_RAW_URL
 * @param administratorId - administratorId
 */
export const mgAdministratorEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator/{administratorId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorEnableFormData = function(formData) {
  const url = mgAdministratorEnable_RAW_URL()
  const method = mgAdministratorEnable_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorEnable_RAW_URL = function() {
  return '/management/administrator/{administratorId}/enable'
}
export const mgAdministratorEnable_TYPE = function() {
  return 'put'
}
export const mgAdministratorEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator/{administratorId}/enable'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改管理员密码
 * request: mgAdministratorUpdatePwd
 * formData: mgAdministratorUpdatePwdFormData
 * url: mgAdministratorUpdatePwd_URL
 * method: mgAdministratorUpdatePwd_TYPE
 * raw_url: mgAdministratorUpdatePwd_RAW_URL
 * @param administratorId - administratorId
 * @param password - 
 */
export const mgAdministratorUpdatePwd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/administrator/{administratorId}/password'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['password'] !== undefined) {
    form['password'] = parameters['password']
  }
  if (parameters['password'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: password'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAdministratorUpdatePwdFormData = function(formData) {
  const url = mgAdministratorUpdatePwd_RAW_URL()
  const method = mgAdministratorUpdatePwd_TYPE()
  return axios[method](url, formData)
}
export const mgAdministratorUpdatePwd_RAW_URL = function() {
  return '/management/administrator/{administratorId}/password'
}
export const mgAdministratorUpdatePwd_TYPE = function() {
  return 'put'
}
export const mgAdministratorUpdatePwd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/administrator/{administratorId}/password'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 版本列表
 * request: mgAppVersionList
 * formData: mgAppVersionListFormData
 * url: mgAppVersionList_URL
 * method: mgAppVersionList_TYPE
 * raw_url: mgAppVersionList_RAW_URL
 * @param appType - 系统类型
 * @param uniqueCode - 唯一码，类型为1, 才支持检索
 * @param name - 名称
 * @param content - 内容
 * @param enablePromptUpdate - 是否提示更新
 * @param enableForceUpdate - 是否强制更新
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgAppVersionList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/app-version'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters['uniqueCode'] !== undefined) {
    queryParameters['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['content'] !== undefined) {
    queryParameters['content'] = parameters['content']
  }
  if (parameters['enablePromptUpdate'] !== undefined) {
    queryParameters['enablePromptUpdate'] = parameters['enablePromptUpdate']
  }
  if (parameters['enableForceUpdate'] !== undefined) {
    queryParameters['enableForceUpdate'] = parameters['enableForceUpdate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgAppVersionListFormData = function(formData) {
  const url = mgAppVersionList_RAW_URL()
  const method = mgAppVersionList_TYPE()
  return axios[method](url, formData)
}
export const mgAppVersionList_RAW_URL = function() {
  return '/management/app-version'
}
export const mgAppVersionList_TYPE = function() {
  return 'get'
}
export const mgAppVersionList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/app-version'
  if (parameters['appType'] !== undefined) {
    queryParameters['appType'] = parameters['appType']
  }
  if (parameters['uniqueCode'] !== undefined) {
    queryParameters['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['content'] !== undefined) {
    queryParameters['content'] = parameters['content']
  }
  if (parameters['enablePromptUpdate'] !== undefined) {
    queryParameters['enablePromptUpdate'] = parameters['enablePromptUpdate']
  }
  if (parameters['enableForceUpdate'] !== undefined) {
    queryParameters['enableForceUpdate'] = parameters['enableForceUpdate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 应用版本添加
 * request: mgAppVersionAdd
 * formData: mgAppVersionAddFormData
 * url: mgAppVersionAdd_URL
 * method: mgAppVersionAdd_TYPE
 * raw_url: mgAppVersionAdd_RAW_URL
 * @param appType - 
 * @param name - 
 * @param uniqueCode - 类型为1, 才必须添加
 * @param content - 
 * @param enablePromptUpdate - 
 * @param enableForceUpdate - 
 * @param fileName - 
 */
export const mgAppVersionAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/app-version'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['appType'] !== undefined) {
    form['appType'] = parameters['appType']
  }
  if (parameters['appType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appType'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['uniqueCode'] !== undefined) {
    form['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['content'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters['enablePromptUpdate'] !== undefined) {
    form['enablePromptUpdate'] = parameters['enablePromptUpdate']
  }
  if (parameters['enablePromptUpdate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: enablePromptUpdate'))
  }
  if (parameters['enableForceUpdate'] !== undefined) {
    form['enableForceUpdate'] = parameters['enableForceUpdate']
  }
  if (parameters['fileName'] !== undefined) {
    form['fileName'] = parameters['fileName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgAppVersionAddFormData = function(formData) {
  const url = mgAppVersionAdd_RAW_URL()
  const method = mgAppVersionAdd_TYPE()
  return axios[method](url, formData)
}
export const mgAppVersionAdd_RAW_URL = function() {
  return '/management/app-version'
}
export const mgAppVersionAdd_TYPE = function() {
  return 'post'
}
export const mgAppVersionAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/app-version'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传应用
 * request: mgAppVersionUploadFile
 * formData: mgAppVersionUploadFileFormData
 * url: mgAppVersionUploadFile_URL
 * method: mgAppVersionUploadFile_TYPE
 * raw_url: mgAppVersionUploadFile_RAW_URL
 * @param file - 
 */
export const mgAppVersionUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/app-version/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgAppVersionUploadFileFormData = function(formData) {
  const url = mgAppVersionUploadFile_RAW_URL()
  const method = mgAppVersionUploadFile_TYPE()
  return axios[method](url, formData)
}
export const mgAppVersionUploadFile_RAW_URL = function() {
  return '/management/app-version/file'
}
export const mgAppVersionUploadFile_TYPE = function() {
  return 'post'
}
export const mgAppVersionUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/app-version/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 应用版本修改
 * request: mgAppVersionUpdate
 * formData: mgAppVersionUpdateFormData
 * url: mgAppVersionUpdate_URL
 * method: mgAppVersionUpdate_TYPE
 * raw_url: mgAppVersionUpdate_RAW_URL
 * @param appVersionId - appVersionId
 * @param name - 
 * @param uniqueCode - 类型为1, 才必须添加
 * @param content - 
 * @param enablePromptUpdate - 
 * @param enableForceUpdate - 
 * @param fileName - 
 */
export const mgAppVersionUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/app-version/{appVersionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters['appVersionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appVersionId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['uniqueCode'] !== undefined) {
    form['uniqueCode'] = parameters['uniqueCode']
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['content'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters['enablePromptUpdate'] !== undefined) {
    form['enablePromptUpdate'] = parameters['enablePromptUpdate']
  }
  if (parameters['enablePromptUpdate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: enablePromptUpdate'))
  }
  if (parameters['enableForceUpdate'] !== undefined) {
    form['enableForceUpdate'] = parameters['enableForceUpdate']
  }
  if (parameters['fileName'] !== undefined) {
    form['fileName'] = parameters['fileName']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgAppVersionUpdateFormData = function(formData) {
  const url = mgAppVersionUpdate_RAW_URL()
  const method = mgAppVersionUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgAppVersionUpdate_RAW_URL = function() {
  return '/management/app-version/{appVersionId}'
}
export const mgAppVersionUpdate_TYPE = function() {
  return 'put'
}
export const mgAppVersionUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/app-version/{appVersionId}'
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除应用版本
 * request: mgAppVersionDelete
 * formData: mgAppVersionDeleteFormData
 * url: mgAppVersionDelete_URL
 * method: mgAppVersionDelete_TYPE
 * raw_url: mgAppVersionDelete_RAW_URL
 * @param appVersionId - appVersionId
 */
export const mgAppVersionDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/app-version/{appVersionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters['appVersionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: appVersionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const mgAppVersionDeleteFormData = function(formData) {
  const url = mgAppVersionDelete_RAW_URL()
  const method = mgAppVersionDelete_TYPE()
  return axios[method](url, formData)
}
export const mgAppVersionDelete_RAW_URL = function() {
  return '/management/app-version/{appVersionId}'
}
export const mgAppVersionDelete_TYPE = function() {
  return 'delete'
}
export const mgAppVersionDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/app-version/{appVersionId}'
  path = path.replace('{appVersionId}', `${parameters['appVersionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板列表
 * request: mgDataFileTplList
 * formData: mgDataFileTplListFormData
 * url: mgDataFileTplList_URL
 * method: mgDataFileTplList_TYPE
 * raw_url: mgDataFileTplList_RAW_URL
 * @param name - 模板名称
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgDataFileTplList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplListFormData = function(formData) {
  const url = mgDataFileTplList_RAW_URL()
  const method = mgDataFileTplList_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplList_RAW_URL = function() {
  return '/management/data-file-tpl'
}
export const mgDataFileTplList_TYPE = function() {
  return 'get'
}
export const mgDataFileTplList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板保存
 * request: mgDataFileTplSave
 * formData: mgDataFileTplSaveFormData
 * url: mgDataFileTplSave_URL
 * method: mgDataFileTplSave_TYPE
 * raw_url: mgDataFileTplSave_RAW_URL
 * @param vo - vo
 */
export const mgDataFileTplSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplSaveFormData = function(formData) {
  const url = mgDataFileTplSave_RAW_URL()
  const method = mgDataFileTplSave_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplSave_RAW_URL = function() {
  return '/management/data-file-tpl'
}
export const mgDataFileTplSave_TYPE = function() {
  return 'post'
}
export const mgDataFileTplSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板修改
 * request: mgDataFileTplUpdate
 * formData: mgDataFileTplUpdateFormData
 * url: mgDataFileTplUpdate_URL
 * method: mgDataFileTplUpdate_TYPE
 * raw_url: mgDataFileTplUpdate_RAW_URL
 * @param id - id
 * @param vo - vo
 */
export const mgDataFileTplUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplUpdateFormData = function(formData) {
  const url = mgDataFileTplUpdate_RAW_URL()
  const method = mgDataFileTplUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplUpdate_RAW_URL = function() {
  return '/management/data-file-tpl/{id}'
}
export const mgDataFileTplUpdate_TYPE = function() {
  return 'put'
}
export const mgDataFileTplUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板查看
 * request: mgDataFileTplCheck
 * formData: mgDataFileTplCheckFormData
 * url: mgDataFileTplCheck_URL
 * method: mgDataFileTplCheck_TYPE
 * raw_url: mgDataFileTplCheck_RAW_URL
 * @param id - id
 * @param versionId - versionId
 */
export const mgDataFileTplCheck = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl/{id}/check'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['versionId'] !== undefined) {
    queryParameters['versionId'] = parameters['versionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplCheckFormData = function(formData) {
  const url = mgDataFileTplCheck_RAW_URL()
  const method = mgDataFileTplCheck_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplCheck_RAW_URL = function() {
  return '/management/data-file-tpl/{id}/check'
}
export const mgDataFileTplCheck_TYPE = function() {
  return 'get'
}
export const mgDataFileTplCheck_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl/{id}/check'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['versionId'] !== undefined) {
    queryParameters['versionId'] = parameters['versionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板启用禁用
 * request: mgDataFileTplOnOff
 * formData: mgDataFileTplOnOffFormData
 * url: mgDataFileTplOnOff_URL
 * method: mgDataFileTplOnOff_TYPE
 * raw_url: mgDataFileTplOnOff_RAW_URL
 * @param id - id
 */
export const mgDataFileTplOnOff = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl/{id}/on_off'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplOnOffFormData = function(formData) {
  const url = mgDataFileTplOnOff_RAW_URL()
  const method = mgDataFileTplOnOff_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplOnOff_RAW_URL = function() {
  return '/management/data-file-tpl/{id}/on_off'
}
export const mgDataFileTplOnOff_TYPE = function() {
  return 'get'
}
export const mgDataFileTplOnOff_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl/{id}/on_off'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据文档模板升级
 * request: mgDataFileTplUp
 * formData: mgDataFileTplUpFormData
 * url: mgDataFileTplUp_URL
 * method: mgDataFileTplUp_TYPE
 * raw_url: mgDataFileTplUp_RAW_URL
 * @param id - id
 */
export const mgDataFileTplUp = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-file-tpl/{id}/up'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFileTplUpFormData = function(formData) {
  const url = mgDataFileTplUp_RAW_URL()
  const method = mgDataFileTplUp_TYPE()
  return axios[method](url, formData)
}
export const mgDataFileTplUp_RAW_URL = function() {
  return '/management/data-file-tpl/{id}/up'
}
export const mgDataFileTplUp_TYPE = function() {
  return 'get'
}
export const mgDataFileTplUp_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-file-tpl/{id}/up'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单列表
 * request: mgDataFormList
 * formData: mgDataFormListFormData
 * url: mgDataFormList_URL
 * method: mgDataFormList_TYPE
 * raw_url: mgDataFormList_RAW_URL
 * @param name - 
 * @param status - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgDataFormList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFormListFormData = function(formData) {
  const url = mgDataFormList_RAW_URL()
  const method = mgDataFormList_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormList_RAW_URL = function() {
  return '/management/data-form'
}
export const mgDataFormList_TYPE = function() {
  return 'get'
}
export const mgDataFormList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单添加
 * request: mgDataFormAdd
 * formData: mgDataFormAddFormData
 * url: mgDataFormAdd_URL
 * method: mgDataFormAdd_TYPE
 * raw_url: mgDataFormAdd_RAW_URL
 * @param name - 
 * @param dataItemIdStr - 
 */
export const mgDataFormAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['dataItemIdStr'] !== undefined) {
    form['dataItemIdStr'] = parameters['dataItemIdStr']
  }
  if (parameters['dataItemIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgDataFormAddFormData = function(formData) {
  const url = mgDataFormAdd_RAW_URL()
  const method = mgDataFormAdd_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormAdd_RAW_URL = function() {
  return '/management/data-form'
}
export const mgDataFormAdd_TYPE = function() {
  return 'post'
}
export const mgDataFormAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 通过表单版本获取表单选项
 * request: mgDataFormVersionItemByDataFormVersion
 * formData: mgDataFormVersionItemByDataFormVersionFormData
 * url: mgDataFormVersionItemByDataFormVersion_URL
 * method: mgDataFormVersionItemByDataFormVersion_TYPE
 * raw_url: mgDataFormVersionItemByDataFormVersion_RAW_URL
 * @param dataFormVersionId - dataFormVersionId
 */
export const mgDataFormVersionItemByDataFormVersion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form-version-item/data-form-version/{dataFormVersionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormVersionId}', `${parameters['dataFormVersionId']}`)
  if (parameters['dataFormVersionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormVersionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFormVersionItemByDataFormVersionFormData = function(formData) {
  const url = mgDataFormVersionItemByDataFormVersion_RAW_URL()
  const method = mgDataFormVersionItemByDataFormVersion_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormVersionItemByDataFormVersion_RAW_URL = function() {
  return '/management/data-form-version-item/data-form-version/{dataFormVersionId}'
}
export const mgDataFormVersionItemByDataFormVersion_TYPE = function() {
  return 'get'
}
export const mgDataFormVersionItemByDataFormVersion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form-version-item/data-form-version/{dataFormVersionId}'
  path = path.replace('{dataFormVersionId}', `${parameters['dataFormVersionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改表单选项排序号
 * request: mgDataFormVersionItemUpdateSortNum
 * formData: mgDataFormVersionItemUpdateSortNumFormData
 * url: mgDataFormVersionItemUpdateSortNum_URL
 * method: mgDataFormVersionItemUpdateSortNum_TYPE
 * raw_url: mgDataFormVersionItemUpdateSortNum_RAW_URL
 * @param voList - voList
 */
export const mgDataFormVersionItemUpdateSortNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form-version-item/sort-num'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['voList'] !== undefined) {
    body = parameters['voList']
  }
  if (parameters['voList'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: voList'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataFormVersionItemUpdateSortNumFormData = function(formData) {
  const url = mgDataFormVersionItemUpdateSortNum_RAW_URL()
  const method = mgDataFormVersionItemUpdateSortNum_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormVersionItemUpdateSortNum_RAW_URL = function() {
  return '/management/data-form-version-item/sort-num'
}
export const mgDataFormVersionItemUpdateSortNum_TYPE = function() {
  return 'put'
}
export const mgDataFormVersionItemUpdateSortNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form-version-item/sort-num'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单选择列表
 * request: mgDataFormSimpleList
 * formData: mgDataFormSimpleListFormData
 * url: mgDataFormSimpleList_URL
 * method: mgDataFormSimpleList_TYPE
 * raw_url: mgDataFormSimpleList_RAW_URL
 * @param keyword - 
 */
export const mgDataFormSimpleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/simple'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataFormSimpleListFormData = function(formData) {
  const url = mgDataFormSimpleList_RAW_URL()
  const method = mgDataFormSimpleList_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormSimpleList_RAW_URL = function() {
  return '/management/data-form/simple'
}
export const mgDataFormSimpleList_TYPE = function() {
  return 'get'
}
export const mgDataFormSimpleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/simple'
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * find
 * request: findUsingGET
 * formData: findUsingGETFormData
 * url: findUsingGET_URL
 * method: findUsingGET_TYPE
 * raw_url: findUsingGET_RAW_URL
 * @param dataFormId - dataFormId
 */
export const findUsingGET = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/{dataFormId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters['dataFormId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const findUsingGETFormData = function(formData) {
  const url = findUsingGET_RAW_URL()
  const method = findUsingGET_TYPE()
  return axios[method](url, formData)
}
export const findUsingGET_RAW_URL = function() {
  return '/management/data-form/{dataFormId}'
}
export const findUsingGET_TYPE = function() {
  return 'get'
}
export const findUsingGET_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/{dataFormId}'
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单修改
 * request: mgDataFormUpdate
 * formData: mgDataFormUpdateFormData
 * url: mgDataFormUpdate_URL
 * method: mgDataFormUpdate_TYPE
 * raw_url: mgDataFormUpdate_RAW_URL
 * @param dataFormId - dataFormId
 * @param name - 
 * @param dataItemIdStr - 
 */
export const mgDataFormUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/{dataFormId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters['dataFormId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['dataItemIdStr'] !== undefined) {
    form['dataItemIdStr'] = parameters['dataItemIdStr']
  }
  if (parameters['dataItemIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataFormUpdateFormData = function(formData) {
  const url = mgDataFormUpdate_RAW_URL()
  const method = mgDataFormUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormUpdate_RAW_URL = function() {
  return '/management/data-form/{dataFormId}'
}
export const mgDataFormUpdate_TYPE = function() {
  return 'put'
}
export const mgDataFormUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/{dataFormId}'
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单禁用
 * request: mgDataFormDisable
 * formData: mgDataFormDisableFormData
 * url: mgDataFormDisable_URL
 * method: mgDataFormDisable_TYPE
 * raw_url: mgDataFormDisable_RAW_URL
 * @param dataFormId - dataFormId
 */
export const mgDataFormDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/{dataFormId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters['dataFormId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataFormDisableFormData = function(formData) {
  const url = mgDataFormDisable_RAW_URL()
  const method = mgDataFormDisable_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormDisable_RAW_URL = function() {
  return '/management/data-form/{dataFormId}/disable'
}
export const mgDataFormDisable_TYPE = function() {
  return 'put'
}
export const mgDataFormDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/{dataFormId}/disable'
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单启用
 * request: mgDataFormEnable
 * formData: mgDataFormEnableFormData
 * url: mgDataFormEnable_URL
 * method: mgDataFormEnable_TYPE
 * raw_url: mgDataFormEnable_RAW_URL
 * @param dataFormId - dataFormId
 */
export const mgDataFormEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/{dataFormId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters['dataFormId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataFormEnableFormData = function(formData) {
  const url = mgDataFormEnable_RAW_URL()
  const method = mgDataFormEnable_TYPE()
  return axios[method](url, formData)
}
export const mgDataFormEnable_RAW_URL = function() {
  return '/management/data-form/{dataFormId}/enable'
}
export const mgDataFormEnable_TYPE = function() {
  return 'put'
}
export const mgDataFormEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/{dataFormId}/enable'
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据表单升级
 * request: mgDateItemUpgrade
 * formData: mgDateItemUpgradeFormData
 * url: mgDateItemUpgrade_URL
 * method: mgDateItemUpgrade_TYPE
 * raw_url: mgDateItemUpgrade_RAW_URL
 * @param dataFormId - dataFormId
 */
export const mgDateItemUpgrade = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-form/{dataFormId}/upgrade'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters['dataFormId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataFormId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDateItemUpgradeFormData = function(formData) {
  const url = mgDateItemUpgrade_RAW_URL()
  const method = mgDateItemUpgrade_TYPE()
  return axios[method](url, formData)
}
export const mgDateItemUpgrade_RAW_URL = function() {
  return '/management/data-form/{dataFormId}/upgrade'
}
export const mgDateItemUpgrade_TYPE = function() {
  return 'put'
}
export const mgDateItemUpgrade_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-form/{dataFormId}/upgrade'
  path = path.replace('{dataFormId}', `${parameters['dataFormId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项列表
 * request: mgDataItemList
 * formData: mgDataItemListFormData
 * url: mgDataItemList_URL
 * method: mgDataItemList_TYPE
 * raw_url: mgDataItemList_RAW_URL
 * @param name - 
 * @param type - 
 * @param status - 
 * @param code - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgDataItemList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataItemListFormData = function(formData) {
  const url = mgDataItemList_RAW_URL()
  const method = mgDataItemList_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemList_RAW_URL = function() {
  return '/management/data-item'
}
export const mgDataItemList_TYPE = function() {
  return 'get'
}
export const mgDataItemList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项添加
 * request: mgDataItemAdd
 * formData: mgDataItemAddFormData
 * url: mgDataItemAdd_URL
 * method: mgDataItemAdd_TYPE
 * raw_url: mgDataItemAdd_RAW_URL
 * @param vo - vo
 */
export const mgDataItemAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgDataItemAddFormData = function(formData) {
  const url = mgDataItemAdd_RAW_URL()
  const method = mgDataItemAdd_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemAdd_RAW_URL = function() {
  return '/management/data-item'
}
export const mgDataItemAdd_TYPE = function() {
  return 'post'
}
export const mgDataItemAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项选择列表
 * request: mgDataItemSimpleList
 * formData: mgDataItemSimpleListFormData
 * url: mgDataItemSimpleList_URL
 * method: mgDataItemSimpleList_TYPE
 * raw_url: mgDataItemSimpleList_RAW_URL
 * @param keyword - 
 */
export const mgDataItemSimpleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item/simple'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataItemSimpleListFormData = function(formData) {
  const url = mgDataItemSimpleList_RAW_URL()
  const method = mgDataItemSimpleList_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemSimpleList_RAW_URL = function() {
  return '/management/data-item/simple'
}
export const mgDataItemSimpleList_TYPE = function() {
  return 'get'
}
export const mgDataItemSimpleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item/simple'
  if (parameters['keyword'] !== undefined) {
    queryParameters['keyword'] = parameters['keyword']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取数据项
 * request: mgDataItemFind
 * formData: mgDataItemFindFormData
 * url: mgDataItemFind_URL
 * method: mgDataItemFind_TYPE
 * raw_url: mgDataItemFind_RAW_URL
 * @param dataItemId - dataItemId
 */
export const mgDataItemFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item/{dataItemId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters['dataItemId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgDataItemFindFormData = function(formData) {
  const url = mgDataItemFind_RAW_URL()
  const method = mgDataItemFind_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemFind_RAW_URL = function() {
  return '/management/data-item/{dataItemId}'
}
export const mgDataItemFind_TYPE = function() {
  return 'get'
}
export const mgDataItemFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item/{dataItemId}'
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项修改
 * request: mgDataItemUpdate
 * formData: mgDataItemUpdateFormData
 * url: mgDataItemUpdate_URL
 * method: mgDataItemUpdate_TYPE
 * raw_url: mgDataItemUpdate_RAW_URL
 * @param dataItemId - dataItemId
 * @param vo - vo
 */
export const mgDataItemUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item/{dataItemId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters['dataItemId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataItemUpdateFormData = function(formData) {
  const url = mgDataItemUpdate_RAW_URL()
  const method = mgDataItemUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemUpdate_RAW_URL = function() {
  return '/management/data-item/{dataItemId}'
}
export const mgDataItemUpdate_TYPE = function() {
  return 'put'
}
export const mgDataItemUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item/{dataItemId}'
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项禁用
 * request: mgDateItemDisable
 * formData: mgDateItemDisableFormData
 * url: mgDateItemDisable_URL
 * method: mgDateItemDisable_TYPE
 * raw_url: mgDateItemDisable_RAW_URL
 * @param dataItemId - dataItemId
 */
export const mgDateItemDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item/{dataItemId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters['dataItemId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDateItemDisableFormData = function(formData) {
  const url = mgDateItemDisable_RAW_URL()
  const method = mgDateItemDisable_TYPE()
  return axios[method](url, formData)
}
export const mgDateItemDisable_RAW_URL = function() {
  return '/management/data-item/{dataItemId}/disable'
}
export const mgDateItemDisable_TYPE = function() {
  return 'put'
}
export const mgDateItemDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item/{dataItemId}/disable'
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 数据项启用
 * request: mgDataItemEnable
 * formData: mgDataItemEnableFormData
 * url: mgDataItemEnable_URL
 * method: mgDataItemEnable_TYPE
 * raw_url: mgDataItemEnable_RAW_URL
 * @param dataItemId - dataItemId
 */
export const mgDataItemEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/data-item/{dataItemId}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters['dataItemId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dataItemId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgDataItemEnableFormData = function(formData) {
  const url = mgDataItemEnable_RAW_URL()
  const method = mgDataItemEnable_TYPE()
  return axios[method](url, formData)
}
export const mgDataItemEnable_RAW_URL = function() {
  return '/management/data-item/{dataItemId}/enable'
}
export const mgDataItemEnable_TYPE = function() {
  return 'put'
}
export const mgDataItemEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/data-item/{dataItemId}/enable'
  path = path.replace('{dataItemId}', `${parameters['dataItemId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加省政府部门
 * request: mgGovernmentSectorAdd
 * formData: mgGovernmentSectorAddFormData
 * url: mgGovernmentSectorAdd_URL
 * method: mgGovernmentSectorAdd_TYPE
 * raw_url: mgGovernmentSectorAdd_RAW_URL
 * @param type - 政府单位类型
 * @param provinceCode - 省编码
 * @param cityCode - 市编码（当系统地区类型为省时不填，为市时必填）
 * @param managerId - 管理员ID
 */
export const mgGovernmentSectorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/government-sector'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgGovernmentSectorAddFormData = function(formData) {
  const url = mgGovernmentSectorAdd_RAW_URL()
  const method = mgGovernmentSectorAdd_TYPE()
  return axios[method](url, formData)
}
export const mgGovernmentSectorAdd_RAW_URL = function() {
  return '/management/government-sector'
}
export const mgGovernmentSectorAdd_TYPE = function() {
  return 'post'
}
export const mgGovernmentSectorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/government-sector'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取顶级政府部门
 * request: mgGovernmentSectorFindTopRegion
 * formData: mgGovernmentSectorFindTopRegionFormData
 * url: mgGovernmentSectorFindTopRegion_URL
 * method: mgGovernmentSectorFindTopRegion_TYPE
 * raw_url: mgGovernmentSectorFindTopRegion_RAW_URL
 * @param type - 
 */
export const mgGovernmentSectorFindTopRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/government-sector/top-region'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgGovernmentSectorFindTopRegionFormData = function(formData) {
  const url = mgGovernmentSectorFindTopRegion_RAW_URL()
  const method = mgGovernmentSectorFindTopRegion_TYPE()
  return axios[method](url, formData)
}
export const mgGovernmentSectorFindTopRegion_RAW_URL = function() {
  return '/management/government-sector/top-region'
}
export const mgGovernmentSectorFindTopRegion_TYPE = function() {
  return 'get'
}
export const mgGovernmentSectorFindTopRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/government-sector/top-region'
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 省部门详情
 * request: mgGovernmentSectorFind
 * formData: mgGovernmentSectorFindFormData
 * url: mgGovernmentSectorFind_URL
 * method: mgGovernmentSectorFind_TYPE
 * raw_url: mgGovernmentSectorFind_RAW_URL
 * @param governmentSectorId - governmentSectorId
 */
export const mgGovernmentSectorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/government-sector/{governmentSectorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgGovernmentSectorFindFormData = function(formData) {
  const url = mgGovernmentSectorFind_RAW_URL()
  const method = mgGovernmentSectorFind_TYPE()
  return axios[method](url, formData)
}
export const mgGovernmentSectorFind_RAW_URL = function() {
  return '/management/government-sector/{governmentSectorId}'
}
export const mgGovernmentSectorFind_TYPE = function() {
  return 'get'
}
export const mgGovernmentSectorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/government-sector/{governmentSectorId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改省政府部门管理员
 * request: mgGovernmentSectorUpdate
 * formData: mgGovernmentSectorUpdateFormData
 * url: mgGovernmentSectorUpdate_URL
 * method: mgGovernmentSectorUpdate_TYPE
 * raw_url: mgGovernmentSectorUpdate_RAW_URL
 * @param governmentSectorId - governmentSectorId
 * @param managerId - managerId
 * @param type - 政府单位类型
 */
export const mgGovernmentSectorUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/government-sector/{governmentSectorId}/administrator/{managerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgGovernmentSectorUpdateFormData = function(formData) {
  const url = mgGovernmentSectorUpdate_RAW_URL()
  const method = mgGovernmentSectorUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgGovernmentSectorUpdate_RAW_URL = function() {
  return '/management/government-sector/{governmentSectorId}/administrator/{managerId}'
}
export const mgGovernmentSectorUpdate_TYPE = function() {
  return 'put'
}
export const mgGovernmentSectorUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/government-sector/{governmentSectorId}/administrator/{managerId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * ICD10编码列表
 * request: mgIcd10List
 * formData: mgIcd10ListFormData
 * url: mgIcd10List_URL
 * method: mgIcd10List_TYPE
 * raw_url: mgIcd10List_RAW_URL
 * @param code - 编码
 * @param description - 描述
 * @param mnemonicCode - 助记码
 * @param status - 状态
 * @param lastUpdaterPeopleId - 更新人id
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgIcd10List = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['mnemonicCode'] !== undefined) {
    queryParameters['mnemonicCode'] = parameters['mnemonicCode']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['lastUpdaterPeopleId'] !== undefined) {
    queryParameters['lastUpdaterPeopleId'] = parameters['lastUpdaterPeopleId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgIcd10ListFormData = function(formData) {
  const url = mgIcd10List_RAW_URL()
  const method = mgIcd10List_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10List_RAW_URL = function() {
  return '/management/icd10'
}
export const mgIcd10List_TYPE = function() {
  return 'get'
}
export const mgIcd10List_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10'
  if (parameters['code'] !== undefined) {
    queryParameters['code'] = parameters['code']
  }
  if (parameters['description'] !== undefined) {
    queryParameters['description'] = parameters['description']
  }
  if (parameters['mnemonicCode'] !== undefined) {
    queryParameters['mnemonicCode'] = parameters['mnemonicCode']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['lastUpdaterPeopleId'] !== undefined) {
    queryParameters['lastUpdaterPeopleId'] = parameters['lastUpdaterPeopleId']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加ICD10编码
 * request: mgIcd10Add
 * formData: mgIcd10AddFormData
 * url: mgIcd10Add_URL
 * method: mgIcd10Add_TYPE
 * raw_url: mgIcd10Add_RAW_URL
 * @param code - 编码
 * @param description - 描述
 * @param mnemonicCode - 助记码
 */
export const mgIcd10Add = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['description'] !== undefined) {
    form['description'] = parameters['description']
  }
  if (parameters['description'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: description'))
  }
  if (parameters['mnemonicCode'] !== undefined) {
    form['mnemonicCode'] = parameters['mnemonicCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgIcd10AddFormData = function(formData) {
  const url = mgIcd10Add_RAW_URL()
  const method = mgIcd10Add_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10Add_RAW_URL = function() {
  return '/management/icd10'
}
export const mgIcd10Add_TYPE = function() {
  return 'post'
}
export const mgIcd10Add_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * ICD10编码最后更新人选择列表
 * request: mgIcd10LastUpdaterPeopleList
 * formData: mgIcd10LastUpdaterPeopleListFormData
 * url: mgIcd10LastUpdaterPeopleList_URL
 * method: mgIcd10LastUpdaterPeopleList_TYPE
 * raw_url: mgIcd10LastUpdaterPeopleList_RAW_URL
 */
export const mgIcd10LastUpdaterPeopleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10/last-updater-people-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgIcd10LastUpdaterPeopleListFormData = function(formData) {
  const url = mgIcd10LastUpdaterPeopleList_RAW_URL()
  const method = mgIcd10LastUpdaterPeopleList_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10LastUpdaterPeopleList_RAW_URL = function() {
  return '/management/icd10/last-updater-people-list'
}
export const mgIcd10LastUpdaterPeopleList_TYPE = function() {
  return 'get'
}
export const mgIcd10LastUpdaterPeopleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10/last-updater-people-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改ICD10编码
 * request: mgIcd10Update
 * formData: mgIcd10UpdateFormData
 * url: mgIcd10Update_URL
 * method: mgIcd10Update_TYPE
 * raw_url: mgIcd10Update_RAW_URL
 * @param id - id
 * @param description - 描述
 * @param mnemonicCode - 助记码
 */
export const mgIcd10Update = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['description'] !== undefined) {
    form['description'] = parameters['description']
  }
  if (parameters['description'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: description'))
  }
  if (parameters['mnemonicCode'] !== undefined) {
    form['mnemonicCode'] = parameters['mnemonicCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgIcd10UpdateFormData = function(formData) {
  const url = mgIcd10Update_RAW_URL()
  const method = mgIcd10Update_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10Update_RAW_URL = function() {
  return '/management/icd10/{id}'
}
export const mgIcd10Update_TYPE = function() {
  return 'put'
}
export const mgIcd10Update_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用ICD10编码
 * request: mgIcd10Disable
 * formData: mgIcd10DisableFormData
 * url: mgIcd10Disable_URL
 * method: mgIcd10Disable_TYPE
 * raw_url: mgIcd10Disable_RAW_URL
 * @param id - id
 */
export const mgIcd10Disable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgIcd10DisableFormData = function(formData) {
  const url = mgIcd10Disable_RAW_URL()
  const method = mgIcd10Disable_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10Disable_RAW_URL = function() {
  return '/management/icd10/{id}/disable'
}
export const mgIcd10Disable_TYPE = function() {
  return 'put'
}
export const mgIcd10Disable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用ICD10编码
 * request: mgIcd10Enable
 * formData: mgIcd10EnableFormData
 * url: mgIcd10Enable_URL
 * method: mgIcd10Enable_TYPE
 * raw_url: mgIcd10Enable_RAW_URL
 * @param id - id
 */
export const mgIcd10Enable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/icd10/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgIcd10EnableFormData = function(formData) {
  const url = mgIcd10Enable_RAW_URL()
  const method = mgIcd10Enable_TYPE()
  return axios[method](url, formData)
}
export const mgIcd10Enable_RAW_URL = function() {
  return '/management/icd10/{id}/enable'
}
export const mgIcd10Enable_TYPE = function() {
  return 'put'
}
export const mgIcd10Enable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/icd10/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区配置列表
 * request: mgRegionConfigList
 * formData: mgRegionConfigListFormData
 * url: mgRegionConfigList_URL
 * method: mgRegionConfigList_TYPE
 * raw_url: mgRegionConfigList_RAW_URL
 * @param parentRegionCode - 父级地区编码
 * @param regionCode - 地区编码
 * @param checked - 根据parentRegionCode查找，包含自己就传
 */
export const mgRegionConfigList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/region-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentRegionCode'] !== undefined) {
    queryParameters['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgRegionConfigListFormData = function(formData) {
  const url = mgRegionConfigList_RAW_URL()
  const method = mgRegionConfigList_TYPE()
  return axios[method](url, formData)
}
export const mgRegionConfigList_RAW_URL = function() {
  return '/management/region-config'
}
export const mgRegionConfigList_TYPE = function() {
  return 'get'
}
export const mgRegionConfigList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/region-config'
  if (parameters['parentRegionCode'] !== undefined) {
    queryParameters['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加地区配置
 * request: mgRegionConfigAdd
 * formData: mgRegionConfigAddFormData
 * url: mgRegionConfigAdd_URL
 * method: mgRegionConfigAdd_TYPE
 * raw_url: mgRegionConfigAdd_RAW_URL
 * @param parentRegionCode - 父级地区编码
 * @param regionCode - 地区编码
 * @param code - 编码
 * @param monitoringPoint - 监测点
 */
export const mgRegionConfigAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/region-config'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['parentRegionCode'] !== undefined) {
    form['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    form['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['monitoringPoint'] !== undefined) {
    form['monitoringPoint'] = parameters['monitoringPoint']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const mgRegionConfigAddFormData = function(formData) {
  const url = mgRegionConfigAdd_RAW_URL()
  const method = mgRegionConfigAdd_TYPE()
  return axios[method](url, formData)
}
export const mgRegionConfigAdd_RAW_URL = function() {
  return '/management/region-config'
}
export const mgRegionConfigAdd_TYPE = function() {
  return 'post'
}
export const mgRegionConfigAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/region-config'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取地区配置
 * request: mgRegionConfigFind
 * formData: mgRegionConfigFindFormData
 * url: mgRegionConfigFind_URL
 * method: mgRegionConfigFind_TYPE
 * raw_url: mgRegionConfigFind_RAW_URL
 * @param regionConfigId - regionConfigId
 */
export const mgRegionConfigFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/region-config/{regionConfigId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionConfigId}', `${parameters['regionConfigId']}`)
  if (parameters['regionConfigId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionConfigId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgRegionConfigFindFormData = function(formData) {
  const url = mgRegionConfigFind_RAW_URL()
  const method = mgRegionConfigFind_TYPE()
  return axios[method](url, formData)
}
export const mgRegionConfigFind_RAW_URL = function() {
  return '/management/region-config/{regionConfigId}'
}
export const mgRegionConfigFind_TYPE = function() {
  return 'get'
}
export const mgRegionConfigFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/region-config/{regionConfigId}'
  path = path.replace('{regionConfigId}', `${parameters['regionConfigId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改地区配置
 * request: mgRegionConfigUpdate
 * formData: mgRegionConfigUpdateFormData
 * url: mgRegionConfigUpdate_URL
 * method: mgRegionConfigUpdate_TYPE
 * raw_url: mgRegionConfigUpdate_RAW_URL
 * @param regionConfigId - regionConfigId
 * @param parentRegionCode - 父级地区编码
 * @param regionCode - 地区编码
 * @param code - 编码
 * @param monitoringPoint - 监测点
 */
export const mgRegionConfigUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/region-config/{regionConfigId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionConfigId}', `${parameters['regionConfigId']}`)
  if (parameters['regionConfigId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionConfigId'))
  }
  if (parameters['parentRegionCode'] !== undefined) {
    form['parentRegionCode'] = parameters['parentRegionCode']
  }
  if (parameters['regionCode'] !== undefined) {
    form['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['monitoringPoint'] !== undefined) {
    form['monitoringPoint'] = parameters['monitoringPoint']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgRegionConfigUpdateFormData = function(formData) {
  const url = mgRegionConfigUpdate_RAW_URL()
  const method = mgRegionConfigUpdate_TYPE()
  return axios[method](url, formData)
}
export const mgRegionConfigUpdate_RAW_URL = function() {
  return '/management/region-config/{regionConfigId}'
}
export const mgRegionConfigUpdate_TYPE = function() {
  return 'put'
}
export const mgRegionConfigUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/region-config/{regionConfigId}'
  path = path.replace('{regionConfigId}', `${parameters['regionConfigId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划列表
 * request: mgScreeningPlanList
 * formData: mgScreeningPlanListFormData
 * url: mgScreeningPlanList_URL
 * method: mgScreeningPlanList_TYPE
 * raw_url: mgScreeningPlanList_RAW_URL
 * @param status - 计划状态
 * @param planName - 计划名称
 * @param planType - 计划类型
 * @param inputType - 录入类型
 * @param startTime - 开始时间
 * @param endTime - 结束时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const mgScreeningPlanList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['startTime'] !== undefined) {
    queryParameters['startTime'] = parameters['startTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const mgScreeningPlanListFormData = function(formData) {
  const url = mgScreeningPlanList_RAW_URL()
  const method = mgScreeningPlanList_TYPE()
  return axios[method](url, formData)
}
export const mgScreeningPlanList_RAW_URL = function() {
  return '/management/screening-plan'
}
export const mgScreeningPlanList_TYPE = function() {
  return 'get'
}
export const mgScreeningPlanList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/screening-plan'
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['inputType'] !== undefined) {
    queryParameters['inputType'] = parameters['inputType']
  }
  if (parameters['startTime'] !== undefined) {
    queryParameters['startTime'] = parameters['startTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用筛查计划
 * request: mgScreeningPlanDisable
 * formData: mgScreeningPlanDisableFormData
 * url: mgScreeningPlanDisable_URL
 * method: mgScreeningPlanDisable_TYPE
 * raw_url: mgScreeningPlanDisable_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const mgScreeningPlanDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/management/screening-plan/{screeningPlanId}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const mgScreeningPlanDisableFormData = function(formData) {
  const url = mgScreeningPlanDisable_RAW_URL()
  const method = mgScreeningPlanDisable_TYPE()
  return axios[method](url, formData)
}
export const mgScreeningPlanDisable_RAW_URL = function() {
  return '/management/screening-plan/{screeningPlanId}/disable'
}
export const mgScreeningPlanDisable_TYPE = function() {
  return 'put'
}
export const mgScreeningPlanDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/management/screening-plan/{screeningPlanId}/disable'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: schAdministratorList
 * formData: schAdministratorListFormData
 * url: schAdministratorList_URL
 * method: schAdministratorList_TYPE
 * raw_url: schAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schAdministratorListFormData = function(formData) {
  const url = schAdministratorList_RAW_URL()
  const method = schAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorList_RAW_URL = function() {
  return '/school/administrator'
}
export const schAdministratorList_TYPE = function() {
  return 'get'
}
export const schAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: schAdministratorAdd
 * formData: schAdministratorAddFormData
 * url: schAdministratorAdd_URL
 * method: schAdministratorAdd_TYPE
 * raw_url: schAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const schAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schAdministratorAddFormData = function(formData) {
  const url = schAdministratorAdd_RAW_URL()
  const method = schAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorAdd_RAW_URL = function() {
  return '/school/administrator'
}
export const schAdministratorAdd_TYPE = function() {
  return 'post'
}
export const schAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: schAdministratorAllChoiceList
 * formData: schAdministratorAllChoiceListFormData
 * url: schAdministratorAllChoiceList_URL
 * method: schAdministratorAllChoiceList_TYPE
 * raw_url: schAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const schAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schAdministratorAllChoiceListFormData = function(formData) {
  const url = schAdministratorAllChoiceList_RAW_URL()
  const method = schAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorAllChoiceList_RAW_URL = function() {
  return '/school/administrator/all-choice'
}
export const schAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const schAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: schImportAdministrator
 * formData: schImportAdministratorFormData
 * url: schImportAdministrator_URL
 * method: schImportAdministrator_TYPE
 * raw_url: schImportAdministrator_RAW_URL
 * @param file - 
 */
export const schImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schImportAdministratorFormData = function(formData) {
  const url = schImportAdministrator_RAW_URL()
  const method = schImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const schImportAdministrator_RAW_URL = function() {
  return '/school/administrator/import'
}
export const schImportAdministrator_TYPE = function() {
  return 'post'
}
export const schImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: schAdministratorDelete
 * formData: schAdministratorDeleteFormData
 * url: schAdministratorDelete_URL
 * method: schAdministratorDelete_TYPE
 * raw_url: schAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const schAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schAdministratorDeleteFormData = function(formData) {
  const url = schAdministratorDelete_RAW_URL()
  const method = schAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorDelete_RAW_URL = function() {
  return '/school/administrator/{administratorId}'
}
export const schAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const schAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: schAdministratorListRole
 * formData: schAdministratorListRoleFormData
 * url: schAdministratorListRole_URL
 * method: schAdministratorListRole_TYPE
 * raw_url: schAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const schAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schAdministratorListRoleFormData = function(formData) {
  const url = schAdministratorListRole_RAW_URL()
  const method = schAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorListRole_RAW_URL = function() {
  return '/school/administrator/{administratorId}/role'
}
export const schAdministratorListRole_TYPE = function() {
  return 'get'
}
export const schAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: schAdministratorAddRole
 * formData: schAdministratorAddRoleFormData
 * url: schAdministratorAddRole_URL
 * method: schAdministratorAddRole_TYPE
 * raw_url: schAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const schAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schAdministratorAddRoleFormData = function(formData) {
  const url = schAdministratorAddRole_RAW_URL()
  const method = schAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorAddRole_RAW_URL = function() {
  return '/school/administrator/{administratorId}/role'
}
export const schAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const schAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: schAdministratorDeleteRole
 * formData: schAdministratorDeleteRoleFormData
 * url: schAdministratorDeleteRole_URL
 * method: schAdministratorDeleteRole_TYPE
 * raw_url: schAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const schAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/administrator/{administratorId}/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schAdministratorDeleteRoleFormData = function(formData) {
  const url = schAdministratorDeleteRole_RAW_URL()
  const method = schAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const schAdministratorDeleteRole_RAW_URL = function() {
  return '/school/administrator/{administratorId}/role/{roleId}'
}
export const schAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const schAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/administrator/{administratorId}/role/{roleId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章列表
 * request: schArticleList
 * formData: schArticleListFormData
 * url: schArticleList_URL
 * method: schArticleList_TYPE
 * raw_url: schArticleList_RAW_URL
 * @param categoryId - 类别id
 * @param title - 标题
 * @param readerType - 阅读者类型
 * @param intro - 简介
 * @param label - 宣教标签
 * @param creatorName - 创建人姓名
 * @param createTime - 创建时间
 * @param auditorName - 审核人
 * @param auditTime - 审核时间
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schArticleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleListFormData = function(formData) {
  const url = schArticleList_RAW_URL()
  const method = schArticleList_TYPE()
  return axios[method](url, formData)
}
export const schArticleList_RAW_URL = function() {
  return '/school/article'
}
export const schArticleList_TYPE = function() {
  return 'get'
}
export const schArticleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article'
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章
 * request: schArticleAdd
 * formData: schArticleAddFormData
 * url: schArticleAdd_URL
 * method: schArticleAdd_TYPE
 * raw_url: schArticleAdd_RAW_URL
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const schArticleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schArticleAddFormData = function(formData) {
  const url = schArticleAdd_RAW_URL()
  const method = schArticleAdd_TYPE()
  return axios[method](url, formData)
}
export const schArticleAdd_RAW_URL = function() {
  return '/school/article'
}
export const schArticleAdd_TYPE = function() {
  return 'post'
}
export const schArticleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传图片
 * request: schArticleUploadFile
 * formData: schArticleUploadFileFormData
 * url: schArticleUploadFile_URL
 * method: schArticleUploadFile_TYPE
 * raw_url: schArticleUploadFile_RAW_URL
 * @param file - 
 * @param image - 
 */
export const schArticleUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schArticleUploadFileFormData = function(formData) {
  const url = schArticleUploadFile_RAW_URL()
  const method = schArticleUploadFile_TYPE()
  return axios[method](url, formData)
}
export const schArticleUploadFile_RAW_URL = function() {
  return '/school/article/file'
}
export const schArticleUploadFile_TYPE = function() {
  return 'post'
}
export const schArticleUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/file'
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章预警关联列表
 * request: schArticleEarlyWarningList
 * formData: schArticleEarlyWarningListFormData
 * url: schArticleEarlyWarningList_URL
 * method: schArticleEarlyWarningList_TYPE
 * raw_url: schArticleEarlyWarningList_RAW_URL
 * @param articleId - articleId
 */
export const schArticleEarlyWarningList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleEarlyWarningListFormData = function(formData) {
  const url = schArticleEarlyWarningList_RAW_URL()
  const method = schArticleEarlyWarningList_TYPE()
  return axios[method](url, formData)
}
export const schArticleEarlyWarningList_RAW_URL = function() {
  return '/school/article/{articleId}/early-warning'
}
export const schArticleEarlyWarningList_TYPE = function() {
  return 'get'
}
export const schArticleEarlyWarningList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章预警关联
 * request: schArticleEarlyWarningAdd
 * formData: schArticleEarlyWarningAddFormData
 * url: schArticleEarlyWarningAdd_URL
 * method: schArticleEarlyWarningAdd_TYPE
 * raw_url: schArticleEarlyWarningAdd_RAW_URL
 * @param articleId - articleId
 * @param earlyWarningType - 
 * @param earlyWarningLevel - 
 */
export const schArticleEarlyWarningAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters['earlyWarningType'] !== undefined) {
    form['earlyWarningType'] = parameters['earlyWarningType']
  }
  if (parameters['earlyWarningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningType'))
  }
  if (parameters['earlyWarningLevel'] !== undefined) {
    form['earlyWarningLevel'] = parameters['earlyWarningLevel']
  }
  if (parameters['earlyWarningLevel'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningLevel'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schArticleEarlyWarningAddFormData = function(formData) {
  const url = schArticleEarlyWarningAdd_RAW_URL()
  const method = schArticleEarlyWarningAdd_TYPE()
  return axios[method](url, formData)
}
export const schArticleEarlyWarningAdd_RAW_URL = function() {
  return '/school/article/{articleId}/early-warning'
}
export const schArticleEarlyWarningAdd_TYPE = function() {
  return 'post'
}
export const schArticleEarlyWarningAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章预警关联
 * request: schArticleEarlyWarningDelete
 * formData: schArticleEarlyWarningDeleteFormData
 * url: schArticleEarlyWarningDelete_URL
 * method: schArticleEarlyWarningDelete_TYPE
 * raw_url: schArticleEarlyWarningDelete_RAW_URL
 * @param articleId - articleId
 * @param id - id
 */
export const schArticleEarlyWarningDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{articleId}/early-warning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schArticleEarlyWarningDeleteFormData = function(formData) {
  const url = schArticleEarlyWarningDelete_RAW_URL()
  const method = schArticleEarlyWarningDelete_TYPE()
  return axios[method](url, formData)
}
export const schArticleEarlyWarningDelete_RAW_URL = function() {
  return '/school/article/{articleId}/early-warning/{id}'
}
export const schArticleEarlyWarningDelete_TYPE = function() {
  return 'delete'
}
export const schArticleEarlyWarningDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{articleId}/early-warning/{id}'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章详情
 * request: schArticleFind
 * formData: schArticleFindFormData
 * url: schArticleFind_URL
 * method: schArticleFind_TYPE
 * raw_url: schArticleFind_RAW_URL
 * @param id - id
 */
export const schArticleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleFindFormData = function(formData) {
  const url = schArticleFind_RAW_URL()
  const method = schArticleFind_TYPE()
  return axios[method](url, formData)
}
export const schArticleFind_RAW_URL = function() {
  return '/school/article/{id}'
}
export const schArticleFind_TYPE = function() {
  return 'get'
}
export const schArticleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改科普文章
 * request: schArticleUpdate
 * formData: schArticleUpdateFormData
 * url: schArticleUpdate_URL
 * method: schArticleUpdate_TYPE
 * raw_url: schArticleUpdate_RAW_URL
 * @param id - id
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const schArticleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schArticleUpdateFormData = function(formData) {
  const url = schArticleUpdate_RAW_URL()
  const method = schArticleUpdate_TYPE()
  return axios[method](url, formData)
}
export const schArticleUpdate_RAW_URL = function() {
  return '/school/article/{id}'
}
export const schArticleUpdate_TYPE = function() {
  return 'put'
}
export const schArticleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章
 * request: schArticleDelete
 * formData: schArticleDeleteFormData
 * url: schArticleDelete_URL
 * method: schArticleDelete_TYPE
 * raw_url: schArticleDelete_RAW_URL
 * @param id - id
 */
export const schArticleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schArticleDeleteFormData = function(formData) {
  const url = schArticleDelete_RAW_URL()
  const method = schArticleDelete_TYPE()
  return axios[method](url, formData)
}
export const schArticleDelete_RAW_URL = function() {
  return '/school/article/{id}'
}
export const schArticleDelete_TYPE = function() {
  return 'delete'
}
export const schArticleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 草稿科普文章
 * request: schArticleDraft
 * formData: schArticleDraftFormData
 * url: schArticleDraft_URL
 * method: schArticleDraft_TYPE
 * raw_url: schArticleDraft_RAW_URL
 * @param id - id
 */
export const schArticleDraft = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/draft'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schArticleDraftFormData = function(formData) {
  const url = schArticleDraft_RAW_URL()
  const method = schArticleDraft_TYPE()
  return axios[method](url, formData)
}
export const schArticleDraft_RAW_URL = function() {
  return '/school/article/{id}/draft'
}
export const schArticleDraft_TYPE = function() {
  return 'put'
}
export const schArticleDraft_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/draft'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 下架科普文章
 * request: schArticleLowerShelf
 * formData: schArticleLowerShelfFormData
 * url: schArticleLowerShelf_URL
 * method: schArticleLowerShelf_TYPE
 * raw_url: schArticleLowerShelf_RAW_URL
 * @param id - id
 */
export const schArticleLowerShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/lower-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schArticleLowerShelfFormData = function(formData) {
  const url = schArticleLowerShelf_RAW_URL()
  const method = schArticleLowerShelf_TYPE()
  return axios[method](url, formData)
}
export const schArticleLowerShelf_RAW_URL = function() {
  return '/school/article/{id}/lower-shelf'
}
export const schArticleLowerShelf_TYPE = function() {
  return 'put'
}
export const schArticleLowerShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/lower-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发布科普文章
 * request: schArticlePublish
 * formData: schArticlePublishFormData
 * url: schArticlePublish_URL
 * method: schArticlePublish_TYPE
 * raw_url: schArticlePublish_RAW_URL
 * @param id - id
 */
export const schArticlePublish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/publish'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schArticlePublishFormData = function(formData) {
  const url = schArticlePublish_RAW_URL()
  const method = schArticlePublish_TYPE()
  return axios[method](url, formData)
}
export const schArticlePublish_RAW_URL = function() {
  return '/school/article/{id}/publish'
}
export const schArticlePublish_TYPE = function() {
  return 'put'
}
export const schArticlePublish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/publish'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章阅读学校统计
 * request: schArticleReadSchoolStats
 * formData: schArticleReadSchoolStatsFormData
 * url: schArticleReadSchoolStats_URL
 * method: schArticleReadSchoolStats_TYPE
 * raw_url: schArticleReadSchoolStats_RAW_URL
 * @param id - id
 */
export const schArticleReadSchoolStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/read-school-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleReadSchoolStatsFormData = function(formData) {
  const url = schArticleReadSchoolStats_RAW_URL()
  const method = schArticleReadSchoolStats_TYPE()
  return axios[method](url, formData)
}
export const schArticleReadSchoolStats_RAW_URL = function() {
  return '/school/article/{id}/read-school-stats'
}
export const schArticleReadSchoolStats_TYPE = function() {
  return 'get'
}
export const schArticleReadSchoolStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/read-school-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章未阅读学校
 * request: schArticleUnreadSchool
 * formData: schArticleUnreadSchoolFormData
 * url: schArticleUnreadSchool_URL
 * method: schArticleUnreadSchool_TYPE
 * raw_url: schArticleUnreadSchool_RAW_URL
 * @param id - id
 */
export const schArticleUnreadSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/unread-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleUnreadSchoolFormData = function(formData) {
  const url = schArticleUnreadSchool_RAW_URL()
  const method = schArticleUnreadSchool_TYPE()
  return axios[method](url, formData)
}
export const schArticleUnreadSchool_RAW_URL = function() {
  return '/school/article/{id}/unread-school'
}
export const schArticleUnreadSchool_TYPE = function() {
  return 'get'
}
export const schArticleUnreadSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/unread-school'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上架科普文章
 * request: schArticleUpperShelf
 * formData: schArticleUpperShelfFormData
 * url: schArticleUpperShelf_URL
 * method: schArticleUpperShelf_TYPE
 * raw_url: schArticleUpperShelf_RAW_URL
 * @param id - id
 */
export const schArticleUpperShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/upper-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schArticleUpperShelfFormData = function(formData) {
  const url = schArticleUpperShelf_RAW_URL()
  const method = schArticleUpperShelf_TYPE()
  return axios[method](url, formData)
}
export const schArticleUpperShelf_RAW_URL = function() {
  return '/school/article/{id}/upper-shelf'
}
export const schArticleUpperShelf_TYPE = function() {
  return 'put'
}
export const schArticleUpperShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/upper-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章1周内用户阅读统计
 * request: schArticleReadWeekUserStats
 * formData: schArticleReadWeekUserStatsFormData
 * url: schArticleReadWeekUserStats_URL
 * method: schArticleReadWeekUserStats_TYPE
 * raw_url: schArticleReadWeekUserStats_RAW_URL
 * @param id - id
 */
export const schArticleReadWeekUserStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/article/{id}/week-user-read-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schArticleReadWeekUserStatsFormData = function(formData) {
  const url = schArticleReadWeekUserStats_RAW_URL()
  const method = schArticleReadWeekUserStats_TYPE()
  return axios[method](url, formData)
}
export const schArticleReadWeekUserStats_RAW_URL = function() {
  return '/school/article/{id}/week-user-read-stats'
}
export const schArticleReadWeekUserStats_TYPE = function() {
  return 'get'
}
export const schArticleReadWeekUserStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/article/{id}/week-user-read-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存开展学校卫生工作情况调查表
 * request: schCosswqSave
 * formData: schCosswqSaveFormData
 * url: schCosswqSave_URL
 * method: schCosswqSave_TYPE
 * raw_url: schCosswqSave_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param preparer - 
 * @param preparerPhone - 
 * @param reviewedBy - 
 * @param reviewedByPhone - 
 * @param date - 
 * @param a01 - 
 * @param a01OtherDescription - 当A01选中选项3时必填，反之不填
 * @param a02 - 
 * @param a03 - 
 * @param a03OtherDescription - 当A03选中选项8时必填，反之不填
 * @param a041 - 
 * @param a042 - 
 * @param a043 - 
 * @param a044 - 
 * @param a045 - 
 * @param a046 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b0511 - 当B05的值大于0时必填，反之不填
 * @param b0512 - 当B05的值大于0时必填，反之不填
 * @param b0513 - 当B05的值大于0时必填，反之不填
 * @param b0514 - 当B05的值大于0时必填，反之不填
 * @param b0515 - 当B05的值大于0时必填，反之不填
 * @param b0516 - 当B05的值大于0时必填，反之不填
 * @param b0517 - 当B05的值大于0时必填，反之不填
 * @param b0521 - 当B05的值大于1时必填，反之不填
 * @param b0522 - 当B05的值大于1时必填，反之不填
 * @param b0523 - 当B05的值大于1时必填，反之不填
 * @param b0524 - 当B05的值大于1时必填，反之不填
 * @param b0525 - 当B05的值大于1时必填，反之不填
 * @param b0526 - 当B05的值大于1时必填，反之不填
 * @param b0527 - 当B05的值大于1时必填，反之不填
 * @param b0531 - 当B05的值大于2时必填，反之不填
 * @param b0532 - 当B05的值大于2时必填，反之不填
 * @param b0533 - 当B05的值大于2时必填，反之不填
 * @param b0534 - 当B05的值大于2时必填，反之不填
 * @param b0535 - 当B05的值大于2时必填，反之不填
 * @param b0536 - 当B05的值大于2时必填，反之不填
 * @param b0537 - 当B05的值大于2时必填，反之不填
 * @param b0541 - 当B05的值大于3时必填，反之不填
 * @param b0542 - 当B05的值大于3时必填，反之不填
 * @param b0543 - 当B05的值大于3时必填，反之不填
 * @param b0544 - 当B05的值大于3时必填，反之不填
 * @param b0545 - 当B05的值大于3时必填，反之不填
 * @param b0546 - 当B05的值大于3时必填，反之不填
 * @param b0547 - 当B05的值大于3时必填，反之不填
 * @param b06 - 
 * @param b07 - 
 * @param c01 - 
 * @param c01Frequency - 当C01选中选项2时必填，反之不填
 * @param c011 - 当C01不选中选项3时必填，反之不填
 * @param c011OtherDescription - 当C011选中包括选项9时必填，反之不填
 * @param c012 - 当C01不选中选项3时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c03 - 
 * @param d01 - 
 * @param d0111 - 当D01不选中选项1时必填，反之不填
 * @param d0112 - 当D01不选中选项1时必填，反之不填
 * @param d0112OtherDescription - 当D0112选中包括选项6时必填，反之不填
 * @param d012 - 当D01不选中选项1时必填，反之不填
 * @param d013 - 当D01不选中选项1时必填，反之不填
 * @param d02 - 
 * @param e01 - 
 * @param e011 - 当E01选中选项1时必填，反之不填
 * @param e011OtherDescription - 当E011选中包括选项11时必填，反之不填
 * @param e02 - 当E01选中选项1时必填，反之不填
 * @param e03 - 当E01选中选项1时必填，反之不填
 * @param e04 - 
 * @param e05 - 
 * @param e0501 - 
 * @param e0501OtherDescription - 
 * @param e06 - 
 * @param e07 - 
 * @param e0701 - 
 * @param e0701OtherDescription - 
 * @param f0111 - 
 * @param f0112 - 
 * @param f0113 - 
 * @param f0114 - 
 * @param f0121 - 
 * @param f0122 - 
 * @param f0131 - 
 * @param f0132 - 
 * @param f021 - 
 * @param f022 - 
 * @param g01 - 
 * @param g011 - 当G01选中选项3或4时必填，反之不填
 * @param g02 - 当G01不选中选项1时必填，反之不填
 * @param g021 - 当G02选中选项1时必填，反之不填
 * @param g03 - 
 * @param g031 - 当G03选中选项1时必填，反之不填
 * @param g032 - 当G03选中选项1时必填，反之不填
 * @param h01 - 
 * @param h011 - 当H01的值大于0时必填，反之不填
 * @param h011OtherDescription - 当H011选中选项4时必填，反之不填
 * @param h012 - 当H01的值大于0时必填，反之不填
 * @param h012OtherDescription - 当H012包括选中选项7时必填，反之不填
 * @param h02 - 是否按要求采购普通教室照明灯具？
 * @param h021 - 主要对采购的照明灯具的哪方面做限定(可多选)
 * @param h021Description - 主要对采购的照明灯具的哪方面做限定其它说明
 * @param h03 - 是否按要求采购书籍、教辅材料和学习用杂志？
 * @param h031 - 主要对采购的书籍、教辅材料和学习用杂志的哪方面做限定(可多选)
 * @param h031Description - 主要对采购的书籍、教辅材料和学习用杂志的哪方面做限定其它说明
 * @param h04 - 是否按要求购买或自制考卷？
 * @param h041 - 主要对考卷的哪些方面提出要求(可多选)
 * @param h041Description - 主要对考卷的哪些方面提出要求其它说明
 * @param j020 - 
 * @param j03 - 
 * @param j040 - 
 * @param j050 - 
 * @param j06 - 学校是否开展传染病监测预警相关工作？
 * @param k01 - 学校是否收到疾控部门或卫健局(委)与教育局(教委)下发的学生常见病和健康影响因素干预实施方案?
 * @param k02 - 学校是否制定了本校的学生常见病和健康影响因素干预实施方案?
 * @param k03 - 学校开展了下面哪些干预活动?
 * @param k04 - 疾控部门是否对学校开展的干预活动进行了评估?
 */
export const schCosswqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/cosswq/save/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['preparer'] !== undefined) {
    form['preparer'] = parameters['preparer']
  }
  if (parameters['preparer'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: preparer'))
  }
  if (parameters['preparerPhone'] !== undefined) {
    form['preparerPhone'] = parameters['preparerPhone']
  }
  if (parameters['preparerPhone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: preparerPhone'))
  }
  if (parameters['reviewedBy'] !== undefined) {
    form['reviewedBy'] = parameters['reviewedBy']
  }
  if (parameters['reviewedBy'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reviewedBy'))
  }
  if (parameters['reviewedByPhone'] !== undefined) {
    form['reviewedByPhone'] = parameters['reviewedByPhone']
  }
  if (parameters['reviewedByPhone'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: reviewedByPhone'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a01'] !== undefined) {
    form['a01'] = parameters['a01']
  }
  if (parameters['a01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a01'))
  }
  if (parameters['a01OtherDescription'] !== undefined) {
    form['a01OtherDescription'] = parameters['a01OtherDescription']
  }
  if (parameters['a02'] !== undefined) {
    form['a02'] = parameters['a02']
  }
  if (parameters['a02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a02'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a03OtherDescription'] !== undefined) {
    form['a03OtherDescription'] = parameters['a03OtherDescription']
  }
  if (parameters['a041'] !== undefined) {
    form['a041'] = parameters['a041']
  }
  if (parameters['a041'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a041'))
  }
  if (parameters['a042'] !== undefined) {
    form['a042'] = parameters['a042']
  }
  if (parameters['a042'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a042'))
  }
  if (parameters['a043'] !== undefined) {
    form['a043'] = parameters['a043']
  }
  if (parameters['a043'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a043'))
  }
  if (parameters['a044'] !== undefined) {
    form['a044'] = parameters['a044']
  }
  if (parameters['a044'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a044'))
  }
  if (parameters['a045'] !== undefined) {
    form['a045'] = parameters['a045']
  }
  if (parameters['a045'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a045'))
  }
  if (parameters['a046'] !== undefined) {
    form['a046'] = parameters['a046']
  }
  if (parameters['a046'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a046'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b0511'] !== undefined) {
    form['b0511'] = parameters['b0511']
  }
  if (parameters['b0512'] !== undefined) {
    form['b0512'] = parameters['b0512']
  }
  if (parameters['b0513'] !== undefined) {
    form['b0513'] = parameters['b0513']
  }
  if (parameters['b0514'] !== undefined) {
    form['b0514'] = parameters['b0514']
  }
  if (parameters['b0515'] !== undefined) {
    form['b0515'] = parameters['b0515']
  }
  if (parameters['b0516'] !== undefined) {
    form['b0516'] = parameters['b0516']
  }
  if (parameters['b0517'] !== undefined) {
    form['b0517'] = parameters['b0517']
  }
  if (parameters['b0521'] !== undefined) {
    form['b0521'] = parameters['b0521']
  }
  if (parameters['b0522'] !== undefined) {
    form['b0522'] = parameters['b0522']
  }
  if (parameters['b0523'] !== undefined) {
    form['b0523'] = parameters['b0523']
  }
  if (parameters['b0524'] !== undefined) {
    form['b0524'] = parameters['b0524']
  }
  if (parameters['b0525'] !== undefined) {
    form['b0525'] = parameters['b0525']
  }
  if (parameters['b0526'] !== undefined) {
    form['b0526'] = parameters['b0526']
  }
  if (parameters['b0527'] !== undefined) {
    form['b0527'] = parameters['b0527']
  }
  if (parameters['b0531'] !== undefined) {
    form['b0531'] = parameters['b0531']
  }
  if (parameters['b0532'] !== undefined) {
    form['b0532'] = parameters['b0532']
  }
  if (parameters['b0533'] !== undefined) {
    form['b0533'] = parameters['b0533']
  }
  if (parameters['b0534'] !== undefined) {
    form['b0534'] = parameters['b0534']
  }
  if (parameters['b0535'] !== undefined) {
    form['b0535'] = parameters['b0535']
  }
  if (parameters['b0536'] !== undefined) {
    form['b0536'] = parameters['b0536']
  }
  if (parameters['b0537'] !== undefined) {
    form['b0537'] = parameters['b0537']
  }
  if (parameters['b0541'] !== undefined) {
    form['b0541'] = parameters['b0541']
  }
  if (parameters['b0542'] !== undefined) {
    form['b0542'] = parameters['b0542']
  }
  if (parameters['b0543'] !== undefined) {
    form['b0543'] = parameters['b0543']
  }
  if (parameters['b0544'] !== undefined) {
    form['b0544'] = parameters['b0544']
  }
  if (parameters['b0545'] !== undefined) {
    form['b0545'] = parameters['b0545']
  }
  if (parameters['b0546'] !== undefined) {
    form['b0546'] = parameters['b0546']
  }
  if (parameters['b0547'] !== undefined) {
    form['b0547'] = parameters['b0547']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Frequency'] !== undefined) {
    form['c01Frequency'] = parameters['c01Frequency']
  }
  if (parameters['c011'] !== undefined) {
    form['c011'] = parameters['c011']
  }
  if (parameters['c011OtherDescription'] !== undefined) {
    form['c011OtherDescription'] = parameters['c011OtherDescription']
  }
  if (parameters['c012'] !== undefined) {
    form['c012'] = parameters['c012']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d0111'] !== undefined) {
    form['d0111'] = parameters['d0111']
  }
  if (parameters['d0112'] !== undefined) {
    form['d0112'] = parameters['d0112']
  }
  if (parameters['d0112OtherDescription'] !== undefined) {
    form['d0112OtherDescription'] = parameters['d0112OtherDescription']
  }
  if (parameters['d012'] !== undefined) {
    form['d012'] = parameters['d012']
  }
  if (parameters['d013'] !== undefined) {
    form['d013'] = parameters['d013']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e011OtherDescription'] !== undefined) {
    form['e011OtherDescription'] = parameters['e011OtherDescription']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['e05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e05'))
  }
  if (parameters['e0501'] !== undefined) {
    form['e0501'] = parameters['e0501']
  }
  if (parameters['e0501OtherDescription'] !== undefined) {
    form['e0501OtherDescription'] = parameters['e0501OtherDescription']
  }
  if (parameters['e06'] !== undefined) {
    form['e06'] = parameters['e06']
  }
  if (parameters['e06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e06'))
  }
  if (parameters['e07'] !== undefined) {
    form['e07'] = parameters['e07']
  }
  if (parameters['e07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e07'))
  }
  if (parameters['e0701'] !== undefined) {
    form['e0701'] = parameters['e0701']
  }
  if (parameters['e0701OtherDescription'] !== undefined) {
    form['e0701OtherDescription'] = parameters['e0701OtherDescription']
  }
  if (parameters['f0111'] !== undefined) {
    form['f0111'] = parameters['f0111']
  }
  if (parameters['f0111'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0111'))
  }
  if (parameters['f0112'] !== undefined) {
    form['f0112'] = parameters['f0112']
  }
  if (parameters['f0112'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0112'))
  }
  if (parameters['f0113'] !== undefined) {
    form['f0113'] = parameters['f0113']
  }
  if (parameters['f0113'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0113'))
  }
  if (parameters['f0114'] !== undefined) {
    form['f0114'] = parameters['f0114']
  }
  if (parameters['f0114'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0114'))
  }
  if (parameters['f0121'] !== undefined) {
    form['f0121'] = parameters['f0121']
  }
  if (parameters['f0121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0121'))
  }
  if (parameters['f0122'] !== undefined) {
    form['f0122'] = parameters['f0122']
  }
  if (parameters['f0122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0122'))
  }
  if (parameters['f0131'] !== undefined) {
    form['f0131'] = parameters['f0131']
  }
  if (parameters['f0131'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0131'))
  }
  if (parameters['f0132'] !== undefined) {
    form['f0132'] = parameters['f0132']
  }
  if (parameters['f0132'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f0132'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f021'))
  }
  if (parameters['f022'] !== undefined) {
    form['f022'] = parameters['f022']
  }
  if (parameters['f022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f022'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g011'] !== undefined) {
    form['g011'] = parameters['g011']
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g021'] !== undefined) {
    form['g021'] = parameters['g021']
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g031'] !== undefined) {
    form['g031'] = parameters['g031']
  }
  if (parameters['g032'] !== undefined) {
    form['g032'] = parameters['g032']
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h011'] !== undefined) {
    form['h011'] = parameters['h011']
  }
  if (parameters['h011OtherDescription'] !== undefined) {
    form['h011OtherDescription'] = parameters['h011OtherDescription']
  }
  if (parameters['h012'] !== undefined) {
    form['h012'] = parameters['h012']
  }
  if (parameters['h012OtherDescription'] !== undefined) {
    form['h012OtherDescription'] = parameters['h012OtherDescription']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h021'] !== undefined) {
    form['h021'] = parameters['h021']
  }
  if (parameters['h021Description'] !== undefined) {
    form['h021Description'] = parameters['h021Description']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h031'] !== undefined) {
    form['h031'] = parameters['h031']
  }
  if (parameters['h031Description'] !== undefined) {
    form['h031Description'] = parameters['h031Description']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h041'] !== undefined) {
    form['h041'] = parameters['h041']
  }
  if (parameters['h041Description'] !== undefined) {
    form['h041Description'] = parameters['h041Description']
  }
  if (parameters['j020'] !== undefined) {
    form['j020'] = parameters['j020']
  }
  if (parameters['j020'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j020'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j040'] !== undefined) {
    form['j040'] = parameters['j040']
  }
  if (parameters['j040'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j040'))
  }
  if (parameters['j050'] !== undefined) {
    form['j050'] = parameters['j050']
  }
  if (parameters['j050'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j050'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['k01'] !== undefined) {
    form['k01'] = parameters['k01']
  }
  if (parameters['k01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k01'))
  }
  if (parameters['k02'] !== undefined) {
    form['k02'] = parameters['k02']
  }
  if (parameters['k02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k02'))
  }
  if (parameters['k03'] !== undefined) {
    form['k03'] = parameters['k03']
  }
  if (parameters['k03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k03'))
  }
  if (parameters['k04'] !== undefined) {
    form['k04'] = parameters['k04']
  }
  if (parameters['k04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: k04'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schCosswqSaveFormData = function(formData) {
  const url = schCosswqSave_RAW_URL()
  const method = schCosswqSave_TYPE()
  return axios[method](url, formData)
}
export const schCosswqSave_RAW_URL = function() {
  return '/school/cosswq/save/{screeningSchoolId}'
}
export const schCosswqSave_TYPE = function() {
  return 'post'
}
export const schCosswqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/cosswq/save/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 开展学校卫生工作情况调查表详情
 * request: schCosswqFind
 * formData: schCosswqFindFormData
 * url: schCosswqFind_URL
 * method: schCosswqFind_TYPE
 * raw_url: schCosswqFind_RAW_URL
 * @param id - id
 */
export const schCosswqFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/cosswq/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schCosswqFindFormData = function(formData) {
  const url = schCosswqFind_RAW_URL()
  const method = schCosswqFind_TYPE()
  return axios[method](url, formData)
}
export const schCosswqFind_RAW_URL = function() {
  return '/school/cosswq/{id}'
}
export const schCosswqFind_TYPE = function() {
  return 'get'
}
export const schCosswqFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/cosswq/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 开展学校卫生工作情况调查表填表人信息
 * request: schCosswqFindByIdOnlyCreateInfo
 * formData: schCosswqFindByIdOnlyCreateInfoFormData
 * url: schCosswqFindByIdOnlyCreateInfo_URL
 * method: schCosswqFindByIdOnlyCreateInfo_TYPE
 * raw_url: schCosswqFindByIdOnlyCreateInfo_RAW_URL
 * @param id - id
 */
export const schCosswqFindByIdOnlyCreateInfo = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/cosswq/{id}/create-info'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schCosswqFindByIdOnlyCreateInfoFormData = function(formData) {
  const url = schCosswqFindByIdOnlyCreateInfo_RAW_URL()
  const method = schCosswqFindByIdOnlyCreateInfo_TYPE()
  return axios[method](url, formData)
}
export const schCosswqFindByIdOnlyCreateInfo_RAW_URL = function() {
  return '/school/cosswq/{id}/create-info'
}
export const schCosswqFindByIdOnlyCreateInfo_TYPE = function() {
  return 'get'
}
export const schCosswqFindByIdOnlyCreateInfo_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/cosswq/{id}/create-info'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查学校获取开展学校卫生工作情况调查表
 * request: schCosswqFindByScreeningSchool
 * formData: schCosswqFindByScreeningSchoolFormData
 * url: schCosswqFindByScreeningSchool_URL
 * method: schCosswqFindByScreeningSchool_TYPE
 * raw_url: schCosswqFindByScreeningSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const schCosswqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/cosswq/{screeningPlanId}/by-screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schCosswqFindByScreeningSchoolFormData = function(formData) {
  const url = schCosswqFindByScreeningSchool_RAW_URL()
  const method = schCosswqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const schCosswqFindByScreeningSchool_RAW_URL = function() {
  return '/school/cosswq/{screeningPlanId}/by-screening-school'
}
export const schCosswqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const schCosswqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/cosswq/{screeningPlanId}/by-screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: schRoleList
 * formData: schRoleListFormData
 * url: schRoleList_URL
 * method: schRoleList_TYPE
 * raw_url: schRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schRoleListFormData = function(formData) {
  const url = schRoleList_RAW_URL()
  const method = schRoleList_TYPE()
  return axios[method](url, formData)
}
export const schRoleList_RAW_URL = function() {
  return '/school/role'
}
export const schRoleList_TYPE = function() {
  return 'get'
}
export const schRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: schRoleAdd
 * formData: schRoleAddFormData
 * url: schRoleAdd_URL
 * method: schRoleAdd_TYPE
 * raw_url: schRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const schRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schRoleAddFormData = function(formData) {
  const url = schRoleAdd_RAW_URL()
  const method = schRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const schRoleAdd_RAW_URL = function() {
  return '/school/role'
}
export const schRoleAdd_TYPE = function() {
  return 'post'
}
export const schRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: schRoleListFunction
 * formData: schRoleListFunctionFormData
 * url: schRoleListFunction_URL
 * method: schRoleListFunction_TYPE
 * raw_url: schRoleListFunction_RAW_URL
 */
export const schRoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schRoleListFunctionFormData = function(formData) {
  const url = schRoleListFunction_RAW_URL()
  const method = schRoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const schRoleListFunction_RAW_URL = function() {
  return '/school/role/function'
}
export const schRoleListFunction_TYPE = function() {
  return 'get'
}
export const schRoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: schRoleFind
 * formData: schRoleFindFormData
 * url: schRoleFind_URL
 * method: schRoleFind_TYPE
 * raw_url: schRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const schRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schRoleFindFormData = function(formData) {
  const url = schRoleFind_RAW_URL()
  const method = schRoleFind_TYPE()
  return axios[method](url, formData)
}
export const schRoleFind_RAW_URL = function() {
  return '/school/role/{roleId}'
}
export const schRoleFind_TYPE = function() {
  return 'get'
}
export const schRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: schRoleUpdate
 * formData: schRoleUpdateFormData
 * url: schRoleUpdate_URL
 * method: schRoleUpdate_TYPE
 * raw_url: schRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const schRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schRoleUpdateFormData = function(formData) {
  const url = schRoleUpdate_RAW_URL()
  const method = schRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const schRoleUpdate_RAW_URL = function() {
  return '/school/role/{roleId}'
}
export const schRoleUpdate_TYPE = function() {
  return 'put'
}
export const schRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: schRoleDelete
 * formData: schRoleDeleteFormData
 * url: schRoleDelete_URL
 * method: schRoleDelete_TYPE
 * raw_url: schRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const schRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schRoleDeleteFormData = function(formData) {
  const url = schRoleDelete_RAW_URL()
  const method = schRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const schRoleDelete_RAW_URL = function() {
  return '/school/role/{roleId}'
}
export const schRoleDelete_TYPE = function() {
  return 'delete'
}
export const schRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: schScreeningMonitoringFind
 * formData: schScreeningMonitoringFindFormData
 * url: schScreeningMonitoringFind_URL
 * method: schScreeningMonitoringFind_TYPE
 * raw_url: schScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const schScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringFindFormData = function(formData) {
  const url = schScreeningMonitoringFind_RAW_URL()
  const method = schScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringFind_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const schScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: schScreeningMonitoringAbnormalStudent
 * formData: schScreeningMonitoringAbnormalStudentFormData
 * url: schScreeningMonitoringAbnormalStudent_URL
 * method: schScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: schScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = schScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = schScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const schScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: schScreeningMonitoringFinishedStudent
 * formData: schScreeningMonitoringFinishedStudentFormData
 * url: schScreeningMonitoringFinishedStudent_URL
 * method: schScreeningMonitoringFinishedStudent_TYPE
 * raw_url: schScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = schScreeningMonitoringFinishedStudent_RAW_URL()
  const method = schScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const schScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: schScreeningMonitoringFinishedStudentWithQuestionary
 * formData: schScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: schScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: schScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: schScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = schScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = schScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const schScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: schScreeningMonitoringMissingStudent
 * formData: schScreeningMonitoringMissingStudentFormData
 * url: schScreeningMonitoringMissingStudent_URL
 * method: schScreeningMonitoringMissingStudent_TYPE
 * raw_url: schScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = schScreeningMonitoringMissingStudent_RAW_URL()
  const method = schScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const schScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: schScreeningMonitoringMissingStudentWithQuestionary
 * formData: schScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: schScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: schScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: schScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = schScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = schScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const schScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: schScreeningMonitoringUncheckedStudent
 * formData: schScreeningMonitoringUncheckedStudentFormData
 * url: schScreeningMonitoringUncheckedStudent_URL
 * method: schScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: schScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = schScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = schScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const schScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: schScreeningMonitoringFindWithQuestionary
 * formData: schScreeningMonitoringFindWithQuestionaryFormData
 * url: schScreeningMonitoringFindWithQuestionary_URL
 * method: schScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: schScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const schScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = schScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = schScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const schScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷已完成学生名单导出
 * request: schScreeningMonitoringListQuestionnaryCompleteStudentExport
 * formData: schScreeningMonitoringListQuestionnaryCompleteStudentExportFormData
 * url: schScreeningMonitoringListQuestionnaryCompleteStudentExport_URL
 * method: schScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE
 * raw_url: schScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param accessToken - 
 * @param screeningSchoolId - 
 */
export const schScreeningMonitoringListQuestionnaryCompleteStudentExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringListQuestionnaryCompleteStudentExportFormData = function(formData) {
  const url = schScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL()
  const method = schScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringListQuestionnaryCompleteStudentExport_RAW_URL = function() {
  return '/school/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
}
export const schScreeningMonitoringListQuestionnaryCompleteStudentExport_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringListQuestionnaryCompleteStudentExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{screeningPlanId}/questionnary-complete-student-export'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生名单导出
 * request: schScreeningMonitoringListQuestionnaryIncompleteStudentExport
 * formData: schScreeningMonitoringListQuestionnaryIncompleteStudentExportFormData
 * url: schScreeningMonitoringListQuestionnaryIncompleteStudentExport_URL
 * method: schScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE
 * raw_url: schScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param accessToken - 
 * @param screeningSchoolId - 
 */
export const schScreeningMonitoringListQuestionnaryIncompleteStudentExport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudentExportFormData = function(formData) {
  const url = schScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL()
  const method = schScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudentExport_RAW_URL = function() {
  return '/school/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudentExport_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudentExport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{screeningPlanId}/questionnary-incomplete-student-export'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: schScreeningMonitoringQualityControl
 * formData: schScreeningMonitoringQualityControlFormData
 * url: schScreeningMonitoringQualityControl_URL
 * method: schScreeningMonitoringQualityControl_TYPE
 * raw_url: schScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const schScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringQualityControlFormData = function(formData) {
  const url = schScreeningMonitoringQualityControl_RAW_URL()
  const method = schScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/school/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const schScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: schScreeningMonitoringQuestionnaryScreeningSchool
 * formData: schScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: schScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: schScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: schScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const schScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = schScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = schScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/school/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const schScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: schScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: schScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: schScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: schScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: schScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const schScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = schScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = schScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/school/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const schScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校对应筛查学校列表
 * request: schScreeningSchoolBySchool
 * formData: schScreeningSchoolBySchoolFormData
 * url: schScreeningSchoolBySchool_URL
 * method: schScreeningSchoolBySchool_TYPE
 * raw_url: schScreeningSchoolBySchool_RAW_URL
 */
export const schScreeningSchoolBySchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/by-school'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolBySchoolFormData = function(formData) {
  const url = schScreeningSchoolBySchool_RAW_URL()
  const method = schScreeningSchoolBySchool_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolBySchool_RAW_URL = function() {
  return '/school/screening-school/by-school'
}
export const schScreeningSchoolBySchool_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolBySchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/by-school'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查班级
 * request: schScreeningSchoolAddScreeningClass
 * formData: schScreeningSchoolAddScreeningClassFormData
 * url: schScreeningSchoolAddScreeningClass_URL
 * method: schScreeningSchoolAddScreeningClass_TYPE
 * raw_url: schScreeningSchoolAddScreeningClass_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param gradeId - 年级ID
 * @param name - 班级名称
 * @param sortNum - 排序号
 */
export const schScreeningSchoolAddScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/class'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    form['gradeId'] = parameters['gradeId']
  }
  if (parameters['gradeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolAddScreeningClassFormData = function(formData) {
  const url = schScreeningSchoolAddScreeningClass_RAW_URL()
  const method = schScreeningSchoolAddScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolAddScreeningClass_RAW_URL = function() {
  return '/school/screening-school/class'
}
export const schScreeningSchoolAddScreeningClass_TYPE = function() {
  return 'post'
}
export const schScreeningSchoolAddScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/class'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 更新筛查班级
 * request: schScreeningSchoolUpdateScreeningClass
 * formData: schScreeningSchoolUpdateScreeningClassFormData
 * url: schScreeningSchoolUpdateScreeningClass_URL
 * method: schScreeningSchoolUpdateScreeningClass_TYPE
 * raw_url: schScreeningSchoolUpdateScreeningClass_RAW_URL
 * @param screeningClassId - screeningClassId
 * @param screeningSchoolId - 筛查学校ID
 * @param gradeId - 年级ID
 * @param name - 班级名称
 * @param sortNum - 排序号
 */
export const schScreeningSchoolUpdateScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/class/{screeningClassId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    form['gradeId'] = parameters['gradeId']
  }
  if (parameters['gradeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sortNum'] !== undefined) {
    form['sortNum'] = parameters['sortNum']
  }
  if (parameters['sortNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sortNum'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolUpdateScreeningClassFormData = function(formData) {
  const url = schScreeningSchoolUpdateScreeningClass_RAW_URL()
  const method = schScreeningSchoolUpdateScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolUpdateScreeningClass_RAW_URL = function() {
  return '/school/screening-school/class/{screeningClassId}'
}
export const schScreeningSchoolUpdateScreeningClass_TYPE = function() {
  return 'put'
}
export const schScreeningSchoolUpdateScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/class/{screeningClassId}'
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导出视力监测数据
 * request: schScreeningSchoolExportVisionMonitoringData
 * formData: schScreeningSchoolExportVisionMonitoringDataFormData
 * url: schScreeningSchoolExportVisionMonitoringData_URL
 * method: schScreeningSchoolExportVisionMonitoringData_TYPE
 * raw_url: schScreeningSchoolExportVisionMonitoringData_RAW_URL
 * @param accessToken - 
 * @param planId - 
 * @param schoolId - 
 */
export const schScreeningSchoolExportVisionMonitoringData = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/export-vision-monitoring-data'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolExportVisionMonitoringDataFormData = function(formData) {
  const url = schScreeningSchoolExportVisionMonitoringData_RAW_URL()
  const method = schScreeningSchoolExportVisionMonitoringData_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolExportVisionMonitoringData_RAW_URL = function() {
  return '/school/screening-school/export-vision-monitoring-data'
}
export const schScreeningSchoolExportVisionMonitoringData_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolExportVisionMonitoringData_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/export-vision-monitoring-data'
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['schoolId'] !== undefined) {
    queryParameters['schoolId'] = parameters['schoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校参与筛查团队筛查计划列表
 * request: schScreeningSchoolTeamScreeningSchool
 * formData: schScreeningSchoolTeamScreeningSchoolFormData
 * url: schScreeningSchoolTeamScreeningSchool_URL
 * method: schScreeningSchoolTeamScreeningSchool_TYPE
 * raw_url: schScreeningSchoolTeamScreeningSchool_RAW_URL
 * @param planType - 计划类型
 * @param planName - 计划名字
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const schScreeningSchoolTeamScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolTeamScreeningSchoolFormData = function(formData) {
  const url = schScreeningSchoolTeamScreeningSchool_RAW_URL()
  const method = schScreeningSchoolTeamScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolTeamScreeningSchool_RAW_URL = function() {
  return '/school/screening-school/screening-plan'
}
export const schScreeningSchoolTeamScreeningSchool_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolTeamScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/screening-plan'
  if (parameters['planType'] !== undefined) {
    queryParameters['planType'] = parameters['planType']
  }
  if (parameters['planName'] !== undefined) {
    queryParameters['planName'] = parameters['planName']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查学生
 * request: schScreeningSchoolAddScreeningStudent
 * formData: schScreeningSchoolAddScreeningStudentFormData
 * url: schScreeningSchoolAddScreeningStudent_URL
 * method: schScreeningSchoolAddScreeningStudent_TYPE
 * raw_url: schScreeningSchoolAddScreeningStudent_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param geneticsHistory - 
 */
export const schScreeningSchoolAddScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/student'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolAddScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolAddScreeningStudent_RAW_URL()
  const method = schScreeningSchoolAddScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolAddScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/student'
}
export const schScreeningSchoolAddScreeningStudent_TYPE = function() {
  return 'post'
}
export const schScreeningSchoolAddScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/student'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查学生
 * request: schScreeningSchoolUpdateScreeningStudent
 * formData: schScreeningSchoolUpdateScreeningStudentFormData
 * url: schScreeningSchoolUpdateScreeningStudent_URL
 * method: schScreeningSchoolUpdateScreeningStudent_TYPE
 * raw_url: schScreeningSchoolUpdateScreeningStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param diseaseHistoryCoronavirus - 
 * @param geneticsHistory - 
 */
export const schScreeningSchoolUpdateScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['diseaseHistoryCoronavirus'] !== undefined) {
    form['diseaseHistoryCoronavirus'] = parameters['diseaseHistoryCoronavirus']
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolUpdateScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolUpdateScreeningStudent_RAW_URL()
  const method = schScreeningSchoolUpdateScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolUpdateScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/student/{screeningStudentId}'
}
export const schScreeningSchoolUpdateScreeningStudent_TYPE = function() {
  return 'put'
}
export const schScreeningSchoolUpdateScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查学生
 * request: schScreeningSchoolDeleteScreeningStudent
 * formData: schScreeningSchoolDeleteScreeningStudentFormData
 * url: schScreeningSchoolDeleteScreeningStudent_URL
 * method: schScreeningSchoolDeleteScreeningStudent_TYPE
 * raw_url: schScreeningSchoolDeleteScreeningStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const schScreeningSchoolDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolDeleteScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolDeleteScreeningStudent_RAW_URL()
  const method = schScreeningSchoolDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolDeleteScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/student/{screeningStudentId}'
}
export const schScreeningSchoolDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const schScreeningSchoolDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校问卷
 * request: schScreeningSchoolQuestionary
 * formData: schScreeningSchoolQuestionaryFormData
 * url: schScreeningSchoolQuestionary_URL
 * method: schScreeningSchoolQuestionary_TYPE
 * raw_url: schScreeningSchoolQuestionary_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const schScreeningSchoolQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningPlanId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolQuestionaryFormData = function(formData) {
  const url = schScreeningSchoolQuestionary_RAW_URL()
  const method = schScreeningSchoolQuestionary_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolQuestionary_RAW_URL = function() {
  return '/school/screening-school/{screeningPlanId}/questionary'
}
export const schScreeningSchoolQuestionary_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningPlanId}/questionary'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取学校对应筛查计划详情
 * request: schSchoolScreeningSchool
 * formData: schSchoolScreeningSchoolFormData
 * url: schSchoolScreeningSchool_URL
 * method: schSchoolScreeningSchool_TYPE
 * raw_url: schSchoolScreeningSchool_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const schSchoolScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningPlanId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schSchoolScreeningSchoolFormData = function(formData) {
  const url = schSchoolScreeningSchool_RAW_URL()
  const method = schSchoolScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const schSchoolScreeningSchool_RAW_URL = function() {
  return '/school/screening-school/{screeningPlanId}/screening-school'
}
export const schSchoolScreeningSchool_TYPE = function() {
  return 'get'
}
export const schSchoolScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningPlanId}/screening-school'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量恢复学生
 * request: schScreeningSchoolBatchRecoveryScreeningStudent
 * formData: schScreeningSchoolBatchRecoveryScreeningStudentFormData
 * url: schScreeningSchoolBatchRecoveryScreeningStudent_URL
 * method: schScreeningSchoolBatchRecoveryScreeningStudent_TYPE
 * raw_url: schScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const schScreeningSchoolBatchRecoveryScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolBatchRecoveryScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL()
  const method = schScreeningSchoolBatchRecoveryScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolBatchRecoveryScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
}
export const schScreeningSchoolBatchRecoveryScreeningStudent_TYPE = function() {
  return 'delete'
}
export const schScreeningSchoolBatchRecoveryScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/batch-recovery-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量删除学生
 * request: schScreeningSchoolBatchDeleteScreeningStudent
 * formData: schScreeningSchoolBatchDeleteScreeningStudentFormData
 * url: schScreeningSchoolBatchDeleteScreeningStudent_URL
 * method: schScreeningSchoolBatchDeleteScreeningStudent_TYPE
 * raw_url: schScreeningSchoolBatchDeleteScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const schScreeningSchoolBatchDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/batch-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolBatchDeleteScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolBatchDeleteScreeningStudent_RAW_URL()
  const method = schScreeningSchoolBatchDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolBatchDeleteScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/batch-screening-student'
}
export const schScreeningSchoolBatchDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const schScreeningSchoolBatchDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/batch-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查班级选择列表
 * request: schScreeningSchoolScreeningClassChoiceList
 * formData: schScreeningSchoolScreeningClassChoiceListFormData
 * url: schScreeningSchoolScreeningClassChoiceList_URL
 * method: schScreeningSchoolScreeningClassChoiceList_TYPE
 * raw_url: schScreeningSchoolScreeningClassChoiceList_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const schScreeningSchoolScreeningClassChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/class/choice-list'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolScreeningClassChoiceListFormData = function(formData) {
  const url = schScreeningSchoolScreeningClassChoiceList_RAW_URL()
  const method = schScreeningSchoolScreeningClassChoiceList_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolScreeningClassChoiceList_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/class/choice-list'
}
export const schScreeningSchoolScreeningClassChoiceList_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolScreeningClassChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/class/choice-list'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查班级
 * request: schScreeningSchoolDeleteScreeningClass
 * formData: schScreeningSchoolDeleteScreeningClassFormData
 * url: schScreeningSchoolDeleteScreeningClass_URL
 * method: schScreeningSchoolDeleteScreeningClass_TYPE
 * raw_url: schScreeningSchoolDeleteScreeningClass_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - screeningClassId
 */
export const schScreeningSchoolDeleteScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/class/{screeningClassId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolDeleteScreeningClassFormData = function(formData) {
  const url = schScreeningSchoolDeleteScreeningClass_RAW_URL()
  const method = schScreeningSchoolDeleteScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolDeleteScreeningClass_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/class/{screeningClassId}'
}
export const schScreeningSchoolDeleteScreeningClass_TYPE = function() {
  return 'delete'
}
export const schScreeningSchoolDeleteScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/class/{screeningClassId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  path = path.replace('{screeningClassId}', `${parameters['screeningClassId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量清除删除学生
 * request: schScreeningSchoolCleanDeleteScreeningStudent
 * formData: schScreeningSchoolCleanDeleteScreeningStudentFormData
 * url: schScreeningSchoolCleanDeleteScreeningStudent_URL
 * method: schScreeningSchoolCleanDeleteScreeningStudent_TYPE
 * raw_url: schScreeningSchoolCleanDeleteScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const schScreeningSchoolCleanDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/clean-delete-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolCleanDeleteScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolCleanDeleteScreeningStudent_RAW_URL()
  const method = schScreeningSchoolCleanDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolCleanDeleteScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/clean-delete-screening-student'
}
export const schScreeningSchoolCleanDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const schScreeningSchoolCleanDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/clean-delete-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学校完成学生录入
 * request: schScreeningSchoolFinishInputStudent
 * formData: schScreeningSchoolFinishInputStudentFormData
 * url: schScreeningSchoolFinishInputStudent_URL
 * method: schScreeningSchoolFinishInputStudent_TYPE
 * raw_url: schScreeningSchoolFinishInputStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const schScreeningSchoolFinishInputStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/finish-input-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolFinishInputStudentFormData = function(formData) {
  const url = schScreeningSchoolFinishInputStudent_RAW_URL()
  const method = schScreeningSchoolFinishInputStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolFinishInputStudent_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/finish-input-student'
}
export const schScreeningSchoolFinishInputStudent_TYPE = function() {
  return 'put'
}
export const schScreeningSchoolFinishInputStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/finish-input-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查班级排序号
 * request: schScreeningSchoolClassSortNum
 * formData: schScreeningSchoolClassSortNumFormData
 * url: schScreeningSchoolClassSortNum_URL
 * method: schScreeningSchoolClassSortNum_TYPE
 * raw_url: schScreeningSchoolClassSortNum_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - gradeId
 */
export const schScreeningSchoolClassSortNum = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/grade/{gradeId}/class-sort-num'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  path = path.replace('{gradeId}', `${parameters['gradeId']}`)
  if (parameters['gradeId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: gradeId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolClassSortNumFormData = function(formData) {
  const url = schScreeningSchoolClassSortNum_RAW_URL()
  const method = schScreeningSchoolClassSortNum_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolClassSortNum_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/grade/{gradeId}/class-sort-num'
}
export const schScreeningSchoolClassSortNum_TYPE = function() {
  return 'get'
}
export const schScreeningSchoolClassSortNum_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/grade/{gradeId}/class-sort-num'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  path = path.replace('{gradeId}', `${parameters['gradeId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入筛查班级
 * request: schScreeningSchoolImportScreeningClass
 * formData: schScreeningSchoolImportScreeningClassFormData
 * url: schScreeningSchoolImportScreeningClass_URL
 * method: schScreeningSchoolImportScreeningClass_TYPE
 * raw_url: schScreeningSchoolImportScreeningClass_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param file - 
 */
export const schScreeningSchoolImportScreeningClass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/screening-class/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolImportScreeningClassFormData = function(formData) {
  const url = schScreeningSchoolImportScreeningClass_RAW_URL()
  const method = schScreeningSchoolImportScreeningClass_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolImportScreeningClass_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/screening-class/import'
}
export const schScreeningSchoolImportScreeningClass_TYPE = function() {
  return 'post'
}
export const schScreeningSchoolImportScreeningClass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/screening-class/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入筛查学生资料
 * request: schScreeningSchoolImportScreeningStudent
 * formData: schScreeningSchoolImportScreeningStudentFormData
 * url: schScreeningSchoolImportScreeningStudent_URL
 * method: schScreeningSchoolImportScreeningStudent_TYPE
 * raw_url: schScreeningSchoolImportScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param accessToken - 
 * @param file - 
 */
export const schScreeningSchoolImportScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/screening-school/{screeningSchoolId}/student/import'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters['accessToken'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: accessToken'))
  }
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const schScreeningSchoolImportScreeningStudentFormData = function(formData) {
  const url = schScreeningSchoolImportScreeningStudent_RAW_URL()
  const method = schScreeningSchoolImportScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const schScreeningSchoolImportScreeningStudent_RAW_URL = function() {
  return '/school/screening-school/{screeningSchoolId}/student/import'
}
export const schScreeningSchoolImportScreeningStudent_TYPE = function() {
  return 'post'
}
export const schScreeningSchoolImportScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/screening-school/{screeningSchoolId}/student/import'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['accessToken'] !== undefined) {
    queryParameters['access_token'] = parameters['accessToken']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看任务指标
 * request: schTaskIndicatorFind
 * formData: schTaskIndicatorFindFormData
 * url: schTaskIndicatorFind_URL
 * method: schTaskIndicatorFind_TYPE
 * raw_url: schTaskIndicatorFind_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const schTaskIndicatorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/task-indicator/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schTaskIndicatorFindFormData = function(formData) {
  const url = schTaskIndicatorFind_RAW_URL()
  const method = schTaskIndicatorFind_TYPE()
  return axios[method](url, formData)
}
export const schTaskIndicatorFind_RAW_URL = function() {
  return '/school/task-indicator/{screeningSchoolId}'
}
export const schTaskIndicatorFind_TYPE = function() {
  return 'get'
}
export const schTaskIndicatorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/task-indicator/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: schTaskIndicatorDubiousStudent
 * formData: schTaskIndicatorDubiousStudentFormData
 * url: schTaskIndicatorDubiousStudent_URL
 * method: schTaskIndicatorDubiousStudent_TYPE
 * raw_url: schTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const schTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/school/task-indicator/{screeningSchoolId}/dubious-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const schTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = schTaskIndicatorDubiousStudent_RAW_URL()
  const method = schTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const schTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/school/task-indicator/{screeningSchoolId}/dubious-student'
}
export const schTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const schTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/school/task-indicator/{screeningSchoolId}/dubious-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: teamAdministratorList
 * formData: teamAdministratorListFormData
 * url: teamAdministratorList_URL
 * method: teamAdministratorList_TYPE
 * raw_url: teamAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorListFormData = function(formData) {
  const url = teamAdministratorList_RAW_URL()
  const method = teamAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorList_RAW_URL = function() {
  return '/team/administrator'
}
export const teamAdministratorList_TYPE = function() {
  return 'get'
}
export const teamAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: teamAdministratorAdd
 * formData: teamAdministratorAddFormData
 * url: teamAdministratorAdd_URL
 * method: teamAdministratorAdd_TYPE
 * raw_url: teamAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const teamAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorAddFormData = function(formData) {
  const url = teamAdministratorAdd_RAW_URL()
  const method = teamAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorAdd_RAW_URL = function() {
  return '/team/administrator'
}
export const teamAdministratorAdd_TYPE = function() {
  return 'post'
}
export const teamAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 全部管理员选择列表
 * request: teamAdministratorAllChoiceList
 * formData: teamAdministratorAllChoiceListFormData
 * url: teamAdministratorAllChoiceList_URL
 * method: teamAdministratorAllChoiceList_TYPE
 * raw_url: teamAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const teamAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorAllChoiceListFormData = function(formData) {
  const url = teamAdministratorAllChoiceList_RAW_URL()
  const method = teamAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorAllChoiceList_RAW_URL = function() {
  return '/team/administrator/all-choice'
}
export const teamAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const teamAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员选择列表
 * request: teamAdministratorChoiceList
 * formData: teamAdministratorChoiceListFormData
 * url: teamAdministratorChoiceList_URL
 * method: teamAdministratorChoiceList_TYPE
 * raw_url: teamAdministratorChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const teamAdministratorChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorChoiceListFormData = function(formData) {
  const url = teamAdministratorChoiceList_RAW_URL()
  const method = teamAdministratorChoiceList_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorChoiceList_RAW_URL = function() {
  return '/team/administrator/choice'
}
export const teamAdministratorChoiceList_TYPE = function() {
  return 'get'
}
export const teamAdministratorChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: teamImportAdministrator
 * formData: teamImportAdministratorFormData
 * url: teamImportAdministrator_URL
 * method: teamImportAdministrator_TYPE
 * raw_url: teamImportAdministrator_RAW_URL
 * @param file - 
 */
export const teamImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamImportAdministratorFormData = function(formData) {
  const url = teamImportAdministrator_RAW_URL()
  const method = teamImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const teamImportAdministrator_RAW_URL = function() {
  return '/team/administrator/import'
}
export const teamImportAdministrator_TYPE = function() {
  return 'post'
}
export const teamImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: teamAdministratorDelete
 * formData: teamAdministratorDeleteFormData
 * url: teamAdministratorDelete_URL
 * method: teamAdministratorDelete_TYPE
 * raw_url: teamAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const teamAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorDeleteFormData = function(formData) {
  const url = teamAdministratorDelete_RAW_URL()
  const method = teamAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorDelete_RAW_URL = function() {
  return '/team/administrator/{administratorId}'
}
export const teamAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const teamAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: teamAdministratorListRole
 * formData: teamAdministratorListRoleFormData
 * url: teamAdministratorListRole_URL
 * method: teamAdministratorListRole_TYPE
 * raw_url: teamAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const teamAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorListRoleFormData = function(formData) {
  const url = teamAdministratorListRole_RAW_URL()
  const method = teamAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorListRole_RAW_URL = function() {
  return '/team/administrator/{administratorId}/role'
}
export const teamAdministratorListRole_TYPE = function() {
  return 'get'
}
export const teamAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: teamAdministratorAddRole
 * formData: teamAdministratorAddRoleFormData
 * url: teamAdministratorAddRole_URL
 * method: teamAdministratorAddRole_TYPE
 * raw_url: teamAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const teamAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorAddRoleFormData = function(formData) {
  const url = teamAdministratorAddRole_RAW_URL()
  const method = teamAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorAddRole_RAW_URL = function() {
  return '/team/administrator/{administratorId}/role'
}
export const teamAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const teamAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: teamAdministratorDeleteRole
 * formData: teamAdministratorDeleteRoleFormData
 * url: teamAdministratorDeleteRole_URL
 * method: teamAdministratorDeleteRole_TYPE
 * raw_url: teamAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const teamAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/administrator/{administratorId}/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamAdministratorDeleteRoleFormData = function(formData) {
  const url = teamAdministratorDeleteRole_RAW_URL()
  const method = teamAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const teamAdministratorDeleteRole_RAW_URL = function() {
  return '/team/administrator/{administratorId}/role/{roleId}'
}
export const teamAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const teamAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/administrator/{administratorId}/role/{roleId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取国家卫健委新冠肺炎对学生视力影响评估调查表
 * request: teamCovid19SvisqFindByScreeningSchool
 * formData: teamCovid19SvisqFindByScreeningSchoolFormData
 * url: teamCovid19SvisqFindByScreeningSchool_URL
 * method: teamCovid19SvisqFindByScreeningSchool_TYPE
 * raw_url: teamCovid19SvisqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamCovid19SvisqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/covid19-svisq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamCovid19SvisqFindByScreeningSchoolFormData = function(formData) {
  const url = teamCovid19SvisqFindByScreeningSchool_RAW_URL()
  const method = teamCovid19SvisqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamCovid19SvisqFindByScreeningSchool_RAW_URL = function() {
  return '/team/covid19-svisq/by-screeningSchool/{screeningSchoolId}'
}
export const teamCovid19SvisqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamCovid19SvisqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/covid19-svisq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取国家卫健委新冠肺炎对学生视力影响评估调查表
 * request: teamCovid19SvisqFindByStudent
 * formData: teamCovid19SvisqFindByStudentFormData
 * url: teamCovid19SvisqFindByStudent_URL
 * method: teamCovid19SvisqFindByStudent_TYPE
 * raw_url: teamCovid19SvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamCovid19SvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/covid19-svisq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamCovid19SvisqFindByStudentFormData = function(formData) {
  const url = teamCovid19SvisqFindByStudent_RAW_URL()
  const method = teamCovid19SvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamCovid19SvisqFindByStudent_RAW_URL = function() {
  return '/team/covid19-svisq/by-student/{studentId}'
}
export const teamCovid19SvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamCovid19SvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/covid19-svisq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存2021年疫情防控期间学生视力影响因素专项调查表
 * request: teamCovid19SvisqSave
 * formData: teamCovid19SvisqSaveFormData
 * url: teamCovid19SvisqSave_URL
 * method: teamCovid19SvisqSave_TYPE
 * raw_url: teamCovid19SvisqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04LeftVision - 
 * @param a04RightVision - 
 * @param a05 - 
 * @param a06 - 
 * @param a06Day - 当a06填未隔离，则不填
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b06 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b17OtherDescription - 
 * @param b18 - 
 * @param c01 - 
 * @param c011 - c01选是则必填，反之不填
 * @param c011OtherDescription - 
 * @param c012 - c01选是则必填，反之不填
 * @param c013 - c01选是则必填，反之不填
 * @param c013Date - 
 * @param c014 - c01选是则必填，反之不填
 * @param c014OtherDescription - c01选是则必填，反之不填
 * @param c015 - c01选是则必填，反之不填; 值','隔开
 * @param c016 - c01选是则必填，反之不填; 值','隔开
 * @param c0171 - 单位分钟
 * @param c0172 - 单位分钟
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c081 - 
 * @param c09 - 
 * @param c091 - 
 * @param c11 - 
 * @param c12 - 
 * @param c121 - 
 * @param c121Minute - 
 * @param c123 - 
 * @param c123Minute - 
 * @param c14 - 
 * @param c14Minute - 
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 */
export const teamCovid19SvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/covid19-svisq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04LeftVision'] !== undefined) {
    form['a04LeftVision'] = parameters['a04LeftVision']
  }
  if (parameters['a04LeftVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04LeftVision'))
  }
  if (parameters['a04RightVision'] !== undefined) {
    form['a04RightVision'] = parameters['a04RightVision']
  }
  if (parameters['a04RightVision'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04RightVision'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a06Day'] !== undefined) {
    form['a06Day'] = parameters['a06Day']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17OtherDescription'] !== undefined) {
    form['b17OtherDescription'] = parameters['b17OtherDescription']
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c011'] !== undefined) {
    form['c011'] = parameters['c011']
  }
  if (parameters['c011OtherDescription'] !== undefined) {
    form['c011OtherDescription'] = parameters['c011OtherDescription']
  }
  if (parameters['c012'] !== undefined) {
    form['c012'] = parameters['c012']
  }
  if (parameters['c013'] !== undefined) {
    form['c013'] = parameters['c013']
  }
  if (parameters['c013Date'] !== undefined) {
    form['c013Date'] = parameters['c013Date']
  }
  if (parameters['c014'] !== undefined) {
    form['c014'] = parameters['c014']
  }
  if (parameters['c014OtherDescription'] !== undefined) {
    form['c014OtherDescription'] = parameters['c014OtherDescription']
  }
  if (parameters['c015'] !== undefined) {
    form['c015'] = parameters['c015']
  }
  if (parameters['c016'] !== undefined) {
    form['c016'] = parameters['c016']
  }
  if (parameters['c0171'] !== undefined) {
    form['c0171'] = parameters['c0171']
  }
  if (parameters['c0172'] !== undefined) {
    form['c0172'] = parameters['c0172']
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c081'] !== undefined) {
    form['c081'] = parameters['c081']
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c091'] !== undefined) {
    form['c091'] = parameters['c091']
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c12'] !== undefined) {
    form['c12'] = parameters['c12']
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121Minute'] !== undefined) {
    form['c121Minute'] = parameters['c121Minute']
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123Minute'] !== undefined) {
    form['c123Minute'] = parameters['c123Minute']
  }
  if (parameters['c14'] !== undefined) {
    form['c14'] = parameters['c14']
  }
  if (parameters['c14Minute'] !== undefined) {
    form['c14Minute'] = parameters['c14Minute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamCovid19SvisqSaveFormData = function(formData) {
  const url = teamCovid19SvisqSave_RAW_URL()
  const method = teamCovid19SvisqSave_TYPE()
  return axios[method](url, formData)
}
export const teamCovid19SvisqSave_RAW_URL = function() {
  return '/team/covid19-svisq/save/{studentId}'
}
export const teamCovid19SvisqSave_TYPE = function() {
  return 'post'
}
export const teamCovid19SvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/covid19-svisq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查设备列表
 * request: teamScreeningDeviceList
 * formData: teamScreeningDeviceListFormData
 * url: teamScreeningDeviceList_URL
 * method: teamScreeningDeviceList_TYPE
 * raw_url: teamScreeningDeviceList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param type - 类型
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningDeviceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceListFormData = function(formData) {
  const url = teamScreeningDeviceList_RAW_URL()
  const method = teamScreeningDeviceList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceList_RAW_URL = function() {
  return '/team/device'
}
export const teamScreeningDeviceList_TYPE = function() {
  return 'get'
}
export const teamScreeningDeviceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查设备
 * request: teamScreeningDeviceAdd
 * formData: teamScreeningDeviceAddFormData
 * url: teamScreeningDeviceAdd_URL
 * method: teamScreeningDeviceAdd_TYPE
 * raw_url: teamScreeningDeviceAdd_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param type - 类型
 * @param organization - 所属单位
 * @param brand - 品牌
 * @param modelNum - 型号
 * @param organizationDeviceCode - 单位设备编码
 * @param factoryDeviceCode - 出厂设备编码
 * @param files - 照片
 */
export const teamScreeningDeviceAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['organization'] !== undefined) {
    form['organization'] = parameters['organization']
  }
  if (parameters['organization'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organization'))
  }
  if (parameters['brand'] !== undefined) {
    form['brand'] = parameters['brand']
  }
  if (parameters['brand'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: brand'))
  }
  if (parameters['modelNum'] !== undefined) {
    form['modelNum'] = parameters['modelNum']
  }
  if (parameters['modelNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: modelNum'))
  }
  if (parameters['organizationDeviceCode'] !== undefined) {
    form['organizationDeviceCode'] = parameters['organizationDeviceCode']
  }
  if (parameters['organizationDeviceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationDeviceCode'))
  }
  if (parameters['factoryDeviceCode'] !== undefined) {
    form['factoryDeviceCode'] = parameters['factoryDeviceCode']
  }
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceAddFormData = function(formData) {
  const url = teamScreeningDeviceAdd_RAW_URL()
  const method = teamScreeningDeviceAdd_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceAdd_RAW_URL = function() {
  return '/team/device'
}
export const teamScreeningDeviceAdd_TYPE = function() {
  return 'post'
}
export const teamScreeningDeviceAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查设备选择列表
 * request: teamScreeningDeviceChoiceList
 * formData: teamScreeningDeviceChoiceListFormData
 * url: teamScreeningDeviceChoiceList_URL
 * method: teamScreeningDeviceChoiceList_TYPE
 * raw_url: teamScreeningDeviceChoiceList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param type - 类型
 * @param status - 状态
 */
export const teamScreeningDeviceChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceChoiceListFormData = function(formData) {
  const url = teamScreeningDeviceChoiceList_RAW_URL()
  const method = teamScreeningDeviceChoiceList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceChoiceList_RAW_URL = function() {
  return '/team/device/choice'
}
export const teamScreeningDeviceChoiceList_TYPE = function() {
  return 'get'
}
export const teamScreeningDeviceChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device/choice'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查设备
 * request: teamScreeningDeviceUpdate
 * formData: teamScreeningDeviceUpdateFormData
 * url: teamScreeningDeviceUpdate_URL
 * method: teamScreeningDeviceUpdate_TYPE
 * raw_url: teamScreeningDeviceUpdate_RAW_URL
 * @param id - id
 * @param type - 类型
 * @param organization - 所属单位
 * @param brand - 品牌
 * @param modelNum - 型号
 * @param organizationDeviceCode - 单位设备编码
 * @param factoryDeviceCode - 出厂设备编码
 * @param photo - 原照片路径
 * @param files - 照片
 */
export const teamScreeningDeviceUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['organization'] !== undefined) {
    form['organization'] = parameters['organization']
  }
  if (parameters['organization'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organization'))
  }
  if (parameters['brand'] !== undefined) {
    form['brand'] = parameters['brand']
  }
  if (parameters['brand'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: brand'))
  }
  if (parameters['modelNum'] !== undefined) {
    form['modelNum'] = parameters['modelNum']
  }
  if (parameters['modelNum'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: modelNum'))
  }
  if (parameters['organizationDeviceCode'] !== undefined) {
    form['organizationDeviceCode'] = parameters['organizationDeviceCode']
  }
  if (parameters['organizationDeviceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organizationDeviceCode'))
  }
  if (parameters['factoryDeviceCode'] !== undefined) {
    form['factoryDeviceCode'] = parameters['factoryDeviceCode']
  }
  if (parameters['photo'] !== undefined) {
    form['photo'] = parameters['photo']
  }
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceUpdateFormData = function(formData) {
  const url = teamScreeningDeviceUpdate_RAW_URL()
  const method = teamScreeningDeviceUpdate_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceUpdate_RAW_URL = function() {
  return '/team/device/{id}'
}
export const teamScreeningDeviceUpdate_TYPE = function() {
  return 'post'
}
export const teamScreeningDeviceUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查设备
 * request: teamScreeningDeviceDelete
 * formData: teamScreeningDeviceDeleteFormData
 * url: teamScreeningDeviceDelete_URL
 * method: teamScreeningDeviceDelete_TYPE
 * raw_url: teamScreeningDeviceDelete_RAW_URL
 * @param id - id
 */
export const teamScreeningDeviceDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device/{id}/delete'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceDeleteFormData = function(formData) {
  const url = teamScreeningDeviceDelete_RAW_URL()
  const method = teamScreeningDeviceDelete_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceDelete_RAW_URL = function() {
  return '/team/device/{id}/delete'
}
export const teamScreeningDeviceDelete_TYPE = function() {
  return 'delete'
}
export const teamScreeningDeviceDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device/{id}/delete'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用筛查设备
 * request: teamScreeningDeviceDisable
 * formData: teamScreeningDeviceDisableFormData
 * url: teamScreeningDeviceDisable_URL
 * method: teamScreeningDeviceDisable_TYPE
 * raw_url: teamScreeningDeviceDisable_RAW_URL
 * @param id - id
 */
export const teamScreeningDeviceDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceDisableFormData = function(formData) {
  const url = teamScreeningDeviceDisable_RAW_URL()
  const method = teamScreeningDeviceDisable_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceDisable_RAW_URL = function() {
  return '/team/device/{id}/disable'
}
export const teamScreeningDeviceDisable_TYPE = function() {
  return 'put'
}
export const teamScreeningDeviceDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用筛查设备
 * request: teamScreeningDeviceEnable
 * formData: teamScreeningDeviceEnableFormData
 * url: teamScreeningDeviceEnable_URL
 * method: teamScreeningDeviceEnable_TYPE
 * raw_url: teamScreeningDeviceEnable_RAW_URL
 * @param id - id
 */
export const teamScreeningDeviceEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/device/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningDeviceEnableFormData = function(formData) {
  const url = teamScreeningDeviceEnable_RAW_URL()
  const method = teamScreeningDeviceEnable_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningDeviceEnable_RAW_URL = function() {
  return '/team/device/{id}/enable'
}
export const teamScreeningDeviceEnable_TYPE = function() {
  return 'put'
}
export const teamScreeningDeviceEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/device/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取中学学生健康状况及影响因素调查表
 * request: teamMshiqFindByScreeningSchool
 * formData: teamMshiqFindByScreeningSchoolFormData
 * url: teamMshiqFindByScreeningSchool_URL
 * method: teamMshiqFindByScreeningSchool_TYPE
 * raw_url: teamMshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamMshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/mshiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamMshiqFindByScreeningSchoolFormData = function(formData) {
  const url = teamMshiqFindByScreeningSchool_RAW_URL()
  const method = teamMshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamMshiqFindByScreeningSchool_RAW_URL = function() {
  return '/team/mshiq/by-screeningSchool/{screeningSchoolId}'
}
export const teamMshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamMshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/mshiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看中学学生健康状况及影响因素调查表
 * request: teamMshiqFindByStudent
 * formData: teamMshiqFindByStudentFormData
 * url: teamMshiqFindByStudent_URL
 * method: teamMshiqFindByStudent_TYPE
 * raw_url: teamMshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamMshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/mshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamMshiqFindByStudentFormData = function(formData) {
  const url = teamMshiqFindByStudent_RAW_URL()
  const method = teamMshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamMshiqFindByStudent_RAW_URL = function() {
  return '/team/mshiq/by-student/{studentId}'
}
export const teamMshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamMshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/mshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查表
 * request: teamMshiqSave
 * formData: teamMshiqSaveFormData
 * url: teamMshiqSave_URL
 * method: teamMshiqSave_TYPE
 * raw_url: teamMshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 
 * @param d06Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d07 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d08 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d081 - 每次服用止咳药水的量是多少？
 * @param d09 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填
 * @param f02 - 当F01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param f03 - 当F01的值为2时必填，反之不填
 * @param f04 - 当F01的值为2时必填，反之不填
 * @param f05 - 当F01的值为2时必填，反之不填
 * @param g01 - 
 * @param g02 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 
 * @param h05 - 
 * @param h06 - 
 * @param h07 - 
 * @param h08 - 
 * @param h09 - 
 * @param h10 - 
 * @param h11 - 
 * @param h12 - 
 * @param h13 - 
 * @param h14 - 
 * @param h15 - 
 * @param h16 - 
 * @param h17 - 
 * @param h18 - 
 * @param h19 - 
 * @param h20 - 
 * @param h21 - 感觉紧张，焦虑或急切
 * @param h22 - 不能够停止或控制担忧
 * @param h23 - 对各种恶样的事情担忧过多
 * @param h24 - 很难放松下来
 * @param h25 - 由于不安而无法静坐
 * @param h26 - 变得容易烦恼或急躁
 * @param h27 - 感到似乎将有可怕的事情发生而害怕
 * @param h28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param h281 - 你寻求了什么形式的帮助
 * @param h282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param i01 - 
 * @param i011 - 
 * @param i02 - 
 * @param i021 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const teamMshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/mshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['d06Description'] !== undefined) {
    form['d06Description'] = parameters['d06Description']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d07'))
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d08'))
  }
  if (parameters['d081'] !== undefined) {
    form['d081'] = parameters['d081']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['h21'] !== undefined) {
    form['h21'] = parameters['h21']
  }
  if (parameters['h21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h21'))
  }
  if (parameters['h22'] !== undefined) {
    form['h22'] = parameters['h22']
  }
  if (parameters['h22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h22'))
  }
  if (parameters['h23'] !== undefined) {
    form['h23'] = parameters['h23']
  }
  if (parameters['h23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h23'))
  }
  if (parameters['h24'] !== undefined) {
    form['h24'] = parameters['h24']
  }
  if (parameters['h24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h24'))
  }
  if (parameters['h25'] !== undefined) {
    form['h25'] = parameters['h25']
  }
  if (parameters['h25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h25'))
  }
  if (parameters['h26'] !== undefined) {
    form['h26'] = parameters['h26']
  }
  if (parameters['h26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h26'))
  }
  if (parameters['h27'] !== undefined) {
    form['h27'] = parameters['h27']
  }
  if (parameters['h27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h27'))
  }
  if (parameters['h28'] !== undefined) {
    form['h28'] = parameters['h28']
  }
  if (parameters['h28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h28'))
  }
  if (parameters['h281'] !== undefined) {
    form['h281'] = parameters['h281']
  }
  if (parameters['h282'] !== undefined) {
    form['h282'] = parameters['h282']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamMshiqSaveFormData = function(formData) {
  const url = teamMshiqSave_RAW_URL()
  const method = teamMshiqSave_TYPE()
  return axios[method](url, formData)
}
export const teamMshiqSave_RAW_URL = function() {
  return '/team/mshiq/save/{studentId}'
}
export const teamMshiqSave_TYPE = function() {
  return 'post'
}
export const teamMshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/mshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取小学学生健康状况及影响因素调查表
 * request: teamPshiqFindByScreeningSchool
 * formData: teamPshiqFindByScreeningSchoolFormData
 * url: teamPshiqFindByScreeningSchool_URL
 * method: teamPshiqFindByScreeningSchool_TYPE
 * raw_url: teamPshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamPshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/pshiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamPshiqFindByScreeningSchoolFormData = function(formData) {
  const url = teamPshiqFindByScreeningSchool_RAW_URL()
  const method = teamPshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamPshiqFindByScreeningSchool_RAW_URL = function() {
  return '/team/pshiq/by-screeningSchool/{screeningSchoolId}'
}
export const teamPshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamPshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/pshiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看小学学生健康状况及影响因素调查表
 * request: teamPshiqFindByStudent
 * formData: teamPshiqFindByStudentFormData
 * url: teamPshiqFindByStudent_URL
 * method: teamPshiqFindByStudent_TYPE
 * raw_url: teamPshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamPshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/pshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamPshiqFindByStudentFormData = function(formData) {
  const url = teamPshiqFindByStudent_RAW_URL()
  const method = teamPshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamPshiqFindByStudent_RAW_URL = function() {
  return '/team/pshiq/by-student/{studentId}'
}
export const teamPshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamPshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/pshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查表
 * request: teamPshiqSave
 * formData: teamPshiqSaveFormData
 * url: teamPshiqSave_URL
 * method: teamPshiqSave_TYPE
 * raw_url: teamPshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d03 - 
 * @param d04 - 
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填
 * @param e01 - 
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填
 * @param e02 - 当E01的值为2时必填，反之不填.过去7天，你平均每天使用耳机时间数选项
 * @param e03 - 当E01的值为2时必填，反之不填
 * @param e04 - 当E01的值为2时必填，反之不填
 * @param e05 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f011 - 
 * @param f02 - 
 * @param f021 - 
 * @param f05 - 
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const teamPshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/pshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamPshiqSaveFormData = function(formData) {
  const url = teamPshiqSave_RAW_URL()
  const method = teamPshiqSave_TYPE()
  return axios[method](url, formData)
}
export const teamPshiqSave_RAW_URL = function() {
  return '/team/pshiq/save/{studentId}'
}
export const teamPshiqSave_TYPE = function() {
  return 'post'
}
export const teamPshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/pshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控记录列表
 * request: teamQualityControlRecordList
 * formData: teamQualityControlRecordListFormData
 * url: teamQualityControlRecordList_URL
 * method: teamQualityControlRecordList_TYPE
 * raw_url: teamQualityControlRecordList_RAW_URL
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamQualityControlRecordList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/quality-control-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamQualityControlRecordListFormData = function(formData) {
  const url = teamQualityControlRecordList_RAW_URL()
  const method = teamQualityControlRecordList_TYPE()
  return axios[method](url, formData)
}
export const teamQualityControlRecordList_RAW_URL = function() {
  return '/team/quality-control-record'
}
export const teamQualityControlRecordList_TYPE = function() {
  return 'get'
}
export const teamQualityControlRecordList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/quality-control-record'
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查团队质控记录详情
 * request: teamQualityControlRecordDetail
 * formData: teamQualityControlRecordDetailFormData
 * url: teamQualityControlRecordDetail_URL
 * method: teamQualityControlRecordDetail_TYPE
 * raw_url: teamQualityControlRecordDetail_RAW_URL
 * @param staTime - 
 * @param endTime - 
 * @param teamId - 
 */
export const teamQualityControlRecordDetail = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/quality-control-record/detail'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['staTime'] !== undefined) {
    queryParameters['staTime'] = parameters['staTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['teamId'] !== undefined) {
    queryParameters['teamId'] = parameters['teamId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamQualityControlRecordDetailFormData = function(formData) {
  const url = teamQualityControlRecordDetail_RAW_URL()
  const method = teamQualityControlRecordDetail_TYPE()
  return axios[method](url, formData)
}
export const teamQualityControlRecordDetail_RAW_URL = function() {
  return '/team/quality-control-record/detail'
}
export const teamQualityControlRecordDetail_TYPE = function() {
  return 'post'
}
export const teamQualityControlRecordDetail_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/quality-control-record/detail'
  if (parameters['staTime'] !== undefined) {
    queryParameters['staTime'] = parameters['staTime']
  }
  if (parameters['endTime'] !== undefined) {
    queryParameters['endTime'] = parameters['endTime']
  }
  if (parameters['teamId'] !== undefined) {
    queryParameters['teamId'] = parameters['teamId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存常见病报告
 * request: teamReportSaveCommonDiseaseReport
 * formData: teamReportSaveCommonDiseaseReportFormData
 * url: teamReportSaveCommonDiseaseReport_URL
 * method: teamReportSaveCommonDiseaseReport_TYPE
 * raw_url: teamReportSaveCommonDiseaseReport_RAW_URL
 * @param personId - 
 * @param deciduousTeethInfo - 
 * @param permanentTeethInfo - 
 * @param deciduousTeethDecayed - 
 * @param deciduousTeethMissing - 
 * @param deciduousTeethFill - 
 * @param permanentTeethDecayed - 
 * @param permanentTeethMissing - 
 * @param permanentTeethFill - 
 * @param height - 
 * @param weight - 
 * @param scoliosis - 脊柱侧弯
 * @param thoracicCurvature - 
 * @param thoracicCurvatureDegree - 
 * @param thoracolumbarCurvature - 
 * @param thoracolumbarCurvatureDegree - 
 * @param lumbarCurvature - 
 * @param lumbarCurvatureDegree - 
 * @param thoracicCurvatureV2 - 
 * @param thoracicCurvatureDegreeV2 - 
 * @param thoracolumbarCurvatureV2 - 
 * @param thoracolumbarCurvatureDegreeV2 - 
 * @param lumbarCurvatureV2 - 
 * @param lumbarCurvatureDegreeV2 - 
 * @param spineMotionTesting - 进行脊柱运动试验
 * @param testingThoracicAtr - 试验胸段ATR
 * @param testingThoracolumbarAtr - 试验腰胸段ATR
 * @param testingLumbarAtr - 试验腰段ATR
 * @param frontBackCurvature - 
 * @param frontBackCurvatureDegree - 
 * @param frontBackCurvatureV2 - 
 * @param bendDownLieExperiment - 
 * @param spinalCurvatureFirstResult - 
 * @param historyOfTheSpine - 
 * @param systolicPressure - 
 * @param diastolicBloodPressure - 
 * @param menstruation - 
 * @param firstMenstruationAge - 
 * @param menstruationFrequency - 
 * @param menstrualDuration - 
 * @param spermatorrhea - 
 * @param firstSpermatorrheaAge - 
 * @param remark - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param teethTesterMobile - 
 * @param heightTesterMobile - 
 * @param weightTesterMobile - 
 * @param spineSideBendTesterMobile - 
 * @param bloodPressureTesterMobile - 
 * @param menstruationSpermatorrheaTesterMobile - 
 * @param heightTestDeviceNumber - 
 * @param weightTestDeviceNumber - 
 * @param spineSideBendTestDeviceNumber - 
 * @param bloodPressureTestDeviceNumber - 
 * @param stethoscopeDeviceNumber - 
 */
export const teamReportSaveCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/common-disease-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['deciduousTeethInfo'] !== undefined) {
    form['deciduousTeethInfo'] = parameters['deciduousTeethInfo']
  }
  if (parameters['permanentTeethInfo'] !== undefined) {
    form['permanentTeethInfo'] = parameters['permanentTeethInfo']
  }
  if (parameters['deciduousTeethDecayed'] !== undefined) {
    form['deciduousTeethDecayed'] = parameters['deciduousTeethDecayed']
  }
  if (parameters['deciduousTeethMissing'] !== undefined) {
    form['deciduousTeethMissing'] = parameters['deciduousTeethMissing']
  }
  if (parameters['deciduousTeethFill'] !== undefined) {
    form['deciduousTeethFill'] = parameters['deciduousTeethFill']
  }
  if (parameters['permanentTeethDecayed'] !== undefined) {
    form['permanentTeethDecayed'] = parameters['permanentTeethDecayed']
  }
  if (parameters['permanentTeethMissing'] !== undefined) {
    form['permanentTeethMissing'] = parameters['permanentTeethMissing']
  }
  if (parameters['permanentTeethFill'] !== undefined) {
    form['permanentTeethFill'] = parameters['permanentTeethFill']
  }
  if (parameters['height'] !== undefined) {
    form['height'] = parameters['height']
  }
  if (parameters['weight'] !== undefined) {
    form['weight'] = parameters['weight']
  }
  if (parameters['scoliosis'] !== undefined) {
    form['scoliosis'] = parameters['scoliosis']
  }
  if (parameters['thoracicCurvature'] !== undefined) {
    form['thoracicCurvature'] = parameters['thoracicCurvature']
  }
  if (parameters['thoracicCurvatureDegree'] !== undefined) {
    form['thoracicCurvatureDegree'] = parameters['thoracicCurvatureDegree']
  }
  if (parameters['thoracolumbarCurvature'] !== undefined) {
    form['thoracolumbarCurvature'] = parameters['thoracolumbarCurvature']
  }
  if (parameters['thoracolumbarCurvatureDegree'] !== undefined) {
    form['thoracolumbarCurvatureDegree'] = parameters['thoracolumbarCurvatureDegree']
  }
  if (parameters['lumbarCurvature'] !== undefined) {
    form['lumbarCurvature'] = parameters['lumbarCurvature']
  }
  if (parameters['lumbarCurvatureDegree'] !== undefined) {
    form['lumbarCurvatureDegree'] = parameters['lumbarCurvatureDegree']
  }
  if (parameters['thoracicCurvatureV2'] !== undefined) {
    form['thoracicCurvatureV2'] = parameters['thoracicCurvatureV2']
  }
  if (parameters['thoracicCurvatureDegreeV2'] !== undefined) {
    form['thoracicCurvatureDegreeV2'] = parameters['thoracicCurvatureDegreeV2']
  }
  if (parameters['thoracolumbarCurvatureV2'] !== undefined) {
    form['thoracolumbarCurvatureV2'] = parameters['thoracolumbarCurvatureV2']
  }
  if (parameters['thoracolumbarCurvatureDegreeV2'] !== undefined) {
    form['thoracolumbarCurvatureDegreeV2'] = parameters['thoracolumbarCurvatureDegreeV2']
  }
  if (parameters['lumbarCurvatureV2'] !== undefined) {
    form['lumbarCurvatureV2'] = parameters['lumbarCurvatureV2']
  }
  if (parameters['lumbarCurvatureDegreeV2'] !== undefined) {
    form['lumbarCurvatureDegreeV2'] = parameters['lumbarCurvatureDegreeV2']
  }
  if (parameters['spineMotionTesting'] !== undefined) {
    form['spineMotionTesting'] = parameters['spineMotionTesting']
  }
  if (parameters['testingThoracicAtr'] !== undefined) {
    form['testingThoracicATR'] = parameters['testingThoracicAtr']
  }
  if (parameters['testingThoracolumbarAtr'] !== undefined) {
    form['testingThoracolumbarATR'] = parameters['testingThoracolumbarAtr']
  }
  if (parameters['testingLumbarAtr'] !== undefined) {
    form['testingLumbarATR'] = parameters['testingLumbarAtr']
  }
  if (parameters['frontBackCurvature'] !== undefined) {
    form['frontBackCurvature'] = parameters['frontBackCurvature']
  }
  if (parameters['frontBackCurvatureDegree'] !== undefined) {
    form['frontBackCurvatureDegree'] = parameters['frontBackCurvatureDegree']
  }
  if (parameters['frontBackCurvatureV2'] !== undefined) {
    form['frontBackCurvatureV2'] = parameters['frontBackCurvatureV2']
  }
  if (parameters['bendDownLieExperiment'] !== undefined) {
    form['bendDownLieExperiment'] = parameters['bendDownLieExperiment']
  }
  if (parameters['spinalCurvatureFirstResult'] !== undefined) {
    form['spinalCurvatureFirstResult'] = parameters['spinalCurvatureFirstResult']
  }
  if (parameters['historyOfTheSpine'] !== undefined) {
    form['historyOfTheSpine'] = parameters['historyOfTheSpine']
  }
  if (parameters['systolicPressure'] !== undefined) {
    form['systolicPressure'] = parameters['systolicPressure']
  }
  if (parameters['diastolicBloodPressure'] !== undefined) {
    form['diastolicBloodPressure'] = parameters['diastolicBloodPressure']
  }
  if (parameters['menstruation'] !== undefined) {
    form['menstruation'] = parameters['menstruation']
  }
  if (parameters['firstMenstruationAge'] !== undefined) {
    form['firstMenstruationAge'] = parameters['firstMenstruationAge']
  }
  if (parameters['menstruationFrequency'] !== undefined) {
    form['menstruationFrequency'] = parameters['menstruationFrequency']
  }
  if (parameters['menstrualDuration'] !== undefined) {
    form['menstrualDuration'] = parameters['menstrualDuration']
  }
  if (parameters['spermatorrhea'] !== undefined) {
    form['spermatorrhea'] = parameters['spermatorrhea']
  }
  if (parameters['firstSpermatorrheaAge'] !== undefined) {
    form['firstSpermatorrheaAge'] = parameters['firstSpermatorrheaAge']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['teethTesterMobile'] !== undefined) {
    form['teethTesterMobile'] = parameters['teethTesterMobile']
  }
  if (parameters['heightTesterMobile'] !== undefined) {
    form['heightTesterMobile'] = parameters['heightTesterMobile']
  }
  if (parameters['weightTesterMobile'] !== undefined) {
    form['weightTesterMobile'] = parameters['weightTesterMobile']
  }
  if (parameters['spineSideBendTesterMobile'] !== undefined) {
    form['spineSideBendTesterMobile'] = parameters['spineSideBendTesterMobile']
  }
  if (parameters['bloodPressureTesterMobile'] !== undefined) {
    form['bloodPressureTesterMobile'] = parameters['bloodPressureTesterMobile']
  }
  if (parameters['menstruationSpermatorrheaTesterMobile'] !== undefined) {
    form['menstruationSpermatorrheaTesterMobile'] = parameters['menstruationSpermatorrheaTesterMobile']
  }
  if (parameters['heightTestDeviceNumber'] !== undefined) {
    form['heightTestDeviceNumber'] = parameters['heightTestDeviceNumber']
  }
  if (parameters['weightTestDeviceNumber'] !== undefined) {
    form['weightTestDeviceNumber'] = parameters['weightTestDeviceNumber']
  }
  if (parameters['spineSideBendTestDeviceNumber'] !== undefined) {
    form['spineSideBendTestDeviceNumber'] = parameters['spineSideBendTestDeviceNumber']
  }
  if (parameters['bloodPressureTestDeviceNumber'] !== undefined) {
    form['bloodPressureTestDeviceNumber'] = parameters['bloodPressureTestDeviceNumber']
  }
  if (parameters['stethoscopeDeviceNumber'] !== undefined) {
    form['stethoscopeDeviceNumber'] = parameters['stethoscopeDeviceNumber']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveCommonDiseaseReportFormData = function(formData) {
  const url = teamReportSaveCommonDiseaseReport_RAW_URL()
  const method = teamReportSaveCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveCommonDiseaseReport_RAW_URL = function() {
  return '/team/report/common-disease-report'
}
export const teamReportSaveCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const teamReportSaveCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/common-disease-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存屈光报告
 * request: teamReportSaveDiopterReport
 * formData: teamReportSaveDiopterReportFormData
 * url: teamReportSaveDiopterReport_URL
 * method: teamReportSaveDiopterReport_TYPE
 * raw_url: teamReportSaveDiopterReport_RAW_URL
 * @param personId - 
 * @param leftDs - 
 * @param leftDc - 
 * @param leftAxis - 
 * @param rightDs - 
 * @param rightDc - 
 * @param rightAxis - 
 * @param remark - 
 * @param otherSpecialCircumstances - 
 * @param byRetest - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param diopterTesterMobile - 
 * @param diopterTestDeviceNumber - 
 * @param ballRight - 球镜-右眼,0手动1自动
 * @param ballLeft - 球镜-左眼
 * @param pillarRight - 柱镜-右眼
 * @param pillarLeft - 柱镜-左眼
 * @param axisRight - 轴位-右眼
 * @param axisLeft - 轴位-左眼
 */
export const teamReportSaveDiopterReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/diopter-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['leftDs'] !== undefined) {
    form['leftDS'] = parameters['leftDs']
  }
  if (parameters['leftDc'] !== undefined) {
    form['leftDC'] = parameters['leftDc']
  }
  if (parameters['leftAxis'] !== undefined) {
    form['leftAxis'] = parameters['leftAxis']
  }
  if (parameters['rightDs'] !== undefined) {
    form['rightDS'] = parameters['rightDs']
  }
  if (parameters['rightDc'] !== undefined) {
    form['rightDC'] = parameters['rightDc']
  }
  if (parameters['rightAxis'] !== undefined) {
    form['rightAxis'] = parameters['rightAxis']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['otherSpecialCircumstances'] !== undefined) {
    form['otherSpecialCircumstances'] = parameters['otherSpecialCircumstances']
  }
  if (parameters['byRetest'] !== undefined) {
    form['byRetest'] = parameters['byRetest']
  }
  if (parameters['byRetest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: byRetest'))
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['diopterTesterMobile'] !== undefined) {
    form['diopterTesterMobile'] = parameters['diopterTesterMobile']
  }
  if (parameters['diopterTestDeviceNumber'] !== undefined) {
    form['diopterTestDeviceNumber'] = parameters['diopterTestDeviceNumber']
  }
  if (parameters['ballRight'] !== undefined) {
    form['ballRight'] = parameters['ballRight']
  }
  if (parameters['ballLeft'] !== undefined) {
    form['ballLeft'] = parameters['ballLeft']
  }
  if (parameters['pillarRight'] !== undefined) {
    form['pillarRight'] = parameters['pillarRight']
  }
  if (parameters['pillarLeft'] !== undefined) {
    form['pillarLeft'] = parameters['pillarLeft']
  }
  if (parameters['axisRight'] !== undefined) {
    form['axisRight'] = parameters['axisRight']
  }
  if (parameters['axisLeft'] !== undefined) {
    form['axisLeft'] = parameters['axisLeft']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveDiopterReportFormData = function(formData) {
  const url = teamReportSaveDiopterReport_RAW_URL()
  const method = teamReportSaveDiopterReport_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveDiopterReport_RAW_URL = function() {
  return '/team/report/diopter-report'
}
export const teamReportSaveDiopterReport_TYPE = function() {
  return 'post'
}
export const teamReportSaveDiopterReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/diopter-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存报告
 * request: teamReportSaveReport
 * formData: teamReportSaveReportFormData
 * url: teamReportSaveReport_URL
 * method: teamReportSaveReport_TYPE
 * raw_url: teamReportSaveReport_RAW_URL
 * @param vo - vo
 */
export const teamReportSaveReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveReportFormData = function(formData) {
  const url = teamReportSaveReport_RAW_URL()
  const method = teamReportSaveReport_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveReport_RAW_URL = function() {
  return '/team/report/report'
}
export const teamReportSaveReport_TYPE = function() {
  return 'post'
}
export const teamReportSaveReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存复测常见病报告
 * request: teamReportSaveRetestCommonDiseaseReport
 * formData: teamReportSaveRetestCommonDiseaseReportFormData
 * url: teamReportSaveRetestCommonDiseaseReport_URL
 * method: teamReportSaveRetestCommonDiseaseReport_TYPE
 * raw_url: teamReportSaveRetestCommonDiseaseReport_RAW_URL
 * @param personId - 
 * @param height - 
 * @param weight - 
 * @param remark - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param heightTesterMobile - 
 * @param weightTesterMobile - 
 * @param heightTestDeviceNumber - 
 * @param weightTestDeviceNumber - 
 */
export const teamReportSaveRetestCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/retest-common-disease-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['height'] !== undefined) {
    form['height'] = parameters['height']
  }
  if (parameters['weight'] !== undefined) {
    form['weight'] = parameters['weight']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['heightTesterMobile'] !== undefined) {
    form['heightTesterMobile'] = parameters['heightTesterMobile']
  }
  if (parameters['weightTesterMobile'] !== undefined) {
    form['weightTesterMobile'] = parameters['weightTesterMobile']
  }
  if (parameters['heightTestDeviceNumber'] !== undefined) {
    form['heightTestDeviceNumber'] = parameters['heightTestDeviceNumber']
  }
  if (parameters['weightTestDeviceNumber'] !== undefined) {
    form['weightTestDeviceNumber'] = parameters['weightTestDeviceNumber']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveRetestCommonDiseaseReportFormData = function(formData) {
  const url = teamReportSaveRetestCommonDiseaseReport_RAW_URL()
  const method = teamReportSaveRetestCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveRetestCommonDiseaseReport_RAW_URL = function() {
  return '/team/report/retest-common-disease-report'
}
export const teamReportSaveRetestCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const teamReportSaveRetestCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/retest-common-disease-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存疾病史
 * request: teamReportSaveStudentMedicalHistory
 * formData: teamReportSaveStudentMedicalHistoryFormData
 * url: teamReportSaveStudentMedicalHistory_URL
 * method: teamReportSaveStudentMedicalHistory_TYPE
 * raw_url: teamReportSaveStudentMedicalHistory_RAW_URL
 * @param screeningStudentId - 
 * @param geneticsHistory - 
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param diseaseHistoryCoronavirus - 
 */
export const teamReportSaveStudentMedicalHistory = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/student-medical-history'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['diseaseHistoryCoronavirus'] !== undefined) {
    form['diseaseHistoryCoronavirus'] = parameters['diseaseHistoryCoronavirus']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveStudentMedicalHistoryFormData = function(formData) {
  const url = teamReportSaveStudentMedicalHistory_RAW_URL()
  const method = teamReportSaveStudentMedicalHistory_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveStudentMedicalHistory_RAW_URL = function() {
  return '/team/report/student-medical-history'
}
export const teamReportSaveStudentMedicalHistory_TYPE = function() {
  return 'post'
}
export const teamReportSaveStudentMedicalHistory_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/student-medical-history'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改学生基本信息
 * request: teamReportUpdateStudent
 * formData: teamReportUpdateStudentFormData
 * url: teamReportUpdateStudent_URL
 * method: teamReportUpdateStudent_TYPE
 * raw_url: teamReportUpdateStudent_RAW_URL
 * @param studentId - studentId
 * @param sex - 
 * @param birth - 
 * @param nation - 
 */
export const teamReportUpdateStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamReportUpdateStudentFormData = function(formData) {
  const url = teamReportUpdateStudent_RAW_URL()
  const method = teamReportUpdateStudent_TYPE()
  return axios[method](url, formData)
}
export const teamReportUpdateStudent_RAW_URL = function() {
  return '/team/report/student/{studentId}'
}
export const teamReportUpdateStudent_TYPE = function() {
  return 'put'
}
export const teamReportUpdateStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生报告检查时间
 * request: teamReportStudentCheckTime
 * formData: teamReportStudentCheckTimeFormData
 * url: teamReportStudentCheckTime_URL
 * method: teamReportStudentCheckTime_TYPE
 * raw_url: teamReportStudentCheckTime_RAW_URL
 * @param studentId - studentId
 */
export const teamReportStudentCheckTime = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/student/{studentId}/check-time'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamReportStudentCheckTimeFormData = function(formData) {
  const url = teamReportStudentCheckTime_RAW_URL()
  const method = teamReportStudentCheckTime_TYPE()
  return axios[method](url, formData)
}
export const teamReportStudentCheckTime_RAW_URL = function() {
  return '/team/report/student/{studentId}/check-time'
}
export const teamReportStudentCheckTime_TYPE = function() {
  return 'get'
}
export const teamReportStudentCheckTime_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/student/{studentId}/check-time'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 同步学生初检复检时间
 * request: teamReportSyncStudentCheckTime
 * formData: teamReportSyncStudentCheckTimeFormData
 * url: teamReportSyncStudentCheckTime_URL
 * method: teamReportSyncStudentCheckTime_TYPE
 * raw_url: teamReportSyncStudentCheckTime_RAW_URL
 * @param studentId - studentId
 * @param dateMills - dateMills
 */
export const teamReportSyncStudentCheckTime = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/student/{studentId}/sync-check-time'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['dateMills'] !== undefined) {
    queryParameters['dateMills'] = parameters['dateMills']
  }
  if (parameters['dateMills'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: dateMills'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSyncStudentCheckTimeFormData = function(formData) {
  const url = teamReportSyncStudentCheckTime_RAW_URL()
  const method = teamReportSyncStudentCheckTime_TYPE()
  return axios[method](url, formData)
}
export const teamReportSyncStudentCheckTime_RAW_URL = function() {
  return '/team/report/student/{studentId}/sync-check-time'
}
export const teamReportSyncStudentCheckTime_TYPE = function() {
  return 'post'
}
export const teamReportSyncStudentCheckTime_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/student/{studentId}/sync-check-time'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['dateMills'] !== undefined) {
    queryParameters['dateMills'] = parameters['dateMills']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存视力报告
 * request: teamReportSaveVisionReport
 * formData: teamReportSaveVisionReportFormData
 * url: teamReportSaveVisionReport_URL
 * method: teamReportSaveVisionReport_TYPE
 * raw_url: teamReportSaveVisionReport_RAW_URL
 * @param personId - 
 * @param wearingGlassesType - 
 * @param leftVision - 
 * @param rightVision - 
 * @param leftVisionBox - 
 * @param rightVisionBox - 
 * @param leftCorrectedVision - 
 * @param rightCorrectedVision - 
 * @param okLeft - 
 * @param okRight - 
 * @param remark - 
 * @param otherSpecialCircumstances - 
 * @param byRetest - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param visionTesterMobile - 
 * @param visionTestDeviceNumber - 
 * @param eyeDisease - 
 * @param otherEyeDisease - 
 */
export const teamReportSaveVisionReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/report/vision-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['wearingGlassesType'] !== undefined) {
    form['wearingGlassesType'] = parameters['wearingGlassesType']
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['leftVisionBox'] !== undefined) {
    form['leftVisionBox'] = parameters['leftVisionBox']
  }
  if (parameters['rightVisionBox'] !== undefined) {
    form['rightVisionBox'] = parameters['rightVisionBox']
  }
  if (parameters['leftCorrectedVision'] !== undefined) {
    form['leftCorrectedVision'] = parameters['leftCorrectedVision']
  }
  if (parameters['rightCorrectedVision'] !== undefined) {
    form['rightCorrectedVision'] = parameters['rightCorrectedVision']
  }
  if (parameters['okLeft'] !== undefined) {
    form['okLeft'] = parameters['okLeft']
  }
  if (parameters['okRight'] !== undefined) {
    form['okRight'] = parameters['okRight']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['otherSpecialCircumstances'] !== undefined) {
    form['otherSpecialCircumstances'] = parameters['otherSpecialCircumstances']
  }
  if (parameters['byRetest'] !== undefined) {
    form['byRetest'] = parameters['byRetest']
  }
  if (parameters['byRetest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: byRetest'))
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['visionTesterMobile'] !== undefined) {
    form['visionTesterMobile'] = parameters['visionTesterMobile']
  }
  if (parameters['visionTestDeviceNumber'] !== undefined) {
    form['visionTestDeviceNumber'] = parameters['visionTestDeviceNumber']
  }
  if (parameters['eyeDisease'] !== undefined) {
    form['eyeDisease'] = parameters['eyeDisease']
  }
  if (parameters['otherEyeDisease'] !== undefined) {
    form['otherEyeDisease'] = parameters['otherEyeDisease']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamReportSaveVisionReportFormData = function(formData) {
  const url = teamReportSaveVisionReport_RAW_URL()
  const method = teamReportSaveVisionReport_TYPE()
  return axios[method](url, formData)
}
export const teamReportSaveVisionReport_RAW_URL = function() {
  return '/team/report/vision-report'
}
export const teamReportSaveVisionReport_TYPE = function() {
  return 'post'
}
export const teamReportSaveVisionReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/report/vision-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: teamRoleList
 * formData: teamRoleListFormData
 * url: teamRoleList_URL
 * method: teamRoleList_TYPE
 * raw_url: teamRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamRoleListFormData = function(formData) {
  const url = teamRoleList_RAW_URL()
  const method = teamRoleList_TYPE()
  return axios[method](url, formData)
}
export const teamRoleList_RAW_URL = function() {
  return '/team/role'
}
export const teamRoleList_TYPE = function() {
  return 'get'
}
export const teamRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: teamRoleAdd
 * formData: teamRoleAddFormData
 * url: teamRoleAdd_URL
 * method: teamRoleAdd_TYPE
 * raw_url: teamRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const teamRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamRoleAddFormData = function(formData) {
  const url = teamRoleAdd_RAW_URL()
  const method = teamRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const teamRoleAdd_RAW_URL = function() {
  return '/team/role'
}
export const teamRoleAdd_TYPE = function() {
  return 'post'
}
export const teamRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: teamFoleListFunction
 * formData: teamFoleListFunctionFormData
 * url: teamFoleListFunction_URL
 * method: teamFoleListFunction_TYPE
 * raw_url: teamFoleListFunction_RAW_URL
 */
export const teamFoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamFoleListFunctionFormData = function(formData) {
  const url = teamFoleListFunction_RAW_URL()
  const method = teamFoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const teamFoleListFunction_RAW_URL = function() {
  return '/team/role/function'
}
export const teamFoleListFunction_TYPE = function() {
  return 'get'
}
export const teamFoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: teamRoleFind
 * formData: teamRoleFindFormData
 * url: teamRoleFind_URL
 * method: teamRoleFind_TYPE
 * raw_url: teamRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const teamRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamRoleFindFormData = function(formData) {
  const url = teamRoleFind_RAW_URL()
  const method = teamRoleFind_TYPE()
  return axios[method](url, formData)
}
export const teamRoleFind_RAW_URL = function() {
  return '/team/role/{roleId}'
}
export const teamRoleFind_TYPE = function() {
  return 'get'
}
export const teamRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: teamRoleUpdate
 * formData: teamRoleUpdateFormData
 * url: teamRoleUpdate_URL
 * method: teamRoleUpdate_TYPE
 * raw_url: teamRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const teamRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamRoleUpdateFormData = function(formData) {
  const url = teamRoleUpdate_RAW_URL()
  const method = teamRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const teamRoleUpdate_RAW_URL = function() {
  return '/team/role/{roleId}'
}
export const teamRoleUpdate_TYPE = function() {
  return 'put'
}
export const teamRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: teamRoleDelete
 * formData: teamRoleDeleteFormData
 * url: teamRoleDelete_URL
 * method: teamRoleDelete_TYPE
 * raw_url: teamRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const teamRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamRoleDeleteFormData = function(formData) {
  const url = teamRoleDelete_RAW_URL()
  const method = teamRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const teamRoleDelete_RAW_URL = function() {
  return '/team/role/{roleId}'
}
export const teamRoleDelete_TYPE = function() {
  return 'delete'
}
export const teamRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生筛查进度
 * request: teamScreeningMonitoringStudentScreeningProgress
 * formData: teamScreeningMonitoringStudentScreeningProgressFormData
 * url: teamScreeningMonitoringStudentScreeningProgress_URL
 * method: teamScreeningMonitoringStudentScreeningProgress_TYPE
 * raw_url: teamScreeningMonitoringStudentScreeningProgress_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const teamScreeningMonitoringStudentScreeningProgress = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringStudentScreeningProgressFormData = function(formData) {
  const url = teamScreeningMonitoringStudentScreeningProgress_RAW_URL()
  const method = teamScreeningMonitoringStudentScreeningProgress_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringStudentScreeningProgress_RAW_URL = function() {
  return '/team/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
}
export const teamScreeningMonitoringStudentScreeningProgress_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringStudentScreeningProgress_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: teamScreeningMonitoringFind
 * formData: teamScreeningMonitoringFindFormData
 * url: teamScreeningMonitoringFind_URL
 * method: teamScreeningMonitoringFind_TYPE
 * raw_url: teamScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringFindFormData = function(formData) {
  const url = teamScreeningMonitoringFind_RAW_URL()
  const method = teamScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringFind_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const teamScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: teamScreeningMonitoringAbnormalStudent
 * formData: teamScreeningMonitoringAbnormalStudentFormData
 * url: teamScreeningMonitoringAbnormalStudent_URL
 * method: teamScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: teamScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = teamScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = teamScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const teamScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: teamScreeningMonitoringFinishedStudent
 * formData: teamScreeningMonitoringFinishedStudentFormData
 * url: teamScreeningMonitoringFinishedStudent_URL
 * method: teamScreeningMonitoringFinishedStudent_TYPE
 * raw_url: teamScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = teamScreeningMonitoringFinishedStudent_RAW_URL()
  const method = teamScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const teamScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: teamScreeningMonitoringFinishedStudentWithQuestionary
 * formData: teamScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: teamScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: teamScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: teamScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = teamScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = teamScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const teamScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: teamScreeningMonitoringMissingStudent
 * formData: teamScreeningMonitoringMissingStudentFormData
 * url: teamScreeningMonitoringMissingStudent_URL
 * method: teamScreeningMonitoringMissingStudent_TYPE
 * raw_url: teamScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = teamScreeningMonitoringMissingStudent_RAW_URL()
  const method = teamScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const teamScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: teamScreeningMonitoringMissingStudentWithQuestionary
 * formData: teamScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: teamScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: teamScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: teamScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = teamScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = teamScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const teamScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: teamScreeningMonitoringUncheckedStudent
 * formData: teamScreeningMonitoringUncheckedStudentFormData
 * url: teamScreeningMonitoringUncheckedStudent_URL
 * method: teamScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: teamScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = teamScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = teamScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const teamScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: teamScreeningMonitoringFindWithQuestionary
 * formData: teamScreeningMonitoringFindWithQuestionaryFormData
 * url: teamScreeningMonitoringFindWithQuestionary_URL
 * method: teamScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: teamScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = teamScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = teamScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const teamScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: teamScreeningMonitoringQualityControl
 * formData: teamScreeningMonitoringQualityControlFormData
 * url: teamScreeningMonitoringQualityControl_URL
 * method: teamScreeningMonitoringQualityControl_TYPE
 * raw_url: teamScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const teamScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringQualityControlFormData = function(formData) {
  const url = teamScreeningMonitoringQualityControl_RAW_URL()
  const method = teamScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/team/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const teamScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: teamScreeningMonitoringQuestionnaryScreeningSchool
 * formData: teamScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: teamScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: teamScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: teamScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = teamScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = teamScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/team/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const teamScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: teamScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: teamScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: teamScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: teamScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: teamScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const teamScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = teamScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = teamScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/team/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const teamScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划列表
 * request: teamScreeningPlanList
 * formData: teamScreeningPlanListFormData
 * url: teamScreeningPlanList_URL
 * method: teamScreeningPlanList_TYPE
 * raw_url: teamScreeningPlanList_RAW_URL
 */
export const teamScreeningPlanList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanListFormData = function(formData) {
  const url = teamScreeningPlanList_RAW_URL()
  const method = teamScreeningPlanList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanList_RAW_URL = function() {
  return '/team/screening-plan'
}
export const teamScreeningPlanList_TYPE = function() {
  return 'get'
}
export const teamScreeningPlanList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增筛查计划
 * request: teamScreeningPlanAdd
 * formData: teamScreeningPlanAddFormData
 * url: teamScreeningPlanAdd_URL
 * method: teamScreeningPlanAdd_TYPE
 * raw_url: teamScreeningPlanAdd_RAW_URL
 * @param type - 类型
 * @param name - 筛查计划名称
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param regionType - 地区类型
 * @param startDate - 开始时间
 * @param planEndDate - 计划完成时间
 * @param inputType - 录入类型
 */
export const teamScreeningPlanAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['regionType'] !== undefined) {
    form['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['startDate'] !== undefined) {
    form['startDate'] = parameters['startDate']
  }
  if (parameters['startDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startDate'))
  }
  if (parameters['planEndDate'] !== undefined) {
    form['planEndDate'] = parameters['planEndDate']
  }
  if (parameters['planEndDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planEndDate'))
  }
  if (parameters['inputType'] !== undefined) {
    form['inputType'] = parameters['inputType']
  }
  if (parameters['inputType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: inputType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanAddFormData = function(formData) {
  const url = teamScreeningPlanAdd_RAW_URL()
  const method = teamScreeningPlanAdd_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanAdd_RAW_URL = function() {
  return '/team/screening-plan'
}
export const teamScreeningPlanAdd_TYPE = function() {
  return 'post'
}
export const teamScreeningPlanAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划列表
 * request: teamScreeningPlanPageList
 * formData: teamScreeningPlanPageListFormData
 * url: teamScreeningPlanPageList_URL
 * method: teamScreeningPlanPageList_TYPE
 * raw_url: teamScreeningPlanPageList_RAW_URL
 * @param planDate - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningPlanPageList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/page'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planDate'] !== undefined) {
    queryParameters['planDate'] = parameters['planDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanPageListFormData = function(formData) {
  const url = teamScreeningPlanPageList_RAW_URL()
  const method = teamScreeningPlanPageList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanPageList_RAW_URL = function() {
  return '/team/screening-plan/page'
}
export const teamScreeningPlanPageList_TYPE = function() {
  return 'get'
}
export const teamScreeningPlanPageList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/page'
  if (parameters['planDate'] !== undefined) {
    queryParameters['planDate'] = parameters['planDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查计划详情
 * request: teamScreeningPlanFind
 * formData: teamScreeningPlanFindFormData
 * url: teamScreeningPlanFind_URL
 * method: teamScreeningPlanFind_TYPE
 * raw_url: teamScreeningPlanFind_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const teamScreeningPlanFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanFindFormData = function(formData) {
  const url = teamScreeningPlanFind_RAW_URL()
  const method = teamScreeningPlanFind_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanFind_RAW_URL = function() {
  return '/team/screening-plan/{screeningPlanId}'
}
export const teamScreeningPlanFind_TYPE = function() {
  return 'get'
}
export const teamScreeningPlanFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查计划
 * request: teamScreeningPlanUpdate
 * formData: teamScreeningPlanUpdateFormData
 * url: teamScreeningPlanUpdate_URL
 * method: teamScreeningPlanUpdate_TYPE
 * raw_url: teamScreeningPlanUpdate_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param type - 类型
 * @param name - 筛查计划名称
 * @param provinceCode - 省编码
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param regionType - 地区类型
 * @param startDate - 开始时间
 * @param planEndDate - 计划完成时间
 */
export const teamScreeningPlanUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters['type'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: type'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['provinceCode'] !== undefined) {
    form['provinceCode'] = parameters['provinceCode']
  }
  if (parameters['provinceCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: provinceCode'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['regionType'] !== undefined) {
    form['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['startDate'] !== undefined) {
    form['startDate'] = parameters['startDate']
  }
  if (parameters['startDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: startDate'))
  }
  if (parameters['planEndDate'] !== undefined) {
    form['planEndDate'] = parameters['planEndDate']
  }
  if (parameters['planEndDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planEndDate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanUpdateFormData = function(formData) {
  const url = teamScreeningPlanUpdate_RAW_URL()
  const method = teamScreeningPlanUpdate_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanUpdate_RAW_URL = function() {
  return '/team/screening-plan/{screeningPlanId}'
}
export const teamScreeningPlanUpdate_TYPE = function() {
  return 'put'
}
export const teamScreeningPlanUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查计划
 * request: teamScreeningPlanDelete
 * formData: teamScreeningPlanDeleteFormData
 * url: teamScreeningPlanDelete_URL
 * method: teamScreeningPlanDelete_TYPE
 * raw_url: teamScreeningPlanDelete_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const teamScreeningPlanDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/{screeningPlanId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanDeleteFormData = function(formData) {
  const url = teamScreeningPlanDelete_RAW_URL()
  const method = teamScreeningPlanDelete_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanDelete_RAW_URL = function() {
  return '/team/screening-plan/{screeningPlanId}'
}
export const teamScreeningPlanDelete_TYPE = function() {
  return 'delete'
}
export const teamScreeningPlanDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/{screeningPlanId}'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成筛查
 * request: teamScreeningPlanFinishScreening
 * formData: teamScreeningPlanFinishScreeningFormData
 * url: teamScreeningPlanFinishScreening_URL
 * method: teamScreeningPlanFinishScreening_TYPE
 * raw_url: teamScreeningPlanFinishScreening_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const teamScreeningPlanFinishScreening = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/{screeningPlanId}/finish-screening'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanFinishScreeningFormData = function(formData) {
  const url = teamScreeningPlanFinishScreening_RAW_URL()
  const method = teamScreeningPlanFinishScreening_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanFinishScreening_RAW_URL = function() {
  return '/team/screening-plan/{screeningPlanId}/finish-screening'
}
export const teamScreeningPlanFinishScreening_TYPE = function() {
  return 'put'
}
export const teamScreeningPlanFinishScreening_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/{screeningPlanId}/finish-screening'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发布筛查计划
 * request: teamScreeningPlanUpdateStatusEnable
 * formData: teamScreeningPlanUpdateStatusEnableFormData
 * url: teamScreeningPlanUpdateStatusEnable_URL
 * method: teamScreeningPlanUpdateStatusEnable_TYPE
 * raw_url: teamScreeningPlanUpdateStatusEnable_RAW_URL
 * @param screeningPlanId - screeningPlanId
 */
export const teamScreeningPlanUpdateStatusEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-plan/{screeningPlanId}/status-enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningPlanUpdateStatusEnableFormData = function(formData) {
  const url = teamScreeningPlanUpdateStatusEnable_RAW_URL()
  const method = teamScreeningPlanUpdateStatusEnable_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningPlanUpdateStatusEnable_RAW_URL = function() {
  return '/team/screening-plan/{screeningPlanId}/status-enable'
}
export const teamScreeningPlanUpdateStatusEnable_TYPE = function() {
  return 'put'
}
export const teamScreeningPlanUpdateStatusEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-plan/{screeningPlanId}/status-enable'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查地区
 * request: teamScreeningRegionAdd
 * formData: teamScreeningRegionAddFormData
 * url: teamScreeningRegionAdd_URL
 * method: teamScreeningRegionAdd_TYPE
 * raw_url: teamScreeningRegionAdd_RAW_URL
 * @param screeningRegionVo - screeningRegionVO
 */
export const teamScreeningRegionAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningRegionVo'] !== undefined) {
    body = parameters['screeningRegionVo']
  }
  if (parameters['screeningRegionVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionAddFormData = function(formData) {
  const url = teamScreeningRegionAdd_RAW_URL()
  const method = teamScreeningRegionAdd_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionAdd_RAW_URL = function() {
  return '/team/screening-region'
}
export const teamScreeningRegionAdd_TYPE = function() {
  return 'post'
}
export const teamScreeningRegionAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区监测列表
 * request: teamScreeningRegionRegionMonitoringList
 * formData: teamScreeningRegionRegionMonitoringListFormData
 * url: teamScreeningRegionRegionMonitoringList_URL
 * method: teamScreeningRegionRegionMonitoringList_TYPE
 * raw_url: teamScreeningRegionRegionMonitoringList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const teamScreeningRegionRegionMonitoringList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionRegionMonitoringListFormData = function(formData) {
  const url = teamScreeningRegionRegionMonitoringList_RAW_URL()
  const method = teamScreeningRegionRegionMonitoringList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionRegionMonitoringList_RAW_URL = function() {
  return '/team/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
}
export const teamScreeningRegionRegionMonitoringList_TYPE = function() {
  return 'get'
}
export const teamScreeningRegionRegionMonitoringList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加下级地区
 * request: teamScreeningRegionAddLowerRegion
 * formData: teamScreeningRegionAddLowerRegionFormData
 * url: teamScreeningRegionAddLowerRegion_URL
 * method: teamScreeningRegionAddLowerRegion_TYPE
 * raw_url: teamScreeningRegionAddLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param vo - vo
 */
export const teamScreeningRegionAddLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{parentRegionId}/lower-region'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionAddLowerRegionFormData = function(formData) {
  const url = teamScreeningRegionAddLowerRegion_RAW_URL()
  const method = teamScreeningRegionAddLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionAddLowerRegion_RAW_URL = function() {
  return '/team/screening-region/{parentRegionId}/lower-region'
}
export const teamScreeningRegionAddLowerRegion_TYPE = function() {
  return 'post'
}
export const teamScreeningRegionAddLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{parentRegionId}/lower-region'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改下级地区
 * request: teamScreeningRegionUpdateLowerRegion
 * formData: teamScreeningRegionUpdateLowerRegionFormData
 * url: teamScreeningRegionUpdateLowerRegion_URL
 * method: teamScreeningRegionUpdateLowerRegion_TYPE
 * raw_url: teamScreeningRegionUpdateLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param screeningRegionId - screeningRegionId
 * @param vo - vo
 */
export const teamScreeningRegionUpdateLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionUpdateLowerRegionFormData = function(formData) {
  const url = teamScreeningRegionUpdateLowerRegion_RAW_URL()
  const method = teamScreeningRegionUpdateLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionUpdateLowerRegion_RAW_URL = function() {
  return '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
}
export const teamScreeningRegionUpdateLowerRegion_TYPE = function() {
  return 'put'
}
export const teamScreeningRegionUpdateLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除下级地区
 * request: teamScreeningRegionDeleteLowerRegion
 * formData: teamScreeningRegionDeleteLowerRegionFormData
 * url: teamScreeningRegionDeleteLowerRegion_URL
 * method: teamScreeningRegionDeleteLowerRegion_TYPE
 * raw_url: teamScreeningRegionDeleteLowerRegion_RAW_URL
 * @param parentRegionId - parentRegionId
 * @param screeningRegionId - screeningRegionId
 */
export const teamScreeningRegionDeleteLowerRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  if (parameters['parentRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: parentRegionId'))
  }
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionDeleteLowerRegionFormData = function(formData) {
  const url = teamScreeningRegionDeleteLowerRegion_RAW_URL()
  const method = teamScreeningRegionDeleteLowerRegion_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionDeleteLowerRegion_RAW_URL = function() {
  return '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
}
export const teamScreeningRegionDeleteLowerRegion_TYPE = function() {
  return 'delete'
}
export const teamScreeningRegionDeleteLowerRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{parentRegionId}/lower-region/{screeningRegionId}'
  path = path.replace('{parentRegionId}', `${parameters['parentRegionId']}`)
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷
 * request: teamScreeningRegionQuestionary
 * formData: teamScreeningRegionQuestionaryFormData
 * url: teamScreeningRegionQuestionary_URL
 * method: teamScreeningRegionQuestionary_TYPE
 * raw_url: teamScreeningRegionQuestionary_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 */
export const teamScreeningRegionQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningPlanId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionQuestionaryFormData = function(formData) {
  const url = teamScreeningRegionQuestionary_RAW_URL()
  const method = teamScreeningRegionQuestionary_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionQuestionary_RAW_URL = function() {
  return '/team/screening-region/{screeningPlanId}/questionary'
}
export const teamScreeningRegionQuestionary_TYPE = function() {
  return 'get'
}
export const teamScreeningRegionQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningPlanId}/questionary'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷统计
 * request: teamScreeningRegionQuestionaryStats
 * formData: teamScreeningRegionQuestionaryStatsFormData
 * url: teamScreeningRegionQuestionaryStats_URL
 * method: teamScreeningRegionQuestionaryStats_TYPE
 * raw_url: teamScreeningRegionQuestionaryStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const teamScreeningRegionQuestionaryStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningPlanId}/questionary-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionQuestionaryStatsFormData = function(formData) {
  const url = teamScreeningRegionQuestionaryStats_RAW_URL()
  const method = teamScreeningRegionQuestionaryStats_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionQuestionaryStats_RAW_URL = function() {
  return '/team/screening-region/{screeningPlanId}/questionary-stats'
}
export const teamScreeningRegionQuestionaryStats_TYPE = function() {
  return 'get'
}
export const teamScreeningRegionQuestionaryStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningPlanId}/questionary-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查地区
 * request: teamScreeningRegionUpdate
 * formData: teamScreeningRegionUpdateFormData
 * url: teamScreeningRegionUpdate_URL
 * method: teamScreeningRegionUpdate_TYPE
 * raw_url: teamScreeningRegionUpdate_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param screeningRegionVo - screeningRegionVO
 */
export const teamScreeningRegionUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['screeningRegionVo'] !== undefined) {
    body = parameters['screeningRegionVo']
  }
  if (parameters['screeningRegionVo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionVo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionUpdateFormData = function(formData) {
  const url = teamScreeningRegionUpdate_RAW_URL()
  const method = teamScreeningRegionUpdate_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionUpdate_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}'
}
export const teamScreeningRegionUpdate_TYPE = function() {
  return 'put'
}
export const teamScreeningRegionUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查地区
 * request: teamScreeningRegionDelete
 * formData: teamScreeningRegionDeleteFormData
 * url: teamScreeningRegionDelete_URL
 * method: teamScreeningRegionDelete_TYPE
 * raw_url: teamScreeningRegionDelete_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const teamScreeningRegionDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionDeleteFormData = function(formData) {
  const url = teamScreeningRegionDelete_RAW_URL()
  const method = teamScreeningRegionDelete_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionDelete_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}'
}
export const teamScreeningRegionDelete_TYPE = function() {
  return 'delete'
}
export const teamScreeningRegionDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成筛查
 * request: teamScreeningRegionFinishScreening
 * formData: teamScreeningRegionFinishScreeningFormData
 * url: teamScreeningRegionFinishScreening_URL
 * method: teamScreeningRegionFinishScreening_TYPE
 * raw_url: teamScreeningRegionFinishScreening_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const teamScreeningRegionFinishScreening = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}/finish-screening'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionFinishScreeningFormData = function(formData) {
  const url = teamScreeningRegionFinishScreening_RAW_URL()
  const method = teamScreeningRegionFinishScreening_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionFinishScreening_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}/finish-screening'
}
export const teamScreeningRegionFinishScreening_TYPE = function() {
  return 'put'
}
export const teamScreeningRegionFinishScreening_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}/finish-screening'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查学校
 * request: teamScreeningRegionAddScreeningSchool
 * formData: teamScreeningRegionAddScreeningSchoolFormData
 * url: teamScreeningRegionAddScreeningSchool_URL
 * method: teamScreeningRegionAddScreeningSchool_TYPE
 * raw_url: teamScreeningRegionAddScreeningSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param schoolId - 学校ID
 * @param schoolType - 学校类型
 * @param code - 编码
 */
export const teamScreeningRegionAddScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}/screening-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters['schoolId'] !== undefined) {
    form['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolId'))
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters['schoolType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: schoolType'))
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionAddScreeningSchoolFormData = function(formData) {
  const url = teamScreeningRegionAddScreeningSchool_RAW_URL()
  const method = teamScreeningRegionAddScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionAddScreeningSchool_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}/screening-school'
}
export const teamScreeningRegionAddScreeningSchool_TYPE = function() {
  return 'post'
}
export const teamScreeningRegionAddScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}/screening-school'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成学校分配
 * request: teamScreeningRegionFinishDistributeSchool
 * formData: teamScreeningRegionFinishDistributeSchoolFormData
 * url: teamScreeningRegionFinishDistributeSchool_URL
 * method: teamScreeningRegionFinishDistributeSchool_TYPE
 * raw_url: teamScreeningRegionFinishDistributeSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const teamScreeningRegionFinishDistributeSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}/screening-school/finish-distribute'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionFinishDistributeSchoolFormData = function(formData) {
  const url = teamScreeningRegionFinishDistributeSchool_RAW_URL()
  const method = teamScreeningRegionFinishDistributeSchool_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionFinishDistributeSchool_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}/screening-school/finish-distribute'
}
export const teamScreeningRegionFinishDistributeSchool_TYPE = function() {
  return 'put'
}
export const teamScreeningRegionFinishDistributeSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}/screening-school/finish-distribute'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查学校
 * request: teamScreeningRegionDeleteScreeningSchool
 * formData: teamScreeningRegionDeleteScreeningSchoolFormData
 * url: teamScreeningRegionDeleteScreeningSchool_URL
 * method: teamScreeningRegionDeleteScreeningSchool_TYPE
 * raw_url: teamScreeningRegionDeleteScreeningSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScreeningRegionDeleteScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningRegionDeleteScreeningSchoolFormData = function(formData) {
  const url = teamScreeningRegionDeleteScreeningSchool_RAW_URL()
  const method = teamScreeningRegionDeleteScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningRegionDeleteScreeningSchool_RAW_URL = function() {
  return '/team/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
}
export const teamScreeningRegionDeleteScreeningSchool_TYPE = function() {
  return 'delete'
}
export const teamScreeningRegionDeleteScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-region/{screeningRegionId}/screening-school/{screeningSchoolId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成学校筛查计划选择列表
 * request: teamScreeningSchoolChoiceListFinish
 * formData: teamScreeningSchoolChoiceListFinishFormData
 * url: teamScreeningSchoolChoiceListFinish_URL
 * method: teamScreeningSchoolChoiceListFinish_TYPE
 * raw_url: teamScreeningSchoolChoiceListFinish_RAW_URL
 */
export const teamScreeningSchoolChoiceListFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/finish-choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolChoiceListFinishFormData = function(formData) {
  const url = teamScreeningSchoolChoiceListFinish_RAW_URL()
  const method = teamScreeningSchoolChoiceListFinish_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolChoiceListFinish_RAW_URL = function() {
  return '/team/screening-school/finish-choice-list'
}
export const teamScreeningSchoolChoiceListFinish_TYPE = function() {
  return 'get'
}
export const teamScreeningSchoolChoiceListFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/finish-choice-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 待处理选择列表
 * request: teamScreeningSchoolChoiceListPending
 * formData: teamScreeningSchoolChoiceListPendingFormData
 * url: teamScreeningSchoolChoiceListPending_URL
 * method: teamScreeningSchoolChoiceListPending_TYPE
 * raw_url: teamScreeningSchoolChoiceListPending_RAW_URL
 */
export const teamScreeningSchoolChoiceListPending = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/pending-choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolChoiceListPendingFormData = function(formData) {
  const url = teamScreeningSchoolChoiceListPending_RAW_URL()
  const method = teamScreeningSchoolChoiceListPending_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolChoiceListPending_RAW_URL = function() {
  return '/team/screening-school/pending-choice-list'
}
export const teamScreeningSchoolChoiceListPending_TYPE = function() {
  return 'get'
}
export const teamScreeningSchoolChoiceListPending_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/pending-choice-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 生成质控记录
 * request: teamScreeningSchoolSaveQualityControlRecord
 * formData: teamScreeningSchoolSaveQualityControlRecordFormData
 * url: teamScreeningSchoolSaveQualityControlRecord_URL
 * method: teamScreeningSchoolSaveQualityControlRecord_TYPE
 * raw_url: teamScreeningSchoolSaveQualityControlRecord_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param qcDate - 质控日期
 */
export const teamScreeningSchoolSaveQualityControlRecord = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/quality-control-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['qcDate'] !== undefined) {
    form['qcDate'] = parameters['qcDate']
  }
  if (parameters['qcDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: qcDate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolSaveQualityControlRecordFormData = function(formData) {
  const url = teamScreeningSchoolSaveQualityControlRecord_RAW_URL()
  const method = teamScreeningSchoolSaveQualityControlRecord_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolSaveQualityControlRecord_RAW_URL = function() {
  return '/team/screening-school/quality-control-record'
}
export const teamScreeningSchoolSaveQualityControlRecord_TYPE = function() {
  return 'post'
}
export const teamScreeningSchoolSaveQualityControlRecord_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/quality-control-record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 机构筛查详情
 * request: teamScreeningSchoolStatisticasStudent
 * formData: teamScreeningSchoolStatisticasStudentFormData
 * url: teamScreeningSchoolStatisticasStudent_URL
 * method: teamScreeningSchoolStatisticasStudent_TYPE
 * raw_url: teamScreeningSchoolStatisticasStudent_RAW_URL
 */
export const teamScreeningSchoolStatisticasStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/statisticas'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolStatisticasStudentFormData = function(formData) {
  const url = teamScreeningSchoolStatisticasStudent_RAW_URL()
  const method = teamScreeningSchoolStatisticasStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolStatisticasStudent_RAW_URL = function() {
  return '/team/screening-school/statisticas'
}
export const teamScreeningSchoolStatisticasStudent_TYPE = function() {
  return 'get'
}
export const teamScreeningSchoolStatisticasStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/statisticas'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查学生
 * request: teamScreeningSchoolUpdateScreeningStudent
 * formData: teamScreeningSchoolUpdateScreeningStudentFormData
 * url: teamScreeningSchoolUpdateScreeningStudent_URL
 * method: teamScreeningSchoolUpdateScreeningStudent_TYPE
 * raw_url: teamScreeningSchoolUpdateScreeningStudent_RAW_URL
 * @param screeningStudentId - screeningStudentId
 * @param screeningSchoolId - 筛查学校ID
 * @param screeningClassId - 筛查班级ID
 * @param name - 姓名
 * @param sex - 性别
 * @param birth - 出生日期
 * @param idCard - 身份证号
 * @param code - 编码
 * @param nation - 民族
 * @param mobile - 手机号
 * @param hepatitis - 
 * @param nephritis - 
 * @param chd - 
 * @param hypertension - 
 * @param anemia - 
 * @param diabetes - 
 * @param allergicasthma - 
 * @param physicaldisability - 
 * @param geneticsHistory - 
 * @param diseaseHistoryCoronavirus - 
 */
export const teamScreeningSchoolUpdateScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/student/{screeningStudentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    form['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['screeningClassId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningClassId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['code'] !== undefined) {
    form['code'] = parameters['code']
  }
  if (parameters['code'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: code'))
  }
  if (parameters['nation'] !== undefined) {
    form['nation'] = parameters['nation']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['hepatitis'] !== undefined) {
    form['hepatitis'] = parameters['hepatitis']
  }
  if (parameters['nephritis'] !== undefined) {
    form['nephritis'] = parameters['nephritis']
  }
  if (parameters['chd'] !== undefined) {
    form['chd'] = parameters['chd']
  }
  if (parameters['hypertension'] !== undefined) {
    form['hypertension'] = parameters['hypertension']
  }
  if (parameters['anemia'] !== undefined) {
    form['anemia'] = parameters['anemia']
  }
  if (parameters['diabetes'] !== undefined) {
    form['diabetes'] = parameters['diabetes']
  }
  if (parameters['allergicasthma'] !== undefined) {
    form['allergicasthma'] = parameters['allergicasthma']
  }
  if (parameters['physicaldisability'] !== undefined) {
    form['physicaldisability'] = parameters['physicaldisability']
  }
  if (parameters['geneticsHistory'] !== undefined) {
    form['geneticsHistory'] = parameters['geneticsHistory']
  }
  if (parameters['diseaseHistoryCoronavirus'] !== undefined) {
    form['diseaseHistoryCoronavirus'] = parameters['diseaseHistoryCoronavirus']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolUpdateScreeningStudentFormData = function(formData) {
  const url = teamScreeningSchoolUpdateScreeningStudent_RAW_URL()
  const method = teamScreeningSchoolUpdateScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolUpdateScreeningStudent_RAW_URL = function() {
  return '/team/screening-school/student/{screeningStudentId}'
}
export const teamScreeningSchoolUpdateScreeningStudent_TYPE = function() {
  return 'put'
}
export const teamScreeningSchoolUpdateScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/student/{screeningStudentId}'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成筛查学校
 * request: teamScreeningSchoolFinishScreening
 * formData: teamScreeningSchoolFinishScreeningFormData
 * url: teamScreeningSchoolFinishScreening_URL
 * method: teamScreeningSchoolFinishScreening_TYPE
 * raw_url: teamScreeningSchoolFinishScreening_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScreeningSchoolFinishScreening = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolFinishScreeningFormData = function(formData) {
  const url = teamScreeningSchoolFinishScreening_RAW_URL()
  const method = teamScreeningSchoolFinishScreening_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolFinishScreening_RAW_URL = function() {
  return '/team/screening-school/{screeningSchoolId}'
}
export const teamScreeningSchoolFinishScreening_TYPE = function() {
  return 'put'
}
export const teamScreeningSchoolFinishScreening_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 批量删除学生
 * request: teamScreeningSchoolBatchDeleteScreeningStudent
 * formData: teamScreeningSchoolBatchDeleteScreeningStudentFormData
 * url: teamScreeningSchoolBatchDeleteScreeningStudent_URL
 * method: teamScreeningSchoolBatchDeleteScreeningStudent_TYPE
 * raw_url: teamScreeningSchoolBatchDeleteScreeningStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningStudentIds - 
 * @param checked - 
 */
export const teamScreeningSchoolBatchDeleteScreeningStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-school/{screeningSchoolId}/batch-screening-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningSchoolBatchDeleteScreeningStudentFormData = function(formData) {
  const url = teamScreeningSchoolBatchDeleteScreeningStudent_RAW_URL()
  const method = teamScreeningSchoolBatchDeleteScreeningStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningSchoolBatchDeleteScreeningStudent_RAW_URL = function() {
  return '/team/screening-school/{screeningSchoolId}/batch-screening-student'
}
export const teamScreeningSchoolBatchDeleteScreeningStudent_TYPE = function() {
  return 'delete'
}
export const teamScreeningSchoolBatchDeleteScreeningStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-school/{screeningSchoolId}/batch-screening-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningStudentIds'] !== undefined) {
    queryParameters['screeningStudentIds'] = parameters['screeningStudentIds']
  }
  if (parameters['checked'] !== undefined) {
    queryParameters['checked'] = parameters['checked']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 检查学校任务状态
 * request: teamScreeningTaskCheckSchoolTaskStatus
 * formData: teamScreeningTaskCheckSchoolTaskStatusFormData
 * url: teamScreeningTaskCheckSchoolTaskStatus_URL
 * method: teamScreeningTaskCheckSchoolTaskStatus_TYPE
 * raw_url: teamScreeningTaskCheckSchoolTaskStatus_RAW_URL
 * @param screeningSchoolId - 
 */
export const teamScreeningTaskCheckSchoolTaskStatus = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-task/check-task-status'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningTaskCheckSchoolTaskStatusFormData = function(formData) {
  const url = teamScreeningTaskCheckSchoolTaskStatus_RAW_URL()
  const method = teamScreeningTaskCheckSchoolTaskStatus_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningTaskCheckSchoolTaskStatus_RAW_URL = function() {
  return '/team/screening-task/check-task-status'
}
export const teamScreeningTaskCheckSchoolTaskStatus_TYPE = function() {
  return 'get'
}
export const teamScreeningTaskCheckSchoolTaskStatus_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-task/check-task-status'
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成筛查
 * request: teamScreeningTaskFinishScreening
 * formData: teamScreeningTaskFinishScreeningFormData
 * url: teamScreeningTaskFinishScreening_URL
 * method: teamScreeningTaskFinishScreening_TYPE
 * raw_url: teamScreeningTaskFinishScreening_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const teamScreeningTaskFinishScreening = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/screening-task/{screeningTaskId}/finish-screening'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningTaskFinishScreeningFormData = function(formData) {
  const url = teamScreeningTaskFinishScreening_RAW_URL()
  const method = teamScreeningTaskFinishScreening_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningTaskFinishScreening_RAW_URL = function() {
  return '/team/screening-task/{screeningTaskId}/finish-screening'
}
export const teamScreeningTaskFinishScreening_TYPE = function() {
  return 'put'
}
export const teamScreeningTaskFinishScreening_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/screening-task/{screeningTaskId}/finish-screening'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取学生视力不良及影响因素专项调查表
 * request: teamSpvisqFindByScreeningSchool
 * formData: teamSpvisqFindByScreeningSchoolFormData
 * url: teamSpvisqFindByScreeningSchool_URL
 * method: teamSpvisqFindByScreeningSchool_TYPE
 * raw_url: teamSpvisqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamSpvisqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/spvisq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamSpvisqFindByScreeningSchoolFormData = function(formData) {
  const url = teamSpvisqFindByScreeningSchool_RAW_URL()
  const method = teamSpvisqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamSpvisqFindByScreeningSchool_RAW_URL = function() {
  return '/team/spvisq/by-screeningSchool/{screeningSchoolId}'
}
export const teamSpvisqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamSpvisqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/spvisq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生视力不良及影响因素专项调查表
 * request: teamSpvisqFindByStudent
 * formData: teamSpvisqFindByStudentFormData
 * url: teamSpvisqFindByStudent_URL
 * method: teamSpvisqFindByStudent_TYPE
 * raw_url: teamSpvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamSpvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/spvisq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamSpvisqFindByStudentFormData = function(formData) {
  const url = teamSpvisqFindByStudent_RAW_URL()
  const method = teamSpvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamSpvisqFindByStudent_RAW_URL = function() {
  return '/team/spvisq/by-student/{studentId}'
}
export const teamSpvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamSpvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/spvisq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查表
 * request: teamSpvisqSave
 * formData: teamSpvisqSaveFormData
 * url: teamSpvisqSave_URL
 * method: teamSpvisqSave_TYPE
 * raw_url: teamSpvisqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param e04 - 在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间？
 * @param e04Time - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的平均分钟数
 * @param e04NonLearningTime - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间非学习目的使用分钟数
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const teamSpvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/spvisq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e04Time'] !== undefined) {
    form['e04Time'] = parameters['e04Time']
  }
  if (parameters['e04NonLearningTime'] !== undefined) {
    form['e04NonLearningTime'] = parameters['e04NonLearningTime']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamSpvisqSaveFormData = function(formData) {
  const url = teamSpvisqSave_RAW_URL()
  const method = teamSpvisqSave_TYPE()
  return axios[method](url, formData)
}
export const teamSpvisqSave_RAW_URL = function() {
  return '/team/spvisq/save/{studentId}'
}
export const teamSpvisqSave_TYPE = function() {
  return 'post'
}
export const teamSpvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/spvisq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校id获取学生新冠肺炎专项调查问卷
 * request: teamScsqByScreeningSchool
 * formData: teamScsqByScreeningSchoolFormData
 * url: teamScsqByScreeningSchool_URL
 * method: teamScsqByScreeningSchool_TYPE
 * raw_url: teamScsqByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamScsqByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-covid19-special-questionary/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScsqByScreeningSchoolFormData = function(formData) {
  const url = teamScsqByScreeningSchool_RAW_URL()
  const method = teamScsqByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamScsqByScreeningSchool_RAW_URL = function() {
  return '/team/student-covid19-special-questionary/by-screeningSchool/{screeningSchoolId}'
}
export const teamScsqByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamScsqByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-covid19-special-questionary/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生新冠肺炎专项调查表
 * request: teamScsqFindByStudent
 * formData: teamScsqFindByStudentFormData
 * url: teamScsqFindByStudent_URL
 * method: teamScsqFindByStudent_TYPE
 * raw_url: teamScsqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamScsqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-covid19-special-questionary/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScsqFindByStudentFormData = function(formData) {
  const url = teamScsqFindByStudent_RAW_URL()
  const method = teamScsqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamScsqFindByStudent_RAW_URL = function() {
  return '/team/student-covid19-special-questionary/by-student/{studentId}'
}
export const teamScsqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamScsqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-covid19-special-questionary/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生新冠肺炎专项调查表
 * request: teamScsqSave
 * formData: teamScsqSaveFormData
 * url: teamScsqSave_URL
 * method: teamScsqSave_TYPE
 * raw_url: teamScsqSave_RAW_URL
 * @param studentId - studentId
 * @param a01 - 
 * @param a02 - 
 * @param a03 - 
 * @param a04 - 
 * @param a05 - 
 * @param a06 - 
 * @param a07 - 
 * @param a071Day - 
 * @param a072Day - 
 * @param a08 - 
 * @param a08Day - 
 * @param a09 - 
 * @param b01 - 
 * @param b01StartDate - 
 * @param b01EndDate - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b05 - 
 * @param b05OtherDescription - 
 * @param b06 - 
 * @param b0601 - 
 * @param b0602 - 
 * @param b0603 - 
 * @param b0604 - 
 * @param b0605 - 
 * @param b07 - 
 * @param b08 - 
 * @param b09 - 
 * @param b10 - 
 * @param b11 - 
 * @param b12 - 
 * @param b13 - 
 * @param b14 - 
 * @param b15 - 
 * @param b16 - 
 * @param b17 - 
 * @param b18 - 
 * @param b19 - 
 * @param b20 - 
 * @param b201 - 
 * @param b21 - 
 * @param b21OtherDescription - 
 * @param b22 - 
 * @param b23 - 
 * @param b24 - 
 * @param b25 - 
 * @param b26 - 
 * @param b27 - 
 * @param b28 - 
 * @param b28OtherDescription - 
 * @param b29 - 
 * @param b30 - 
 * @param b31 - 
 * @param b32 - 
 * @param b32OtherDescription - 
 * @param b33 - 
 */
export const teamScsqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-covid19-special-questionary/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['a01'] !== undefined) {
    form['a01'] = parameters['a01']
  }
  if (parameters['a01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a01'))
  }
  if (parameters['a02'] !== undefined) {
    form['a02'] = parameters['a02']
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a071Day'] !== undefined) {
    form['a071Day'] = parameters['a071Day']
  }
  if (parameters['a072Day'] !== undefined) {
    form['a072Day'] = parameters['a072Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Day'] !== undefined) {
    form['a08Day'] = parameters['a08Day']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b01StartDate'] !== undefined) {
    form['b01StartDate'] = parameters['b01StartDate']
  }
  if (parameters['b01EndDate'] !== undefined) {
    form['b01EndDate'] = parameters['b01EndDate']
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b05OtherDescription'] !== undefined) {
    form['b05OtherDescription'] = parameters['b05OtherDescription']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b0601'] !== undefined) {
    form['b0601'] = parameters['b0601']
  }
  if (parameters['b0601'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0601'))
  }
  if (parameters['b0602'] !== undefined) {
    form['b0602'] = parameters['b0602']
  }
  if (parameters['b0602'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0602'))
  }
  if (parameters['b0603'] !== undefined) {
    form['b0603'] = parameters['b0603']
  }
  if (parameters['b0603'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0603'))
  }
  if (parameters['b0604'] !== undefined) {
    form['b0604'] = parameters['b0604']
  }
  if (parameters['b0604'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0604'))
  }
  if (parameters['b0605'] !== undefined) {
    form['b0605'] = parameters['b0605']
  }
  if (parameters['b0605'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b0605'))
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['b11'] !== undefined) {
    form['b11'] = parameters['b11']
  }
  if (parameters['b11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b11'))
  }
  if (parameters['b12'] !== undefined) {
    form['b12'] = parameters['b12']
  }
  if (parameters['b12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b12'))
  }
  if (parameters['b13'] !== undefined) {
    form['b13'] = parameters['b13']
  }
  if (parameters['b13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b13'))
  }
  if (parameters['b14'] !== undefined) {
    form['b14'] = parameters['b14']
  }
  if (parameters['b14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b14'))
  }
  if (parameters['b15'] !== undefined) {
    form['b15'] = parameters['b15']
  }
  if (parameters['b15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b15'))
  }
  if (parameters['b16'] !== undefined) {
    form['b16'] = parameters['b16']
  }
  if (parameters['b16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b16'))
  }
  if (parameters['b17'] !== undefined) {
    form['b17'] = parameters['b17']
  }
  if (parameters['b17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b17'))
  }
  if (parameters['b18'] !== undefined) {
    form['b18'] = parameters['b18']
  }
  if (parameters['b18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b18'))
  }
  if (parameters['b19'] !== undefined) {
    form['b19'] = parameters['b19']
  }
  if (parameters['b19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b19'))
  }
  if (parameters['b20'] !== undefined) {
    form['b20'] = parameters['b20']
  }
  if (parameters['b20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b20'))
  }
  if (parameters['b201'] !== undefined) {
    form['b201'] = parameters['b201']
  }
  if (parameters['b21'] !== undefined) {
    form['b21'] = parameters['b21']
  }
  if (parameters['b21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b21'))
  }
  if (parameters['b21OtherDescription'] !== undefined) {
    form['b21OtherDescription'] = parameters['b21OtherDescription']
  }
  if (parameters['b22'] !== undefined) {
    form['b22'] = parameters['b22']
  }
  if (parameters['b22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b22'))
  }
  if (parameters['b23'] !== undefined) {
    form['b23'] = parameters['b23']
  }
  if (parameters['b23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b23'))
  }
  if (parameters['b24'] !== undefined) {
    form['b24'] = parameters['b24']
  }
  if (parameters['b24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b24'))
  }
  if (parameters['b25'] !== undefined) {
    form['b25'] = parameters['b25']
  }
  if (parameters['b25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b25'))
  }
  if (parameters['b26'] !== undefined) {
    form['b26'] = parameters['b26']
  }
  if (parameters['b26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b26'))
  }
  if (parameters['b27'] !== undefined) {
    form['b27'] = parameters['b27']
  }
  if (parameters['b27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b27'))
  }
  if (parameters['b28'] !== undefined) {
    form['b28'] = parameters['b28']
  }
  if (parameters['b28OtherDescription'] !== undefined) {
    form['b28OtherDescription'] = parameters['b28OtherDescription']
  }
  if (parameters['b29'] !== undefined) {
    form['b29'] = parameters['b29']
  }
  if (parameters['b29'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b29'))
  }
  if (parameters['b30'] !== undefined) {
    form['b30'] = parameters['b30']
  }
  if (parameters['b30'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b30'))
  }
  if (parameters['b31'] !== undefined) {
    form['b31'] = parameters['b31']
  }
  if (parameters['b31'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b31'))
  }
  if (parameters['b32'] !== undefined) {
    form['b32'] = parameters['b32']
  }
  if (parameters['b32'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b32'))
  }
  if (parameters['b32OtherDescription'] !== undefined) {
    form['b32OtherDescription'] = parameters['b32OtherDescription']
  }
  if (parameters['b33'] !== undefined) {
    form['b33'] = parameters['b33']
  }
  if (parameters['b33'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b33'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScsqSaveFormData = function(formData) {
  const url = teamScsqSave_RAW_URL()
  const method = teamScsqSave_TYPE()
  return axios[method](url, formData)
}
export const teamScsqSave_RAW_URL = function() {
  return '/team/student-covid19-special-questionary/save/{studentId}'
}
export const teamScsqSave_TYPE = function() {
  return 'post'
}
export const teamScsqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-covid19-special-questionary/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校id获取中小学生视力相关行为调查问卷
 * request: teamStudentVisionBehaviorByScreeningSchool
 * formData: teamStudentVisionBehaviorByScreeningSchoolFormData
 * url: teamStudentVisionBehaviorByScreeningSchool_URL
 * method: teamStudentVisionBehaviorByScreeningSchool_TYPE
 * raw_url: teamStudentVisionBehaviorByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamStudentVisionBehaviorByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamStudentVisionBehaviorByScreeningSchoolFormData = function(formData) {
  const url = teamStudentVisionBehaviorByScreeningSchool_RAW_URL()
  const method = teamStudentVisionBehaviorByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamStudentVisionBehaviorByScreeningSchool_RAW_URL = function() {
  return '/team/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
}
export const teamStudentVisionBehaviorByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamStudentVisionBehaviorByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-vision-behavior/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生id获取中小学生视力相关行为调查问卷
 * request: teamStudentVisionBehavior
 * formData: teamStudentVisionBehaviorFormData
 * url: teamStudentVisionBehavior_URL
 * method: teamStudentVisionBehavior_TYPE
 * raw_url: teamStudentVisionBehavior_RAW_URL
 * @param studentId - studentId
 */
export const teamStudentVisionBehavior = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-vision-behavior/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamStudentVisionBehaviorFormData = function(formData) {
  const url = teamStudentVisionBehavior_RAW_URL()
  const method = teamStudentVisionBehavior_TYPE()
  return axios[method](url, formData)
}
export const teamStudentVisionBehavior_RAW_URL = function() {
  return '/team/student-vision-behavior/by-student/{studentId}'
}
export const teamStudentVisionBehavior_TYPE = function() {
  return 'get'
}
export const teamStudentVisionBehavior_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-vision-behavior/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中小学生视力相关行为调查问卷
 * request: teamStudentVisionBehaviorSave
 * formData: teamStudentVisionBehaviorSaveFormData
 * url: teamStudentVisionBehaviorSave_URL
 * method: teamStudentVisionBehaviorSave_TYPE
 * raw_url: teamStudentVisionBehaviorSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a05 - 
 * @param a06 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041Left - b04选是必填
 * @param b041Right - b04选是必填
 * @param b05 - 
 * @param b06 - 
 * @param b061Left - b06选是必填
 * @param b061Right - b06选是必填
 * @param c01 - 
 * @param c02 - 
 * @param c02Other - c02选其他必填
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c06 - 
 * @param c07 - 
 * @param c08 - 
 * @param c09 - 
 * @param c10 - 
 * @param c11 - 
 * @param c12 - 
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d03Other - d03选其他必填
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e01True - e01选是必填
 * @param e02 - e01选是必填
 * @param e02True - e02选是必填
 * @param e03 - 
 * @param e03True - e03选是必填
 * @param e04 - e03选是必填
 * @param e04True - e04选是必填
 * @param e05 - 
 * @param e05True - e05选是必填
 * @param e06 - e05选是必填
 * @param e06True - e06选是必填
 * @param e07 - 
 * @param e07True - e07选是必填
 * @param e08 - 
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f04True - f04选是必填
 * @param f05 - 
 * @param f06 - 
 */
export const teamStudentVisionBehaviorSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/student-vision-behavior/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a06'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041Left'] !== undefined) {
    form['b041Left'] = parameters['b041Left']
  }
  if (parameters['b041Right'] !== undefined) {
    form['b041Right'] = parameters['b041Right']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061Left'] !== undefined) {
    form['b061Left'] = parameters['b061Left']
  }
  if (parameters['b061Right'] !== undefined) {
    form['b061Right'] = parameters['b061Right']
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c02Other'] !== undefined) {
    form['c02Other'] = parameters['c02Other']
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c09'] !== undefined) {
    form['c09'] = parameters['c09']
  }
  if (parameters['c09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c09'))
  }
  if (parameters['c10'] !== undefined) {
    form['c10'] = parameters['c10']
  }
  if (parameters['c10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c10'))
  }
  if (parameters['c11'] !== undefined) {
    form['c11'] = parameters['c11']
  }
  if (parameters['c11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c11'))
  }
  if (parameters['c12'] !== undefined) {
    form['c12'] = parameters['c12']
  }
  if (parameters['c12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c12'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d03Other'] !== undefined) {
    form['d03Other'] = parameters['d03Other']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01True'] !== undefined) {
    form['e01True'] = parameters['e01True']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02True'] !== undefined) {
    form['e02True'] = parameters['e02True']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03True'] !== undefined) {
    form['e03True'] = parameters['e03True']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04True'] !== undefined) {
    form['e04True'] = parameters['e04True']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['e05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e05'))
  }
  if (parameters['e05True'] !== undefined) {
    form['e05True'] = parameters['e05True']
  }
  if (parameters['e06'] !== undefined) {
    form['e06'] = parameters['e06']
  }
  if (parameters['e06True'] !== undefined) {
    form['e06True'] = parameters['e06True']
  }
  if (parameters['e07'] !== undefined) {
    form['e07'] = parameters['e07']
  }
  if (parameters['e07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e07'))
  }
  if (parameters['e07True'] !== undefined) {
    form['e07True'] = parameters['e07True']
  }
  if (parameters['e08'] !== undefined) {
    form['e08'] = parameters['e08']
  }
  if (parameters['e08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e08'))
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f04True'] !== undefined) {
    form['f04True'] = parameters['f04True']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamStudentVisionBehaviorSaveFormData = function(formData) {
  const url = teamStudentVisionBehaviorSave_RAW_URL()
  const method = teamStudentVisionBehaviorSave_TYPE()
  return axios[method](url, formData)
}
export const teamStudentVisionBehaviorSave_RAW_URL = function() {
  return '/team/student-vision-behavior/save/{studentId}'
}
export const teamStudentVisionBehaviorSave_TYPE = function() {
  return 'post'
}
export const teamStudentVisionBehaviorSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/student-vision-behavior/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存任务指标
 * request: teamTaskIndicatorSave
 * formData: teamTaskIndicatorSaveFormData
 * url: teamTaskIndicatorSave_URL
 * method: teamTaskIndicatorSave_TYPE
 * raw_url: teamTaskIndicatorSave_RAW_URL
 * @param vo - vo
 */
export const teamTaskIndicatorSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/task-indicator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamTaskIndicatorSaveFormData = function(formData) {
  const url = teamTaskIndicatorSave_RAW_URL()
  const method = teamTaskIndicatorSave_TYPE()
  return axios[method](url, formData)
}
export const teamTaskIndicatorSave_RAW_URL = function() {
  return '/team/task-indicator'
}
export const teamTaskIndicatorSave_TYPE = function() {
  return 'post'
}
export const teamTaskIndicatorSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/task-indicator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看任务指标
 * request: teamTaskIndicatorFind
 * formData: teamTaskIndicatorFindFormData
 * url: teamTaskIndicatorFind_URL
 * method: teamTaskIndicatorFind_TYPE
 * raw_url: teamTaskIndicatorFind_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamTaskIndicatorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/task-indicator/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamTaskIndicatorFindFormData = function(formData) {
  const url = teamTaskIndicatorFind_RAW_URL()
  const method = teamTaskIndicatorFind_TYPE()
  return axios[method](url, formData)
}
export const teamTaskIndicatorFind_RAW_URL = function() {
  return '/team/task-indicator/{screeningSchoolId}'
}
export const teamTaskIndicatorFind_TYPE = function() {
  return 'get'
}
export const teamTaskIndicatorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/task-indicator/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: teamTaskIndicatorDubiousStudent
 * formData: teamTaskIndicatorDubiousStudentFormData
 * url: teamTaskIndicatorDubiousStudent_URL
 * method: teamTaskIndicatorDubiousStudent_TYPE
 * raw_url: teamTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const teamTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/task-indicator/{screeningSchoolId}/dubious-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = teamTaskIndicatorDubiousStudent_RAW_URL()
  const method = teamTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const teamTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/team/task-indicator/{screeningSchoolId}/dubious-student'
}
export const teamTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const teamTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/task-indicator/{screeningSchoolId}/dubious-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取大学学生健康状况及影响因素调查表
 * request: teamUshiqFindByScreeningSchool
 * formData: teamUshiqFindByScreeningSchoolFormData
 * url: teamUshiqFindByScreeningSchool_URL
 * method: teamUshiqFindByScreeningSchool_TYPE
 * raw_url: teamUshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const teamUshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/ushiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamUshiqFindByScreeningSchoolFormData = function(formData) {
  const url = teamUshiqFindByScreeningSchool_RAW_URL()
  const method = teamUshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const teamUshiqFindByScreeningSchool_RAW_URL = function() {
  return '/team/ushiq/by-screeningSchool/{screeningSchoolId}'
}
export const teamUshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const teamUshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/ushiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看大学学生健康状况及影响因素调查表
 * request: teamUshiqFindByStudent
 * formData: teamUshiqFindByStudentFormData
 * url: teamUshiqFindByStudent_URL
 * method: teamUshiqFindByStudent_TYPE
 * raw_url: teamUshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const teamUshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/ushiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamUshiqFindByStudentFormData = function(formData) {
  const url = teamUshiqFindByStudent_RAW_URL()
  const method = teamUshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const teamUshiqFindByStudent_RAW_URL = function() {
  return '/team/ushiq/by-student/{studentId}'
}
export const teamUshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const teamUshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/ushiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查表
 * request: teamUshiqSave
 * formData: teamUshiqSaveFormData
 * url: teamUshiqSave_URL
 * method: teamUshiqSave_TYPE
 * raw_url: teamUshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c061 - 
 * @param c062 - 
 * @param c063 - 
 * @param c064 - 
 * @param c07 - 
 * @param c08 - 
 * @param c121 - 在公共网页上受到他人的辱骂？
 * @param c122 - 曾收到侮辱性的信息？
 * @param c123 - 有人将令我尴尬的照片或视频上传到网络上？
 * @param c124 - 某人用假的身份给我发送令我难堪的信息？
 * @param c125 - 有人在网络上散布我的谣言(如社交网站等)？
 * @param c126 - 曾有人未经我的允许转发或公开我的私人信息？
 * @param c127 - 曾经受到威胁性或恐吓性的信息？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 当D05的值为1时必填，反之不填
 * @param d07 - 当D05的值为1时必填，反之不填
 * @param d08 - 当D05的值为1时必填，反之不填
 * @param d09 - 
 * @param d09Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d10 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d11 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d111 - 每次服用止咳药水的量是多少？
 * @param d12 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f03Time - 当F03的值为2时必填，反之不填
 * @param f04 - 
 * @param f05 - 
 * @param f06 - 
 * @param f06Time - 当F06的值为2时必填，反之不填
 * @param g01 - 
 * @param h01 - 
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填
 * @param h02 - 当H01的值为2时必填，反之不填，你平均每天使用耳机分钟数 --> 时间选项
 * @param h03 - 当H01的值为2时必填，反之不填
 * @param h04 - 当H01的值为2时必填，反之不填
 * @param h05 - 当H01的值为2时必填，反之不填
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i04 - 当I03的值不为1时必填，反之不填
 * @param i05 - 当I03的值不为1时必填，反之不填
 * @param i06 - 当I03的值不为1时必填，反之不填
 * @param i07 - 当I03的值不为1时必填，反之不填
 * @param i08 - 当I03的值不为1时必填，反之不填
 * @param i09 - 当I03的值不为1时必填，反之不填
 * @param j01 - 
 * @param j02 - 
 * @param j03 - 
 * @param j04 - 
 * @param j05 - 
 * @param j06 - 
 * @param j07 - 
 * @param j08 - 
 * @param j09 - 
 * @param j10 - 
 * @param j11 - 
 * @param j12 - 
 * @param j13 - 
 * @param j14 - 
 * @param j15 - 
 * @param j16 - 
 * @param j17 - 
 * @param j18 - 
 * @param j19 - 
 * @param j20 - 
 * @param j21 - 感觉紧张，焦虑或急切
 * @param j22 - 不能够停止或控制担忧
 * @param j23 - 对各种恶样的事情担忧过多
 * @param j24 - 很难放松下来
 * @param j25 - 由于不安而无法静坐
 * @param j26 - 变得容易烦恼或急躁
 * @param j27 - 感到似乎将有可怕的事情发生而害怕
 * @param j28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param j281 - 你寻求了什么形式的帮助
 * @param j282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param l01 - 
 * @param l02 - 
 * @param l03 - 
 * @param l03Week - 
 * @param l04 - 
 * @param l05 - 
 * @param l06 - 
 * @param l07 - 
 * @param l08 - 
 */
export const teamUshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/ushiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c061'] !== undefined) {
    form['c061'] = parameters['c061']
  }
  if (parameters['c061'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c061'))
  }
  if (parameters['c062'] !== undefined) {
    form['c062'] = parameters['c062']
  }
  if (parameters['c062'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c062'))
  }
  if (parameters['c063'] !== undefined) {
    form['c063'] = parameters['c063']
  }
  if (parameters['c063'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c063'))
  }
  if (parameters['c064'] !== undefined) {
    form['c064'] = parameters['c064']
  }
  if (parameters['c064'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c064'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c121'))
  }
  if (parameters['c122'] !== undefined) {
    form['c122'] = parameters['c122']
  }
  if (parameters['c122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c122'))
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c123'))
  }
  if (parameters['c124'] !== undefined) {
    form['c124'] = parameters['c124']
  }
  if (parameters['c124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c124'))
  }
  if (parameters['c125'] !== undefined) {
    form['c125'] = parameters['c125']
  }
  if (parameters['c125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c125'))
  }
  if (parameters['c126'] !== undefined) {
    form['c126'] = parameters['c126']
  }
  if (parameters['c126'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c126'))
  }
  if (parameters['c127'] !== undefined) {
    form['c127'] = parameters['c127']
  }
  if (parameters['c127'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c127'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['d09Description'] !== undefined) {
    form['d09Description'] = parameters['d09Description']
  }
  if (parameters['d10'] !== undefined) {
    form['d10'] = parameters['d10']
  }
  if (parameters['d10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d10'))
  }
  if (parameters['d11'] !== undefined) {
    form['d11'] = parameters['d11']
  }
  if (parameters['d11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d11'))
  }
  if (parameters['d111'] !== undefined) {
    form['d111'] = parameters['d111']
  }
  if (parameters['d12'] !== undefined) {
    form['d12'] = parameters['d12']
  }
  if (parameters['d12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d12'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f06Time'] !== undefined) {
    form['f06Time'] = parameters['f06Time']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['j21'] !== undefined) {
    form['j21'] = parameters['j21']
  }
  if (parameters['j21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j21'))
  }
  if (parameters['j22'] !== undefined) {
    form['j22'] = parameters['j22']
  }
  if (parameters['j22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j22'))
  }
  if (parameters['j23'] !== undefined) {
    form['j23'] = parameters['j23']
  }
  if (parameters['j23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j23'))
  }
  if (parameters['j24'] !== undefined) {
    form['j24'] = parameters['j24']
  }
  if (parameters['j24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j24'))
  }
  if (parameters['j25'] !== undefined) {
    form['j25'] = parameters['j25']
  }
  if (parameters['j25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j25'))
  }
  if (parameters['j26'] !== undefined) {
    form['j26'] = parameters['j26']
  }
  if (parameters['j26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j26'))
  }
  if (parameters['j27'] !== undefined) {
    form['j27'] = parameters['j27']
  }
  if (parameters['j27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j27'))
  }
  if (parameters['j28'] !== undefined) {
    form['j28'] = parameters['j28']
  }
  if (parameters['j28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j28'))
  }
  if (parameters['j281'] !== undefined) {
    form['j281'] = parameters['j281']
  }
  if (parameters['j282'] !== undefined) {
    form['j282'] = parameters['j282']
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamUshiqSaveFormData = function(formData) {
  const url = teamUshiqSave_RAW_URL()
  const method = teamUshiqSave_TYPE()
  return axios[method](url, formData)
}
export const teamUshiqSave_RAW_URL = function() {
  return '/team/ushiq/save/{studentId}'
}
export const teamUshiqSave_TYPE = function() {
  return 'post'
}
export const teamUshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/ushiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员列表
 * request: teamScreeningWorkerList
 * formData: teamScreeningWorkerListFormData
 * url: teamScreeningWorkerList_URL
 * method: teamScreeningWorkerList_TYPE
 * raw_url: teamScreeningWorkerList_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param name - 姓名
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const teamScreeningWorkerList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerListFormData = function(formData) {
  const url = teamScreeningWorkerList_RAW_URL()
  const method = teamScreeningWorkerList_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerList_RAW_URL = function() {
  return '/team/worker'
}
export const teamScreeningWorkerList_TYPE = function() {
  return 'get'
}
export const teamScreeningWorkerList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加筛查工作人员
 * request: teamScreeningWorkerAdd
 * formData: teamScreeningWorkerAddFormData
 * url: teamScreeningWorkerAdd_URL
 * method: teamScreeningWorkerAdd_TYPE
 * raw_url: teamScreeningWorkerAdd_RAW_URL
 * @param screeningPlanId - 筛查计划ID
 * @param name - 姓名
 * @param organization - 所属单位
 * @param mobile - 手机号码
 * @param files - 资格图片
 */
export const teamScreeningWorkerAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    form['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['organization'] !== undefined) {
    form['organization'] = parameters['organization']
  }
  if (parameters['organization'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organization'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerAddFormData = function(formData) {
  const url = teamScreeningWorkerAdd_RAW_URL()
  const method = teamScreeningWorkerAdd_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerAdd_RAW_URL = function() {
  return '/team/worker'
}
export const teamScreeningWorkerAdd_TYPE = function() {
  return 'post'
}
export const teamScreeningWorkerAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取筛查工作人员注册码
 * request: teamScreeningWorkerGenCode
 * formData: teamScreeningWorkerGenCodeFormData
 * url: teamScreeningWorkerGenCode_URL
 * method: teamScreeningWorkerGenCode_TYPE
 * raw_url: teamScreeningWorkerGenCode_RAW_URL
 * @param planId - planId
 * @param days - days
 */
export const teamScreeningWorkerGenCode = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/code'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters['days'] !== undefined) {
    queryParameters['days'] = parameters['days']
  }
  if (parameters['days'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: days'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerGenCodeFormData = function(formData) {
  const url = teamScreeningWorkerGenCode_RAW_URL()
  const method = teamScreeningWorkerGenCode_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerGenCode_RAW_URL = function() {
  return '/team/worker/code'
}
export const teamScreeningWorkerGenCode_TYPE = function() {
  return 'get'
}
export const teamScreeningWorkerGenCode_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/code'
  if (parameters['planId'] !== undefined) {
    queryParameters['planId'] = parameters['planId']
  }
  if (parameters['days'] !== undefined) {
    queryParameters['days'] = parameters['days']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改筛查工作人员
 * request: teamScreeningWorkerUpdate
 * formData: teamScreeningWorkerUpdateFormData
 * url: teamScreeningWorkerUpdate_URL
 * method: teamScreeningWorkerUpdate_TYPE
 * raw_url: teamScreeningWorkerUpdate_RAW_URL
 * @param id - id
 * @param name - 姓名
 * @param organization - 所属单位
 * @param mobile - 手机号码
 * @param certificatePhoto - 原资格图片路径
 * @param files - 资格图片
 */
export const teamScreeningWorkerUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['organization'] !== undefined) {
    form['organization'] = parameters['organization']
  }
  if (parameters['organization'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: organization'))
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['mobile'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: mobile'))
  }
  if (parameters['certificatePhoto'] !== undefined) {
    form['certificatePhoto'] = parameters['certificatePhoto']
  }
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerUpdateFormData = function(formData) {
  const url = teamScreeningWorkerUpdate_RAW_URL()
  const method = teamScreeningWorkerUpdate_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerUpdate_RAW_URL = function() {
  return '/team/worker/{id}'
}
export const teamScreeningWorkerUpdate_TYPE = function() {
  return 'post'
}
export const teamScreeningWorkerUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除筛查工作人员
 * request: teamScreeningWorkerDelete
 * formData: teamScreeningWorkerDeleteFormData
 * url: teamScreeningWorkerDelete_URL
 * method: teamScreeningWorkerDelete_TYPE
 * raw_url: teamScreeningWorkerDelete_RAW_URL
 * @param id - id
 */
export const teamScreeningWorkerDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}/delete'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerDeleteFormData = function(formData) {
  const url = teamScreeningWorkerDelete_RAW_URL()
  const method = teamScreeningWorkerDelete_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerDelete_RAW_URL = function() {
  return '/team/worker/{id}/delete'
}
export const teamScreeningWorkerDelete_TYPE = function() {
  return 'delete'
}
export const teamScreeningWorkerDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}/delete'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 禁用筛查工作人员
 * request: teamScreeningWorkerDisable
 * formData: teamScreeningWorkerDisableFormData
 * url: teamScreeningWorkerDisable_URL
 * method: teamScreeningWorkerDisable_TYPE
 * raw_url: teamScreeningWorkerDisable_RAW_URL
 * @param id - id
 */
export const teamScreeningWorkerDisable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}/disable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerDisableFormData = function(formData) {
  const url = teamScreeningWorkerDisable_RAW_URL()
  const method = teamScreeningWorkerDisable_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerDisable_RAW_URL = function() {
  return '/team/worker/{id}/disable'
}
export const teamScreeningWorkerDisable_TYPE = function() {
  return 'put'
}
export const teamScreeningWorkerDisable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}/disable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 启用筛查工作人员
 * request: teamScreeningWorkerEnable
 * formData: teamScreeningWorkerEnableFormData
 * url: teamScreeningWorkerEnable_URL
 * method: teamScreeningWorkerEnable_TYPE
 * raw_url: teamScreeningWorkerEnable_RAW_URL
 * @param id - id
 */
export const teamScreeningWorkerEnable = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}/enable'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerEnableFormData = function(formData) {
  const url = teamScreeningWorkerEnable_RAW_URL()
  const method = teamScreeningWorkerEnable_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerEnable_RAW_URL = function() {
  return '/team/worker/{id}/enable'
}
export const teamScreeningWorkerEnable_TYPE = function() {
  return 'put'
}
export const teamScreeningWorkerEnable_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}/enable'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员通过审核
 * request: teamScreeningWorkerPass
 * formData: teamScreeningWorkerPassFormData
 * url: teamScreeningWorkerPass_URL
 * method: teamScreeningWorkerPass_TYPE
 * raw_url: teamScreeningWorkerPass_RAW_URL
 * @param id - id
 */
export const teamScreeningWorkerPass = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}/pass'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerPassFormData = function(formData) {
  const url = teamScreeningWorkerPass_RAW_URL()
  const method = teamScreeningWorkerPass_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerPass_RAW_URL = function() {
  return '/team/worker/{id}/pass'
}
export const teamScreeningWorkerPass_TYPE = function() {
  return 'put'
}
export const teamScreeningWorkerPass_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}/pass'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查工作人员驳回审核
 * request: teamScreeningWorkerReject
 * formData: teamScreeningWorkerRejectFormData
 * url: teamScreeningWorkerReject_URL
 * method: teamScreeningWorkerReject_TYPE
 * raw_url: teamScreeningWorkerReject_RAW_URL
 * @param id - id
 */
export const teamScreeningWorkerReject = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/team/worker/{id}/reject'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const teamScreeningWorkerRejectFormData = function(formData) {
  const url = teamScreeningWorkerReject_RAW_URL()
  const method = teamScreeningWorkerReject_TYPE()
  return axios[method](url, formData)
}
export const teamScreeningWorkerReject_RAW_URL = function() {
  return '/team/worker/{id}/reject'
}
export const teamScreeningWorkerReject_TYPE = function() {
  return 'put'
}
export const teamScreeningWorkerReject_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/team/worker/{id}/reject'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: wjwAdministratorList
 * formData: wjwAdministratorListFormData
 * url: wjwAdministratorList_URL
 * method: wjwAdministratorList_TYPE
 * raw_url: wjwAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorListFormData = function(formData) {
  const url = wjwAdministratorList_RAW_URL()
  const method = wjwAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorList_RAW_URL = function() {
  return '/wjw/administrator'
}
export const wjwAdministratorList_TYPE = function() {
  return 'get'
}
export const wjwAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: wjwAdministratorAdd
 * formData: wjwAdministratorAddFormData
 * url: wjwAdministratorAdd_URL
 * method: wjwAdministratorAdd_TYPE
 * raw_url: wjwAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const wjwAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorAddFormData = function(formData) {
  const url = wjwAdministratorAdd_RAW_URL()
  const method = wjwAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorAdd_RAW_URL = function() {
  return '/wjw/administrator'
}
export const wjwAdministratorAdd_TYPE = function() {
  return 'post'
}
export const wjwAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 全部管理员选择列表
 * request: wjwAdministratorAllChoiceList
 * formData: wjwAdministratorAllChoiceListFormData
 * url: wjwAdministratorAllChoiceList_URL
 * method: wjwAdministratorAllChoiceList_TYPE
 * raw_url: wjwAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const wjwAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorAllChoiceListFormData = function(formData) {
  const url = wjwAdministratorAllChoiceList_RAW_URL()
  const method = wjwAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorAllChoiceList_RAW_URL = function() {
  return '/wjw/administrator/all-choice'
}
export const wjwAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const wjwAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员选择列表
 * request: wjwAdministratorChoiceList
 * formData: wjwAdministratorChoiceListFormData
 * url: wjwAdministratorChoiceList_URL
 * method: wjwAdministratorChoiceList_TYPE
 * raw_url: wjwAdministratorChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const wjwAdministratorChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorChoiceListFormData = function(formData) {
  const url = wjwAdministratorChoiceList_RAW_URL()
  const method = wjwAdministratorChoiceList_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorChoiceList_RAW_URL = function() {
  return '/wjw/administrator/choice'
}
export const wjwAdministratorChoiceList_TYPE = function() {
  return 'get'
}
export const wjwAdministratorChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: wjwImportAdministrator
 * formData: wjwImportAdministratorFormData
 * url: wjwImportAdministrator_URL
 * method: wjwImportAdministrator_TYPE
 * raw_url: wjwImportAdministrator_RAW_URL
 * @param file - 
 */
export const wjwImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const wjwImportAdministratorFormData = function(formData) {
  const url = wjwImportAdministrator_RAW_URL()
  const method = wjwImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const wjwImportAdministrator_RAW_URL = function() {
  return '/wjw/administrator/import'
}
export const wjwImportAdministrator_TYPE = function() {
  return 'post'
}
export const wjwImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: wjwAdministratorDelete
 * formData: wjwAdministratorDeleteFormData
 * url: wjwAdministratorDelete_URL
 * method: wjwAdministratorDelete_TYPE
 * raw_url: wjwAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const wjwAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorDeleteFormData = function(formData) {
  const url = wjwAdministratorDelete_RAW_URL()
  const method = wjwAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorDelete_RAW_URL = function() {
  return '/wjw/administrator/{administratorId}'
}
export const wjwAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const wjwAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: wjwAdministratorListRole
 * formData: wjwAdministratorListRoleFormData
 * url: wjwAdministratorListRole_URL
 * method: wjwAdministratorListRole_TYPE
 * raw_url: wjwAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const wjwAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorListRoleFormData = function(formData) {
  const url = wjwAdministratorListRole_RAW_URL()
  const method = wjwAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorListRole_RAW_URL = function() {
  return '/wjw/administrator/{administratorId}/role'
}
export const wjwAdministratorListRole_TYPE = function() {
  return 'get'
}
export const wjwAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: wjwAdministratorAddRole
 * formData: wjwAdministratorAddRoleFormData
 * url: wjwAdministratorAddRole_URL
 * method: wjwAdministratorAddRole_TYPE
 * raw_url: wjwAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const wjwAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorAddRoleFormData = function(formData) {
  const url = wjwAdministratorAddRole_RAW_URL()
  const method = wjwAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorAddRole_RAW_URL = function() {
  return '/wjw/administrator/{administratorId}/role'
}
export const wjwAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const wjwAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: wjwAdministratorDeleteRole
 * formData: wjwAdministratorDeleteRoleFormData
 * url: wjwAdministratorDeleteRole_URL
 * method: wjwAdministratorDeleteRole_TYPE
 * raw_url: wjwAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const wjwAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/administrator/{administratorId}/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const wjwAdministratorDeleteRoleFormData = function(formData) {
  const url = wjwAdministratorDeleteRole_RAW_URL()
  const method = wjwAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const wjwAdministratorDeleteRole_RAW_URL = function() {
  return '/wjw/administrator/{administratorId}/role/{roleId}'
}
export const wjwAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const wjwAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/administrator/{administratorId}/role/{roleId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 卫健部门添加
 * request: wjwGovernmentSectorAdd
 * formData: wjwGovernmentSectorAddFormData
 * url: wjwGovernmentSectorAdd_URL
 * method: wjwGovernmentSectorAdd_TYPE
 * raw_url: wjwGovernmentSectorAdd_RAW_URL
 * @param regionType - 地区类型
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param managerId - 管理员ID
 */
export const wjwGovernmentSectorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/government-sector'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionType'] !== undefined) {
    form['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters['cityCode'] !== undefined) {
    form['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    form['districtCode'] = parameters['districtCode']
  }
  if (parameters['managerId'] !== undefined) {
    form['managerId'] = parameters['managerId']
  }
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const wjwGovernmentSectorAddFormData = function(formData) {
  const url = wjwGovernmentSectorAdd_RAW_URL()
  const method = wjwGovernmentSectorAdd_TYPE()
  return axios[method](url, formData)
}
export const wjwGovernmentSectorAdd_RAW_URL = function() {
  return '/wjw/government-sector'
}
export const wjwGovernmentSectorAdd_TYPE = function() {
  return 'post'
}
export const wjwGovernmentSectorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/government-sector'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 卫健部门
 * request: wjwGovernmentSectorFindAndChildren
 * formData: wjwGovernmentSectorFindAndChildrenFormData
 * url: wjwGovernmentSectorFindAndChildren_URL
 * method: wjwGovernmentSectorFindAndChildren_TYPE
 * raw_url: wjwGovernmentSectorFindAndChildren_RAW_URL
 */
export const wjwGovernmentSectorFindAndChildren = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/government-sector/children'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwGovernmentSectorFindAndChildrenFormData = function(formData) {
  const url = wjwGovernmentSectorFindAndChildren_RAW_URL()
  const method = wjwGovernmentSectorFindAndChildren_TYPE()
  return axios[method](url, formData)
}
export const wjwGovernmentSectorFindAndChildren_RAW_URL = function() {
  return '/wjw/government-sector/children'
}
export const wjwGovernmentSectorFindAndChildren_TYPE = function() {
  return 'get'
}
export const wjwGovernmentSectorFindAndChildren_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/government-sector/children'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 卫健部门转让负责人
 * request: wjwGovernmentSectorUpdateManager
 * formData: wjwGovernmentSectorUpdateManagerFormData
 * url: wjwGovernmentSectorUpdateManager_URL
 * method: wjwGovernmentSectorUpdateManager_TYPE
 * raw_url: wjwGovernmentSectorUpdateManager_RAW_URL
 * @param administratorId - 
 */
export const wjwGovernmentSectorUpdateManager = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/government-sector/manager'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const wjwGovernmentSectorUpdateManagerFormData = function(formData) {
  const url = wjwGovernmentSectorUpdateManager_RAW_URL()
  const method = wjwGovernmentSectorUpdateManager_TYPE()
  return axios[method](url, formData)
}
export const wjwGovernmentSectorUpdateManager_RAW_URL = function() {
  return '/wjw/government-sector/manager'
}
export const wjwGovernmentSectorUpdateManager_TYPE = function() {
  return 'put'
}
export const wjwGovernmentSectorUpdateManager_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/government-sector/manager'
  if (parameters['administratorId'] !== undefined) {
    queryParameters['administratorId'] = parameters['administratorId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 卫健部门详情
 * request: wjwGovernmentSectorFind
 * formData: wjwGovernmentSectorFindFormData
 * url: wjwGovernmentSectorFind_URL
 * method: wjwGovernmentSectorFind_TYPE
 * raw_url: wjwGovernmentSectorFind_RAW_URL
 * @param governmentSectorId - governmentSectorId
 */
export const wjwGovernmentSectorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/government-sector/{governmentSectorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwGovernmentSectorFindFormData = function(formData) {
  const url = wjwGovernmentSectorFind_RAW_URL()
  const method = wjwGovernmentSectorFind_TYPE()
  return axios[method](url, formData)
}
export const wjwGovernmentSectorFind_RAW_URL = function() {
  return '/wjw/government-sector/{governmentSectorId}'
}
export const wjwGovernmentSectorFind_TYPE = function() {
  return 'get'
}
export const wjwGovernmentSectorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/government-sector/{governmentSectorId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改卫健部门管理员
 * request: wjwGovernmentSectorUpdate
 * formData: wjwGovernmentSectorUpdateFormData
 * url: wjwGovernmentSectorUpdate_URL
 * method: wjwGovernmentSectorUpdate_TYPE
 * raw_url: wjwGovernmentSectorUpdate_RAW_URL
 * @param governmentSectorId - governmentSectorId
 * @param managerId - managerId
 */
export const wjwGovernmentSectorUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/government-sector/{governmentSectorId}/administrator/{managerId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  if (parameters['governmentSectorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: governmentSectorId'))
  }
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters['managerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: managerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const wjwGovernmentSectorUpdateFormData = function(formData) {
  const url = wjwGovernmentSectorUpdate_RAW_URL()
  const method = wjwGovernmentSectorUpdate_TYPE()
  return axios[method](url, formData)
}
export const wjwGovernmentSectorUpdate_RAW_URL = function() {
  return '/wjw/government-sector/{governmentSectorId}/administrator/{managerId}'
}
export const wjwGovernmentSectorUpdate_TYPE = function() {
  return 'put'
}
export const wjwGovernmentSectorUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/government-sector/{governmentSectorId}/administrator/{managerId}'
  path = path.replace('{governmentSectorId}', `${parameters['governmentSectorId']}`)
  path = path.replace('{managerId}', `${parameters['managerId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: wjwRoleList
 * formData: wjwRoleListFormData
 * url: wjwRoleList_URL
 * method: wjwRoleList_TYPE
 * raw_url: wjwRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwRoleListFormData = function(formData) {
  const url = wjwRoleList_RAW_URL()
  const method = wjwRoleList_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleList_RAW_URL = function() {
  return '/wjw/role'
}
export const wjwRoleList_TYPE = function() {
  return 'get'
}
export const wjwRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: wjwRoleAdd
 * formData: wjwRoleAddFormData
 * url: wjwRoleAdd_URL
 * method: wjwRoleAdd_TYPE
 * raw_url: wjwRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const wjwRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const wjwRoleAddFormData = function(formData) {
  const url = wjwRoleAdd_RAW_URL()
  const method = wjwRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleAdd_RAW_URL = function() {
  return '/wjw/role'
}
export const wjwRoleAdd_TYPE = function() {
  return 'post'
}
export const wjwRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: wjwRoleListFunction
 * formData: wjwRoleListFunctionFormData
 * url: wjwRoleListFunction_URL
 * method: wjwRoleListFunction_TYPE
 * raw_url: wjwRoleListFunction_RAW_URL
 */
export const wjwRoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwRoleListFunctionFormData = function(formData) {
  const url = wjwRoleListFunction_RAW_URL()
  const method = wjwRoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleListFunction_RAW_URL = function() {
  return '/wjw/role/function'
}
export const wjwRoleListFunction_TYPE = function() {
  return 'get'
}
export const wjwRoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: wjwRoleFind
 * formData: wjwRoleFindFormData
 * url: wjwRoleFind_URL
 * method: wjwRoleFind_TYPE
 * raw_url: wjwRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const wjwRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwRoleFindFormData = function(formData) {
  const url = wjwRoleFind_RAW_URL()
  const method = wjwRoleFind_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleFind_RAW_URL = function() {
  return '/wjw/role/{roleId}'
}
export const wjwRoleFind_TYPE = function() {
  return 'get'
}
export const wjwRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: wjwRoleUpdate
 * formData: wjwRoleUpdateFormData
 * url: wjwRoleUpdate_URL
 * method: wjwRoleUpdate_TYPE
 * raw_url: wjwRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const wjwRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const wjwRoleUpdateFormData = function(formData) {
  const url = wjwRoleUpdate_RAW_URL()
  const method = wjwRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleUpdate_RAW_URL = function() {
  return '/wjw/role/{roleId}'
}
export const wjwRoleUpdate_TYPE = function() {
  return 'put'
}
export const wjwRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: wjwRoleDelete
 * formData: wjwRoleDeleteFormData
 * url: wjwRoleDelete_URL
 * method: wjwRoleDelete_TYPE
 * raw_url: wjwRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const wjwRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const wjwRoleDeleteFormData = function(formData) {
  const url = wjwRoleDelete_RAW_URL()
  const method = wjwRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const wjwRoleDelete_RAW_URL = function() {
  return '/wjw/role/{roleId}'
}
export const wjwRoleDelete_TYPE = function() {
  return 'delete'
}
export const wjwRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: wjwScreeningMonitoringFind
 * formData: wjwScreeningMonitoringFindFormData
 * url: wjwScreeningMonitoringFind_URL
 * method: wjwScreeningMonitoringFind_TYPE
 * raw_url: wjwScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const wjwScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringFindFormData = function(formData) {
  const url = wjwScreeningMonitoringFind_RAW_URL()
  const method = wjwScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringFind_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const wjwScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: wjwScreeningMonitoringAbnormalStudent
 * formData: wjwScreeningMonitoringAbnormalStudentFormData
 * url: wjwScreeningMonitoringAbnormalStudent_URL
 * method: wjwScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: wjwScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = wjwScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = wjwScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const wjwScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: wjwScreeningMonitoringFinishedStudent
 * formData: wjwScreeningMonitoringFinishedStudentFormData
 * url: wjwScreeningMonitoringFinishedStudent_URL
 * method: wjwScreeningMonitoringFinishedStudent_TYPE
 * raw_url: wjwScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = wjwScreeningMonitoringFinishedStudent_RAW_URL()
  const method = wjwScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const wjwScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: wjwScreeningMonitoringFinishedStudentWithQuestionary
 * formData: wjwScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: wjwScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: wjwScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: wjwScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = wjwScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = wjwScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const wjwScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: wjwScreeningMonitoringMissingStudent
 * formData: wjwScreeningMonitoringMissingStudentFormData
 * url: wjwScreeningMonitoringMissingStudent_URL
 * method: wjwScreeningMonitoringMissingStudent_TYPE
 * raw_url: wjwScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = wjwScreeningMonitoringMissingStudent_RAW_URL()
  const method = wjwScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const wjwScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: wjwScreeningMonitoringMissingStudentWithQuestionary
 * formData: wjwScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: wjwScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: wjwScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: wjwScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = wjwScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = wjwScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const wjwScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: wjwScreeningMonitoringUncheckedStudent
 * formData: wjwScreeningMonitoringUncheckedStudentFormData
 * url: wjwScreeningMonitoringUncheckedStudent_URL
 * method: wjwScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: wjwScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const wjwScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = wjwScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = wjwScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const wjwScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: wjwScreeningMonitoringFindWithQuestionary
 * formData: wjwScreeningMonitoringFindWithQuestionaryFormData
 * url: wjwScreeningMonitoringFindWithQuestionary_URL
 * method: wjwScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: wjwScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const wjwScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = wjwScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = wjwScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const wjwScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: wjwScreeningMonitoringQualityControl
 * formData: wjwScreeningMonitoringQualityControlFormData
 * url: wjwScreeningMonitoringQualityControl_URL
 * method: wjwScreeningMonitoringQualityControl_TYPE
 * raw_url: wjwScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const wjwScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringQualityControlFormData = function(formData) {
  const url = wjwScreeningMonitoringQualityControl_RAW_URL()
  const method = wjwScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/wjw/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const wjwScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: wjwScreeningMonitoringQuestionnaryScreeningSchool
 * formData: wjwScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: wjwScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: wjwScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: wjwScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const wjwScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = wjwScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = wjwScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/wjw/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const wjwScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: wjwScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: wjwScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: wjwScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: wjwScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: wjwScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const wjwScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = wjwScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = wjwScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/wjw/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const wjwScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const wjwScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查地区监测列表
 * request: wjwScreeningRegionRegionMonitoringList
 * formData: wjwScreeningRegionRegionMonitoringListFormData
 * url: wjwScreeningRegionRegionMonitoringList_URL
 * method: wjwScreeningRegionRegionMonitoringList_TYPE
 * raw_url: wjwScreeningRegionRegionMonitoringList_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param cityCode - 
 */
export const wjwScreeningRegionRegionMonitoringList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningRegionRegionMonitoringListFormData = function(formData) {
  const url = wjwScreeningRegionRegionMonitoringList_RAW_URL()
  const method = wjwScreeningRegionRegionMonitoringList_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningRegionRegionMonitoringList_RAW_URL = function() {
  return '/wjw/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
}
export const wjwScreeningRegionRegionMonitoringList_TYPE = function() {
  return 'get'
}
export const wjwScreeningRegionRegionMonitoringList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-region/screening-plan/{screeningPlanId}/region-monitoring'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷
 * request: wjwScreeningRegionQuestionary
 * formData: wjwScreeningRegionQuestionaryFormData
 * url: wjwScreeningRegionQuestionary_URL
 * method: wjwScreeningRegionQuestionary_TYPE
 * raw_url: wjwScreeningRegionQuestionary_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 */
export const wjwScreeningRegionQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-region/{screeningPlanId}/questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningRegionQuestionaryFormData = function(formData) {
  const url = wjwScreeningRegionQuestionary_RAW_URL()
  const method = wjwScreeningRegionQuestionary_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningRegionQuestionary_RAW_URL = function() {
  return '/wjw/screening-region/{screeningPlanId}/questionary'
}
export const wjwScreeningRegionQuestionary_TYPE = function() {
  return 'get'
}
export const wjwScreeningRegionQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-region/{screeningPlanId}/questionary'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 地区问卷统计
 * request: wjwScreeningRegionQuestionaryStats
 * formData: wjwScreeningRegionQuestionaryStatsFormData
 * url: wjwScreeningRegionQuestionaryStats_URL
 * method: wjwScreeningRegionQuestionaryStats_TYPE
 * raw_url: wjwScreeningRegionQuestionaryStats_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param regionCode - 地区编码
 * @param regionType - 地区类型
 */
export const wjwScreeningRegionQuestionaryStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/screening-region/{screeningPlanId}/questionary-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionCode'))
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters['regionType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionType'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwScreeningRegionQuestionaryStatsFormData = function(formData) {
  const url = wjwScreeningRegionQuestionaryStats_RAW_URL()
  const method = wjwScreeningRegionQuestionaryStats_TYPE()
  return axios[method](url, formData)
}
export const wjwScreeningRegionQuestionaryStats_RAW_URL = function() {
  return '/wjw/screening-region/{screeningPlanId}/questionary-stats'
}
export const wjwScreeningRegionQuestionaryStats_TYPE = function() {
  return 'get'
}
export const wjwScreeningRegionQuestionaryStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/screening-region/{screeningPlanId}/questionary-stats'
  path = path.replace('{screeningPlanId}', `${parameters['screeningPlanId']}`)
  if (parameters['regionCode'] !== undefined) {
    queryParameters['regionCode'] = parameters['regionCode']
  }
  if (parameters['regionType'] !== undefined) {
    queryParameters['regionType'] = parameters['regionType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看任务指标
 * request: wjwTaskIndicatorFind
 * formData: wjwTaskIndicatorFindFormData
 * url: wjwTaskIndicatorFind_URL
 * method: wjwTaskIndicatorFind_TYPE
 * raw_url: wjwTaskIndicatorFind_RAW_URL
 * @param screeningPlanId - screeningPlanId
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningPlanId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningPlanId'))
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorFindFormData = function(formData) {
  const url = wjwTaskIndicatorFind_RAW_URL()
  const method = wjwTaskIndicatorFind_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorFind_RAW_URL = function() {
  return '/wjw/task-indicator'
}
export const wjwTaskIndicatorFind_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator'
  if (parameters['screeningPlanId'] !== undefined) {
    queryParameters['screeningPlanId'] = parameters['screeningPlanId']
  }
  if (parameters['screeningRegionId'] !== undefined) {
    queryParameters['screeningRegionId'] = parameters['screeningRegionId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: wjwTaskIndicatorDubiousStudent
 * formData: wjwTaskIndicatorDubiousStudentFormData
 * url: wjwTaskIndicatorDubiousStudent_URL
 * method: wjwTaskIndicatorDubiousStudent_TYPE
 * raw_url: wjwTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const wjwTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/dubious-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = wjwTaskIndicatorDubiousStudent_RAW_URL()
  const method = wjwTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/wjw/task-indicator/dubious-student/{screeningSchoolId}'
}
export const wjwTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/dubious-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看年级指标和班级
 * request: wjwTaskIndicatorFindForGradeStudent
 * formData: wjwTaskIndicatorFindForGradeStudentFormData
 * url: wjwTaskIndicatorFindForGradeStudent_URL
 * method: wjwTaskIndicatorFindForGradeStudent_TYPE
 * raw_url: wjwTaskIndicatorFindForGradeStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const wjwTaskIndicatorFindForGradeStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/grade-student/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorFindForGradeStudentFormData = function(formData) {
  const url = wjwTaskIndicatorFindForGradeStudent_RAW_URL()
  const method = wjwTaskIndicatorFindForGradeStudent_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorFindForGradeStudent_RAW_URL = function() {
  return '/wjw/task-indicator/grade-student/{screeningSchoolId}'
}
export const wjwTaskIndicatorFindForGradeStudent_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorFindForGradeStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/grade-student/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看计划区域指标
 * request: wjwTaskIndicatorFindPlanRegion
 * formData: wjwTaskIndicatorFindPlanRegionFormData
 * url: wjwTaskIndicatorFindPlanRegion_URL
 * method: wjwTaskIndicatorFindPlanRegion_TYPE
 * raw_url: wjwTaskIndicatorFindPlanRegion_RAW_URL
 * @param planId - planId
 */
export const wjwTaskIndicatorFindPlanRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/plan/{planId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorFindPlanRegionFormData = function(formData) {
  const url = wjwTaskIndicatorFindPlanRegion_RAW_URL()
  const method = wjwTaskIndicatorFindPlanRegion_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorFindPlanRegion_RAW_URL = function() {
  return '/wjw/task-indicator/plan/{planId}'
}
export const wjwTaskIndicatorFindPlanRegion_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorFindPlanRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/plan/{planId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学校类型指标进度
 * request: wjwTaskIndicatorSchoolDegree
 * formData: wjwTaskIndicatorSchoolDegreeFormData
 * url: wjwTaskIndicatorSchoolDegree_URL
 * method: wjwTaskIndicatorSchoolDegree_TYPE
 * raw_url: wjwTaskIndicatorSchoolDegree_RAW_URL
 * @param planId - planId
 */
export const wjwTaskIndicatorSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/plan/{planId}/school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorSchoolDegreeFormData = function(formData) {
  const url = wjwTaskIndicatorSchoolDegree_RAW_URL()
  const method = wjwTaskIndicatorSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorSchoolDegree_RAW_URL = function() {
  return '/wjw/task-indicator/plan/{planId}/school-degree'
}
export const wjwTaskIndicatorSchoolDegree_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/plan/{planId}/school-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 计划学生指标进度
 * request: wjwTaskIndicatorStudentDegree
 * formData: wjwTaskIndicatorStudentDegreeFormData
 * url: wjwTaskIndicatorStudentDegree_URL
 * method: wjwTaskIndicatorStudentDegree_TYPE
 * raw_url: wjwTaskIndicatorStudentDegree_RAW_URL
 * @param planId - planId
 */
export const wjwTaskIndicatorStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/plan/{planId}/student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorStudentDegreeFormData = function(formData) {
  const url = wjwTaskIndicatorStudentDegree_RAW_URL()
  const method = wjwTaskIndicatorStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorStudentDegree_RAW_URL = function() {
  return '/wjw/task-indicator/plan/{planId}/student-degree'
}
export const wjwTaskIndicatorStudentDegree_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/plan/{planId}/student-degree'
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看区域指标
 * request: wjwTaskIndicatorFindForRegion
 * formData: wjwTaskIndicatorFindForRegionFormData
 * url: wjwTaskIndicatorFindForRegion_URL
 * method: wjwTaskIndicatorFindForRegion_TYPE
 * raw_url: wjwTaskIndicatorFindForRegion_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorFindForRegion = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/region/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorFindForRegionFormData = function(formData) {
  const url = wjwTaskIndicatorFindForRegion_RAW_URL()
  const method = wjwTaskIndicatorFindForRegion_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorFindForRegion_RAW_URL = function() {
  return '/wjw/task-indicator/region/{screeningRegionId}'
}
export const wjwTaskIndicatorFindForRegion_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorFindForRegion_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/region/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看学校指标
 * request: wjwTaskIndicatorFindForSchool
 * formData: wjwTaskIndicatorFindForSchoolFormData
 * url: wjwTaskIndicatorFindForSchool_URL
 * method: wjwTaskIndicatorFindForSchool_TYPE
 * raw_url: wjwTaskIndicatorFindForSchool_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorFindForSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/school/{screeningRegionId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorFindForSchoolFormData = function(formData) {
  const url = wjwTaskIndicatorFindForSchool_RAW_URL()
  const method = wjwTaskIndicatorFindForSchool_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorFindForSchool_RAW_URL = function() {
  return '/wjw/task-indicator/school/{screeningRegionId}'
}
export const wjwTaskIndicatorFindForSchool_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorFindForSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/school/{screeningRegionId}'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学校类型指标进度
 * request: wjwTaskIndicatorCityRegionSchoolDegree
 * formData: wjwTaskIndicatorCityRegionSchoolDegreeFormData
 * url: wjwTaskIndicatorCityRegionSchoolDegree_URL
 * method: wjwTaskIndicatorCityRegionSchoolDegree_TYPE
 * raw_url: wjwTaskIndicatorCityRegionSchoolDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorCityRegionSchoolDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorCityRegionSchoolDegreeFormData = function(formData) {
  const url = wjwTaskIndicatorCityRegionSchoolDegree_RAW_URL()
  const method = wjwTaskIndicatorCityRegionSchoolDegree_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorCityRegionSchoolDegree_RAW_URL = function() {
  return '/wjw/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
}
export const wjwTaskIndicatorCityRegionSchoolDegree_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorCityRegionSchoolDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/city-school-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查市级地区学生类型指标进度
 * request: wjwTaskIndicatorCityRegionStudentDegree
 * formData: wjwTaskIndicatorCityRegionStudentDegreeFormData
 * url: wjwTaskIndicatorCityRegionStudentDegree_URL
 * method: wjwTaskIndicatorCityRegionStudentDegree_TYPE
 * raw_url: wjwTaskIndicatorCityRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorCityRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorCityRegionStudentDegreeFormData = function(formData) {
  const url = wjwTaskIndicatorCityRegionStudentDegree_RAW_URL()
  const method = wjwTaskIndicatorCityRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorCityRegionStudentDegree_RAW_URL = function() {
  return '/wjw/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
}
export const wjwTaskIndicatorCityRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorCityRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/city-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 筛查区级地区学生类型指标进度
 * request: wjwTaskIndicatorDistrictRegionStudentDegree
 * formData: wjwTaskIndicatorDistrictRegionStudentDegreeFormData
 * url: wjwTaskIndicatorDistrictRegionStudentDegree_URL
 * method: wjwTaskIndicatorDistrictRegionStudentDegree_TYPE
 * raw_url: wjwTaskIndicatorDistrictRegionStudentDegree_RAW_URL
 * @param screeningRegionId - screeningRegionId
 */
export const wjwTaskIndicatorDistrictRegionStudentDegree = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters['screeningRegionId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningRegionId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const wjwTaskIndicatorDistrictRegionStudentDegreeFormData = function(formData) {
  const url = wjwTaskIndicatorDistrictRegionStudentDegree_RAW_URL()
  const method = wjwTaskIndicatorDistrictRegionStudentDegree_TYPE()
  return axios[method](url, formData)
}
export const wjwTaskIndicatorDistrictRegionStudentDegree_RAW_URL = function() {
  return '/wjw/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
}
export const wjwTaskIndicatorDistrictRegionStudentDegree_TYPE = function() {
  return 'get'
}
export const wjwTaskIndicatorDistrictRegionStudentDegree_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/wjw/task-indicator/screening-region/{screeningRegionId}/district-student-degree'
  path = path.replace('{screeningRegionId}', `${parameters['screeningRegionId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员列表
 * request: yljgAdministratorList
 * formData: yljgAdministratorListFormData
 * url: yljgAdministratorList_URL
 * method: yljgAdministratorList_TYPE
 * raw_url: yljgAdministratorList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgAdministratorList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorListFormData = function(formData) {
  const url = yljgAdministratorList_RAW_URL()
  const method = yljgAdministratorList_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorList_RAW_URL = function() {
  return '/yljg/administrator'
}
export const yljgAdministratorList_TYPE = function() {
  return 'get'
}
export const yljgAdministratorList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员
 * request: yljgAdministratorAdd
 * formData: yljgAdministratorAddFormData
 * url: yljgAdministratorAdd_URL
 * method: yljgAdministratorAdd_TYPE
 * raw_url: yljgAdministratorAdd_RAW_URL
 * @param name - 
 * @param id - 若通过手机号码查询到对应账号，则只传管理员ID, 没有则除id 外其他必填
 * @param mobile - 
 * @param email - 
 * @param type - 
 */
export const yljgAdministratorAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['mobile'] !== undefined) {
    form['mobile'] = parameters['mobile']
  }
  if (parameters['email'] !== undefined) {
    form['email'] = parameters['email']
  }
  if (parameters['type'] !== undefined) {
    form['type'] = parameters['type']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorAddFormData = function(formData) {
  const url = yljgAdministratorAdd_RAW_URL()
  const method = yljgAdministratorAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorAdd_RAW_URL = function() {
  return '/yljg/administrator'
}
export const yljgAdministratorAdd_TYPE = function() {
  return 'post'
}
export const yljgAdministratorAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 全部管理员选择列表
 * request: yljgAdministratorAllChoiceList
 * formData: yljgAdministratorAllChoiceListFormData
 * url: yljgAdministratorAllChoiceList_URL
 * method: yljgAdministratorAllChoiceList_TYPE
 * raw_url: yljgAdministratorAllChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const yljgAdministratorAllChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/all-choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorAllChoiceListFormData = function(formData) {
  const url = yljgAdministratorAllChoiceList_RAW_URL()
  const method = yljgAdministratorAllChoiceList_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorAllChoiceList_RAW_URL = function() {
  return '/yljg/administrator/all-choice'
}
export const yljgAdministratorAllChoiceList_TYPE = function() {
  return 'get'
}
export const yljgAdministratorAllChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/all-choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员选择列表
 * request: yljgAdministratorChoiceList
 * formData: yljgAdministratorChoiceListFormData
 * url: yljgAdministratorChoiceList_URL
 * method: yljgAdministratorChoiceList_TYPE
 * raw_url: yljgAdministratorChoiceList_RAW_URL
 * @param name - 姓名
 * @param mobile - 手机号
 */
export const yljgAdministratorChoiceList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/choice'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorChoiceListFormData = function(formData) {
  const url = yljgAdministratorChoiceList_RAW_URL()
  const method = yljgAdministratorChoiceList_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorChoiceList_RAW_URL = function() {
  return '/yljg/administrator/choice'
}
export const yljgAdministratorChoiceList_TYPE = function() {
  return 'get'
}
export const yljgAdministratorChoiceList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/choice'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['mobile'] !== undefined) {
    queryParameters['mobile'] = parameters['mobile']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 导入管理员
 * request: yljgImportAdministrator
 * formData: yljgImportAdministratorFormData
 * url: yljgImportAdministrator_URL
 * method: yljgImportAdministrator_TYPE
 * raw_url: yljgImportAdministrator_RAW_URL
 * @param file - 
 */
export const yljgImportAdministrator = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/import'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgImportAdministratorFormData = function(formData) {
  const url = yljgImportAdministrator_RAW_URL()
  const method = yljgImportAdministrator_TYPE()
  return axios[method](url, formData)
}
export const yljgImportAdministrator_RAW_URL = function() {
  return '/yljg/administrator/import'
}
export const yljgImportAdministrator_TYPE = function() {
  return 'post'
}
export const yljgImportAdministrator_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/import'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员
 * request: yljgAdministratorDelete
 * formData: yljgAdministratorDeleteFormData
 * url: yljgAdministratorDelete_URL
 * method: yljgAdministratorDelete_TYPE
 * raw_url: yljgAdministratorDelete_RAW_URL
 * @param administratorId - administratorId
 */
export const yljgAdministratorDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/{administratorId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorDeleteFormData = function(formData) {
  const url = yljgAdministratorDelete_RAW_URL()
  const method = yljgAdministratorDelete_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorDelete_RAW_URL = function() {
  return '/yljg/administrator/{administratorId}'
}
export const yljgAdministratorDelete_TYPE = function() {
  return 'delete'
}
export const yljgAdministratorDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/{administratorId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 管理员角色列表
 * request: yljgAdministratorListRole
 * formData: yljgAdministratorListRoleFormData
 * url: yljgAdministratorListRole_URL
 * method: yljgAdministratorListRole_TYPE
 * raw_url: yljgAdministratorListRole_RAW_URL
 * @param administratorId - administratorId
 */
export const yljgAdministratorListRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorListRoleFormData = function(formData) {
  const url = yljgAdministratorListRole_RAW_URL()
  const method = yljgAdministratorListRole_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorListRole_RAW_URL = function() {
  return '/yljg/administrator/{administratorId}/role'
}
export const yljgAdministratorListRole_TYPE = function() {
  return 'get'
}
export const yljgAdministratorListRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加管理员角色
 * request: yljgAdministratorAddRole
 * formData: yljgAdministratorAddRoleFormData
 * url: yljgAdministratorAddRole_URL
 * method: yljgAdministratorAddRole_TYPE
 * raw_url: yljgAdministratorAddRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleIdStr - 
 */
export const yljgAdministratorAddRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/{administratorId}/role'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  if (parameters['roleIdStr'] !== undefined) {
    form['roleIdStr'] = parameters['roleIdStr']
  }
  if (parameters['roleIdStr'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleIdStr'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorAddRoleFormData = function(formData) {
  const url = yljgAdministratorAddRole_RAW_URL()
  const method = yljgAdministratorAddRole_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorAddRole_RAW_URL = function() {
  return '/yljg/administrator/{administratorId}/role'
}
export const yljgAdministratorAddRole_TYPE = function() {
  return 'post'
}
export const yljgAdministratorAddRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/{administratorId}/role'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除管理员角色
 * request: yljgAdministratorDeleteRole
 * formData: yljgAdministratorDeleteRoleFormData
 * url: yljgAdministratorDeleteRole_URL
 * method: yljgAdministratorDeleteRole_TYPE
 * raw_url: yljgAdministratorDeleteRole_RAW_URL
 * @param administratorId - administratorId
 * @param roleId - roleId
 */
export const yljgAdministratorDeleteRole = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/administrator/{administratorId}/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  if (parameters['administratorId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: administratorId'))
  }
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgAdministratorDeleteRoleFormData = function(formData) {
  const url = yljgAdministratorDeleteRole_RAW_URL()
  const method = yljgAdministratorDeleteRole_TYPE()
  return axios[method](url, formData)
}
export const yljgAdministratorDeleteRole_RAW_URL = function() {
  return '/yljg/administrator/{administratorId}/role/{roleId}'
}
export const yljgAdministratorDeleteRole_TYPE = function() {
  return 'delete'
}
export const yljgAdministratorDeleteRole_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/administrator/{administratorId}/role/{roleId}'
  path = path.replace('{administratorId}', `${parameters['administratorId']}`)
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 档案列表
 * request: yljgArchiveList
 * formData: yljgArchiveListFormData
 * url: yljgArchiveList_URL
 * method: yljgArchiveList_TYPE
 * raw_url: yljgArchiveList_RAW_URL
 * @param regionPersonId - 地区受检人ID
 * @param type - 类型
 * @param cityCode - 市编码
 * @param districtCode - 区编码
 * @param creatorName - 建档人姓名
 * @param startDateMills - 建档起始时间
 * @param endDateMills - 建档结束时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgArchiveList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveListFormData = function(formData) {
  const url = yljgArchiveList_RAW_URL()
  const method = yljgArchiveList_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveList_RAW_URL = function() {
  return '/yljg/archive'
}
export const yljgArchiveList_TYPE = function() {
  return 'get'
}
export const yljgArchiveList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive'
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['type'] !== undefined) {
    queryParameters['type'] = parameters['type']
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['districtCode'] !== undefined) {
    queryParameters['districtCode'] = parameters['districtCode']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存儿童青少年屈光发育档案
 * request: yljgArchiveSaveAdolescentDioptricArchive
 * formData: yljgArchiveSaveAdolescentDioptricArchiveFormData
 * url: yljgArchiveSaveAdolescentDioptricArchive_URL
 * method: yljgArchiveSaveAdolescentDioptricArchive_TYPE
 * raw_url: yljgArchiveSaveAdolescentDioptricArchive_RAW_URL
 * @param id - ID，更新时必传，反之不传
 * @param regionPersonId - 地区受检人ID
 * @param icd10 - ICD10编码
 * @param drgs - DRGS编码
 * @param subjectiveMedicalHistory - 主述病史
 * @param height - 身高
 * @param weight - 体重
 * @param leftVision - 左眼裸眼视力
 * @param rightVision - 右眼裸眼视力
 * @param leftWearingVision - 左眼戴镜视力
 * @param rightWearingVision - 右眼戴镜视力
 * @param rightIop - 右眼眼压
 * @param leftIop - 左眼眼压
 * @param rightDs - 右眼球镜
 * @param leftDs - 左眼球镜
 * @param rightDc - 右眼柱镜
 * @param leftDc - 左眼柱镜
 * @param rightAxis - 右眼轴位
 * @param leftAxis - 左眼轴位
 * @param rightAxialLength - 右眼眼轴长度
 * @param leftAxialLength - 左眼眼轴长度
 * @param rightKeratometry - 右眼角膜曲率
 * @param leftKeratometry - 左眼角膜曲率
 * @param rightCornealThickness - 右眼角膜厚度
 * @param leftCornealThickness - 左眼角膜厚度
 * @param rightAnteriorChamberDepth - 右眼前房深度
 * @param leftAnteriorChamberDepth - 左眼前房深度
 * @param rightLensDiopter - 右眼晶状体屈光度
 * @param leftLensDiopter - 左眼晶状体屈光度
 * @param rightLensTensioning - 右眼晶状体张力性调节
 * @param leftLensTensioning - 左眼晶状体张力性调节
 * @param colorVision - 色觉
 * @param eyePosition - 眼位
 * @param trachoma - 沙眼
 * @param conjunctivitis - 结膜炎
 * @param stereopsis - 立体视
 * @param slitLamp - 裂隙灯检查
 * @param eyeground - 眼底检查
 * @param otc - OTC检查
 * @param cornealTopography - 角膜地形图
 * @param diagnosis - 诊断结果
 * @param treatmentSuggestion - 处理意见
 */
export const yljgArchiveSaveAdolescentDioptricArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/adolescent-dioptric'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['icd10'] !== undefined) {
    form['icd10'] = parameters['icd10']
  }
  if (parameters['drgs'] !== undefined) {
    form['drgs'] = parameters['drgs']
  }
  if (parameters['subjectiveMedicalHistory'] !== undefined) {
    form['subjectiveMedicalHistory'] = parameters['subjectiveMedicalHistory']
  }
  if (parameters['height'] !== undefined) {
    form['height'] = parameters['height']
  }
  if (parameters['weight'] !== undefined) {
    form['weight'] = parameters['weight']
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['leftWearingVision'] !== undefined) {
    form['leftWearingVision'] = parameters['leftWearingVision']
  }
  if (parameters['rightWearingVision'] !== undefined) {
    form['rightWearingVision'] = parameters['rightWearingVision']
  }
  if (parameters['rightIop'] !== undefined) {
    form['rightIOP'] = parameters['rightIop']
  }
  if (parameters['leftIop'] !== undefined) {
    form['leftIOP'] = parameters['leftIop']
  }
  if (parameters['rightDs'] !== undefined) {
    form['rightDS'] = parameters['rightDs']
  }
  if (parameters['leftDs'] !== undefined) {
    form['leftDS'] = parameters['leftDs']
  }
  if (parameters['rightDc'] !== undefined) {
    form['rightDC'] = parameters['rightDc']
  }
  if (parameters['leftDc'] !== undefined) {
    form['leftDC'] = parameters['leftDc']
  }
  if (parameters['rightAxis'] !== undefined) {
    form['rightAxis'] = parameters['rightAxis']
  }
  if (parameters['leftAxis'] !== undefined) {
    form['leftAxis'] = parameters['leftAxis']
  }
  if (parameters['rightAxialLength'] !== undefined) {
    form['rightAxialLength'] = parameters['rightAxialLength']
  }
  if (parameters['leftAxialLength'] !== undefined) {
    form['leftAxialLength'] = parameters['leftAxialLength']
  }
  if (parameters['rightKeratometry'] !== undefined) {
    form['rightKeratometry'] = parameters['rightKeratometry']
  }
  if (parameters['leftKeratometry'] !== undefined) {
    form['leftKeratometry'] = parameters['leftKeratometry']
  }
  if (parameters['rightCornealThickness'] !== undefined) {
    form['rightCornealThickness'] = parameters['rightCornealThickness']
  }
  if (parameters['leftCornealThickness'] !== undefined) {
    form['leftCornealThickness'] = parameters['leftCornealThickness']
  }
  if (parameters['rightAnteriorChamberDepth'] !== undefined) {
    form['rightAnteriorChamberDepth'] = parameters['rightAnteriorChamberDepth']
  }
  if (parameters['leftAnteriorChamberDepth'] !== undefined) {
    form['leftAnteriorChamberDepth'] = parameters['leftAnteriorChamberDepth']
  }
  if (parameters['rightLensDiopter'] !== undefined) {
    form['rightLensDiopter'] = parameters['rightLensDiopter']
  }
  if (parameters['leftLensDiopter'] !== undefined) {
    form['leftLensDiopter'] = parameters['leftLensDiopter']
  }
  if (parameters['rightLensTensioning'] !== undefined) {
    form['rightLensTensioning'] = parameters['rightLensTensioning']
  }
  if (parameters['leftLensTensioning'] !== undefined) {
    form['leftLensTensioning'] = parameters['leftLensTensioning']
  }
  if (parameters['colorVision'] !== undefined) {
    form['colorVision'] = parameters['colorVision']
  }
  if (parameters['eyePosition'] !== undefined) {
    form['eyePosition'] = parameters['eyePosition']
  }
  if (parameters['trachoma'] !== undefined) {
    form['trachoma'] = parameters['trachoma']
  }
  if (parameters['conjunctivitis'] !== undefined) {
    form['conjunctivitis'] = parameters['conjunctivitis']
  }
  if (parameters['stereopsis'] !== undefined) {
    form['stereopsis'] = parameters['stereopsis']
  }
  if (parameters['slitLamp'] !== undefined) {
    form['slitLamp'] = parameters['slitLamp']
  }
  if (parameters['eyeground'] !== undefined) {
    form['eyeground'] = parameters['eyeground']
  }
  if (parameters['otc'] !== undefined) {
    form['otc'] = parameters['otc']
  }
  if (parameters['cornealTopography'] !== undefined) {
    form['cornealTopography'] = parameters['cornealTopography']
  }
  if (parameters['diagnosis'] !== undefined) {
    form['diagnosis'] = parameters['diagnosis']
  }
  if (parameters['treatmentSuggestion'] !== undefined) {
    form['treatmentSuggestion'] = parameters['treatmentSuggestion']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveSaveAdolescentDioptricArchiveFormData = function(formData) {
  const url = yljgArchiveSaveAdolescentDioptricArchive_RAW_URL()
  const method = yljgArchiveSaveAdolescentDioptricArchive_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveSaveAdolescentDioptricArchive_RAW_URL = function() {
  return '/yljg/archive/adolescent-dioptric'
}
export const yljgArchiveSaveAdolescentDioptricArchive_TYPE = function() {
  return 'post'
}
export const yljgArchiveSaveAdolescentDioptricArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/adolescent-dioptric'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传图片
 * request: yljgArchiveUploadFile
 * formData: yljgArchiveUploadFileFormData
 * url: yljgArchiveUploadFile_URL
 * method: yljgArchiveUploadFile_TYPE
 * raw_url: yljgArchiveUploadFile_RAW_URL
 * @param files - 
 */
export const yljgArchiveUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveUploadFileFormData = function(formData) {
  const url = yljgArchiveUploadFile_RAW_URL()
  const method = yljgArchiveUploadFile_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveUploadFile_RAW_URL = function() {
  return '/yljg/archive/file'
}
export const yljgArchiveUploadFile_TYPE = function() {
  return 'post'
}
export const yljgArchiveUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存0-6岁眼健康管理档案
 * request: yljgArchiveSaveInfantEyeHealthArchive
 * formData: yljgArchiveSaveInfantEyeHealthArchiveFormData
 * url: yljgArchiveSaveInfantEyeHealthArchive_URL
 * method: yljgArchiveSaveInfantEyeHealthArchive_TYPE
 * raw_url: yljgArchiveSaveInfantEyeHealthArchive_RAW_URL
 * @param id - ID，更新时必传，反之不传
 * @param regionPersonId - 地区受检人ID
 * @param icd10 - ICD10编码
 * @param drgs - DRGS编码
 * @param eyelid - 眼睑
 * @param eyelidRemark - 眼睑异常备注
 * @param conjunctiva - 结膜
 * @param conjunctivaRemark - 结膜异常备注
 * @param cornea - 角膜
 * @param corneaRemark - 角膜异常备注
 * @param pupil - 瞳孔
 * @param pupilRemark - 瞳孔异常备注
 * @param lightReflex - 光照反射
 * @param lightReflexRemark - 光照反射异常备注
 * @param winkingReflex - 瞬目反射
 * @param winkingReflexRemark - 瞬目反射异常备注
 * @param abnormalVisualBehavior - 异常视觉行为
 * @param abnormalVisualBehaviorRemark - 异常视觉行为其他备注
 * @param rightRetinopathyOfPrematurity - 右眼早产儿视网膜病变
 * @param leftRetinopathyOfPrematurity - 左眼早产儿视网膜病变
 * @param rightRedReflex - 右眼红光反射
 * @param rightRedReflexRemark - 右眼红光反射异常备注
 * @param leftRedReflex - 左眼红光反射
 * @param leftRedReflexRemark - 左眼红光反射异常备注
 * @param eyePosition - 眼位
 * @param eyePositionRemark - 眼位异常备注
 * @param eyeMovement - 眼球运动
 * @param eyeMovementRemark - 眼球运动异常备注
 * @param visualChartType - 视力表类型
 * @param visualChartTypeRemark - 视力表类型其他备注
 * @param wearingGlassesType - 戴镜类型
 * @param rightWearingDegree - 右眼佩戴度数
 * @param leftWearingDegree - 左眼佩戴度数
 * @param rightVision - 右眼裸眼视力
 * @param leftVision - 左眼裸眼视力
 * @param rightWearingVision - 右眼戴镜视力
 * @param leftWearingVision - 左眼戴镜视力
 * @param nonCycloplegicRefractionRightDs - 非睫状肌麻痹验光下右眼球镜
 * @param nonCycloplegicRefractionLeftDs - 非睫状肌麻痹验光下左眼球镜
 * @param nonCycloplegicRefractionRightDc - 非睫状肌麻痹验光下右眼柱镜
 * @param nonCycloplegicRefractionLeftDc - 非睫状肌麻痹验光下左眼柱镜
 * @param nonCycloplegicRefractionRightAxis - 非睫状肌麻痹验光下右眼轴位
 * @param nonCycloplegicRefractionLeftAxis - 非睫状肌麻痹验光下左眼轴位
 * @param cycloplegicRefractionRightDs - 睫状肌麻痹验光下右眼球镜
 * @param cycloplegicRefractionLeftDs - 睫状肌麻痹验光下左眼球镜
 * @param cycloplegicRefractionRightDc - 睫状肌麻痹验光下右眼柱镜
 * @param cycloplegicRefractionLeftDc - 睫状肌麻痹验光下左眼柱镜
 * @param cycloplegicRefractionRightAxis - 睫状肌麻痹验光下右眼轴位
 * @param cycloplegicRefractionLeftAxis - 睫状肌麻痹验光下左眼轴位
 * @param diopterSpecialRemark - 屈光筛查其他特殊情况备注
 * @param clinicalImpression - 临床印象
 * @param clinicalImpressionRemark - 临床印象异常备注
 * @param followUp - 随访
 * @param transferTreatment - 转诊
 */
export const yljgArchiveSaveInfantEyeHealthArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/infant-eye-health'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['id'] !== undefined) {
    form['id'] = parameters['id']
  }
  if (parameters['regionPersonId'] !== undefined) {
    form['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['icd10'] !== undefined) {
    form['icd10'] = parameters['icd10']
  }
  if (parameters['drgs'] !== undefined) {
    form['drgs'] = parameters['drgs']
  }
  if (parameters['eyelid'] !== undefined) {
    form['eyelid'] = parameters['eyelid']
  }
  if (parameters['eyelidRemark'] !== undefined) {
    form['eyelidRemark'] = parameters['eyelidRemark']
  }
  if (parameters['conjunctiva'] !== undefined) {
    form['conjunctiva'] = parameters['conjunctiva']
  }
  if (parameters['conjunctivaRemark'] !== undefined) {
    form['conjunctivaRemark'] = parameters['conjunctivaRemark']
  }
  if (parameters['cornea'] !== undefined) {
    form['cornea'] = parameters['cornea']
  }
  if (parameters['corneaRemark'] !== undefined) {
    form['corneaRemark'] = parameters['corneaRemark']
  }
  if (parameters['pupil'] !== undefined) {
    form['pupil'] = parameters['pupil']
  }
  if (parameters['pupilRemark'] !== undefined) {
    form['pupilRemark'] = parameters['pupilRemark']
  }
  if (parameters['lightReflex'] !== undefined) {
    form['lightReflex'] = parameters['lightReflex']
  }
  if (parameters['lightReflexRemark'] !== undefined) {
    form['lightReflexRemark'] = parameters['lightReflexRemark']
  }
  if (parameters['winkingReflex'] !== undefined) {
    form['winkingReflex'] = parameters['winkingReflex']
  }
  if (parameters['winkingReflexRemark'] !== undefined) {
    form['winkingReflexRemark'] = parameters['winkingReflexRemark']
  }
  if (parameters['abnormalVisualBehavior'] !== undefined) {
    form['abnormalVisualBehavior'] = parameters['abnormalVisualBehavior']
  }
  if (parameters['abnormalVisualBehaviorRemark'] !== undefined) {
    form['abnormalVisualBehaviorRemark'] = parameters['abnormalVisualBehaviorRemark']
  }
  if (parameters['rightRetinopathyOfPrematurity'] !== undefined) {
    form['rightRetinopathyOfPrematurity'] = parameters['rightRetinopathyOfPrematurity']
  }
  if (parameters['leftRetinopathyOfPrematurity'] !== undefined) {
    form['leftRetinopathyOfPrematurity'] = parameters['leftRetinopathyOfPrematurity']
  }
  if (parameters['rightRedReflex'] !== undefined) {
    form['rightRedReflex'] = parameters['rightRedReflex']
  }
  if (parameters['rightRedReflexRemark'] !== undefined) {
    form['rightRedReflexRemark'] = parameters['rightRedReflexRemark']
  }
  if (parameters['leftRedReflex'] !== undefined) {
    form['leftRedReflex'] = parameters['leftRedReflex']
  }
  if (parameters['leftRedReflexRemark'] !== undefined) {
    form['leftRedReflexRemark'] = parameters['leftRedReflexRemark']
  }
  if (parameters['eyePosition'] !== undefined) {
    form['eyePosition'] = parameters['eyePosition']
  }
  if (parameters['eyePositionRemark'] !== undefined) {
    form['eyePositionRemark'] = parameters['eyePositionRemark']
  }
  if (parameters['eyeMovement'] !== undefined) {
    form['eyeMovement'] = parameters['eyeMovement']
  }
  if (parameters['eyeMovementRemark'] !== undefined) {
    form['eyeMovementRemark'] = parameters['eyeMovementRemark']
  }
  if (parameters['visualChartType'] !== undefined) {
    form['visualChartType'] = parameters['visualChartType']
  }
  if (parameters['visualChartTypeRemark'] !== undefined) {
    form['visualChartTypeRemark'] = parameters['visualChartTypeRemark']
  }
  if (parameters['wearingGlassesType'] !== undefined) {
    form['wearingGlassesType'] = parameters['wearingGlassesType']
  }
  if (parameters['rightWearingDegree'] !== undefined) {
    form['rightWearingDegree'] = parameters['rightWearingDegree']
  }
  if (parameters['leftWearingDegree'] !== undefined) {
    form['leftWearingDegree'] = parameters['leftWearingDegree']
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['rightWearingVision'] !== undefined) {
    form['rightWearingVision'] = parameters['rightWearingVision']
  }
  if (parameters['leftWearingVision'] !== undefined) {
    form['leftWearingVision'] = parameters['leftWearingVision']
  }
  if (parameters['nonCycloplegicRefractionRightDs'] !== undefined) {
    form['nonCycloplegicRefractionRightDS'] = parameters['nonCycloplegicRefractionRightDs']
  }
  if (parameters['nonCycloplegicRefractionLeftDs'] !== undefined) {
    form['nonCycloplegicRefractionLeftDS'] = parameters['nonCycloplegicRefractionLeftDs']
  }
  if (parameters['nonCycloplegicRefractionRightDc'] !== undefined) {
    form['nonCycloplegicRefractionRightDC'] = parameters['nonCycloplegicRefractionRightDc']
  }
  if (parameters['nonCycloplegicRefractionLeftDc'] !== undefined) {
    form['nonCycloplegicRefractionLeftDC'] = parameters['nonCycloplegicRefractionLeftDc']
  }
  if (parameters['nonCycloplegicRefractionRightAxis'] !== undefined) {
    form['nonCycloplegicRefractionRightAxis'] = parameters['nonCycloplegicRefractionRightAxis']
  }
  if (parameters['nonCycloplegicRefractionLeftAxis'] !== undefined) {
    form['nonCycloplegicRefractionLeftAxis'] = parameters['nonCycloplegicRefractionLeftAxis']
  }
  if (parameters['cycloplegicRefractionRightDs'] !== undefined) {
    form['cycloplegicRefractionRightDS'] = parameters['cycloplegicRefractionRightDs']
  }
  if (parameters['cycloplegicRefractionLeftDs'] !== undefined) {
    form['cycloplegicRefractionLeftDS'] = parameters['cycloplegicRefractionLeftDs']
  }
  if (parameters['cycloplegicRefractionRightDc'] !== undefined) {
    form['cycloplegicRefractionRightDC'] = parameters['cycloplegicRefractionRightDc']
  }
  if (parameters['cycloplegicRefractionLeftDc'] !== undefined) {
    form['cycloplegicRefractionLeftDC'] = parameters['cycloplegicRefractionLeftDc']
  }
  if (parameters['cycloplegicRefractionRightAxis'] !== undefined) {
    form['cycloplegicRefractionRightAxis'] = parameters['cycloplegicRefractionRightAxis']
  }
  if (parameters['cycloplegicRefractionLeftAxis'] !== undefined) {
    form['cycloplegicRefractionLeftAxis'] = parameters['cycloplegicRefractionLeftAxis']
  }
  if (parameters['diopterSpecialRemark'] !== undefined) {
    form['diopterSpecialRemark'] = parameters['diopterSpecialRemark']
  }
  if (parameters['clinicalImpression'] !== undefined) {
    form['clinicalImpression'] = parameters['clinicalImpression']
  }
  if (parameters['clinicalImpressionRemark'] !== undefined) {
    form['clinicalImpressionRemark'] = parameters['clinicalImpressionRemark']
  }
  if (parameters['followUp'] !== undefined) {
    form['followUp'] = parameters['followUp']
  }
  if (parameters['transferTreatment'] !== undefined) {
    form['transferTreatment'] = parameters['transferTreatment']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveSaveInfantEyeHealthArchiveFormData = function(formData) {
  const url = yljgArchiveSaveInfantEyeHealthArchive_RAW_URL()
  const method = yljgArchiveSaveInfantEyeHealthArchive_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveSaveInfantEyeHealthArchive_RAW_URL = function() {
  return '/yljg/archive/infant-eye-health'
}
export const yljgArchiveSaveInfantEyeHealthArchive_TYPE = function() {
  return 'post'
}
export const yljgArchiveSaveInfantEyeHealthArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/infant-eye-health'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 用户档案趋势图
 * request: yljgArchiveTendencyChart
 * formData: yljgArchiveTendencyChartFormData
 * url: yljgArchiveTendencyChart_URL
 * method: yljgArchiveTendencyChart_TYPE
 * raw_url: yljgArchiveTendencyChart_RAW_URL
 * @param regionPersonId - regionPersonId
 * @param cityCode - cityCode
 */
export const yljgArchiveTendencyChart = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/tendency-chart/{regionPersonId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['regionPersonId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: regionPersonId'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveTendencyChartFormData = function(formData) {
  const url = yljgArchiveTendencyChart_RAW_URL()
  const method = yljgArchiveTendencyChart_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveTendencyChart_RAW_URL = function() {
  return '/yljg/archive/tendency-chart/{regionPersonId}'
}
export const yljgArchiveTendencyChart_TYPE = function() {
  return 'get'
}
export const yljgArchiveTendencyChart_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/tendency-chart/{regionPersonId}'
  path = path.replace('{regionPersonId}', `${parameters['regionPersonId']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看儿童青少年屈光发育档案
 * request: yljgArchiveFindAdolescentDioptricArchive
 * formData: yljgArchiveFindAdolescentDioptricArchiveFormData
 * url: yljgArchiveFindAdolescentDioptricArchive_URL
 * method: yljgArchiveFindAdolescentDioptricArchive_TYPE
 * raw_url: yljgArchiveFindAdolescentDioptricArchive_RAW_URL
 * @param id - id
 * @param cityCode - cityCode
 */
export const yljgArchiveFindAdolescentDioptricArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/{id}/adolescent-dioptric'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveFindAdolescentDioptricArchiveFormData = function(formData) {
  const url = yljgArchiveFindAdolescentDioptricArchive_RAW_URL()
  const method = yljgArchiveFindAdolescentDioptricArchive_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveFindAdolescentDioptricArchive_RAW_URL = function() {
  return '/yljg/archive/{id}/adolescent-dioptric'
}
export const yljgArchiveFindAdolescentDioptricArchive_TYPE = function() {
  return 'get'
}
export const yljgArchiveFindAdolescentDioptricArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/{id}/adolescent-dioptric'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看0-6岁眼健康管理档案
 * request: yljgArchiveFindInfantEyeHealthArchive
 * formData: yljgArchiveFindInfantEyeHealthArchiveFormData
 * url: yljgArchiveFindInfantEyeHealthArchive_URL
 * method: yljgArchiveFindInfantEyeHealthArchive_TYPE
 * raw_url: yljgArchiveFindInfantEyeHealthArchive_RAW_URL
 * @param id - id
 * @param cityCode - cityCode
 */
export const yljgArchiveFindInfantEyeHealthArchive = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/archive/{id}/infant-eye-health'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters['cityCode'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: cityCode'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArchiveFindInfantEyeHealthArchiveFormData = function(formData) {
  const url = yljgArchiveFindInfantEyeHealthArchive_RAW_URL()
  const method = yljgArchiveFindInfantEyeHealthArchive_TYPE()
  return axios[method](url, formData)
}
export const yljgArchiveFindInfantEyeHealthArchive_RAW_URL = function() {
  return '/yljg/archive/{id}/infant-eye-health'
}
export const yljgArchiveFindInfantEyeHealthArchive_TYPE = function() {
  return 'get'
}
export const yljgArchiveFindInfantEyeHealthArchive_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/archive/{id}/infant-eye-health'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章列表
 * request: yljgArticleList
 * formData: yljgArticleListFormData
 * url: yljgArticleList_URL
 * method: yljgArticleList_TYPE
 * raw_url: yljgArticleList_RAW_URL
 * @param categoryId - 类别id
 * @param title - 标题
 * @param readerType - 阅读者类型
 * @param intro - 简介
 * @param label - 宣教标签
 * @param creatorName - 创建人姓名
 * @param createTime - 创建时间
 * @param auditorName - 审核人
 * @param auditTime - 审核时间
 * @param status - 状态
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgArticleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleListFormData = function(formData) {
  const url = yljgArticleList_RAW_URL()
  const method = yljgArticleList_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleList_RAW_URL = function() {
  return '/yljg/article'
}
export const yljgArticleList_TYPE = function() {
  return 'get'
}
export const yljgArticleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article'
  if (parameters['categoryId'] !== undefined) {
    queryParameters['categoryId'] = parameters['categoryId']
  }
  if (parameters['title'] !== undefined) {
    queryParameters['title'] = parameters['title']
  }
  if (parameters['readerType'] !== undefined) {
    queryParameters['readerType'] = parameters['readerType']
  }
  if (parameters['intro'] !== undefined) {
    queryParameters['intro'] = parameters['intro']
  }
  if (parameters['label'] !== undefined) {
    queryParameters['label'] = parameters['label']
  }
  if (parameters['creatorName'] !== undefined) {
    queryParameters['creatorName'] = parameters['creatorName']
  }
  if (parameters['createTime'] !== undefined) {
    queryParameters['createTime'] = parameters['createTime']
  }
  if (parameters['auditorName'] !== undefined) {
    queryParameters['auditorName'] = parameters['auditorName']
  }
  if (parameters['auditTime'] !== undefined) {
    queryParameters['auditTime'] = parameters['auditTime']
  }
  if (parameters['status'] !== undefined) {
    queryParameters['status'] = parameters['status']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章
 * request: yljgArticleAdd
 * formData: yljgArticleAddFormData
 * url: yljgArticleAdd_URL
 * method: yljgArticleAdd_TYPE
 * raw_url: yljgArticleAdd_RAW_URL
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const yljgArticleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArticleAddFormData = function(formData) {
  const url = yljgArticleAdd_RAW_URL()
  const method = yljgArticleAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleAdd_RAW_URL = function() {
  return '/yljg/article'
}
export const yljgArticleAdd_TYPE = function() {
  return 'post'
}
export const yljgArticleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上传图片
 * request: yljgArticleUploadFile
 * formData: yljgArticleUploadFileFormData
 * url: yljgArticleUploadFile_URL
 * method: yljgArticleUploadFile_TYPE
 * raw_url: yljgArticleUploadFile_RAW_URL
 * @param file - 
 * @param image - 
 */
export const yljgArticleUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArticleUploadFileFormData = function(formData) {
  const url = yljgArticleUploadFile_RAW_URL()
  const method = yljgArticleUploadFile_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleUploadFile_RAW_URL = function() {
  return '/yljg/article/file'
}
export const yljgArticleUploadFile_TYPE = function() {
  return 'post'
}
export const yljgArticleUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/file'
  if (parameters['image'] !== undefined) {
    queryParameters['image'] = parameters['image']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章预警关联列表
 * request: yljgArticleEarlyWarningList
 * formData: yljgArticleEarlyWarningListFormData
 * url: yljgArticleEarlyWarningList_URL
 * method: yljgArticleEarlyWarningList_TYPE
 * raw_url: yljgArticleEarlyWarningList_RAW_URL
 * @param articleId - articleId
 */
export const yljgArticleEarlyWarningList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleEarlyWarningListFormData = function(formData) {
  const url = yljgArticleEarlyWarningList_RAW_URL()
  const method = yljgArticleEarlyWarningList_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleEarlyWarningList_RAW_URL = function() {
  return '/yljg/article/{articleId}/early-warning'
}
export const yljgArticleEarlyWarningList_TYPE = function() {
  return 'get'
}
export const yljgArticleEarlyWarningList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加科普文章预警关联
 * request: yljgArticleEarlyWarningAdd
 * formData: yljgArticleEarlyWarningAddFormData
 * url: yljgArticleEarlyWarningAdd_URL
 * method: yljgArticleEarlyWarningAdd_TYPE
 * raw_url: yljgArticleEarlyWarningAdd_RAW_URL
 * @param articleId - articleId
 * @param earlyWarningType - 
 * @param earlyWarningLevel - 
 */
export const yljgArticleEarlyWarningAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{articleId}/early-warning'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  if (parameters['earlyWarningType'] !== undefined) {
    form['earlyWarningType'] = parameters['earlyWarningType']
  }
  if (parameters['earlyWarningType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningType'))
  }
  if (parameters['earlyWarningLevel'] !== undefined) {
    form['earlyWarningLevel'] = parameters['earlyWarningLevel']
  }
  if (parameters['earlyWarningLevel'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: earlyWarningLevel'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgArticleEarlyWarningAddFormData = function(formData) {
  const url = yljgArticleEarlyWarningAdd_RAW_URL()
  const method = yljgArticleEarlyWarningAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleEarlyWarningAdd_RAW_URL = function() {
  return '/yljg/article/{articleId}/early-warning'
}
export const yljgArticleEarlyWarningAdd_TYPE = function() {
  return 'post'
}
export const yljgArticleEarlyWarningAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{articleId}/early-warning'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章预警关联
 * request: yljgArticleEarlyWarningDelete
 * formData: yljgArticleEarlyWarningDeleteFormData
 * url: yljgArticleEarlyWarningDelete_URL
 * method: yljgArticleEarlyWarningDelete_TYPE
 * raw_url: yljgArticleEarlyWarningDelete_RAW_URL
 * @param articleId - articleId
 * @param id - id
 */
export const yljgArticleEarlyWarningDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{articleId}/early-warning/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  if (parameters['articleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: articleId'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgArticleEarlyWarningDeleteFormData = function(formData) {
  const url = yljgArticleEarlyWarningDelete_RAW_URL()
  const method = yljgArticleEarlyWarningDelete_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleEarlyWarningDelete_RAW_URL = function() {
  return '/yljg/article/{articleId}/early-warning/{id}'
}
export const yljgArticleEarlyWarningDelete_TYPE = function() {
  return 'delete'
}
export const yljgArticleEarlyWarningDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{articleId}/early-warning/{id}'
  path = path.replace('{articleId}', `${parameters['articleId']}`)
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章详情
 * request: yljgArticleFind
 * formData: yljgArticleFindFormData
 * url: yljgArticleFind_URL
 * method: yljgArticleFind_TYPE
 * raw_url: yljgArticleFind_RAW_URL
 * @param id - id
 */
export const yljgArticleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleFindFormData = function(formData) {
  const url = yljgArticleFind_RAW_URL()
  const method = yljgArticleFind_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleFind_RAW_URL = function() {
  return '/yljg/article/{id}'
}
export const yljgArticleFind_TYPE = function() {
  return 'get'
}
export const yljgArticleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改科普文章
 * request: yljgArticleUpdate
 * formData: yljgArticleUpdateFormData
 * url: yljgArticleUpdate_URL
 * method: yljgArticleUpdate_TYPE
 * raw_url: yljgArticleUpdate_RAW_URL
 * @param id - id
 * @param categoryId - 
 * @param title - 
 * @param intro - 
 * @param picture - 
 * @param content - 
 * @param readerType - 
 * @param status - 
 * @param label - 
 * @param filePath - 
 */
export const yljgArticleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['categoryId'] !== undefined) {
    form['categoryId'] = parameters['categoryId']
  }
  if (parameters['categoryId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: categoryId'))
  }
  if (parameters['title'] !== undefined) {
    form['title'] = parameters['title']
  }
  if (parameters['title'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: title'))
  }
  if (parameters['intro'] !== undefined) {
    form['intro'] = parameters['intro']
  }
  if (parameters['intro'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: intro'))
  }
  if (parameters['picture'] !== undefined) {
    form['picture'] = parameters['picture']
  }
  if (parameters['picture'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: picture'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['readerType'] !== undefined) {
    form['readerType'] = parameters['readerType']
  }
  if (parameters['readerType'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: readerType'))
  }
  if (parameters['status'] !== undefined) {
    form['status'] = parameters['status']
  }
  if (parameters['status'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: status'))
  }
  if (parameters['label'] !== undefined) {
    form['label'] = parameters['label']
  }
  if (parameters['label'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: label'))
  }
  if (parameters['filePath'] !== undefined) {
    form['filePath'] = parameters['filePath']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgArticleUpdateFormData = function(formData) {
  const url = yljgArticleUpdate_RAW_URL()
  const method = yljgArticleUpdate_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleUpdate_RAW_URL = function() {
  return '/yljg/article/{id}'
}
export const yljgArticleUpdate_TYPE = function() {
  return 'put'
}
export const yljgArticleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除科普文章
 * request: yljgArticleDelete
 * formData: yljgArticleDeleteFormData
 * url: yljgArticleDelete_URL
 * method: yljgArticleDelete_TYPE
 * raw_url: yljgArticleDelete_RAW_URL
 * @param id - id
 */
export const yljgArticleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgArticleDeleteFormData = function(formData) {
  const url = yljgArticleDelete_RAW_URL()
  const method = yljgArticleDelete_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleDelete_RAW_URL = function() {
  return '/yljg/article/{id}'
}
export const yljgArticleDelete_TYPE = function() {
  return 'delete'
}
export const yljgArticleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 草稿科普文章
 * request: yljgArticleDraft
 * formData: yljgArticleDraftFormData
 * url: yljgArticleDraft_URL
 * method: yljgArticleDraft_TYPE
 * raw_url: yljgArticleDraft_RAW_URL
 * @param id - id
 */
export const yljgArticleDraft = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/draft'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgArticleDraftFormData = function(formData) {
  const url = yljgArticleDraft_RAW_URL()
  const method = yljgArticleDraft_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleDraft_RAW_URL = function() {
  return '/yljg/article/{id}/draft'
}
export const yljgArticleDraft_TYPE = function() {
  return 'put'
}
export const yljgArticleDraft_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/draft'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 下架科普文章
 * request: yljgArticleLowerShelf
 * formData: yljgArticleLowerShelfFormData
 * url: yljgArticleLowerShelf_URL
 * method: yljgArticleLowerShelf_TYPE
 * raw_url: yljgArticleLowerShelf_RAW_URL
 * @param id - id
 */
export const yljgArticleLowerShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/lower-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgArticleLowerShelfFormData = function(formData) {
  const url = yljgArticleLowerShelf_RAW_URL()
  const method = yljgArticleLowerShelf_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleLowerShelf_RAW_URL = function() {
  return '/yljg/article/{id}/lower-shelf'
}
export const yljgArticleLowerShelf_TYPE = function() {
  return 'put'
}
export const yljgArticleLowerShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/lower-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 发布科普文章
 * request: yljgArticlePublish
 * formData: yljgArticlePublishFormData
 * url: yljgArticlePublish_URL
 * method: yljgArticlePublish_TYPE
 * raw_url: yljgArticlePublish_RAW_URL
 * @param id - id
 */
export const yljgArticlePublish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/publish'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgArticlePublishFormData = function(formData) {
  const url = yljgArticlePublish_RAW_URL()
  const method = yljgArticlePublish_TYPE()
  return axios[method](url, formData)
}
export const yljgArticlePublish_RAW_URL = function() {
  return '/yljg/article/{id}/publish'
}
export const yljgArticlePublish_TYPE = function() {
  return 'put'
}
export const yljgArticlePublish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/publish'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章阅读学校统计
 * request: yljgArticleReadSchoolStats
 * formData: yljgArticleReadSchoolStatsFormData
 * url: yljgArticleReadSchoolStats_URL
 * method: yljgArticleReadSchoolStats_TYPE
 * raw_url: yljgArticleReadSchoolStats_RAW_URL
 * @param id - id
 */
export const yljgArticleReadSchoolStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/read-school-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleReadSchoolStatsFormData = function(formData) {
  const url = yljgArticleReadSchoolStats_RAW_URL()
  const method = yljgArticleReadSchoolStats_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleReadSchoolStats_RAW_URL = function() {
  return '/yljg/article/{id}/read-school-stats'
}
export const yljgArticleReadSchoolStats_TYPE = function() {
  return 'get'
}
export const yljgArticleReadSchoolStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/read-school-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章未阅读学校
 * request: yljgArticleUnreadSchool
 * formData: yljgArticleUnreadSchoolFormData
 * url: yljgArticleUnreadSchool_URL
 * method: yljgArticleUnreadSchool_TYPE
 * raw_url: yljgArticleUnreadSchool_RAW_URL
 * @param id - id
 */
export const yljgArticleUnreadSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/unread-school'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleUnreadSchoolFormData = function(formData) {
  const url = yljgArticleUnreadSchool_RAW_URL()
  const method = yljgArticleUnreadSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleUnreadSchool_RAW_URL = function() {
  return '/yljg/article/{id}/unread-school'
}
export const yljgArticleUnreadSchool_TYPE = function() {
  return 'get'
}
export const yljgArticleUnreadSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/unread-school'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 上架科普文章
 * request: yljgArticleUpperShelf
 * formData: yljgArticleUpperShelfFormData
 * url: yljgArticleUpperShelf_URL
 * method: yljgArticleUpperShelf_TYPE
 * raw_url: yljgArticleUpperShelf_RAW_URL
 * @param id - id
 */
export const yljgArticleUpperShelf = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/upper-shelf'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgArticleUpperShelfFormData = function(formData) {
  const url = yljgArticleUpperShelf_RAW_URL()
  const method = yljgArticleUpperShelf_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleUpperShelf_RAW_URL = function() {
  return '/yljg/article/{id}/upper-shelf'
}
export const yljgArticleUpperShelf_TYPE = function() {
  return 'put'
}
export const yljgArticleUpperShelf_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/upper-shelf'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 科普文章1周内用户阅读统计
 * request: yljgArticleReadWeekUserStats
 * formData: yljgArticleReadWeekUserStatsFormData
 * url: yljgArticleReadWeekUserStats_URL
 * method: yljgArticleReadWeekUserStats_TYPE
 * raw_url: yljgArticleReadWeekUserStats_RAW_URL
 * @param id - id
 */
export const yljgArticleReadWeekUserStats = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/article/{id}/week-user-read-stats'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgArticleReadWeekUserStatsFormData = function(formData) {
  const url = yljgArticleReadWeekUserStats_RAW_URL()
  const method = yljgArticleReadWeekUserStats_TYPE()
  return axios[method](url, formData)
}
export const yljgArticleReadWeekUserStats_RAW_URL = function() {
  return '/yljg/article/{id}/week-user-read-stats'
}
export const yljgArticleReadWeekUserStats_TYPE = function() {
  return 'get'
}
export const yljgArticleReadWeekUserStats_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/article/{id}/week-user-read-stats'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 档案列表
 * request: yljgDataFileList
 * formData: yljgDataFileListFormData
 * url: yljgDataFileList_URL
 * method: yljgDataFileList_TYPE
 * raw_url: yljgDataFileList_RAW_URL
 * @param regionPersonId - 用户id
 * @param name - 档案名称
 * @param personName - 用户姓名
 * @param personSex - 用户性别
 * @param startDateMills - 建档起始时间
 * @param endDateMills - 建档结束时间
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgDataFileList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['personName'] !== undefined) {
    queryParameters['personName'] = parameters['personName']
  }
  if (parameters['personSex'] !== undefined) {
    queryParameters['personSex'] = parameters['personSex']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileListFormData = function(formData) {
  const url = yljgDataFileList_RAW_URL()
  const method = yljgDataFileList_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileList_RAW_URL = function() {
  return '/yljg/data-file'
}
export const yljgDataFileList_TYPE = function() {
  return 'get'
}
export const yljgDataFileList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file'
  if (parameters['regionPersonId'] !== undefined) {
    queryParameters['regionPersonId'] = parameters['regionPersonId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['personName'] !== undefined) {
    queryParameters['personName'] = parameters['personName']
  }
  if (parameters['personSex'] !== undefined) {
    queryParameters['personSex'] = parameters['personSex']
  }
  if (parameters['startDateMills'] !== undefined) {
    queryParameters['startDateMills'] = parameters['startDateMills']
  }
  if (parameters['endDateMills'] !== undefined) {
    queryParameters['endDateMills'] = parameters['endDateMills']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 新增档案
 * request: yljgDataFileSave
 * formData: yljgDataFileSaveFormData
 * url: yljgDataFileSave_URL
 * method: yljgDataFileSave_TYPE
 * raw_url: yljgDataFileSave_RAW_URL
 * @param vo - vo
 */
export const yljgDataFileSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileSaveFormData = function(formData) {
  const url = yljgDataFileSave_RAW_URL()
  const method = yljgDataFileSave_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileSave_RAW_URL = function() {
  return '/yljg/data-file'
}
export const yljgDataFileSave_TYPE = function() {
  return 'post'
}
export const yljgDataFileSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 单图上传
 * request: yljgDataFileUploadFile
 * formData: yljgDataFileUploadFileFormData
 * url: yljgDataFileUploadFile_URL
 * method: yljgDataFileUploadFile_TYPE
 * raw_url: yljgDataFileUploadFile_RAW_URL
 * @param file - 
 */
export const yljgDataFileUploadFile = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/file'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['file'] !== undefined) {
    body = parameters['file']
  }
  if (parameters['file'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: file'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileUploadFileFormData = function(formData) {
  const url = yljgDataFileUploadFile_RAW_URL()
  const method = yljgDataFileUploadFile_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileUploadFile_RAW_URL = function() {
  return '/yljg/data-file/file'
}
export const yljgDataFileUploadFile_TYPE = function() {
  return 'post'
}
export const yljgDataFileUploadFile_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/file'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 多图上传
 * request: yljgDataFileUploadFiles
 * formData: yljgDataFileUploadFilesFormData
 * url: yljgDataFileUploadFiles_URL
 * method: yljgDataFileUploadFiles_TYPE
 * raw_url: yljgDataFileUploadFiles_RAW_URL
 * @param files - 
 */
export const yljgDataFileUploadFiles = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/files'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['files'] !== undefined) {
    body = parameters['files']
  }
  if (parameters['files'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: files'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileUploadFilesFormData = function(formData) {
  const url = yljgDataFileUploadFiles_RAW_URL()
  const method = yljgDataFileUploadFiles_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileUploadFiles_RAW_URL = function() {
  return '/yljg/data-file/files'
}
export const yljgDataFileUploadFiles_TYPE = function() {
  return 'post'
}
export const yljgDataFileUploadFiles_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/files'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 模板列表
 * request: YljgDataFileFplList
 * formData: YljgDataFileFplListFormData
 * url: YljgDataFileFplList_URL
 * method: YljgDataFileFplList_TYPE
 * raw_url: YljgDataFileFplList_RAW_URL
 */
export const YljgDataFileFplList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/fpl-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const YljgDataFileFplListFormData = function(formData) {
  const url = YljgDataFileFplList_RAW_URL()
  const method = YljgDataFileFplList_TYPE()
  return axios[method](url, formData)
}
export const YljgDataFileFplList_RAW_URL = function() {
  return '/yljg/data-file/fpl-list'
}
export const YljgDataFileFplList_TYPE = function() {
  return 'get'
}
export const YljgDataFileFplList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/fpl-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改档案
 * request: yljgDataFileUpdate
 * formData: yljgDataFileUpdateFormData
 * url: yljgDataFileUpdate_URL
 * method: yljgDataFileUpdate_TYPE
 * raw_url: yljgDataFileUpdate_RAW_URL
 * @param vo - vo
 * @param id - id
 */
export const yljgDataFileUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/{id}'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileUpdateFormData = function(formData) {
  const url = yljgDataFileUpdate_RAW_URL()
  const method = yljgDataFileUpdate_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileUpdate_RAW_URL = function() {
  return '/yljg/data-file/{id}'
}
export const yljgDataFileUpdate_TYPE = function() {
  return 'put'
}
export const yljgDataFileUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看档案
 * request: yljgDataFileCheck
 * formData: yljgDataFileCheckFormData
 * url: yljgDataFileCheck_URL
 * method: yljgDataFileCheck_TYPE
 * raw_url: yljgDataFileCheck_RAW_URL
 * @param id - id
 * @param cityCode - 市编码
 */
export const yljgDataFileCheck = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/{id}/check'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileCheckFormData = function(formData) {
  const url = yljgDataFileCheck_RAW_URL()
  const method = yljgDataFileCheck_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileCheck_RAW_URL = function() {
  return '/yljg/data-file/{id}/check'
}
export const yljgDataFileCheck_TYPE = function() {
  return 'get'
}
export const yljgDataFileCheck_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/{id}/check'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['cityCode'] !== undefined) {
    queryParameters['cityCode'] = parameters['cityCode']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 最近是否有建新档案
 * request: yljgDataFileRecently
 * formData: yljgDataFileRecentlyFormData
 * url: yljgDataFileRecently_URL
 * method: yljgDataFileRecently_TYPE
 * raw_url: yljgDataFileRecently_RAW_URL
 * @param personId - personId
 */
export const yljgDataFileRecently = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/data-file/{personId}/recently'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgDataFileRecentlyFormData = function(formData) {
  const url = yljgDataFileRecently_RAW_URL()
  const method = yljgDataFileRecently_TYPE()
  return axios[method](url, formData)
}
export const yljgDataFileRecently_RAW_URL = function() {
  return '/yljg/data-file/{personId}/recently'
}
export const yljgDataFileRecently_TYPE = function() {
  return 'put'
}
export const yljgDataFileRecently_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/data-file/{personId}/recently'
  path = path.replace('{personId}', `${parameters['personId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * ICD10编码选择列表
 * request: yljgIcd10SelectList
 * formData: yljgIcd10SelectListFormData
 * url: yljgIcd10SelectList_URL
 * method: yljgIcd10SelectList_TYPE
 * raw_url: yljgIcd10SelectList_RAW_URL
 */
export const yljgIcd10SelectList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/icd10/select'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgIcd10SelectListFormData = function(formData) {
  const url = yljgIcd10SelectList_RAW_URL()
  const method = yljgIcd10SelectList_TYPE()
  return axios[method](url, formData)
}
export const yljgIcd10SelectList_RAW_URL = function() {
  return '/yljg/icd10/select'
}
export const yljgIcd10SelectList_TYPE = function() {
  return 'get'
}
export const yljgIcd10SelectList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/icd10/select'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构模板列表
 * request: YljgMedicalInstitutionDataFileFplList
 * formData: YljgMedicalInstitutionDataFileFplListFormData
 * url: YljgMedicalInstitutionDataFileFplList_URL
 * method: YljgMedicalInstitutionDataFileFplList_TYPE
 * raw_url: YljgMedicalInstitutionDataFileFplList_RAW_URL
 */
export const YljgMedicalInstitutionDataFileFplList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/medical-institution-data-file-fpl'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const YljgMedicalInstitutionDataFileFplListFormData = function(formData) {
  const url = YljgMedicalInstitutionDataFileFplList_RAW_URL()
  const method = YljgMedicalInstitutionDataFileFplList_TYPE()
  return axios[method](url, formData)
}
export const YljgMedicalInstitutionDataFileFplList_RAW_URL = function() {
  return '/yljg/medical-institution-data-file-fpl'
}
export const YljgMedicalInstitutionDataFileFplList_TYPE = function() {
  return 'get'
}
export const YljgMedicalInstitutionDataFileFplList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/medical-institution-data-file-fpl'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构保存模板
 * request: YljgDataFileTplSave
 * formData: YljgDataFileTplSaveFormData
 * url: YljgDataFileTplSave_URL
 * method: YljgDataFileTplSave_TYPE
 * raw_url: YljgDataFileTplSave_RAW_URL
 * @param vo - vo
 */
export const YljgDataFileTplSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/medical-institution-data-file-fpl'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const YljgDataFileTplSaveFormData = function(formData) {
  const url = YljgDataFileTplSave_RAW_URL()
  const method = YljgDataFileTplSave_TYPE()
  return axios[method](url, formData)
}
export const YljgDataFileTplSave_RAW_URL = function() {
  return '/yljg/medical-institution-data-file-fpl'
}
export const YljgDataFileTplSave_TYPE = function() {
  return 'post'
}
export const YljgDataFileTplSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/medical-institution-data-file-fpl'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构可选数据文档模板列表
 * request: YljgDataFileTplList
 * formData: YljgDataFileTplListFormData
 * url: YljgDataFileTplList_URL
 * method: YljgDataFileTplList_TYPE
 * raw_url: YljgDataFileTplList_RAW_URL
 */
export const YljgDataFileTplList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/medical-institution-data-file-fpl/data_file_tpl_list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const YljgDataFileTplListFormData = function(formData) {
  const url = YljgDataFileTplList_RAW_URL()
  const method = YljgDataFileTplList_TYPE()
  return axios[method](url, formData)
}
export const YljgDataFileTplList_RAW_URL = function() {
  return '/yljg/medical-institution-data-file-fpl/data_file_tpl_list'
}
export const YljgDataFileTplList_TYPE = function() {
  return 'get'
}
export const YljgDataFileTplList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/medical-institution-data-file-fpl/data_file_tpl_list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构删除模板
 * request: YljgxDataFileTplDelete
 * formData: YljgxDataFileTplDeleteFormData
 * url: YljgxDataFileTplDelete_URL
 * method: YljgxDataFileTplDelete_TYPE
 * raw_url: YljgxDataFileTplDelete_RAW_URL
 * @param id - id
 */
export const YljgxDataFileTplDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/medical-institution-data-file-fpl/{id}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const YljgxDataFileTplDeleteFormData = function(formData) {
  const url = YljgxDataFileTplDelete_RAW_URL()
  const method = YljgxDataFileTplDelete_TYPE()
  return axios[method](url, formData)
}
export const YljgxDataFileTplDelete_RAW_URL = function() {
  return '/yljg/medical-institution-data-file-fpl/{id}'
}
export const YljgxDataFileTplDelete_TYPE = function() {
  return 'delete'
}
export const YljgxDataFileTplDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/medical-institution-data-file-fpl/{id}'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 医疗机构文档模板启用禁用
 * request: YljgDataFileTplOnOff
 * formData: YljgDataFileTplOnOffFormData
 * url: YljgDataFileTplOnOff_URL
 * method: YljgDataFileTplOnOff_TYPE
 * raw_url: YljgDataFileTplOnOff_RAW_URL
 * @param id - id
 */
export const YljgDataFileTplOnOff = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/medical-institution-data-file-fpl/{id}/on_off'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters['id'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: id'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const YljgDataFileTplOnOffFormData = function(formData) {
  const url = YljgDataFileTplOnOff_RAW_URL()
  const method = YljgDataFileTplOnOff_TYPE()
  return axios[method](url, formData)
}
export const YljgDataFileTplOnOff_RAW_URL = function() {
  return '/yljg/medical-institution-data-file-fpl/{id}/on_off'
}
export const YljgDataFileTplOnOff_TYPE = function() {
  return 'get'
}
export const YljgDataFileTplOnOff_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/medical-institution-data-file-fpl/{id}/on_off'
  path = path.replace('{id}', `${parameters['id']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取中学学生健康状况及影响因素调查表
 * request: yljgMshiqFindByScreeningSchool
 * formData: yljgMshiqFindByScreeningSchoolFormData
 * url: yljgMshiqFindByScreeningSchool_URL
 * method: yljgMshiqFindByScreeningSchool_TYPE
 * raw_url: yljgMshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgMshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/mshiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgMshiqFindByScreeningSchoolFormData = function(formData) {
  const url = yljgMshiqFindByScreeningSchool_RAW_URL()
  const method = yljgMshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgMshiqFindByScreeningSchool_RAW_URL = function() {
  return '/yljg/mshiq/by-screeningSchool/{screeningSchoolId}'
}
export const yljgMshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const yljgMshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/mshiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看中学学生健康状况及影响因素调查表
 * request: yljgMshiqFindByStudent
 * formData: yljgMshiqFindByStudentFormData
 * url: yljgMshiqFindByStudent_URL
 * method: yljgMshiqFindByStudent_TYPE
 * raw_url: yljgMshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const yljgMshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/mshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgMshiqFindByStudentFormData = function(formData) {
  const url = yljgMshiqFindByStudent_RAW_URL()
  const method = yljgMshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgMshiqFindByStudent_RAW_URL = function() {
  return '/yljg/mshiq/by-student/{studentId}'
}
export const yljgMshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const yljgMshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/mshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存中学学生健康状况及影响因素调查表
 * request: yljgMshiqSave
 * formData: yljgMshiqSaveFormData
 * url: yljgMshiqSave_URL
 * method: yljgMshiqSave_TYPE
 * raw_url: yljgMshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b09 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 
 * @param d06Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d07 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d08 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d081 - 每次服用止咳药水的量是多少？
 * @param d09 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f01AgeOfFirst - 当F01的值为2时必填，反之不填
 * @param f02 - 当F01的值为2时必填，反之不填.过去7天，你平均每天使用耳机分钟数 --> 时间选项
 * @param f03 - 当F01的值为2时必填，反之不填
 * @param f04 - 当F01的值为2时必填，反之不填
 * @param f05 - 当F01的值为2时必填，反之不填
 * @param g01 - 
 * @param g02 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 
 * @param h05 - 
 * @param h06 - 
 * @param h07 - 
 * @param h08 - 
 * @param h09 - 
 * @param h10 - 
 * @param h11 - 
 * @param h12 - 
 * @param h13 - 
 * @param h14 - 
 * @param h15 - 
 * @param h16 - 
 * @param h17 - 
 * @param h18 - 
 * @param h19 - 
 * @param h20 - 
 * @param h21 - 感觉紧张，焦虑或急切
 * @param h22 - 不能够停止或控制担忧
 * @param h23 - 对各种恶样的事情担忧过多
 * @param h24 - 很难放松下来
 * @param h25 - 由于不安而无法静坐
 * @param h26 - 变得容易烦恼或急躁
 * @param h27 - 感到似乎将有可怕的事情发生而害怕
 * @param h28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param h281 - 你寻求了什么形式的帮助
 * @param h282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param i01 - 
 * @param i011 - 
 * @param i02 - 
 * @param i021 - 
 * @param i03 - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 * @param i09 - 
 */
export const yljgMshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/mshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b09'] !== undefined) {
    form['b09'] = parameters['b09']
  }
  if (parameters['b09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b09'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d06'))
  }
  if (parameters['d06Description'] !== undefined) {
    form['d06Description'] = parameters['d06Description']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d07'))
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d08'))
  }
  if (parameters['d081'] !== undefined) {
    form['d081'] = parameters['d081']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f01AgeOfFirst'] !== undefined) {
    form['f01AgeOfFirst'] = parameters['f01AgeOfFirst']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h04'))
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h05'))
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h06'))
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h07'))
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['h08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h08'))
  }
  if (parameters['h09'] !== undefined) {
    form['h09'] = parameters['h09']
  }
  if (parameters['h09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h09'))
  }
  if (parameters['h10'] !== undefined) {
    form['h10'] = parameters['h10']
  }
  if (parameters['h10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h10'))
  }
  if (parameters['h11'] !== undefined) {
    form['h11'] = parameters['h11']
  }
  if (parameters['h11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h11'))
  }
  if (parameters['h12'] !== undefined) {
    form['h12'] = parameters['h12']
  }
  if (parameters['h12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h12'))
  }
  if (parameters['h13'] !== undefined) {
    form['h13'] = parameters['h13']
  }
  if (parameters['h13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h13'))
  }
  if (parameters['h14'] !== undefined) {
    form['h14'] = parameters['h14']
  }
  if (parameters['h14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h14'))
  }
  if (parameters['h15'] !== undefined) {
    form['h15'] = parameters['h15']
  }
  if (parameters['h15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h15'))
  }
  if (parameters['h16'] !== undefined) {
    form['h16'] = parameters['h16']
  }
  if (parameters['h16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h16'))
  }
  if (parameters['h17'] !== undefined) {
    form['h17'] = parameters['h17']
  }
  if (parameters['h17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h17'))
  }
  if (parameters['h18'] !== undefined) {
    form['h18'] = parameters['h18']
  }
  if (parameters['h18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h18'))
  }
  if (parameters['h19'] !== undefined) {
    form['h19'] = parameters['h19']
  }
  if (parameters['h19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h19'))
  }
  if (parameters['h20'] !== undefined) {
    form['h20'] = parameters['h20']
  }
  if (parameters['h20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h20'))
  }
  if (parameters['h21'] !== undefined) {
    form['h21'] = parameters['h21']
  }
  if (parameters['h21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h21'))
  }
  if (parameters['h22'] !== undefined) {
    form['h22'] = parameters['h22']
  }
  if (parameters['h22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h22'))
  }
  if (parameters['h23'] !== undefined) {
    form['h23'] = parameters['h23']
  }
  if (parameters['h23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h23'))
  }
  if (parameters['h24'] !== undefined) {
    form['h24'] = parameters['h24']
  }
  if (parameters['h24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h24'))
  }
  if (parameters['h25'] !== undefined) {
    form['h25'] = parameters['h25']
  }
  if (parameters['h25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h25'))
  }
  if (parameters['h26'] !== undefined) {
    form['h26'] = parameters['h26']
  }
  if (parameters['h26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h26'))
  }
  if (parameters['h27'] !== undefined) {
    form['h27'] = parameters['h27']
  }
  if (parameters['h27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h27'))
  }
  if (parameters['h28'] !== undefined) {
    form['h28'] = parameters['h28']
  }
  if (parameters['h28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h28'))
  }
  if (parameters['h281'] !== undefined) {
    form['h281'] = parameters['h281']
  }
  if (parameters['h282'] !== undefined) {
    form['h282'] = parameters['h282']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i011'] !== undefined) {
    form['i011'] = parameters['i011']
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i021'] !== undefined) {
    form['i021'] = parameters['i021']
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['i09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgMshiqSaveFormData = function(formData) {
  const url = yljgMshiqSave_RAW_URL()
  const method = yljgMshiqSave_TYPE()
  return axios[method](url, formData)
}
export const yljgMshiqSave_RAW_URL = function() {
  return '/yljg/mshiq/save/{studentId}'
}
export const yljgMshiqSave_TYPE = function() {
  return 'post'
}
export const yljgMshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/mshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取小学学生健康状况及影响因素调查表
 * request: yljgPshiqFindByScreeningSchool
 * formData: yljgPshiqFindByScreeningSchoolFormData
 * url: yljgPshiqFindByScreeningSchool_URL
 * method: yljgPshiqFindByScreeningSchool_TYPE
 * raw_url: yljgPshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgPshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/pshiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgPshiqFindByScreeningSchoolFormData = function(formData) {
  const url = yljgPshiqFindByScreeningSchool_RAW_URL()
  const method = yljgPshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgPshiqFindByScreeningSchool_RAW_URL = function() {
  return '/yljg/pshiq/by-screeningSchool/{screeningSchoolId}'
}
export const yljgPshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const yljgPshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/pshiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看小学学生健康状况及影响因素调查表
 * request: yljgPshiqFindByStudent
 * formData: yljgPshiqFindByStudentFormData
 * url: yljgPshiqFindByStudent_URL
 * method: yljgPshiqFindByStudent_TYPE
 * raw_url: yljgPshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const yljgPshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/pshiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgPshiqFindByStudentFormData = function(formData) {
  const url = yljgPshiqFindByStudent_RAW_URL()
  const method = yljgPshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgPshiqFindByStudent_RAW_URL = function() {
  return '/yljg/pshiq/by-student/{studentId}'
}
export const yljgPshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const yljgPshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/pshiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存小学学生健康状况及影响因素调查表
 * request: yljgPshiqSave
 * formData: yljgPshiqSaveFormData
 * url: yljgPshiqSave_URL
 * method: yljgPshiqSave_TYPE
 * raw_url: yljgPshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 过去一年内，你是否因生病休学几个月
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08 - 
 * @param b10 - 过去7天里，你通常每天喝牛奶、酸奶、豆浆或豆奶的天数？
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c03 - 
 * @param c04 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c06Times - 当C06的值为2时必填，反之不填
 * @param c07 - 是否有人通过电子传媒恶意取笑、谩骂、威胁、恐吓或者散布有关你的谣言、影像或视频等？
 * @param c08 - 请回忆一下，你持续受到欺负多长时间了？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填.第一次尝试吸烟的年龄？
 * @param d03 - 
 * @param d04 - 
 * @param d04AgeOfFirst - 当D04的值为1时必填，反之不填
 * @param e01 - 
 * @param e01AgeOfFirst - 当E01的值为2时必填，反之不填
 * @param e02 - 当E01的值为2时必填，反之不填.过去7天，你平均每天使用耳机时间数选项
 * @param e03 - 当E01的值为2时必填，反之不填
 * @param e04 - 当E01的值为2时必填，反之不填
 * @param e05 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f011 - 
 * @param f02 - 
 * @param f021 - 
 * @param f05 - 
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param f09 - 
 */
export const yljgPshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/pshiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a09'))
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08'] !== undefined) {
    form['b08'] = parameters['b08']
  }
  if (parameters['b08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b08'))
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c04'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06Times'] !== undefined) {
    form['c06Times'] = parameters['c06Times']
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d04AgeOfFirst'] !== undefined) {
    form['d04AgeOfFirst'] = parameters['d04AgeOfFirst']
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e01AgeOfFirst'] !== undefined) {
    form['e01AgeOfFirst'] = parameters['e01AgeOfFirst']
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e05'] !== undefined) {
    form['e05'] = parameters['e05']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f011'] !== undefined) {
    form['f011'] = parameters['f011']
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f021'] !== undefined) {
    form['f021'] = parameters['f021']
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['f09'] !== undefined) {
    form['f09'] = parameters['f09']
  }
  if (parameters['f09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f09'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgPshiqSaveFormData = function(formData) {
  const url = yljgPshiqSave_RAW_URL()
  const method = yljgPshiqSave_TYPE()
  return axios[method](url, formData)
}
export const yljgPshiqSave_RAW_URL = function() {
  return '/yljg/pshiq/save/{studentId}'
}
export const yljgPshiqSave_TYPE = function() {
  return 'post'
}
export const yljgPshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/pshiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加地区受检人
 * request: yljgRegionPersonAdd
 * formData: yljgRegionPersonAddFormData
 * url: yljgRegionPersonAdd_URL
 * method: yljgRegionPersonAdd_TYPE
 * raw_url: yljgRegionPersonAdd_RAW_URL
 * @param name - 
 * @param sex - 
 * @param birth - 
 * @param idCard - 
 * @param schoolId - 
 * @param schoolType - 
 */
export const yljgRegionPersonAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-person'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['sex'] !== undefined) {
    form['sex'] = parameters['sex']
  }
  if (parameters['sex'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: sex'))
  }
  if (parameters['birth'] !== undefined) {
    form['birth'] = parameters['birth']
  }
  if (parameters['birth'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: birth'))
  }
  if (parameters['idCard'] !== undefined) {
    form['idCard'] = parameters['idCard']
  }
  if (parameters['idCard'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: idCard'))
  }
  if (parameters['schoolId'] !== undefined) {
    form['schoolId'] = parameters['schoolId']
  }
  if (parameters['schoolType'] !== undefined) {
    form['schoolType'] = parameters['schoolType']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgRegionPersonAddFormData = function(formData) {
  const url = yljgRegionPersonAdd_RAW_URL()
  const method = yljgRegionPersonAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgRegionPersonAdd_RAW_URL = function() {
  return '/yljg/region-person'
}
export const yljgRegionPersonAdd_TYPE = function() {
  return 'post'
}
export const yljgRegionPersonAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-person'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 受检人访问列表
 * request: yljgPersonVisitList
 * formData: yljgPersonVisitListFormData
 * url: yljgPersonVisitList_URL
 * method: yljgPersonVisitList_TYPE
 * raw_url: yljgPersonVisitList_RAW_URL
 * @param personId - 受检人ID
 * @param startDate - 开始日期
 * @param endDate - 结束日期
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgPersonVisitList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-visit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters['startDate'] !== undefined) {
    queryParameters['startDate'] = parameters['startDate']
  }
  if (parameters['endDate'] !== undefined) {
    queryParameters['endDate'] = parameters['endDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgPersonVisitListFormData = function(formData) {
  const url = yljgPersonVisitList_RAW_URL()
  const method = yljgPersonVisitList_TYPE()
  return axios[method](url, formData)
}
export const yljgPersonVisitList_RAW_URL = function() {
  return '/yljg/region-visit'
}
export const yljgPersonVisitList_TYPE = function() {
  return 'get'
}
export const yljgPersonVisitList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-visit'
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['startDate'] !== undefined) {
    queryParameters['startDate'] = parameters['startDate']
  }
  if (parameters['endDate'] !== undefined) {
    queryParameters['endDate'] = parameters['endDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 受检人访问添加
 * request: yljgPersonVisitAdd
 * formData: yljgPersonVisitAddFormData
 * url: yljgPersonVisitAdd_URL
 * method: yljgPersonVisitAdd_TYPE
 * raw_url: yljgPersonVisitAdd_RAW_URL
 * @param personId - 受检人ID
 * @param content - 内容
 * @param date - 日期
 */
export const yljgPersonVisitAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-visit'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['content'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgPersonVisitAddFormData = function(formData) {
  const url = yljgPersonVisitAdd_RAW_URL()
  const method = yljgPersonVisitAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgPersonVisitAdd_RAW_URL = function() {
  return '/yljg/region-visit'
}
export const yljgPersonVisitAdd_TYPE = function() {
  return 'post'
}
export const yljgPersonVisitAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-visit'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 获取受检人访问
 * request: yljgPersonVisitFind
 * formData: yljgPersonVisitFindFormData
 * url: yljgPersonVisitFind_URL
 * method: yljgPersonVisitFind_TYPE
 * raw_url: yljgPersonVisitFind_RAW_URL
 * @param personVisitId - personVisitId
 * @param personId - 受检人ID
 */
export const yljgPersonVisitFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-visit/{personVisitId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personVisitId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personVisitId'))
  }
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgPersonVisitFindFormData = function(formData) {
  const url = yljgPersonVisitFind_RAW_URL()
  const method = yljgPersonVisitFind_TYPE()
  return axios[method](url, formData)
}
export const yljgPersonVisitFind_RAW_URL = function() {
  return '/yljg/region-visit/{personVisitId}'
}
export const yljgPersonVisitFind_TYPE = function() {
  return 'get'
}
export const yljgPersonVisitFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-visit/{personVisitId}'
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 受检人访问修改
 * request: yljgPersonVisitUpdate
 * formData: yljgPersonVisitUpdateFormData
 * url: yljgPersonVisitUpdate_URL
 * method: yljgPersonVisitUpdate_TYPE
 * raw_url: yljgPersonVisitUpdate_RAW_URL
 * @param personVisitId - personVisitId
 * @param personId - 受检人ID
 * @param content - 内容
 * @param date - 日期
 */
export const yljgPersonVisitUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-visit/{personVisitId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personVisitId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personVisitId'))
  }
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters['content'] !== undefined) {
    form['content'] = parameters['content']
  }
  if (parameters['content'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: content'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgPersonVisitUpdateFormData = function(formData) {
  const url = yljgPersonVisitUpdate_RAW_URL()
  const method = yljgPersonVisitUpdate_TYPE()
  return axios[method](url, formData)
}
export const yljgPersonVisitUpdate_RAW_URL = function() {
  return '/yljg/region-visit/{personVisitId}'
}
export const yljgPersonVisitUpdate_TYPE = function() {
  return 'put'
}
export const yljgPersonVisitUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-visit/{personVisitId}'
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 受检人访问删除
 * request: yljgPersonVisitDelete
 * formData: yljgPersonVisitDeleteFormData
 * url: yljgPersonVisitDelete_URL
 * method: yljgPersonVisitDelete_TYPE
 * raw_url: yljgPersonVisitDelete_RAW_URL
 * @param personVisitId - personVisitId
 * @param personId - 受检人ID
 */
export const yljgPersonVisitDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/region-visit/{personVisitId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personVisitId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personVisitId'))
  }
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters['personId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: personId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgPersonVisitDeleteFormData = function(formData) {
  const url = yljgPersonVisitDelete_RAW_URL()
  const method = yljgPersonVisitDelete_TYPE()
  return axios[method](url, formData)
}
export const yljgPersonVisitDelete_RAW_URL = function() {
  return '/yljg/region-visit/{personVisitId}'
}
export const yljgPersonVisitDelete_TYPE = function() {
  return 'delete'
}
export const yljgPersonVisitDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/region-visit/{personVisitId}'
  path = path.replace('{personVisitId}', `${parameters['personVisitId']}`)
  if (parameters['personId'] !== undefined) {
    queryParameters['personId'] = parameters['personId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存常见病报告
 * request: ylzxReportSaveCommonDiseaseReport
 * formData: ylzxReportSaveCommonDiseaseReportFormData
 * url: ylzxReportSaveCommonDiseaseReport_URL
 * method: ylzxReportSaveCommonDiseaseReport_TYPE
 * raw_url: ylzxReportSaveCommonDiseaseReport_RAW_URL
 * @param personId - 
 * @param deciduousTeethInfo - 
 * @param permanentTeethInfo - 
 * @param deciduousTeethDecayed - 
 * @param deciduousTeethMissing - 
 * @param deciduousTeethFill - 
 * @param permanentTeethDecayed - 
 * @param permanentTeethMissing - 
 * @param permanentTeethFill - 
 * @param height - 
 * @param weight - 
 * @param scoliosis - 脊柱侧弯
 * @param thoracicCurvature - 
 * @param thoracicCurvatureDegree - 
 * @param thoracolumbarCurvature - 
 * @param thoracolumbarCurvatureDegree - 
 * @param lumbarCurvature - 
 * @param lumbarCurvatureDegree - 
 * @param thoracicCurvatureV2 - 
 * @param thoracicCurvatureDegreeV2 - 
 * @param thoracolumbarCurvatureV2 - 
 * @param thoracolumbarCurvatureDegreeV2 - 
 * @param lumbarCurvatureV2 - 
 * @param lumbarCurvatureDegreeV2 - 
 * @param spineMotionTesting - 进行脊柱运动试验
 * @param testingThoracicAtr - 试验胸段ATR
 * @param testingThoracolumbarAtr - 试验腰胸段ATR
 * @param testingLumbarAtr - 试验腰段ATR
 * @param frontBackCurvature - 
 * @param frontBackCurvatureDegree - 
 * @param frontBackCurvatureV2 - 
 * @param bendDownLieExperiment - 
 * @param spinalCurvatureFirstResult - 
 * @param historyOfTheSpine - 
 * @param systolicPressure - 
 * @param diastolicBloodPressure - 
 * @param menstruation - 
 * @param firstMenstruationAge - 
 * @param menstruationFrequency - 
 * @param menstrualDuration - 
 * @param spermatorrhea - 
 * @param firstSpermatorrheaAge - 
 * @param remark - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param workerId - 
 */
export const ylzxReportSaveCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/report/common-disease-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['deciduousTeethInfo'] !== undefined) {
    form['deciduousTeethInfo'] = parameters['deciduousTeethInfo']
  }
  if (parameters['permanentTeethInfo'] !== undefined) {
    form['permanentTeethInfo'] = parameters['permanentTeethInfo']
  }
  if (parameters['deciduousTeethDecayed'] !== undefined) {
    form['deciduousTeethDecayed'] = parameters['deciduousTeethDecayed']
  }
  if (parameters['deciduousTeethMissing'] !== undefined) {
    form['deciduousTeethMissing'] = parameters['deciduousTeethMissing']
  }
  if (parameters['deciduousTeethFill'] !== undefined) {
    form['deciduousTeethFill'] = parameters['deciduousTeethFill']
  }
  if (parameters['permanentTeethDecayed'] !== undefined) {
    form['permanentTeethDecayed'] = parameters['permanentTeethDecayed']
  }
  if (parameters['permanentTeethMissing'] !== undefined) {
    form['permanentTeethMissing'] = parameters['permanentTeethMissing']
  }
  if (parameters['permanentTeethFill'] !== undefined) {
    form['permanentTeethFill'] = parameters['permanentTeethFill']
  }
  if (parameters['height'] !== undefined) {
    form['height'] = parameters['height']
  }
  if (parameters['weight'] !== undefined) {
    form['weight'] = parameters['weight']
  }
  if (parameters['scoliosis'] !== undefined) {
    form['scoliosis'] = parameters['scoliosis']
  }
  if (parameters['thoracicCurvature'] !== undefined) {
    form['thoracicCurvature'] = parameters['thoracicCurvature']
  }
  if (parameters['thoracicCurvatureDegree'] !== undefined) {
    form['thoracicCurvatureDegree'] = parameters['thoracicCurvatureDegree']
  }
  if (parameters['thoracolumbarCurvature'] !== undefined) {
    form['thoracolumbarCurvature'] = parameters['thoracolumbarCurvature']
  }
  if (parameters['thoracolumbarCurvatureDegree'] !== undefined) {
    form['thoracolumbarCurvatureDegree'] = parameters['thoracolumbarCurvatureDegree']
  }
  if (parameters['lumbarCurvature'] !== undefined) {
    form['lumbarCurvature'] = parameters['lumbarCurvature']
  }
  if (parameters['lumbarCurvatureDegree'] !== undefined) {
    form['lumbarCurvatureDegree'] = parameters['lumbarCurvatureDegree']
  }
  if (parameters['thoracicCurvatureV2'] !== undefined) {
    form['thoracicCurvatureV2'] = parameters['thoracicCurvatureV2']
  }
  if (parameters['thoracicCurvatureDegreeV2'] !== undefined) {
    form['thoracicCurvatureDegreeV2'] = parameters['thoracicCurvatureDegreeV2']
  }
  if (parameters['thoracolumbarCurvatureV2'] !== undefined) {
    form['thoracolumbarCurvatureV2'] = parameters['thoracolumbarCurvatureV2']
  }
  if (parameters['thoracolumbarCurvatureDegreeV2'] !== undefined) {
    form['thoracolumbarCurvatureDegreeV2'] = parameters['thoracolumbarCurvatureDegreeV2']
  }
  if (parameters['lumbarCurvatureV2'] !== undefined) {
    form['lumbarCurvatureV2'] = parameters['lumbarCurvatureV2']
  }
  if (parameters['lumbarCurvatureDegreeV2'] !== undefined) {
    form['lumbarCurvatureDegreeV2'] = parameters['lumbarCurvatureDegreeV2']
  }
  if (parameters['spineMotionTesting'] !== undefined) {
    form['spineMotionTesting'] = parameters['spineMotionTesting']
  }
  if (parameters['testingThoracicAtr'] !== undefined) {
    form['testingThoracicATR'] = parameters['testingThoracicAtr']
  }
  if (parameters['testingThoracolumbarAtr'] !== undefined) {
    form['testingThoracolumbarATR'] = parameters['testingThoracolumbarAtr']
  }
  if (parameters['testingLumbarAtr'] !== undefined) {
    form['testingLumbarATR'] = parameters['testingLumbarAtr']
  }
  if (parameters['frontBackCurvature'] !== undefined) {
    form['frontBackCurvature'] = parameters['frontBackCurvature']
  }
  if (parameters['frontBackCurvatureDegree'] !== undefined) {
    form['frontBackCurvatureDegree'] = parameters['frontBackCurvatureDegree']
  }
  if (parameters['frontBackCurvatureV2'] !== undefined) {
    form['frontBackCurvatureV2'] = parameters['frontBackCurvatureV2']
  }
  if (parameters['bendDownLieExperiment'] !== undefined) {
    form['bendDownLieExperiment'] = parameters['bendDownLieExperiment']
  }
  if (parameters['spinalCurvatureFirstResult'] !== undefined) {
    form['spinalCurvatureFirstResult'] = parameters['spinalCurvatureFirstResult']
  }
  if (parameters['historyOfTheSpine'] !== undefined) {
    form['historyOfTheSpine'] = parameters['historyOfTheSpine']
  }
  if (parameters['systolicPressure'] !== undefined) {
    form['systolicPressure'] = parameters['systolicPressure']
  }
  if (parameters['diastolicBloodPressure'] !== undefined) {
    form['diastolicBloodPressure'] = parameters['diastolicBloodPressure']
  }
  if (parameters['menstruation'] !== undefined) {
    form['menstruation'] = parameters['menstruation']
  }
  if (parameters['firstMenstruationAge'] !== undefined) {
    form['firstMenstruationAge'] = parameters['firstMenstruationAge']
  }
  if (parameters['menstruationFrequency'] !== undefined) {
    form['menstruationFrequency'] = parameters['menstruationFrequency']
  }
  if (parameters['menstrualDuration'] !== undefined) {
    form['menstrualDuration'] = parameters['menstrualDuration']
  }
  if (parameters['spermatorrhea'] !== undefined) {
    form['spermatorrhea'] = parameters['spermatorrhea']
  }
  if (parameters['firstSpermatorrheaAge'] !== undefined) {
    form['firstSpermatorrheaAge'] = parameters['firstSpermatorrheaAge']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['workerId'] !== undefined) {
    form['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxReportSaveCommonDiseaseReportFormData = function(formData) {
  const url = ylzxReportSaveCommonDiseaseReport_RAW_URL()
  const method = ylzxReportSaveCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const ylzxReportSaveCommonDiseaseReport_RAW_URL = function() {
  return '/yljg/report/common-disease-report'
}
export const ylzxReportSaveCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const ylzxReportSaveCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/report/common-disease-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存屈光报告
 * request: ylzxReportSaveDiopterReport
 * formData: ylzxReportSaveDiopterReportFormData
 * url: ylzxReportSaveDiopterReport_URL
 * method: ylzxReportSaveDiopterReport_TYPE
 * raw_url: ylzxReportSaveDiopterReport_RAW_URL
 * @param personId - 
 * @param leftDs - 
 * @param leftDc - 
 * @param leftAxis - 
 * @param rightDs - 
 * @param rightDc - 
 * @param rightAxis - 
 * @param remark - 
 * @param otherSpecialCircumstances - 
 * @param byRetest - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param workerId - 
 */
export const ylzxReportSaveDiopterReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/report/diopter-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['leftDs'] !== undefined) {
    form['leftDS'] = parameters['leftDs']
  }
  if (parameters['leftDc'] !== undefined) {
    form['leftDC'] = parameters['leftDc']
  }
  if (parameters['leftAxis'] !== undefined) {
    form['leftAxis'] = parameters['leftAxis']
  }
  if (parameters['rightDs'] !== undefined) {
    form['rightDS'] = parameters['rightDs']
  }
  if (parameters['rightDc'] !== undefined) {
    form['rightDC'] = parameters['rightDc']
  }
  if (parameters['rightAxis'] !== undefined) {
    form['rightAxis'] = parameters['rightAxis']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['otherSpecialCircumstances'] !== undefined) {
    form['otherSpecialCircumstances'] = parameters['otherSpecialCircumstances']
  }
  if (parameters['byRetest'] !== undefined) {
    form['byRetest'] = parameters['byRetest']
  }
  if (parameters['byRetest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: byRetest'))
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['workerId'] !== undefined) {
    form['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxReportSaveDiopterReportFormData = function(formData) {
  const url = ylzxReportSaveDiopterReport_RAW_URL()
  const method = ylzxReportSaveDiopterReport_TYPE()
  return axios[method](url, formData)
}
export const ylzxReportSaveDiopterReport_RAW_URL = function() {
  return '/yljg/report/diopter-report'
}
export const ylzxReportSaveDiopterReport_TYPE = function() {
  return 'post'
}
export const ylzxReportSaveDiopterReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/report/diopter-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存报告
 * request: ylzxReportSaveReport
 * formData: ylzxReportSaveReportFormData
 * url: ylzxReportSaveReport_URL
 * method: ylzxReportSaveReport_TYPE
 * raw_url: ylzxReportSaveReport_RAW_URL
 * @param vo - vo
 */
export const ylzxReportSaveReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/report/report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['vo'] !== undefined) {
    body = parameters['vo']
  }
  if (parameters['vo'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: vo'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxReportSaveReportFormData = function(formData) {
  const url = ylzxReportSaveReport_RAW_URL()
  const method = ylzxReportSaveReport_TYPE()
  return axios[method](url, formData)
}
export const ylzxReportSaveReport_RAW_URL = function() {
  return '/yljg/report/report'
}
export const ylzxReportSaveReport_TYPE = function() {
  return 'post'
}
export const ylzxReportSaveReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/report/report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存复测常见病报告
 * request: ylzxReportSaveRetestCommonDiseaseReport
 * formData: ylzxReportSaveRetestCommonDiseaseReportFormData
 * url: ylzxReportSaveRetestCommonDiseaseReport_URL
 * method: ylzxReportSaveRetestCommonDiseaseReport_TYPE
 * raw_url: ylzxReportSaveRetestCommonDiseaseReport_RAW_URL
 * @param personId - 
 * @param height - 
 * @param weight - 
 * @param remark - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param workerId - 
 */
export const ylzxReportSaveRetestCommonDiseaseReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/report/retest-common-disease-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['height'] !== undefined) {
    form['height'] = parameters['height']
  }
  if (parameters['weight'] !== undefined) {
    form['weight'] = parameters['weight']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['workerId'] !== undefined) {
    form['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxReportSaveRetestCommonDiseaseReportFormData = function(formData) {
  const url = ylzxReportSaveRetestCommonDiseaseReport_RAW_URL()
  const method = ylzxReportSaveRetestCommonDiseaseReport_TYPE()
  return axios[method](url, formData)
}
export const ylzxReportSaveRetestCommonDiseaseReport_RAW_URL = function() {
  return '/yljg/report/retest-common-disease-report'
}
export const ylzxReportSaveRetestCommonDiseaseReport_TYPE = function() {
  return 'post'
}
export const ylzxReportSaveRetestCommonDiseaseReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/report/retest-common-disease-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存视力报告
 * request: ylzxReportSaveVisionReport
 * formData: ylzxReportSaveVisionReportFormData
 * url: ylzxReportSaveVisionReport_URL
 * method: ylzxReportSaveVisionReport_TYPE
 * raw_url: ylzxReportSaveVisionReport_RAW_URL
 * @param personId - 
 * @param wearingGlassesType - 
 * @param leftVision - 
 * @param rightVision - 
 * @param leftVisionBox - 
 * @param rightVisionBox - 
 * @param leftCorrectedVision - 
 * @param rightCorrectedVision - 
 * @param okLeft - 
 * @param okRight - 
 * @param remark - 
 * @param otherSpecialCircumstances - 
 * @param byRetest - 
 * @param checkTime - 
 * @param screeningStudentId - 
 * @param workerId - 
 */
export const ylzxReportSaveVisionReport = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/report/vision-report'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['personId'] !== undefined) {
    form['personId'] = parameters['personId']
  }
  if (parameters['wearingGlassesType'] !== undefined) {
    form['wearingGlassesType'] = parameters['wearingGlassesType']
  }
  if (parameters['leftVision'] !== undefined) {
    form['leftVision'] = parameters['leftVision']
  }
  if (parameters['rightVision'] !== undefined) {
    form['rightVision'] = parameters['rightVision']
  }
  if (parameters['leftVisionBox'] !== undefined) {
    form['leftVisionBox'] = parameters['leftVisionBox']
  }
  if (parameters['rightVisionBox'] !== undefined) {
    form['rightVisionBox'] = parameters['rightVisionBox']
  }
  if (parameters['leftCorrectedVision'] !== undefined) {
    form['leftCorrectedVision'] = parameters['leftCorrectedVision']
  }
  if (parameters['rightCorrectedVision'] !== undefined) {
    form['rightCorrectedVision'] = parameters['rightCorrectedVision']
  }
  if (parameters['okLeft'] !== undefined) {
    form['okLeft'] = parameters['okLeft']
  }
  if (parameters['okRight'] !== undefined) {
    form['okRight'] = parameters['okRight']
  }
  if (parameters['remark'] !== undefined) {
    form['remark'] = parameters['remark']
  }
  if (parameters['otherSpecialCircumstances'] !== undefined) {
    form['otherSpecialCircumstances'] = parameters['otherSpecialCircumstances']
  }
  if (parameters['byRetest'] !== undefined) {
    form['byRetest'] = parameters['byRetest']
  }
  if (parameters['byRetest'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: byRetest'))
  }
  if (parameters['checkTime'] !== undefined) {
    form['checkTime'] = parameters['checkTime']
  }
  if (parameters['checkTime'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: checkTime'))
  }
  if (parameters['screeningStudentId'] !== undefined) {
    form['screeningStudentId'] = parameters['screeningStudentId']
  }
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters['workerId'] !== undefined) {
    form['workerId'] = parameters['workerId']
  }
  if (parameters['workerId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: workerId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxReportSaveVisionReportFormData = function(formData) {
  const url = ylzxReportSaveVisionReport_RAW_URL()
  const method = ylzxReportSaveVisionReport_TYPE()
  return axios[method](url, formData)
}
export const ylzxReportSaveVisionReport_RAW_URL = function() {
  return '/yljg/report/vision-report'
}
export const ylzxReportSaveVisionReport_TYPE = function() {
  return 'post'
}
export const ylzxReportSaveVisionReport_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/report/vision-report'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色列表
 * request: yljgRoleList
 * formData: yljgRoleListFormData
 * url: yljgRoleList_URL
 * method: yljgRoleList_TYPE
 * raw_url: yljgRoleList_RAW_URL
 * @param name - 名称
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgRoleList = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgRoleListFormData = function(formData) {
  const url = yljgRoleList_RAW_URL()
  const method = yljgRoleList_TYPE()
  return axios[method](url, formData)
}
export const yljgRoleList_RAW_URL = function() {
  return '/yljg/role'
}
export const yljgRoleList_TYPE = function() {
  return 'get'
}
export const yljgRoleList_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role'
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 添加角色
 * request: yljgRoleAdd
 * formData: yljgRoleAddFormData
 * url: yljgRoleAdd_URL
 * method: yljgRoleAdd_TYPE
 * raw_url: yljgRoleAdd_RAW_URL
 * @param name - 
 * @param functionIdStr - 
 */
export const yljgRoleAdd = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgRoleAddFormData = function(formData) {
  const url = yljgRoleAdd_RAW_URL()
  const method = yljgRoleAdd_TYPE()
  return axios[method](url, formData)
}
export const yljgRoleAdd_RAW_URL = function() {
  return '/yljg/role'
}
export const yljgRoleAdd_TYPE = function() {
  return 'post'
}
export const yljgRoleAdd_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 功能列表
 * request: yljgFoleListFunction
 * formData: yljgFoleListFunctionFormData
 * url: yljgFoleListFunction_URL
 * method: yljgFoleListFunction_TYPE
 * raw_url: yljgFoleListFunction_RAW_URL
 */
export const yljgFoleListFunction = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role/function'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgFoleListFunctionFormData = function(formData) {
  const url = yljgFoleListFunction_RAW_URL()
  const method = yljgFoleListFunction_TYPE()
  return axios[method](url, formData)
}
export const yljgFoleListFunction_RAW_URL = function() {
  return '/yljg/role/function'
}
export const yljgFoleListFunction_TYPE = function() {
  return 'get'
}
export const yljgFoleListFunction_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role/function'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 角色详情
 * request: yljgRoleFind
 * formData: yljgRoleFindFormData
 * url: yljgRoleFind_URL
 * method: yljgRoleFind_TYPE
 * raw_url: yljgRoleFind_RAW_URL
 * @param roleId - roleId
 */
export const yljgRoleFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgRoleFindFormData = function(formData) {
  const url = yljgRoleFind_RAW_URL()
  const method = yljgRoleFind_TYPE()
  return axios[method](url, formData)
}
export const yljgRoleFind_RAW_URL = function() {
  return '/yljg/role/{roleId}'
}
export const yljgRoleFind_TYPE = function() {
  return 'get'
}
export const yljgRoleFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 修改角色
 * request: yljgRoleUpdate
 * formData: yljgRoleUpdateFormData
 * url: yljgRoleUpdate_URL
 * method: yljgRoleUpdate_TYPE
 * raw_url: yljgRoleUpdate_RAW_URL
 * @param roleId - roleId
 * @param name - 
 * @param functionIdStr - 
 */
export const yljgRoleUpdate = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters['name'] !== undefined) {
    form['name'] = parameters['name']
  }
  if (parameters['name'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: name'))
  }
  if (parameters['functionIdStr'] !== undefined) {
    form['functionIdStr'] = parameters['functionIdStr']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const yljgRoleUpdateFormData = function(formData) {
  const url = yljgRoleUpdate_RAW_URL()
  const method = yljgRoleUpdate_TYPE()
  return axios[method](url, formData)
}
export const yljgRoleUpdate_RAW_URL = function() {
  return '/yljg/role/{roleId}'
}
export const yljgRoleUpdate_TYPE = function() {
  return 'put'
}
export const yljgRoleUpdate_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 删除角色
 * request: yljgRoleDelete
 * formData: yljgRoleDeleteFormData
 * url: yljgRoleDelete_URL
 * method: yljgRoleDelete_TYPE
 * raw_url: yljgRoleDelete_RAW_URL
 * @param roleId - roleId
 */
export const yljgRoleDelete = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/role/{roleId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters['roleId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: roleId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('delete', domain + path, body, queryParameters, form, config)
}
export const yljgRoleDeleteFormData = function(formData) {
  const url = yljgRoleDelete_RAW_URL()
  const method = yljgRoleDelete_TYPE()
  return axios[method](url, formData)
}
export const yljgRoleDelete_RAW_URL = function() {
  return '/yljg/role/{roleId}'
}
export const yljgRoleDelete_TYPE = function() {
  return 'delete'
}
export const yljgRoleDelete_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/role/{roleId}'
  path = path.replace('{roleId}', `${parameters['roleId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 学生筛查进度
 * request: yljgScreeningMonitoringStudentScreeningProgress
 * formData: yljgScreeningMonitoringStudentScreeningProgressFormData
 * url: yljgScreeningMonitoringStudentScreeningProgress_URL
 * method: yljgScreeningMonitoringStudentScreeningProgress_TYPE
 * raw_url: yljgScreeningMonitoringStudentScreeningProgress_RAW_URL
 * @param screeningStudentId - screeningStudentId
 */
export const yljgScreeningMonitoringStudentScreeningProgress = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters['screeningStudentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningStudentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringStudentScreeningProgressFormData = function(formData) {
  const url = yljgScreeningMonitoringStudentScreeningProgress_RAW_URL()
  const method = yljgScreeningMonitoringStudentScreeningProgress_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringStudentScreeningProgress_RAW_URL = function() {
  return '/yljg/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
}
export const yljgScreeningMonitoringStudentScreeningProgress_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringStudentScreeningProgress_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/screening-student/{screeningStudentId}/screening-progress'
  path = path.replace('{screeningStudentId}', `${parameters['screeningStudentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组）
 * request: yljgScreeningMonitoringFind
 * formData: yljgScreeningMonitoringFindFormData
 * url: yljgScreeningMonitoringFind_URL
 * method: yljgScreeningMonitoringFind_TYPE
 * raw_url: yljgScreeningMonitoringFind_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgScreeningMonitoringFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringFindFormData = function(formData) {
  const url = yljgScreeningMonitoringFind_RAW_URL()
  const method = yljgScreeningMonitoringFind_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringFind_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}'
}
export const yljgScreeningMonitoringFind_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 异常学生
 * request: yljgScreeningMonitoringAbnormalStudent
 * formData: yljgScreeningMonitoringAbnormalStudentFormData
 * url: yljgScreeningMonitoringAbnormalStudent_URL
 * method: yljgScreeningMonitoringAbnormalStudent_TYPE
 * raw_url: yljgScreeningMonitoringAbnormalStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringAbnormalStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringAbnormalStudentFormData = function(formData) {
  const url = yljgScreeningMonitoringAbnormalStudent_RAW_URL()
  const method = yljgScreeningMonitoringAbnormalStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringAbnormalStudent_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
}
export const yljgScreeningMonitoringAbnormalStudent_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringAbnormalStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/abnormal'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生
 * request: yljgScreeningMonitoringFinishedStudent
 * formData: yljgScreeningMonitoringFinishedStudentFormData
 * url: yljgScreeningMonitoringFinishedStudent_URL
 * method: yljgScreeningMonitoringFinishedStudent_TYPE
 * raw_url: yljgScreeningMonitoringFinishedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringFinishedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringFinishedStudentFormData = function(formData) {
  const url = yljgScreeningMonitoringFinishedStudent_RAW_URL()
  const method = yljgScreeningMonitoringFinishedStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringFinishedStudent_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
}
export const yljgScreeningMonitoringFinishedStudent_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringFinishedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 已完成学生（带调查问卷）
 * request: yljgScreeningMonitoringFinishedStudentWithQuestionary
 * formData: yljgScreeningMonitoringFinishedStudentWithQuestionaryFormData
 * url: yljgScreeningMonitoringFinishedStudentWithQuestionary_URL
 * method: yljgScreeningMonitoringFinishedStudentWithQuestionary_TYPE
 * raw_url: yljgScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param screeningClassId - 
 * @param checkDate - 查看该值当天检查
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringFinishedStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringFinishedStudentWithQuestionaryFormData = function(formData) {
  const url = yljgScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL()
  const method = yljgScreeningMonitoringFinishedStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringFinishedStudentWithQuestionary_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
}
export const yljgScreeningMonitoringFinishedStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringFinishedStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/finished/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['checkDate'] !== undefined) {
    queryParameters['checkDate'] = parameters['checkDate']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生
 * request: yljgScreeningMonitoringMissingStudent
 * formData: yljgScreeningMonitoringMissingStudentFormData
 * url: yljgScreeningMonitoringMissingStudent_URL
 * method: yljgScreeningMonitoringMissingStudent_TYPE
 * raw_url: yljgScreeningMonitoringMissingStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringMissingStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringMissingStudentFormData = function(formData) {
  const url = yljgScreeningMonitoringMissingStudent_RAW_URL()
  const method = yljgScreeningMonitoringMissingStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringMissingStudent_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
}
export const yljgScreeningMonitoringMissingStudent_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringMissingStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 漏检学生（带调查问卷）
 * request: yljgScreeningMonitoringMissingStudentWithQuestionary
 * formData: yljgScreeningMonitoringMissingStudentWithQuestionaryFormData
 * url: yljgScreeningMonitoringMissingStudentWithQuestionary_URL
 * method: yljgScreeningMonitoringMissingStudentWithQuestionary_TYPE
 * raw_url: yljgScreeningMonitoringMissingStudentWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringMissingStudentWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringMissingStudentWithQuestionaryFormData = function(formData) {
  const url = yljgScreeningMonitoringMissingStudentWithQuestionary_RAW_URL()
  const method = yljgScreeningMonitoringMissingStudentWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringMissingStudentWithQuestionary_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
}
export const yljgScreeningMonitoringMissingStudentWithQuestionary_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringMissingStudentWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/missing/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 未检查学生
 * request: yljgScreeningMonitoringUncheckedStudent
 * formData: yljgScreeningMonitoringUncheckedStudentFormData
 * url: yljgScreeningMonitoringUncheckedStudent_URL
 * method: yljgScreeningMonitoringUncheckedStudent_TYPE
 * raw_url: yljgScreeningMonitoringUncheckedStudent_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 * @param name - 
 * @param sex - 
 * @param age - 
 * @param page - 
 * @param rows - 
 * @param sort - 
 * @param order - 
 */
export const yljgScreeningMonitoringUncheckedStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringUncheckedStudentFormData = function(formData) {
  const url = yljgScreeningMonitoringUncheckedStudent_RAW_URL()
  const method = yljgScreeningMonitoringUncheckedStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringUncheckedStudent_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
}
export const yljgScreeningMonitoringUncheckedStudent_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringUncheckedStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/student/unchecked'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters['name'] !== undefined) {
    queryParameters['name'] = parameters['name']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters['age'] !== undefined) {
    queryParameters['age'] = parameters['age']
  }
  if (parameters['page'] !== undefined) {
    queryParameters['page'] = parameters['page']
  }
  if (parameters['rows'] !== undefined) {
    queryParameters['rows'] = parameters['rows']
  }
  if (parameters['sort'] !== undefined) {
    queryParameters['sort'] = parameters['sort']
  }
  if (parameters['order'] !== undefined) {
    queryParameters['order'] = parameters['order']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看筛查监控（按班级分组，带调查问卷）
 * request: yljgScreeningMonitoringFindWithQuestionary
 * formData: yljgScreeningMonitoringFindWithQuestionaryFormData
 * url: yljgScreeningMonitoringFindWithQuestionary_URL
 * method: yljgScreeningMonitoringFindWithQuestionary_TYPE
 * raw_url: yljgScreeningMonitoringFindWithQuestionary_RAW_URL
 * @param planId - planId
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgScreeningMonitoringFindWithQuestionary = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{planId}', `${parameters['planId']}`)
  if (parameters['planId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: planId'))
  }
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringFindWithQuestionaryFormData = function(formData) {
  const url = yljgScreeningMonitoringFindWithQuestionary_RAW_URL()
  const method = yljgScreeningMonitoringFindWithQuestionary_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringFindWithQuestionary_RAW_URL = function() {
  return '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
}
export const yljgScreeningMonitoringFindWithQuestionary_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringFindWithQuestionary_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{planId}/school/{screeningSchoolId}/with-questionary'
  path = path.replace('{planId}', `${parameters['planId']}`)
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 质控
 * request: yljgScreeningMonitoringQualityControl
 * formData: yljgScreeningMonitoringQualityControlFormData
 * url: yljgScreeningMonitoringQualityControl_URL
 * method: yljgScreeningMonitoringQualityControl_TYPE
 * raw_url: yljgScreeningMonitoringQualityControl_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param date - 
 */
export const yljgScreeningMonitoringQualityControl = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/quality-control'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringQualityControlFormData = function(formData) {
  const url = yljgScreeningMonitoringQualityControl_RAW_URL()
  const method = yljgScreeningMonitoringQualityControl_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringQualityControl_RAW_URL = function() {
  return '/yljg/screening-monitoring/{screeningSchoolId}/quality-control'
}
export const yljgScreeningMonitoringQualityControl_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringQualityControl_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/quality-control'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['date'] !== undefined) {
    queryParameters['date'] = parameters['date']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷监控
 * request: yljgScreeningMonitoringQuestionnaryScreeningSchool
 * formData: yljgScreeningMonitoringQuestionnaryScreeningSchoolFormData
 * url: yljgScreeningMonitoringQuestionnaryScreeningSchool_URL
 * method: yljgScreeningMonitoringQuestionnaryScreeningSchool_TYPE
 * raw_url: yljgScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgScreeningMonitoringQuestionnaryScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/questionnary'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringQuestionnaryScreeningSchoolFormData = function(formData) {
  const url = yljgScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL()
  const method = yljgScreeningMonitoringQuestionnaryScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringQuestionnaryScreeningSchool_RAW_URL = function() {
  return '/yljg/screening-monitoring/{screeningSchoolId}/questionnary'
}
export const yljgScreeningMonitoringQuestionnaryScreeningSchool_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringQuestionnaryScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/questionnary'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 问卷未完成学生
 * request: yljgScreeningMonitoringListQuestionnaryIncompleteStudent
 * formData: yljgScreeningMonitoringListQuestionnaryIncompleteStudentFormData
 * url: yljgScreeningMonitoringListQuestionnaryIncompleteStudent_URL
 * method: yljgScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE
 * raw_url: yljgScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param gradeId - 
 * @param screeningClassId - 
 */
export const yljgScreeningMonitoringListQuestionnaryIncompleteStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgScreeningMonitoringListQuestionnaryIncompleteStudentFormData = function(formData) {
  const url = yljgScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL()
  const method = yljgScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgScreeningMonitoringListQuestionnaryIncompleteStudent_RAW_URL = function() {
  return '/yljg/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
}
export const yljgScreeningMonitoringListQuestionnaryIncompleteStudent_TYPE = function() {
  return 'get'
}
export const yljgScreeningMonitoringListQuestionnaryIncompleteStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-monitoring/{screeningSchoolId}/questionnary-incomplete-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['gradeId'] !== undefined) {
    queryParameters['gradeId'] = parameters['gradeId']
  }
  if (parameters['screeningClassId'] !== undefined) {
    queryParameters['screeningClassId'] = parameters['screeningClassId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成学校筛查计划选择列表
 * request: ylzxScreeningSchoolChoiceListFinish
 * formData: ylzxScreeningSchoolChoiceListFinishFormData
 * url: ylzxScreeningSchoolChoiceListFinish_URL
 * method: ylzxScreeningSchoolChoiceListFinish_TYPE
 * raw_url: ylzxScreeningSchoolChoiceListFinish_RAW_URL
 */
export const ylzxScreeningSchoolChoiceListFinish = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-school/finish-choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningSchoolChoiceListFinishFormData = function(formData) {
  const url = ylzxScreeningSchoolChoiceListFinish_RAW_URL()
  const method = ylzxScreeningSchoolChoiceListFinish_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningSchoolChoiceListFinish_RAW_URL = function() {
  return '/yljg/screening-school/finish-choice-list'
}
export const ylzxScreeningSchoolChoiceListFinish_TYPE = function() {
  return 'get'
}
export const ylzxScreeningSchoolChoiceListFinish_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-school/finish-choice-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 待处理选择列表
 * request: ylzxScreeningSchoolChoiceListPending
 * formData: ylzxScreeningSchoolChoiceListPendingFormData
 * url: ylzxScreeningSchoolChoiceListPending_URL
 * method: ylzxScreeningSchoolChoiceListPending_TYPE
 * raw_url: ylzxScreeningSchoolChoiceListPending_RAW_URL
 */
export const ylzxScreeningSchoolChoiceListPending = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-school/pending-choice-list'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningSchoolChoiceListPendingFormData = function(formData) {
  const url = ylzxScreeningSchoolChoiceListPending_RAW_URL()
  const method = ylzxScreeningSchoolChoiceListPending_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningSchoolChoiceListPending_RAW_URL = function() {
  return '/yljg/screening-school/pending-choice-list'
}
export const ylzxScreeningSchoolChoiceListPending_TYPE = function() {
  return 'get'
}
export const ylzxScreeningSchoolChoiceListPending_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-school/pending-choice-list'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 生成质控记录
 * request: ylzxScreeningSchoolSaveQualityControlRecord
 * formData: ylzxScreeningSchoolSaveQualityControlRecordFormData
 * url: ylzxScreeningSchoolSaveQualityControlRecord_URL
 * method: ylzxScreeningSchoolSaveQualityControlRecord_TYPE
 * raw_url: ylzxScreeningSchoolSaveQualityControlRecord_RAW_URL
 * @param screeningSchoolId - 筛查学校ID
 * @param qcDate - 质控日期
 */
export const ylzxScreeningSchoolSaveQualityControlRecord = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-school/quality-control-record'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    form['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['qcDate'] !== undefined) {
    form['qcDate'] = parameters['qcDate']
  }
  if (parameters['qcDate'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: qcDate'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningSchoolSaveQualityControlRecordFormData = function(formData) {
  const url = ylzxScreeningSchoolSaveQualityControlRecord_RAW_URL()
  const method = ylzxScreeningSchoolSaveQualityControlRecord_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningSchoolSaveQualityControlRecord_RAW_URL = function() {
  return '/yljg/screening-school/quality-control-record'
}
export const ylzxScreeningSchoolSaveQualityControlRecord_TYPE = function() {
  return 'post'
}
export const ylzxScreeningSchoolSaveQualityControlRecord_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-school/quality-control-record'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 机构筛查详情
 * request: ylzxScreeningSchoolStatisticasStudent
 * formData: ylzxScreeningSchoolStatisticasStudentFormData
 * url: ylzxScreeningSchoolStatisticasStudent_URL
 * method: ylzxScreeningSchoolStatisticasStudent_TYPE
 * raw_url: ylzxScreeningSchoolStatisticasStudent_RAW_URL
 */
export const ylzxScreeningSchoolStatisticasStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-school/statisticas'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningSchoolStatisticasStudentFormData = function(formData) {
  const url = ylzxScreeningSchoolStatisticasStudent_RAW_URL()
  const method = ylzxScreeningSchoolStatisticasStudent_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningSchoolStatisticasStudent_RAW_URL = function() {
  return '/yljg/screening-school/statisticas'
}
export const ylzxScreeningSchoolStatisticasStudent_TYPE = function() {
  return 'get'
}
export const ylzxScreeningSchoolStatisticasStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-school/statisticas'
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 检查学校任务状态
 * request: ylzxScreeningTaskCheckSchoolTaskStatus
 * formData: ylzxScreeningTaskCheckSchoolTaskStatusFormData
 * url: ylzxScreeningTaskCheckSchoolTaskStatus_URL
 * method: ylzxScreeningTaskCheckSchoolTaskStatus_TYPE
 * raw_url: ylzxScreeningTaskCheckSchoolTaskStatus_RAW_URL
 * @param screeningSchoolId - 
 */
export const ylzxScreeningTaskCheckSchoolTaskStatus = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-task/check-task-status'
  let body
  let queryParameters = {}
  let form = {}
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningTaskCheckSchoolTaskStatusFormData = function(formData) {
  const url = ylzxScreeningTaskCheckSchoolTaskStatus_RAW_URL()
  const method = ylzxScreeningTaskCheckSchoolTaskStatus_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningTaskCheckSchoolTaskStatus_RAW_URL = function() {
  return '/yljg/screening-task/check-task-status'
}
export const ylzxScreeningTaskCheckSchoolTaskStatus_TYPE = function() {
  return 'get'
}
export const ylzxScreeningTaskCheckSchoolTaskStatus_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-task/check-task-status'
  if (parameters['screeningSchoolId'] !== undefined) {
    queryParameters['screeningSchoolId'] = parameters['screeningSchoolId']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 完成筛查
 * request: ylzxScreeningTaskFinishScreening
 * formData: ylzxScreeningTaskFinishScreeningFormData
 * url: ylzxScreeningTaskFinishScreening_URL
 * method: ylzxScreeningTaskFinishScreening_TYPE
 * raw_url: ylzxScreeningTaskFinishScreening_RAW_URL
 * @param screeningTaskId - screeningTaskId
 */
export const ylzxScreeningTaskFinishScreening = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-task/{screeningTaskId}/finish-screening'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningTaskFinishScreeningFormData = function(formData) {
  const url = ylzxScreeningTaskFinishScreening_RAW_URL()
  const method = ylzxScreeningTaskFinishScreening_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningTaskFinishScreening_RAW_URL = function() {
  return '/yljg/screening-task/{screeningTaskId}/finish-screening'
}
export const ylzxScreeningTaskFinishScreening_TYPE = function() {
  return 'put'
}
export const ylzxScreeningTaskFinishScreening_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-task/{screeningTaskId}/finish-screening'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 重新录入学生
 * request: ylzxScreeningTaskInputStudentAgain
 * formData: ylzxScreeningTaskInputStudentAgainFormData
 * url: ylzxScreeningTaskInputStudentAgain_URL
 * method: ylzxScreeningTaskInputStudentAgain_TYPE
 * raw_url: ylzxScreeningTaskInputStudentAgain_RAW_URL
 * @param screeningTaskId - screeningTaskId
 * @param rejectReason - 
 */
export const ylzxScreeningTaskInputStudentAgain = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/screening-task/{screeningTaskId}/input-student-again'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['screeningTaskId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningTaskId'))
  }
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('put', domain + path, body, queryParameters, form, config)
}
export const ylzxScreeningTaskInputStudentAgainFormData = function(formData) {
  const url = ylzxScreeningTaskInputStudentAgain_RAW_URL()
  const method = ylzxScreeningTaskInputStudentAgain_TYPE()
  return axios[method](url, formData)
}
export const ylzxScreeningTaskInputStudentAgain_RAW_URL = function() {
  return '/yljg/screening-task/{screeningTaskId}/input-student-again'
}
export const ylzxScreeningTaskInputStudentAgain_TYPE = function() {
  return 'put'
}
export const ylzxScreeningTaskInputStudentAgain_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/screening-task/{screeningTaskId}/input-student-again'
  path = path.replace('{screeningTaskId}', `${parameters['screeningTaskId']}`)
  if (parameters['rejectReason'] !== undefined) {
    queryParameters['rejectReason'] = parameters['rejectReason']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取学生视力不良及影响因素专项调查表
 * request: yljgSpvisqFindByScreeningSchool
 * formData: yljgSpvisqFindByScreeningSchoolFormData
 * url: yljgSpvisqFindByScreeningSchool_URL
 * method: yljgSpvisqFindByScreeningSchool_TYPE
 * raw_url: yljgSpvisqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgSpvisqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/spvisq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgSpvisqFindByScreeningSchoolFormData = function(formData) {
  const url = yljgSpvisqFindByScreeningSchool_RAW_URL()
  const method = yljgSpvisqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgSpvisqFindByScreeningSchool_RAW_URL = function() {
  return '/yljg/spvisq/by-screeningSchool/{screeningSchoolId}'
}
export const yljgSpvisqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const yljgSpvisqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/spvisq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看学生视力不良及影响因素专项调查表
 * request: yljgSpvisqFindByStudent
 * formData: yljgSpvisqFindByStudentFormData
 * url: yljgSpvisqFindByStudent_URL
 * method: yljgSpvisqFindByStudent_TYPE
 * raw_url: yljgSpvisqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const yljgSpvisqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/spvisq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgSpvisqFindByStudentFormData = function(formData) {
  const url = yljgSpvisqFindByStudent_RAW_URL()
  const method = yljgSpvisqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgSpvisqFindByStudent_RAW_URL = function() {
  return '/yljg/spvisq/by-student/{studentId}'
}
export const yljgSpvisqFindByStudent_TYPE = function() {
  return 'get'
}
export const yljgSpvisqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/spvisq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存学生视力不良及影响因素专项调查表
 * request: yljgSpvisqSave
 * formData: yljgSpvisqSaveFormData
 * url: yljgSpvisqSave_URL
 * method: yljgSpvisqSave_TYPE
 * raw_url: yljgSpvisqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param c01 - 
 * @param c02 - 
 * @param c03 - 
 * @param c04 - 当C03不是选中选项5和6时必填，反之不填
 * @param c05 - 
 * @param c06 - 
 * @param c06LimitMinute - 当C06选中选项1时必填，反之不填
 * @param d01 - 
 * @param d02 - 
 * @param d03 - 
 * @param d04 - 
 * @param d05 - 
 * @param e01 - 
 * @param e02 - 
 * @param e03 - 
 * @param e03Time - 当E03选中选项2时必填，反之不填
 * @param e04 - 在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间？
 * @param e04Time - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的平均分钟数
 * @param e04NonLearningTime - 当E04选中选项2时必填，反之不填。在过去一周里，你平均每天看电视、使用电脑、手机、游戏机、以及看（玩）其他电子屏幕的时间非学习目的使用分钟数
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f04 - 
 * @param f05 - 
 * @param f05OtherDescription - 当F05选中选项4时必填，反之不填
 * @param f06 - 
 * @param f07 - 
 * @param f08 - 
 * @param g01 - 
 * @param g02 - 
 * @param g03 - 
 * @param h01 - 
 * @param h02 - 
 * @param h03 - 
 * @param h04 - 当H03选中选项1时必填，反之不填
 * @param h04OtherDescription - 当H04包括选项5时必填，反之不填
 * @param h05 - 当H03选中选项1时必填，反之不填
 * @param h05OtherDescription - 当H05包括选项6时必填，反之不填
 * @param h06 - 当H05不包括选项7时必填，反之不填
 * @param h06AgeOfFirst - 当H06不选中选项3时必填，反之不填
 * @param h07 - 当H05不包括选项7时必填，反之不填
 * @param h08 - 
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i03Week - 
 * @param i04 - 
 * @param i05 - 
 * @param i06 - 
 * @param i07 - 
 * @param i08 - 
 */
export const yljgSpvisqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/spvisq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b02'))
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c02'] !== undefined) {
    form['c02'] = parameters['c02']
  }
  if (parameters['c02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c02'))
  }
  if (parameters['c03'] !== undefined) {
    form['c03'] = parameters['c03']
  }
  if (parameters['c03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c03'))
  }
  if (parameters['c04'] !== undefined) {
    form['c04'] = parameters['c04']
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c06'))
  }
  if (parameters['c06LimitMinute'] !== undefined) {
    form['c06LimitMinute'] = parameters['c06LimitMinute']
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d02'))
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d03'))
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e02'] !== undefined) {
    form['e02'] = parameters['e02']
  }
  if (parameters['e02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e02'))
  }
  if (parameters['e03'] !== undefined) {
    form['e03'] = parameters['e03']
  }
  if (parameters['e03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e03'))
  }
  if (parameters['e03Time'] !== undefined) {
    form['e03Time'] = parameters['e03Time']
  }
  if (parameters['e04'] !== undefined) {
    form['e04'] = parameters['e04']
  }
  if (parameters['e04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e04'))
  }
  if (parameters['e04Time'] !== undefined) {
    form['e04Time'] = parameters['e04Time']
  }
  if (parameters['e04NonLearningTime'] !== undefined) {
    form['e04NonLearningTime'] = parameters['e04NonLearningTime']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f05OtherDescription'] !== undefined) {
    form['f05OtherDescription'] = parameters['f05OtherDescription']
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f07'] !== undefined) {
    form['f07'] = parameters['f07']
  }
  if (parameters['f07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f07'))
  }
  if (parameters['f08'] !== undefined) {
    form['f08'] = parameters['f08']
  }
  if (parameters['f08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f08'))
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['g02'] !== undefined) {
    form['g02'] = parameters['g02']
  }
  if (parameters['g02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g02'))
  }
  if (parameters['g03'] !== undefined) {
    form['g03'] = parameters['g03']
  }
  if (parameters['g03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g03'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h02'))
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h03'))
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h04OtherDescription'] !== undefined) {
    form['h04OtherDescription'] = parameters['h04OtherDescription']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['h05OtherDescription'] !== undefined) {
    form['h05OtherDescription'] = parameters['h05OtherDescription']
  }
  if (parameters['h06'] !== undefined) {
    form['h06'] = parameters['h06']
  }
  if (parameters['h06AgeOfFirst'] !== undefined) {
    form['h06AgeOfFirst'] = parameters['h06AgeOfFirst']
  }
  if (parameters['h07'] !== undefined) {
    form['h07'] = parameters['h07']
  }
  if (parameters['h08'] !== undefined) {
    form['h08'] = parameters['h08']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i03Week'] !== undefined) {
    form['i03Week'] = parameters['i03Week']
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i04'))
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i05'))
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i06'))
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i07'))
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgSpvisqSaveFormData = function(formData) {
  const url = yljgSpvisqSave_RAW_URL()
  const method = yljgSpvisqSave_TYPE()
  return axios[method](url, formData)
}
export const yljgSpvisqSave_RAW_URL = function() {
  return '/yljg/spvisq/save/{studentId}'
}
export const yljgSpvisqSave_TYPE = function() {
  return 'post'
}
export const yljgSpvisqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/spvisq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 查看任务指标
 * request: yljgTaskIndicatorFind
 * formData: yljgTaskIndicatorFindFormData
 * url: yljgTaskIndicatorFind_URL
 * method: yljgTaskIndicatorFind_TYPE
 * raw_url: yljgTaskIndicatorFind_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgTaskIndicatorFind = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/task-indicator/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgTaskIndicatorFindFormData = function(formData) {
  const url = yljgTaskIndicatorFind_RAW_URL()
  const method = yljgTaskIndicatorFind_TYPE()
  return axios[method](url, formData)
}
export const yljgTaskIndicatorFind_RAW_URL = function() {
  return '/yljg/task-indicator/{screeningSchoolId}'
}
export const yljgTaskIndicatorFind_TYPE = function() {
  return 'get'
}
export const yljgTaskIndicatorFind_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/task-indicator/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 可疑学生列表
 * request: yljgTaskIndicatorDubiousStudent
 * formData: yljgTaskIndicatorDubiousStudentFormData
 * url: yljgTaskIndicatorDubiousStudent_URL
 * method: yljgTaskIndicatorDubiousStudent_TYPE
 * raw_url: yljgTaskIndicatorDubiousStudent_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 * @param dubiousType - 
 * @param studentName - 
 * @param sex - 
 */
export const yljgTaskIndicatorDubiousStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/task-indicator/{screeningSchoolId}/dubious-student'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgTaskIndicatorDubiousStudentFormData = function(formData) {
  const url = yljgTaskIndicatorDubiousStudent_RAW_URL()
  const method = yljgTaskIndicatorDubiousStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgTaskIndicatorDubiousStudent_RAW_URL = function() {
  return '/yljg/task-indicator/{screeningSchoolId}/dubious-student'
}
export const yljgTaskIndicatorDubiousStudent_TYPE = function() {
  return 'get'
}
export const yljgTaskIndicatorDubiousStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/task-indicator/{screeningSchoolId}/dubious-student'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['dubiousType'] !== undefined) {
    queryParameters['dubiousType'] = parameters['dubiousType']
  }
  if (parameters['studentName'] !== undefined) {
    queryParameters['studentName'] = parameters['studentName']
  }
  if (parameters['sex'] !== undefined) {
    queryParameters['sex'] = parameters['sex']
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学校获取大学学生健康状况及影响因素调查表
 * request: yljgUshiqFindByScreeningSchool
 * formData: yljgUshiqFindByScreeningSchoolFormData
 * url: yljgUshiqFindByScreeningSchool_URL
 * method: yljgUshiqFindByScreeningSchool_TYPE
 * raw_url: yljgUshiqFindByScreeningSchool_RAW_URL
 * @param screeningSchoolId - screeningSchoolId
 */
export const yljgUshiqFindByScreeningSchool = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/ushiq/by-screeningSchool/{screeningSchoolId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters['screeningSchoolId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: screeningSchoolId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgUshiqFindByScreeningSchoolFormData = function(formData) {
  const url = yljgUshiqFindByScreeningSchool_RAW_URL()
  const method = yljgUshiqFindByScreeningSchool_TYPE()
  return axios[method](url, formData)
}
export const yljgUshiqFindByScreeningSchool_RAW_URL = function() {
  return '/yljg/ushiq/by-screeningSchool/{screeningSchoolId}'
}
export const yljgUshiqFindByScreeningSchool_TYPE = function() {
  return 'get'
}
export const yljgUshiqFindByScreeningSchool_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/ushiq/by-screeningSchool/{screeningSchoolId}'
  path = path.replace('{screeningSchoolId}', `${parameters['screeningSchoolId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 根据学生查看大学学生健康状况及影响因素调查表
 * request: yljgUshiqFindByStudent
 * formData: yljgUshiqFindByStudentFormData
 * url: yljgUshiqFindByStudent_URL
 * method: yljgUshiqFindByStudent_TYPE
 * raw_url: yljgUshiqFindByStudent_RAW_URL
 * @param studentId - studentId
 */
export const yljgUshiqFindByStudent = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/ushiq/by-student/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('get', domain + path, body, queryParameters, form, config)
}
export const yljgUshiqFindByStudentFormData = function(formData) {
  const url = yljgUshiqFindByStudent_RAW_URL()
  const method = yljgUshiqFindByStudent_TYPE()
  return axios[method](url, formData)
}
export const yljgUshiqFindByStudent_RAW_URL = function() {
  return '/yljg/ushiq/by-student/{studentId}'
}
export const yljgUshiqFindByStudent_TYPE = function() {
  return 'get'
}
export const yljgUshiqFindByStudent_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/ushiq/by-student/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}
/**
 * 保存大学学生健康状况及影响因素调查表
 * request: yljgUshiqSave
 * formData: yljgUshiqSaveFormData
 * url: yljgUshiqSave_URL
 * method: yljgUshiqSave_TYPE
 * raw_url: yljgUshiqSave_RAW_URL
 * @param studentId - studentId
 * @param date - 
 * @param a03 - 
 * @param a04 - 
 * @param a04OtherDescription - 你的民族其他描述
 * @param a05 - 
 * @param a051 - 当A05的值大于0时必填，反之不填
 * @param a06 - 
 * @param a07 - 
 * @param a07Day - 当A07的值为1时必填，反之不填
 * @param a08 - 
 * @param a08Month - 
 * @param a09 - 是否来月经/遗精？
 * @param a091 - 首次月经/遗精的年龄
 * @param b01 - 
 * @param b02 - 
 * @param b03 - 
 * @param b04 - 
 * @param b041 - 
 * @param b05 - 
 * @param b051 - 
 * @param b06 - 
 * @param b061 - 
 * @param b07 - 
 * @param b071 - 
 * @param b08Minute - 当B08的值为1时必填，反之不填
 * @param b10 - 
 * @param c01 - 
 * @param c01Times - 当C01的值为1时必填，反之不填
 * @param c021 - 
 * @param c022 - 
 * @param c023 - 
 * @param c024 - 
 * @param c025 - 
 * @param c026 - 
 * @param c05 - 
 * @param c05Times - 当C05的值为2时必填，反之不填
 * @param c06 - 
 * @param c061 - 
 * @param c062 - 
 * @param c063 - 
 * @param c064 - 
 * @param c07 - 
 * @param c08 - 
 * @param c121 - 在公共网页上受到他人的辱骂？
 * @param c122 - 曾收到侮辱性的信息？
 * @param c123 - 有人将令我尴尬的照片或视频上传到网络上？
 * @param c124 - 某人用假的身份给我发送令我难堪的信息？
 * @param c125 - 有人在网络上散布我的谣言(如社交网站等)？
 * @param c126 - 曾有人未经我的允许转发或公开我的私人信息？
 * @param c127 - 曾经受到威胁性或恐吓性的信息？
 * @param d01 - 
 * @param d01AgeOfFirst - 当D01的值为1时必填，反之不填
 * @param d02 - 当D01的值为1时必填，反之不填
 * @param d02Day - 当D02的值为2时必填，反之不填
 * @param d03 - 当D01的值为1时必填，反之不填
 * @param d04 - 
 * @param d05 - 
 * @param d05AgeOfFirst - 当D05的值为1时必填，反之不填
 * @param d06 - 当D05的值为1时必填，反之不填
 * @param d07 - 当D05的值为1时必填，反之不填
 * @param d08 - 当D05的值为1时必填，反之不填
 * @param d09 - 
 * @param d09Description - 到目前为止，你是否使用过下列物质其它说明
 * @param d10 - 到目前为止，你是否为了高兴而吸入胶水、汽油等可吸入溶剂？
 * @param d11 - 到目前为止，除治疗咳嗽外，你是否使用过止咳药水？
 * @param d111 - 每次服用止咳药水的量是多少？
 * @param d12 - 到目前为止，在没有医生许可情况下，你是否使用镇静催眠类药品？
 * @param e01 - 
 * @param e011 - 当E01的值为2时必填，反之不填
 * @param e021 - 当E01的值为2时必填，反之不填
 * @param e022 - 当E01的值为2时必填，反之不填
 * @param e023 - 当E01的值为2时必填，反之不填
 * @param e024 - 当E01的值为2时必填，反之不填
 * @param e025 - 当E01的值为2时必填，反之不填
 * @param e026 - 当E01的值为2时必填，反之不填
 * @param e027 - 当E01的值为2时必填，反之不填
 * @param e028 - 当E01的值为2时必填，反之不填
 * @param e029 - 当E01的值为2时必填，反之不填
 * @param f01 - 
 * @param f02 - 
 * @param f03 - 
 * @param f03Time - 当F03的值为2时必填，反之不填
 * @param f04 - 
 * @param f05 - 
 * @param f06 - 
 * @param f06Time - 当F06的值为2时必填，反之不填
 * @param g01 - 
 * @param h01 - 
 * @param h01AgeOfFirst - 当H01的值为2时必填，反之不填
 * @param h02 - 当H01的值为2时必填，反之不填，你平均每天使用耳机分钟数 --> 时间选项
 * @param h03 - 当H01的值为2时必填，反之不填
 * @param h04 - 当H01的值为2时必填，反之不填
 * @param h05 - 当H01的值为2时必填，反之不填
 * @param i01 - 
 * @param i02 - 
 * @param i03 - 
 * @param i04 - 当I03的值不为1时必填，反之不填
 * @param i05 - 当I03的值不为1时必填，反之不填
 * @param i06 - 当I03的值不为1时必填，反之不填
 * @param i07 - 当I03的值不为1时必填，反之不填
 * @param i08 - 当I03的值不为1时必填，反之不填
 * @param i09 - 当I03的值不为1时必填，反之不填
 * @param j01 - 
 * @param j02 - 
 * @param j03 - 
 * @param j04 - 
 * @param j05 - 
 * @param j06 - 
 * @param j07 - 
 * @param j08 - 
 * @param j09 - 
 * @param j10 - 
 * @param j11 - 
 * @param j12 - 
 * @param j13 - 
 * @param j14 - 
 * @param j15 - 
 * @param j16 - 
 * @param j17 - 
 * @param j18 - 
 * @param j19 - 
 * @param j20 - 
 * @param j21 - 感觉紧张，焦虑或急切
 * @param j22 - 不能够停止或控制担忧
 * @param j23 - 对各种恶样的事情担忧过多
 * @param j24 - 很难放松下来
 * @param j25 - 由于不安而无法静坐
 * @param j26 - 变得容易烦恼或急躁
 * @param j27 - 感到似乎将有可怕的事情发生而害怕
 * @param j28 - 担你被心理问题困扰时，你是否寻求过外界的帮助？
 * @param j281 - 你寻求了什么形式的帮助
 * @param j282 - 寻求帮助后你的心理问题是否得到有效缓解？
 * @param l01 - 
 * @param l02 - 
 * @param l03 - 
 * @param l03Week - 
 * @param l04 - 
 * @param l05 - 
 * @param l06 - 
 * @param l07 - 
 * @param l08 - 
 */
export const yljgUshiqSave = function(parameters = {}) {
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  const config = parameters.$config
  let path = '/yljg/ushiq/save/{studentId}'
  let body
  let queryParameters = {}
  let form = {}
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters['studentId'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: studentId'))
  }
  if (parameters['date'] !== undefined) {
    form['date'] = parameters['date']
  }
  if (parameters['date'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: date'))
  }
  if (parameters['a03'] !== undefined) {
    form['a03'] = parameters['a03']
  }
  if (parameters['a03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a03'))
  }
  if (parameters['a04'] !== undefined) {
    form['a04'] = parameters['a04']
  }
  if (parameters['a04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a04'))
  }
  if (parameters['a04OtherDescription'] !== undefined) {
    form['a04OtherDescription'] = parameters['a04OtherDescription']
  }
  if (parameters['a05'] !== undefined) {
    form['a05'] = parameters['a05']
  }
  if (parameters['a05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a05'))
  }
  if (parameters['a051'] !== undefined) {
    form['a051'] = parameters['a051']
  }
  if (parameters['a06'] !== undefined) {
    form['a06'] = parameters['a06']
  }
  if (parameters['a07'] !== undefined) {
    form['a07'] = parameters['a07']
  }
  if (parameters['a07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a07'))
  }
  if (parameters['a07Day'] !== undefined) {
    form['a07Day'] = parameters['a07Day']
  }
  if (parameters['a08'] !== undefined) {
    form['a08'] = parameters['a08']
  }
  if (parameters['a08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: a08'))
  }
  if (parameters['a08Month'] !== undefined) {
    form['a08Month'] = parameters['a08Month']
  }
  if (parameters['a09'] !== undefined) {
    form['a09'] = parameters['a09']
  }
  if (parameters['a091'] !== undefined) {
    form['a091'] = parameters['a091']
  }
  if (parameters['b01'] !== undefined) {
    form['b01'] = parameters['b01']
  }
  if (parameters['b01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b01'))
  }
  if (parameters['b02'] !== undefined) {
    form['b02'] = parameters['b02']
  }
  if (parameters['b03'] !== undefined) {
    form['b03'] = parameters['b03']
  }
  if (parameters['b03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b03'))
  }
  if (parameters['b04'] !== undefined) {
    form['b04'] = parameters['b04']
  }
  if (parameters['b04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b04'))
  }
  if (parameters['b041'] !== undefined) {
    form['b041'] = parameters['b041']
  }
  if (parameters['b05'] !== undefined) {
    form['b05'] = parameters['b05']
  }
  if (parameters['b05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b05'))
  }
  if (parameters['b051'] !== undefined) {
    form['b051'] = parameters['b051']
  }
  if (parameters['b06'] !== undefined) {
    form['b06'] = parameters['b06']
  }
  if (parameters['b06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b06'))
  }
  if (parameters['b061'] !== undefined) {
    form['b061'] = parameters['b061']
  }
  if (parameters['b07'] !== undefined) {
    form['b07'] = parameters['b07']
  }
  if (parameters['b07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b07'))
  }
  if (parameters['b071'] !== undefined) {
    form['b071'] = parameters['b071']
  }
  if (parameters['b08Minute'] !== undefined) {
    form['b08Minute'] = parameters['b08Minute']
  }
  if (parameters['b10'] !== undefined) {
    form['b10'] = parameters['b10']
  }
  if (parameters['b10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: b10'))
  }
  if (parameters['c01'] !== undefined) {
    form['c01'] = parameters['c01']
  }
  if (parameters['c01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c01'))
  }
  if (parameters['c01Times'] !== undefined) {
    form['c01Times'] = parameters['c01Times']
  }
  if (parameters['c021'] !== undefined) {
    form['c021'] = parameters['c021']
  }
  if (parameters['c021'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c021'))
  }
  if (parameters['c022'] !== undefined) {
    form['c022'] = parameters['c022']
  }
  if (parameters['c022'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c022'))
  }
  if (parameters['c023'] !== undefined) {
    form['c023'] = parameters['c023']
  }
  if (parameters['c023'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c023'))
  }
  if (parameters['c024'] !== undefined) {
    form['c024'] = parameters['c024']
  }
  if (parameters['c024'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c024'))
  }
  if (parameters['c025'] !== undefined) {
    form['c025'] = parameters['c025']
  }
  if (parameters['c025'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c025'))
  }
  if (parameters['c026'] !== undefined) {
    form['c026'] = parameters['c026']
  }
  if (parameters['c026'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c026'))
  }
  if (parameters['c05'] !== undefined) {
    form['c05'] = parameters['c05']
  }
  if (parameters['c05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c05'))
  }
  if (parameters['c05Times'] !== undefined) {
    form['c05Times'] = parameters['c05Times']
  }
  if (parameters['c06'] !== undefined) {
    form['c06'] = parameters['c06']
  }
  if (parameters['c061'] !== undefined) {
    form['c061'] = parameters['c061']
  }
  if (parameters['c061'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c061'))
  }
  if (parameters['c062'] !== undefined) {
    form['c062'] = parameters['c062']
  }
  if (parameters['c062'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c062'))
  }
  if (parameters['c063'] !== undefined) {
    form['c063'] = parameters['c063']
  }
  if (parameters['c063'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c063'))
  }
  if (parameters['c064'] !== undefined) {
    form['c064'] = parameters['c064']
  }
  if (parameters['c064'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c064'))
  }
  if (parameters['c07'] !== undefined) {
    form['c07'] = parameters['c07']
  }
  if (parameters['c07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c07'))
  }
  if (parameters['c08'] !== undefined) {
    form['c08'] = parameters['c08']
  }
  if (parameters['c08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c08'))
  }
  if (parameters['c121'] !== undefined) {
    form['c121'] = parameters['c121']
  }
  if (parameters['c121'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c121'))
  }
  if (parameters['c122'] !== undefined) {
    form['c122'] = parameters['c122']
  }
  if (parameters['c122'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c122'))
  }
  if (parameters['c123'] !== undefined) {
    form['c123'] = parameters['c123']
  }
  if (parameters['c123'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c123'))
  }
  if (parameters['c124'] !== undefined) {
    form['c124'] = parameters['c124']
  }
  if (parameters['c124'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c124'))
  }
  if (parameters['c125'] !== undefined) {
    form['c125'] = parameters['c125']
  }
  if (parameters['c125'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c125'))
  }
  if (parameters['c126'] !== undefined) {
    form['c126'] = parameters['c126']
  }
  if (parameters['c126'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c126'))
  }
  if (parameters['c127'] !== undefined) {
    form['c127'] = parameters['c127']
  }
  if (parameters['c127'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: c127'))
  }
  if (parameters['d01'] !== undefined) {
    form['d01'] = parameters['d01']
  }
  if (parameters['d01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d01'))
  }
  if (parameters['d01AgeOfFirst'] !== undefined) {
    form['d01AgeOfFirst'] = parameters['d01AgeOfFirst']
  }
  if (parameters['d02'] !== undefined) {
    form['d02'] = parameters['d02']
  }
  if (parameters['d02Day'] !== undefined) {
    form['d02Day'] = parameters['d02Day']
  }
  if (parameters['d03'] !== undefined) {
    form['d03'] = parameters['d03']
  }
  if (parameters['d04'] !== undefined) {
    form['d04'] = parameters['d04']
  }
  if (parameters['d04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d04'))
  }
  if (parameters['d05'] !== undefined) {
    form['d05'] = parameters['d05']
  }
  if (parameters['d05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d05'))
  }
  if (parameters['d05AgeOfFirst'] !== undefined) {
    form['d05AgeOfFirst'] = parameters['d05AgeOfFirst']
  }
  if (parameters['d06'] !== undefined) {
    form['d06'] = parameters['d06']
  }
  if (parameters['d07'] !== undefined) {
    form['d07'] = parameters['d07']
  }
  if (parameters['d08'] !== undefined) {
    form['d08'] = parameters['d08']
  }
  if (parameters['d09'] !== undefined) {
    form['d09'] = parameters['d09']
  }
  if (parameters['d09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d09'))
  }
  if (parameters['d09Description'] !== undefined) {
    form['d09Description'] = parameters['d09Description']
  }
  if (parameters['d10'] !== undefined) {
    form['d10'] = parameters['d10']
  }
  if (parameters['d10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d10'))
  }
  if (parameters['d11'] !== undefined) {
    form['d11'] = parameters['d11']
  }
  if (parameters['d11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d11'))
  }
  if (parameters['d111'] !== undefined) {
    form['d111'] = parameters['d111']
  }
  if (parameters['d12'] !== undefined) {
    form['d12'] = parameters['d12']
  }
  if (parameters['d12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: d12'))
  }
  if (parameters['e01'] !== undefined) {
    form['e01'] = parameters['e01']
  }
  if (parameters['e01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: e01'))
  }
  if (parameters['e011'] !== undefined) {
    form['e011'] = parameters['e011']
  }
  if (parameters['e021'] !== undefined) {
    form['e021'] = parameters['e021']
  }
  if (parameters['e022'] !== undefined) {
    form['e022'] = parameters['e022']
  }
  if (parameters['e023'] !== undefined) {
    form['e023'] = parameters['e023']
  }
  if (parameters['e024'] !== undefined) {
    form['e024'] = parameters['e024']
  }
  if (parameters['e025'] !== undefined) {
    form['e025'] = parameters['e025']
  }
  if (parameters['e026'] !== undefined) {
    form['e026'] = parameters['e026']
  }
  if (parameters['e027'] !== undefined) {
    form['e027'] = parameters['e027']
  }
  if (parameters['e028'] !== undefined) {
    form['e028'] = parameters['e028']
  }
  if (parameters['e029'] !== undefined) {
    form['e029'] = parameters['e029']
  }
  if (parameters['f01'] !== undefined) {
    form['f01'] = parameters['f01']
  }
  if (parameters['f01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f01'))
  }
  if (parameters['f02'] !== undefined) {
    form['f02'] = parameters['f02']
  }
  if (parameters['f02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f02'))
  }
  if (parameters['f03'] !== undefined) {
    form['f03'] = parameters['f03']
  }
  if (parameters['f03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f03'))
  }
  if (parameters['f03Time'] !== undefined) {
    form['f03Time'] = parameters['f03Time']
  }
  if (parameters['f04'] !== undefined) {
    form['f04'] = parameters['f04']
  }
  if (parameters['f04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f04'))
  }
  if (parameters['f05'] !== undefined) {
    form['f05'] = parameters['f05']
  }
  if (parameters['f05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f05'))
  }
  if (parameters['f06'] !== undefined) {
    form['f06'] = parameters['f06']
  }
  if (parameters['f06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: f06'))
  }
  if (parameters['f06Time'] !== undefined) {
    form['f06Time'] = parameters['f06Time']
  }
  if (parameters['g01'] !== undefined) {
    form['g01'] = parameters['g01']
  }
  if (parameters['g01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: g01'))
  }
  if (parameters['h01'] !== undefined) {
    form['h01'] = parameters['h01']
  }
  if (parameters['h01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: h01'))
  }
  if (parameters['h01AgeOfFirst'] !== undefined) {
    form['h01AgeOfFirst'] = parameters['h01AgeOfFirst']
  }
  if (parameters['h02'] !== undefined) {
    form['h02'] = parameters['h02']
  }
  if (parameters['h03'] !== undefined) {
    form['h03'] = parameters['h03']
  }
  if (parameters['h04'] !== undefined) {
    form['h04'] = parameters['h04']
  }
  if (parameters['h05'] !== undefined) {
    form['h05'] = parameters['h05']
  }
  if (parameters['i01'] !== undefined) {
    form['i01'] = parameters['i01']
  }
  if (parameters['i01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i01'))
  }
  if (parameters['i02'] !== undefined) {
    form['i02'] = parameters['i02']
  }
  if (parameters['i02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i02'))
  }
  if (parameters['i03'] !== undefined) {
    form['i03'] = parameters['i03']
  }
  if (parameters['i03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: i03'))
  }
  if (parameters['i04'] !== undefined) {
    form['i04'] = parameters['i04']
  }
  if (parameters['i05'] !== undefined) {
    form['i05'] = parameters['i05']
  }
  if (parameters['i06'] !== undefined) {
    form['i06'] = parameters['i06']
  }
  if (parameters['i07'] !== undefined) {
    form['i07'] = parameters['i07']
  }
  if (parameters['i08'] !== undefined) {
    form['i08'] = parameters['i08']
  }
  if (parameters['i09'] !== undefined) {
    form['i09'] = parameters['i09']
  }
  if (parameters['j01'] !== undefined) {
    form['j01'] = parameters['j01']
  }
  if (parameters['j01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j01'))
  }
  if (parameters['j02'] !== undefined) {
    form['j02'] = parameters['j02']
  }
  if (parameters['j02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j02'))
  }
  if (parameters['j03'] !== undefined) {
    form['j03'] = parameters['j03']
  }
  if (parameters['j03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j03'))
  }
  if (parameters['j04'] !== undefined) {
    form['j04'] = parameters['j04']
  }
  if (parameters['j04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j04'))
  }
  if (parameters['j05'] !== undefined) {
    form['j05'] = parameters['j05']
  }
  if (parameters['j05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j05'))
  }
  if (parameters['j06'] !== undefined) {
    form['j06'] = parameters['j06']
  }
  if (parameters['j06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j06'))
  }
  if (parameters['j07'] !== undefined) {
    form['j07'] = parameters['j07']
  }
  if (parameters['j07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j07'))
  }
  if (parameters['j08'] !== undefined) {
    form['j08'] = parameters['j08']
  }
  if (parameters['j08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j08'))
  }
  if (parameters['j09'] !== undefined) {
    form['j09'] = parameters['j09']
  }
  if (parameters['j09'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j09'))
  }
  if (parameters['j10'] !== undefined) {
    form['j10'] = parameters['j10']
  }
  if (parameters['j10'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j10'))
  }
  if (parameters['j11'] !== undefined) {
    form['j11'] = parameters['j11']
  }
  if (parameters['j11'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j11'))
  }
  if (parameters['j12'] !== undefined) {
    form['j12'] = parameters['j12']
  }
  if (parameters['j12'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j12'))
  }
  if (parameters['j13'] !== undefined) {
    form['j13'] = parameters['j13']
  }
  if (parameters['j13'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j13'))
  }
  if (parameters['j14'] !== undefined) {
    form['j14'] = parameters['j14']
  }
  if (parameters['j14'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j14'))
  }
  if (parameters['j15'] !== undefined) {
    form['j15'] = parameters['j15']
  }
  if (parameters['j15'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j15'))
  }
  if (parameters['j16'] !== undefined) {
    form['j16'] = parameters['j16']
  }
  if (parameters['j16'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j16'))
  }
  if (parameters['j17'] !== undefined) {
    form['j17'] = parameters['j17']
  }
  if (parameters['j17'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j17'))
  }
  if (parameters['j18'] !== undefined) {
    form['j18'] = parameters['j18']
  }
  if (parameters['j18'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j18'))
  }
  if (parameters['j19'] !== undefined) {
    form['j19'] = parameters['j19']
  }
  if (parameters['j19'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j19'))
  }
  if (parameters['j20'] !== undefined) {
    form['j20'] = parameters['j20']
  }
  if (parameters['j20'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j20'))
  }
  if (parameters['j21'] !== undefined) {
    form['j21'] = parameters['j21']
  }
  if (parameters['j21'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j21'))
  }
  if (parameters['j22'] !== undefined) {
    form['j22'] = parameters['j22']
  }
  if (parameters['j22'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j22'))
  }
  if (parameters['j23'] !== undefined) {
    form['j23'] = parameters['j23']
  }
  if (parameters['j23'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j23'))
  }
  if (parameters['j24'] !== undefined) {
    form['j24'] = parameters['j24']
  }
  if (parameters['j24'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j24'))
  }
  if (parameters['j25'] !== undefined) {
    form['j25'] = parameters['j25']
  }
  if (parameters['j25'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j25'))
  }
  if (parameters['j26'] !== undefined) {
    form['j26'] = parameters['j26']
  }
  if (parameters['j26'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j26'))
  }
  if (parameters['j27'] !== undefined) {
    form['j27'] = parameters['j27']
  }
  if (parameters['j27'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j27'))
  }
  if (parameters['j28'] !== undefined) {
    form['j28'] = parameters['j28']
  }
  if (parameters['j28'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: j28'))
  }
  if (parameters['j281'] !== undefined) {
    form['j281'] = parameters['j281']
  }
  if (parameters['j282'] !== undefined) {
    form['j282'] = parameters['j282']
  }
  if (parameters['l01'] !== undefined) {
    form['l01'] = parameters['l01']
  }
  if (parameters['l01'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l01'))
  }
  if (parameters['l02'] !== undefined) {
    form['l02'] = parameters['l02']
  }
  if (parameters['l02'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l02'))
  }
  if (parameters['l03'] !== undefined) {
    form['l03'] = parameters['l03']
  }
  if (parameters['l03'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l03'))
  }
  if (parameters['l03Week'] !== undefined) {
    form['l03Week'] = parameters['l03Week']
  }
  if (parameters['l04'] !== undefined) {
    form['l04'] = parameters['l04']
  }
  if (parameters['l04'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l04'))
  }
  if (parameters['l05'] !== undefined) {
    form['l05'] = parameters['l05']
  }
  if (parameters['l05'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l05'))
  }
  if (parameters['l06'] !== undefined) {
    form['l06'] = parameters['l06']
  }
  if (parameters['l06'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l06'))
  }
  if (parameters['l07'] !== undefined) {
    form['l07'] = parameters['l07']
  }
  if (parameters['l07'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l07'))
  }
  if (parameters['l08'] !== undefined) {
    form['l08'] = parameters['l08']
  }
  if (parameters['l08'] === undefined) {
    return Promise.reject(new Error('Missing required  parameter: l08'))
  }
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    });
  }
  return request('post', domain + path, body, queryParameters, form, config)
}
export const yljgUshiqSaveFormData = function(formData) {
  const url = yljgUshiqSave_RAW_URL()
  const method = yljgUshiqSave_TYPE()
  return axios[method](url, formData)
}
export const yljgUshiqSave_RAW_URL = function() {
  return '/yljg/ushiq/save/{studentId}'
}
export const yljgUshiqSave_TYPE = function() {
  return 'post'
}
export const yljgUshiqSave_URL = function(parameters = {}) {
  let queryParameters = {}
  const domain = parameters.$domain ? parameters.$domain : getDomain()
  let path = '/yljg/ushiq/save/{studentId}'
  path = path.replace('{studentId}', `${parameters['studentId']}`)
  if (parameters.$queryParameters) {
    Object.keys(parameters.$queryParameters).forEach(function(parameterName) {
      queryParameters[parameterName] = parameters.$queryParameters[parameterName]
    })
  }
  let keys = Object.keys(queryParameters)
  return domain + path + (keys.length > 0 ? '?' + (keys.map(key => key + '=' + encodeURIComponent(queryParameters[key])).join('&')) : '')
}