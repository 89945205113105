import _ from 'lodash'
import conf from './constant.yaml'
export default {
  data: conf,
  equal (key, value) {
    const getValue = _.get(conf, key)
    return getValue.value === value
  },
  equalLabel (key, label) {
    const getValue = _.get(conf, key)
    return getValue.label === label
  },
  findKeyByValue (key, value, field) {
    const item = conf[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i][field]
        }
      }
    } else {
      return ''
    }
  },
  findValue (key, label) {
    const item = conf[key]
    if (item) {
      for (var i in item) {
        if (item[i].label === label) {
          return item[i].value
        }
      }
    } else {
      return ''
    }
  },
  findLabel (key, value) {
    const item = conf[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i].label
        }
      }
    } else {
      return ''
    }
  },
  findFieldByValue (key, value) {
    const item = conf[key]
    if (item) {
      for (var i in item) {
        if (item[i].value === value) {
          return item[i]
        }
      }
    } else {
      return ''
    }
  },
  findFieldByLabel (key, label) {
    const item = conf[key]
    if (item) {
      for (var i in item) {
        if (item[i].label === label) {
          return item[i]
        }
      }
    } else {
      return ''
    }
  },
  getOptions (key, returnKey = 'label', returnValue = 'value') {
    const items = conf[key]
    if (items) {
      const result = []
      for (let i in items) {
        const it = items[i]
        result.push({ [returnKey]: it.label, [returnValue]: it.value })
      }
      return result
    } else {
      if (process.env.NODE_ENV === 'production') {
        return []
      } else {
        console.error(`没有${key}这个字段`)
      }
    }
  }
}
