<template lang="pug">
  .ListView
    div(
      v-infinite-scroll="loadMore",
      infinite-scroll-disabled="scrollDisabled",
      infinite-scroll-immediate-check="true",
      infinite-scroll-distance="0")
      slot(v-for="item in rowDetail",:row="item")
    div(v-if="dirty && rowDetail.length < 1").center.m1.p2.empty-content
      i.iconfont.icon-empty.gray(style="font-size:80px;")
      p.gray {{empty}}
    template(v-else)
      div.flex.justify-center.mt1.items-center(v-show="loading")
        mt-spinner(type="fading-circle")
        div.ml2 加载中...
      div.btn-more.flex.items-center.justify-center.full-width(v-show="!loading")
        div(v-show="!scrollDisabled",@click="reload()").full-width.center.wechat-green 点击此处加载更多
        div(v-if="loadStatus === 'loadAll'",style="font-size:13px").gray 数据已全部加载
</template>

<style scoped>
  .btn-more{
    background: white;
    height: 40px;
  }
  .empty-content{
    /* border:1px dashed lightGray; */
  }
</style>

<script>
import axios from 'axios'
export default {
  name: 'ListView',
  props: {
    url: {
      type: String,
      default: ''
    },
    pageSize: {
      type: Number,
      default: 10
    },
    immediate: {
      type: Boolean,
      default: true
    },
    autoLoad: {
      type: Boolean,
      default: true
    },
    pagination: {
      type: Boolean,
      default: true
    },
    method: {
      type: String,
      default: 'get'
    },
    filter: {
      type: Function,
      default (arr) {
        return arr
      }
    },
    queryParams: {
      type: Object,
      default () {
        return {}
      }
    },
    empty: {
      type: String,
      default: '暂无数据'
    }
  },
  data () {
    return {
      params: {},
      rowDetail: [],
      scrollDisabled: false,
      currentPage: 1,
      loading: false,
      query: null,
      dirty: false,
      loadStatus: '',
      totalPage: 10
    }
  },
  watch: {
    url (val) {
      if (val) {
        this.load()
      }
    },
    queryParams (val) {
      this.query = this.queryParams
      this.dirty = false
      this.loading = true
      this.reload()
    }
  },
  methods: {
    loadMore () {
      if (this.autoLoad) {
        this.reload()
      }
    },
    async request () {
      let res
      this.loading = true
      try {
        if (this.method === 'get') {
          res = await axios.get(this.url, {params: this.params})
        } else {
          res = await axios[this.method](this.url, this.params)
        }
        this.loading = false
        if (res) {
          this.dirty = true
          const total = res.data.total
          this.totalPage = Math.ceil(total / 10)
          if (!res.data.rows) {
            this.rowDetail.push(...this.filter(res.data))
            this.scrollDisabled = true
            this.loadStatus = 'loadAll'
          } else {
            this.rowDetail.push(...this.filter(res.data.rows))
            this.currentPage = this.currentPage + 1
            if (this.rowDetail.length < total) {
              this.scrollDisabled = false
            } else {
              this.scrollDisabled = true
              this.loadStatus = 'loadAll'
            }
          }
          this.$emit('success', res.data)
        }
      } catch (e) {
        console.log(e)
      }
    },
    reset () {
      this.rowDetail = []
      this.params.page = 1
      this.currentPage = 1
    },
    reload (query = this.query, method = this.method) {
      this.scrollDisabled = true
      let params = {}
      if (this.pagination) {
        params.rows = this.pageSize
        params.page = this.currentPage
      } else {
        // 文章每次加载10项
        params.rows = 10
        if (this.currentPage <= this.totalPage) {
          params.page = this.currentPage
        }
      }
      this.query = query
      Object.assign(params, query)
      this.params = params
      this.request()
    },
    load (query = this.query, method = this.method) {
      this.reset()
      this.reload(query, method)
    }
  },
  mounted () {
    this.query = this.queryParams
    if (this.immediate) {
      this.reload()
    }
  },
  computed: {},
  components: {}
}
</script>
