module.exports = {
	"SEX": {
		"FMALE": {
			"label": "女",
			"value": 2
		},
		"MALE": {
			"label": "男",
			"value": 1
		}
	},
	"REGION_TYPE": {
		"PROVINCE": {
			"value": 1,
			"label": "省"
		},
		"CITY": {
			"value": 2,
			"label": "市"
		},
		"DISTRICT": {
			"value": 3,
			"label": "区"
		}
	},
	"SCHOOL_TYPE": {
		"KINDERGARTEN": {
			"value": 1,
			"label": "幼儿园"
		},
		"PRIMARYSCHOOL": {
			"value": 2,
			"label": "小学"
		},
		"MIDDLESCHOOL": {
			"value": 4,
			"label": "初中"
		},
		"HIGHSCHOOL": {
			"value": 8,
			"label": "高中"
		},
		"PROFESSIONALSCHOOL": {
			"value": 16,
			"label": "职高"
		},
		"UNIVERSITY": {
			"value": 32,
			"label": "大学"
		}
	},
	"TOOTH_TYPE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"ABNORMAL": {
			"value": 4,
			"label": "龋齿"
		},
		"OTHER": {
			"value": 2,
			"label": "其他"
		}
	},
	"SHCOOL_QUESTIONNAIRE": {
		"PRIMARY_SCHOOOL": {
			"label": "小学学生健康状况及影响因素调查表管理",
			"value": 0
		},
		"MIDDLE_SCHOOL": {
			"label": "中学学生健康状况及影响因素调查表管理",
			"value": 1
		},
		"UNIVERSITY": {
			"label": "大学学生健康状况及影响因素调查表管理",
			"value": 2
		},
		"POOR_VISUALACUITY": {
			"label": "学生视力不良、脊柱弯曲异常及影响因素专项调查表",
			"value": 3
		},
		"SCHOOL_ENVIRONMENT": {
			"label": "学校环境健康影响因素调查表",
			"value": 4
		},
		"HEALTH_QUESTIONNAIRE": {
			"label": "管理部门学校卫生工作调查表",
			"value": 5
		},
		"PRIMARYSECONDARY_HEALTH_QUESTIONNAIRE": {
			"label": "中小学校开展学校卫生工作情况调查表",
			"value": 6
		},
		"COVID_POOR_VISUALACUITY": {
			"label": "2021年疫情防控期间学生视力影响因素专项调查表",
			"value": 7
		},
		"VISION_RELATED_BEHAVIOR": {
			"label": "教育部中小学生视力相关行为调查表",
			"value": 8
		},
		"COVID_19_PREVENTION_CONTROL_KNOWLEDGE": {
			"label": "学生新冠肺炎防控知识行为专题调查表",
			"value": 9
		}
	},
	"TASK_TYPE": {
		"COLLECT": {
			"value": 1,
			"label": "收集中"
		},
		"END": {
			"value": 2,
			"label": "已结束"
		}
	},
	"ABNORMAL_STATE": {
		"NORMAL": {
			"value": 1,
			"label": "正常"
		},
		"INNORMAL": {
			"value": 2,
			"label": "异常"
		}
	},
	"TRACHOMA": {
		"INNORMAL": {
			"value": 1,
			"label": "有"
		},
		"NORMAL": {
			"value": 2,
			"label": "无"
		}
	},
	"ABNORMAL_STATE1": {
		"NO_ABNORMALITIES": {
			"value": 1,
			"label": "未见异常"
		},
		"INNORMAL": {
			"value": 2,
			"label": "异常"
		}
	},
	"ABNORMAL_VISION": {
		"NOTHING": {
			"value": 1,
			"label": "无"
		},
		"PHOTOPHOBIA": {
			"value": 2,
			"label": "畏光"
		},
		"SQUINT": {
			"value": 3,
			"label": "眯眼"
		},
		"SLANTING_HEAD": {
			"value": 4,
			"label": "歪头"
		},
		"OTHER": {
			"value": 5,
			"label": "其他"
		}
	},
	"SHILIB": {
		"LOGARITHMIC_CHART": {
			"label": "标准对数视力表(3.5周岁以上)",
			"value": 1
		},
		"CHART_VISUAL_ACUITY": {
			"label": "图形视力表(2.5-3.5岁)",
			"value": 2
		},
		"OTHER": {
			"label": "其他",
			"value": 3
		}
	},
	"SPECTACLES_SITUATION": {
		"WEAR_FRAME_GLASSES": {
			"label": "佩戴框架眼镜",
			"value": 1
		},
		"WEAR_CONTACT_GLASSES": {
			"label": "佩戴隐形眼镜",
			"value": 2
		},
		"KERATOPLASTY_MIRROR": {
			"label": "佩戴角膜塑形镜",
			"value": 3
		},
		"WITHOUT_GLASSES": {
			"label": "不戴镜",
			"value": 4
		}
	},
	"EYEVISION1": {
		"SEVENTEEN": {
			"value": 0,
			"label": "0.0(0.0)"
		},
		"EIGHTTEEN": {
			"value": 3.3,
			"label": "3.3(0.02)"
		},
		"NIGHTTEEN": {
			"value": 3.4,
			"label": "3.4(0.025)"
		},
		"TWENTY": {
			"value": 3.5,
			"label": "3.5(0.03)"
		},
		"TWENTYONE": {
			"value": 3.6,
			"label": "3.6(0.04)"
		},
		"TWENTYTWO": {
			"value": 3.7,
			"label": "3.7(0.05)"
		},
		"TWENTYTHREE": {
			"value": 3.8,
			"label": "3.8(0.06)"
		},
		"TWENTYFOUR": {
			"value": 3.9,
			"label": "3.9(0.08)"
		},
		"ONE": {
			"value": 4,
			"label": "4.0(0.1)"
		},
		"TWO": {
			"value": 4.1,
			"label": "4.1(0.12)"
		},
		"THIRD": {
			"value": 4.2,
			"label": "4.2(0.15)"
		},
		"FOUR": {
			"value": 4.3,
			"label": "4.3(0.2)"
		},
		"FIVE": {
			"value": 4.4,
			"label": "4.4(0.25)"
		},
		"SIX": {
			"value": 4.5,
			"label": "4.5(0.3)"
		},
		"SEVEN": {
			"value": 4.6,
			"label": "4.6(0.4)"
		},
		"EIGHT": {
			"value": 4.7,
			"label": "4.7(0.5)"
		},
		"NIGHT": {
			"value": 4.8,
			"label": "4.8(0.6)"
		},
		"TEN": {
			"value": 4.9,
			"label": "4.9(0.8)"
		},
		"ELEVEN": {
			"value": 5,
			"label": "5.0(1.0)"
		},
		"TWELVE": {
			"value": 5.1,
			"label": "5.1(1.2)"
		},
		"THIRTEEN": {
			"value": 5.2,
			"label": "5.2(1.5)"
		},
		"FOURTEEN": {
			"value": 5.3,
			"label": "5.3(2.0)"
		}
	},
	"LATERAL_BEND_DEGREES": {
		"ONE": {
			"value": 1,
			"label": "I度"
		},
		"TWO": {
			"value": 2,
			"label": "II度"
		},
		"THIRD": {
			"value": 3,
			"label": "III度"
		}
	},
	"FRONT_BACK_BEND": {
		"NOT_HAVE": {
			"value": 1,
			"label": "无前后弯曲异常"
		},
		"STRAIGHT_BACK": {
			"value": 2,
			"label": "直背"
		},
		"FRONT_BEND": {
			"value": 3,
			"label": "前凸异常"
		},
		"BACK_BEND": {
			"value": 4,
			"label": "后凸异常"
		}
	},
	"EXCEPTION_TYPE": {
		"MYOPIA": {
			"value": 1,
			"label": "近视"
		},
		"CARIES": {
			"value": 2,
			"label": "患龋"
		},
		"LOSS_OF_TEETH": {
			"value": 4,
			"label": "患失"
		},
		"TOOTH_REPAIR": {
			"value": 8,
			"label": "患补"
		},
		"OVER_WEIGHT": {
			"value": 16,
			"label": "超重"
		},
		"ADIPOSITY": {
			"value": 32,
			"label": "肥胖"
		},
		"DELAYED_GROWTH": {
			"value": 64,
			"label": "生长迟缓"
		},
		"EMACIATION": {
			"value": 128,
			"label": "消瘦"
		},
		"UNDER_WEIGHT": {
			"value": 256,
			"label": "体重过低"
		},
		"HIGH_BLOOD_PRESSURE": {
			"value": 512,
			"label": "血压偏高"
		},
		"SCOLIOSIS": {
			"value": 1024,
			"label": "脊柱侧弯"
		},
		"SPINE_FRONT_BACK_CURVATURE": {
			"value": 2048,
			"label": "脊柱前后弯曲"
		},
		"MENSTRUATION": {
			"value": 4096,
			"label": "月经"
		},
		"SPERMATORRHEA": {
			"value": 8192,
			"label": "遗精"
		},
		"VISION_EARLY_WARNING_0": {
			"value": 16384,
			"label": "0级视力预警"
		},
		"DIOPTER_EARLY_WARNING_0": {
			"value": 32768,
			"label": "0级屈光预警"
		}
	},
	"REPORT_ECHARTS_TYPE": {
		"VISION_DEVELOPMENT_TREND": {
			"value": 1,
			"label": "视力进展趋势"
		},
		"DEVELOPMENT_TREND_OF_MYOPIA": {
			"value": 2,
			"label": "近视进展趋势"
		},
		"DEVELOPMENT_TREND_OF_ASTIGMATISM": {
			"value": 3,
			"label": "散光进展趋势"
		}
	},
	"TYPES_OF_PREVENTION_AND_CONTROL": {
		"WEAR_FRAME_GLASSES": {
			"label": "框架眼镜防控",
			"value": 1,
			"percentage": 0.25
		},
		"WEAR_CONTACT_GLASSES": {
			"label": "隐形眼镜防控",
			"value": 2,
			"percentage": 0.32
		},
		"KERATOPLASTY_MIRROR": {
			"label": "夜戴角膜塑形镜防控",
			"value": 3,
			"percentage": 0.43
		},
		"WITHOUT_GLASSES": {
			"label": "无近视防控",
			"value": 4,
			"percentage": 0
		}
	},
	"BMI": {
		"TYPE1": {
			"minAge": 6,
			"maxAge": 6.5,
			"maleOverWeightBim": 16.4,
			"maleObesityBim": 17.7,
			"fmaleOverWeightBim": 16.2,
			"fmaleObesityBim": 17.5
		},
		"TYPE2": {
			"minAge": 6.5,
			"maxAge": 7,
			"maleOverWeightBim": 16.7,
			"maleObesityBim": 18.1,
			"fmaleOverWeightBim": 16.5,
			"fmaleObesityBim": 18
		},
		"TYPE3": {
			"minAge": 7,
			"maxAge": 7.5,
			"maleOverWeightBim": 17,
			"maleObesityBim": 18.7,
			"fmaleOverWeightBim": 16.8,
			"fmaleObesityBim": 18.5
		},
		"TYPE4": {
			"minAge": 7.5,
			"maxAge": 8,
			"maleOverWeightBim": 17.4,
			"maleObesityBim": 19.2,
			"fmaleOverWeightBim": 17.2,
			"fmaleObesityBim": 19
		},
		"TYPE5": {
			"minAge": 8,
			"maxAge": 8.5,
			"maleOverWeightBim": 17.8,
			"maleObesityBim": 19.7,
			"fmaleOverWeightBim": 17.6,
			"fmaleObesityBim": 19.4
		},
		"TYPE6": {
			"minAge": 8.5,
			"maxAge": 9,
			"maleOverWeightBim": 18.1,
			"maleObesityBim": 20.3,
			"fmaleOverWeightBim": 18.1,
			"fmaleObesityBim": 19.9
		},
		"TYPE7": {
			"minAge": 9,
			"maxAge": 9.5,
			"maleOverWeightBim": 18.5,
			"maleObesityBim": 20.8,
			"fmaleOverWeightBim": 18.5,
			"fmaleObesityBim": 20.4
		},
		"TYPE8": {
			"minAge": 9.5,
			"maxAge": 10,
			"maleOverWeightBim": 18.9,
			"maleObesityBim": 21.4,
			"fmaleOverWeightBim": 19,
			"fmaleObesityBim": 21
		},
		"TYPE9": {
			"minAge": 10,
			"maxAge": 10.5,
			"maleOverWeightBim": 19.2,
			"maleObesityBim": 21.9,
			"fmaleOverWeightBim": 19.5,
			"fmaleObesityBim": 21.5
		},
		"TYPE10": {
			"minAge": 10.5,
			"maxAge": 11,
			"maleOverWeightBim": 19.6,
			"maleObesityBim": 22.5,
			"fmaleOverWeightBim": 20,
			"fmaleObesityBim": 22.1
		},
		"TYPE11": {
			"minAge": 11,
			"maxAge": 11.5,
			"maleOverWeightBim": 19.9,
			"maleObesityBim": 23,
			"fmaleOverWeightBim": 20.5,
			"fmaleObesityBim": 22.7
		},
		"TYPE12": {
			"minAge": 11.5,
			"maxAge": 12,
			"maleOverWeightBim": 20.3,
			"maleObesityBim": 23.6,
			"fmaleOverWeightBim": 21.1,
			"fmaleObesityBim": 23.3
		},
		"TYPE13": {
			"minAge": 12,
			"maxAge": 12.5,
			"maleOverWeightBim": 20.7,
			"maleObesityBim": 24.1,
			"fmaleOverWeightBim": 21.5,
			"fmaleObesityBim": 23.9
		},
		"TYPE14": {
			"minAge": 12.5,
			"maxAge": 13,
			"maleOverWeightBim": 21,
			"maleObesityBim": 24.7,
			"fmaleOverWeightBim": 21.9,
			"fmaleObesityBim": 24.5
		},
		"TYPE15": {
			"minAge": 13,
			"maxAge": 13.5,
			"maleOverWeightBim": 21.4,
			"maleObesityBim": 25.2,
			"fmaleOverWeightBim": 22.2,
			"fmaleObesityBim": 25
		},
		"TYPE16": {
			"minAge": 13.5,
			"maxAge": 14,
			"maleOverWeightBim": 21.9,
			"maleObesityBim": 25.7,
			"fmaleOverWeightBim": 22.6,
			"fmaleObesityBim": 25.6
		},
		"TYPE17": {
			"minAge": 14,
			"maxAge": 14.5,
			"maleOverWeightBim": 22.3,
			"maleObesityBim": 26.1,
			"fmaleOverWeightBim": 22.8,
			"fmaleObesityBim": 25.9
		},
		"TYPE18": {
			"minAge": 14.5,
			"maxAge": 15,
			"maleOverWeightBim": 22.6,
			"maleObesityBim": 26.4,
			"fmaleOverWeightBim": 23,
			"fmaleObesityBim": 26.3
		},
		"TYPE19": {
			"minAge": 15,
			"maxAge": 15.5,
			"maleOverWeightBim": 22.9,
			"maleObesityBim": 26.6,
			"fmaleOverWeightBim": 23.2,
			"fmaleObesityBim": 26.6
		},
		"TYPE20": {
			"minAge": 15.5,
			"maxAge": 16,
			"maleOverWeightBim": 23.1,
			"maleObesityBim": 26.9,
			"fmaleOverWeightBim": 23.4,
			"fmaleObesityBim": 26.9
		},
		"TYPE21": {
			"minAge": 16,
			"maxAge": 16.5,
			"maleOverWeightBim": 23.3,
			"maleObesityBim": 27.1,
			"fmaleOverWeightBim": 23.6,
			"fmaleObesityBim": 27.1
		},
		"TYPE22": {
			"minAge": 16.5,
			"maxAge": 17,
			"maleOverWeightBim": 23.5,
			"maleObesityBim": 27.4,
			"fmaleOverWeightBim": 23.7,
			"fmaleObesityBim": 27.4
		},
		"TYPE23": {
			"minAge": 17,
			"maxAge": 17.5,
			"maleOverWeightBim": 23.7,
			"maleObesityBim": 27.6,
			"fmaleOverWeightBim": 23.8,
			"fmaleObesityBim": 27.6
		},
		"TYPE24": {
			"minAge": 17.5,
			"maxAge": 18,
			"maleOverWeightBim": 23.8,
			"maleObesityBim": 27.8,
			"fmaleOverWeightBim": 23.9,
			"fmaleObesityBim": 27.8
		},
		"TYPE25": {
			"minAge": 18,
			"maxAge": 25,
			"maleOverWeightBim": 24,
			"maleObesityBim": 28,
			"fmaleOverWeightBim": 24,
			"fmaleObesityBim": 28
		}
	},
	"GROWTH_RETARDATION": {
		"TYPE1": {
			"minAge": 6,
			"maxAge": 6.5,
			"maleHeight": 106.3,
			"fmaleHeight": 105.7
		},
		"TYPE2": {
			"minAge": 6.5,
			"maxAge": 7,
			"maleHeight": 109.5,
			"fmaleHeight": 108
		},
		"TYPE3": {
			"minAge": 7,
			"maxAge": 7.5,
			"maleHeight": 111.3,
			"fmaleHeight": 110.2
		},
		"TYPE4": {
			"minAge": 7.5,
			"maxAge": 8,
			"maleHeight": 112.8,
			"fmaleHeight": 111.8
		},
		"TYPE5": {
			"minAge": 8,
			"maxAge": 8.5,
			"maleHeight": 115.4,
			"fmaleHeight": 114.5
		},
		"TYPE6": {
			"minAge": 8.5,
			"maxAge": 9,
			"maleHeight": 117.6,
			"fmaleHeight": 116.8
		},
		"TYPE7": {
			"minAge": 9,
			"maxAge": 9.5,
			"maleHeight": 120.6,
			"fmaleHeight": 119.5
		},
		"TYPE8": {
			"minAge": 9.5,
			"maxAge": 10,
			"maleHeight": 123,
			"fmaleHeight": 121.7
		},
		"TYPE9": {
			"minAge": 10,
			"maxAge": 10.5,
			"maleHeight": 125.2,
			"fmaleHeight": 123.9
		},
		"TYPE10": {
			"minAge": 10.5,
			"maxAge": 11,
			"maleHeight": 127,
			"fmaleHeight": 125.7
		},
		"TYPE11": {
			"minAge": 11,
			"maxAge": 11.5,
			"maleHeight": 129.1,
			"fmaleHeight": 128.6
		},
		"TYPE12": {
			"minAge": 11.5,
			"maxAge": 12,
			"maleHeight": 130.8,
			"fmaleHeight": 131
		},
		"TYPE13": {
			"minAge": 12,
			"maxAge": 12.5,
			"maleHeight": 133.1,
			"fmaleHeight": 133.6
		},
		"TYPE14": {
			"minAge": 12.5,
			"maxAge": 13,
			"maleHeight": 134.9,
			"fmaleHeight": 135.7
		},
		"TYPE15": {
			"minAge": 13,
			"maxAge": 13.5,
			"maleHeight": 136.9,
			"fmaleHeight": 138.8
		},
		"TYPE16": {
			"minAge": 13.5,
			"maxAge": 14,
			"maleHeight": 138.6,
			"fmaleHeight": 141.4
		},
		"TYPE17": {
			"minAge": 14,
			"maxAge": 14.5,
			"maleHeight": 141.9,
			"fmaleHeight": 142.9
		},
		"TYPE18": {
			"minAge": 14.5,
			"maxAge": 15,
			"maleHeight": 144.7,
			"fmaleHeight": 144.1
		},
		"TYPE19": {
			"minAge": 15,
			"maxAge": 15.5,
			"maleHeight": 149.6,
			"fmaleHeight": 145.4
		},
		"TYPE20": {
			"minAge": 15.5,
			"maxAge": 16,
			"maleHeight": 153.6,
			"fmaleHeight": 146.5
		},
		"TYPE21": {
			"minAge": 16,
			"maxAge": 16.5,
			"maleHeight": 155.1,
			"fmaleHeight": 146.8
		},
		"TYPE22": {
			"minAge": 16.5,
			"maxAge": 17,
			"maleHeight": 156.4,
			"fmaleHeight": 147
		},
		"TYPE23": {
			"minAge": 17,
			"maxAge": 17.5,
			"maleHeight": 156.8,
			"fmaleHeight": 147.3
		},
		"TYPE24": {
			"minAge": 17.5,
			"maxAge": 25,
			"maleHeight": 157.1,
			"fmaleHeight": 147.5
		}
	},
	"EMACIATION": {
		"TYPE1": {
			"minAge": 6,
			"maxAge": 6.5,
			"maleWeight": 13.4,
			"fmaleWeight": 13.1
		},
		"TYPE2": {
			"minAge": 6.5,
			"maxAge": 7,
			"maleWeight": 13.8,
			"fmaleWeight": 13.3
		},
		"TYPE3": {
			"minAge": 7,
			"maxAge": 7.5,
			"maleWeight": 13.9,
			"fmaleWeight": 13.4
		},
		"TYPE4": {
			"minAge": 7.5,
			"maxAge": 8,
			"maleWeight": 13.9,
			"fmaleWeight": 13.5
		},
		"TYPE5": {
			"minAge": 8,
			"maxAge": 8.5,
			"maleWeight": 14,
			"fmaleWeight": 13.6
		},
		"TYPE6": {
			"minAge": 8.5,
			"maxAge": 9,
			"maleWeight": 14,
			"fmaleWeight": 13.7
		},
		"TYPE7": {
			"minAge": 9,
			"maxAge": 9.5,
			"maleWeight": 14.1,
			"fmaleWeight": 13.8
		},
		"TYPE8": {
			"minAge": 9.5,
			"maxAge": 10,
			"maleWeight": 14.2,
			"fmaleWeight": 13.9
		},
		"TYPE9": {
			"minAge": 10,
			"maxAge": 10.5,
			"maleWeight": 14.4,
			"fmaleWeight": 14
		},
		"TYPE10": {
			"minAge": 10.5,
			"maxAge": 11,
			"maleWeight": 14.6,
			"fmaleWeight": 14.1
		},
		"TYPE11": {
			"minAge": 11,
			"maxAge": 11.5,
			"maleWeight": 14.9,
			"fmaleWeight": 14.3
		},
		"TYPE12": {
			"minAge": 11.5,
			"maxAge": 12,
			"maleWeight": 15.1,
			"fmaleWeight": 14.5
		},
		"TYPE13": {
			"minAge": 12,
			"maxAge": 12.5,
			"maleWeight": 15.4,
			"fmaleWeight": 14.7
		},
		"TYPE14": {
			"minAge": 12.5,
			"maxAge": 13,
			"maleWeight": 15.6,
			"fmaleWeight": 14.9
		},
		"TYPE15": {
			"minAge": 13,
			"maxAge": 13.5,
			"maleWeight": 15.9,
			"fmaleWeight": 15.3
		},
		"TYPE16": {
			"minAge": 13.5,
			"maxAge": 14,
			"maleWeight": 16.1,
			"fmaleWeight": 15.6
		},
		"TYPE17": {
			"minAge": 14,
			"maxAge": 14.5,
			"maleWeight": 16.4,
			"fmaleWeight": 16
		},
		"TYPE18": {
			"minAge": 14.5,
			"maxAge": 15,
			"maleWeight": 16.7,
			"fmaleWeight": 16.3
		},
		"TYPE19": {
			"minAge": 15,
			"maxAge": 15.5,
			"maleWeight": 16.9,
			"fmaleWeight": 16.6
		},
		"TYPE20": {
			"minAge": 15.5,
			"maxAge": 16,
			"maleWeight": 17,
			"fmaleWeight": 16.8
		},
		"TYPE21": {
			"minAge": 16,
			"maxAge": 16.5,
			"maleWeight": 17.3,
			"fmaleWeight": 17
		},
		"TYPE22": {
			"minAge": 16.5,
			"maxAge": 17,
			"maleWeight": 17.5,
			"fmaleWeight": 17.1
		},
		"TYPE23": {
			"minAge": 17,
			"maxAge": 17.5,
			"maleWeight": 17.7,
			"fmaleWeight": 17.2
		},
		"TYPE24": {
			"minAge": 17.5,
			"maxAge": 25,
			"maleWeight": 17.9,
			"fmaleWeight": 17.3
		}
	},
	"EYE_DISEASE_TYPE": {
		"AMBLYOPIA": {
			"value": 1,
			"label": "弱视"
		},
		"STRABISMUS": {
			"value": 2,
			"label": "斜视"
		},
		"TRAUMA": {
			"value": 4,
			"label": "外伤"
		},
		"CONGENITAL_ANOMALIES": {
			"value": 8,
			"label": "先天异常"
		},
		"OTHER": {
			"value": 16,
			"label": "其他眼病"
		},
		"NONE_OF_THE_ABOVE": {
			"value": 32,
			"label": "无以上情况"
		}
	},
	"PRESCHOOL_WARNINGLEVEL": {
		"NORMAL": {
			"value": 0,
			"label": "视力正常",
			"text": "当前筛查结果显示正常，请保持良好的用眼习惯，青少年儿童视力波动变化较快，建议每六个月去正规医疗机构进行眼视光检查。"
		},
		"FIRST": {
			"value": 1,
			"label": "观察期预警",
			"text": "戴镜视力下降。建议及时到医疗机构复查，确定是否需要更换眼镜。"
		},
		"TWO": {
			"value": 2,
			"label": "轻度预警",
			"text": "戴镜视力正常。建议3个月或半年1次检查裸眼视力和戴镜视力。"
		},
		"THIRD": {
			"value": 3,
			"label": "中度预警",
			"text": "裸眼视力下降，视功能可能异常。建议到医疗机构接受散瞳验光检查，明确诊断并及时采取措施。"
		},
		"FOURTH": {
			"value": 4,
			"label": "重度预警",
			"text": "裸眼视力下降，合并较为明显的屈光不正或眼病。建议到医疗机构明确诊断及时矫治。"
		}
	},
	"WARNINGLEVEL": {
		"NORMAL": {
			"value": 0,
			"label": "视力正常",
			"text": "当前筛查结果显示正常，请保持良好的用眼习惯，青少年儿童视力波动变化较快，建议每六个月去正规医疗机构进行眼视光检查。"
		},
		"FIRST": {
			"value": 1,
			"label": "观察期预警",
			"text": "戴镜视力下降，非弱视者建议及时到医疗机构复查，确定是否需要更换眼镜。"
		},
		"TWO": {
			"value": 2,
			"label": "轻度预警",
			"text": "戴镜视力正常。建议每6-12月检查裸眼视力和戴镜视力。"
		},
		"THIRD": {
			"value": 3,
			"label": "中度预警",
			"text": "裸眼视力下降，视功能可能异常。建议到医疗机构接受散瞳验光检查，明确诊断并及时采取措施。"
		},
		"FOURTH": {
			"value": 4,
			"label": "重度预警",
			"text": "裸眼视力下降，合并较为明显的屈光不正或眼病。建议到医疗机构明确诊断及时矫治。"
		}
	},
	"QUESTIONNAIRE_SCHOOL_TYPE": {
		"PRIMARY_SCHOOL": {
			"value": 2,
			"label": "小学"
		},
		"JUNIOR_HIGH_SCHOOL": {
			"value": 4,
			"label": "初中"
		},
		"SENIOR_HIGH_SCHOOL": {
			"value": 8,
			"label": "高中"
		},
		"PROFESSIONAL_HIGH_SCHOOL": {
			"value": 16,
			"label": "职高"
		},
		"COLLEGE": {
			"value": 32,
			"label": "大学"
		}
	},
	"QUESTIONNAIRE_OBJECT": {
		"JKZX": {
			"value": 1,
			"label": "机构"
		},
		"SCHOOL": {
			"value": 2,
			"label": "学校"
		},
		"STUDENT": {
			"value": 3,
			"label": "学生"
		}
	},
	"SCOLIOSIS_INSPECT": {
		"NOT_HAVE": {
			"value": 1,
			"label": "正常"
		},
		"TWO": {
			"value": 2,
			"label": "双肩不等高"
		},
		"THREE": {
			"value": 4,
			"label": "双侧肩胛骨下角不等高"
		},
		"FOUR": {
			"value": 8,
			"label": "两侧腰凹不对称"
		},
		"FIVE": {
			"value": 16,
			"label": "双侧髂嵴不等高"
		},
		"SIX": {
			"value": 32,
			"label": "棘突连线倾斜或偏离正中线"
		}
	},
	"LATERAL_BEND": {
		"NOT_HAVE": {
			"value": 1,
			"label": "无侧弯"
		},
		"LEFT_BEND": {
			"value": 2,
			"label": "左低右高"
		},
		"RIGHT_BEND": {
			"value": 3,
			"label": "右低左高"
		},
		"DEFECT": {
			"value": 9,
			"label": "缺失"
		}
	},
	"YESORNO_BOOLEAN": {
		"VALUEYES": {
			"value": true,
			"label": "是"
		},
		"VALUENO": {
			"value": false,
			"label": "否"
		}
	},
	"FRONT_BACK_BEND2": {
		"NOT_HAVE": {
			"value": 1,
			"label": "正常"
		},
		"LORDOSIS_SIGN": {
			"value": 2,
			"label": "前凸体征"
		},
		"KYPHOSIS_SIGN": {
			"value": 3,
			"label": "后凸体征"
		}
	},
	"PRONE_TEXT": {
		"LOSS_CONVEXITY_SIGNS": {
			"label": "前后凸体征消失",
			"value": 1
		},
		"LORDOSIS_SIGN": {
			"label": "前凸体征",
			"value": 2
		},
		"KYPHOSIS_SIGN": {
			"label": "后凸体征",
			"value": 3
		}
	},
	"SPINAL_HISTORY": {
		"NOTHING": {
			"label": "无",
			"value": 1
		},
		"REMEMBER_FAMILY_HISTORY_BENDING_ANOMALIES": {
			"label": "脊柱弯曲异常家族史",
			"value": 2
		},
		"HISTORY_SPINAL_TRAUMA": {
			"label": "脊柱外伤史",
			"value": 4
		},
		"HISTORY_SPINAL_SURGERY": {
			"label": "脊柱手术病史",
			"value": 8
		}
	},
	"PRELIMINARY_SCREENING_RESULT": {
		"NORMAL": {
			"label": "正常",
			"value": 1
		},
		"BAD_POSTURE": {
			"label": "姿态不良",
			"value": 2
		},
		"ABNORMAL_LORDOSIS": {
			"label": "脊柱前凸异常",
			"value": 4
		},
		"ABNORMAL_KYPHOSIS": {
			"label": "脊柱后凸异常",
			"value": 8
		},
		"SCOLIOSIS_I": {
			"label": "脊柱侧弯Ⅰ级",
			"value": 16
		},
		"SCOLIOSIS_Ⅱ": {
			"label": "脊柱侧弯Ⅱ级",
			"value": 32
		},
		"SCOLIOSIS_Ⅲ": {
			"label": "脊柱侧弯Ⅲ级",
			"value": 64
		}
	}
};