<template lang="pug">
  .NavBar
    Tabbar(v-model="selected",:fixed="true",style="height:45px")
      TabItem.flex.flex-center(id="home",@click.native="$router.push({name: 'Home'})")
        Icon(name="home")
        div(style="margin-top:4px") 主页
      TabItem.flex.flex-center(id="back",@click.native="$router.go(-1)")
        Icon(name="back")
        div(style="margin-top:4px") 返回
</template>

<script>
import { Tabbar, TabItem } from 'mint-ui'
export default {
  name: 'NavBar',
  props: {
  },
  data () {
    return {
      selected: 'home'
    }
  },
  methods: {
  },
  mounted () {
  },
  components: {
    Tabbar,
    TabItem
  }
}
</script>

<style scoped>
</style>
