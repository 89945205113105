<template lang="pug">
  span.iconfont.my-icon(:class="'icon-' + name")
</template>

<script>
export default {
  name: 'Icon',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
