<template lang="pug">
  svg.svgicon(aria-hidden="true")
    use(:xlink:href="iconName")
</template>

<script>
export default {
  name: 'ColorIcon',
  props: {
    name: {
      type: String,
      default: ''
    }
  },
  data () {
    return {}
  },
  computed: {
    iconName () {
      return this.name ? `#color-${this.name}` : ''
    }
  },
  methods: {
  },
  mounted () {
  },
  components: {
  }
}
</script>

<style scoped>
</style>
