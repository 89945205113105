import websiteConfig from './website.yaml'
import _ from 'lodash'
export default {
  config: {},
  init () {
    // let hostname = window.location.hostname
    // 调试在控制台输入
    // 调试 window.sessionStorage.setItem('debug_hostname', 'uss.boai.eyesws.com')
    // 清除 window.sessionStorage.clear('debug_hostname')
    // if (process.env.VUE_APP_ENV !== 'production') {
    //   let debugHostname = window.sessionStorage.getItem('debug_hostname')
    //   if (debugHostname) {
    //     hostname = debugHostname
    //   }
    // }
    const domainName = process.env.VUE_APP_DOMAIN_NAME
    let hostConfig = websiteConfig[domainName]
    const defaultConfig = websiteConfig['default']
    this.config = _.assign({}, defaultConfig, hostConfig)
    // console.log(this.config)
    window.document.title = this.config.documentTitle.value
  }
}
