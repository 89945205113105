import {SET_VIEW_DATA} from 'src/store/mutation-types'

const state = {
  /* eslint-disable-next-line */
  data: null
}

const mutations = {
  [SET_VIEW_DATA] (state, {data}) {
    state.data = data
  }
}
const actions = {
}
export default {
  state,
  mutations,
  actions
}
