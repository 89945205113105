import Vue from 'vue'
import Router from 'vue-router'
import config from './router.yaml'
Vue.use(Router)
const register = (opt) => {
  let name = opt.name
  let path = opt.path
  let file = opt.file
  if (!file) {
    file = `${name}/${name}`
  }
  const result = {
    path,
    name,
    meta: opt.meta,
    children: [],
    component: () => import('pages/' + file)
  }

  if (opt.children) {
    opt.children.forEach(element => {
      result.children.push(register(element))
    })
  }
  return result
}
const routes = []
config.forEach(element => {
  routes.push(register(element))
})
export default new Router({
  routes
})
