import Vue from 'vue'
import {
  Button,
  Cell,
  Switch,
  Checklist,
  Radio,
  Field,
  MessageBox,
  Toast,
  InfiniteScroll,
  Spinner
} from 'mint-ui'
Vue.component(Button.name, Button)
Vue.component(Cell.name, Cell)
Vue.component(Switch.name, Switch)
Vue.component(Checklist.name, Checklist)
Vue.component(Radio.name, Radio)
Vue.component(Field.name, Field)
Vue.component(Spinner.name, Spinner)
Vue.use(InfiniteScroll)
Vue.prototype.$messageBox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$toast = Toast
Vue.prototype.$success = (message = '操作成功') => {
  Toast({
    message,
    iconClass: 'iconfont icon-success mt-custom-toast'
  })
}
