module.exports = [
	{
		"name": "Home",
		"path": "/",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "VisualDocument",
		"path": "/VisualDocument",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "VisionReport",
		"path": "/VisionReport",
		"file": "VisualDocument/VisionReport",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "ClearToken",
		"path": "/ClearToken",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "ArticleDetails",
		"path": "/ArticleDetails",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "User",
		"path": "/User",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "ScreeningReport",
		"path": "/ScreeningReport",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "HealthRecords",
		"path": "/HealthRecords",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "HealthRecordsList",
		"path": "/HealthRecordsList",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "HealthRecordsView",
		"path": "/HealthRecordsView",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "QuestionNaireList",
		"path": "/QuestionNaireList",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "QuestionNaire",
		"path": "/QuestionNaire",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "QuestionNaireShow",
		"path": "/QuestionNaireShow",
		"meta": {
			"hideNav": true,
			"requiresAuth": true
		}
	},
	{
		"name": "RegisteredAppointment",
		"path": "/RegisteredAppointment",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "RegisteredAppointmentView",
		"path": "/RegisteredAppointmentView",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "ChoiseStudent",
		"path": "/ChoiseStudent",
		"meta": {
			"requiresAuth": true
		}
	},
	{
		"name": "StudentTakingPicture",
		"path": "/StudentTakingPicture",
		"meta": {
			"requiresAuth": true
		}
	}
];