/**
 *
 微信登录
     页面有ticket
     -> 获取token
     -> 有
     -> 无 -> 点击登录的时候带上ticket
 帐号登录
  输入帐号
    -> 换取token
*/
import _ from 'lodash'
import store from 'store'
import { bizUserLoginByTicket, bizMainSystemInfo } from 'src/api'
// import {cmMainUserInfo} from 'src/api'
import config from 'src/config'
import qs from 'qs'
import axios from 'axios'
let TOKEN_KEY
if (process.env.VUE_APP_DOMAIN_NAME === 'nanchang') {
  TOKEN_KEY = 'NC_ACCESS_TOKEN'
} else {
  TOKEN_KEY = 'HMP_ACCESS_TOKEN'
}
const CACHE_TICKET_KEY = 'CACHE_TICKET_KEY'
export default {
  userInfo: null,
  token: store.get(TOKEN_KEY),
  wxRegistUrl: null,
  platformBaseUrl: null,
  cacheTicket: null,
  setInfo (info) {
    this.userInfo = info
  },
  isGuest () {
    return !this.hasToken()
  },
  getInfo () {
    return this.userInfo
  },
  clearInfo () {
    this.userInfo = null
  },
  getToken () {
    return this.token
  },
  setToken (token) {
    store.set(TOKEN_KEY, token)
    this.token = token
  },
  hasToken () {
    return !_.isNil(this.getToken())
  },
  clearToken () {
    this.token = null
    store.remove(TOKEN_KEY)
  },
  setWxRegistUrl (wxRegistUrl) {
    this.wxRegistUrl = wxRegistUrl || ''
  },
  setPlatformBaseUrl (platformBaseUrl) {
    this.platformBaseUrl = platformBaseUrl
  },
  getPlatformBaseUrl () {
    return this.platformBaseUrl
  },
  getTicket () {
    const url = window.location.href
    // eslint-disable-next-line
    const result = /user_ticket=([^&|\?]*)/g.exec(url)
    if (result) {
      return result[1]
    }
    return null
  },
  wechatLogin () {
    // const href = window.location.href.replace('#','wx/#')
    const href = window.location.href
    const cacheTicket = window.sessionStorage.getItem(CACHE_TICKET_KEY)
    console.log(encodeURIComponent(encodeURIComponent(href)))
    let result = this.wxRegistUrl.replace('P_RETURN_URL', encodeURIComponent(encodeURIComponent(href)))
    if (cacheTicket) {
      result = result.replace('P_TICKET', cacheTicket)
    } else {
      result = result.replace('P_TICKET', '')
    }
    // eslint-disable-next-line
    window.location.href = result.replace(/(\?|&)user_ticket=[^&|\?]*/g, '')
    setTimeout(() => {
      window.location.reload()
    }, 3000)
  },
  async initTokenByTicket () {
    const ticket = this.getTicket()
    if (!ticket) {
      return
    }
    try {
      const requestParams = {}
      requestParams['ticket'] = ticket // ticket 必填
      const res = await bizUserLoginByTicket(requestParams)
      if (res.data) {
        this.setToken(res.data.access_token)
        window.sessionStorage.removeItem(CACHE_TICKET_KEY)
      } else {
        if (!this.hasToken()) {
          window.sessionStorage.setItem(CACHE_TICKET_KEY, ticket)
        }
      }
    } catch (error) {
      throw error
    } finally {
      // eslint-disable-next-line
      window.location.hash = window.location.hash.replace(/(\?|&)wcoa_ticket=[^&|\?]*/g, '')
    }
  },
  async accountLogin (username, password) {
    try {
      const res = await axios.post(`${config.token}`, qs.stringify({ username, password }))
      this.setToken(res.data.access_token)
    } catch (error) {
      throw error
    }
  },
  async getSystemInfo () {
    try {
      const res = await bizMainSystemInfo()
      // this.setPlatformBaseUrl(res.data.platformBaseUrl)
      this.setWxRegistUrl(res.data.wxJoinUrl)
      return res.data
    } catch (error) {
      console.log(error)
      throw error
    }
  }
}
