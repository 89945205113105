module.exports = {
	"default": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		},
		"sowingMap": {
			"desc": "轮播图",
			"value": {
				"img": "eye_help.jpg"
			}
		},
		"indexReportBtn": {
			"desc": "眼健康报告按钮",
			"value": "门诊报告查询"
		},
		"game": {
			"desc": "训练小游戏",
			"value": true
		},
		"diseaseBaike": {
			"desc": "疾病科普",
			"value": "疾病科普"
		},
		"onlyReport": {
			"desc": "只显示报告界面",
			"value": false
		}
	},
	"hunan": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	},
	"nanchang": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	},
	"changzhou": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	},
	"guangxi": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	},
	"yuanbao": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "亮卫士平台"
		}
	},
	"taizhou": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	},
	"changsha": {
		"documentTitle": {
			"desc": "网站标题",
			"value": "科教云平台"
		}
	}
};